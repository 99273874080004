import * as React from "react";
import * as Api from '../api/api';
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type VoucherCampaignFormProps = InjectedFormProps<{}, {}, {}>
    & VoucherCampaignFormOwnProps
    & VoucherCampaignFormExternalProps;

export type VoucherCampaignAmountType = "Flat"
    | "Percentage";

interface VoucherCampaignFormOwnProps {
    type: Api.VoucherCampaignModelTypeEnum;
    disabled: boolean;
}

interface VoucherCampaignFormExternalProps {
    text: string;
    onSubmit: (values: Api.VoucherCampaignModel) => Promise<any>
}

interface VoucherCampaignState {
    amountType: VoucherCampaignAmountType;
}

export const getVoucherCampaignTypeOptions = (): Array<{ label: string; value: Api.VoucherCampaignModelTypeEnum; }> => [
    { label: getText( "VoucherModalManual"), value: "Normal" },
    { label: getText( "VoucherModalAuto"), value: "Automatic" }
];

const marginStyle: React.CSSProperties = { marginBottom: "3%" };
const labelStyle: React.CSSProperties = { fontSize: 14};

class VoucherCampaignForm extends React.Component<VoucherCampaignFormProps, VoucherCampaignState> {
    constructor(props: VoucherCampaignFormProps) {
        super(props);
        this.state = {
            amountType: "Flat"
        };
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (<FormField.BaseForm {...this.props}>
            <div className="row borderSelectors" style={{margin: 0, textAlign: 'left', display: 'flex', justifyContent: 'center'}}>
                <div className="col-md-7">
                    {/*<div className="form-group" style={marginStyle}>
                        <label style={labelStyle}>{getText( "VoucherType2")} : </label>
                        <Field name="type"
                            className="form-control"
                            validate={[FormField.required]}
                            type="text"
                            component={FormField.getSelectField(getVoucherCampaignTypeOptions())} />
                    </div>*/}
                    <div className="form-group" style={marginStyle}>
                        <label style={labelStyle}>{getText( "VoucherModalName")} : </label>
                        <Field name="name"
                            className="form-control"
                            type="text"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                    </div>
                    {this.props.type === "Automatic"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherModalMax")} : </label>
                            <Field name="number"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherStartDate")} : </label>
                            <Field name="startDate"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.DatePickerField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherEndDate")} : </label>
                            <Field name="endDate"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.DatePickerField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherType")} : </label>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 3 }}>
                                        <input type="radio"
                                            value="Flat"
                                            checked={this.state.amountType === "Flat"}
                                            onChange={(e) => this.setState({ amountType: e.target.value as any })} />
                                        <span style={{ marginLeft: 3 }}>{getText( "VoucherModalFixedAmount")}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input type="radio"
                                            value="Percentage"
                                            checked={this.state.amountType === "Percentage"}
                                            onChange={(e) => this.setState({ amountType: e.target.value as any })} />
                                        <span style={{ marginLeft: 3 }}>{getText( "VoucherModalPercentageAmount2")}</span>
                                    </div>
                                </div>
                            </div>
                    </div>}
                    {this.props.type === "Automatic"
                        && <div className="form-group" style={marginStyle}>
                        <label style={labelStyle}>{getText( "VoucherModalAmountCashed")} : </label>
                        <Field name="minOrderAmount"
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.NumberField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && this.state.amountType === "Flat"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherModalAmount")} : </label>
                            <Field name="amount"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && this.state.amountType === "Percentage"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherModalPercent2")} : </label>
                            <Field name="percentageOfOrder"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && this.state.amountType === "Percentage"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherModalAmountMin")} : </label>
                            <Field name="minAmount"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                    </div>}
                    {this.props.type === "Automatic"
                        && this.state.amountType === "Percentage"
                        && <div className="form-group" style={marginStyle}>
                            <label style={labelStyle}>{getText( "VoucherModalAmountMax")} : </label>
                            <Field name="maxAmount"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                    </div>}
                    <div className="form-group">
                        <button type={"submit"}
                            style={{
                                width: '100%', 
                                fontSize: 15,
                                 marginTop: '3%',
                                 backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                 color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                 borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            className="btn btn-primary"
                            disabled={this.props.submitting || this.props.disabled}>
                            {this.props.text}
                        </button>
                    </div>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

let form = reduxForm({
    form: 'createVoucherCampaign',
    enableReinitialize: true,
    destroyOnUnmount: true
})(VoucherCampaignForm as any) as any;
const createSelector = formValueSelector('createVoucherCampaign');

export default connect((state: ApplicationState) => {
    return {
        initialValues: {
            type: "Normal"
        } as any,
        type: createSelector(state, "type"),
        disabled: !canUpdate(state)
    } as VoucherCampaignFormOwnProps
}, null)(form as any) as any as React.ComponentClass<VoucherCampaignFormExternalProps>