import * as Api from '../api/api';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import CashRegisterOperations from "./CashRegisterOperations";
import { getCurrentLang } from '../utils/language';
import { GroupedStoreData } from '../store/History';

interface HistoryCashRegisterProps {
    hoppers: Array<Api.HmiMoneyHopperModel>;
    isLoading: boolean;
    seed: Api.SeedMngModel;
    selectedIndex: number;
    groupedStoreDatas: Array<HistoryStore.GroupedStoreData>;
    timeGroupBy: HistoryStore.TimeGroupBy;
    updateSelectedIndex: (value: number) => void;
}
const rowStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between'};

export default class HistoryCashRegister extends React.Component<HistoryCashRegisterProps, {}> {

    get selectedDayStoreData(): GroupedStoreData {
        return this.props.groupedStoreDatas[this.props.selectedIndex]
            || this.props.groupedStoreDatas[0];
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{...rowStyle}}>
                <div style={{paddingLeft: '1%', overflow: 'scroll', height: '59vh', width: '20%'}}>
                    {this.props.groupedStoreDatas.map((dayStoreData, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: xi === this.props.selectedIndex
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.updateSelectedIndex(xi)}>
                        {this.props.timeGroupBy === "Day" && Moment(dayStoreData.date).locale(getCurrentLang()).format("dddd DD/MM/YYYY")}
                        {this.props.timeGroupBy === "Week" && Moment(dayStoreData.date).locale(getCurrentLang()).format("YYYY") + " S" + Moment(dayStoreData.date).locale(getCurrentLang()).week()}
                        {this.props.timeGroupBy === "Month" && Moment(dayStoreData.date).locale(getCurrentLang()).format("MMMM")}
                        {this.props.timeGroupBy === "Year" && Moment(dayStoreData.date).locale(getCurrentLang()).format("YYYY")}
                    </div>)}
                </div>
                <div style={{padding: '5px 1%', width: '100%', fontSize: 13}}>
                    {this.selectedDayStoreData
                        && <CashRegisterOperations hoppers={this.props.hoppers}
                            dayStoreData={{
                                ...this.selectedDayStoreData,
                                dayDate: this.selectedDayStoreData.date,
                            } as any}
                            seed={this.props.seed}
                            resetDisabled={true}
                            onResetStoredMoneyClick={(e) => { }} />}
                </div>
            </div>
            );
    }
}