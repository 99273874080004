import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import { SubmissionError } from 'redux-form';
import * as _ from 'lodash';

export interface VoucherState {
    isLoading: boolean;
    requestTime?: number;
    voucherCampaignId?: number;
    createVouchersMode: CreateVouchersMode;
    result: Api.VouchersResultModel;
    createVoucherCampaign: {
        isOpen: boolean;
        isLoading: boolean;
        requestTime?: number;
    },
    createVouchers: {
        isOpen: boolean;
        isLoading: boolean;
        requestTime?: number;
    },
    deleteVoucher: {
        isLoading: boolean;
        requestTime?: number;
    },
    vouchersHistoryState: {
        isLoading: boolean;
        requestTime?: number;
        result: Api.VouchersHistoryResultModel;
        fromDate: Date;
        voucherCampaignId?: number;
    }
}

export type CreateVouchersMode = "Number" | "Codes";

interface RequestVouchers {
    type: "REQUEST_DISCOUNTS";
    payload: { requestTime: number };
}
interface ReceiveVouchers {
    type: "RECEIVE_DISCOUNTS";
    payload: { requestTime: number; result?: Api.VouchersResultModel };
    error?: any;
}

interface UpdateCreateVouchersMode {
    type: "UPDATE_CREATE_DISCOUNTS_MODE";
    payload: { value: CreateVouchersMode };
}

interface OpenCreateVouchers {
    type: "OPEN_CREATE_DISCOUNTS"
}
interface CloseCreateVouchers {
    type: "CLOSE_CREATE_DISCOUNTS"
}

interface OpenCreateVoucherCampaign {
    type: "OPEN_CREATE_DISCOUNTCAMPAIGN"
}
interface CloseCreateVoucherCampaign {
    type: "CLOSE_CREATE_DISCOUNTCAMPAIGN"
}

interface UpdateVoucherCampaignId {
    type: "UPDATE_DISCOUNTCAMPAIGNID";
    payload: { value: number; }
}

interface RequestCreateVouchers {
    type: "REQUEST_CREATE_DISCOUNTS";
    payload: { requestTime: number; }
}
interface ReceiveCreateVouchers {
    type: "RECEIVE_CREATE_DISCOUNTS";
    payload: { requestTime: number; discounst?: Array<Api.VoucherModel> }
    error?: any;
}

interface RequestDeleteVoucher {
    type: "REQUEST_DELETE_DISCOUNT";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeleteVoucher {
    type: "RECEIVE_DELETE_DISCOUNT";
    payload: { requestTime: number; id: number; }
    error?: any
}

interface RequestCreateVoucherCampaign {
    type: "REQUEST_CREATE_DISCOUNT_CAMPAIGN";
    payload: { requestTime: number; }
}
interface ReceiveCreateVoucherCampaign {
    type: "RECEIVE_CREATE_DISCOUNT_CAMPAIGN";
    payload: { requestTime: number; voucherCampaign?: Api.VoucherCampaignModel }
    error?: any;
}

interface VoucherUpdateHistoryFromDate {
    type: "VOUCHER_UPDATE_HISTORY_FROMDATE";
    payload: { value: Date; }
}
interface VoucherUpdateHistoryCampaignId {
    type: "VOUCHER_UPDATE_HISTORY_CAMPAIGNID";
    payload: { value: number; }
}
interface RequestVouchersHistory {
    type: "REQUEST_VOUCHERS_HISTORY";
    payload: { requestTime: number; }
}
interface ReceiveVouchersHistory {
    type: "RECEIVE_VOUCHERS_HISTORY";
    payload: { requestTime: number; result?: Api.VouchersHistoryResultModel }
    error?: any;
}

interface RequestDeleteVouchersCampaign {
    type: "REQUEST_DELETE_DISCOUNTS_CAMPAIGN";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeleteVouchersCampaign {
    type: "RECEIVE_DELETE_DISCOUNTS_CAMPAIGN";
    payload: { requestTime: number; id: number; }
    error?: any
}

type KnownAction = RequestVouchers
    | ReceiveVouchers
    | UpdateCreateVouchersMode
    | OpenCreateVouchers | CloseCreateVouchers
    | OpenCreateVoucherCampaign
    | CloseCreateVoucherCampaign
    | UpdateVoucherCampaignId
    | RequestCreateVouchers
    | ReceiveCreateVouchers
    | RequestCreateVoucherCampaign
    | ReceiveCreateVoucherCampaign
    | RequestVouchersHistory
    | ReceiveVouchersHistory
    | VoucherUpdateHistoryFromDate
    | VoucherUpdateHistoryCampaignId
    | RequestDeleteVoucher
    | ReceiveDeleteVoucher
    | RequestDeleteVouchersCampaign
    | ReceiveDeleteVouchersCampaign;

export const actionCreators = {
    requestVouchers: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.VoucherApi();
        let fetchTask = api.getVouchers({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(result => {
            dispatch({
                type: "RECEIVE_DISCOUNTS",
                payload: {
                    requestTime: requestTime,
                    result: result
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_DISCOUNTS",
                payload: {
                    requestTime: requestTime,
                },
                error: err
            });
        });

        dispatch({ type: "REQUEST_DISCOUNTS", payload: { requestTime: requestTime } });
        addTask(fetchTask);
        return fetchTask;
    },
    updateCreateVoucherMode: (value: CreateVouchersMode) => <UpdateCreateVouchersMode>{
        type: "UPDATE_CREATE_DISCOUNTS_MODE",
        payload: { value: value }
    },
    openCreateVouchers: () => <OpenCreateVouchers>{ type: "OPEN_CREATE_DISCOUNTS" },
    closeCreateVouchers: () => <CloseCreateVouchers>{ type: "CLOSE_CREATE_DISCOUNTS" },
    openCreateVoucherCampaign: () => <OpenCreateVoucherCampaign>{ type: "OPEN_CREATE_DISCOUNTCAMPAIGN" },
    closeCreateVoucherCampaign: () => <CloseCreateVoucherCampaign>{ type: "CLOSE_CREATE_DISCOUNTCAMPAIGN" },
    updateVoucherCampaignId: (value: number) => <UpdateVoucherCampaignId>{
        type: "UPDATE_DISCOUNTCAMPAIGNID",
        payload: { value: value }
    },
    requestCreateVouchers: (requestTime: number, model: Api.CreateVouchersModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.VoucherApi();
        let fetchTask = api.createVouchers({ model: model }, {
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(vouchers => {
            dispatch({
                type: "RECEIVE_CREATE_DISCOUNTS",
                payload: {
                    requestTime: requestTime,
                    discounst: vouchers
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_CREATE_DISCOUNTS",
                payload: {
                    requestTime: requestTime,
                },
                error: err
            });
            throw new SubmissionError({ _error: err.message || err.statusText });
        });

        dispatch({ type: "REQUEST_CREATE_DISCOUNTS", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestDeleteVoucher: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.VoucherApi();
        let fetchTask = api.deleteVoucher({ id: id }, {
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(() => {
            dispatch({
                type: "RECEIVE_DELETE_DISCOUNT",
                payload: {
                    requestTime: requestTime,
                    id: id
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_DELETE_DISCOUNT",
                payload: {
                    requestTime: requestTime,
                    id: id
                },
                error: err
            });
        });

        dispatch({ type: "REQUEST_DELETE_DISCOUNT", payload: { requestTime: requestTime, id: id } });
        return fetchTask;
    },
    requestDeleteVouchers: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.VoucherApi();
        let fetchTask = api.deleteVouchersByCampaign({ id: id }, {
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(() => {
            dispatch({
                type: "RECEIVE_DELETE_DISCOUNTS_CAMPAIGN",
                payload: {
                    requestTime: requestTime,
                    id: id
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_DELETE_DISCOUNTS_CAMPAIGN",
                payload: {
                    requestTime: requestTime,
                    id: id
                },
                error: err
            });
        });

        dispatch({ type: "REQUEST_DELETE_DISCOUNTS_CAMPAIGN", payload: { requestTime: requestTime, id: id } });
        return fetchTask;
    },
    requestCreateVoucherCampaign: (requestTime: number, model: Api.VoucherCampaignModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.VoucherApi();
        let fetchTask = api.createVoucherCampaign({ model: model }, {
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(voucherCampaign => {
            dispatch({
                type: "RECEIVE_CREATE_DISCOUNT_CAMPAIGN",
                payload: {
                    requestTime: requestTime,
                    voucherCampaign: voucherCampaign
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_CREATE_DISCOUNT_CAMPAIGN",
                payload: {
                    requestTime: requestTime,
                },
                error: err
            });
            throw new SubmissionError({ _error: err.message || err.statusText });
        });

        dispatch({ type: "REQUEST_CREATE_DISCOUNT_CAMPAIGN", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestVouchersHistory: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.VoucherApi();
        let fetchTask = api.getVouchersHistory({
            fromDate: getState().voucher.vouchersHistoryState.fromDate
        }, {
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(result => {
            dispatch({
                type: "RECEIVE_VOUCHERS_HISTORY",
                payload: {
                    requestTime: requestTime,
                    result: result
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_VOUCHERS_HISTORY",
                payload: {
                    requestTime: requestTime,
                },
                error: err
            });
            throw new SubmissionError({ _error: err.message || err.statusText });
        });

        dispatch({ type: "REQUEST_VOUCHERS_HISTORY", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    updateHistoryFromDate: (value: Date) => <VoucherUpdateHistoryFromDate>{
        type: "VOUCHER_UPDATE_HISTORY_FROMDATE",
        payload: { value: value }
    },
    updateHistoryCampaignId: (value: number) => <VoucherUpdateHistoryCampaignId>{
        type: "VOUCHER_UPDATE_HISTORY_CAMPAIGNID",
        payload: { value: value }
    }
};

const unloadedState: VoucherState = {
    isLoading: false,
    result: {},
    createVouchersMode: "Number",
    createVouchers: {
        isOpen: false,
        isLoading: false
    },
    deleteVoucher: {
        isLoading: false
    },
    createVoucherCampaign: {
        isOpen: false,
        isLoading: false
    },
    vouchersHistoryState: {
        isLoading: false,
        result: {},
        fromDate: Moment().add(-1, "months").startOf("day").toDate()
    }
};

export const reducer: Reducer<VoucherState> = (state: VoucherState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_DISCOUNTS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_DISCOUNTS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                result: action.error
                    ? state.result
                    : action.payload.result
            };
        case "UPDATE_DISCOUNTCAMPAIGNID":
            return {
                ...state,
                voucherCampaignId: action.payload.value
            };
        case "UPDATE_CREATE_DISCOUNTS_MODE":
            return {
                ...state,
                createVouchersMode: action.payload.value
            };
        case "OPEN_CREATE_DISCOUNTS":
            return {
                ...state,
                createVouchers: {
                    ...state.createVouchers,
                    isOpen: true
                }
            };
        case "CLOSE_CREATE_DISCOUNTS":
            return {
                ...state,
                createVouchers: {
                    ...state.createVouchers,
                    isOpen: false
                }
            };
        case "OPEN_CREATE_DISCOUNTCAMPAIGN":
            return {
                ...state,
                createVoucherCampaign: {
                    ...state.createVoucherCampaign,
                    isOpen: true
                }
            };
        case "CLOSE_CREATE_DISCOUNTCAMPAIGN":
            return {
                ...state,
                createVoucherCampaign: {
                    ...state.createVoucherCampaign,
                    isOpen: false
                }
            };
        case "REQUEST_CREATE_DISCOUNTS":
            return {
                ...state,
                createVouchers: {
                    ...state.createVouchers,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_DISCOUNTS":
            if (state.createVouchers.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createVouchers: {
                    ...state.createVouchers,
                    isLoading: false
                },
                result: {
                    ...state.result,
                    vouchers: state.result.vouchers
                        .concat(action.error
                            ? [] : action.payload.discounst)
                }
            };
        case "REQUEST_DELETE_DISCOUNT":
            return {
                ...state,
                deleteVoucher: {
                    ...state.deleteVoucher,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DELETE_DISCOUNT":
            return {
                ...state,
                deleteVoucher: {
                    ...state.deleteVoucher,
                    requestTime: action.payload.requestTime,
                    isLoading: false
                },
                result: {
                    ...state.result,
                    vouchers: action.error
                        ? state.result.vouchers
                        : state.result.vouchers.filter(x => x.voucherId !== action.payload.id)
                }
            };
        case "REQUEST_CREATE_DISCOUNT_CAMPAIGN":
            return {
                ...state,
                createVoucherCampaign: {
                    ...state.createVoucherCampaign,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_DISCOUNT_CAMPAIGN":
            if (state.createVoucherCampaign.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                voucherCampaignId: (!state.voucherCampaignId
                    && !action.error)
                    ? action.payload.voucherCampaign.voucherCampaignId
                    : state.voucherCampaignId,
                createVoucherCampaign: {
                    ...state.createVoucherCampaign,
                    isLoading: false,
                    isOpen: false
                },
                result: action.error
                    ? state.result
                    : {
                        ...state.result,
                        voucherCampaigns: {
                            ...state.result.voucherCampaigns,
                            [action.payload.voucherCampaign.voucherCampaignId]: action.payload.voucherCampaign
                        }
                    }
            };
        case "REQUEST_VOUCHERS_HISTORY":
            return {
                ...state,
                vouchersHistoryState: {
                    ...state.vouchersHistoryState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_VOUCHERS_HISTORY":
            if (action.payload.requestTime !== state.vouchersHistoryState.requestTime)
                return state;

            return {
                ...state,
                vouchersHistoryState: {
                    ...state.vouchersHistoryState,
                    isLoading: false,
                    voucherCampaignId: _.values(action.payload.result.voucherCampaigns).length !== 0
                        ? _.values(action.payload.result.voucherCampaigns)[0].voucherCampaignId
                        : undefined,
                    result: action.error
                        ? state.vouchersHistoryState.result
                        : action.payload.result
                },
            };
        case "VOUCHER_UPDATE_HISTORY_FROMDATE":
            return {
                ...state,
                vouchersHistoryState: {
                    ...state.vouchersHistoryState,
                    fromDate: action.payload.value
                }
            };
        case "VOUCHER_UPDATE_HISTORY_CAMPAIGNID":
            return {
                ...state,
                vouchersHistoryState: {
                    ...state.vouchersHistoryState,
                    voucherCampaignId: action.payload.value
                }
            };
        case "REQUEST_DELETE_DISCOUNTS_CAMPAIGN":
            return {
                ...state,
                deleteVoucher: {
                    ...state.deleteVoucher,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DELETE_DISCOUNTS_CAMPAIGN":
            return {
                ...state,
                deleteVoucher: {
                    ...state.deleteVoucher,
                    requestTime: action.payload.requestTime,
                    isLoading: false
                },
                result: {
                    ...state.result,
                    vouchers: action.error
                        ? state.result.vouchers
                        : state.result.vouchers.filter(x => x.voucherCampaignId !== action.payload.id)
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};