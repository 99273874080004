import * as React from 'react';
import Moment from 'moment';
import * as ImportStore from '../store/Import';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type LgapImportProps = ImportStore.ImportState
    & LgapImportOwnProps
    & typeof ImportStore.actionCreators;

interface LgapImportOwnProps {
    disabled: boolean;
}

class LgapImport extends React.Component<LgapImportProps, {}> {

    componentDidMount() {
        this.props.requestHasStoreToken(new Date().getTime());
        this.props.requestLastLgapImport(new Date().getTime());
    }

    get getLastImportString(): string {
        let date = this.props.lgapImportState.lastLgapImport.creationDate
            ? Moment(this.props.lgapImportState.lastLgapImport.creationDate).format("DD/MM/YYYY HH:mm:ss")
            : "N/A";

        let successMess = this.props.lgapImportState.lastLgapImport
            ? (this.props.lgapImportState.lastLgapImport.status === "Success" ? getText( "PlanogramSuccess") : getText( "PlanogramFail"))
            : "N/A";
        return getText( "PlanogramLastImport") + `: (${successMess}) ${date}`;
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flex: "0 0 auto", padding: "0px 6px" }}>
                    {this.props.lgapImportState.lastLgapImport ?
                        this.getLastImportString : <span>&nbsp;</span>}
                </div>
                {this.props.lgapImportState.lastLgapImport
                    && this.props.lgapImportState.lastLgapImport.status === "Failure"
                    && <div style={{ flex: "0 0 auto", padding: "0px 6px", color: "red" }}>
                        Info : {this.props.lgapImportState.lastLgapImport.errorMessage}
                    </div>}
                <div style={{ flex: "0 0 auto", padding: "0px 6px" }}>
                    <button className="btn btn-primary"
                        disabled={this.props.lgapImportState.runState.isLoading
                            || !this.props.storeTokenState.hasToken
                            || this.props.disabled}
                        style={{ width: "228px", height: 32, marginTop: 5, left: '-30%' }}
                        onClick={(e) => this.props.requestRunLgapImport(new Date().getTime())}>
                        {getText( "PlanogramStartImport")}
                    </button>
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.import,
    disabled: !canUpdate(state)
}), ImportStore.actionCreators)(LgapImport as any);