import * as Api from '../api/api';
import * as React from 'react';
import Moment from 'moment';
import * as _ from 'lodash';
import * as FormField from './FormField';
import * as HmiApplicationStore from "../store/HmiApplication";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { calculateProductDepth, getExpirityTypeDesc } from '../utils/utils';
import { canUpdate } from '../security/roles';
import * as StockStore from '../store/Stock';
import * as ReduxForm from "redux-form";
import { getText } from '../utils/texts';
import {productSuppliersSelector} from "../store/Seed";

type ProductStockFormProps = ReduxForm.InjectedFormProps<ProductStockFormModel, string, string>
    & ProductStockOwnProps
    & ProductStockExternalPropsExternal
    & typeof StockStore.actionCreators;

interface ProductStockExternalPropsExternal{
    productStock: Api.ProductStockModel[],
    stockArr: number,
    onSubmit: (values: ProductStockFormModel) => any;
}
interface ProductStockOwnProps {
    hmiApplication: HmiApplicationStore.HmiApplicationState;
    actionStock: string,
    productRailId: number;
    quantity: number;
    productSupplierId: number;
    expirity: Date;
    productRail: Api.ProductRailModel;
    shelvingTray: Api.ShelvingTrayModel;
    product: Api.ProductModel;
    productReceptions: Array<Api.ProductReceptionModel>;
    suppliers: { [id: number]: Api.SupplierModel };
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
    disabled: boolean,
    isHMIRunning: boolean,
    storeType: string;
}

export type ProductStockFormModel = {
    "action": StockStore.ActionStockEnum
} & Api.CreateProductStocksModel;

// const noReceptionWarn = (values: Api.ProductStockModel, props: ProductStockFormProps): string => {
//     return props.productReceptions
//         .some(x => x.productSupplierId === values.productSupplierId
//             && x.quantityRemaining > 0)
//         ? undefined : "Attention : Aucune reception correspondant a cette source"
// };

const isHMIRunning = (value: HmiApplicationStore.HmiApplicationState):boolean => {
    return value.hmis.reduce((accum, curr) => {
        const hmiState = value.hmiStates[curr.hmiId];
        return hmiState.applicationState ? hmiState.applicationState.applicationState === 'Running' : false;
    }, false);
}

// const validateValue = val => value =>
//     value && value > val ? `Stock supérieure à ${val} invalide` : undefined

class ProductStockForm extends React.Component<ProductStockFormProps, {}> {
    get maxStock(): number {
        return Math.floor(this.props.shelvingTray.depth
            / calculateProductDepth(this.props.product, this.props.productRail.sens));
    }
    
    constructor(props: ProductStockFormProps) {
        super(props);
        this.overMaxStock = this.overMaxStock.bind(this);
        this.lessMinStock = this.lessMinStock.bind(this);
        this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
        // this.validateStock = this.validateStock.bind(this);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyPress);
    }

    handleKeyPress = key => {
        let isDisableCreate = (this.props.submitting
            || this.maxStock <= this.props.productRail.productStocks.length
            || this.props.disabled);

        let isDisableDelete= (this.props.submitting
                || 0 >= this.props.productRail.productStocks.length
                || this.props.disabled);

        switch(key.key){
            case 't':
            case 'T':
                if(isDisableCreate == false){
                    this.onBeforeSubmit(key, 'fill');
                    this.props.onSubmit({
                        ...this.props.initialValues,
                        expirity: this.props.expirity, 
                        action: "fill"
                    });
                }
                break;
            case 'a':
            case 'A':
                if(isDisableCreate == false){
                    this.onBeforeSubmit(key, 'create');
                    this.props.onSubmit({
                        ...this.props.initialValues,
                        expirity: this.props.expirity, 
                        quantity: this.props.quantity,
                        action: "create"
                    });
                }
                break;
            case 's':
            case 'S':
                if(isDisableDelete == false){
                    this.onBeforeSubmit(key, 'delete');
                    this.props.onSubmit({
                        ...this.props.initialValues,
                        quantity: this.props.quantity,
                        action: "delete"
                    });
                }
                break;
        }
      };

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyPress);
    }


    //Might missbehave because it will be a new instance at every changes, not important for a number input I think
    overMaxStock(value: string) {
        let currentStock = this.props.productRail.productStocks.length;
        return FormField.maxValue(this.maxStock - currentStock)(value)
    } 
    lessMinStock(value: string) {
        return FormField.maxValue(this.props.productRail ? this.props.productRail.productStocks.length : 0)(value)
    } 
    // validateStock(value: string) {
    //     return validateValue(this.props.stockArr)(value);
    // }

    onBeforeSubmit(e: any, action: StockStore.ActionStockEnum) {
        this.props.requestActionProductStock(action)
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} disableResetOnSubmit={false}>
                <div className="borderSelectors" style={{padding: "1% 0", display: 'flex', justifyContent: 'space-between' }}>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ textAlign: 'initial'}}>
                            {getExpirityTypeDesc(this.props.product)} :
                        </div>
                        {this.props.product.expirityType !== "None"  &&
                            <ReduxForm.Field name="expirity"
                                validate={[FormField.required]}
                                className="form-control"
                                showTimeSelect={false}
                                dateFormat={'DD/MM/YYYY'}
                                style={{
                                    height: 40,
                                    background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                                }}
                                component={FormField.DatePickerField}
                            />
                        }
                    </div>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ textAlign: 'initial' }}>
                            {getText( "PlanogramModalQty")} :
                        </div>
                        <ReduxForm.Field name="quantity"
                                         validate={this.props.actionStock === "create" || !this.props.actionStock ? [FormField.required, FormField.minOne, this.overMaxStock ]
                                : this.props.actionStock === "delete" || !this.props.actionStock ? [FormField.required, FormField.minOne, this.lessMinStock] :
                                    [FormField.required, FormField.minOne]}
                            className="form-control"
                            style={{
                                height: 40,
                                background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                                color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                            }}
                            component={FormField.NumberField}
                        />
                    </div>
                    <div style={{minWidth: 180, display: 'flex', alignItems: 'center', marginTop: '3%'}}>
                      { this.props.storeType=="Ximiti" && 
                        <ReduxForm.Field  name="productSupplierId"
                            validate={[FormField.required]}
                            style={{ 
                                width: "100%",
                                color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                            }}
                            component={FormField.getSelectField(
                                _.values(this.props.productSuppliers)
                                    .filter(x => x.productId === this.props.productRail.productId)
                                    .map(x => ({
                                        label: `${this.props.suppliers[x.supplierMainId].name}/Cdt:${x.packCondition}`,
                                        value: x.productSupplierId
                                    })))}
                        />
                                }
                    </div>
                </div>
                <div style={{ marginBottom: '2%', textAlign: "left" }}>                    
                    <button className="btn btn-primary"
                        style={{
                            flex: "0 0 auto",
                            marginRight: "2%",
                            height: 40,
                            fontWeight: 'bold',
                            fontSize: 15,
                            background: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                            borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                            color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"
                        }}
                        type={"submit"}
                        disabled={this.props.submitting
                            || this.maxStock <= this.props.productRail.productStocks.length
                            || this.props.disabled}
                        onClick={e=>this.onBeforeSubmit(e,'create')}>
                            {getText( "PlanogramModalAdd")}
                    </button>
                    <button className="btn btn-primary"
                        style={{
                            flex: "0 0 auto",
                            margin: "0 2%",
                            height: 40,
                            fontWeight: 'bold',
                            fontSize: 15,
                            background: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                            borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                            color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"
                        }}
                        type={"submit"}
                        disabled={this.props.submitting
                            || 0 >= this.props.productRail.productStocks.length
                            || this.props.disabled}
                        onClick={e => this.onBeforeSubmit(e, 'delete')}>
                        {getText( "PlanogramModalDeleteStock")}
                    </button>
                    <button className="btn btn-primary"
                        style={{
                            flex: "0 0 auto",
                            margin: "0 2%",
                            height: 40,
                            fontWeight: 'bold',
                            fontSize: 15,
                            background: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                            borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                            color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"
                        }}
                        type={"submit"}
                        disabled={this.props.submitting
                            || this.maxStock <= this.props.productRail.productStocks.length
                            || this.props.disabled}
                        onClick={e => this.onBeforeSubmit(e, 'fill')}>
                        {getText( "PlanogramModalFill")}
                    </button>
                </div>
            </FormField.BaseForm>
        );
    }
}

let form = reduxForm({
    form: 'createProductStock',
    enableReinitialize: true,
    destroyOnUnmount: true,
})(ProductStockForm) as any;
const createSelector = formValueSelector('createProductStock');

export default connect((state: ApplicationState) => {
    let productRail = state.stock.stockStatus.productRails
        .find(x => x.productRailId === state.stock.createStock.productRailId);
    if(!productRail){
        return {};
    }
    let product;
    if(state.seed.seed.storeAppSettings.typeStore == "Ximiti")
     product =  state.seed.seed.products[productRail.productId];
    else 
     product = state.seed.seed.externalProducts[productRail.externalProductId];
    
    // let stockArr = _.sum( state.stock.stockStatus.productReceptions
    //     .filter(x => {
    //         if (state.stock.stockStatus.productSuppliers[x.productSupplierId] && state.seed.seed.storeAppSettings.typeStore == "Ximiti")
    //             return state.stock.stockStatus.productSuppliers[x.productSupplierId].productId === productRail.productId
    //        /* else if(state.stock.stockStatus.productSuppliers[x.productSupplierId])    
    //             return state.stock.stockStatus.productSuppliers[x.productSupplierId].externalProductId === productRail.externalProductId
    //             */
    //         else
    //             return false;
    //     })
    //     .map(x => x.quantityRemaining));

    let stkRobot = productRail.productStocks.length;

    let defaultExpirity = product.expirityType === "None"
        ? undefined
        : (stkRobot <= 0
            ? Moment().startOf("day").add(product.expirityDays, "days").toDate()            
            : (productRail.productStocks.length > 0 && productRail.productStocks[productRail.productStocks.length - 1].expirity
                ? Moment(productRail.productStocks[productRail.productStocks.length - 1].expirity).toDate()
                : undefined))
  
    const _isHMIRunning = isHMIRunning(state.hmiApplication);
    
    let auxSupplier = _.sortBy(product
            .productSuppliers, x => (x.enabled ? 0 : 2)
                + (x.supplierMainId === product.defaultSupplierId ? 0 : 1))[0];
    
    return {
        initialValues: {
            productRailId: state.stock.createStock.productRailId,
            expirity: defaultExpirity,
            quantity: 1,
            productSupplierId: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ?
                auxSupplier.productSupplierId
                ? auxSupplier.productSupplierId
                : undefined 
                : undefined
        } as Api.ProductStockModel,
        hmiApplication: state.hmiApplication,
        actionStock: state.stock.createStock.actionStock,
        productSuppliers: productSuppliersSelector(state),
        productReceptions: state.stock.stockStatus.productReceptions,
        suppliers: state.seed.seed.suppliers,
        productRail: productRail,
        shelvingTray: state.stock.stockStatus.shelvingTrays[productRail.shelvingTrayId],
        product: product,
        disabled: !canUpdate(state),
        disabledDelete: !canUpdate(state),
        storeType: state.seed.seed.storeAppSettings.typeStore,
        isHMIRunning: _isHMIRunning,
        expirity: createSelector(state, "expirity"),
        productRailId: createSelector(state, "productRailId"),
        quantity: createSelector(state, "quantity"),
        productSupplierId: createSelector(state, "productSupplierId")
    } as ProductStockOwnProps
}, StockStore.actionCreators)(form as any) as any as React.ComponentClass<ProductStockExternalPropsExternal>;