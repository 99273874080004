import * as React from "react";
import * as _ from 'lodash';
import Moment from 'moment';
import * as Api from '../api/api';
import * as VoucherStore from '../store/Voucher';
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import Table from "./Table";
import * as ReactTable from "react-table";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';

type VoucherHistoryProps = VoucherHistoryOwnProps
    & VoucherStore.VoucherState
    & typeof VoucherStore.actionCreators;

interface VoucherHistoryOwnProps {

}

interface VoucherForTableModel {
    "voucherId"?: number;
    "code"?: string;
    "amountType"?: Api.VoucherModelAmountTypeEnum;
    "amount"?: number;
    "maxAmount"?: number;
    "minOrderAmount"?: number;
    "maxUse"?: number;
    "startDate"?: Date;
    "endDate"?: Date;
    "creationDate"?: Date;
    "sourceOrderId"?: number;
    "voucherCampaignId"?: number;
    "voucherOrders"?: Array<Api.VoucherOrderModel>;
    "voucherOrderCreationDate"?: Date;
}

class VoucherHistory extends React.Component<VoucherHistoryProps, {}> {

    componentDidMount() {
        this.props.requestVouchersHistory(new Date().getTime());
    }

    get voucherCampaign(): Api.VoucherCampaignModel {
        return this.props.vouchersHistoryState.result.voucherCampaigns[this.props.vouchersHistoryState.voucherCampaignId];
    }

    get vouchers(): Array<VoucherForTableModel> {
        if(this.props.vouchersHistoryState.result && this.props.vouchersHistoryState.result.vouchers){
            let firstFilter = this.props.vouchersHistoryState.result.vouchers
            .filter(x => x.voucherOrders.length > 0);

            let dataTable : Array<VoucherForTableModel> = [];
            //To create a row for each voucherOrders
            firstFilter.map(x => x.voucherOrders.map(y => {
                let objVouchers : VoucherForTableModel = Object.create(x);
                objVouchers.voucherOrderCreationDate = y.creationDate;
                dataTable.push(objVouchers);
           }));
           return dataTable;
        }
        return [];
    }

    get totalCommands(): number {
        return _.sum(this.vouchers
            .map(x => x.voucherOrders)
            .reduce((a, b) => a.concat(b), [])
            .map(x => this.props.vouchersHistoryState.result.salePayments[x.orderId]
                ? this.props.vouchersHistoryState.result.salePayments[x.orderId].collection
                : 0));
    }

    get totalDiscounted(): number {
        return _.sum(this.vouchers
            .map(x => x.amountType === "Flat"
                ? x.amount * x.voucherOrders.length
                : _.sum(x.voucherOrders.map(y => (this.props.vouchersHistoryState.result.salePayments[y.orderId]
                    ? Math.min(Math.round((this.props.vouchersHistoryState.result.salePayments[y.orderId].askedSolvency * (x.amount / 100)) * 10) / 10, x.maxAmount || 1000)
                    : 0)))));
    }

    public render() {
        return (
            <div className="card" style={{ ...Styles.cardStyle,  margin: '1%'}}>
                <Table data={this.vouchers}
                    pageSize={Math.max(this.vouchers.length,20)}
                    style={{ height: '74vh' }}
                    columns={[{
                        Header: getText( "VoucherCampaign"),
                        id: "VoucherCampaignId",
                        accessor: d => this.props.vouchersHistoryState.result.voucherCampaigns[d.voucherCampaignId].name
                    },
                    {
                        Header: getText( "VoucherCode"),
                        id: "code",
                        accessor: d => d.code
                    },
                    {
                        Header: getText( "VoucherAmount"),
                        id: "amount",
                        accessor: d => d,
                        Cell: row => {
                            let voucher = row.value as VoucherForTableModel;
                            return (<div>
                                {voucher.amountType === "Flat" && voucher.amount}
                                {voucher.amountType === "Percentage" && voucher.amount + "%"}
                            </div>);
                        }
                    },
                    {
                        Header: getText( "VoucherMiniBasket"),
                        id: "minOrderAmount",
                        accessor: d => d.minOrderAmount
                    },
                    {
                        Header: getText( "VoucherMaxUsed"),
                        id: "maxUse",
                        accessor: d => d.maxUse
                    },
                    {
                        Header: getText( "VoucherUsed"),
                        id: "voucherOrders",
                        accessor: d => d.voucherOrders.length
                    },
                    {
                        Header: getText( "VoucherStart"),
                        id: "startDate",
                        accessor: d => d.startDate,
                        Cell: row => <div>{Moment(row.value).format("DD/MM/YYYY")}</div>
                    },
                    {
                        Header: getText( "VoucherDateOfUse"),
                        id: "voucherOrderCreationDate",
                        accessor: d => d.voucherOrderCreationDate,
                        Cell: row => <div>{Moment(row.value).format("DD/MM/YYYY")}</div>
                    },
                    {
                        Header: getText( "VoucherEnd"),
                        id: "endDate",
                        accessor: d => d.endDate,
                        Cell: row => <div>{Moment(row.value).format("DD/MM/YYYY")}</div>
                    }] as Array<ReactTable.Column & { accessor: (d: VoucherForTableModel) => any }>} 
                    showPageSizeOptions={false}
                    showPagination={false}
                    showPaginationBottom={false}/>
                {/* <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch",
                }}>
                    <div style={{ flex: "0 0 200px", overflowY: "scroll" }}>
                        {_.values(this.props.vouchersHistoryState.result.voucherCampaigns)
                            .map(x => <div key={x.voucherCampaignId}
                                style={{
                                    border: "1px solid",
                                    padding: "3px 6px",
                                    margin: "2px 0px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    ...(x.voucherCampaignId === this.props.vouchersHistoryState.voucherCampaignId
                                        ? { color: "blue", fontWeight: "bold" }
                                        : {})
                                }}
                                onClick={(e) => this.props.updateHistoryCampaignId(x.voucherCampaignId)}>
                                {x.name}
                            </div>)}
                    </div>
                    <div style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch"
                    }}>
                        {this.props.vouchersHistoryState.voucherCampaignId
                            && <div style={{ flex: "0 0 auto", display: "grid", gridTemplateRows: "1fr 1fr", gridTemplateColumns: "1fr 1fr 1fr" }}>
                            <div style={{ gridRow: "1 / 2", gridColumn: "1 / 2" }}>Nom : {this.voucherCampaign.name}</div>
                            <div style={{ gridRow: "1 / 2", gridColumn: "2 / 3" }}>Nb. coupons : {this.vouchers.length}</div>
                            <div style={{ gridRow: "1 / 2", gridColumn: "3 / 4" }}>Nb. d'utilisations : {_.sum(this.vouchers.map(x => x.voucherOrders.length))} </div>

                            <div style={{ gridRow: "2 / 3", gridColumn: "1 / 2" }}>Total commandes : {this.totalCommands}</div>
                            <div style={{ gridRow: "2 / 3", gridColumn: "2 / 3" }}>Total réduit : {this.totalDiscounted}</div>
                            <div style={{ gridRow: "2 / 3", gridColumn: "3 / 4" }}>Perte : {this.totalCommands ? ((this.totalDiscounted / this.totalCommands) * 100).toFixed(2) : 0} %</div>
                            </div>}
                    </div>
                </div> */}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.voucher
}), VoucherStore.actionCreators)(VoucherHistory as any);