import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import * as CashStore from "../store/Cash";
import { RouteComponentProps } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { CSSProperties } from 'react';
import { canUpdate } from '../security/roles';
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';

type CashHoppersContrlProps = CashHoppersControlOwnProps
    & CashStore.CashState
    & typeof CashStore.actionCreators;

interface CashHoppersControlOwnProps {
    seed: Api.SeedMngModel;
    disabled: boolean;
}

const colStyle: React.CSSProperties = {
    flex: 1,
    minWidth: 100,
    textAlign: "center",
    padding: "2px 0",
    color: "#181F3A", 
};
const headStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "#FEFF81",
};
const hopperColStyle: React.CSSProperties = {
    ...colStyle,
    marginLeft: 10,
    marginRight: 10
};
const hopperTitleStyle: React.CSSProperties = {
    fontSize: 16,
    fontWeight: "bold"
};



class CashHoppersControl extends React.Component<CashHoppersContrlProps, {}> {
    componentDidMount() {
        this.props.requestHoppers(new Date().getTime());
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ ...hopperColStyle }}></div>
                    <div style={{ ...headStyle, ...hopperColStyle }}>{getText( "CashRegisterType")}</div>
                    <div style={{ ...headStyle, ...hopperColStyle }}>{getText( "CashRegisterLevel")}</div>
                    <div style={{ flex: 1, maxWidth: 50, minWidth: 50 }}></div>
                    <div style={{ ...headStyle, ...hopperColStyle, flex: 4, minWidth: 200, maxWidth: 200 }}>{getText( "CashRegisterReloading")}</div>
                    <div style={{ flex: 1, maxWidth: 20, minWidth: 20 }}></div>
                    <div style={{ ...headStyle, ...hopperColStyle, flex: 4, minWidth: 200, maxWidth: 200  }}>{getText( "CashRegisterPartialEmpty")}</div>
                    <div style={{ flex: 1, maxWidth: 20, minWidth: 20 }}></div>
                    <div style={{ ...headStyle, ...hopperColStyle, flex: 4, minWidth: 200, maxWidth: 200  }}>{getText( "CashRegisterResetHoppers")}</div>
                </div>
                <div>
                    <div style={hopperTitleStyle}>{getText( "CashRegisterHoppers")}</div>
                    <div>
                        {this.props.hoppers.hoppers.filter(x => this.props.seed.moneys[x.moneyId].type === "Coin")
                            .map(hopper => <HopperControl key={hopper.hmiMoneyHopperId} hopper={hopper}
                                seed={this.props.seed}
                                disabled={this.props.disabled}
                                hoppersEmpty={this.props.hoppersEmpty[hopper.hmiMoneyHopperId]}
                                hoppersRecharge={this.props.hoppersRecharge[hopper.hmiMoneyHopperId]}
                                requestEmptyHoppers={(requestTime, id) => this.props.requestEmptyHoppers(requestTime, id)}
                                requestRechargeHopper={(requestTime, id) => this.props.requestRechargeHopper(requestTime, id)}
                                requestResetHopper={(requestTime, id) => this.props.requestResetHopper(requestTime, id)}
                                updateHopperEmpty={(id, value) => this.props.updateHopperEmpty(id, value)}
                                updateHopperRecharge={(id, value) => this.props.updateHopperRecharge(id, value)} />)}
                    </div>
                </div>
                <div>
                    {this.props.hoppers.hoppers.filter(x => this.props.seed.moneys[x.moneyId].type === "Bill").length != 0 &&
                        <div style={hopperTitleStyle}>{getText( "CashRegisterBanknoteReader")}</div>}
                    <div>
                        {this.props.hoppers.hoppers.filter(x => this.props.seed.moneys[x.moneyId].type === "Bill")
                            .map(hopper => <HopperControl key={hopper.hmiMoneyHopperId} hopper={hopper}
                                seed={this.props.seed}
                                disabled={this.props.disabled}
                                hoppersEmpty={this.props.hoppersEmpty[hopper.hmiMoneyHopperId]}
                                hoppersRecharge={this.props.hoppersRecharge[hopper.hmiMoneyHopperId]}
                                requestEmptyHoppers={(requestTime, id) => this.props.requestEmptyHoppers(requestTime, id)}
                                requestRechargeHopper={(requestTime, id) => this.props.requestRechargeHopper(requestTime, id)}
                                requestResetHopper={(requestTime, id) => this.props.requestResetHopper(requestTime, id)}
                                updateHopperEmpty={(id, value) => this.props.updateHopperEmpty(id, value)}
                                updateHopperRecharge={(id, value) => this.props.updateHopperRecharge(id, value)} />)}
                    </div>
                </div>
            </div>
        );
    }
}

interface HopperControlProps {
    hopper: Api.HmiMoneyHopperModel;
    seed: Api.SeedMngModel;
    hoppersRecharge: CashStore.HopperActionState;
    hoppersEmpty: CashStore.HopperActionState;
    disabled: boolean;
    updateHopperRecharge: (id: number, value: number) => any;
    requestRechargeHopper: (requestTime: number, id: number) => any;
    updateHopperEmpty: (id: number, value: number) => any;
    requestEmptyHoppers: (requestTime: number, id: number) => any;
    requestResetHopper: (requestTime: number, id: number) => any;
}

class HopperControl extends React.Component<HopperControlProps, {}>
{
    get getHopperStyle(): React.CSSProperties {
        return {
            ...hopperColStyle,
            backgroundColor: this.props.seed.moneys[this.props.hopper.moneyId].type === "Coin"
                ? "#BFFFBC"
                : "rgb(159, 113, 248)"
        };
    };

    public render() {
        return (
            <div key={this.props.hopper.hmiMoneyHopperId} style={{ color: "#181F3A", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ ...this.getHopperStyle }}>{this.props.hopper.name}</div>
                <div style={{ ...this.getHopperStyle }}>{this.props.seed.moneys[this.props.hopper.moneyId].value.toFixed(2)} €</div>
                <div style={{ ...this.getHopperStyle }}>{this.props.hopper.quantity}</div>
                <div style={{ flex: 1, maxWidth: 50, minWidth: 50 }}></div>
                <div style={{
                    ...hopperColStyle, flex: 2, display: "flex",
                    flexDirection: "row", alignItems: "center",
                    minWidth: 200, maxWidth: 200
                }}>
                    <input style={{ flex: 1, minWidth: 60, maxWidth: 60 }}
                        value={this.props.hoppersRecharge
                            ? this.props.hoppersRecharge.value
                            : 0}
                        onChange={(e) => this.props.updateHopperRecharge(this.props.hopper.hmiMoneyHopperId, parseInt(e.target.value))}
                        min={0}
                        type="number" className="form-control input-sm" />
                    <button className="btn btn-secondary btn-sm" style={{ flex: 1, minWidth: 140, maxWidth: 140 }}
                        onClick={(e) => this.props.requestRechargeHopper(new Date().getTime(), this.props.hopper.hmiMoneyHopperId)}
                        disabled={(this.props.hoppersRecharge
                            && this.props.hoppersRecharge.isLoading)
                            || this.props.disabled}>
                        {getText( "CashRegisterReload")} {this.props.hopper.name}
                    </button>
                </div>
                <div style={{ flex: 1, maxWidth: 20, minWidth: 20 }}></div>
                <div style={{
                    ...hopperColStyle, flex: 2, display: "flex",
                    flexDirection: "row", alignItems: "center",
                    minWidth: 200, maxWidth: 200
                }}>
                    <input style={{ flex: 1, minWidth: 60, maxWidth: 60 }}
                        value={this.props.hoppersEmpty
                            ? this.props.hoppersEmpty.value
                            : 0}
                        onChange={(e) => this.props.updateHopperEmpty(this.props.hopper.hmiMoneyHopperId, parseInt(e.target.value))}
                        min={0}
                        type="number" className="form-control input-sm" />
                    <button className="btn btn-secondary btn-sm" style={{ flex: 1, minWidth: 140, maxWidth: 140 }}
                        onClick={(e) => this.props.requestEmptyHoppers(new Date().getTime(), this.props.hopper.hmiMoneyHopperId)}
                        disabled={(this.props.hoppersEmpty
                            && this.props.hoppersEmpty.isLoading)
                            || this.props.disabled}>
                        {getText( "CashRegisterEmpty")} {this.props.hopper.name}
                    </button>
                </div>
                <div style={{ flex: 1, maxWidth: 20, minWidth: 20 }}></div>
                <div style={{
                    ...hopperColStyle, flex: 2, display: "flex",
                    flexDirection: "row", alignItems: "center",
                    minWidth: 200, maxWidth: 200
                }}>
                    <button className="btn btn-secondary btn-sm"
                        onClick={(e) => this.props.requestResetHopper(new Date().getTime(), this.props.hopper.hmiMoneyHopperId)}
                        disabled={(this.props.hoppersEmpty
                            && this.props.hoppersEmpty.isLoading)
                        || this.props.disabled}
                        style={{ flex: 1 }}>
                        {getText( "CashRegisterReset")} {this.props.hopper.name}
                    </button>
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.cash,
    seed: state.seed.seed,
    disabled: !canUpdate(state)
} as CashHoppersControlOwnProps), CashStore.actionCreators)(CashHoppersControl  as any);