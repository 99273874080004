import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/texts';
import _ from 'lodash';


type MenuCategoryTranslationsFormProps = ReduxForm.InjectedFormProps<Api.MenuCategoryTranslationModel, {}, {}>
    & { submitting: boolean; pristine: boolean; }
    & MenuCategoryTranslationsFormOwnProps
    & MenuCategoryTranslationsFormExternalProps;


interface MenuCategoryTranslationsFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.MenuCategoryModel) => any;
}

interface MenuCategoryTranslationsFormOwnProps {
    languagesState: Array<Api.LanguageModel>;
}

const inputStyle: React.CSSProperties = {minWidth: 300, maxWidth: 300, height: 35};

class MenuCategoryTranslationsForm extends React.Component<MenuCategoryTranslationsFormProps, {}> {

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    public render() {
        return (
            <FormField.BaseForm 
            {...this.props}
            className="form-vertical" 
            style={{ maxWidth: '50%' }}>
            <div className="row" style={{padding: '0 5px', textAlign: 'left', marginTop: 5}}>
                <div className="col-md-12 form-group">
                    <label className="control-label">{getText( "SettingsTextTransHmiLanguage")}  </label>
                    <ReduxForm.Field 
                        style={inputStyle}
                        name={'languageId'}
                        validate={[FormField.required]}
                        component={FormField.getSelectField(
                            this.props.languagesState
                                .map(x => ({
                                    label: x.name,
                                    value: x.languageId
                                })))} 
                    />
                </div>
                <div className="col-md-12 form-group">
                    <label className="control-label">{getText( "SettingsTextTransHmi")}  </label>
                        <ReduxForm.Field
                            style={{ textTransform: 'uppercase'}}
                            name="translation"
                            type={"text"}
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                </div>
                <div className="col-md-12 form-group">
                    <button className="btn btn-primary"
                        style={{width: '100%', height: 40, fontWeight: 'bold', fontSize: 15, 
                            backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                            color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                            borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                        }}
                        type={"submit"}
                        disabled={this.props.submitting || this.props.pristine}>
                        {this.props.actionName}
                    </button>
                </div>
            </div>
        </FormField.BaseForm>
        );
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createMenuCategoryTranslations'));

let createForm = ReduxForm.reduxForm({
    form: 'createMenuCategoryTranslations',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(MenuCategoryTranslationsForm as any) as any;

const createSelector = ReduxForm.formValueSelector('createMenuCategoryTranslations');

export const CreateMenuCategoryTranslationsForm = connect((state: ApplicationState) => {
    return { 
        initialValues: {
            menuCategoryId: state.menuCategories.selectedMenuCategoryId
         } as Api.MenuCategoryTranslationModel,
         languagesState: _.values(state.seed.seed.languages),
     } as MenuCategoryTranslationsFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<MenuCategoryTranslationsFormExternalProps>;


let updateForm = ReduxForm.reduxForm({
    form: 'updateMenuCategoryTranslations',
    enableReinitialize: true,
    destroyOnUnmount: true
})(MenuCategoryTranslationsForm as any) as any;

const updateSelector = ReduxForm.formValueSelector('updateMenuCategoryTranslations');

export const UpdateMenuCategoryTranslationsForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.menuCategories.selectedMenuCategoryId && state.menuCategories.selectedMenuCategoryTranslationId
            ? state.seed.seed.menuCategories[state.menuCategories.selectedMenuCategoryId].menuCategoryTranslations.find(x => x.menuCategoryTranslationId == state.menuCategories.selectedMenuCategoryTranslationId)
            : {},
        languagesState: _.values(state.seed.seed.languages),
    } as MenuCategoryTranslationsFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<MenuCategoryTranslationsFormExternalProps>;