import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders, filterWithoutAccents } from '../utils/utils';
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { getText } from '../utils/texts';

export interface OfferState {
    isLoading: boolean;
    requestTime?: number;
    loadedTime?: number;
    filter: OfferFilter;
    promotionDefaultPriceNameId: { [id: number]: number };
    experityDefaultPriceNameId?: number;
    experityDdmDefaultPriceNameId?: number;
    itemOfferResult?: Api.ItemOfferResultModel;
    itemOfferStates: { [storeItemId: number]: { [id: number]: ItemOfferState } }
    itemOfTheDayState: {
        isLoading: boolean;
        requestTime?: number;
    }
    promotionDate: Date;
    DLCDays: number;
    DDMDays: number;
    isNewDateSelected: boolean;
    dialogOpenOffer: {
        isOpen: boolean;
        message?: string;
    };
}

export interface ItemOfferState {
    requestTime?: number;
    daysBeforeExpirity?: number;
    isLoading: boolean;
    isChecked: boolean;
    priceNameId: number;
    dateEnd: Date;
}

export interface OfferFilter {
    familyId?: number;
    subFamilyId?: number;
    productName?: string;
    hasOffer?: boolean;
}

interface UpdateFilter {
    type: "UPDATE_OFFER_FILTER";
    payload: { value: OfferFilter }
}
interface UpdatePromotionDefaultPriceNameId {
    type: "UPDATE_PROMOTION_DEFAULT_PRICENAMEID";
    payload: { value: number; promotionId: number; }
}
interface UpdateExpirityDefaultPriceNameId {
    type: "UPDATE_EXPIRITY_DEFAULT_PRICENAMEID";
    payload: { value: number; }
}
interface UpdateExpirityDdmDefaultPriceNameId {
    type: "UPDATE_EXPIRITYDDM_DEFAULT_PRICENAMEID";
    payload: { value: number; }
}

interface RequestItemOffers {
    type: "REQUEST_ITEMOFFERS"
    payload: { requestTime: number; }
}

interface ReceiveItemOffers {
    type: "RECEIVE_ITEMOFFERS"
    payload: {
        requestTime: number;
        itemOfferResult?: Api.ItemOfferResultModel
    }
    error?: any;
}

interface RequestCreateItemPromotion {
    type: "REQUEST_CREATE_ITEMPROMOTION";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
    }
}
interface ReceiveCreateItemPromotion {
    type: "RECEIVE_CREATE_ITEMPROMOTION";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
        itemPromotion?: Api.ItemPromotionModel;
    }
    error?: any;
}

interface RequestUpdateItemOfTheDay {
    type: "REQUEST_UPDATE_ITEMOFTHEDAY";
    payload: {
        requestTime: number;
    };
}
interface ReceiveUpdateItemOfTheDay {
    type: "RECEIVE_UPDATE_ITEMOFTHEDAY";
    payload: {
        requestTime: number;
        itemOfTheDay?: Api.ItemOfTheDayModel;
    };
    error?: any;
}

interface RequestCreateItemExpirity {
    type: "REQUEST_CREATE_ITEMEXPIRITY";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
    }
}
interface ReceiveCreateItemExpirity {
    type: "RECEIVE_CREATE_ITEMEXPIRITY";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
        itemExpirityOffer?: Api.ItemExpirityOfferModel;
    }
    error?: any;
}

interface RequestUpdateItemPromotion {
    type: "REQUEST_UPDATE_ITEMPROMOTION";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
    }
}
interface ReceiveUpdateItemPromotion {
    type: "RECEIVE_UPDATE_ITEMPROMOTION";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
        itemPromotion?: Api.ItemPromotionModel;
    }
    error?: any;
}

interface RequestUpdateItemExpirity {
    type: "REQUEST_UPDATE_ITEMEXPIRITY";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
    }
}
interface ReceiveUpdateItemExpirity {
    type: "RECEIVE_UPDATE_ITEMEXPIRITY";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
        itemExpirityOffer?: Api.ItemExpirityOfferModel;
    }
    error?: any;
}

interface RequestDeleteItemOffer {
    type: "REQUEST_DELETE_ITEMOFFER";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
    }
}
interface ReceiveDeleteItemOffer {
    type: "RECEIVE_DELETE_ITEMOFFER";
    payload: {
        requestTime: number;
        storeItemId: number;
        promotionId: number;
        itemOfferId?: number;
    }
    error?: any;
}

interface UpdateCheckedItemOffer {
    type: "UPDATE_CHECKED_ITEMOFFER";
    payload: {
        value: boolean;
        storeItemId: number;
        promotionId: number;
    }
}

interface UpdatePriceNameIdItemOffer {
    type: "UPDATE_PRICENAMEID_ITEMOFFER";
    payload: {
        value: number;
        storeItemId: number;
        promotionId: number;
    }
}

interface UpdateDateEndItemOffer {
    type: "UPDATE_DATEEND_ITEMOFFER";
    payload: {
        value: Date;
        storeItemId: number;
        promotionId: number;
    }
}

interface UpdateDaysBeforeExpirityItemOffer {
    type: "UPDATE_DAYSBEFOREEXPIRITY_ITEMOFFER";
    payload: {
        value: number;
        storeItemId: number;
        promotionId: number;
    }
}
interface UpdateItemExpirityOffer {
    type: "UPDATE_ITEMEXPIRITYOFFER";
    payload: {
        storeItemId: number;
        promotionId: number;
        daysBeforeExpirty: number;
        priceNameId: number;
    }
}
interface UpdateItemPromotionOffer {
    type: "UPDATE_ITEMPROMOTIONOFFER";
    payload: {
        storeItemId: number;
        promotionId: number;
        dateEnd: Date;
        priceNameId: number;
    }
}

interface ResetItemOfferState {
    type: "RESET_ITEMOFFER_STATE";
    payload: {
        storeItemId: number;
        promotionId: number;
    }
}

interface UpdatePromotionDate {
    type: "UPDATE_PROMOTION_DATE";
    payload: { value: Date }
}

interface UpdateNewDateSelected {
    type: "UPDATE_NEW_DATE_SELECTED";
    payload: { value: boolean }
}

interface UpdateDLCDays {
    type: "UPDATE_DLC_DAYS";
    payload: { value: any }
}

interface UpdateDDMDays {
    type: "UPDATE_DDM_DAYS";
    payload: { value: any }
}

interface OpenDialogModaOffer {
    type: "OPEN_OFFER_MODAL";
    payload: { message: string }
}
interface CloseDialogModaOffer {
    type: "CLOSE_OFFER_MODAL";
}


type KnownAction = RequestItemOffers
    | UpdateFilter
    | ReceiveItemOffers
    | UpdateCheckedItemOffer
    | UpdatePriceNameIdItemOffer
    | UpdateDateEndItemOffer
    | UpdateDaysBeforeExpirityItemOffer
    | UpdateItemExpirityOffer
    | UpdateItemPromotionOffer
    | ResetItemOfferState
    | RequestCreateItemPromotion
    | ReceiveCreateItemPromotion
    | RequestCreateItemExpirity
    | ReceiveCreateItemExpirity
    | RequestUpdateItemPromotion
    | ReceiveUpdateItemPromotion
    | RequestUpdateItemExpirity
    | ReceiveUpdateItemExpirity
    | RequestDeleteItemOffer
    | ReceiveDeleteItemOffer
    | UpdatePromotionDefaultPriceNameId
    | UpdateExpirityDefaultPriceNameId
    | UpdateExpirityDdmDefaultPriceNameId
    | RequestUpdateItemOfTheDay
    | ReceiveUpdateItemOfTheDay
    | UpdatePromotionDate
    | UpdateNewDateSelected
    | UpdateDLCDays
    | UpdateDDMDays
    | OpenDialogModaOffer
    | CloseDialogModaOffer
    ;

export const getDefaultDateEnd = (): Date => {
    return Moment().add("months", 1).startOf("day").toDate();
}

export const getPriceNameId = (itemOfferState: ItemOfferState,
    prices: Array<Api.PriceModel>,
    princeNames: Array<Api.PriceNameModel>,
    priceNameId?: number): number => {
    return (itemOfferState
        ? (itemOfferState.priceNameId
            || priceNameId)
        : priceNameId)
        || (princeNames.some(x => x.type === "Offer"
        && prices.some(y => y.priceNameId === x.priceNameId))
            && princeNames.find(x => x.type === "Offer"
                && prices.some(y => y.priceNameId === x.priceNameId)).priceNameId);
}

const requestCreateItemPromotion = (dispatch: (a: KnownAction) => void, getState: () => ApplicationState, requestTime: number, storeItemId: number, promotionId: number, priceNameId:number, dateEnd:Date): Promise<any> => {
    let api = new Api.OfferApi();
    let itemOfferState = (getState().offer.itemOfferStates[storeItemId] || {})[promotionId];
    let product = getState().seed.seed.products[storeItemId];

    const dateEndVal = dateEnd ? dateEnd : ((itemOfferState && itemOfferState.dateEnd) ? itemOfferState.dateEnd : getDefaultDateEnd());
    const priceNameVal = priceNameId ? priceNameId : (getPriceNameId(itemOfferState, product.prices, _.values(getState().seed.seed.priceNames), undefined))
    let fetchTask = api.createItemPromotion({
        model: {
            dateBegin: Moment().startOf("day").toDate(),
            dateEnd: dateEndVal,
            priceNameId: priceNameVal,
            promotionId: promotionId,
            storeItemId: storeItemId,
        }
    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(itemPromotion => {
            dispatch({
                type: "RECEIVE_CREATE_ITEMPROMOTION",
                payload: {
                    requestTime: requestTime,
                    storeItemId: storeItemId,
                    promotionId: promotionId,
                    itemPromotion: itemPromotion
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_CREATE_ITEMPROMOTION",
                payload: {
                    requestTime: requestTime,
                    storeItemId: storeItemId,
                    promotionId: promotionId
                },
                error: err
            });
            if (err.status === 404) {
                err.text().then(x => {
                    dispatch({ type: "OPEN_OFFER_MODAL", payload: { message: `${getText( "OfferDialogMsg")} - ${product.name}` } });
                })
            }
        });

    dispatch({
        type: "REQUEST_CREATE_ITEMPROMOTION",
        payload: {
            requestTime: requestTime,
            storeItemId: storeItemId,
            promotionId: promotionId
        }
    });
    return fetchTask;
}

const requestCreateItemExpirity = (dispatch: (a: KnownAction) => void, getState: () => ApplicationState, requestTime: number, storeItemId: number, promotionId: number): Promise<any> => {
    let api = new Api.OfferApi();
    let itemOfferState = (getState().offer.itemOfferStates[storeItemId] || {})[promotionId]
        || { daysBeforeExpirity: 3 } as any;
    let product = getState().seed.seed.products[storeItemId];

    let fetchTask = api.createItemExpirityOffer({
        model: {
            dateBegin: Moment().startOf("day").toDate(),
            dateEnd: itemOfferState.dateEnd,
            priceNameId: getPriceNameId(itemOfferState,
                product.prices,
                _.values(getState().seed.seed.priceNames),
                undefined),
            daysBeforeExpirity: itemOfferState.daysBeforeExpirity || 3,
            storeItemId: storeItemId,
        }
    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(itemExpirityOffer => {
            dispatch({
                type: "RECEIVE_CREATE_ITEMEXPIRITY",
                payload: {
                    requestTime: requestTime,
                    storeItemId: storeItemId,
                    promotionId: promotionId,
                    itemExpirityOffer: itemExpirityOffer
                }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_CREATE_ITEMEXPIRITY",
                payload: {
                    requestTime: requestTime,
                    storeItemId: storeItemId,
                    promotionId: promotionId
                },
                error: err
            });
            if (err.status === 404) {
                err.text().then(x => {
                    dispatch({ type: "OPEN_OFFER_MODAL", payload: { message: `${getText( "OfferDialogMsg")} - ${product.name}` } });
                })
            }
        });

    dispatch({
        type: "REQUEST_CREATE_ITEMEXPIRITY",
        payload: {
            requestTime: requestTime,
            storeItemId: storeItemId,
            promotionId: promotionId
        }
    });
    return fetchTask;
}

export const actionCreators = {
    requestItemOfferResult: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.OfferApi();
        let fetchTask = api.getItemOffers({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(itemOfferResult => {
            dispatch({
                type: "RECEIVE_ITEMOFFERS",
                payload: {
                    requestTime: requestTime,
                    itemOfferResult: itemOfferResult
                }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_ITEMOFFERS",
                payload: {
                    requestTime: requestTime,
                },
                error: err
            });
        });

        dispatch({
            type: "REQUEST_ITEMOFFERS",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreateItemPromotion: (requestTime: number, storeItemId: number, promotionId: number,priceNameId:number, dateEnd: Date): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestCreateItemPromotion(dispatch, getState, requestTime, storeItemId, promotionId,priceNameId, dateEnd);
    },
    requestCreateItemExpirity: (requestTime: number, storeItemId: number, promotionId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestCreateItemExpirity(dispatch, getState, requestTime, storeItemId, promotionId);
    },
    requestUpdateItemPromotion: (requestTime: number, storeItemId: number, promotionId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.OfferApi();
        let itemOfferState = (getState().offer.itemOfferStates[storeItemId] || {})[promotionId];
        if (!itemOfferState)
            return Promise.reject("No offer state");

        let itemOffer = getState().offer.itemOfferResult
            .itemPromotions[storeItemId].find(x => x.promotionId == promotionId);
        let fetchTask = api.updateItemPromotion({
            model: {
                ...itemOffer,
                priceNameId: itemOfferState.priceNameId
                    || itemOffer.priceNameId,
                dateEnd: itemOfferState.dateEnd
                    || itemOffer.dateEnd,
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(itemPromotion => {
                dispatch({
                    type: "RECEIVE_UPDATE_ITEMPROMOTION",
                    payload: {
                        requestTime: requestTime,
                        storeItemId: storeItemId,
                        promotionId: promotionId,
                        itemPromotion: itemPromotion
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_ITEMPROMOTION",
                    payload: {
                        requestTime: requestTime,
                        storeItemId: storeItemId,
                        promotionId: promotionId,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_ITEMPROMOTION",
            payload: {
                requestTime: requestTime,
                storeItemId: storeItemId,
                promotionId: promotionId
            }
        });
        return fetchTask;
    },
    requestUpdateItemExpirity: (requestTime: number, storeItemId: number, promotionId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.OfferApi();
        let itemOfferState = (getState().offer.itemOfferStates[storeItemId] || {})[promotionId];
        if (!itemOfferState)
            return Promise.reject("No offer state");

        let itemOffer = getState().offer.itemOfferResult
            .itemExpirityOffers[storeItemId][promotionId];
        let fetchTask = api.updateItemExpirityOffer({
            model: {
                ...itemOffer,
                priceNameId: itemOfferState.priceNameId
                    || itemOffer.priceNameId,
                dateEnd: itemOfferState.dateEnd
                    || itemOffer.dateEnd,
                daysBeforeExpirity: itemOfferState.daysBeforeExpirity
                    || itemOffer.daysBeforeExpirity
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(itemExpirityOffer => {
                dispatch({
                    type: "RECEIVE_UPDATE_ITEMEXPIRITY",
                    payload: {
                        requestTime: requestTime,
                        storeItemId: storeItemId,
                        promotionId: promotionId,
                        itemExpirityOffer: itemExpirityOffer
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_ITEMEXPIRITY",
                    payload: {
                        requestTime: requestTime,
                        storeItemId: storeItemId,
                        promotionId: promotionId,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_ITEMEXPIRITY",
            payload: {
                requestTime: requestTime,
                storeItemId: storeItemId,
                promotionId: promotionId
            }
        });
        return fetchTask;
    },
    requestDeleteItemOffer: (requestTime: number, storeItemId: number, promotionId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.OfferApi();
        let itemOffer;
        if (promotionId === 0) {
            itemOffer = (getState().offer.itemOfferResult
                .itemExpirityOffers[storeItemId] || [])[0];
        } else {
            itemOffer = (getState().offer.itemOfferResult
                    .itemPromotions[storeItemId] || [])
                    .find(x => x.promotionId === promotionId);
        }

        let fetchTask = api.deleteItemOffer({
            id: itemOffer.itemOfferId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_DELETE_ITEMOFFER",
                    payload: {
                        requestTime: requestTime,
                        storeItemId: storeItemId,
                        promotionId: promotionId,
                        itemOfferId: itemOffer.itemOfferId
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_ITEMOFFER",
                    payload: {
                        requestTime: requestTime,
                        storeItemId: storeItemId,
                        promotionId: promotionId
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_DELETE_ITEMOFFER",
            payload: {
                requestTime: requestTime,
                storeItemId: storeItemId,
                promotionId: promotionId
            }
        });
        return fetchTask;
    },
    requestUpdateItemOfTheDay: (requestTime: number, storeItemId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.OfferApi();
        let task = api.updateItemOfTheDay({
            model: {
                dateBegin: Moment("01-01-2000", "DD-MM-YYYY").toDate(),
                dateEnd: Moment("01-01-2500", "DD-MM-YYYY").toDate(),
                storeItemId: storeItemId
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(itemOfTheDay => {
                dispatch({
                    type: "RECEIVE_UPDATE_ITEMOFTHEDAY",
                    payload: { requestTime: requestTime, itemOfTheDay: itemOfTheDay }
                });
            }).catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_ITEMOFTHEDAY",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_UPDATE_ITEMOFTHEDAY", payload: { requestTime: requestTime } });
        return task;
    },
    updateCheckedItemOffer: (storeItemId: number, promotionId: number, value: boolean) => <UpdateCheckedItemOffer>{
        type: "UPDATE_CHECKED_ITEMOFFER",
        payload: {
            storeItemId: storeItemId,
            value: value,
            promotionId: promotionId
        }
    },
    updatePriceNameIdItemOffer: (storeItemId: number, promotionId: number, value: number) => <UpdatePriceNameIdItemOffer>{
        type: "UPDATE_PRICENAMEID_ITEMOFFER",
        payload: {
            storeItemId: storeItemId,
            value: value,
            promotionId: promotionId
        }
    },
    updateDateEndItemOffer: (storeItemId: number, promotionId: number, value: Date) => <UpdateDateEndItemOffer>{
        type: "UPDATE_DATEEND_ITEMOFFER",
        payload: {
            storeItemId: storeItemId,
            value: value,
            promotionId: promotionId
        }
    },
    updateDaysBeforeExpirityItemOffer: (storeItemId: number, promotionId: number, value: number) => <UpdateDaysBeforeExpirityItemOffer>{
        type: "UPDATE_DAYSBEFOREEXPIRITY_ITEMOFFER",
        payload: {
            storeItemId: storeItemId,
            value: value,
            promotionId: promotionId
        }
    },
    updateItemExpirityOffer: (storeItemId: number, promotionId: number, daysBeforeExpirity: number, priceNameId: number) => <UpdateItemExpirityOffer>{
        type: "UPDATE_ITEMEXPIRITYOFFER",
        payload: {
            storeItemId: storeItemId,
            daysBeforeExpirty: daysBeforeExpirity,
            priceNameId: priceNameId,
            promotionId: promotionId
        }
    },
    updateItemPromotionOffer: (storeItemId: number, promotionId: number, dateEnd: Date, priceNameId: number) => <UpdateItemPromotionOffer>{
        type: "UPDATE_ITEMPROMOTIONOFFER",
        payload: {
            storeItemId: storeItemId,            
            promotionId: promotionId,
            dateEnd: dateEnd,
            priceNameId: priceNameId
        }
    },
    resetItemOfferState: (storeItemId: number, promotionId: number) => <ResetItemOfferState>{
        type: "RESET_ITEMOFFER_STATE",
        payload: {
            storeItemId: storeItemId,
            promotionId: promotionId
        }
    },
    updateOfferFilter: (value: OfferFilter) => <UpdateFilter>{
        type: "UPDATE_OFFER_FILTER", payload: { value: value }
    },
    updatePromotionDefaultPriceNameId: (value: number, promotionId: number) => <UpdatePromotionDefaultPriceNameId>{
        type: "UPDATE_PROMOTION_DEFAULT_PRICENAMEID",
        payload: { value: value, promotionId: promotionId }
    },
    updateExpirityDefaultPriceNameId: (value: number) => <UpdateExpirityDefaultPriceNameId>{
        type: "UPDATE_EXPIRITY_DEFAULT_PRICENAMEID",
        payload: { value: value }
    },
    updateExpirityDdmDefaultPriceNameId: (value: number) => <UpdateExpirityDdmDefaultPriceNameId>{
        type: "UPDATE_EXPIRITYDDM_DEFAULT_PRICENAMEID",
        payload: { value: value }
    },
    
    requestCreateAllItemExpirity: (): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let itemExpirityOffers = getState().offer.itemOfferResult.itemExpirityOffers;
        let products = productFilteredSelector(getState())
            .filter(x => x.expirityType === "Normal")
            .filter(x => !itemExpirityOffers[x.storeItemId]
                || itemExpirityOffers[x.storeItemId].length === 0);
        let tasks = [];
        products.forEach(product => {
            tasks.push(requestCreateItemExpirity(dispatch, getState, new Date().getTime(), product.storeItemId, 0));
        });
        return Promise.all(tasks);
    },
    requestCreateAllItemExpirityDdm: (): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let itemExpirityOffers = getState().offer.itemOfferResult.itemExpirityOffers;
        let products = productFilteredSelector(getState())
            .filter(x => x.expirityType === "Ddm")
            .filter(x => !itemExpirityOffers[x.storeItemId]
                || itemExpirityOffers[x.storeItemId].length === 0);
        let tasks = [];
        products.forEach(product => {
            tasks.push(requestCreateItemExpirity(dispatch, getState, new Date().getTime(), product.storeItemId, 0));
        });
        return Promise.all(tasks);
    },
    updatePromotionDate: (date: Date) => <UpdatePromotionDate>{
        type: "UPDATE_PROMOTION_DATE",
        payload: { value: date }
    },
    updateNewDateSelected: (value: boolean) => <UpdateNewDateSelected>{
        type: "UPDATE_NEW_DATE_SELECTED",
        payload: { value: value }
    },
    updateDLCDays: (value: any) => <UpdateDLCDays>{
        type: "UPDATE_DLC_DAYS",
        payload: { value: value }
    },
    updateDDMDays: (value: any) => <UpdateDDMDays>{
        type: "UPDATE_DDM_DAYS",
        payload: { value: value }
    },
    closeDialogModalOffer: () => <CloseDialogModaOffer>{ type: "CLOSE_OFFER_MODAL" }
};

const unloadedState: OfferState = {
    isLoading: false,
    itemOfferStates: {

    },
    filter: {

    },
    promotionDefaultPriceNameId: {},
    itemOfTheDayState: {
        isLoading: false,
    },
    promotionDate: undefined,
    DLCDays: undefined,
    DDMDays: undefined,
    isNewDateSelected: false,
    dialogOpenOffer: {
        isOpen: false
    }
};

const productRailsSelector = (state: ApplicationState) => {
    let plannogramId = state.stock.stockStatus
        ? state.stock.stockStatus.planograms
            .find(x => x.active).planogramId
        : 0;
    return state.stock.stockStatus
        ? state.stock.stockStatus.productRails
            .filter(x => x.planogramId === plannogramId)
        : []
};
const productsSelector = (state: ApplicationState) => state.seed.seed ? state.seed.seed.products : {};

const itemOfferResultSelector = (state: ApplicationState) => state.offer.itemOfferResult;
const filterSelector = (state: ApplicationState) => state.offer.filter;
const subFamiliesSelector = (state: ApplicationState) => state.seed.seed.subFamilies;

export const productInRailsSelector = createSelector(productRailsSelector,
    productsSelector,
    (productRails, products) => _.sortBy(_.uniq(productRails.map(x => x.productId))
        .map(x => products[x]), x => x.name));

export const productFilteredSelector = createSelector(filterSelector,
    subFamiliesSelector,
    productInRailsSelector,
    itemOfferResultSelector, (filter, subFamilies, products, itemOfferResult) => {
        return products
            .filter(x => (!filter.familyId
                || x.productSubFamilies
                    .some(y => subFamilies[y.subFamilyId].familyId === filter.familyId))
                && (!filter.subFamilyId
                    || x.productSubFamilies
                        .some(y => y.subFamilyId === filter.subFamilyId))
                && (!filter.productName
                    || filterWithoutAccents(x.name).toLowerCase()
                        .includes(filterWithoutAccents(filter.productName).toLowerCase()))
                && (filter.hasOffer === undefined
                    || (((itemOfferResult
                        && itemOfferResult.itemPromotions[x.storeItemId]
                        && itemOfferResult.itemPromotions[x.storeItemId].length !== 0)
                || (itemOfferResult
                            && itemOfferResult.itemExpirityOffers[x.storeItemId]
                            && itemOfferResult.itemExpirityOffers[x.storeItemId].length !== 0) ? true : false) === filter.hasOffer)))
    });


export const productPromoFilteredSelector = createSelector( filterSelector,
    productFilteredSelector,
    itemOfferResultSelector, (filter, products, itemOfferResult) => {
        return products
            .filter(x => 
                (filter.hasOffer === undefined
                    || (((itemOfferResult
                        && itemOfferResult.itemPromotions[x.storeItemId]
                        && itemOfferResult.itemPromotions[x.storeItemId].length !== 0) ? true : false) === filter.hasOffer)))
    });

export const productExpirityOfferFilteredSelector = createSelector( filterSelector,
    productFilteredSelector,
    itemOfferResultSelector, (filter, products, itemOfferResult) => {
        return products
            .filter(x => 
                (filter.hasOffer === undefined
                    || (((itemOfferResult
                        && itemOfferResult.itemExpirityOffers[x.storeItemId]
                        && itemOfferResult.itemExpirityOffers[x.storeItemId].length !== 0) ? true : false) === filter.hasOffer)))
});
    

const itemOfferRequestReducer = (state: OfferState,
    action: { payload: { requestTime: number; storeItemId: number; promotionId: number } }): OfferState => {
    return {
        ...state,
        itemOfferStates: {
            ...state.itemOfferStates,
            [action.payload.storeItemId]: {
                ...state.itemOfferStates[action.payload.storeItemId],
                [action.payload.promotionId]: {
                    ...(state.itemOfferStates[action.payload.storeItemId]
                        || {})[action.payload.promotionId],
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            }
        }
    };
}

const itemOfferReceivedIsLastSent = (state: OfferState,
    action: { payload: { requestTime: number; storeItemId: number; promotionId: number } }): boolean => {
    return !state.itemOfferStates[action.payload.storeItemId]
        || !state.itemOfferStates[action.payload.storeItemId]
        [action.payload.promotionId]
        || state.itemOfferStates[action.payload.storeItemId]
        [action.payload.promotionId].requestTime === action.payload.requestTime;
}

export const reducer: Reducer<OfferState> = (state: OfferState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "UPDATE_OFFER_FILTER":
            return {
                ...state,
                filter: action.payload.value
            };
        case "REQUEST_ITEMOFFERS":
            return {
                ...state,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_ITEMOFFERS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                itemOfferResult: action.error
                    ? state.itemOfferResult
                    : action.payload.itemOfferResult,
                loadedTime: action.error
                    ? state.requestTime
                    : action.payload.requestTime
            };
        case "UPDATE_CHECKED_ITEMOFFER":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            isChecked: action.payload.value,
                        }
                    }
                }
            };
        case "UPDATE_PRICENAMEID_ITEMOFFER":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            priceNameId: action.payload.value
                        }
                    }
                }
            };
        case "UPDATE_DATEEND_ITEMOFFER":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            dateEnd: action.payload.value
                        }
                    }
                }
            };
        case "UPDATE_DAYSBEFOREEXPIRITY_ITEMOFFER":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            daysBeforeExpirity: action.payload.value
                        }
                    }
                }
            };
        case "UPDATE_ITEMEXPIRITYOFFER":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            daysBeforeExpirity: action.payload.daysBeforeExpirty,
                            priceNameId: action.payload.priceNameId
                        }
                    }
                }
            }
        case "UPDATE_ITEMPROMOTIONOFFER":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            dateEnd: action.payload.dateEnd,
                            priceNameId: action.payload.priceNameId
                        }
                    }
                }
            }
        case "RESET_ITEMOFFER_STATE":
            return {
                ...state,
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: undefined
                    }
                }
            };
        case "REQUEST_CREATE_ITEMPROMOTION":
            return itemOfferRequestReducer(state, action);
        case "RECEIVE_CREATE_ITEMPROMOTION":
            if (!itemOfferReceivedIsLastSent(state, action))
                return state;

            return {
                ...state,
                itemOfferResult: {
                    ...state.itemOfferResult,
                    itemPromotions: action.error
                        ? state.itemOfferResult.itemPromotions
                        : {
                            ...state.itemOfferResult.itemPromotions,
                            [action.payload.itemPromotion.storeItemId]: (state.itemOfferResult
                                .itemPromotions[action.payload.itemPromotion.storeItemId] || [])
                                .concat([action.payload.itemPromotion])
                        }
                },
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: undefined
                    }
                }
            };
        case "REQUEST_CREATE_ITEMEXPIRITY":
            return itemOfferRequestReducer(state, action);
        case "RECEIVE_CREATE_ITEMEXPIRITY":
            if (!itemOfferReceivedIsLastSent(state, action))
                return state;

            return {
                ...state,
                itemOfferResult: {
                    ...state.itemOfferResult,
                    itemExpirityOffers: action.error
                        ? state.itemOfferResult.itemExpirityOffers
                        : {
                            ...state.itemOfferResult.itemExpirityOffers,
                            [action.payload.itemExpirityOffer.storeItemId]: (state.itemOfferResult
                                .itemExpirityOffers[action.payload.itemExpirityOffer.storeItemId] || [])
                                .concat([action.payload.itemExpirityOffer])
                        }
                },
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: undefined
                    }
                }
            };
        case "REQUEST_UPDATE_ITEMPROMOTION":
            return itemOfferRequestReducer(state, action);
        case "RECEIVE_UPDATE_ITEMPROMOTION":
            return {
                ...state,
                itemOfferResult: {
                    ...state.itemOfferResult,
                    itemPromotions: action.error
                        ? state.itemOfferResult.itemPromotions
                        : {
                            ...state.itemOfferResult.itemPromotions,
                            [action.payload.itemPromotion.storeItemId]: (state.itemOfferResult
                                .itemPromotions[action.payload.itemPromotion.storeItemId] || [])
                                .map(x => x.itemOfferId === action.payload.itemPromotion.itemOfferId
                                    ? action.payload.itemPromotion
                                    : x)
                        }
                },
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: undefined
                    }
                }
            };
        case "REQUEST_UPDATE_ITEMEXPIRITY":
            return itemOfferRequestReducer(state, action);
        case "RECEIVE_UPDATE_ITEMEXPIRITY":
            return {
                ...state,
                itemOfferResult: {
                    ...state.itemOfferResult,
                    itemExpirityOffers: action.error
                        ? state.itemOfferResult.itemExpirityOffers
                        : {
                            ...state.itemOfferResult.itemExpirityOffers,
                            [action.payload.itemExpirityOffer.storeItemId]: (state.itemOfferResult
                                .itemExpirityOffers[action.payload.itemExpirityOffer.storeItemId] || [])
                                .map(x => x.itemOfferId === action.payload.itemExpirityOffer.itemOfferId
                                    ? action.payload.itemExpirityOffer
                                    : x)
                        }
                },
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: undefined
                    }
                }
            };
        case "REQUEST_DELETE_ITEMOFFER":
            return itemOfferRequestReducer(state, action);
        case "RECEIVE_DELETE_ITEMOFFER":
            return {
                ...state,
                itemOfferResult: {
                    ...state.itemOfferResult,
                    itemExpirityOffers: action.error
                        ? state.itemOfferResult.itemExpirityOffers
                        : {
                            ...state.itemOfferResult.itemExpirityOffers,
                            [action.payload.storeItemId]: (state.itemOfferResult
                                .itemExpirityOffers[action.payload.storeItemId] || [])
                                .filter(x => x.itemOfferId !== action.payload.itemOfferId)
                        },
                    itemPromotions: action.error
                        ? state.itemOfferResult.itemPromotions
                        : {
                            ...state.itemOfferResult.itemPromotions,
                            [action.payload.storeItemId]: (state.itemOfferResult
                                .itemPromotions[action.payload.storeItemId] || [])
                                .filter(x => x.itemOfferId !== action.payload.itemOfferId)
                        }
                },
                itemOfferStates: {
                    ...state.itemOfferStates,
                    [action.payload.storeItemId]: {
                        ...state.itemOfferStates[action.payload.storeItemId],
                        [action.payload.promotionId]: {
                            ...(state.itemOfferStates[action.payload.storeItemId]
                                || {})[action.payload.promotionId],
                            isLoading: false,
                            ...(action.error ? {} : { isChecked: false })
                        }
                    }
                }
            };
        case "UPDATE_PROMOTION_DEFAULT_PRICENAMEID":
            return {
                ...state,
                promotionDefaultPriceNameId: {
                    ...state.promotionDefaultPriceNameId,
                    [action.payload.promotionId]: action.payload.value
                }
            };
        case "UPDATE_EXPIRITY_DEFAULT_PRICENAMEID":
            return {
                ...state,
                experityDefaultPriceNameId: action.payload.value
            };
        case "UPDATE_EXPIRITYDDM_DEFAULT_PRICENAMEID":
            return {
                ...state,
                experityDdmDefaultPriceNameId: action.payload.value
            };
        case "REQUEST_UPDATE_ITEMOFTHEDAY":
            return {
                ...state,
                itemOfTheDayState: {
                    ...state.itemOfTheDayState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_ITEMOFTHEDAY":
            if (action.payload.requestTime !== state.itemOfTheDayState.requestTime)
                return state;

            return {
                ...state,
                itemOfTheDayState: {
                    ...state.itemOfTheDayState,
                    isLoading: false,
                },
                itemOfferResult: {
                    ...state.itemOfferResult,
                    itemOfTheDay: action.error
                        ? state.itemOfferResult.itemOfTheDay
                        : action.payload.itemOfTheDay
                }
            };
        case "UPDATE_PROMOTION_DATE":
            return {
                ...state,
                promotionDate: action.payload.value
            };
        case "UPDATE_NEW_DATE_SELECTED": 
            return {
                ...state,
                isNewDateSelected: action.payload.value
            };
        case "UPDATE_DLC_DAYS":
            return {
                ...state,
                DLCDays: action.payload.value
            };
        case "UPDATE_DDM_DAYS":
            return {
                ...state,
                DDMDays: action.payload.value
            };
        case "OPEN_OFFER_MODAL":
                return {
                    ...state,
                    dialogOpenOffer: {
                        isOpen: true,
                        message: action.payload.message
                    }
                };
        case "CLOSE_OFFER_MODAL":
                return {
                    ...state,
                    dialogOpenOffer: {
                        isOpen: false,
                        message: ""
                    }
                };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};