import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import Table from "./Table";
import * as ReactTable from "react-table";
import { getText } from '../utils/texts';

interface MenuDetailsProps {
    menu: Api.MenuModel;
    seed: Api.SeedMngModel;
    stockStatus: Api.StockStatusModel;
}


type ProductColumn = ReactTable.Column
    & { accessor: (d: Api.ProductModel) => any; }


export default class MenuDetails extends React.Component<MenuDetailsProps, {}> {

    getProductFromCategoryId(id: number): Array<Api.ProductModel> {
        return _.sortBy(_.values(this.props.seed.products)
            .filter(x => x.productMenuCategories.some(y => y.menuCategoryId === id)
                && x.prices.length !== 0), x => x.name);
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get productRails() {
        if (!this.props.stockStatus)
            return [];

        let planogramId = _.sortBy(
            this.props.stockStatus.planograms, x => !x.active)[0].planogramId;

        return this.props.stockStatus.productRails
            .filter(x => x.planogramId === planogramId);
    }

    public render() {
        return (
            <div style={{ padding: 10 }}>
                <div style={{}}>
                    {this.props.menu.menuCategoryMenus.map(x => {
                        let products = this.getProductFromCategoryId(x.menuCategoryId);

                        return <div style={{ marginBottom: 10 }} key={x.menuCategoryMenuId}>
                            <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>{this.props.seed.menuCategories[x.menuCategoryId].name}</div>
                            <div>
                                <Table key={"menuProducts-" + x.menuCategoryMenuId}
                                    style= {{
                                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                        color: this.isDarkMode() ? "#FFFFFF" : "#181F3A",
                                    }}
                                    showPagination={false}
                                    showPaginationBottom={false}
                                    pageSize={products.length}
                                    data={products}
                                    columns={[
                                        {
                                            id: "name",
                                            Header: <div>{getText( "MenuName")}</div>,
                                            accessor: d => d.name,
                                            style: {
                                                color: this.isDarkMode() ?  'white' : '#3B4370'
                                            }
                                        },
                                        {
                                            id: "price",
                                            Header: <div>{getText( "MenuPrice")}</div>,
                                            accessor: d => `${d.prices[0].value.toFixed(2)} ${this.props.seed.currencies[d.prices[0].currencyId].symbol}`,
                                            style: {
                                                color: this.isDarkMode() ?  'white' : '#3B4370'
                                            }
                                        },
                                        {
                                            id: "stockState",
                                            accessor: (d) => d,
                                            Header: <div>{getText( "StockState")}</div>,
                                            Cell: row => {
                                                let product = row.value as Api.ProductModel
                                                let color = 'green'
                                                let planogramMissed = !this.productRails
                                                            .some(pr => pr.productId === product.storeItemId);

                                                let stockMissed = this.productRails
                                                            .some(pr => pr.productId === product.storeItemId && 
                                                                (pr.productStocks && pr.productStocks.length <= 0));

                                                if(stockMissed)
                                                    color = 'orange';
                                                if(planogramMissed)
                                                    color = 'red';
                                                return (
                                                    <div style={{width: 25}}>
                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                            viewBox="0 0 512 512" fill={color}>
                                                                <path d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
                                                                    c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
                                                                    c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
                                                                    M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
                                                                    V317.241z"/>
                                                        </svg>
                                                    </div>
                                                );
                                            },
                                            width: 150
                                        }
                                    ] as Array<ProductColumn>} />
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            );
    }
}