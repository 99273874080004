import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as RobotStore from '../store/Robot';
import * as HmiApplicationStore from '../store/HmiApplication';
import * as NeoEventStore from '../store/NeoEvent';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import RobotAnimation from "./RobotAnimation";
import RobotIndicators from "./RobotIndicators";
import { canUpdate } from '../security/roles';
import CheckBox from "./CheckBox";
import { getText } from '../utils/texts';
import DialogModal from './DialogModal';
import RobotExtractors from './RobotExtractors';
import { validRobotPosition } from '../utils/utils';

type RobotControlHmiProps = RobotStore.RobotState
    & RobotControlHmiOwnProps
    & RobotControlHmiExternalProps
    & typeof RobotStore.actionCreators
    & typeof NeoEventStore.actionCreators
    ;

interface RobotControlHmiOwnProps {
    hmiStates: { [id: number]: HmiApplicationStore.HmiState };
    stockStatus: Api.StockStatusModel;
    disabled: boolean;
}

interface RobotControlHmiExternalProps {
    robotId: number;
}

const btnStyle: React.CSSProperties = { backgroundColor: "rgba(59, 67, 112, 0.25)", height: 30, fontSize: 14 };
const btnShevlingStyle: React.CSSProperties = { height: 12, width: 12, fontSize: 10 };

class RobotControlHmi extends React.Component<RobotControlHmiProps, {}> {

    get disabled(): boolean {
        return (_.values(this.props.hmiStates).length > 0
            && _.values(this.props.hmiStates)
                .some(x => !x.applicationState
                    || x.applicationState.applicationState === "Running"
                    || x.applicationState.applicationState === "Selling"))
            || !this.props.state
            || this.props.disabled;
    }

    isCommandLoading(command: string): boolean {
        return this.props.robotCommandStates[command]
            && this.props.robotCommandStates[command].isLoading;
    }

    get robot(): Api.RobotModel {
        return this.props.robotsState.robots
            .find(x => x.robotId === this.props.robotId)
            || {
            deliveryStations: [],
            robotConfiguration: {
                
            },
            robotExtractors: []
            };
    }

    get robotData(): Api.RobotData {
        return this.props.state
            ? this.props.state.robotData
            : {
                extractor1: {},
                extractor2: {},
                coord: {},
            };
    }

    getExtractorData(index: number): Api.ExtractorData {
        if (index === 0) {
            return this.robotData.extractor1 || {};
        } else {
            return this.robotData.extractor2 || {};
        }
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get areValidCoords(): boolean {
        let x = this.props.state ? this.props.state.robotData.coord.x : 0;
        let z = this.props.state ? this.props.state.robotData.coord.z : 0;
        let existExtra2 = this.props.robotsState.robots[0].robotExtractors.length == 1 ? false : true;
        let shelveG = this.props.robotsState ? this.props.robotsState.robots[0].robotConfiguration.shelveGap : 0;
        
        return this.props.stockStatus ? validRobotPosition(
            x, z, shelveG, this.props.stockStatus, existExtra2) : false;
    }

    public render() {
        return (
            <div className="robot-control">
                <div className="row">
                    <div className="col-md-12" style={{fontWeight: "bold", fontSize: 22, paddingRight: '2%'}}>
                        {getText( "MaintenanceTabRobot")}
                    </div>
                </div>
                <>
                <div className="preloader" style={{display: (this.isCommandLoading("initTotal") || this.isCommandLoading("initPartial")) ? "block" : "none"}}></div>
                <div style={{ pointerEvents: (this.isCommandLoading("initTotal") || this.isCommandLoading("initPartial")) ? "none" : "initial", 
                    opacity: (this.isCommandLoading("initTotal") || this.isCommandLoading("initPartial")) ? 0.5 : 1}}>
                    <div>
                        {/* {this.props.state && <div className="row" style={{fontSize: 13}}>
                            <div className="col-md-2">
                                <div>{getText( "MaintenanceLastName")} : {this.robot.name} </div>
                            </div>
                            <div className="col-md-2">
                                <div>{getText( "MaintenanceVersion")} : {this.robot.version}</div>
                            </div>
                            <div className="col-md-3" style={{padding: 0}}>
                                <div>{getText( "MaintenanceStatusInit")} : {this.props.state.initStatus}</div>
                            </div>
                            <div className="col-md-2">
                                <div>{getText( "MaintenanceStatus")} : {this.props.state.status}</div>
                            </div>
                            <div className="col-md-2" style={{padding: 0}}>
                                <div>{getText( "MaintenancePosition")} : {this.props.state.position}</div>
                            </div>
                        </div>} */}
                        <div className="row">
                            <div className="col-md-3">
                                <div style={{fontWeight: "bold", fontSize: 16, paddingRight: '2%'}}>{getText( "MaintenanceRobotSAS")}</div>
                                <div>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled
                                            || this.robotData.isSasDoorOpen
                                            || this.isCommandLoading("openInsideDoor")}
                                        onClick={() => {
                                            //this.props.requestRobotUnLockInsideDoor(new Date().getTime()); //Managed server-side and by the robot itself, and it should be following each other not at the same time (.then(()=> { doRequest }))
                                            this.props.requestRobotOpenInsideDoor(new Date().getTime()) //open door
                                        }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceOpening")}
                                    </button>
                                </div>
                                <div style={{marginTop: '3%'}}>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled
                                            || !this.robotData.isSasDoorOpen
                                            || this.isCommandLoading("closeInsideDoor")}
                                        onClick={() => {
                                            this.props.requestRobotCloseInsideDoor(new Date().getTime()) //close door
                                            //this.props.requestRobotLockInsideDoor(new Date().getTime()) //lock //Managed server-side and by the robot itself
                                        }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceClosing")}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div style={{fontWeight: "bold", fontSize: 16, paddingRight: '2%'}}>{getText( "MaintenanceDeliveryMat")}</div>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize:14}}>
                                    <div
                                        onClick={(e) => this.props.updateDeliveryBeltDestination("DeliveryDoor")}>
                                        <input style={{ margin: "0px 6px" }} type="radio" checked={this.props.deliveryBeltDirection === "DeliveryDoor"} readOnly/>
                                        {getText( "MaintenanceDirectionDelivery")}
                                    </div>
                                    <button style={{ ...btnStyle, width: '35%'}}
                                        disabled={this.disabled
                                            || (this.robotData.deliveryBeltState ? true : false)
                                            || !this.robotData.isSasDoorOpen
                                            || this.isCommandLoading("runDeliveryBelt")}
                                        onClick={() => this.props.requestRobotRunDeliveryBelt(new Date().getTime(), this.props.deliveryBeltDirection)}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceIHMMarket")}
                                    </button>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '3%', fontSize: 14}}>
                                    <div
                                        onClick={(e) => this.props.updateDeliveryBeltDestination("Trash")}>
                                        <input style={{ margin: "0px 6px" }} type="radio" checked={this.props.deliveryBeltDirection === "Trash"} readOnly/>
                                        {getText( "MaintenanceDirectionDLC")}
                                    </div>
                                    <button style={{ ...btnStyle, width: '35%' }}
                                        disabled={this.disabled
                                            || (this.robotData.deliveryBeltState ? false : true)
                                            || this.isCommandLoading("stopDeliveryBelt")}
                                        onClick={() => this.props.requestRobotStopDeliveryBelt(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceIHMStop")}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <RobotIndicators robotData={this.robotData} robotState={this.props.state} />
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '1%'}}>
                            <div className="col-md-3">
                                <div style={{fontWeight: "bold", fontSize: 16, paddingRight: '2%'}}>{getText( "MaintenanceCleaning")}</div>
                                <div style={{marginTop: '3%'}}>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled }
                                        onClick={() => {
                                            this.props.requestRobotToCoord(new Date().getTime(), {
                                                lane: 1,
                                                x: this.robot.robotConfiguration.maintenanceX1,
                                                z: this.robot.robotConfiguration.maintenanceZ1
                                            });
                                        }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenancePosition")} 1
                                    </button>
                                </div>
                                <div style={{marginTop: '3%'}}>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled }
                                        onClick={() => {
                                            this.props.requestRobotToCoord(new Date().getTime(), {
                                                lane: 1,
                                                x: this.robot.robotConfiguration.maintenanceX2,
                                                z: this.robot.robotConfiguration.maintenanceZ2
                                            });
                                        }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenancePosition")} 2
                                    </button>
                                </div>
                                <div style={{marginTop: '3%'}}>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled }
                                        onClick={() => {
                                            this.props.requestRobotToCoord(new Date().getTime(), {
                                                lane: 1,
                                                x: this.robot.robotConfiguration.deliveryX,
                                                z: this.robot.robotConfiguration.deliveryZ
                                            });
                                        }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenancePosition2")}
                                    </button>
                                </div>
                            </div>
                            <div style={{
                                display: "grid",
                                height: 200
                                }}>
                                <div style={{
                                    gridRow: "1 / 3",
                                    gridColumn: "1 / 3",
                                    position: "relative" }}>
                                        <RobotAnimation
                                            robot={this.robot}
                                            roobotData={this.robotData} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '1%'}}>
                            <div className="col-md-3">
                                <div style={{fontWeight: "bold", fontSize: 16, paddingRight: '2%'}}>{getText( "MaintenanceDelivery")}</div>
                                <div style={{marginTop: '3%'}}>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled
                                            || this.robotData.isDeliveryDoorOpen
                                            || this.isCommandLoading("openDoor")}
                                        onClick={() => {
                                            //this.props.requestRobotUnLockDoor(new Date().getTime()) //unlock
                                            this.props.requestRobotOpenDoor(new Date().getTime()) //open door
                                        }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceOpening")}
                                    </button>
                                </div>
                                <div style={{marginTop: '3%'}}>
                                    <button style={{ ...btnStyle, width: '100%' }}
                                        disabled={this.disabled
                                            || !this.robotData.isDeliveryDoorOpen
                                            || this.isCommandLoading("closeDoor")}
                                        onClick={() => {
                                            this.props.requestRobotCloseDoor(new Date().getTime()) //close door
                                            //this.props.requestRobotLockDoor(new Date().getTime()) //lock
                                        } }
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceClosing")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row"  style={{marginTop: '2%'}}>
                            <div className="col-md-3" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{paddingRight: '5%'}}>X:</div>
                                <input style={{ width: '50%'}}
                                    className="form-control" type="text"
                                    value={this.props.state ? this.props.state.robotData.coord.x : 0}
                                    onChange={(value) =>{}}/>
                                <div style={{padding: '5%'}}>Z:</div>
                                <input style={{ width: '50%' }}
                                    className="form-control" type="text"
                                    value={this.props.state ? this.props.state.robotData.coord.z : 0}
                                    onChange={(value) =>{}}/>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <button style={{ height: 40 }}
                                    disabled={this.disabled}
                                    onClick={() => {this.props.openUnlockDialog()}}
                                    className="btn btn-primary">
                                        {getText( "MaintenanceUnblock")}
                                </button>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-4" style={{display: 'flex', alignItems: 'center'}}>
                                <button style={{ height: 40 }}
                                    disabled={this.disabled || this.isCommandLoading("initTotal")}
                                    onClick={() => {
                                        this.props.requestInitRobotTotal(new Date().getTime());
                                        this.props.requestAlarmEvents(new Date().getTime());
                                    }}
                                    className="btn btn-primary">
                                        {getText( "MaintenanceInit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                <DialogModal contentLabel={"Maintenance Robot Unblock"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                        width: 500, 
                        maxHeight: 600,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    title={getText( "MaintenanceUnblock")}
                    isOpen={this.props.robotAttenptToUnlock.isOpen}
                    onRequestClose={() => this.props.closeUnlockDialog()}>
                    <div style={{margin: "24px 50px"}}>
                        <RobotExtractors
                            robotId={this.props.robotId}
                            disabled={this.disabled}
                            areValidCoords={this.areValidCoords}
                        />
                        {!this.areValidCoords && 
                        <h4 style={{ overflowWrap: 'break-word'}}>
                            {getText( "MaintenanceErrorRobotUnlockDialog")}
                        </h4>}
                    </div>
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.robot,
    hmiStates: state.hmiApplication.hmiStates,
    stockStatus: state.stock.stockStatus,
    disabled: !canUpdate(state)
} as RobotControlHmiOwnProps), {
        ...RobotStore.actionCreators,
    ...NeoEventStore.actionCreators
    })(RobotControlHmi as any) as any as React.ComponentClass<RobotControlHmiExternalProps>;