import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate } from '../security/roles';
import { Field } from 'redux-form';
import { getText } from '../utils/texts';

type PlanogramSettingsFormProps = ReduxForm.InjectedFormProps<Api.PlanogramSettingsModel, {}, {}>
    & PlanogramSettingsFormOwnProps
    & PlanogramSettingsFormExternalProps;

interface PlanogramSettingsFormExternalProps {
    onSubmit: (values: Api.PlanogramSettingsModel) => any;
}

interface PlanogramSettingsFormOwnProps {
    disabled: boolean;
    showDeleteShelving: boolean
}

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    columnGap: 5
};
const labelStyle: React.CSSProperties = {
    minWidth: 120,
    textAlign: "right"
};

class PlanogramSettingsForm extends React.Component<PlanogramSettingsFormProps, {}> {
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} style={{ maxWidth: 800 }}>
                <div style={{ minWidth: '300px', display: "flex", flexDirection: "column", alignItems: "stretch", rowGap:10 }}>
                    <div style={{ ...fieldStyle }}>
                        <div style={{ flex: "0 0 auto", ...labelStyle }}>
                            <Field name="showDeleteShelving"
                                label={getText("PlanogramRemoveShelving")}
                                disabled={this.props.disabled}
                                containerStyle={{ width: 150, display: "inline-block" }}
                                
                                component={FormField.CheckBoxField} />

                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <button className="btn btn-primary"
                            style={{
                                fontSize: 14,
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            type={"submit"}
                            disabled={this.props.submitting || this.props.disabled}>
                            {getText("PlanogramUpdateShelving")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
            
        );
    }
}


let createForm = ReduxForm.reduxForm({
    form: 'planogramSetting',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmit: (values) => { console.log(values) }
    
})(PlanogramSettingsForm as any) as any;
const createSelector = ReduxForm.formValueSelector('planogramSetting');
export default connect((state: ApplicationState) => {
    let sh = createSelector(state, "showDeleteShelving");
    return {
        initialValues: {
            showDeleteShelving: state.planogram.settings.showDeleteShelving
        } as Api.PlanogramSettingsModel,
        disabled: !canUpdate(state),
        showDeleteShelving: sh,
    } as PlanogramSettingsFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<PlanogramSettingsFormExternalProps>;