import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { getText } from '../utils/texts';


export interface SettingsState {
    isLoading: boolean;
    requestTime?: number;
    storeSettings?: Api.StoreSettingsModel;
    updateState: {
        isLoading: boolean;
        requestTime?: number;
    },
    importStockState: {
        isLoading: boolean;
        requestTime?: number;
    },
    updateStatusLanguage: {
        isLoading: boolean;
        requestTime?: number;
    };
}

interface RequestStoreSettings {
    type: "REQUEST_STORESETTINGS";
    payload: { requestTime: number }
}
interface ReceiveStoreSettings {
    type: "RECEIVE_STORESETTINGS";
    payload: { requestTime: number; storeSettings?: Api.StoreSettingsModel; };
    error?: any;
}

interface RequestUpdateStoreSettings {
    type: "REQUEST_UPDATE_STORESETTINGS";
    payload: { requestTime: number }
}
interface ReceiveUpdateStoreSettings {
    type: "RECEIVE_UPDATE_STORESETTINGS";
    payload: { requestTime: number; storeSettings?: Api.StoreSettingsModel; };
    error?: any;
}

interface RequestImportStockData {
    type: 'REQUEST_IMPORT_STOCK_DATA';
    payload: { requestTime: number; }
}
interface ReceiveImportStockData {
    type: 'RECEIVE_IMPORT_STOCK_DATA';
    payload: { requestTime: number; }
    error?: any;
}

interface RequestUpdateStatusLanguage {
    type: "REQUEST_UPDATE_STATUSLANGUAGE";
    payload: { requestTime: number }
}
export interface ReceiveUpdateStatusLanguage {
    type: "RECEIVE_UPDATE_STATUSLANGUAGE";
    payload: { requestTime: number; languages?: { [id: number]: Api.LanguageModel }; };
    error?: any;
}

export type KnownAction = RequestStoreSettings
    | ReceiveStoreSettings
    | RequestUpdateStoreSettings
    | ReceiveUpdateStoreSettings
    | RequestImportStockData
    | ReceiveImportStockData
    | RequestUpdateStatusLanguage
    | ReceiveUpdateStatusLanguage
    ;

export const requestStoreSettings = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.SettingsApi();
    let fetchTask = api.getStoreSettings({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(storeSettings => {
            dispatch({
                type: "RECEIVE_STORESETTINGS",
                payload: { requestTime: requestTime, storeSettings: storeSettings }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_STORESETTINGS",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "REQUEST_STORESETTINGS",
        payload: { requestTime: requestTime }
    });
    return fetchTask;
};

export const actionCreators = {
    requestStoreSettings: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestStoreSettings(requestTime, dispatch, getState);
    },
    requestUpdateStatusLanguage: (requestTime: number, models: Array<Api.LanguageModel>): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SettingsApi();
        let fetchTask = api.updateStatusLanguage({
            models: models
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(UpdateLanguages => {
                dispatch({
                    type: "RECEIVE_UPDATE_STATUSLANGUAGE",
                    payload: { requestTime: requestTime, languages: UpdateLanguages }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_STATUSLANGUAGE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_STATUSLANGUAGE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateStoreSettings: (requestTime: number, model: Api.StoreSettingsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SettingsApi();
        let fetchTask = api.updateStoreSettings({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(storeSettings => {
                dispatch({
                    type: "RECEIVE_UPDATE_STORESETTINGS",
                    payload: { requestTime: requestTime, storeSettings: storeSettings }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_STORESETTINGS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_STORESETTINGS",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    importData: (requestTime: number, file: File): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        if (requestTime === getState().settings.importStockState.requestTime)
            return Promise.reject("Already did");

        let formData = new FormData();
        formData.append("file", file);
        let fetchTask = fetch(`/api/Settings/ImportStockData`, {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then((response) => {
            if(response.ok){
                dispatch({ type: "RECEIVE_IMPORT_STOCK_DATA", payload: { requestTime: requestTime } });
                dispatch(Notifications.success({
                    title: getText("NotificationTitleSuccess"),
                    message: getText("NotificationCreatedSuccess")
                }) as any);
            }
            else{
                dispatch({ type: "RECEIVE_IMPORT_STOCK_DATA", payload: { requestTime: requestTime }, error: "Erreur" });
                dispatch(Notifications.error({
                    title: getText("NotificationTitleError"),
                    message: getText("NotificationCreatedError")
                })as any);
            }
        }).catch(err => {
            dispatch({ type: "RECEIVE_IMPORT_STOCK_DATA", payload: { requestTime: requestTime }, error: err });
        });

        dispatch({ type: "REQUEST_IMPORT_STOCK_DATA", payload: { requestTime: requestTime }});
        return fetchTask;
    }
}

const unloadedState: SettingsState = {
    isLoading: false,
    updateState: {
        isLoading: false
    },
    importStockState: {
        isLoading: false
    },
    updateStatusLanguage: {
        isLoading: false
    }
}

export const reducer: Reducer<SettingsState> = (state: SettingsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_STORESETTINGS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_STORESETTINGS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                storeSettings: action.payload.storeSettings
            };
        case "REQUEST_UPDATE_STORESETTINGS":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime,
                }
            };
        case "RECEIVE_UPDATE_STORESETTINGS":
            if (state.updateState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                storeSettings: action.error
                    ? state.storeSettings
                    : action.payload.storeSettings,
                updateState: {
                    ...state.updateState,
                    isLoading: false
                }
            };
        case 'REQUEST_IMPORT_STOCK_DATA':
            return {
                ...state,
                importStockState: {
                    ...state.importStockState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_IMPORT_STOCK_DATA':
            if (action.payload.requestTime !== state.importStockState.requestTime)
                return state;

            return {
                ...state,
                importStockState: {
                    ...state.importStockState,
                    isLoading: false
                }
            };
        case "REQUEST_UPDATE_STATUSLANGUAGE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_UPDATE_STATUSLANGUAGE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}