import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as _ from 'lodash';
import { getText } from '../utils/texts';

export interface HmiApplicationState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime?: number;
    hmis: Array<Api.HmiModel>;
    hmiStates: { [id: number]: HmiState };
    setHmiStates: {
        [id: number]: {
            requestTime?: number;
            isLoading: boolean
        }
    },
    aviablePaymentMethods?: boolean;
}

export interface HmiState {
    isLoading: boolean;
    requestTime?: number;
    lastUpdate?: number;
    lastRequest?: number;
    applicationState?: Api.HmiApplicationStateModel;
    isDialogOpen?: boolean;
    dialogMessage?: string;
}

//Actions
interface ReceiveHmiState {
    type: "RECEIVE_HMI_STATE";
    payload: { hmiState: Api.HmiApplicationStateModel; currentTime: number; }
}

interface RequestHmis {
    type: "REQUEST_HMIS";
    payload: { requestTime: number; }
}
interface ReceiveHmis {
    type: "RECEIVE_HMIS";
    payload: { requestTime: number; hmis: Array<Api.HmiModel> };
    error?: any;
}

interface RequestRequestHmiState {
    type: "REQUEST_REQUEST_HMI_STATE";
    payload: { requestTime: number; hmiId: number; }
}
interface ReceiveRequestHmiState {
    type: "RECEIVE_REQUEST_HMI_STATE";
    payload: { requestTime: number; hmiId: number; receiveTime: number }
    error?: any;
}

interface RequestSetHmiApplicationState {
    type: "REQUEST_SET_HMI_APPLICATION_STATE";
    payload: {
        requestTime: number;
        hmiId: number;
        state: Api.HmiApplicationStateModelApplicationStateEnum
    }
}
interface ReceiveSetHmiApplicationState {
    type: "RECEIVE_SET_HMI_APPLICATION_STATE";
    payload: {
        requestTime: number;
        hmiId: number;
        state: Api.HmiApplicationStateModelApplicationStateEnum
    };
    error?: any
}

interface RequestPaymentMethods {
    type: "HOME_REQUEST_CHECK_METHODS";
    payload: { aviablePaymentMethods: boolean; }
}
interface ReceivePaymentMethods {
    type: "HOME_RECIEVE_CHECK_METHODS";
    payload: { aviablePaymentMethods: boolean; }
    error?: any;
}
interface CloseDialogModal {
    type: "CLOSE_MODAL";
    payload: { hmiId: number; }
}
interface OpenDialogModal {
    type: "OPEN_MODAL";
    payload: { hmiId: number; message: string }
}

interface RequestUpdateHmisLanguage {
    type: "REQUEST_UPDATE_HMISLANGUAGE";
    payload: { requestTime: number }
}
export interface ReceiveUpdateHmisLanguage {
    type: "RECEIVE_UPDATE_HMISLANGUAGE";
    payload: { requestTime: number; Hmis?: { [id: number]: Api.HmiModel }; };
    error?: any;
}

export type WebSocketAction = ReceiveHmiState;

type KnownAction = ReceiveHmiState
    | RequestRequestHmiState
    | ReceiveRequestHmiState
    | RequestHmis | ReceiveHmis
    | RequestSetHmiApplicationState
    | RequestSetHmiApplicationState
    | ReceiveSetHmiApplicationState
    | RequestPaymentMethods
    | ReceivePaymentMethods
    | CloseDialogModal
    | OpenDialogModal
    | RequestUpdateHmisLanguage
    | ReceiveUpdateHmisLanguage
    ;

export const actionCreators = {
    requestHmis: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.HmiApi();
        let fetchTask = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hmis => {
                dispatch({
                    type: "RECEIVE_HMIS",
                    payload: { requestTime: requestTime, hmis: hmis }
                });
                return hmis;
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_HMIS",
                    payload: { requestTime: requestTime, hmis: [] },
                    error: err
                });
            });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_HMIS", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    updateHmisLang: (requestTime: number,  models: Array<Api.HmiModel>): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.HmiApi();
        let fetchTask = api.updateHmisLanguage({
            models: models
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(UpdateHmiLanguages => {
                dispatch({
                    type: "RECEIVE_UPDATE_HMISLANGUAGE",
                    payload: { requestTime: requestTime, Hmis: UpdateHmiLanguages }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_HMISLANGUAGE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_HMISLANGUAGE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestRequestHmiState: (requestTime: number, hmiId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.HmiNotificatorApi();
        let fetchTask = api.requestApplicationState({
            hmiId: hmiId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_REQUEST_HMI_STATE",
                    payload: { requestTime: requestTime, hmiId: hmiId, receiveTime: new Date().getTime() }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_REQUEST_HMI_STATE",
                    payload: { requestTime: requestTime, hmiId: hmiId, receiveTime: new Date().getTime() },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_REQUEST_HMI_STATE", payload: { requestTime: requestTime, hmiId: hmiId } });
        return fetchTask;
    },
    requestSetHmiApplicationState: (requestTime: number, hmiId: number, state: Api.HmiApplicationStateModelApplicationStateEnum): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.HmiNotificatorApi();
        let fetchTask = api.setApplicationState({
            hmiId: hmiId,
            state: state
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_SET_HMI_APPLICATION_STATE",
                    payload: { requestTime: requestTime, hmiId: hmiId, state: state }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_SET_HMI_APPLICATION_STATE",
                    payload: { requestTime: requestTime, hmiId: hmiId, state: state },
                    error: err
                });

                if (err.text) {
                    err.text().then(x => {
                        let msg =  x.replace(/\"/gi,'').replace(/\\/gi,'');
                        if(msg == "Robot not initialized"){ msg = getText( "MaintenanceError1")}
                        else if(msg == "Robot is being used by another application, in case of session left opened you can wait for 60 sec"){msg = getText( "MaintenanceError2")}
                        else if(msg = "An alarm prevent this action"){msg = getText( "MaintenanceError3")}
                        else{ msg = getText( "MaintenanceErrorDefault")}
                        dispatch({ type: "OPEN_MODAL", payload: { hmiId: hmiId, message: msg} });
                        //dispatch(Notification.error({ title: "Erreur", message: x, position: "tc", level: "error" }) as any);
                    });
                }
            });

        dispatch({
            type: "REQUEST_SET_HMI_APPLICATION_STATE",
            payload: { requestTime: requestTime, hmiId: hmiId, state: state }
        });
        return fetchTask;
    },
    checkAviablePaymentMethods: (hmiId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PaymentApi();
        let fetchTask = api.checkAviablePaymentMethods({
                hmiId: hmiId
            },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(aviableMethods => {
                    dispatch({
                        type: "HOME_RECIEVE_CHECK_METHODS",
                        payload: { aviablePaymentMethods: aviableMethods }
                    });
                    return aviableMethods;
                })
                .catch(err => {
                    dispatch({
                        type: "HOME_RECIEVE_CHECK_METHODS",
                        payload: { aviablePaymentMethods: false },
                        error: err
                    });
                });
    
            addTask(fetchTask);
            dispatch({ type: "HOME_REQUEST_CHECK_METHODS", payload: { aviablePaymentMethods: false } });
            return fetchTask;
    },
    closeDialogModal: (hmiId: number) => <CloseDialogModal>{
        type: "CLOSE_MODAL",
        payload: { hmiId: hmiId }
    }
};

const unloadedState: HmiApplicationState = {
    isLoading: false,
    isLoaded: false,
    hmis: [],
    hmiStates: {},
    setHmiStates: {},
    aviablePaymentMethods: false
};

export const hmiNotRunning = (state: ApplicationState): boolean => {
    return !_.values(state.hmiApplication.hmiStates).some(x => x
        && x.applicationState
        && (x.applicationState.applicationState === "Running"
            || x.applicationState.applicationState === "Test"
            || x.applicationState.applicationState === "Selling"));
};

export const hmiNotSelling = (state: ApplicationState): boolean => {
    return !_.values(state.hmiApplication.hmiStates).some(x => x
        && x.applicationState
        && x.applicationState.applicationState === "Selling");
};

export const reducer: Reducer<HmiApplicationState> = (state: HmiApplicationState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_HMI_STATE":
            return {
                ...state,
                hmiStates: {
                    ...state.hmiStates,
                    [action.payload.hmiState.hmiId]: {
                        ...state.hmiStates[action.payload.hmiState.hmiId],
                        applicationState: action.payload.hmiState,
                        lastUpdate: action.payload.currentTime,
                        isDialogOpe: false,
                        dialogMessage: ""
                    }
                }
            };
        case "REQUEST_REQUEST_HMI_STATE":
            return {
                ...state,
                hmiStates: {
                    ...state.hmiStates,
                    [action.payload.hmiId]: {
                        ...state.hmiStates[action.payload.hmiId],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                        isDialogOpe: false,
                        dialogMessage: ""
                    }
                }
            };
        case "RECEIVE_REQUEST_HMI_STATE":
            if (state.hmiStates[action.payload.hmiId]
                && state.hmiStates[action.payload.hmiId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                hmiStates: {
                    ...state.hmiStates,
                    [action.payload.hmiId]: {
                        ...state.hmiStates[action.payload.hmiId],
                        isLoading: false,
                        lastRequest: action.payload.receiveTime,
                        isDialogOpe: false,
                        dialogMessage: ""
                    }
                }
            };
        case "REQUEST_HMIS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_HMIS":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                isLoaded: action.error
                    ? false : true,
                hmis: action.error
                    ? state.hmis
                    : action.payload.hmis
            };
        case "REQUEST_SET_HMI_APPLICATION_STATE":
            return {
                ...state,
                setHmiStates: {
                    ...state.setHmiStates,
                    [action.payload.hmiId]: {
                        ...state.setHmiStates[action.payload.hmiId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_SET_HMI_APPLICATION_STATE":
            if (state.setHmiStates[action.payload.hmiId].requestTime
                && action.payload.requestTime !== state.setHmiStates[action.payload.hmiId].requestTime)
                return state;

            return {
                ...state,
                setHmiStates: {
                    ...state.setHmiStates,
                    [action.payload.hmiId]: {
                        ...state.setHmiStates[action.payload.hmiId],
                        isLoading: false
                    }
                }
            };
        case "HOME_REQUEST_CHECK_METHODS":
            return {
                ...state,
                isLoading: true
            };
        case "HOME_RECIEVE_CHECK_METHODS":
            return {
                ...state,
                isLoading: false,
                isLoaded: action.error
                    ? false : true,
                aviablePaymentMethods: action.error
                    ? state.aviablePaymentMethods
                    : action.payload.aviablePaymentMethods
            };
        case "CLOSE_MODAL":
            return {
                ...state,
                hmiStates: {
                    ...state.hmiStates,
                    [action.payload.hmiId]: {
                        ...state.hmiStates[action.payload.hmiId],
                        isDialogOpen: false,
                        dialogMessage: ""
                    }
                }
            };
        case "OPEN_MODAL":
            return {
                ...state,
                hmiStates: {
                    ...state.hmiStates,
                    [action.payload.hmiId]: {
                        ...state.hmiStates[action.payload.hmiId],
                        isDialogOpen: true,
                        dialogMessage: action.payload.message
                    }
                }
            };
        case "REQUEST_UPDATE_HMISLANGUAGE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_UPDATE_HMISLANGUAGE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                hmis: action.error
                ? state.hmis
                : state.hmis.map(x => action.payload.Hmis[x.hmiId])
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};