import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as HmiApplicationStore from "../store/HmiApplication";
import * as StockStore from "../store/Stock";
import * as MaintenanceStore from "../store/Maintenance";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import HmiStateControl from "./HmiStateControl";
import RobotControl from "./RobotControl";
import RobotControlHmi from "./RobotControlHmi";
import NeoEventAlarms from "./NeoEventAlarms";
import AlarmEvents from "./AlarmEvents";
import PeripheralsControl from "./PeripheralsControl";
import Confirm from "react-confirm-bootstrap";
import MoneyTest from "./MoneyTest";
import { canUpdate } from '../security/roles';
import Contacts from './Contacts';
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import * as RobotStore from '../store/Robot';
import * as NeoEventStore from '../store/NeoEvent';
import * as CardStore from '../store/Card';
import * as CashStore from '../store/Cash';
import * as PrintStore from '../store/Print';
import * as TempStore from '../store/Temp';
import * as HomeStore from '../store/Home';
import * as ContactStore from '../store/Contact';
import * as NeoEventAlarmStore from '../store/NeoEventAlarm';


type MaintenanceProps = MaintenanceStore.MaintenanceState
    & MaintenanceOwnProps
    & RobotStore.RobotState
    & typeof RobotStore.actionCreators
    & typeof StockStore.actionCreators
    & typeof HmiApplicationStore.actionCreators
    & typeof MaintenanceStore.actionCreators
    & typeof NeoEventStore.actionCreators
    & typeof CardStore.actionCreators
    & typeof CashStore.actionCreators
    & typeof PrintStore.actionCreators
    & typeof TempStore.actionCreators
    & typeof HomeStore.actionCreators
    & typeof NeoEventAlarmStore.actionCreators
    & typeof ContactStore.actionCreators
    ;

interface MaintenanceOwnProps {
    hmiApplication: HmiApplicationStore.HmiApplicationState;
    robotHandlerState: Api.RobotHandlerState;
    robots: Array<Api.RobotModel>;
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

const gridContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
    gridArea: "auto",
    justifyContent: "center",
    alignItems: "center"

};

const gridItemStyle: React.CSSProperties = {
    textAlign: "center",
    width: "100%",
    height: "auto"
};

class Maintenance extends React.Component<MaintenanceProps, {}> {

    public render() {
        return (
            <div style={{height: "100%"}}>
                <ConnectedTabs name={"maintenance"}>
                    <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                        <span style={{ padding: '0 2%'}}>
                            {getText( "MaintenanceTitle")}
                        </span>
                        <TabLink to="hmis" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "MaintenanceTabEvent")}</div>
                        </TabLink>
                        <TabLink to="robots" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "MaintenanceTabRobot")}</div>
                        </TabLink>
                        <TabLink to="tests" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "MaintenanceTest")}</div>
                        </TabLink>
                        <TabLink to="alarms" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "MaintenanceTabAlarms")}</div>
                        </TabLink>
                        <TabLink to="contacts" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "MaintenanceTabContacts")}</div>
                        </TabLink>
                        <HelpHeader />
                    </div>
                    <TabContent for="hmis" style={{margin: '0 1%', height: "100%"}}>
                        <div className="row" style={{padding: '1%'}}>
                            <div className="col-md-4" style={{paddingLeft: 0}}>
                                <div className="card" style={{ ...Styles.cardStyle,  padding: '2% 4%'}}>
                                    <div style={{fontWeight: "bold", fontSize: 22, paddingRight: '2%'}}>
                                        {getText( "MaintenanceIHM")}
                                    </div>
                                    <div style={{
                                        pointerEvents: this.props.disabled ? "none" : "initial",
                                        opacity: this.props.disabled ? 0.7 : 1,
                                    }}>
                                        {this.props.hmiApplication.hmis.map(hmi => <HmiStateControl
                                            key={hmi.hmiId}
                                            hmi={hmi}
                                            robotHandlerState={this.props.robotHandlerState}
                                            disabled={this.props.disabled}
                                            hmiState={this.props.hmiApplication.hmiStates[hmi.hmiId]}
                                            setHmiState={this.props.hmiApplication.setHmiStates[hmi.hmiId]}
                                            onSelectApplicationState={(state) =>
                                            {
                                                this.props.requestSetHmiApplicationState(
                                                    new Date().getTime(), hmi.hmiId, state);
                                                if(state === "Running"){
                                                    this.props.requestAlarmEvents(new Date().getTime());
                                                }
                                            }} 
                                            closeDialogModal={() => this.props.closeDialogModal(hmi.hmiId)}/>)}
                                        <div style={{
                                            margin: "7% 0 0 1%",
                                            pointerEvents: this.props.disabled ? "none" : "initial",
                                            opacity: this.props.disabled ? 0.7 : 1
                                        }}>
                                            <PeripheralsControl />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8" style={{padding: '0 2px 0 0'}}>
                                {this.props.robots.map(robot => <div key={robot.robotId}
                                    className="card"
                                    style={{ ...Styles.cardStyle, padding: '1% 2%' }}>
                                    <RobotControlHmi robotId={robot.robotId} />
                                </div>)}
                            </div>
                        </div>
                        <div className="card" style={{ ...Styles.cardStyle,  paddingTop: 8, height: "40vh", overflow: "hidden"}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '0 0 6px 1%'}}>
                                <div style={{fontWeight: "bold", fontSize: 22}}> {getText( "MaintenanceEvents")}</div>
                                <Confirm
                                    style={{marginRight: 5}}
                                    onConfirm={(e) => {
                                        this.props.requestRestartApplications(new Date().getTime());
                                        e.preventDefault();
                                    }}
                                    body={getText( "MaintenanceRestartAppsBodyConfirm")}
                                    confirmText={getText( "MaintenanceConfirm")}
                                    title={getText( "MaintenanceConfirmTitle")}>
                                    <button style={{height: 40, fontWeight: 'bold'}}
                                        className="btn btn-primary"
                                        disabled={this.props.restartState.isLoading
                                            || this.props.disabled}
                                        onClick={(e) => { }}>
                                        {getText( "MaintenanceRestartAppsButton")}
                                        </button>
                                </Confirm>
                            </div>
                            <AlarmEvents />
                        </div>
                    </TabContent>
                    <TabContent for="tests" style={{margin: '0 1%', height: "100%"}}>
                        <div style={{
                            pointerEvents: this.props.disabled ? "none" : "initial", height: "100%",
                            opacity: this.props.disabled ? 0.7 : 1, padding: '4px 10px'
                        }}>
                            <MoneyTest />
                        </div>
                    </TabContent>
                    <TabContent for="robots" style={{margin: '0 1%', height: "100%"}}>
                        <div style={{ marginTop: '1%',
                            pointerEvents: this.props.disabled ? "none" : "initial",
                            opacity: this.props.disabled ? 0.7 : 1
                        }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                {this.props.robots.map(robot => <div key={robot.robotId}>
                                    <RobotControl robotId={robot.robotId} />
                                </div>)}
                            </div>
                        </div>
                    </TabContent>
                    <TabContent for="alarms" style={{margin: '0 1%', height: "100%"}}>
                        <div style={{ padding: '10px 0' }}>
                            <NeoEventAlarms />
                        </div>
                    </TabContent>
                    <TabContent for="contacts" style={{margin: '0 1%', height: "100%"}}>
                        <div style={{ padding: '10px 0' }}>
                            <Contacts />
                        </div>
                    </TabContent>
                </ConnectedTabs>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.maintenance,
    hmiApplication: state.hmiApplication,
    robots: state.robot.robotsState.robots,
    disabled: !canUpdate(state),
    currentUser: state.account.currentUser,
    robotHandlerState: state.robot.state,
} as MaintenanceOwnProps), {
    ...HmiApplicationStore.actionCreators,
    ...MaintenanceStore.actionCreators,
    ...StockStore.actionCreators,
    ...RobotStore.actionCreators,
    ...NeoEventStore.actionCreators,
    ...HomeStore.actionCreators,
    ...CashStore.actionCreators,
    ...CardStore.actionCreators,
    ...PrintStore.actionCreators,
    ...TempStore.actionCreators,
    ...NeoEventAlarmStore.actionCreators,
    ...ContactStore.actionCreators
})(Maintenance as any);