import * as React from 'react';
import * as Api from '../api/api';
import * as CashStore from '../store/Cash';
import * as _ from 'lodash';
import EventViewer from "./EventViewer";
import InputNumber from "./InputNumber";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canEditSettings, canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';

type CashTestProps = CashTestOwnProps
    & CashStore.CashState
    & typeof CashStore.actionCreators;

interface CashTestOwnProps {
    disabled: boolean;
    neoEvents: Array<Api.NeoEventModel>;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
}

const relevantCode = [/*501, 502, 506, 515, 520, 521, 522, 523, 524, 525*/]; //should be 800-900

interface CashTestState {
}

const inputStyle: React.CSSProperties = {height: 32}
const buttonStyle: React.CSSProperties = {height: 32, fontSize: 13, width: '100%', fontWeight: 'bold', margin: 0}
const spaceDiv: React.CSSProperties = {minHeight: 20}
const rowStyle: React.CSSProperties = {marginTop: '1%'}

class CashTest extends React.Component<CashTestProps, CashTestState> {
    constructor(props: CashTestProps) {
        super(props);
    }

    public render() {
        return (
            <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                <div style={{fontWeight: "bold", fontSize: 22, margin: 'auto'}}>
                    {getText( "MaintenanceCash")}
                </div>
                {this.props.state && <div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group" style={{margin: 0}}>
                                <label>{getText( "MaintenanceAmount")}</label>
                                <InputNumber value={this.props.testState.solvency
                                    ? Math.round(this.props.testState.solvency * 100) / 100
                                    : 0}
                                    disabled={this.props.testState.paymentRunning}
                                    style={inputStyle}
                                    onChange={(value) => this.props.updateCashSolvencyAmount(value)} />
                            </div>
                        </div>
                        <div className="col-md-3" style={{padding: 0}}>
                            <div style={spaceDiv}></div>
                            <button disabled={this.props.testState.paymentRunning || this.props.disabled}
                                onClick={(e) => this.props.requestStartCashTestPayment(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenanceSolvencyRequest")}
                            </button>
                        </div>
                        <div className="col-md-2">
                        <div style={spaceDiv}></div>
                            <button disabled={(!this.props.testState.paymentRunning && this.props.state.state === "Idle")
                                || this.props.disabled}
                                onClick={(e) => this.props.requestCancelPayment(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenanceAbandonment")}
                            </button>
                        </div>
                    </div>
                    <div className="row" style={rowStyle}>
                        <div className="col-md-4">
                            <div className="form-group" style={{margin: 0}}>
                                <label>{getText( "MaintenanceAmount2")}</label>
                                <InputNumber value={this.props.testState.collect}
                                    disabled={this.props.testState.collectAmountState.isLoading}
                                    style={inputStyle}
                                    onChange={(value) => this.props.updateCashCollectAmount(value)} />
                            </div>
                        </div>
                        <div className="col-md-2" style={{padding: 0}}>
                            <div style={spaceDiv}></div>
                            <button disabled={!this.props.testState.solvencyCompleted
                                || this.props.disabled}
                                onClick={(e) => this.props.requestCollectAmount(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenanceCollection")}
                            </button>
                        </div>
                        <div className="col-md-4">
                            <div style={spaceDiv}></div>
                            <button disabled={this.props.testState.stopPaymentState.isLoading || this.props.disabled}
                                onClick={(e) => this.props.requestCashStopPaymentSession(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenancePaymentSession")}
                            </button>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '0.5rem'}}>
                        {this.props.hoppers.hoppers && this.props.hoppers.hoppers.map(hopper => 
                            <div key={hopper.hmiMoneyHopperId} className="col-md-3">
                                <div style={{ textAlign: "center" }}>{`${hopper.name} --> ${hopper.quantity}`}</div>
                                <div className="row" style={{marginTop: '5%'}}>
                                    <div className="col-md-8" style={{paddingRight: 0}}>
                                        <button className="btn btn-primary"
                                            style={buttonStyle}
                                            disabled={(this.props.hoppersEmpty[hopper.hmiMoneyHopperId]
                                                && this.props.hoppersEmpty[hopper.hmiMoneyHopperId].isLoading)
                                                || this.props.disabled}
                                            onClick={(e) => this.props.requestEmptyHoppers(new Date().getTime(), hopper.hmiMoneyHopperId)}>
                                            {getText( "MaintenancePartial")}
                                        </button>
                                    </div>
                                    <div className="col-md-4">
                                        <InputNumber
                                            style={{...inputStyle, width: '100%'}}
                                            value={this.props.hoppersEmpty[hopper.hmiMoneyHopperId]
                                                ? this.props.hoppersEmpty[hopper.hmiMoneyHopperId].value
                                                : 0}
                                            onChange={(value) => this.props.updateHopperEmpty(hopper.hmiMoneyHopperId, value)} />
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: '5%'}}>
                                    <div className="col-md-5" style={{paddingRight: 0}}>
                                        <input
                                            style={{...inputStyle, width: '100%'}}
                                            disabled={true}
                                            value={this.props.hoppersEmpty[hopper.hmiMoneyHopperId]
                                                ? this.props.hoppersEmpty[hopper.hmiMoneyHopperId].resetAmount
                                                : ""}
                                        />
                                    </div>
                                    <div className="col-md-7">
                                        <button className="btn btn-primary"
                                            style={buttonStyle}
                                            disabled={(this.props.hoppersEmpty[hopper.hmiMoneyHopperId]
                                                && this.props.hoppersEmpty[hopper.hmiMoneyHopperId].isLoading)
                                                || this.props.disabled}
                                            onClick={(e) => this.props.requestResetHopper(new Date().getTime(), hopper.hmiMoneyHopperId)}>
                                            {getText( "MaintenanceCashTest")}
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>)}
                    </div>
                </div>}
            </div>
        );
            {/* <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", flexDirection: "row", padding: 10 }}>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>General</div>
                    <div style={{
                        height: 30, width: 30, borderRadius: 30,
                        backgroundColor: this.props.state.status === "Ok"
                            ? "green" : "red"
                    }}>
                    </div>
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>Lecteur billet</div>
                    <div style={{
                        height: 30, width: 30, borderRadius: 30,
                        backgroundColor: this.props.state.status === "Ok"
                            ? "green" : "red"
                    }}>
                    </div>
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>Monnayeur</div>
                    <div style={{
                        height: 30, width: 30, borderRadius: 30,
                        backgroundColor: this.props.state.status === "Ok"
                            ? "green" : "red"
                    }}>
                    </div>
                </div>
                <div style={{ flex: 1 }}></div>
                {this.props.state.cashHopperStates.map(hopper => <div key={hopper.serialNumber} style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>Monnayeur</div>
                    <div style={{
                        height: 30, width: 30, borderRadius: 30,
                        backgroundColor: hopper.status === "Error"
                            ? "red" : "green"
                    }}>
                    </div>
                </div>)}
            </div> */}
    }
}

export default connect((state: ApplicationState) => ({
    ...state.cash,
    neoEvents: state.home.neoEvents.neoEvents,
    neoEventCodes: state.seed.seed.neoEventCodes,
    disabled: !canEditSettings(state)
} as CashTestOwnProps), {
        ...CashStore.actionCreators,
    })(CashTest as any);