import * as Api from "../api/api";
import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import _ from "lodash";
import * as Notification from "react-notification-system-redux";
import { getText } from "../utils/texts";

export interface ProductsDetailsState {
    familiesState: FamiliesState,
    //subFamiliesState: SubFamiliesState,
    familyId?: number;
    subFamilyId?: number;
    productId?: number;
    priceId?: number;
    productSupplierId?: number;
    planogrammeFilter: boolean;
    productSizes: productSizesState;
}

export interface productSizesState {
    isSaving: boolean;
    requestTime?: number;
    productsUpdated:  { [id: number]: Api.ProductModel};
}

export interface FamiliesState {
    isLoading: boolean;
    requestTime?: number;
    families: { [id: number]: Api.FamilyModel };
}

export interface SubFamiliesState {
    isLoading: boolean;
    requestTime?: number;
    subFamilies: { [id: number]: Api.SubFamilyModel };
}

interface ProductsDetailsUpdateFamilyId {
    type: "PRODUCTS_DETAILS_UPDATE_FAMILYID";
    payload: { value: number };
}
interface ProductsDetailsUpdateSubFamilyId {
    type: "PRODUCTS_DETAILS_UPDATE_SUBFAMILYID";
    payload: { value: number };
}
interface ProductsDetailsUpdateProductId {
    type: "PRODUCTS_DETAILS_UPDATE_PRODUCTID";
    payload: { value: number };
}
interface ProductsDetailsUpdatePriceId {
    type: "PRODUCTS_DETAILS_UPDATE_PRICEID";
    payload: { value: number };
}
interface ProductsDetailsUpdateProductSupplierId {
    type: "PRODUCTS_DETAILS_UPDATE_PRODUCTSUPPLIERID";
    payload: { value: number };
}
interface ProductsDetailsUpdateFilterPlanogram {
    type: "PRODUCTS_DETAILS_UPDATE_FILTERPLANOGRAM";
    payload: { value: any };
}

interface RequestFamilies {
    type: 'REQUEST_FAMILIES';
    payload: { requestTime: number; }
}
interface ReceiveFamilies {
    type: 'RECEIVE_FAMILIES';
    payload: {
        requestTime: number;
        families?: { [id: number]: Api.FamilyModel };
    }
    error?: any;
}

interface RequestSubFamilies {
    type: 'REQUEST_SUBFAMILIES';
    payload: { requestTime: number; }
}
interface ReceiveSubFamilies {
    type: 'RECEIVE_SUBFAMILIES';
    payload: {
        requestTime: number;
        subFamilies?: { [id: number]: Api.SubFamilyModel };
    }
    error?: any;
}

interface ProdSizesUpdatedProdList {
    type: "PRODSIZES_UPDATED_PRODLIST";
    prod: Api.ProductModel
}

interface ProdSizesRequestSave {
    type: "PRODSIZES_REQUEST_SAVE";
    payload: { requestTime: number; }
}
export interface ProdSizesReceiveSave{
    type: "PRODSIZES_RECEIVE_SAVE";
    payload: { requestTime: number; values?: { [key: string]: Api.ProductModel; } }
    error?: any
}

//placeholder
type KnownAction = ProductsDetailsUpdateFamilyId
    | ProductsDetailsUpdateSubFamilyId
    | ProductsDetailsUpdateProductId
    | ProductsDetailsUpdatePriceId
    | ProductsDetailsUpdateProductSupplierId
    | ProductsDetailsUpdateFilterPlanogram
    | RequestFamilies
    | ReceiveFamilies
    | ProdSizesUpdatedProdList
    | ProdSizesRequestSave
    | ProdSizesReceiveSave;
    // | RequestSubFamilies
    // | ReceiveSubFamilies;

export const actionCreators = {
    updateFamilyId: (value: number) => <ProductsDetailsUpdateFamilyId>{
        type: "PRODUCTS_DETAILS_UPDATE_FAMILYID",
        payload: { value: value }
    },
    updateSubFamilyId: (value: number) => <ProductsDetailsUpdateSubFamilyId>{
        type: "PRODUCTS_DETAILS_UPDATE_SUBFAMILYID",
        payload: { value: value }
    },
    updateProductId: (value: number) => <ProductsDetailsUpdateProductId>{
        type: "PRODUCTS_DETAILS_UPDATE_PRODUCTID",
        payload: { value: value }
    },
    updatePriceId: (value: number) => <ProductsDetailsUpdatePriceId>{
        type: "PRODUCTS_DETAILS_UPDATE_PRICEID",
        payload: { value: value }
    },
    updateProductSupplierId: (value: number) => <ProductsDetailsUpdateProductSupplierId>{
        type: "PRODUCTS_DETAILS_UPDATE_PRODUCTSUPPLIERID",
        payload: { value: value }
    },
    updateFilterPlanogram: (value: any) => <ProductsDetailsUpdateFilterPlanogram>{
        type: "PRODUCTS_DETAILS_UPDATE_FILTERPLANOGRAM",
        payload: { value: value }
    },
    requestFamilies: (requestTime: number, model: Api.ProductDetailsFilterModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let api = new Api.FamilyApi()
        let fetchTask = api.getEntities({ model: model },
            { credentials: "include", headers: getDefaultHeaders(getState()) })
            .then(families => {
                dispatch({
                    type: "RECEIVE_FAMILIES",
                    payload: {
                        requestTime: requestTime,
                        families: families
                    }
                });
            }).catch(error => {
                console.error("Error fetching families: " + error.message);
                dispatch({
                    type: "RECEIVE_FAMILIES",
                    payload: { requestTime: requestTime },
                    error: error
                });
            });
        addTask(fetchTask);
        dispatch({ type: "REQUEST_FAMILIES", payload: { requestTime: requestTime }});
    },
    // requestSubFamiliesByFamily: (requestTime: number, model: Api.ProductDetailsFilterModel): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
    //     let api = new Api.FamilyApi()
    //     let fetchTask = api.getSubFamiliesByFamily({ model: model },
    //         { credentials: "include", headers: getDefaultHeaders(getState()) })
    //         .then(subFamilies => {
    //             dispatch({
    //                 type: "RECEIVE_SUBFAMILIES",
    //                 payload: {
    //                     requestTime: requestTime,
    //                     subFamilies: subFamilies
    //                 }
    //             });
    //         }).catch(error => {
    //             console.error("Error fetching subFamilies: " + error.message);
    //             dispatch({
    //                 type: "RECEIVE_SUBFAMILIES",
    //                 payload: { requestTime: requestTime },
    //                 error: error
    //             });
    //         });


    //     addTask(fetchTask);
    //     dispatch({ type: "REQUEST_SUBFAMILIES", payload: { requestTime: requestTime }});
    // },
    addToUpdatedProdList: (product: Api.ProductModel) => <ProdSizesUpdatedProdList>{
        type: "PRODSIZES_UPDATED_PRODLIST",
        prod: product
    },
    saveProductSizes: (requestTime: number, model: Api.UpdateProductSizeResultModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ProductsLgapApi();
        let fetchTask = api.updateProductSizes({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(aux => {

                let prods: { [id: number]: Api.ProductModel} = {};
                _.values(model.prodSizesUp).map(x => {
                    var prod : Api.ProductModel = JSON.parse(JSON.stringify(getState().seed.seed.products[x.storeItemId]));
                    prod.width = x.width;
                    prod.height = x.height;
                    prod.depth = x.depth;
                    prods = {...prods, [x.storeItemId]: prod};
                });
              
                dispatch({
                    type: "PRODSIZES_RECEIVE_SAVE",
                    payload: { requestTime: requestTime, values: prods }
                });
                dispatch(Notification.success({ title: getText("NotificationTitleSuccess"), message: getText("NotificationUpdatedSuccess"), position: "tc", level: "success" }) as any);
            })
            .catch(err => {
                dispatch(Notification.error({ title: getText("NotificationTitleError"), message: getText("NotificationUpdatedError"), position: "tc", level: "error" }) as any);
                dispatch({
                    type: "PRODSIZES_RECEIVE_SAVE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "PRODSIZES_REQUEST_SAVE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
};

const unloadedState: ProductsDetailsState = {
    planogrammeFilter: null,
    familiesState: {
        isLoading: false,
        families: []
    },
    productSizes: {
        isSaving: false,
        productsUpdated: {}
    }
    // subFamiliesState: {
    //     isLoading: false,
    //     subFamilies: []
    // },
};

export const reducer: Reducer<ProductsDetailsState> = (state: ProductsDetailsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "PRODUCTS_DETAILS_UPDATE_FAMILYID":
            return {
                ...state,
                familyId: action.payload.value
            };
        case "PRODUCTS_DETAILS_UPDATE_SUBFAMILYID":
            return {
                ...state,
                subFamilyId: action.payload.value
            };
        case "PRODUCTS_DETAILS_UPDATE_PRODUCTID":
            return {
                ...state,
                productId: action.payload.value,
                priceId: undefined,
                productSupplierId: undefined
            };
        case "PRODUCTS_DETAILS_UPDATE_PRICEID":
            return {
                ...state,
                priceId: action.payload.value
            };
        case "PRODUCTS_DETAILS_UPDATE_PRODUCTSUPPLIERID":
            return {
                ...state,
                productSupplierId: action.payload.value
            };
        case "PRODUCTS_DETAILS_UPDATE_FILTERPLANOGRAM":
            return {
                ...state,
                planogrammeFilter: action.payload.value
            };
        case "REQUEST_FAMILIES":
            return {
                ...state,
                familiesState: {
                    ...state.familiesState,
                    isLoading: true,
                    requestTime: action.payload.requestTime,

                }
            };
        case "RECEIVE_FAMILIES":
            if (action.payload.requestTime !== state.familiesState.requestTime)
                return state;

            return {
                ...state,
                familiesState: {
                    ...state.familiesState,
                    isLoading: false,
                    families: action.error
                        ? []
                        : action.payload.families
                }
                
            };
        // case "REQUEST_SUBFAMILIES":
        //     return {
        //         ...state,
        //         subFamiliesState: {
        //             ...state.subFamiliesState,
        //             isLoading: true,
        //             requestTime: action.payload.requestTime,

        //         }
        //     };
        // case "RECEIVE_SUBFAMILIES":
        //     if (action.payload.requestTime !== state.subFamiliesState.requestTime)
        //         return state;

        //     return {
        //         ...state,
        //         subFamiliesState: {
        //             ...state.subFamiliesState,
        //             isLoading: false,
        //             subFamilies: action.error
        //                 ? []
        //                 : action.payload.subFamilies
        //         }

        //     };
        case "PRODSIZES_UPDATED_PRODLIST":
            return {
                ...state,
                productSizes: {
                    ...state.productSizes,
                    productsUpdated: {
                        ...state.productSizes.productsUpdated,
                        [action.prod.storeItemId]: action.prod  
                    }
                }
            };
        case "PRODSIZES_REQUEST_SAVE":
            return {
                ...state,
                isSaving: true,
                requestTime: action.payload.requestTime
            };
        case "PRODSIZES_RECEIVE_SAVE":
            return {
                ...state,
                productSizes: {
                    ...state.productSizes,
                    productsUpdated: unloadedState.productSizes.productsUpdated
                }
            };    
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};