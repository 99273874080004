import * as Api from "../api/api";
import * as React from "react";
import { HmiState } from "../store/HmiApplication";
import { getText } from '../utils/texts';
import DialogModal from './DialogModal';

interface HmiStateControlProps {
    hmi: Api.HmiModel;
    hmiState: HmiState;
    disabled: boolean;
    robotHandlerState: Api.RobotHandlerState;
    setHmiState: { requestTime?: number; isLoading: boolean; };
    onSelectApplicationState: (state: Api.HmiApplicationStateModelApplicationStateEnum) => void;
    closeDialogModal: (hmiId: number) => void;
}

const stateBtnStyle: React.CSSProperties = {

};

const selectedStateBtnStyle: React.CSSProperties = {
    ...stateBtnStyle,
    backgroundColor: "#0080FF",
    border: "#0071E2",
    color: "#FFFFFF"
};
const marginRow: React.CSSProperties = {
    margin: '2% 0'
}

const paddingCol: React.CSSProperties = {
    padding: 0
}

export default class HmiStateControl extends React.Component<HmiStateControlProps, {}> {

    get hasTimeouted(): boolean {
        return this.props.hmiState && this.props.hmiState.lastUpdate + (1000 * 120) < new Date().getTime();
    }

    get dialogMessageText(): string {
        let message: string = this.props.hmiState.dialogMessage;
        if(message.includes('HTTP Error 404. The requested resource is not found.')){
            message = getText("MaintenanceHMIState");
        }
        return message;
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div>
                <div className="row" style={marginRow}>
                    <div className="col-md-12" style={paddingCol}>
                        <HmiStateButton text={getText( "MaintenanceIHMMarket")}
                            state={"Running"}
                            disabled={this.props.disabled
                                || !this.props.robotHandlerState
                                || this.props.robotHandlerState.status !== "Ready"}
                            hmi={this.props.hmi}
                            hmiState={this.props.hmiState}
                            hasTimeouted={this.hasTimeouted}
                            setHmiState={this.props.setHmiState}
                            col={1} row={1}
                            onClick={() => this.props.onSelectApplicationState("Running")}
                            />
                    </div>
                </div>
                <div className="row" style={marginRow}>
                    <div className="col-md-12" style={paddingCol}>
                        <HmiStateButton text={getText( "MaintenanceTitle")}
                            state={"Maintainance"}
                            disabled={this.props.disabled}
                            hmi={this.props.hmi}
                            hmiState={this.props.hmiState}
                            hasTimeouted={this.hasTimeouted}
                            setHmiState={this.props.setHmiState}
                            col={1} row={2}
                            onClick={() => this.props.onSelectApplicationState("Maintainance")}
                            />
                    </div>
                </div>
                <div className="row" style={marginRow}>
                    <div className="col-md-12" style={paddingCol}>
                        <HmiStateButton text={getText( "MaintenanceIHMStop")}
                            state={"Stopped"}
                            disabled={this.props.disabled}
                            hmi={this.props.hmi}
                            hmiState={this.props.hmiState}
                            hasTimeouted={this.hasTimeouted}
                            setHmiState={this.props.setHmiState}
                            col={1} row={3}
                            onClick={() => this.props.onSelectApplicationState("Stopped")}
                            />
                    </div>
                </div>
                <div className="row" style={marginRow}>
                    <div className="col-md-12" style={paddingCol}>
                        <HmiStateButton text={getText( "MaintenanceTest")}
                            state={"Test"}
                            disabled={this.props.disabled
                                || !this.props.robotHandlerState
                                || this.props.robotHandlerState.status !== "Ready"}
                            hmi={this.props.hmi}
                            hmiState={this.props.hmiState}
                            hasTimeouted={this.hasTimeouted}
                            setHmiState={this.props.setHmiState}
                            col={1} row={4}
                            onClick={() => this.props.onSelectApplicationState("Test")}
                            />
                    </div>
                </div>
                <DialogModal contentLabel={"HMI State Control"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);", 
                        width: 700, 
                        maxHeight: 600,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    title={getText( "MaintenanceTitleDialog")}
                    isOpen={this.props.hmiState ? this.props.hmiState.isDialogOpen : false}
                    onRequestClose={() => this.props.closeDialogModal(this.props.hmi.hmiId)}>
                    <div>
                        <h4 style={{margin: "40 30", overflowWrap: 'break-word'}}>
                            {(this.props.hmiState && this.props.hmiState.dialogMessage !== "") ? this.dialogMessageText : getText( "MaintenanceErrorDefault")}
                        </h4>
                        <button className="btn btn-secondary btn-sm"
                            type={"button"}
                            style={{
                                ...stateBtnStyle,
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.props.closeDialogModal(this.props.hmi.hmiId)}> {getText( "MaintenanceCorrect")} </button>
                    </div>
                </DialogModal>
                {/* <div style={{
                    gridRow: "1 / 5",
                    gridColumn: "2 / 3",
                    padding: 20
                }}>
                    <div style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 21
                    }}>
                        {this.props.hmi.name}
                    </div>
                    <div style={{
                        textAlign: "center",
                        fontSize: 16
                    }}>
                        {this.props.hmiState
                            && this.props.hmiState.applicationState
                            && this.props.hmiState.applicationState.applicationState === "Selling"
                            && <span style={{ color: "green" }}>{"Vente en cours"}</span>}

                        {this.props.hmiState
                            && this.props.hmiState.applicationState
                            && this.props.hmiState.applicationState.applicationState === "Running"
                            && <span style={{ color: "green" }}>{"Marche"}</span>}

                        {this.props.hmiState
                            && this.props.hmiState.applicationState
                            && this.props.hmiState.applicationState.applicationState === "Maintainance"
                            && <span style={{ color: "orange" }}>{"Maintenance"}</span>}

                        {this.props.hmiState
                            && this.props.hmiState.applicationState
                            && this.props.hmiState.applicationState.applicationState === "Stopped"
                            && <span style={{ color: "red" }}>{"Arrêt"}</span>}

                    {!this.hasTimeouted
                        && this.props.hmiState
                        && this.props.hmiState.applicationState
                        && this.props.hmiState.applicationState.applicationState === "Test"
                        && <span style={{ color: "green" }}>{"Test"}</span>}

                    {!this.hasTimeouted
                        && this.props.hmiState
                        && this.props.hmiState.applicationState
                        && this.props.hmiState.applicationState.applicationState === "Expirity"
                        && <span style={{ color: "green" }}>{"DLC"}</span>}
                    
                    {(this.hasTimeouted
                        || (!this.props.hmiState
                        || !this.props.hmiState.applicationState))
                        && <span style={{ color: "red" }}>{"Off"}</span>}
                        </div>*/}
            </div>
        );
    }
}

interface HmiStateButtonProps {
    text: string;
    disabled: boolean;
    state: Api.HmiApplicationStateModelApplicationStateEnum;
    hmi: Api.HmiModel;
    hmiState: HmiState;
    hasTimeouted: boolean;
    setHmiState: { requestTime?: number; isLoading: boolean; };
    col: number;
    row: number;
    onClick: () => void;
}

class HmiStateButton extends React.Component<HmiStateButtonProps, {}> {

    get isCurrent(): boolean {
        return (this.props.hmiState
            && this.props.hmiState.applicationState
            && this.props.state === this.props.hmiState.applicationState.applicationState)
            && !this.props.hasTimeouted;
    }

    public render() {
        return (<div>
            <button className="btn btn-secondary btn-sm"
            type={"button"}
            style={{
                ...(this.isCurrent ? selectedStateBtnStyle : stateBtnStyle),
                width: '100%',
                height: 40,
                fontSize: 15
            }}
            onClick={(e) => {
                this.props.onClick();
            }}
            disabled={(this.props.setHmiState
                && this.props.setHmiState.isLoading)
                || this.isCurrent
                || (this.props.hmiState
                    && this.props.hmiState.applicationState
                    && this.props.hmiState.applicationState.applicationState === "Selling")}>
            {this.props.text}
        </button>
        </div>);
    }
}