import * as Api from '../api/api';
import * as React from 'react';
import * as RobotStore from '../store/Robot';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import RobotForm from './RobotForm';
import { canEditSettings } from '../security/roles';
import * as StockStore from "../store/Stock";

type SettingsRobotProps = SettingsRobotOwnProps
    & RobotStore.RobotState
    & typeof StockStore.actionCreators
    & typeof RobotStore.actionCreators;

interface SettingsRobotOwnProps {
    currentUser: Api.CurrentUserManagerModel;
    disabled: boolean;
}

class SettingsRobot extends React.Component<SettingsRobotProps, {}> {

    handleSubmitForm(value) {
        let arrayAux: Api.ShelvingModel[] = [];

        value.shelvings.forEach(item => {
            let aux: Api.ShelvingModel = {
                shelvingId: item.shelvingId,
                code: item.code,
                offsetX: item.offsetX,
                offsetZ: item.offsetZ
            };
            arrayAux.push(aux);
        });

        let robotM: Api.RobotModel = {
            robotId: value.robotId,
            name: value.name,
            version: value.version,
            robotConfiguration: value.robotConfiguration,
            creationDate: value.creationDate,
            robotExtractors: value.robotExtractors,
            deliveryStations: value.deliveryStations
        }

        this.props.requestUpdateRobot(new Date().getTime(), robotM);
        this.props.requestUpdateShelvings(new Date().getTime(), arrayAux);
    }

    public render() {
        return (
            <div>
                <div style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                    opacity: this.props.disabled ? 0.7 : 1 }}>
                    {this.props.robotsState.robots.map(x => <div key={x.robotId}>
                        <RobotForm robotId={x.robotId}
                                   onSubmit={(value) => this.handleSubmitForm(value)}
                                   calculateOffsets = {() => this.props.requestCalculateOffsets(new Date().getTime())}
                        />
                    </div>)}
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.robot,
    currentUser: state.account.currentUser,
    disabled: !canEditSettings(state)
}), {...RobotStore.actionCreators, ...StockStore.actionCreators})(SettingsRobot as any);