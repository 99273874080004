import * as React from 'react';
import Moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { getCurrentLang } from '../utils/language';

interface DatePickerProps {
    value: Date;
    onChange: (date: Date) => void;
    maxDate?: Date;
    minDate?: Date;
    className?: any;
    showTimeSelect?: boolean;
    disabled?: boolean;
    style?: any;
    setDateButton?: JSX.Element;
    dateToSet?: Date;
    includeTime?: boolean;
    dateFormat?: string
}

interface DatePickerState {
}

export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps: DatePickerProps) {
    }

    handleChange(date: Moment.Moment) {
        if (!date) {
            this.props.onChange(null);
            return;
        }

        this.props.onChange(date.toDate());

    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    render() {
        let color = this.isDarkMode() ? "white;" : "#181F3A;";
        const css = `
        .react-datepicker__time-list {
            padding: 0 !important;
            overflow: scroll;
            overflow-x: hidden;
        }
        .react-datepicker__input-container input {
            color:` +  color + `
        }
        `
        return (<div style={{ ...this.props.style, display: "flex", flexDirection: "row", alignItems: "center", minWidth: 100 }}>
            <style>{css}</style>
            <div style={{ flex: 1 }}>
                <ReactDatePicker                
                    locale={getCurrentLang()}
                    className={this.props.className}
                    disabled={this.props.disabled}
                    selected={this.props.value ? Moment(this.props.value) : null}
                    dateFormat={this.props.dateFormat || 'dd DD/MM/YYYY, HH:mm'}
                    timeFormat="HH:mm"
                    maxDate={this.props.maxDate ? Moment(this.props.maxDate) : undefined}
                    minDate={this.props.minDate ? Moment(this.props.minDate) : undefined}
                    showTimeSelect={this.props.showTimeSelect}
                    onChangeRaw={(e: any) => {
                        let date = Moment(e.target.value.toString(),'dd DD/MM/YYYY, HH:mm',getCurrentLang());
                        if(date.isValid()) {
                            this.handleChange(Moment(date.format('YYYY-MM-DD[T]HH:mm:ss')));                            
                        }
                    }}
                    onChange={(date: Moment.Moment) => this.handleChange(date)} />
            </div>
            {this.props.setDateButton && <div style={{ flex: "0 0 auto" }}
                onClick={(e) => {
                    this.handleChange(this.props.dateToSet
                        ? Moment(this.props.dateToSet)
                        : Moment());
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                {this.props.setDateButton}
            </div>}
        </div>);
    }
}