import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as _ from 'lodash';

export interface PeriodState {
    isDialogOpen: boolean;
    isLoading: boolean;
    requestTime?: number;
    periods: { [id: number]: Api.PeriodModel};
    selectedPeriodId?: number;
    createState: {
        isLoading: boolean;
        requestTime?: number;
    },
    updatesState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        } 
    },
    deletePeriod: {
        isLoading: boolean;
        requestTime?: number;
    };
}

interface RequestPeriods {
    type: "REQUEST_PERIODS";
    payload: { requestTime: number }
}
interface ReceivePeriods {
    type: "RECEIVE_PERIODS";
    payload: { requestTime: number; periods?: { [id: number]: Api.PeriodModel }; };
    error?: any;
}

interface RequestCreatePeriod {
    type: "REQUEST_CREATE_PERIOD";
    payload: { requestTime: number; }
}
interface ReceiveCreatePeriod {
    type: "RECEIVE_CREATE_PERIOD";
    payload: { requestTime: number; period?: Api.PeriodModel; };
    error?: any;
}

interface RequestUpdatePeriod {
    type: "REQUEST_UPDATE_PERIOD";
    payload: { requestTime: number; id: number; }
}
interface ReceiveUpdatePeriod {
    type: "RECEIVE_UPDATE_PERIOD";
    payload: { requestTime: number; period?: Api.PeriodModel; id: number; };
    error?: any;
}

interface SelectPeriod {
    type: "SELECT_PERDIO";
    payload: { id: number; }
}

interface OpenPeriodDialog {
    type: "OPEN_PERIOD_DIALOG";
}
interface ClosePeriodDialog {
    type: "CLOSE_PERIOD_DIALOG";
}

interface RequestDeletePeriod {
    type: "REQUEST_DELETE_PERIOD";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeletePeriod {
    type: "RECEIVE_DELETE_PERIOD";
    payload: { requestTime: number; id: number; };
    error?: any;
}

export type KnownAction = RequestPeriods
    | ReceivePeriods
    | RequestCreatePeriod
    | ReceiveCreatePeriod
    | RequestUpdatePeriod
    | ReceiveUpdatePeriod
    | SelectPeriod
    | OpenPeriodDialog
    | ClosePeriodDialog
    | RequestDeletePeriod
    | ReceiveDeletePeriod;

export const actionCreators = {
    requestPeriods: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PeriodApi();
        let fetchTask = api.getPeriods({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(periods => {
                dispatch({
                    type: "RECEIVE_PERIODS",
                    payload: {
                        periods: periods,
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_PERIODS",
                    payload: {
                        requestTime: requestTime
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_PERIODS",
            payload: {
                requestTime: requestTime
            }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreatePeriod: (requestTime: number, model: Api.PeriodModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PeriodApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(period => {
                dispatch({
                    type: "RECEIVE_CREATE_PERIOD",
                    payload: { requestTime: requestTime, period: period }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_PERIOD",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CREATE_PERIOD",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdatePeriod: (requestTime: number, model: Api.PeriodModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PeriodApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(period => {
                dispatch({
                    type: "RECEIVE_UPDATE_PERIOD",
                    payload: { requestTime: requestTime, period: period, id: model.periodId }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_PERIOD",
                    payload: { requestTime: requestTime, id: model.periodId },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_PERIOD",
            payload: { requestTime: requestTime, id: model.periodId }
        });
        return fetchTask;
    },
    selectPeriod: (id: number) => <SelectPeriod>{
        type: "SELECT_PERDIO",
        payload: { id: id }
    },
    openPeriodDialog: () => <OpenPeriodDialog>{
        type: "OPEN_PERIOD_DIALOG"
    },
    closePeriodDialog: () => <ClosePeriodDialog>{
        type: "CLOSE_PERIOD_DIALOG"
    },    
    requestDeletePeriod: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PeriodApi();
        let fetch = api._delete({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_DELETE_PERIOD",
                    payload: {
                        requestTime: requestTime,
                        id: id
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_PERIOD",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_DELETE_PERIOD", payload: { requestTime: requestTime, id: id } });
        return fetch;
    },
}

const unloadedState: PeriodState = {
    isDialogOpen: false,
    isLoading: false,
    updatesState: {},
    createState: {
        isLoading: false
    },
    deletePeriod: {
        isLoading: false
    },
    periods: []
}

export const reducer: Reducer<PeriodState> = (state: PeriodState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_PERIODS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_PERIODS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                periods: action.error
                    ? state.periods
                    : action.payload.periods
            };
        case "REQUEST_UPDATE_PERIOD":
            return {
                ...state,
                updatesState: {
                    ...state.updatesState,
                    [action.payload.id]: {
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_UPDATE_PERIOD":
            if (!state.updatesState[action.payload.id]
                || state.updatesState[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                periods: action.error
                    ? state.periods
                    : {
                        ...state.periods,
                        [action.payload.period.periodId]: action.payload.period
                    },
                updatesState: {
                    ...state.updatesState,
                    [action.payload.id]: {
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_CREATE_PERIOD":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_PERIOD":
            if (action.payload.requestTime !== state.createState.requestTime)
                return state;

            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                },
                periods: action.error
                    ? state.periods
                    : {
                        ...state.periods,
                        [action.payload.period.periodId]: action.payload.period
                    }
            };
        case "SELECT_PERDIO":
            return {
                ...state,
                selectedPeriodId: action.payload.id
            };
        case "OPEN_PERIOD_DIALOG":
            return {
                ...state,
                isDialogOpen: true
            };
        case "CLOSE_PERIOD_DIALOG":
            return {
                ...state,
                isDialogOpen: false
            };
        
        case "REQUEST_DELETE_PERIOD":
            return {
                ...state,
                deletePeriod: {
                    ...state.deletePeriod,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DELETE_PERIOD":
            if (state.deletePeriod.requestTime !== action.payload.requestTime)
                return state;

            let entitiesAfterDelete = {
                ...state.periods
            };
            if (!action.error) {
                delete entitiesAfterDelete[action.payload.id];
            }

            return {
                ...state,
                deletePeriod: {
                    ...state.deletePeriod,
                    isLoading: false
                },                
                periods: entitiesAfterDelete
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}