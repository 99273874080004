import * as Cookies from "js-cookie";
require('moment/locale/fr'); //Avoid preparse of null - https://github.com/moment/moment/issues/2566

let storedLangCode = "fr";

export const langCookieName = "app-language";
export const getCurrentLang = (): string => {
    return storedLangCode;
};

export const setCurrentLang = (langCode: string): void => {
    storedLangCode = langCode;
};

export const changeLang = (langCode: string): void => {
    Cookies.set(langCookieName, langCode);
    window.location.href="/";
};