import * as React from "react";
import { Chart } from "react-google-charts";

interface GraphProps {
    width: string;
    height: string;
    chartType: any;
    data: any;
    options: any;
}

export default class Graph extends React.Component<GraphProps, {}> {
    componentWillReceiveProps(nextProps: GraphProps) {
        if (this.props.data !== nextProps.data){
            this.render();
        }
    }

    get chart() {
        if(typeof window !== 'undefined') {
           return <Chart
                width={this.props.width}
                height={this.props.height}
                chartType={this.props.chartType}
                loader={<div style={{height: 300, color: "#3B4370", fontSize: 9, display: 'flex', justifyContent: 'center'}}>
                            ...
                        </div>}
                data={this.props.data}
                options={this.props.options}
                rootProps={{ 'data-testid': '1' }}            
            />
        }
    }
    
    componentDidMount() {
    }

    public render() {
        return (
            <div>
                { this.chart }
            </div>
        );
    }
}