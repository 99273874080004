import * as React from "react";
import * as _ from 'lodash';
import Moment from 'moment';
import * as Api from '../api/api';
import * as VoucherStore from '../store/Voucher';
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector, Form } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type VoucherFormProps = InjectedFormProps<{}, {}, {}>
    & VoucherFormOwnProps
    & VoucherFormExternalProps;

interface VoucherFormOwnProps {
    number: number;
    codes: string;
    disabled: boolean;
    amountType: Api.VoucherAmountTypeEnum;
    createVouchersMode: VoucherStore.CreateVouchersMode;
    voucherCampaigns: { [id: number]: Api.VoucherCampaignModel };
}

interface VoucherFormExternalProps {
    updateCreateVouchersMode: (value: VoucherStore.CreateVouchersMode) => void;
    onSubmit: (values: Api.CreateVouchersModel) => Promise<any>
}

export const getVoucherAmountTypeOptions = (): Array<{ label: string; value: Api.VoucherAmountTypeEnum }> => [
    { label: getText("VoucherAmountFlat"), value: "Flat" },
    { label: getText("VoucherAmountPercentage"), value: "Percentage" }
];

const codesField = FormField.textAreaField(6, 20, "ex.\nCODE123\n1234567\n9876543\n  .  .  .  \n");
const marginStyle: React.CSSProperties = { marginBottom: "3%" };
const labelStyle: React.CSSProperties = { fontSize: 14};
class VoucherForm extends React.Component<VoucherFormProps, {}> {
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (<FormField.BaseForm {...this.props}>
            <div className="row selectors borderSelectors" 
                style={{margin: '2% 0 0 0', textAlign: 'left', display: 'flex', justifyContent: 'center'}}>
                <div className="col-md-7">
                    <div className="form-group" style={marginStyle}>
                        <label style={labelStyle}>{getText( "VoucherCampaign")} : </label>
                        <Field name="VoucherCampaignId"
                            validate={[FormField.required]}
                            component={FormField.getSelectField(
                            _.values(this.props.voucherCampaigns)
                                .filter(x => x.type === "Normal")
                                .map(x => ({
                                    label: x.name,
                                    value: x.voucherCampaignId
                                }))
                            )} 
                        />
                    </div>
                    <div style={{display: 'flex', placeContent: 'center'}}>
                        <div style={{marginRight: 60}}>
                            <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherStartDate")} : </label>
                                <Field name="startDate"
                                    minDate={Moment().startOf("day").toDate()}
                                    validate={[FormField.required]}
                                    showTimeSelect={true}
                                    component={FormField.DatePickerField} />
                            </div>
                            <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherEndDate")} : </label>
                                <Field name="endDate"
                                    minDate={Moment().startOf("day").toDate()}
                                    validate={[FormField.required]}
                                    showTimeSelect={true}
                                    component={FormField.DatePickerField} />
                            </div>
                            <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherType")} : </label>
                                <Field name="amountType"
                                    className="form-control"
                                    validate={[FormField.required]}
                                    component={FormField.getSelectField(getVoucherAmountTypeOptions())} />
                            </div>
                            <div className="form-group" style={marginStyle}>
                                {this.props.amountType === "Flat"
                                    && <label style={labelStyle}>{getText( "VoucherModalFixedAmount")} : </label>}
                                {this.props.amountType === "Percentage"
                                    && <label style={labelStyle}>{getText( "VoucherModalPercentageAmount")}: </label>}
                                <Field name="amount"
                                        className="form-control"
                                        validate={[
                                            FormField.required,
                                            FormField.minOne,
                                            FormField.onlyNumbers,
                                            this.props.amountType === "Percentage" ? FormField.minValue(1) : FormField.requiredNotZero,
                                            this.props.amountType === "Percentage" ? FormField.maxValue(100) : FormField.requiredNotZero
                                        ]}
                                        component={FormField.NumberField} />
                            </div>
                        </div>
                        <div >
                            {this.props.amountType === "Percentage"
                                && <div className="form-group" style={marginStyle}>
                                    <label style={labelStyle}>{getText( "VoucherAmount2")} : </label>
                                    <Field name="maxAmount"
                                        className="form-control"
                                        validate={[FormField.required, FormField.minOne, FormField.onlyNumbers]}
                                        component={FormField.NumberField} />
                                </div>
                            }
                            <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherAmountMin")} : </label>
                                <Field name="minOrderAmount"
                                    className="form-control"
                                    validate={[FormField.required, FormField.minOne, FormField.onlyNumbers]}
                                    component={FormField.NumberField} />
                            </div>
                            <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherTimesToUse")} : </label>
                                <Field name="maxUse"
                                    className="form-control"
                                    validate={[FormField.required, FormField.minOne, FormField.onlyNumbers]}
                                    component={FormField.NumberField} />
                            </div>
                            <div className="form-group" style={{...marginStyle}}>
                                <div
                                    style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ flex: 1, textAlign: "center" }}>
                                        <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}
                                            onClick={(e) => {
                                                this.props.updateCreateVouchersMode("Number");
                                            }}>
                                            <input type="radio" name="creationMode" defaultChecked={this.props.createVouchersMode === "Number"} />
                                            <div style={{...labelStyle, marginLeft: '5%'}}>{getText( "VoucherModalAuto")}</div>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, textAlign: "center", marginLeft: 6 }}>
                                        <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}
                                            onClick={(e) => {
                                                this.props.updateCreateVouchersMode("Codes");
                                            }}>
                                            <input type="radio" name="creationMode" defaultChecked={this.props.createVouchersMode === "Codes"} />
                                            <div style={{...labelStyle, marginLeft: '5%'}}>{getText( "VoucherModalManual")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.createVouchersMode === "Number" && <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherQty")} : </label>
                                <Field name="number"
                                    className="form-control"
                                    validate={[FormField.required, FormField.minOne, FormField.onlyNumbers]}
                                    component={FormField.NumberField} />
                            </div>}
                            {this.props.createVouchersMode === "Codes" && <div className="form-group" style={marginStyle}>
                                <label style={labelStyle}>{getText( "VoucherCodesToUse")} : </label>
                                <Field name="codes"
                                    className="form-control"
                                    validate={[FormField.required]}                                    
                                    component={codesField} />
                            </div>}
                        </div>
                    </div>                    
                    <div className="form-group">
                        <button type={"submit"}
                            style={{
                                width: '100%', 
                                fontSize: 15, 
                                marginTop: '3%',
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            className="btn btn-primary"
                            disabled={this.props.submitting || this.props.disabled}>
                            {getText( "VoucherCreate2")}
                        </button>
                    </div>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

let form = reduxForm({
    form: 'createVouchers',
    enableReinitialize: true,
    destroyOnUnmount: true
})(VoucherForm as any) as any;
const createSelector = formValueSelector('createVouchers');

export const CreateVouchersForm = connect((state: ApplicationState) => {
    return {
        initialValues: {
            startDate: Moment().startOf("day").toDate(),
            endDate: Moment().add(2, "months").startOf("day").toDate(),
            maxUse: 1,
            number: 1,
            maxAmount: 1,
            amountType: "Flat"
        },
        voucherCampaigns: state.voucher.result
            ? state.voucher.result.voucherCampaigns
            : {},
        number: createSelector(state, "number"),
        codes: createSelector(state, "codes"),
        amountType: createSelector(state, "amountType") || "Flat",
        createVouchersMode: state.voucher.createVouchersMode,
        maxUser: 1,
        disabled: !canUpdate(state)
    } as VoucherFormOwnProps
}, null)(form as any) as any as React.ComponentClass<VoucherFormExternalProps>