import * as Api from '../api/api';
import Moment from 'moment';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from "react-table";
import Table from "./Table";
import { getText } from '../utils/texts';

interface EventViewerProps {
    tableKey: string;
    events: Array<Api.NeoEventModel>;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
}
type Column = ReactTable.Column
    & { accessor: (d: Api.NeoEventModel) => any; }

export default class EventViewer extends React.Component<EventViewerProps, {}> {
    public render() {
        return (
                <div style={{height:"100%"}}>
                    <Table
                        className={"test-event-table"}
                        key={this.props.tableKey}
                        showPagination={false}
                        showPaginationBottom={false}
                        pageSize={Math.max(this.props.events.length,15)}
                        style={{height: '100%' }}
                        data={_.sortBy(this.props.events, x => - new Date(x.creationDate).getTime())}
                        columns={[
                            {
                                id: "date",
                                Header: <div>{getText( "MaintenanceDate")}</div>,
                                accessor: d => Moment(d.creationDate).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY HH:mm:ss")
                            },
                            {
                                id: "source",
                                accessor: (d) => this.props.neoEventCodes[d.neoEventCodeId].source,
                                Header: <div>{getText( "MaintenanceType")}</div>,
                                Cell: row => <div>{row.value}</div>
                            },
                            {
                                id: "code",
                                Header: <div>{getText( "MaintenanceCode")}</div>,
                                accessor: d => this.props.neoEventCodes[d.neoEventCodeId].code
                            },
                            {
                                id: "message",
                                Header: <div>{getText( "MaintenanceMessage")}</div>,
                                accessor: d => d.message
                            },
                        ] as Array<Column>} />
                </div>
            );
    }
}