import * as React from "react";
import * as _ from 'lodash';
import * as ReactTable from 'react-table';
import * as Api from '../api/api';
import * as StockStore from "../store/Stock";
import * as TabsStore from "../store/Tabs";
import Table from "./Table";
import { connect } from "react-redux";
import ConnectedTabs from "./ConnectedTabs";
import Confirm from "react-confirm-bootstrap";
import { TabLink, TabContent } from "react-tabs-redux";
import { CreateShelvingForm, UpdateShelvingForm } from "./ShelvingForm";
import { ApplicationState } from "../store";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { canEditSettings, canUpdate } from "../security/roles";

type ShelvingProps = StockStore.StockState
    & ShelvingOwnProps
    & typeof StockStore.actionCreators
    & typeof TabsStore.actionCreators
    ;

interface ShelvingOwnProps {
    stockStatus: Api.StockStatusModel;
    currentUser: Api.CurrentUserManagerModel;  
    disabled: boolean;
}

interface ShelvingState {
    quantityShelAuto: number;
}

type ShelvingColumn = ReactTable.Column
    & { accessor: (d: Api.ShelvingModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class ShelvingDialog extends React.Component<ShelvingProps, ShelvingState> {

    constructor(props: ShelvingProps) {
        super(props);
        this.state = {
            quantityShelAuto: 1
        }
    }

    componentDidMount() {
    }

    handleUpdateVal(value: number){
        this.setState({
            quantityShelAuto: value
        });
    }

    public render() {
        return (
            <div>
                <div className="card" style={{...Styles.cardStyle}}>
                    <Table key={"shelvings"}
                        pageSize={Math.max(15,_.values(this.props.stockStatus ? this.props.stockStatus.shelvings : []).length)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        className="shelvingTrayTable"
                        style={{ }}
                        data={_.values(this.props.stockStatus ? this.props.stockStatus.shelvings : [])}
                        columns={[
                            {
                                id: "name",
                                Header: getText( "SettingsNameTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.name}</div>
                            },
                            {
                                id: "code",
                                Header: getText( "SettingsCode"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.code}</div>
                            },
                            {
                                id: "longueur",
                                Header: getText( "SettingsLengthTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.width}</div>
                            },
                            {
                                id: "hauteur",
                                Header: getText( "SettingsHeightTable"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.railHeight}</div>
                            },
                            {
                                id: "face",
                                Header: getText( "SettingsFace"),
                                accessor: (d) => <div style={{lineHeight: '31px'}}>{d.lane ? d.lane : <span>&nbsp;</span> }</div>
                            },
                            {
                                id: "actions",
                                Header: getText( "SettingsActions"),
                                accessor: (d) => d.shelvingId,
                                Cell: row => <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5
                                        }}>
                                            <i className="glyphicon glyphicon-pencil icon-dm"
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                                                    opacity: this.props.disabled ? 0.7 : 1,
                                                    margin: "11% 0", 
                                                    cursor: "pointer",
                                                    color: "#FFFFFF" }}
                                                    onClick={(e) => {
                                                        this.props.selectShelving(row.value);
                                                        this.props.selectTab("shelvings", "update");
                                                        e.stopPropagation();
                                                    }} />
                                    </div>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        cursor: "pointer",
                                        borderRadius: 5
                                        }}>
                                            <Confirm
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                                    opacity: this.props.disabled ? 0.7 : 1, color: "#FFFFFF", 
                                                    marginTop: '15%',
                                                    marginRight: 1,  }}
                                                onConfirm={() => {
                                                    this.props.requestDeleteShelving(new Date().getTime(), row.value);
                                                    this.props.selectShelving(undefined);
                                                }}
                                                body={getText( "MaintenanceShelvingBodyConfirm")}
                                                confirmText={getText( "MaintenanceConfirm")} title={getText( "MaintenanceConfirmDeleteTitle")}>
                                                    <i className="glyphicon glyphicon-trash icon-dm"/>
                                            </Confirm>
                                    </div>
                                </div>
                            }
                        ] as Array<ShelvingColumn>} />
                </div>
                <div style={{marginTop: '1%'}}>
                    <ConnectedTabs name={"shelvings"}>
                        <TabLink style={{width: '50%'}} to="create">
                            <div style={tabHeadStyle}>{getText( "SettingsCreate")}</div>
                        </TabLink>
                        <TabLink style={{width: '50%'}} to="update">
                            <div style={tabHeadStyle}>{getText( "SettingsEdit")}</div>
                        </TabLink>
                        <TabContent for="create">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1, paddingTop: 5 }}>
                                {this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        {_.values(this.props.stockStatus ? this.props.stockStatus.shelvings : []).length >= 1 &&
                                            <div className="row justify-content-md-center" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                                                <div className="col-md-2 shelvingTrayFormCol"></div>
                                                <div className="col-md-2 shelvingTrayFormCol">
                                                    <label className="control-label">{getText( "SettingsCreateAuto")}</label>            
                                                </div>
                                                <div className="col-md-3 shelvingTrayFormCol">
                                                <input className="form-control"
                                                    style= {{height: 32}}
                                                    disabled={this.props.disabled}
                                                    type={"number"}
                                                    min="1"
                                                    name={"quantityShelvings"}
                                                    value={this.state.quantityShelAuto}
                                                    required
                                                    onChange={(e) => this.handleUpdateVal(parseInt(e.target.value) || 1)} />
                                                </div>
                                                <div className="col-md-3 shelvingTrayFormCol">
                                                    <button className="btn btn-primary btn-sm"
                                                        style={{ width: "100%", height: 32 }}
                                                        disabled={this.props.disabled}
                                                        onClick={(e) => this.props.requestCreateShelvingAuto(new Date().getTime(), this.state.quantityShelAuto)}>{getText("SettingsCreate")}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        <CreateShelvingForm
                                            onSubmit={(values) => this.props.requestCreateShelving(new Date().getTime(), values) as any} />
                                    </div>
                                }
                            </div>
                        </TabContent>
                        <TabContent for="update">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1, paddingTop: 5 }}>
                                {this.props.selectedShelvingId && this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <UpdateShelvingForm
                                            onSubmit={(values) => this.props.requestUpdateShelving(new Date().getTime(), values) as any} />
                                    </div>
                                }
                            </div>
                        </TabContent>
                    </ConnectedTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.stock,
    periods: state.period.periods,
    priceNames: state.seed.seed.priceNames,
    stockStatus: state.stock.stockStatus,
    currentUser: state.account.currentUser,
    disabled: !canEditSettings(state)
} as ShelvingOwnProps), {
        ...TabsStore.actionCreators,
        ...StockStore.actionCreators,
    })(ShelvingDialog as any);