import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { push } from "connected-react-router";
import { createSelector } from 'reselect';

export interface ContactState {
    isLoading: boolean;
    requestTime?: number;
    selectedContactId?: number;
    contacts: { [id: number]: Api.ContactModel};
    createState: {
        isLoading: boolean;
        requestTime?: number;
    };
    contactUpdateStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
    contactDeleteStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
    isDialogOpen: boolean;
}

interface ContactRequestEntities {
    type: "CONTACT_REQUEST_ENTITIES";
    payload: { requestTime: number }
}
interface ContactReceiveEntities {
    type: "CONTACT_RECEIVE_ENTITIES";
    payload: {
        requestTime: number;
        contacts?: { [id: number]: Api.ContactModel };
    },
    error?: any
}

interface ContactRequestCreate {
    type: "CONTACT_REQUEST_CREATE";
    payload: { requestTime: number; }
}
interface ContactReceiveCreate {
    type: "CONTACT_RECEIVE_CREATE";
    payload: { requestTime: number; value?: Api.ContactModel }
    error?: any
}

interface ContactRequestUpdate {
    type: "CONTACT_REQUEST_UPDATE";
    payload: { requestTime: number; id: number; }
}
interface ContactReceiveUpdate {
    type: "CONTACT_RECEIVE_UPDATE";
    payload: { requestTime: number; id: number; value?: Api.ContactModel }
    error?: any
}

interface ContactRequestDelete {
    type: "CONTACT_REQUEST_DELETE";
    payload: { requestTime: number; id: number; }
}
interface ContactReceiveDelete {
    type: "CONTACT_RECEIVE_DELETE";
    payload: { requestTime: number; id: number; }
    error?: any
}

interface ContactUpdateSelectedContactId {
    type: "CONTACT_UPDATE_SELECTED_CONTACTID";
    payload: { id: number; }
}

interface OpenContactsDialog {
    type: "OPEN_CONTACTS_DIALOG"
}
interface CloseContactsDialog {
    type: "CLOSE_CONTACTS_DIALOG"
}

type KnownAction = ContactRequestEntities
    | ContactReceiveEntities
    | ContactRequestCreate
    | ContactReceiveCreate
    | ContactRequestUpdate
    | ContactReceiveUpdate
    | ContactRequestDelete
    | ContactReceiveDelete
    | ContactUpdateSelectedContactId
    | OpenContactsDialog
    | CloseContactsDialog;

export const actionCreators = {
    requestContacts: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ContactApi();
        let fetchTask = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contacts => {
                dispatch({
                    type: "CONTACT_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime, contacts: contacts }
            });
        })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "CONTACT_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "CONTACT_REQUEST_ENTITIES",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreateContact: (requestTime: number, model: Api.ContactModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ContactApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contact => {
                dispatch({
                    type: "CONTACT_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, value: contact }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "CONTACT_RECEIVE_CREATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "CONTACT_REQUEST_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateContact: (requestTime: number, model: Api.ContactModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ContactApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contact => {
                dispatch({
                    type: "CONTACT_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.contactId, value: contact }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "CONTACT_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.contactId },
                    error: err
                });
            });

        dispatch({
            type: "CONTACT_REQUEST_UPDATE",
            payload: { requestTime: requestTime, id: model.contactId }
        });
        return fetchTask;
    },
    requestDeleteContact: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ContactApi();
        let fetchTask = api._delete({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contact => {
                dispatch({
                    type: "CONTACT_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "CONTACT_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "CONTACT_REQUEST_DELETE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetchTask;
    },
    updateSelectedContactId: (id: number) => <ContactUpdateSelectedContactId>{
        type: "CONTACT_UPDATE_SELECTED_CONTACTID",
        payload: { id: id }
    },
    openContactsDialog: () => <OpenContactsDialog>{
        type: "OPEN_CONTACTS_DIALOG"
    },
    closeContactsDialog: () => <CloseContactsDialog>{
        type: "CLOSE_CONTACTS_DIALOG"
    }
};

const unloadedState: ContactState = {
    isLoading: false,
    contacts: [],
    contactUpdateStates: {},
    contactDeleteStates: {},
    createState: {
        isLoading: false
    },
    isDialogOpen: false
};

export const reducer: Reducer<ContactState> = (state: ContactState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "CONTACT_REQUEST_ENTITIES":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "CONTACT_RECEIVE_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isloading: false,
                contacts: action.payload.contacts,
            };
        case "CONTACT_REQUEST_CREATE":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "CONTACT_RECEIVE_CREATE":
            return {
                ...state,
                contacts: action.error
                    ? state.contacts
                    : {
                        ...state.contacts,
                        [action.payload.value.contactId]: action.payload.value
                    },
                createState: {
                    ...state.createState,
                    isLoading: action.payload.requestTime !== state.createState.requestTime
                        ? state.createState.isLoading
                        : false
                }
            };
        case "CONTACT_REQUEST_UPDATE":
            return {
                ...state,
                contactUpdateStates: {
                    ...state.contactUpdateStates,
                    [action.payload.id]: {
                        ...state.contactUpdateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "CONTACT_RECEIVE_UPDATE":
            if (!state.contactUpdateStates[action.payload.id]
                || state.contactUpdateStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                contactUpdateStates: {
                    ...state.contactUpdateStates,
                    [action.payload.id]: {
                        ...state.contactUpdateStates[action.payload.id],
                        isLoading: false,
                    }
                },
                contacts: action.error
                    ? state.contacts
                    : {
                        ...state.contacts,
                        [action.payload.value.contactId]: action.payload.value
                    }
            };
        case "CONTACT_REQUEST_DELETE":
            return {
                ...state,
                contactDeleteStates: {
                    ...state.contactDeleteStates,
                    [action.payload.id]: {
                        ...state.contactDeleteStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "CONTACT_RECEIVE_DELETE":
            if (state.contactDeleteStates[action.payload.id]
                && action.payload.requestTime !== state.contactDeleteStates[action.payload.id].requestTime)
                return state;

            let contactsAfterDelete = {
                ...state.contacts
            };
            if (!action.error) {
                delete contactsAfterDelete[action.payload.id];
            }
            return {
                ...state,
                contacts: contactsAfterDelete,
                contactDeleteStates: {
                    ...state.contactDeleteStates,
                    [action.payload.id]: {
                        ...state.contactDeleteStates[action.payload.id],
                        isLoading: false,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "CONTACT_UPDATE_SELECTED_CONTACTID":
            return {
                ...state,
                selectedContactId: action.payload.id
            };
        case "OPEN_CONTACTS_DIALOG":
            return {
                ...state,
                isDialogOpen: true
            };
        case "CLOSE_CONTACTS_DIALOG":
            return {
                ...state,
                isDialogOpen: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};