import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import * as OfferStore from '../store/Offer';
import * as StockStore from "../store/Stock";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { CSSProperties } from 'react';
import * as ReactTable from "react-table";
import Table from "./Table";
import ItemOffer from "./ItemOffer";
import Select from "./Select";
import { canUpdate } from '../security/roles';
import DatePicker from "./DatePicker";
import { getText } from '../utils/texts';
import DialogModal from "./DialogModal";

type OfferPromoProps = OfferPromoOwnProps
    & OfferStore.OfferState
    & typeof StockStore.actionCreators
    & typeof OfferStore.actionCreators;

interface OfferPromoOwnProps {
    stock: StockStore.StockState;
    currency: Api.CurrencyModel;
    promotions: { [id: number]: Api.PromotionModel };
    priceNames: { [id: number]: Api.PriceNameModel };
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
    products: Array<Api.ProductModel>;
    availableProducts: Array<Api.ProductModel>; //For itemOfTheDay selection
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;
}

type OfferPromoColumn = ReactTable.Column
    & {
        accessor: (d: Api.ProductModel) => any;
        style?: CSSProperties;
        columns?: Array<OfferPromoColumn>
    };

class OfferPromo extends React.Component<OfferPromoProps, {}> {
    componentDidMount() {
        this.props.requestItemOfferResult(new Date().getTime());
    }

    get currentItemOfTheDay(): Api.ItemOfTheDayModel {
        return (this.props.itemOfferResult && this.props.itemOfferResult.itemOfTheDay)
            || {};
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        this.props.updateOfferFilter({});
    }

    public render() {
        return (
            <div style={{ height: "100%" }}>
                <div className="selectors" style={{ display: 'flex', margin: '0 1% 1% 1%' }}>
                    <div style={{ margin: "0px 1px", width: '25%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferFamily")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <Select options={[{ label: "------", value: undefined }]
                                .concat(_.values(this.props.families)
                                    .map(x => ({
                                        label: x.name,
                                        value: x.familyId
                                    })))}
                                value={this.props.filter.familyId}
                                onChange={(value) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    familyId: value
                                })}
                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 1px", width: '25%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferSubfamily")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <Select options={[{ label: "------", value: undefined }]
                                .concat(_.values(this.props.subFamilies)
                                    .filter(x => x.familyId === this.props.filter.familyId)
                                    .map(x => ({
                                        label: x.name,
                                        value: x.subFamilyId
                                    })))}
                                value={this.props.filter.subFamilyId}
                                onChange={(value) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    subFamilyId: value
                                })}
                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", width: 290 }} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 1px", width: '25%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferName")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <input type="text"
                                style={{ height: 40 }}
                                className="form-control input-sm"
                                value={this.props.filter.productName}
                                onChange={(e) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    productName: e.target.value
                                })} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 1px", width: '25%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferHavePromo")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <Select options={[
                                { label: "------", value: undefined },
                                { label: getText("OfferYes"), value: true },
                                { label: getText("OfferNo"), value: false }]}
                                value={this.props.filter.hasOffer}
                                onChange={(value) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    hasOffer: value
                                })}
                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                        </div>
                    </div>
                </div>
                <div className="selectors borderSelectors">
                    <div style={{ display: 'flex', fontSize: 12, margin: '1% 1%' }}>
                        <div className="card" style={{ ...Styles.cardStyle, textAlign: "center", minHeight: 80, width: '50%', margin: "0px 1px" }}>
                            <div style={{ padding: 20 }}>
                                <div style={Styles.filterLabelStyle}>{getText("OfferProductDay")}</div>
                                <div style={{
                                    ...Styles.filterControlStyle, display: 'flex', flexDirection: 'column',
                                    justifyContent: 'center', height: '80%', fontSize: 12
                                }}>
                                    <Select options={[{ label: "------", value: undefined }]
                                        .concat(_.values(this.props.availableProducts)
                                            .map(x => ({
                                                label: x.name,
                                                value: x.storeItemId
                                            })))}
                                        value={this.currentItemOfTheDay.storeItemId}
                                        disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled || this.props.itemOfTheDayState.isLoading}
                                        onChange={(value) => this.props.requestUpdateItemOfTheDay(new Date().getTime(), value)}
                                        style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ ...Styles.cardStyle, textAlign: "center", minHeight: 80, width: '50%', margin: "0px 1px" }}>
                            <div style={{ padding: '10px 2px' }}>
                                <div style={{ marginTop: '1%' }}>
                                    {_.values(this.props.promotions).map(x => <div key={x.promotionId}>
                                        <div style={{ display: "flex", alignItems: 'center', marginBottom: "1%" }}>
                                            <div style={Styles.filterLabelStyle}>{x.name}</div>
                                            <div className="col-md-6" style={{ width: '-webkit-fill-available', padding: 0 }}>
                                                <button key={x.promotionId}
                                                    className="btn btn-primary"
                                                    style={{ fontSize: 12, fontWeight: 'bold', width: 150, height: 40, float: 'right' }}
                                                    disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                    onClick={(e) => {
                                                        this.props.updateNewDateSelected(true)
                                                        _.values(this.props.promotions)
                                                            .map(promotion => {
                                                                this.props.products.forEach(product => {
                                                                    if (this.props.promotionDate || this.props.promotionDefaultPriceNameId[promotion.promotionId]) {
                                                                        let itemOffer = this.props.itemOfferResult
                                                                            && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                                                                            && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                                                                                .find(x => x.promotionId === promotion.promotionId);
                                                                        if (itemOffer) {
                                                                            let priceNameId = this.props.promotionDefaultPriceNameId[promotion.promotionId]
                                                                                && product.prices.some(y => y.priceNameId == this.props.promotionDefaultPriceNameId[promotion.promotionId]) ?
                                                                                this.props.promotionDefaultPriceNameId[promotion.promotionId] : itemOffer.priceNameId;
                                                                            let dateProm = this.props.promotionDate ? this.props.promotionDate : itemOffer.dateEnd;
                                                                            if (dateProm) {
                                                                                this.props.updateItemPromotionOffer(
                                                                                    itemOffer.storeItemId,
                                                                                    promotion.promotionId,
                                                                                    dateProm,
                                                                                    priceNameId);
                                                                            }
                                                                            if (promotion.promotionId) {
                                                                                this.props.requestUpdateItemPromotion(
                                                                                    new Date().getTime(),
                                                                                    itemOffer.storeItemId,
                                                                                    promotion.promotionId);
                                                                            }
                                                                        }
                                                                    }

                                                                });
                                                            });
                                                    }}>
                                                    {getText("OfferUpdateAll")}
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                            <input type="checkbox"
                                                className="offer-checkbox"
                                                style={{ flex: "0 0 auto", margin: 0 }}
                                                //checked={false}
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                onChange={(e) => {
                                                    this.props.products.forEach(product => {
                                                        this.props.updateCheckedItemOffer(product.storeItemId, x.promotionId, false)
                                                        let itemOffer = this.props.itemOfferResult
                                                            && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                                                            && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                                                                .find(x => x.promotionId === x.promotionId);
                                                        if (e.target.checked) {
                                                            if (product.prices
                                                                && (product.prices.some(y => y.priceNameId == this.props.promotionDefaultPriceNameId[x.promotionId]) ||
                                                                    product.prices.some(y => this.props.priceNames[y.priceNameId].name === "Tarif-10") || //Is this the best way to find the default Tarif?
                                                                    product.prices.some(y => this.props.priceNames[y.priceNameId].name === "TarifPlein"))) { 
                                                                let priceNameId = product.prices.some(y => y.priceNameId == this.props.promotionDefaultPriceNameId[x.promotionId]) ?
                                                                    this.props.promotionDefaultPriceNameId[x.promotionId] :
                                                                        product.prices.some(y => this.props.priceNames[y.priceNameId].name === "Tarif-10") ? 
                                                                            product.prices.find(y => this.props.priceNames[y.priceNameId].name === "Tarif-10").priceNameId
                                                                            :  product.prices.find(y => this.props.priceNames[y.priceNameId].name === "TarifPlein").priceNameId;
                                                                if (itemOffer) {
                                                                    this.props.requestUpdateItemPromotion(
                                                                        new Date().getTime(),
                                                                        product.storeItemId,
                                                                        x.promotionId);
                                                                } else {
                                                                    if (!priceNameId || !product.prices.some(y => y.priceNameId == priceNameId)) {
                                                                        priceNameId = (product.prices[0] && product.prices[0].priceNameId);
                                                                    }
                                                                    this.props.requestCreateItemPromotion(
                                                                        new Date().getTime(),
                                                                        product.storeItemId,
                                                                        x.promotionId,
                                                                        priceNameId,
                                                                        this.props.promotionDate
                                                                    );
                                                                }
                                                                if (this.props.promotionDate && this.props.isNewDateSelected) {
                                                                    this.props.updateDateEndItemOffer(product.storeItemId, x.promotionId, this.props.promotionDate);
                                                                }
                                                            }
                                                        } else {
                                                            if (itemOffer) {
                                                                this.props.requestDeleteItemOffer(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    x.promotionId);
                                                            } else {
                                                                this.props.resetItemOfferState(product.storeItemId, x.promotionId);
                                                            }
                                                        }


                                                    });
                                                }} />
                                            <div style={{ flex: "1", marginLeft: 5 }}>
                                                <Select options={[{ label: "------", value: undefined }]
                                                    .concat(_.values(this.props.priceNames)
                                                        .map(x => ({
                                                            label: x.name,
                                                            value: x.priceNameId
                                                        })))}
                                                    disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                    value={this.props.promotionDefaultPriceNameId[x.promotionId]}
                                                    onChange={(value) => this.props.updatePromotionDefaultPriceNameId(value, x.promotionId)}
                                                    style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                                            </div>
                                            <div style={{ flex: "0 0 auto", marginLeft: 5, marginRight: 2 }}>
                                                <DatePicker
                                                    value={this.props.promotionDate}
                                                    className="form-control input-sm"
                                                    disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                    minDate={Moment().startOf("day").toDate()}
                                                    dateFormat={'dd DD/MM/YYYY'}
                                                    showTimeSelect={true}
                                                    style={{ width: 115 }}
                                                    onChange={(newDate) => {
                                                        this.props.updatePromotionDate(newDate);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offer-table card selectors borderSelectors" style={{ ...Styles.cardStyle, margin: "0 1%", height: "80%" }}>
                    <Table tableKey={"offerPromo"}
                        style={{ fontSize: 11, overflow: "hidden", height: '100%' }}
                        loading={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading))
                            || this.props.stock.isLoading
                            || this.props.isLoading}
                        columns={[
                            {
                                id: "name",
                                Header: <div style={{ lineHeight: '65px', width: '-webkit-fill-available' }}><strong>{getText("OfferName")}</strong></div>,
                                accessor: d => d.name,
                                style: {
                                    width: '-webkit-fill-available',
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1,
                                    textAlign: 'left',
                                    lineHeight: '31px'
                                },
                            }
                        ].concat(_.values(this.props.promotions)
                            .map(promotion => ({
                                id: `promotion-${promotion.promotionId}`,
                                Header:
                                    <div>
                                        <div style={{
                                            padding: '2%', height: 34,
                                            borderBottom: this.isDarkMode() ? '1px solid transparent' : '1px solid rgba(255, 255, 255, 0.16)'
                                        }}>
                                            <strong>{promotion.name}</strong>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ margin: 'auto' }}>
                                                {getText("OfferPromoRate")}
                                            </div>
                                            <div style={{ margin: 'auto' }}>
                                                {getText("OfferUntil")}
                                            </div>
                                        </div>
                                    </div>,
                                accessor: d => d,
                                style: {
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, height: "100%", overflow: "visible"
                                } as CSSProperties,
                                Cell: row => {
                                    let product = row.value as Api.ProductModel;
                                    let itemOffer = this.props.itemOfferResult
                                        && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                                        && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                                            .find(x => x.promotionId === promotion.promotionId);

                                    return <ItemOffer currency={this.props.currency}
                                        itemOfferState={this.props.itemOfferStates[product.storeItemId]
                                            ? this.props.itemOfferStates[product.storeItemId][promotion.promotionId]
                                            : undefined}
                                        disabled={this.props.disabled}
                                        priceNames={this.props.priceNames}
                                        prices={product.prices}
                                        defaultPriceNameId={this.props.promotionDefaultPriceNameId[promotion.promotionId]}
                                        itemOffer={itemOffer ? {
                                            dateEnd: itemOffer.dateEnd,
                                            priceNameId: itemOffer.priceNameId,
                                        } : undefined}
                                        defaultDateEnd={this.props.promotionDate ? this.props.promotionDate : undefined}
                                        hasDateEnd={true}
                                        hasDaysBeforeExpirity={false}
                                        onValidate={() => {
                                            if (itemOffer) {
                                                this.props.requestUpdateItemPromotion(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    promotion.promotionId);
                                            } else {
                                                this.props.requestCreateItemPromotion(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    promotion.promotionId,
                                                    this.props.promotionDefaultPriceNameId[promotion.promotionId]
                                                        && product.prices.some(y => y.priceNameId == this.props.promotionDefaultPriceNameId[promotion.promotionId]) ?
                                                        this.props.promotionDefaultPriceNameId[promotion.promotionId] : undefined,
                                                    this.props.promotionDate ? this.props.promotionDate : undefined);
                                            }
                                            if (this.props.promotionDate && this.props.isNewDateSelected) {
                                                this.props.updateDateEndItemOffer(product.storeItemId, promotion.promotionId, this.props.promotionDate);
                                            }
                                        }}
                                        onDelete={() => {
                                            if (itemOffer) {
                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                                this.props.requestDeleteItemOffer(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    promotion.promotionId);
                                            } else {
                                                this.props.resetItemOfferState(product.storeItemId, promotion.promotionId);
                                            }
                                        }}
                                        updateDaysBeforeExpirity={(value) => this.props.updateDaysBeforeExpirityItemOffer(product.storeItemId, promotion.promotionId, value)}
                                        updateDateEnd={(value) => this.props.updateDateEndItemOffer(product.storeItemId, promotion.promotionId, value)}
                                        updateChecked={(value) => this.props.updateCheckedItemOffer(product.storeItemId, promotion.promotionId, value)}
                                        updatePriceNameId={(value) => this.props.updatePriceNameIdItemOffer(product.storeItemId, promotion.promotionId, value)} />;
                                },
                            } as any))) as Array<OfferPromoColumn>}
                        pageSize={Math.max(this.props.products.length, 12)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        data={this.props.products}>
                    </Table>
                </div>
                <DialogModal contentLabel={"Offer dialog"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                        width: 500, 
                        maxHeight: 400,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    title={getText( "OfferDialogTitle")}
                    isOpen={this.props.dialogOpenOffer.isOpen}
                    onRequestClose={() => this.props.closeDialogModalOffer()}>
                    <div>
                        <h4 style={{margin: "40 30", overflowWrap: 'break-word'}}>
                            {this.props.dialogOpenOffer.message}
                        </h4>
                        <button className="btn btn-secondary btn-sm"
                            type={"button"}
                            style={{
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.props.closeDialogModalOffer()}> {getText( "MaintenanceCorrect")} </button>
                    </div>
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.offer,
    stock: state.stock,
    currency: state.seed.seed.currencies[state.settings.storeSettings.currencyId],
    products: OfferStore.productPromoFilteredSelector(state),
    availableProducts: StockStore.availableProductsSelector(state),
    promotions: state.seed.seed.promotions,
    priceNames: state.seed.seed.priceNames,
    families: state.seed.seed.families,
    subFamilies: state.seed.seed.subFamilies,
    disabled: !canUpdate(state),
    currentUser: state.account.currentUser,
} as OfferPromoOwnProps), {
    ...OfferStore.actionCreators,
    ...StockStore.actionCreators
})(OfferPromo as any)