import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import {createSelector} from 'reselect';
import * as Notification from "react-notification-system-redux";
import { getText } from '../utils/texts';

export interface SalesTariffState {
    isSaving: boolean;
    requestTime?: number;
    pricesUpdated:  { [id: number]: Api.PriceModel};  //[PriceId]
    tvasUpdated: { [id: number]: Api.ProductVatModel};  //[ProductVatId]
    convFac1: string;
    convFac2: string;
}

export interface SalesTariffForTableModel {
    "productId"?: number;
    "name"?: string;
    "pmp"?: number;
    "buyPrice"?: number;
    "pVatId"?: number;
    "vatId"?: number;
    "vatName"?: string;
    "price"?: Api.PriceModel;
    "priceNoVat"?: number;
    "delta"?: number;
    "marge"?: number;
    "marquePercentage"?: number;
    "margePercentage"?: number;
    "price2"?:Api.PriceModel;
    "priceNoVat2"?: number;
    "delta2"?: number;
    "marge2"?: number;
    "marquePercentage2"?: number;
    "margePercentage2"?: number;
}

interface SalesTariffUpdateConvFac1 {
    type: "SALESTARIFF_UPDATE_CONV_FAC1";
    payload: { value: string; }
}

interface SalesTariffUpdateConvFac2 {
    type: "SALESTARIFF_UPDATE_CONV_FAC2";
    payload: { value: string; }
}

interface SalesTariffUpdatedPricesList {
    type: "SALESTARIFF_UPDATED_PRICESLIST";
    payload: { value: number; price: Api.PriceModel }
}

interface SalesTariffUpdatedTVAsList {
    type: "SALESTARIFF_UPDATED_TVASLIST";
    payload: { productVat: Api.ProductVatModel }
}

interface SalesTariffRequestSave {
    type: "SALESTARIFF_REQUEST_SAVE";
    payload: { requestTime: number; }
}
export interface  SalesTariffReceiveSave{
    type: "SALESTARIFF_RECEIVE_SAVE";
    payload: { requestTime: number; values?: { [key: string]: Api.ProductModel; } }
    error?: any
}


type KnownAction = 
    SalesTariffUpdateConvFac1
    | SalesTariffUpdateConvFac2
    | SalesTariffUpdatedPricesList
    | SalesTariffUpdatedTVAsList
    | SalesTariffRequestSave
    | SalesTariffReceiveSave
    ;

export const actionCreators = {
    updateConvFac1: (value: string) => <SalesTariffUpdateConvFac1>{
        type: "SALESTARIFF_UPDATE_CONV_FAC1",
        payload: { value: value }
    },
    updateConvFac2: (value: string) => <SalesTariffUpdateConvFac2>{
        type: "SALESTARIFF_UPDATE_CONV_FAC2",
        payload: { value: value }
    },
    addToUpdatedPricesList: (value: number, price: Api.PriceModel) => <SalesTariffUpdatedPricesList>{
        type: "SALESTARIFF_UPDATED_PRICESLIST",
        payload: { value: value, price: price}
    },
    addToUpdatedTVAsList: (productVat: Api.ProductVatModel) => <SalesTariffUpdatedTVAsList>{
        type: "SALESTARIFF_UPDATED_TVASLIST",
        payload: { productVat: productVat}
    },
    saveSalesTariff: (requestTime: number, model: Api.UpdatePricesResultModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ProductsLgapApi();
        let fetchTask = api.updateSalesTariff({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(aux => {

                let prods: { [id: number]: Api.ProductModel} = {};
                _.values(model.pricesUp).map(x => {
                    var prod = JSON.parse(JSON.stringify(getState().seed.seed.products[x.storeItemId]));
                    let index = prod.prices.findIndex(y => y.priceId == x.priceId);
                    prod.prices = prods[x.storeItemId] != undefined ? prods[x.storeItemId].prices : prod.prices;
                    prod.prices[index] = x;
                    prods = {...prods, [prod.storeItemId]: prod};
                });
                _.values(model.productVatsUp).map(x => {
                    var prod = JSON.parse(JSON.stringify(getState().seed.seed.products[x.productId]));
                    let index = prod.productVats.findIndex(y => y.productVatId == x.productVatId);
                    prod.productVats[index] = x;
                    prod.prices = prods[x.productId] != undefined ? prods[x.productId].prices : prod.prices;
                    prods = {...prods, [prod.storeItemId]: prod};
                });
                dispatch({
                    type: "SALESTARIFF_RECEIVE_SAVE",
                    payload: { requestTime: requestTime, values: prods }
                });
                dispatch(Notification.success({ title: getText("NotificationTitleSuccess"), message: getText("NotificationUpdatedSuccess"), position: "tc", level: "success" }) as any);
            })
            .catch(err => {
                dispatch(Notification.error({  title: getText("NotificationTitleError"), message: getText("NotificationUpdatedError"), position: "tc", level: "error" }) as any);
                dispatch({
                    type: "SALESTARIFF_RECEIVE_SAVE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "SALESTARIFF_REQUEST_SAVE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
};

const unloadedState: SalesTariffState = {
    isSaving: false,
    pricesUpdated : {},
    tvasUpdated: {},
    convFac1: '',
    convFac2: ''
};

export const reducer: Reducer<SalesTariffState> = (state: SalesTariffState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SALESTARIFF_UPDATE_CONV_FAC1":
            return {
                ...state,
                convFac1: action.payload.value
            };
        case "SALESTARIFF_UPDATE_CONV_FAC2":
            return {
                ...state,
                convFac2: action.payload.value
            };
        case "SALESTARIFF_UPDATED_PRICESLIST":
            return {
                ...state,
                pricesUpdated: {
                    ...state.pricesUpdated,
                    [action.payload.price.priceId]: {
                        ...action.payload.price,
                        value: action.payload.value
                    }
                }
            };
         case "SALESTARIFF_UPDATED_TVASLIST":
            return {
                ...state,
                tvasUpdated: {
                    ...state.tvasUpdated,
                    [action.payload.productVat.productVatId]: action.payload.productVat
                }
            };
        case "SALESTARIFF_REQUEST_SAVE":
            return {
                ...state,
                isSaving: true,
                requestTime: action.payload.requestTime
            };
         case "SALESTARIFF_RECEIVE_SAVE":
            return {
                ...state,
                isSaving: false,
                tvasUpdated: unloadedState.tvasUpdated,
                pricesUpdated: unloadedState.pricesUpdated
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};