import * as React from 'react';
import * as HelpStore from '../store/Help';
import DialogModel from './DialogModal';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import PDFReader from './PDFReader';
import { getText } from '../utils/texts';

type HelpDialogProps = HelpDialogOwnProps
    & HelpDialogExternalProps
    & HelpStore.HelpState
    & typeof HelpStore.actionCreators;


interface HelpDialogOwnProps {

}

interface HelpDialogExternalProps {
    name: string;
}

interface HelpDialogState {
    helpContent: string;
}

class HelpDialog extends React.Component<HelpDialogProps, HelpDialogState>{

    constructor(props: HelpDialogProps) {
        super(props);
        this.state = {
            helpContent: ""
        };
    }

    componentDidMount() {
        (this.props.requestHelp(new Date().getTime(), this.props.name) as any as Promise<string>)
            .then(helpContent => this.setState({ helpContent: helpContent }));
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (<DialogModel
            contentStyle={{ 
                width:'80vw', 
                height:'85vh', 
                padding: '0 20px',
                background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
            }}
            isOpen={this.props.helpState[this.props.name] && this.props.helpState[this.props.name].isOpen}
            onRequestClose={() => this.props.closeHelpDialog(this.props.name)}
            contentLabel={getText("HelpTitle") + this.props.name}>
            {/*<div dangerouslySetInnerHTML={{ __html: this.state.helpContent }} />*/}
            <PDFReader name={this.props.name}/>
        </DialogModel>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.help
}), HelpStore.actionCreators)(HelpDialog as any) as any as React.ComponentClass<HelpDialogExternalProps>;