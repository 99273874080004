import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import CreateShelvingTrayPlacementForm from './CreateShelvingTrayPlacementForm';
import * as PlanogramStore from "../store/Planogram";

type CreateShelvingTrayPlacementProps = CreateShelvingTrayPlacementOwnProps
    & PlanogramStore.PlanogramState
    & typeof PlanogramStore.actionCreators;

interface CreateShelvingTrayPlacementOwnProps {
}

class CreateShelvingTrayPlacement extends React.Component<CreateShelvingTrayPlacementProps, {}> {
    public render() {
        return (
            <div style={{height: "100%"}}>    
                <CreateShelvingTrayPlacementForm
                    onSubmit={(values) => this.props.requestCreateShelvingTrayPlacement(new Date().getTime(), values)} />    
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
} as CreateShelvingTrayPlacementOwnProps), PlanogramStore.actionCreators)(CreateShelvingTrayPlacement as any);