import * as Api from '../api/api';
import * as React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import Table from "./Table";
import * as ReactTable from "react-table";
import { getText } from '../utils/texts';
import * as SeedStore from "../store/Seed";

type VersionsProps = VersionsDetailsOwnProps
& typeof SeedStore.actionCreators;

interface VersionsDetailsOwnProps {
    historyVersions: Array<Api.StoreAppVersionsModel>;
}

type VersionColumn = ReactTable.Column
    & { accessor: (d: Api.StoreAppVersionsModel) => any; }


class Versions extends React.Component<VersionsProps, {}> {

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    parseLines = (value) => value.replace(/(\\n)/g, "\n");

    public render() {
        return (
            <div style={{ padding: 10 }}>
                <Table
                    style= {{
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                        color: this.isDarkMode() ? "#FFFFFF" : "#181F3A"
                    }}
                    showPagination={false}
                    showPaginationBottom={false}
                    pageSize={this.props.historyVersions.length}
                    data={this.props.historyVersions}
                    columns={[
                        {
                            id: "version",
                            Header: <div>{getText("NavMenuVersionModalVersion")}</div>,
                            width: 100,
                            accessor: d => d.version,
                            style: {
                                color: this.isDarkMode() ?  'white' : '#3B4370'
                            }
                        },
                        {
                            id: "date",
                            Header: <div>{getText("NavMenuDateModalVersion")}</div>,
                            width: 100,
                            accessor: d => d.dateVersion,
                            style: {
                                color: this.isDarkMode() ?  'white' : '#3B4370'
                            }
                        },
                        {
                            id: "description",
                            Header: <div>{getText("NavMenuDescriptionModalVersion")}</div>, 
                            accessor: d =>  
                              <div style={{marginLeft: "2%", textAlign: 'left' }}>
                                        {this.parseLines(d.description)}
                              </div>,
                            style: {
                                color: this.isDarkMode() ?  'white' : '#3B4370',
                                whiteSpace:'pre-wrap'
                            }
                        }
                    ] as Array<VersionColumn>} />
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    historyVersions: state.seed.seed.storeAppVersions
} as VersionsProps), { ...SeedStore.actionCreators })(Versions)