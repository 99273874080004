import * as React from "react";
import Throttle from '../utils/throttle';
import { Guid } from "../utils/utils";

interface InputNumberProps {
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    className?: string;
    throttle?: number;
    integer?: boolean;
    disabled?: boolean;
    unit?: string;
    value: number;
    allowUndefined?: boolean;
    onChange: (value: number) => void;
    onBlur?:(value: number) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface InputNumberState {
    value: string;
}

export default class InputNumber extends React.Component<InputNumberProps, InputNumberState> {
    private guid: string;

    constructor(props: InputNumberProps) {
        super(props);
        this.state = {
            value: props.value
                ? props.value.toString()
                : (props.allowUndefined ? undefined : "0")
        };
        this.guid = Guid.newGuid();
    }

    handleChange(value: string) {
        this.setState({ value: value });
        Throttle.throttle(this.guid, () => {
            this.applyChange(value);
        }, this.props.throttle || 500);
        
    }

    applyChange(value: string) {
        let valueToUse = value
            ? value.replace(",", ".")
            : value;

        let parsed = this.props.integer
            ? parseInt(valueToUse)
            : parseFloat(valueToUse);
        if (parsed || parsed === 0) {
            this.props.onChange(parsed);
        } else {
            this.props.onChange(null);
        }
    }

    componentWillReceiveProps(nextProps: InputNumberProps) {
        this.setState ({
            value: nextProps.value
                ? nextProps.value.toString()
                : (nextProps.allowUndefined ? undefined : "0")
        });
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                ...this.props.containerStyle
            }}>
                <input
                    type="text"
                    onKeyDown={(e) => {
                        if (this.props.onKeyDown) {
                            this.props.onKeyDown(e);
                        }
                    }}
                    style={{ 
                        order: 1, 
                        flex: 1, 
                        ...this.props.style,
                        background: this.isDarkMode() ? "#181F3A" : "white",
                        color: this.isDarkMode() ? "white" : "#181F3A"
                    }}
                    className={this.props.className}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    onBlur={(e) => this.props.onBlur && this.applyChange(this.state.value)}
                    onChange={(e) => this.props.onChange && this.handleChange(e.target.value)}
                />
                {this.props.unit &&
                    <div style={{ order: 2, flex: "0 0 auto", paddingLeft: 5, paddingRight: 5 }}
                        className="form-control-static">
                        <span> {this.props.unit} </span>
                    </div>}
            </div>
            );
    }
}