import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as HistoryStore from '../store/History';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import DialogModal from "./DialogModal";
import { getText } from '../utils/texts';

type RemoteCollectionDialogProps = HistoryStore.ViewRemoteCollection
    & RemoteCollectionDialogOwnProps
    & typeof HistoryStore.actionCreators;

interface RemoteCollectionDialogOwnProps {
}

class RemoteCollectionDialog extends React.Component<RemoteCollectionDialogProps, {}> {

    get dataStyle1() {
        return ([
            { text: getText("HistoRemoteCollectionDiDate"), value: Moment(this.props.currentDataRemote.date + this.props.currentDataRemote.time, "DDMMYYHHmmss").format("DD/MM/YYYY HH:mm:ss") },
            {text: getText("HistoRemoteCollectionDiLabel"), value: this.props.currentRemoteCollection.label},
            {text: getText("HistoRemoteCollectionDiContractId"), value: this.props.currentDataRemote.contractId},
            {text: getText("HistoRemoteCollectionDiActivity"), value: this.props.currentDataRemote.activity},
            {text: getText("HistoRemoteCollectionDiSiteType"), value: this.props.currentDataRemote.siteType},
            {text: getText("HistoRemoteCollectionDiPaymentType"), value: this.props.currentDataRemote.paymentType},
            {text: getText("HistoRemoteCollectionDiSiret"), value: this.props.currentDataRemote.siret},
            {text: getText("HistoRemoteCollectionDiAcceptorNum"), value: this.props.currentDataRemote.acceptorNumber}
        ])
    }

    get dataStyle2() {
        return ([
            {text: getText("HistoRemoteCollectionDiTitle"), value: this.props.currentDataRemote.title},
            {text: getText("HistoRemoteCollectionDiResponse"), value: this.props.currentDataRemote.responseLabel},
            {text: getText("HistoRemoteCollectionDiFooter"), value: this.props.currentDataRemote.footer}
        ])
    }

    get dataStyle3() {
        if(this.props.currentDataRemote.remissions.length != 0)
            return ([
                {text: getText("HistoRemoteCollectionDiAcquirerNum"), value: this.props.currentDataRemote.remissions[0].acquirerNumber},
                {text: getText("HistoRemoteCollectionDiPart"), value: this.props.currentDataRemote.remissions[0].part},
                {text: getText("HistoRemoteCollectionDiCurrencyCode"), value: this.props.currentDataRemote.remissions[0].currencyCode},
                {text: getText("HistoRemoteCollectionDiRemissionId"), value: this.props.currentDataRemote.remissions[0].id},
                {text: getText("HistoRemoteCollectionDiNbTrans"), value: this.props.currentDataRemote.remissions[0].nbTransaction},
                {text: getText("HistoRemoteCollectionDiAmountT"), value: this.props.currentDataRemote.remissions[0].amountTotal},
                {text: getText("HistoRemoteCollectionDiNbFailedTrans"), value: this.props.currentDataRemote.remissions[0].nbFailedTransaction}
            ])
        return [];
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        if(this.props.currentRemoteCollection == undefined || !this.props.currentDataRemote == undefined)
            return <React.Fragment></React.Fragment>;
        
        return (
            <DialogModal isOpen={this.props.isOpen}
                contentLabel={"remoteCollection"}
                contentStyle={{ 
                    width: 940,
                    maxHeight: 600, 
                    background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                    border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)"
                }}
                title={getText("HistoRemoteCollectionDialogTitle")}
                onRequestClose={() => {
                   this.props.closeViewRemoteCollection();
                }}>
                { 
                    <div style={{ marginTop: 10 }}>
                        <div style={{ textAlign: "left", fontSize: "12px", padding: "15px 5px"}} className="row"> 
                            <div className="col-md-6">
                            {
                                this.dataStyle1.map((x, index) => (
                                    (index % 2 == 0) && (
                                        <div key={index} className="row" style={{ marginBottom: "5px" }}>
                                            <div className="col-md-6">
                                                {x.text}
                                            </div>
                                            <div className="col-md-6">
                                                <input disabled
                                                    style={{fontSize: "12px"}}
                                                    className="form-control"
                                                    type="text"
                                                    value={x.value ? x.value : ""} />
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                            </div>
                            <div className="col-md-6">
                            {
                                this.dataStyle1.map((x, index) => (
                                    (index % 2 != 0) && (
                                        <div key={index} className="row" style={{ marginBottom: "5px" }}>
                                            <div className="col-md-6">
                                                {x.text}
                                            </div>
                                            <div className="col-md-6">
                                                <input disabled
                                                    style={{fontSize: "12px"}}
                                                    className="form-control"
                                                    type="text"
                                                    value={x.value ? x.value : ""} />
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                            </div>
                            <div className="col-md-12">
                                {
                                this.dataStyle2.map((x, index) => ( 
                                    <div key={index} className="row" style={{ marginBottom: "5px" }}>
                                        <div className="col-md-3">
                                            {x.text}
                                        </div>
                                        <div className="col-md-9">
                                            <input disabled
                                                style={{fontSize: "12px"}}
                                                className="form-control"
                                                type="text"
                                                value={x.value ? x.value : ""} />
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <hr
                            style={{
                                color: "black",
                                backgroundColor: "black",
                                height: 3,
                                width: 760
                            }}
                        />
                        <div style={{ border: "2px solid rgb(203 197 197)", backgroundColor:"#f6ffbe", margin:"0px 200px", fontSize: 23}}>
                                {this.props.currentDataRemote.remissions.length != 0 ?
                                `${getText("HistoRemoteCollectionDialogTitle2")} ${this.props.currentDataRemote.remissions[0].currencyCode}`
                                : getText("HistoRemoteCollectionDialogTitle2")}
                        </div>
                        <div style={{ textAlign: "left", fontSize: "12px", padding: "15px 5px"}} className="row"> 
                            <div className="col-md-8">
                                { this.props.currentDataRemote.remissions.length != 0 &&
                                this.dataStyle3.map((x, index) => ( 
                                    <div key={index} className="row" style={{ marginBottom: "5px" }}>
                                        <div className="col-md-7">
                                            {x.text}
                                        </div>
                                        <div className="col-md-5">
                                            <input disabled
                                                style={{fontSize: "12px"}}
                                                className="form-control"
                                                type="text"
                                                value={x.value ? x.value : ""} />
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary btn-sm"
                            type={"button"}
                            style={{
                                width: 175,
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.props.closeViewRemoteCollection()}> {getText("HistoCollectionCloseButton")} </button>
                    </div>
                }
            </DialogModal>
        );
    }
}

export default connect((state: ApplicationState) => {
    return {
        ...state.history.viewRemoteCollection,
    } as RemoteCollectionDialogOwnProps
}, {...HistoryStore.actionCreators,})(RemoteCollectionDialog as any)