import * as React from 'react';
import * as HelpStore from '../store/Help';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import HelpDialog from './HelpDialog';
import { getCurrentLang } from '../utils/language'

type HelpHeaderProps =  HelpStore.HelpState
& typeof HelpStore.actionCreators;

interface HelpButtonProps {
    name: string;
    openHelp: (name: string) => void;
}

class HelpButton extends React.Component<HelpButtonProps, {}> {
    public render() {
        return (
            <div style={{ minHeight: 30, padding: "3px 0px", color: "#040930" }}>
                <div style={{
                    height: 25,
                    width: 25,
                    borderRadius: 25,
                    textAlign: "center",
                    fontSize: 25
                }}
                    onClick={(e) => this.props.openHelp(this.props.name)}>
                    <i className="glyphicon glyphicon-question-sign" />
                </div>
            </div>
            );
    }
}

type ValidPage = "CashRegister" | "History" | "Maintenance" | 
    "Offer" | "Planogram" | "PricePeriod" | 
    "Product" | "Reorder" | "Settings" | 
    "Stock" | "Voucher" | "Menu" | "Reception" |
    "Reappro" | "SalesAnalysis" | "Event" | "Interventions";

class HelpHeader extends React.Component<HelpHeaderProps, {}> {

    getPdfUrl(name: ValidPage): string {
        try{
            let lang = getCurrentLang();
            let jsonUrls = require(`../help/${lang}/linksPDF.json`)
            this.props.setHelpUrls(jsonUrls);

            let currentURL = jsonUrls.find(x => x.Page == name);
            this.props.setCurrentUrl(currentURL.Files[0]);
        }
        catch{
            return null
        }
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row-reverse", padding: "0px 30px", alignItems: "center" }}>
                <Switch>
                    <Route path='/stock' exact component={() => <HelpButton name="Stock" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Stock")}} />} />
                    <Route path='/reception' exact component={() => <HelpButton name="Reception" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Reception")}} />} />
                    <Route path='/maintenance' exact component={() => <HelpButton name="Maintenance" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Maintenance")}} />} />
                    <Route path='/promotions' exact component={() => <HelpButton name="PricePeriod" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("PricePeriod")}} />} />
                    <Route path='/product' exact component={() => <HelpButton name="Product" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Product")}} />} />
                    <Route path='/planogram' exact component={() => <HelpButton name="Planogram" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Planogram")}} />} />
                    <Route path='/offer' exact component={() => <HelpButton name="Offer" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Offer")}} />} />
                    <Route path='/voucher' exact component={() => <HelpButton name="Voucher" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Voucher")}} />} />
                    <Route path='/cashregister' exact component={() => <HelpButton name="CashRegister" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("CashRegister")}} />} />
                    <Route path='/history' exact component={() => <HelpButton name="History" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("History")}} />} />
                    <Route path='/settings' exact component={() => <HelpButton name="Settings" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Settings")}} />} />
                    <Route path='/reorder' exact component={() => <HelpButton name="Reorder" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Reorder")}} />} />
                    <Route path='/menu' exact component={() => <HelpButton name="Menu" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Menu")}} />} />
                    <Route path='/reappro' exact component={() => <HelpButton name="Reappro" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Reappro")}} />} />               
                    <Route path='/salesanalysis' exact component={() => <HelpButton name="SalesAnalysis" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("SalesAnalysis")}} />} />               
                    <Route path='/events' exact component={() => <HelpButton name="Event" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Event")}} />} />               
                    <Route path='/interventions' exact component={() => <HelpButton name="Interventions" openHelp={(name) => {this.props.openHelpDialog(name); this.getPdfUrl("Interventions")}} />} />
                </Switch>
                <HelpDialog name={"CashRegister"}/>
                <HelpDialog name={"History"}/>
                <HelpDialog name={"Maintenance"}/>
                <HelpDialog name={"Offer"}/>
                <HelpDialog name={"Planogram"}/>
                <HelpDialog name={"PricePeriod"}/>
                <HelpDialog name={"Product"}/>
                <HelpDialog name={"Reorder"}/>
                <HelpDialog name={"Settings"}/>
                <HelpDialog name={"Reception"}/> 
                <HelpDialog name={"Stock"}/>
                <HelpDialog name={"Voucher"}/>
                <HelpDialog name={"Menu"}/>
                <HelpDialog name={"Reappro"}/>
                <HelpDialog name={"SalesAnalysis"}/>
                <HelpDialog name={"Event"}/>
                <HelpDialog name={"Interventions"}/>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({...state.help}), HelpStore.actionCreators)(HelpHeader as any);