import * as ReactTable from 'react-table';
import Moment from 'moment';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { Action } from 'redux';
import { Reducer } from 'redux';

export type TablesState = { [tableKey: string]: TableState }

export interface TableState {
    filtered: Array<ReactTable.Filter>;
    sorted: Array<ReactTable.SortingRule>;
}

interface TableUpdateFiltered {
    type: "TABLE_UPDATE_FILTERED";
    payload: { tableKey: string; filtered: Array<ReactTable.Filter>; }
}

interface TableUpdateSorted {
    type: "TABLE_UPDATE_SORTED";
    payload: { tableKey: string; sorted: Array<ReactTable.SortingRule> }
}

type KnownAction = TableUpdateFiltered
    | TableUpdateSorted;

export const actionCrators = {
    updateFiltered: (tableKey: string, filtered: Array<ReactTable.Filter>) => <TableUpdateFiltered>{
        type: "TABLE_UPDATE_FILTERED",
        payload: { tableKey: tableKey, filtered: filtered }
    },
    updateSorted: (tableKey: string, sorted: Array<ReactTable.SortingRule>) => <TableUpdateSorted>{
        type: "TABLE_UPDATE_SORTED",
        payload: { tableKey: tableKey, sorted: sorted }
    },

}
export const unloadedTableState: TableState = {
    filtered: [],
    sorted: []
}
const unloadedState: TablesState = {}

export const reducer: Reducer<TablesState> = (state: TablesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TABLE_UPDATE_FILTERED":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    filtered: action.payload.filtered
                }
            };
        case "TABLE_UPDATE_SORTED":
            return {
                ...state,
                [action.payload.tableKey]: {
                    ...(state[action.payload.tableKey] || unloadedTableState),
                    sorted: action.payload.sorted
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};