import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as SettingsStore from '../store/Settings';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import SettingsGeneral from "./SettingsGeneral";
import SettingsHmiMoney from "./SettingsHmiMoney";
import SettingsRobot from "./SettingsRobot";
import SettingsTranslationHmi from "./SettingsTranslationHmi";
import Shelving from './Shelving';
import HelpHeader from './HelpHeader';
import Tray from './Tray';
import { getText } from '../utils/texts';
import Exploitant from './Exploitant';

type SettingsProps = SettingsOwnProps
    & SettingsStore.SettingsState
    & typeof SettingsStore.actionCreators;

interface SettingsOwnProps {

}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

export default class Settings extends React.Component<SettingsProps, {}> {
    public render() {
        return (
            <div>
                <ConnectedTabs name={"settings"}>
                    <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                        <span style={{ padding: '2%'}}>
                            {getText( "SettingsTitle")}
                        </span>
                        <TabLink to="exploitant" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsExploitant")}</div>
                        </TabLink>
                        <TabLink to="general" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsTabGeneral")}</div>
                        </TabLink>
                        <TabLink to="translationHmi" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsTranslationHmi")}</div>
                        </TabLink>
                        <TabLink to="hmiMoney" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsTabMoney")}</div>
                        </TabLink>
                        <TabLink to="robot" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsTitleRobot")}</div>
                        </TabLink>
                        <TabLink to="shelving" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsTitleShelving")}</div>
                        </TabLink>
                        <TabLink to="tray" style={{width: '20%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "SettingsTitleTray")}</div>
                        </TabLink>
                        <HelpHeader />
                    </div>
                    <TabContent for="exploitant">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <Exploitant />
                        </div>
                    </TabContent>
                    <TabContent for="general">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <SettingsGeneral />
                        </div>
                    </TabContent>
                    <TabContent for="translationHmi">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <SettingsTranslationHmi />
                        </div>
                    </TabContent>
                    <TabContent for="hmiMoney">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <SettingsHmiMoney />
                        </div>
                    </TabContent>
                    <TabContent for="robot">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <SettingsRobot />
                        </div>
                    </TabContent>
                    <TabContent for="shelving">
                        <div style={{ margin: '9px 1% 0 1%' }}>
                            <Shelving />
                        </div>
                    </TabContent>
                    <TabContent for="tray">
                        <div style={{ margin: '9px 1% 0 1%'}}>
                            <Tray />
                        </div>
                    </TabContent>
                </ConnectedTabs>
            </div>
        );
    }
}

//export default connect((state: ApplicationState) => ({
//    ...state.settings
//}), SettingsStore.actionCreators)(Settings);