import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as StockStore from "../store/Stock";
import * as ReactTable from "react-table";
import { calculateProductDepth } from '../utils/utils';
import Table from "./Table";
import Select from "./Select";
import ProductStockDialog from "./ProductStockDialog";
import * as OfferStore from '../store/Offer';
import { ExportToCsv } from 'export-to-csv';
import * as ReceptionStore from "../store/Reception";
import HelpHeader from './HelpHeader';
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import * as ReorderStore from "../store/Reorder";
import * as PricePeriodStore from "../store/PricePeriod";
import {generateCsvFromTable, CsvData } from "../utils/csv";
import {
    ProductOfferState,
    productRailsInPlanoSelector,
    stockFilteredProductRailSelector,
    StockProductData
} from "../store/Stock";
import Tooltip from "./Tooltip";
import {ExternalProductModelExpirityTypeEnum} from "../api/api";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type StockProps = StockOwnProps
    & StockStore.StockState
    & typeof ReceptionStore.actionCreators
    & typeof StockStore.actionCreators
    & typeof OfferStore.actionCreators
    & typeof ReorderStore.actionCreators
    & typeof PricePeriodStore.actionCreators;

type StockColumn = ReactTable.Column
    & {
        accessor: (d: StockProductData) => any;
        columns?: Array<StockColumn>
    }

interface StockOwnProps {
    filteredProductRails: Array<StockProductData>;
    productRailsInPlano: Array<Api.ProductRailModel>;
    storeType: string;
    products: { [id: number]: Api.ProductModel };
    externalProducts : { [id: number]: Api.ExternalProductModel};
    offeredProducts: Array<Api.ProductModel>;    
    promotions: { [id: number]: Api.PromotionModel };
    currentUser: Api.CurrentUserManagerModel;
    offer: OfferStore.OfferState;
    suppliers: { [id: number]: Api.SupplierModel };
    pricePeriods: { [id: number]: Api.PricePeriodModel };
    units: { [id: number]: Api.UnitModel };
    receptionisLoading: boolean;
}

const customOptionsFilter = ({ filter, onChange }) => {
    return (
        <input 
            onChange={e => onChange(e.target.value)} 
            style={{ 
                    width: '100%',
                    backgroundColor: 'green'
                }} 
            value={filter ? filter.value : 'all'}
        />
    )
}

const columnXimitiStyle: React.CSSProperties = { backgroundColor: 'rgba(245, 243, 146, 0.4)' }
const columnRexelStyle: React.CSSProperties = { backgroundColor: 'rgba(167, 212, 252, 0.4)' }

const headerColumns: React.CSSProperties = { overflow: 'visible' , whiteSpace: 'normal' }

const expirityTypeOptions = ():  Array< { label: string; value: Api.ProductModelExpirityTypeEnum } > => ([
    { label: getText("SettingsExpirityTypeNone"), value: "None"  },
    { label: getText("SettingsExpirityTypeNormal"), value: "Normal"  },
    { label: getText("SettingsExpirityTypeDdm"), value: "Ddm"  },
    { label: getText("SettingsExpirityTypeEggs"), value: "Eggs"  }
]);

interface StockState {
    filterInputTab: string;
}

class Stock extends React.Component<StockProps, StockState> {
    stockRef = {};
    auxIndexRef = undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            filterInputTab: ''
        };
    }

    componentDidMount() {
        if(this.props.storeType == "Ximiti") {
            (this.props.requestReorderData(new Date().getTime()) as any as Promise<any>)
                .then(() => this.props.requestPricePeriods(new Date().getTime()))
                .then(() => this.props.requestItemOfferResult(new Date().getTime()));
        }
    }

    componentDidUpdate(prevProps: Readonly<StockProps>): void {
        if(this.props.stockStatus != prevProps.stockStatus && this.auxIndexRef != undefined
            && this.stockRef[this.auxIndexRef] != null){
            this.stockRef[this.auxIndexRef].focus();
        }
    }

    stockArr(productId: number) {
        if(this.props.storeType == "Ximiti"){
            return _.sum(this.props.stockStatus.productReceptions
                .filter(x => x ? x.productId === productId : '')
                .map(x => x.quantityRemaining));
        }else{
            return _.sum(this.props.stockStatus.productReceptions
                .filter(x => x ? x.externalProductId === productId : '')
                .map(x => x.quantityRemaining));
        }
    }

    getStoredStockArr(productRaildId: number): number | undefined {
        return this.props.storeStockState[productRaildId]
            || this.props.storeStockState[productRaildId] === 0
            ? this.props.storeStockState[productRaildId] 
            : undefined
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getExpirityLabel(expirityType: string) {
        if (expirityType === "None") {
            return getText("SettingsExpirityTypeNone")
        } else if (expirityType === "Normal") {
            return getText("SettingsExpirityTypeNormal")
        } else if (expirityType === "Ddm") {
            return getText("SettingsExpirityTypeDdm")
        } else if (expirityType === "Eggs") {
            return getText("SettingsExpirityTypeEggs")
        }
    }

    isInPlanogram(supplierId: number): boolean {
        if(!this.props.stockStatus)
            return false;
        
        return this.props.productRailsInPlano.some(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products)
            ? ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === supplierId
            : false);
    }

    getProductSuppliersReference(): Array<string> {

        return _.uniq(_.uniq(this.props.productRailsInPlano.map(x => x.productId).filter(x => x))
            .map(x => ReorderStore.getProductSupplier(x, this.props.products))
            .map(x => x.reference));
    }

    formattedRADF(radf: string): string {
        let splitRADF = radf.split("-");
        let r = ('0' + splitRADF[0]).slice(-3);
        let a = ('0' + splitRADF[1]).slice(-2);
        let d = ('0' + splitRADF[2]).slice(-2);
        let f = ('0' + splitRADF[3]).slice(-2);
        return r + "-" + a + "-" + d + "-" + f;
    }

    getPromoColor(offerState: ProductOfferState): string {
        if(offerState === "Potd")
            return "lightblue";
        
        if (offerState === "Offer")
            return"lightgreen";

        if (offerState === "TimedOffer")
            return "darkBlue";

        if (offerState === "Expirity")
            return "darkorchid";

        return "initial";
    }

    get productOfTheDay(): Api.ItemOfTheDayModel {
        return this.props.offer && this.props.offer.itemOfferResult
            ? this.props.offer.itemOfferResult.itemOfTheDay
            : {};
    }
    
    csvData(): CsvData {
        if(!document.getElementById("stock-container"))
            return {
                data: [],
                headers: []
            };

        return generateCsvFromTable(document.getElementById("stock-container"));
    }

    get isMobileDevice(){
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        return regexp.test(details);
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF('l');
        autoTable(pdf, {body: data});
        pdf.save(`${getText("StockStock")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    public render() {
        return (
            <div id={"stock-container"}>
                <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                    <span style={{paddingLeft: 30}}>
                        {getText( "StockTitle")}
                    </span>
                    <HelpHeader />
                </div>
                {/* <div className="row" style={{margin: "2% 0"}}>
                    <div className="col-md-5">
                        Pourcentage de remplissage pour avertissement:
                    </div>
                    <div className="col-md-1">
                        <InputNumber integer
                            className="form-control"
                            style={{ width: 100 }}
                            value={this.props.warnPercentage}
                            onChange={(value) => this.props.updateWarnPercentage(value)} />
                    </div>
                </div> */}
                <div className="card" style={{ ...Styles.cardStyle, margin: "1%", height: "100%" }}>
                    <Table tableKey={"stock"}
                            className="stock-table"
                            loading={this.props.isLoading}
                            style={{ height: '80vh' }}
                            getTheadFilterThProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        borderRight: column.filterable && (this.isDarkMode() ? '1px solid transparent' : '1px solid rgba(255, 255, 255, 0.16)')
                                    }
                                };
                            }}
                            columns={[
                                {
                                    id:"id",
                                    Header: <div className="borderSelectors csv-ignore" style={{
                                        margin: 0, display: "flex", flexDirection: "row", 
                                        columnGap: 4, flexWrap: "wrap",
                                        width: "100%"
                                    }}>
                                        <div style={{lineHeight: "40px", flex: "0 0 1" }}>{getText( "StockSource")}</div>
                                        {this.props.storeType == "Ximiti" &&
                                        <div style={{padding: 0, flex: "0 0 1", minWidth: 150 }}>
                                            <div style={{/*width: '100%'*/}}>
                                                <Select options={[{
                                                    label: "------",
                                                    value: undefined
                                                    }].concat(_.sortBy(_.values(this.props.suppliers)
                                                        .filter(x => this.isInPlanogram(x.supplierId))
                                                        .map(z => ({ label: z.name, value: z.supplierId })), x => x.label))}
                                                        style={{
                                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                                        }}
                                                        value={this.props.stockFilter.supplierMainId}
                                                        onChange={(newSupplierMainId) => {
                                                            this.props.updateSupplierMainId(newSupplierMainId);
                                                        }}
                                                />
                                            </div>
                                        </div>}
                                        {this.props.storeType == "Ximiti" && <div style={{lineHeight: "40px", marginRight: 5, flex: "0 0 1" }}>{getText( "StockCodeSource")}</div>}
                                        {this.props.storeType == "Ximiti" &&
                                        <div style={{padding: 0, flex: "0 0 1", minWidth: 150 }}>
                                            <div style={{/*width: '100%'*/}}>
                                                <Select options={[{
                                                    label: "------",
                                                    value: undefined
                                                }].concat(_.sortBy(this.getProductSuppliersReference()
                                                    .map(z => ({ label: z, value: z })), x => x.label))}
                                                        style={{
                                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                                        }}
                                                        value={this.props.stockFilter.reference}
                                                        onChange={(newReference) => {
                                                            this.props.updateReference(newReference);
                                                        }} 
                                                />
                                            </div>
                                        </div>}
                                        <div style={{lineHeight: "40px", marginRight: 5, flex: "0 0 1" }}>{getText( "StockType")} :</div>
                                        <div style={{padding: 0, flex: "0 0 1", minWidth: 150 }}>
                                            <div style={{/*width: '100%'*/}}>
                                                <Select options={[{
                                                    label: "------",
                                                    value: undefined
                                                }].concat(expirityTypeOptions())}
                                                        style={{
                                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                                        }}
                                                        value={this.props.stockFilter.expirityType}
                                                        onChange={(newExpirityType) => {
                                                            this.props.updateExpirityType(newExpirityType);
                                                        }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{ flex: "0 0 auto" }}>
                                            <button type={"button"}
                                                    className="btn btn-primary"
                                                    style={{width: '100%', height: 40}}
                                            onClick={(e) => {
                                                const options = {
                                                    fieldSeparator: ';',
                                                    quoteStrings: '"',
                                                    decimalSeparator: ',',
                                                    showLabels: false,
                                                    showTitle: false,
                                                    filename: `${getText("StockStock")}-${Moment().format('YYYYMMDDHHmm')}`,
                                                    useTextFile: false,
                                                    useBom: true,
                                                    useKeysAsHeaders: false,
                                                    headers: this.csvData().headers,
                                                };
                                                const csvExporter = new ExportToCsv(options);
                                                csvExporter.generateCsv(this.csvData().data);  
                                                this.pdfData(this.csvData().data);
                                            }}>
                                                {getText( "HistoExport")}
                                                <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                                            </button>
                                        </div>
                                    </div>,
                                    columns: [
                                        {
                                            id: "name",
                                            accessor: (d) => d.name, 
                                            Cell: row => { 
                                                return (
                                                    <div style={{textAlign: 'initial', padding: 7, cursor:"pointer"}}
                                                        onClick={(e) => {
                                                            this.auxIndexRef = row.index;
                                                            let element = row.original as StockProductData;
                                                            this.props.openCreateStock(element.productRailId, element.backStock)
                                                        }}>
                                                        {row.value}</div>
                                                )
                                            },
                                            style: {
                                                height: "100%",
                                            },
                                            //minWidth: '100%',
                                            Header: <div>{getText( "StockName")}</div>,
                                            filterable: true,
                                            Filter: ({filter, onChange}) => (
                                                <span>
                                                    { !this.isMobileDevice ?
                                                    <input
                                                    onChange={event => { 
                                                        onChange(event.target.value)
                                                        }}
                                                    value={filter ? filter.value : ''}
                                                    placeholder= {getText( "StockSearch")}
                                                    style={{width: '90%', fontWeight: 900}}
                                                    />
                                                    : <>
                                                    <input
                                                    onChange={event =>
                                                        this.setState({
                                                            ...this.state,         
                                                            filterInputTab: event.target.value
                                                        })}
                                                    value={this.state.filterInputTab}
                                                    placeholder= {getText( "StockSearch")}
                                                    style={{width: '70%', fontWeight: 900}}
                                                    />
                                                    <button type={"button"}
                                                        className="btn btn-primary"
                                                        style={{width: '30%', padding: 5}}
                                                        onClick={() => onChange(this.state.filterInputTab)}>
                                                            {getText( "HistoFilter")}
                                                    </button>
                                                    </>
                                                    }
                                                </span>
                                            ),
                                        },
                                        {
                                            id: "volumeUnit",
                                            accessor: (x) => x.volumeUnit,
                                            Header: <div style={headerColumns}>{getText("StockVolUnit")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            Cell: row => { return ( <div style={{...columnXimitiStyle, padding: 7}}>
                                                {row.value}
                                            </div> )},
                                            maxWidth: 100,
                                            filterable: false,
                                            show: this.props.storeType === "Ximiti" ? false : true
                                        },     
                                        {
                                            id: "practicUnit",
                                            accessor: (x) => x.practicUnit,
                                            Header: <div style={headerColumns}>{getText("StockPracticUnit")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            Cell: row => { return ( <div style={{...columnRexelStyle, padding: 7}}>{row.value}</div> )},
                                            maxWidth: 100,
                                            filterable: false,
                                            show: this.props.storeType == "Ximiti" ? false : true
                                        },                      
                                        {
                                            id: "radf",
                                            accessor: (x) => x.radf,
                                            Header: <div>{getText("StockRADF")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 150,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                        }, 
                                    ]
                                },                   
                                {
                                    id: "stock",
                                    Header: <div style={{lineHeight: '40px'}}>{this.props.storeType == "Ximiti" ? getText( "StockStock") : getText( "StockStockRexel")}</div>,
                                    columns: [
                                        {
                                            id: "etatDuStock",
                                            accessor: (d) => d.stockState,
                                            Header: <div>{getText( "StockState")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 60,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),                                            
                                            sortMethod: (x,y) => {
                                                return x - y;
                                            },
                                            Cell: row => {
                                                return (
                                                    <div style={{width: 25}}>
                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	                                                        viewBox="0 0 512 512" fill={["red", "orange", "gray", "transparent"][row.value]}>
                                                                <path d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
                                                                    c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
                                                                    c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
                                                                    M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
                                                                    V317.241z"/>
                                                        </svg>
                                                    </div>
                                                );
                                            }  
                                        },
                                        {
                                            id: "stockNumber",
                                            accessor: (d) => d,
                                            sortMethod: (x,y) => {
                                                return x.stockNumber - y.stockNumber
                                            },
                                            Cell: row => { 
                                                return ( <div style={this.props.storeType == "Ximiti" 
                                                ? {padding: 7} 
                                                : {...columnXimitiStyle, padding: 7}}>{row.value.stockNumber}/{row.value.maxStock}</div> )},
                                            Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "StockNumber") : getText( "StockNumberRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 100,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                        },
                                        {
                                            id: "productReceptions",
                                            Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "StockBack") : getText( "StockBackRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 90,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            accessor: (d) => d,
                                            Cell: row => {
                                                let element = row.value as StockProductData;
                                                let storedStock = this.getStoredStockArr(element.productRailId);
                                                return (
                                                    //for stock porpuse the dictionary will be control by productRailId as Key.
                                                    
                                                    <div style={this.props.storeType == "Ximiti" ? {height: 35} : {...columnXimitiStyle, height: 35}}>
                                                        <input
                                                            ref={(r) => this.stockRef[row.index] = r}
                                                            tabIndex={0}
                                                            className="form-control"
                                                            style={{ width: 70, textAlign: 'center' }}
                                                            type={"number"}
                                                            min="0"
                                                            disabled={this.props.receptionisLoading}
                                                            value={storedStock === undefined ? element.backStock : storedStock}
                                                            onChange={(e) => this.props.productStockUpdateStoredStock(element.productRailId, parseInt(e.target.value) || 0)}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    let value = parseInt(e.currentTarget.value);
                                                                    if ((value || value === 0) && value >= 0) {
                                                                        this.props.requestUpdateQuantityRemaining(new Date().getTime(), {
                                                                            productId: element.storeItemId,
                                                                            quantity: value
                                                                        });
                                                                    }
                                                                }
                                                                if(e.keyCode === 40 && this.stockRef[row.index+1]){
                                                                    this.stockRef[row.index+1].focus();
                                                                    this.auxIndexRef = row.index+1;
                                                                }
                                                                if(e.keyCode === 38 && this.stockRef[row.index-1]){
                                                                    this.stockRef[row.index-1].focus();
                                                                    this.auxIndexRef = row.index-1;
                                                                }
                                                            }}
                                                            onKeyDown={(e => {
                                                                if (e.keyCode === 38 || e.keyCode === 40) {
                                                                    e.preventDefault();
                                                                }
                                                            })}
                                                            onClick={() => this.auxIndexRef = row.index}
                                                            //To avoid many requests due to the arrows navegation
                                                            // onBlur={(e) => {
                                                            //     let value = parseInt(e.currentTarget.value);
                                                            //     let stockAr = this.props.storeType == "Ximiti" ? this.stockArr( element.productId) : this.stockArr(element.externalProductId)
                                                            //     if(stockAr === value)
                                                            //         return;

                                                            //     if ((value || value === 0) && value >= 0) {
                                                            //         this.props.requestUpdateQuantityRemaining(new Date().getTime(), {
                                                            //             productId: this.props.storeType == "Ximiti" ? row.value.productId : row.value.externalProductId,
                                                            //             quantity: value
                                                            //         });
                                                            //     }
                                                            // }}
                                                        />
                                                    </div>
                                                )
                                            },
                                           
                                        },
                                        {
                                            id: "productManualToTrash",
                                            Header: <div style={headerColumns} >{this.props.storeType == "Ximiti" ? getText( "StockMarkdown") : getText( "StockMarkdownRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            width: 130,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            accessor: (d) => d,
                                            Cell: row => {
                                                let element = row.value as StockProductData;
                                                return (
                                                    <div style={this.props.storeType == "Ximiti" ? {height: 35} : {...columnXimitiStyle, height: 35}}>
                                                        <input
                                                            tabIndex={-1}
                                                            className="form-control"
                                                            style={{ width: 115, textAlign: 'center' }}
                                                            type={"number"}
                                                            min={0}
                                                            disabled={this.props.receptionisLoading}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    let stockArr = element.backStock;
                                                                    let value = parseInt(e.currentTarget.value);
                                                                    if ((value || value === 0) && value <= stockArr) {
                                                                        this.props.requestDeleteQuantityRemaining(new Date().getTime(), {
                                                                            productId: element.storeItemId,
                                                                            quantity: value
                                                                        });

                                                                    }
                                                                    e.currentTarget.value = "";
                                                                }
                                                            }}
                                                            />
                                                        </div>
                                                )
                                            },
                                        }
                                    ] as Array<StockColumn>
                                },
                                {
                                    id: "stockUp",
                                    Header: <div style={{lineHeight: '40px', ...headerColumns}}>{getText( "StockStockUpRexel")}</div>,
                                    columns: [               
                                        {
                                            id: "stockMaxUp",
                                            Header: <div style={headerColumns}>{getText( "StockNumberUpRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 85,
                                            filterable: true,
                                            show: this.props.storeType == "Ximiti" ? false : true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),                                              
                                            accessor: (d) =>{
                                                return <div style={{...columnRexelStyle, padding: 7}}>{d.stockUp} </div>
                                                
                                            },
                                        },
                                        {
                                            id: "productReceptionsUp",
                                            Header: <div style={headerColumns}>{getText( "StockBackUpRexel")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 75,
                                            filterable: true,
                                            show: this.props.storeType == "Ximiti" ? false : true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            accessor: (d) => d,
                                            Cell: row => {
                                                let element = row.value as StockProductData;
                                                return <div style={{...columnRexelStyle, padding: 7}}>{  element.backStockUp } </div>
                                            },
                                           
                                        }
                                    ] as Array<StockColumn>
                                },                    
                                {
                                    id: "joursRestants",
                                    Header: <div style={{lineHeight: '40px'}}>{getText( "StockDLCClose")}</div>,
                                    columns: [
                                        {
                                            id: "jours",
                                            accessor: (d) => d.days,
                                            sortMethod: (a,b) => {
                                                if (a > b) return 1;
                                                if (a < b) return -1;
                                                if (typeof(a) == "string") return 1;
                                                if (typeof(b) == "string") return -1;
                                                return 0;
                                            },
                                            Header: <div>{getText( "StockDays")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 60,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            Cell: row => {
                                                return <div style={{padding: 7}}>
                                                    {row.value}
                                                </div>;
                                            }
                                        },
                                        {
                                            id: "date",
                                            accessor: (d) => d.date,
                                            Header: <div>{getText( "StockDate")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 90,
                                            filterable: true,
                                            show: this.props.storeType == "Ximiti" ? true : false,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            Cell: row => {
                                                return (<div style={{padding: 7}}>
                                                    {row.value}
                                                </div>);
                                            }
                                        },
                                        {
                                            id: "alerte",
                                            accessor: (d) => d.offerState,
                                            Header: <div>{getText( "HistoPromo")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 50,
                                            filterable: true,
                                            show: this.props.storeType == "Ximiti" ? true : false,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            Cell: row => {
                                                if(this.props.storeType == "Ximiti" ){
                                                    let color = this.getPromoColor(row.value);
                                                    let msg = "";
                                                    switch(row.value as ProductOfferState){
                                                        case "Potd":   msg=getText("StockPromoOfTheDay"); break;
                                                        case "Offer":  msg=getText("StockPromo"); break;
                                                        case "TimedOffer":    msg=getText("StockTimedOffer"); break;
                                                        case "Expirity":  msg=getText("StockExpirity"); break;
                                                    } 
                                                    return (
                                                        msg != "" ? 
                                                        <Tooltip overlay={<div>{msg}</div>} >
                                                            <div style={{padding: 7}}>
                                                                <div style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: '50%',
                                                                    background: color
                                                                    }}>
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                        :<div style={{padding: 7}}>
                                                            <div style={{
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: '50%',
                                                                background: color
                                                                }}>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        },
                                        {
                                            id: "type",
                                            accessor: (d) => d.expirityType,
                                            Header: <div>{getText( "StockType")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            maxWidth: 60,
                                            filterable: true,
                                            Filter: ({}) => (
                                                <span>&nbsp;</span>
                                            ),
                                            Cell: row => {
                                                return (<div style={{padding: 7}}>
                                                    {
                                                        row.value ?  this.getExpirityLabel(row.value) : <span>&nbsp;</span>
                                                    }
                                                </div>);
                                            }
                                        },
                                        {
                                            id: "actions",
                                            maxWidth: 80,
                                            accessor: (d) => d,
                                            filterable: false,
                                            Header: <div>{getText( "StockActions")}</div>,
                                            style: {
                                                height: "100%"
                                            },
                                            Cell: row => <div style={{ 
                                                width: 20,
                                                height: 20,
                                                placeSelf: "center", 
                                                backgroundColor: "#007AFF",
                                                cursor: "pointer",
                                                marginTop: '2%',
                                                borderRadius: 5, }}
                                                onClick={(e) => {
                                                    this.auxIndexRef = row.index;
                                                    let element = row.value as StockProductData;
                                                    this.props.openCreateStock(element.productRailId, element.backStock)
                                                }}                                        
                                                className="icon-container-dm">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path 
                                                        d="M 11 2 L 11 8 L 5 8 L 5 10 L 11 10 L 11 16 L 13 16 L 13 10 L 19 10 L 19 8 L 13 8 L 13 2 L 11 2 z M 5 18 L 5 20 L 19 20 L 19 18 L 5 18 z"
                                                        fill={ this.isDarkMode() ? "black" : "#FFFFFF" } 
                                                    />
                                                </svg>
                                            </div>
        
                                        }
                                    ] as Array<StockColumn>
                                }
                            ] as Array<StockColumn>}
                            pageSize={Math.max(this.props.filteredProductRails?.length || 0, 20)}
                            showPageSizeOptions={false}
                            showPagination={false}
                            showPaginationBottom={false}
                            data={this.props.stockStatus ? this.props.filteredProductRails : []}>
                        </Table>
                    </div>
                <ProductStockDialog />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    //This is dangerous, if two property have the same name it will be overwritten
    //...state.stock,
    //...state.offer,
    ...state.stock,
    offer: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? state.offer : undefined,
    storeType: state.seed.seed.storeAppSettings.typeStore,
    products: state.seed.seed.products,
    externalProducts: state.seed.seed.externalProducts,
    currentUser: state.account.currentUser,
    offeredProducts: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? OfferStore.productFilteredSelector(state) : [],    
    promotions: state.seed.seed.promotions,
    suppliers: state.seed.seed.suppliers,    
    pricePeriods: state.pricePeriod.pricePeriods,
    units: state.seed.seed.units,
    receptionisLoading: state.reception.updateState.isLoading,
    filteredProductRails: StockStore.stockFilteredProductRailSelector(state),
    productRailsInPlano: StockStore.productRailsInPlanoSelector(state)
} as StockOwnProps),{
    ...OfferStore.actionCreators,
    ...ReceptionStore.actionCreators,
    ...StockStore.actionCreators,
    ...ReorderStore.actionCreators,
    ...PricePeriodStore.actionCreators
} )(Stock as any) as any as React.ComponentClass<{}>