import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Tooltip from "./Tooltip";
import * as MngHubStore from "../store/MngHub";
import * as AccountStore from "../store/Account";
import { isAuthenticated } from '../security/UserIsAuthenticated';
import * as _ from 'lodash';
import { getText } from '../utils/texts';

type UserHubProps = MngHubStore.MngHubState
    & UserHubOwnProps
    & typeof AccountStore.actionCreators
    & typeof MngHubStore.actionCreators;

interface UserHubOwnProps {
    account: any;
    isAuthenticated: boolean;
    currentUser: Api.CurrentUserManagerModel;
}

class UserHub extends React.Component<UserHubProps, {}> {
    get isConnected() {
        let connection = _.values(this.users).some(x => x.ConnectionId === this.props.connectionId);
        return this.props.isConnected && connection;
    }

    get users() {
        //console.log(this.props.connectedUsers);
        return this.props.connectedUsers;
    }

    public render() {
        return (
            <div style={{
                gridRow: "1 / 3", gridColumn: "1 / 3",
                borderRadius: 5,
                display: "flex", flexDirection: "column",
                alignItems: "center",
                height: "100%",
                margin: "0px 2px",
                ...Styles.colors.main2
            }}>
                {this.props.isAuthenticated && this.isConnected && this.props.account && this.users && this.users.length > 0
                    && <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", margin: 3 }}>
                        <div style={{ order: 0, flex: "0 0 50%", textAlign: "center" }}>
                            <Tooltip overlay={<div>{getText("HomeUserHubTooltip")}</div>}>
                                <div style={{ cursor: "default" }}>{this.users.length}</div>
                            </Tooltip>
                        </div>
                        <div style={{ order: 1, flex: "0 0 50%", textAlign: "center" }}>
                            <div style={{ display: "inline-block", position: "relative" }}>
                                <Tooltip placement={"bottom"}
                                    style={{ opacity: 1 }}
                                    containerStyle={{ opacity: 1 }}
                                    onVisibleChange={(value) => this.props.updateIsOpen(value)}
                                    trigger={["click"]}
                                    overlay={<div style={{ width: 100 }}>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center"}}>
                                        <div>{getText("HomeUserHubConnected")}: </div>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 100, maxHeight: 200, overflow: 'scroll', flex: "0 0 auto" }}>
                                                {this.users.map((user, i) => user && <div
                                                    key={i}
                                                    style={{
                                                        flex: "0 0 auto", border: "1px solid black",
                                                        padding: "3px 8px", color: user.Color, borderRadius: 4,
                                                        marginTop: 4
                                                    }}>
                                                    {user.Username}
                                                </div>)}
                                            </div>
                                            {/* Chat */}
                                            {/* <div style={{ flex: "0 0 auto", width: "100%", paddingTop: 5 }}>
                                                <div style={{ height: 200, overflowY: "scroll" }}>
                                                    {this.props.messages.map((message, i) => <div key={i}
                                                        style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ flex: "0 0 auto", color: message.hubUser.color }}>{message.hubUser.username} : </div>
                                                        <div style={{ flex: 1, whiteSpace: "pre-wrap" }}>
                                                            {" " + message.message}
                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>
                                            <div style={{ flex: "0 0 auto", width: "100%", paddingTop: 5, display: "flex", flexDirection: "row" }}>
                                                <div style={{ flex: 1 }}>
                                                    <textarea value={this.props.message}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey) {
                                                                this.props.sendMessage();
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return false;
                                                            }
                                                        }}
                                                        rows={2} style={{ width: "100%" }}
                                                        onChange={(value) => this.props.updateMessage(value.target.value)}></textarea>
                                                </div>
                                                <div style={{ flex: "0 0 auto", paddingBottom: 5 }}>
                                                    <button style={{ height: "100%" }}
                                                        onClick={(e) => {this.props.sendMessage()}}>Envoyer</button>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>}>
                                    <i className="glyphicon glyphicon-eye-open"
                                        style={{ cursor: "pointer" }}></i>
                                </Tooltip>
                                {/* {this.props.hasUnseenMessage && <div style={{
                                    position: "absolute",
                                    bottom: 3, right: -3,
                                    height: 6, width: 6,
                                    borderRadius: 6,
                                    backgroundColor: "red"
                                }}></div>} */}
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.mngHub,
    isAuthenticated: isAuthenticated(state),
    currentUser: state.account.currentUser,
    account: state.account.currentUser
        ? state.account.currentUser.userManager.storeAccount
        : null
} as UserHubOwnProps), { ...MngHubStore.actionCreators, ...AccountStore.actionCreators })(UserHub as any);
