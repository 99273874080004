import * as Api from '../api/api';
import * as _ from 'lodash';
import Moment from 'moment';
import * as React from 'react';
import * as ReactTable from 'react-table';
import * as NeoEventAlarmStore from '../store/NeoEventAlarm';
import * as TabsStore from '../store/Tabs';
import * as NeoEventStore from '../store/NeoEvent';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Table from './Table';
import { getText } from '../utils/texts';
import { hmiNotRunning } from '../store/HmiApplication';

type NeoEventAlarmsProps = NeoEventStore.NeoEventState
    & NeoEventAlarmsOwnProps
    & typeof NeoEventAlarmStore.actionCreators
    & typeof TabsStore.actionCreators
    & typeof NeoEventStore.actionCreators;

interface NeoEventAlarmsOwnProps {
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
    neoEventAlarms: { [id: number]: Api.NeoEventAlarmModel };
    hmiNotRunning: boolean;
}

type NeoEventAlarmColumn = ReactTable.Column
    & { accessor: (d: Api.NeoEventModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

const alarmsNotShow = [47, 48, 105, 143, 147, 402 ]

class NeoEventAlarms extends React.Component<NeoEventAlarmsProps, {}> {
    componentDidMount() {
        (this.props.requestNeoEventAlarms(new Date().getTime()) as any as Promise<any>)
            .then(() => this.props.requestAlarmEvents(new Date().getTime()));
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getEventLabelColor(neoEventCodeId) {
        let neoEvent = this.props.neoEventCodes[neoEventCodeId]
        let color = this.isDarkMode() ? 'white' : '#3B4370';
        if(neoEvent.code == 828 || neoEvent.code == 831) {
            color = 'orange';
        }        
        if(neoEvent.type == "FatalError") {
            color = 'red'
        }
        if(neoEvent.code >= 900 && neoEvent.code <= 999) {
            color = 'red';
        }    
        return color;
    }

    isDeletionEnabled(neoEventCodeId: number) {
        let neoEvent = this.props.neoEventCodes[neoEventCodeId];
        let isHmiNotRunning = this.props.hmiNotRunning;

        if((neoEvent && neoEvent.type === "Error" && (neoEvent.code !== 828 && neoEvent.code !== 831))
          || neoEvent.code == 883) {
            return true;
        } else {
            return isHmiNotRunning;
        }
    }

    public render() {
        return (
            <Table key={"alarmEvents"}
                className={"alarms-events-table"}
                style={{ height: '100%', width: '100%' }}
                showPageSizeOptions={false}
                pageSize={15} //this.props.alarmEventsState.neoEvents.length
                data={_.sortBy(_.values(this.props.alarmEventsState.neoEvents)
                        .filter(x => !alarmsNotShow.some(ns => ns == this.props.neoEventCodes[x.neoEventCodeId].code)), x => -x.neoEventId)}
                // getTrProps={(state, rowInfo, column) => {
                //     if (!rowInfo)
                //         return {};

                //     let neoEvent = rowInfo.original as Api.NeoEventModel;
                //     return {
                //         style: {
                //             color: this.props.neoEventAlarms[neoEvent.neoEventAlarmId]
                //                 ? (this.props.neoEventAlarms[neoEvent.neoEventAlarmId].type === "Major"
                //                 ? "red"
                //                 : "orange")
                //                 : this.props.neoEventCodes[neoEvent.neoEventCodeId].code >= 900 ? "red" : "orange"
                //         }
                //     };
                // }}
                columns={[
                    {
                        id: "creationDate",
                        Header: <div>{getText( "MaintenanceHour")}</div>,
                        style: {
                            height: '100%'
                        },
                        accessor: (d) => d,
                        Cell: row => <div style={{
                            lineHeight: '31px',
                            color: this.getEventLabelColor(row.value.neoEventCodeId)
                        }}>{Moment(row.value.creationDate).format("DD/MM/YYYY HH:mm:ss")}</div>,                        
                        width: 150,
                    },
                    {
                        id: "code",
                        Header: getText( "MaintenanceCode"),
                        style:{
                            width: '100%',
                            height: '100%'
                        },
                        //width: 700,
                        accessor: (d) => d,
                        Cell: row => <div style={{
                            textAlign: 'left', 
                            lineHeight: '31px',
                            marginLeft: '1%', 
                            whiteSpace: 'pre-wrap',
                            color: this.getEventLabelColor(row.value.neoEventCodeId)
                        }}>{`${this.props.neoEventCodes[row.value.neoEventCodeId].code} | ${row.value.message}`}</div>
                    },
                    //{
                    //    Header:  getText( "MaintenanceAlarm"),
                    //    accessor: (d) => this.props.neoEventAlarms[d.neoEventAlarmId]
                    //       ? <div style={{lineHeight: '31px'}}>{`${this.props.neoEventAlarms[d.neoEventAlarmId].type} `
                    //        + `| ${getAlarmActionOptions().find(x => x.value == this.props.neoEventAlarms[d.neoEventAlarmId].action).label}`}</div>
                    //        : "",
                    //},
                    {
                        
                        id: "neoEventAlarmId",
                        Header: getText( "MaintenanceAlarm"),
                        style: {
                            height: '100%'
                        },
                        accessor: (d) => d,
                        width: 70,
                        Cell: row => <div className="icon-container-dm" style={{
                            width: 20,
                            height: 20,
                            placeSelf: "center", 
                            backgroundColor: "#007AFF",
                            cursor: "pointer",
                            borderRadius: 5,
                            marginTop: "5%",
                            ...(this.isDeletionEnabled(row.value.neoEventCodeId) ? {} : { backgroundColor: "rgba(191, 191, 191, 1)" })
                        }}>
                                <i className="glyphicon glyphicon-trash icon-dm"
                                style={{
                                    margin: "11% 0", cursor: "pointer", paddingRight: "8%", color: "#FFFFFF",
                                    ...(this.isDeletionEnabled(row.value.neoEventCodeId) ? {} : { cursor: "not-allowed" })
                                }}
                                onClick={(e) => {
                                    if (this.isDeletionEnabled(row.value.neoEventCodeId)) {
                                        (this.props.requestAcquitNeoEvent(new Date().getTime(), row.value.neoEventId) as any as Promise<any>)
                                            .then(() => (this.props.requestNeoEventAlarms(new Date().getTime()) as any as Promise<any>)
                                                .then(() => this.props.requestAlarmEvents(new Date().getTime()))
                                            );
                                    }
                                    e.stopPropagation();
                                }} />
                        </div>
                    }
                    // {
                    //     id: "actions",
                    //     Header: "",
                    //     accessor: (d) => d.neoEventId,
                    //     Cell: row => <span style={{ cursor: "pointer", color: "blue" }}
                    //         onClick={(e) => {
                    //             this.props.requestAcquitNeoEvent(new Date().getTime(), row.value);
                    //             e.stopPropagation();
                    //         }}>acquiter</span>
                    // }
                ] as Array<NeoEventAlarmColumn>}
                showPagination={false}
                showPaginationBottom={false} />
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.neoEvent,
    neoEventCodes: state.seed.seed.neoEventCodes,
    neoEventAlarms: state.neoEventAlarm.neoEventAlarms,
    hmiNotRunning: hmiNotRunning(state),
} as NeoEventAlarmsOwnProps), {
        ...NeoEventAlarmStore.actionCreators,
        ...TabsStore.actionCreators,
        ...NeoEventStore.actionCreators
    })(NeoEventAlarms as any)