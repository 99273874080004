import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';

interface HistoryTurnoverV6Props {
    isLoading: boolean;
    turnoverV6: Array<Api.HistoCAModel>;
    timeGroupBy: HistoryStore.TimeGroupBy;
}

type HistoryTurnoverV6Column = ReactTable.Column
    & { accessor: (d: Api.HistoCAModel) => any; }

const columnTTCStyle: React.CSSProperties = { backgroundColor: 'rgba(144, 238, 144, 0.4)' }
const footerTTCStyle: React.CSSProperties = { backgroundColor: 'lightgreen' }

export default class HistoryTurnoverV6 extends React.Component<HistoryTurnoverV6Props, {}> {
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    public render() {
        return (
            <Table className="heightHisto"
                style={{margin: 0}}
                loading={this.props.isLoading}
                pageSize={Math.max(this.props.turnoverV6 ? this.props.turnoverV6.length : 0, 20)}
                getTheadGroupProps={(state, rowInfo, column)=>{
                    return {
                        style: {backgroundColor: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}
                    }
                }}
                showPageSizeOptions={false}
                showPagination={false}
                showPaginationBottom={false}
                columns={[
                    {
                        Header: <span>&nbsp;</span>,
                        id: 'date',
                        columns: [
                            {
                                Header: <div>{getText( "HistoDate")}</div>,
                                id: 'dateCA',
                                accessor: x => x.dateCA,
                                Cell: row => <div>{row.value ? Moment(row.value).format("DD/MM/YYYY") : ""}</div>,
                                minWidth: 160,
                                Footer: (
                                    <span><strong>{getText( "HistoTotal")}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoNb")}</div>,
                                id: 'nbTransac',
                                accessor: x => x.nbTransac,
                                width: 60,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => x.nbTransac)) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                        ]
                    },
                    {
                        Header: <div>{getText( "HistoCA")}</div>,
                        id: 'total',
                        columns: [
                            {
                                Header: <div>{getText( "HistoHT")}</div>,
                                id: 'cA_HT',
                                accessor: x => x.cA_HT,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HT))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoTTC")}</div>,
                                id: 'cA_TTC',
                                accessor: x => x.cA_TTC,
                                Cell: row => <div style={columnTTCStyle}>{row.value}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_TTC))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText("HistoMargin")}</div>,
                                id: 'marge',
                                accessor: x => x.marge,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.marge))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            }
                        ] as Array<HistoryTurnoverV6Column>
                    },
                    {
                        Header: <div>{getText( "HistoCashPayments")}</div>,
                        id: 'cash',
                        columns: [
                            {
                                Header: <div>{"5.5%"}</div>,
                                id: 'cA_Especes_TVA1',
                                accessor: x => x.cA_Especes_TVA1,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_Especes_TVA1))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"10%"}</div>,
                                id: 'cA_Especes_TVA2',
                                accessor: x => x.cA_Especes_TVA2,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_Especes_TVA2))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"20%"}</div>,
                                id: 'cA_Especes_TVA3',
                                accessor: x => x.cA_Especes_TVA3,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_Especes_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                id: 'cA_Especes_HT',
                                accessor: x => (parseFloat(x.cA_Especes_TVA1)+parseFloat(x.cA_Especes_TVA2)+parseFloat(x.cA_Especes_TVA3)).toFixed(2) ,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_Especes_TVA1)+parseFloat(x.cA_Especes_TVA2)+parseFloat(x.cA_Especes_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                id: 'cA_Especes_TTC',
                                accessor: x => ((parseFloat(x.cA_Especes_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_Especes_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_Especes_TVA3) * (1+parseFloat(x.tvA3)/100))),
                                Cell: row => <div style={columnTTCStyle}>{Math.round(row.value).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => ((parseFloat(x.cA_Especes_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_Especes_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_Especes_TVA3) * (1+parseFloat(x.tvA3)/100))))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            }
                        ] as Array<HistoryTurnoverV6Column>
                    },
                    {
                        Header: <div>{getText( "HistoCreditCardPayments")}</div>,
                        id: 'card',
                        columns: [
                            {
                                Header: <div>{"5.5%"}</div>,
                                id: 'cA_CB_TVA1',
                                accessor: x => x.cA_CB_TVA1,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_CB_TVA1))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"10%"}</div>,
                                id: 'cA_CB_TVA2',
                                accessor: x => x.cA_CB_TVA2,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_CB_TVA2))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"20%"}</div>,
                                id: 'cA_CB_TVA3',
                                accessor: x => x.cA_CB_TVA3,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_CB_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                id: 'cA_CB_HT',
                                accessor: x => (parseFloat(x.cA_CB_TVA1) +
                                                parseFloat(x.cA_CB_TVA2) +
                                                parseFloat(x.cA_CB_TVA3)).toFixed(2),
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => (parseFloat(x.cA_CB_TVA1) +
                                                parseFloat(x.cA_CB_TVA2)+parseFloat(x.cA_CB_TVA3)))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                id: 'cA_CB_TTC',
                                accessor: x => ((parseFloat(x.cA_CB_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_CB_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_CB_TVA3) * (1+parseFloat(x.tvA3)/100))),
                                Cell: row => <div style={columnTTCStyle}>{Math.round(row.value).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => ((parseFloat(x.cA_CB_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_CB_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_CB_TVA3) * (1+parseFloat(x.tvA3)/100))))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            }
                        ] as Array<HistoryTurnoverV6Column>
                    },
                    {
                        Header: <div>{getText( "HistoNFCPayments")}</div>,
                        id: 'noContact',
                        columns: [
                            {
                                Header: <div>{"5.5%"}</div>,
                                id: 'cA_NFC_TVA1',
                                accessor: x => x.cA_NFC_TVA1,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_NFC_TVA1))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"10%"}</div>,
                                id: 'cA_NFC_TVA2',
                                accessor: x => x.cA_NFC_TVA2,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_NFC_TVA2))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"20%"}</div>,
                                id: 'cA_NFC_TVA3',
                                accessor: x => x.cA_NFC_TVA3,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_NFC_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                id: 'cA_NFC_HT',
                                accessor: x => (parseFloat(x.cA_NFC_TVA1)+parseFloat(x.cA_NFC_TVA2)+parseFloat(x.cA_NFC_TVA3)).toFixed(2),
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_NFC_TVA1)+parseFloat(x.cA_NFC_TVA2)+parseFloat(x.cA_NFC_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                id: 'cA_NFC_TTC',
                                accessor: x => ((parseFloat(x.cA_NFC_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_NFC_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_NFC_TVA3) * (1+parseFloat(x.tvA3)/100))),
                                Cell: row => <div style={columnTTCStyle}>{Math.round(row.value).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => ((parseFloat(x.cA_NFC_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_NFC_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_NFC_TVA3) * (1+parseFloat(x.tvA3)/100))))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            }
                        ] as Array<HistoryTurnoverV6Column>
                    },
                    {
                        Header: <div>{getText( "HistoSmartphonePayments")}</div>,
                        id: 'sm',
                        columns: [
                            {
                                Header: <div>{"5.5%"}</div>,
                                id: 'cA_SMART_TVA1',
                                accessor: x => x.cA_SMART_TVA1,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_SMART_TVA1))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"10%"}</div>,
                                id: 'cA_SMART_TVA2',
                                accessor: x => x.cA_SMART_TVA2,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_SMART_TVA2))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"20%"}</div>,
                                id: 'cA_SMART_TVA3',
                                accessor: x => x.cA_SMART_TVA3,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_SMART_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                id: 'cA_SMART_HT',
                                accessor: x => (parseFloat(x.cA_SMART_TVA1)+parseFloat(x.cA_SMART_TVA2)+parseFloat(x.cA_SMART_TVA3)).toFixed(2),
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_SMART_TVA1)+parseFloat(x.cA_SMART_TVA2)+parseFloat(x.cA_SMART_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                id: 'cA_SMART_TTC',
                                accessor: x => ((parseFloat(x.cA_SMART_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_SMART_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_SMART_TVA3) * (1+parseFloat(x.tvA3)/100))),
                                Cell: row => <div style={columnTTCStyle}>{Math.round(row.value).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => ((parseFloat(x.cA_SMART_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_SMART_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_SMART_TVA3) * (1+parseFloat(x.tvA3)/100))))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            }
                        ] as Array<HistoryTurnoverV6Column>
                    },
                    {
                        Header: <div>{'Paiements Externe'}</div>,
                        id: 'externe',
                        columns: [
                            {
                                Header: <div>{"5.5%"}</div>,
                                id: 'cA_EXTERNE_TVA1',
                                accessor: x => x.cA_EXTERNE_TVA1,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_EXTERNE_TVA1))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"10%"}</div>,
                                id: 'cA_EXTERNE_TVA2',
                                accessor: x => x.cA_EXTERNE_TVA2,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_EXTERNE_TVA2))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{"20%"}</div>,
                                id: 'cA_EXTERNE_TVA3',
                                accessor: x => x.cA_EXTERNE_TVA3,
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_EXTERNE_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCAHT")}</div>,
                                id: 'cA_EXTERNE_HT',
                                accessor: x => (parseFloat(x.cA_EXTERNE_TVA1)+parseFloat(x.cA_EXTERNE_TVA2)+parseFloat(x.cA_EXTERNE_TVA3)).toFixed(2),
                                Footer: (
                                    <div>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_EXTERNE_TVA1)+parseFloat(x.cA_EXTERNE_TVA2)+parseFloat(x.cA_EXTERNE_TVA3))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoCATTC")}</div>,
                                id: 'cA_EXTERNE_TTC',
                                accessor: x => ((parseFloat(x.cA_EXTERNE_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_EXTERNE_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_EXTERNE_TVA3) * (1+parseFloat(x.tvA3)/100))),
                                Cell: row => <div style={columnTTCStyle}>{Math.round(row.value).toFixed(2)}</div>,
                                Footer: (
                                    <div style={footerTTCStyle}>
                                        <div><span><strong>{_.sum(_.map(this.props.turnoverV6, x => ((parseFloat(x.cA_EXTERNE_TVA1) * (1+parseFloat(x.tvA1)/100))+
                                                (parseFloat(x.cA_EXTERNE_TVA2) * (1+parseFloat(x.tvA2)/100)) +
                                                (parseFloat(x.cA_EXTERNE_TVA3) * (1+parseFloat(x.tvA3)/100))))).toFixed(2) || "0"}</strong></span></div>
                                    </div>
                                )
                            }
                        ] as Array<HistoryTurnoverV6Column>
                    }
                ] as Array<HistoryTurnoverV6Column>}
                data={this.props.turnoverV6 ? this.props.turnoverV6 : []} />
        );
    }
}