import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/texts';
import _ from 'lodash';


type MenuCategoryFormProps = ReduxForm.InjectedFormProps<Api.MenuCategoryModel, {}, {}>
    & { submitting: boolean; pristine: boolean; }
    & MenuCategoryFormOwnProps
    & MenuCategoryFormExternalProps;


interface MenuCategoryFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.MenuCategoryModel) => any;
}

interface MenuCategoryFormOwnProps {
}

class MenuCategoryForm extends React.Component<MenuCategoryFormProps, {}> {

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    public render() {
        return (
            <FormField.BaseForm 
                {...this.props}
                className="form-vertical" 
                style={{ maxWidth: '85%'}}>
                <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '2%'}}>
                    <div className="col-md-12 form-group">
                        <label className="control-label">{getText("MenuCatCategory")}</label>
                        <ReduxForm.Field
                            style={{ textTransform: 'uppercase'}}
                            name="name"
                            type={"text"}
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                    </div>
                    <div className="col-md-12 form-group">
                        <button className="btn btn-primary"
                            style={{width: '100%', height: 40, fontWeight: 'bold', fontSize: 15, 
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            type={"submit"}
                            disabled={this.props.submitting || this.props.pristine}>
                            {this.props.actionName}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createMenuCategory'));

let createForm = ReduxForm.reduxForm({
    form: 'createMenuCategory',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(MenuCategoryForm as any) as any;

const createSelector = ReduxForm.formValueSelector('createMenuCategory');

export const CreateMenuCategoryForm = connect((state: ApplicationState) => {
    return { } as MenuCategoryFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<MenuCategoryFormExternalProps>;


let updateForm = ReduxForm.reduxForm({
    form: 'updateMenuCategory',
    enableReinitialize: true,
    destroyOnUnmount: true
})(MenuCategoryForm as any) as any;

const updateSelector = ReduxForm.formValueSelector('updateMenuCategory');

export const UpdateMenuCategoryForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.menuCategories.selectedMenuCategoryId
            ? state.seed.seed.menuCategories[state.menuCategories.selectedMenuCategoryId]
            : {},
    } as MenuCategoryFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<MenuCategoryFormExternalProps>;