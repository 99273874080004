import * as React from 'react';
import ReactModal from 'react-modal';
import { CSSProperties } from 'react';

interface DialogModalProps {
    contentStyle?: CSSProperties;
    isOpen: boolean;
    contentLabel: string;
    children: any;
    title?: string;
    onRequestClose: () => void;
}

export default class DialogModel extends React.Component<DialogModalProps, {}> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps: DialogModalProps) {
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <ReactModal
                ariaHideApp={false}
                isOpen={this.props.isOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => this.props.onRequestClose()}
                className="scroll-modal"
                style={{
                    ...ReactModal.defaultStyles,
                    overlay: {
                        ...ReactModal.defaultStyles.overlay,
                        backgroundColor: 'rgba(120, 120, 120, 0.50)',
                        zIndex: 999,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    },
                    content: {
                        ...ReactModal.defaultStyles.content,
                        position: "relative",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: "auto",
                        ...this.props.contentStyle
                    }
                }}
                contentLabel={this.props.contentLabel}>
                <div style={{
                    height: "100%", display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",                    
                    textAlign: "center"
                }}>
                    <div style={{
                        flex: "0 0 auto", display: "flex",
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div style={{fontSize: 22, textAlign: "center", width: "100%", paddingTop: '1%'}}>
                            {this.props.title}
                        </div>
                        <button
                            style={{ 
                                background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                                color: this.isDarkMode() ? "#FFFFFF" : "#181F3A", 
                                border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)"                                
                            }}
                            className="btn buttonModal"
                            onClick={(e) => {
                                this.props.onRequestClose();
                                e.preventDefault();
                            }}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                    <div style={{ flex: 1 }}>
                        {this.props.children}
                    </div>
                </div>
            </ReactModal>
        );
    }
}