import * as React from "react";
import * as _ from 'lodash';
import * as Api from '../api/api';
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type ShelvingTrayFormProps = InjectedFormProps<{}, {}, {}>
    & ShelvingTrayFormOwnProps
    & ShelvingTrayFormExternalProps;

interface ShelvingTrayFormOwnProps {
    text: string;
    disabled: boolean;
    shelvingTrays: { [key: string]: Api.ShelvingTrayModel; };
    codeUpdated? : string;
}

interface ShelvingTrayFormExternalProps {
    onSubmit: (values: Api.ShelvingTrayModel) => Promise<any>
}

interface ShelvingTrayState {
}

class ShelvingTrayForm extends React.Component<ShelvingTrayFormProps, ShelvingTrayState> {
    constructor(props: ShelvingTrayFormProps) {
        super(props);
        this.validationShelvingTray = this.validationShelvingTray.bind(this);
        this.state = {
        };
    }

    validationShelvingTray(value) {
        return  _.values(this.props.shelvingTrays)
            .some(x => (this.props.codeUpdated == undefined && x.code === value ) ||
                 (this.props.codeUpdated != value && x.code === value))
            ? getText("SettingsShelvingTrayValidation")
            : undefined
    }

    public render() {
        return (<FormField.BaseForm {...this.props} className="form-vertical">
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsNameTable")}</label>
                    <Field name="name"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsCode")}</label>
                    <Field name="code"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required, this.validationShelvingTray]}
                        component={FormField.renderField} />
                </div>
            </div>
            <div className="row" style={{padding: '0 1%', textAlign: 'left'}}>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsFirst")}</label>
                    <Field name="railStart"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsSecond")}</label>
                    <Field name="railEnd"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
            </div>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', display: 'flex', alignItems: 'flex-end'}}>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "SettingsDepthTray")}</label>
                    <Field name="depth"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-6 form-group">
                    <button type={"submit"}
                        className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting || this.props.disabled}>
                        {this.props.text}
                    </button>
                </div>
            </div>
        </FormField.BaseForm>);
    }
}

let createForm = reduxForm({
    form: 'createShelvingTray',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ShelvingTrayForm as any) as any;
const createSelector = formValueSelector('createShelvingTray');

export const CreateShelvingTrayForm = connect((state: ApplicationState) => {
    let elements = state.stock.stockStatus.shelvingTrays ? JSON.parse(JSON.stringify(state.stock.stockStatus.shelvingTrays)) : null;
    let lastElement;
    if (elements) {
        Object.keys(elements).forEach(function (key) {
            lastElement = elements[key]
        });
    }
    if(lastElement) {
        let newName = (Number(lastElement.name) + 1).toString();
        lastElement.name = newName;
        let newCode = (Number(lastElement.code) + 1).toString();
        lastElement.code = newCode;
    }
    return {
        text: getText( "SettingsCreate"),
        initialValues: lastElement ? lastElement : null,
        disabled: !canUpdate(state),
        shelvingTrays : state.stock.stockStatus.shelvingTrays
    } as ShelvingTrayFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<ShelvingTrayFormExternalProps>


let form = reduxForm({
    form: 'updateShelvingTray',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ShelvingTrayForm as any) as any;
const updateSelector = formValueSelector('updateShelvingTray');

export const UpdateShelvingTrayForm = connect((state: ApplicationState) => {
    return {
        text: getText( "SettingsSave"),
        initialValues: state.stock.stockStatus.shelvingTrays[state.stock.selectedShelvingTrayId],
        disabled: !canUpdate(state),
        shelvingTrays : state.stock.stockStatus.shelvingTrays,
        codeUpdated: state.stock.stockStatus.shelvingTrays[state.stock.selectedShelvingTrayId].code,
    } as ShelvingTrayFormOwnProps
}, null)(form as any) as any as React.ComponentClass<ShelvingTrayFormExternalProps>

