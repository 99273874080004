import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import Voucher from './components/Voucher';
import Stock from './components/Stock';
import Reception from './components/Reception';
import Maintenance from './components/Maintenance';
import Planogram from './components/Planogram';
import Offer from './components/Offer';
import Settings from './components/Settings';
import ProductsDetails from './components/ProductsDetails';
import CashRegister from './components/CashRegister';
import History from './components/History';
import Menu from './components/Menu';
import UserIsAuthenticated from './security/UserIsAuthenticated';
import UserIsNotAuthenticated from './security/UserIsNotAuthenticated';
import LoginLgap from './components/LoginLgap';
import Reappro from './components/Reappro';
import SupplierOrders from './components/SupplierOrders';
import SalesAnalysis from './components/SalesAnalysis';
import Event from './components/Event';
import Interventions from './components/Interventions';
import Promotions from './components/Promotions';
import Forum from './components/Forum';
import SalesTariff from './components/SalesTariff';
import PurchaseTariff from './components/PurchaseTariff';
import ProductSizes from './components/ProductSizes';
import MenuCategories from './components/MenuCategories';
// import ProductMenuCategories from './components/ProductMenuCategories';
import Support from './components/Support';
import './css/site.css';
import 'rc-select/assets/index.css';
import 'rc-menu/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default () => (<Layout>
    <Switch>
        <Route exact path='/stock' component={UserIsAuthenticated(Stock)} />
        <Route exact path='/reception' component={UserIsAuthenticated(Reception)} />
        <Route exact path='/maintenance' component={UserIsAuthenticated(Maintenance)} />
        <Route exact path='/promotions' component={UserIsAuthenticated(Promotions)} />
        <Route exact path='/product' component={UserIsAuthenticated(ProductsDetails)} />
        <Route exact path='/planogram' component={UserIsAuthenticated(Planogram)} />
        <Route exact path='/offer' component={UserIsAuthenticated(Offer)} />
        <Route exact path='/voucher' component={UserIsAuthenticated(Voucher)} />
        <Route exact path='/cashregister' component={UserIsAuthenticated(CashRegister)} />
        <Route exact path='/history' component={UserIsAuthenticated(History)} />
        <Route exact path='/settings' component={UserIsAuthenticated(Settings)} />
        <Route exact path='/reorder' component={UserIsAuthenticated(SupplierOrders)} />
        <Route exact path='/menu' component={UserIsAuthenticated(Menu)} />
        <Route exact path='/login' component={UserIsNotAuthenticated(Login)} />
        <Route exact path='/lgap' component={UserIsAuthenticated(LoginLgap)} />
        <Route exact path='/reappro' component={UserIsAuthenticated(Reappro)} />
        <Route exact path='/salesanalysis' component={UserIsAuthenticated(SalesAnalysis)} />
        <Route exact path='/events' component={UserIsAuthenticated(Event)} />
        {/* <Route exact path='/interventions' component={UserIsAuthenticated(Interventions)} /> */}
        <Route exact path='/forum' component={UserIsAuthenticated(Forum)} />
        <Route exact path='/salestariff' component={UserIsAuthenticated(SalesTariff)} />
        <Route exact path='/purchasetariff' component={UserIsAuthenticated(PurchaseTariff)} />
        <Route exact path='/productsizes' component={UserIsAuthenticated(ProductSizes)} />
        <Route exact path='/menucategories' component={UserIsAuthenticated(MenuCategories)} />
        {/* <Route exact path='/productmenucategories' component={UserIsAuthenticated(ProductMenuCategories)} /> */}
        <Route exact path='/support' component={UserIsAuthenticated(Support)} />

        <Route component={UserIsAuthenticated(Home)} />
    </Switch>
</Layout>);

