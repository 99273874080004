import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type ContactFormProps = ReduxForm.InjectedFormProps<Api.ContactModel, {}, {}>
    & { submitting: boolean; pristine: boolean; }
    & ContactFormOwnProps
    & ContactFormExternalProps;

interface ContactFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.ContactModel) => any;
}

interface ContactFormOwnProps {
    disabled: boolean;
}

class ContactForm extends React.Component<ContactFormProps, {}> {
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    public render() {
        return (
            <FormField.BaseForm 
                {...this.props}
                className="form-vertical" 
                style={{ maxWidth: '70%' }}>
                <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '2%'}}>
                    <div className="col-md-6 form-group">
                        <label className="control-label">{getText( "MaintenanceLastName")} </label>
                        <ReduxForm.Field
                            name="lastName"
                            type={"text"}
                            style={{height: 40}}
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="control-label">{getText( "MaintenancePhone")}  </label>
                        <ReduxForm.Field
                            name="phone"
                            type={"text"}
                            style={{height: 40}}
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                    </div>
                </div>
                <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '2%'}}>  
                    <div className="col-md-6 form-group">
                        <label className="control-label">{getText( "MaintenanceFirstName")}  </label>
                        <ReduxForm.Field
                            name="firstName"
                            type={"text"}
                            style={{height: 40}}
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="control-label">{getText( "MaintenanceMail")} </label>
                        <ReduxForm.Field
                            name="email"
                            type={"text"}
                            style={{height: 40}}
                            className="form-control"
                            validate={[FormField.required]}
                            component={FormField.renderField} />
                    </div>
                </div>
                <div className="row" style={{marginTop: '1%'}}>
                    <div className="col-md-6">
                        <button className="btn btn-primary"
                            style={{width: '100%', height: 40, fontWeight: 'bold', fontSize: 15, 
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            type={"submit"}
                            disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                            {this.props.actionName}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createContact',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ContactForm as any) as any;
const createSelector = ReduxForm.formValueSelector('createContact');
export const CreateContactForm = connect((state: ApplicationState) => {
    return {
        initialValues: {
           
        } as Api.ContactModel,
        disabled: !canUpdate(state)
    } as ContactFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<ContactFormExternalProps>;

let updateForm = ReduxForm.reduxForm({
    form: 'updateContact',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ContactForm as any) as any;
const updateSelector = ReduxForm.formValueSelector('updateContact');
export const UpdateContactForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.contact.selectedContactId
            ? state.contact.contacts[state.contact.selectedContactId]
            : {},
        disabled: !canUpdate(state)
    } as ContactFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<ContactFormExternalProps>;