import * as Api from '../api/api';
import Moment from 'moment';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { padLeft } from '../utils/utils';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type PeriodFormProps = ReduxForm.InjectedFormProps<Api.PeriodModel, {}, {}>
    & PeriodFormOwnProps
    & PeriodFormExternalProps;

interface PeriodFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.PeriodModel) => any;
}

interface PeriodFormOwnProps {
    disabled: boolean;
    monthStart?: number;
    monthEnd?: number;
}

const textAreaField = FormField.textAreaField(6, 45);

const hoursSelections: Array<{ label: string, value: number }> = [
    { label: " ", value: null }
].concat(Array.from(Array(24).keys())
    .map(x => x + 1)
    .map(x => ({
        label: padLeft(x, 2, '0'),
        value: x
    })));

const monthsSelections: Array<{ label: string, value: number }> = [
    { label: " ", value: null }
].concat(Array.from(Array(12).keys())
    .map(x => x + 1)
    .map(x => {
        let padded = padLeft(x, 2, '0');
        let month = Moment(`01/${padded}/2020`, "DD/MM/YY").format("MMM");
        return {
            label: `${padded} ${month}`,
            value: x
        };
    }));


const noHoursOrBoth = (value, allValues: Api.PeriodModel) => (allValues.hourStart && !allValues.hourEnd)
    || (!allValues.hourStart && allValues.hourEnd)
    ? getText( "PricePeriodHoursValidation")
    : undefined;

const noMonthsOrBoth = (value, allValues: Api.PeriodModel) => (allValues.monthStart && !allValues.monthEnd)
    || (!allValues.monthStart && allValues.monthEnd)
    ? getText( "PricePeriodMonthsValidation")
    : undefined;

class PeriodForm extends React.Component<PeriodFormProps, {}> {
    get monthStartDaysInMonth(): number {
        return this.props.monthStart
            ? Moment("01/" + padLeft(this.props.monthStart, 2, '0') + "/2020", "DD/MM/YY").daysInMonth()
            : 1;
    }
    get monthEndDaysInMonth(): number {
        return this.props.monthEnd
            ? Moment("01/" + padLeft(this.props.monthEnd, 2, '0') + "/2020", "DD/MM/YY").daysInMonth()
            : 1;
    }    

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-vertical selectors borderSelectors" >
                <div className="row" style={{padding: '0 1%', textAlign: 'left'}}>
                    <div className="col-md-6">
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodName")} : </label>
                            <ReduxForm.Field
                                name="name"
                                className={"form-control"}
                                type={"text"}
                                style={{
                                    backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                    color: this.isDarkMode() ? "white" : "#3B4370"
                                }}
                                validate={[FormField.required]}
                                component={FormField.renderField} />
                        </div>
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodStartHour")} : </label>
                            <ReduxForm.Field
                                name="hourStart"
                                validate={[noHoursOrBoth]}
                                component={FormField.getSelectField(hoursSelections)} />
                        </div>
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodEndHour")} : </label>
                            <ReduxForm.Field
                                name="hourEnd"
                                validate={[noHoursOrBoth]}
                                component={FormField.getSelectField(hoursSelections)} />
                        </div>
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodStartMonth")} : </label>
                            <ReduxForm.Field
                                name="monthStart"
                                validate={[noMonthsOrBoth]}
                                component={FormField.getSelectField(monthsSelections)} />
                        </div>
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodEndMonth")} : </label>
                            <ReduxForm.Field
                                name="monthEnd"
                                validate={[noMonthsOrBoth]}
                                component={FormField.getSelectField(monthsSelections)} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodStartDayOfMonth")} : </label>
                            <ReduxForm.Field
                                name="monthDayStart"
                                validate={[]}
                                component={FormField.getSelectField(
                                    [{ label: " ", value: null }].concat(Array.from(Array(this.monthStartDaysInMonth).keys())
                                        .map(x => x + 1)
                                        .map(x => ({ label: `${x}`, value: x }))))} />
                        </div>
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <label className="control-label">{getText( "PricePeriodEndDayOfMonth")} : </label>
                            <ReduxForm.Field
                                name="monthDayEnd"
                                validate={[]}
                                component={FormField.getSelectField(
                                    [{ label: " ", value: null }].concat(Array.from(Array(this.monthEndDaysInMonth).keys())
                                        .map(x => x + 1)
                                        .map(x => ({ label: `${x}`, value: x }))))} />
                        </div>
                        <div className="form-group" style={{ marginBottom: 12 }}>
                            <div className="row">
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="monday"
                                        label={getText( "PricePeriodMonday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="tuesday"
                                        label={getText( "PricePeriodTuesday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="wednesday"
                                        label={getText( "PricePeriodWednesday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="thursday"
                                        label={getText( "PricePeriodThursday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="friday"
                                        label={getText( "PricePeriodFriday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="saturday"
                                        label={getText( "PricePeriodSaturday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <ReduxForm.Field
                                        name="sunday"
                                        label={getText( "PricePeriodSunday")}
                                        validate={[]}
                                        style={{width: 20, height: 20, fontSize: 10}}
                                        component={FormField.CheckBoxField} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary"
                        style={{
                            fontSize: 14,
                            width: '50%',
                            backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                            color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                            borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                        }}
                        type={"submit"}
                        disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                        {this.props.actionName}
                        </button>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createPeriod',
    enableReinitialize: true,
    destroyOnUnmount: true
})(PeriodForm as any) as any;
const createSelector = ReduxForm.formValueSelector('createPeriod');
export const CreatePeriodForm = connect((state: ApplicationState) => {
    return {
        initialValues: {
            sunday: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true
        } as Api.PeriodModel,
        disabled: !canUpdate(state),
        monthStart: createSelector(state, "monthStart"),
        monthEnd: createSelector(state, "monthEnd"),
    } as PeriodFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<PeriodFormExternalProps>;

let updateForm = ReduxForm.reduxForm({
    form: 'updatePeriod',
    enableReinitialize: true,
    destroyOnUnmount: true
})(PeriodForm as any) as any;
const updateSelector = ReduxForm.formValueSelector('updatePeriod');
export const UpdatePeriodForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.period.selectedPeriodId
            ? state.period.periods[state.period.selectedPeriodId]
            : {},
        disabled: !canUpdate(state),
        monthStart: updateSelector(state, "monthStart"),
        monthEnd: updateSelector(state, "monthEnd"),
    } as PeriodFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<PeriodFormExternalProps>;