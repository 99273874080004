import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as OfferStore from '../store/Offer';
import Select from "./Select";
import DatePicker from "./DatePicker";
import { getDefaultDateEnd } from '../store/Offer';
import InputNumber from './InputNumber';

interface ItemOfferProps {
    itemOfferState: OfferStore.ItemOfferState;
    currency: Api.CurrencyModel;
    disabled: boolean;
    prices: Array<Api.PriceModel>;
    defaultPriceNameId?: number;
    priceNames: { [id: number]: Api.PriceNameModel };
    itemOffer?: {
        priceNameId: number;
        dateEnd: Date;
        daysBeforeExpirity?: number;
    };
    isDLC?: boolean;
    hasDateEnd: boolean;
    hasDaysBeforeExpirity: boolean;
    defaultDateEnd?: Date;
    updatePriceNameId: (value: number) => void;
    updateChecked: (value: boolean) => void;
    updateDateEnd: (value: Date) => void;
    updateDaysBeforeExpirity: (value: number) => void;
    onValidate: () => void;
    onDelete: () => void;
}

export default class ItemOffer extends React.Component<ItemOfferProps, {}> {

    get priceNameId(): number {
        return (this.props.itemOfferState
            ? (this.props.itemOfferState.priceNameId
                || (this.props.itemOffer
                    && this.props.itemOffer.priceNameId))
            : (this.props.itemOffer
                && this.props.itemOffer.priceNameId))
            || (this.priceNames.some(x => x.priceNameId === this.props.defaultPriceNameId) ? this.props.defaultPriceNameId : false)
            || (this.priceNames[0]
                && this.priceNames[0].priceNameId);
    }

    get dateEnd(): Date {
        return (this.props.itemOfferState
            ? (this.props.itemOfferState.dateEnd
                || (this.props.itemOffer
                    && this.props.itemOffer.dateEnd))
            : (this.props.itemOffer
                && this.props.itemOffer.dateEnd))
            ||
            (this.props.defaultDateEnd
                ? this.props.defaultDateEnd
                : getDefaultDateEnd());
    }

    get daysBeforeExpirity(): number {
        return (this.props.itemOfferState
            ? (this.props.itemOfferState.daysBeforeExpirity
                || (this.props.itemOffer
                    && this.props.itemOffer.daysBeforeExpirity))
            : (this.props.itemOffer
                && this.props.itemOffer.daysBeforeExpirity))
            || 3;
    }

    get priceNames(): Array<Api.PriceNameModel> {
        return _.values(this.props.priceNames)
            .filter(x => x.type === "Offer"
                && this.props.prices.some(y => y.priceNameId === x.priceNameId));
    }

    get price(): Api.PriceModel {
        let price = this.props.prices
            .find(x => x.currencyId === this.props.currency.currencyId
                && x.priceNameId === this.priceNameId)
            || this.props.prices
                .find(x => x.currencyId === this.props.currency.currencyId);

        return price;
    }

    get isChecked(): boolean {
        return this.props.itemOfferState
            ? (this.props.itemOfferState.isChecked === false ? false : true)
            : (this.props.itemOffer ? true : false)
    }

    get canValidate(): boolean {
        return ((!this.props.itemOffer
            || (this.props.itemOfferState
                && (this.props.itemOffer.priceNameId !== this.props.itemOfferState.priceNameId
                    || this.props.itemOffer.dateEnd !== this.props.itemOfferState.dateEnd
                    || this.props.itemOffer.daysBeforeExpirity !== this.props.itemOfferState.daysBeforeExpirity)))
            && !this.props.disabled)
            ? true
            : false;
    }

    get canDelete(): boolean {
        return this.isChecked
            && !this.props.disabled;
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{
                display: "flex", marginTop: '1%'
            }}>
                <input type="checkbox"
                    className="offer-checkbox"
                    style={{ flex: "0 0 auto", margin: 0 }}
                    checked={this.isChecked}
                    disabled={this.props.disabled}
                    onClick={(e) => {
                        let newVal = !this.isChecked;
                        this.props.updateChecked(newVal);
                        if (newVal && this.canValidate) {
                            this.props.onValidate();
                        } else if (!newVal && this.canDelete) {
                            this.props.onDelete();
                        }
                    }}
                    onChange={e => {}} 
                />
                {this.props.hasDaysBeforeExpirity && this.props.isDLC && <div style={{ flex: "0 0 auto", padding: "0px 5px" }}>
                    <InputNumber value={this.isChecked ? this.daysBeforeExpirity : undefined}
                        className="form-control input-sm"
                        style={{ width: 50 }}
                        disabled={!this.isChecked}
                        onChange={(val) => {
                            this.props.updateDaysBeforeExpirity(val);
                            this.props.onValidate();
                        }} />
                </div>}
                <div style={{ flex: "0 0 55%", padding: "0px 5px" }}>
                    <Select options={this.priceNames
                        .map(x => ({
                            label: `${x.name} | ${this.props.prices.find(y => y.priceNameId === x.priceNameId).value.toFixed(2)} ${this.props.currency.symbol}`,
                            value: x.priceNameId
                        }))}
                        disabled={!this.isChecked}
                        value={this.isChecked ? this.priceNameId : undefined}
                        className={this.props.hasDateEnd ? 'item-offer-select': ''}
                        style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }}
                        onChange={(value) => {
                            this.props.updatePriceNameId(value);
                            this.props.onValidate();
                        }} />
                </div>
                {this.props.hasDateEnd && <div style={{ flex: "1 0 auto" }}>
                    <DatePicker value={this.isChecked ? this.dateEnd : undefined}
                        //style={{width: 180}}
                        className="form-control input-sm"
                        disabled={!this.isChecked}
                        dateFormat={'dd DD/MM/YYYY'}
                        minDate={Moment().startOf("day").toDate()}
                        onChange={(date) => {
                            this.props.updateDateEnd(date);
                            this.props.onValidate();
                        }} />
                </div>}
                {this.props.hasDaysBeforeExpirity && !this.props.isDLC && <div style={{ flex: "0 0 auto" }}>
                    <InputNumber value={this.isChecked ? this.daysBeforeExpirity : undefined}
                        className="form-control input-sm"
                        style={{ width: 50 }}
                        disabled={!this.isChecked}
                        onChange={(val) => {
                            this.props.updateDaysBeforeExpirity(val);
                            this.props.onValidate();
                        }} />
                </div>}
                {this.props.itemOfferState
                    && this.props.itemOfferState.isLoading
                    && <div style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "gray",
                        opacity: 0.3
                    }}></div>}
            </div>
        );
    }
}