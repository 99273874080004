import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as OfferStore from '../store/Offer';
import * as StockStore from "../store/Stock";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { CSSProperties } from 'react';
import * as ReactTable from "react-table";
import Table from "./Table";
import ItemOffer from "./ItemOffer";
import Select from "./Select";
import { canUpdate } from '../security/roles';
import InputNumber from './InputNumber';
import HelpHeader from "./HelpHeader";
import { getText } from '../utils/texts';
import DialogModal from "./DialogModal";

type OfferProps = OfferOwnProps
    & OfferStore.OfferState
    & typeof StockStore.actionCreators
    & typeof OfferStore.actionCreators;

interface OfferOwnProps {
    stock: StockStore.StockState;
    currency: Api.CurrencyModel;
    promotions: { [id: number]: Api.PromotionModel };
    priceNames: { [id: number]: Api.PriceNameModel };
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
    products: Array<Api.ProductModel>;
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;
}

type OfferColumn = ReactTable.Column
    & {
        accessor: (d: Api.ProductModel) => any;
        style?: CSSProperties;
        columns?: Array<OfferColumn>
    };

class Offer extends React.Component<OfferProps, {}> {
    componentDidMount() {
        this.props.requestItemOfferResult(new Date().getTime());
    }

    noProductHasPromotion() {
        let response = { key: false, label: getText("OfferDisable") };
        let productsLength = this.props.products.length;
        let promotionsLength = 0;
        _.values(this.props.promotions)
            .map(promotion => {
                this.props.products.forEach(product => {
                    let itemOffer = this.props.itemOfferResult
                        && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                        && this.props.itemOfferResult.itemPromotions[product.storeItemId]
                            .find(x => x.promotionId === promotion.promotionId);
                    if (itemOffer) {
                        promotionsLength++;
                    }
                });
            });
        if (0 === promotionsLength) {
            response.key = true;
            response.label = getText("OfferActivate")
        }
        return response
    }

    noProductHasDLC() {
        let response = { key: false, label: getText("OfferDisable") };
        let productsLength = this.props.products.length;
        let promotionsDLCLength = 0;
        _.values(this.props.promotions)
            .map(promotion => {
                this.props.products.forEach(product => {
                    let itemOffer = this.props.itemOfferResult
                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                    if (itemOffer && product.expirityType === "Normal") {
                        promotionsDLCLength++;
                    }
                });
            });
        if (0 === promotionsDLCLength) {
            response.key = true;
            response.label = getText("OfferActivate")
        }
        return response
    }

    noProductHasDDM() {
        let response = { key: false, label: getText("OfferDisable") };
        let productsLength = this.props.products.length;
        let promotionsDDMLength = 0;
        _.values(this.props.promotions)
            .map(promotion => {
                this.props.products.forEach(product => {
                    let itemOffer = this.props.itemOfferResult
                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                    if (itemOffer && product.expirityType === "Ddm") {
                        promotionsDDMLength++;
                    }
                });
            });
        if (0 === promotionsDDMLength) {
            response.key = true;
            response.label = getText("OfferActivate")
        }
        return response
    }

    get currentItemOfTheDay(): Api.ItemOfTheDayModel {
        return (this.props.itemOfferResult && this.props.itemOfferResult.itemOfTheDay)
            || {};
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        this.props.updateOfferFilter({});
    }

    public render() {
        return (
            <div style={{ height: "100%" }}>
                <div className="card"
                    style={{
                        ...Styles.cardStyle,
                        ...Styles.pageTitle
                    }}>
                    <span style={{ paddingLeft: 30 }}>{getText("OfferTitle")}</span>
                    <HelpHeader />
                </div>
                <div className="selectors" style={{ display: 'flex', margin: '0 1% 1% 1%' }}>
                    <div style={{ margin: "0px 1px", width: '16.65%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferFamily")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <Select options={[{ label: "------", value: undefined }]
                                .concat(_.values(this.props.families)
                                    .map(x => ({
                                        label: x.name,
                                        value: x.familyId
                                    })))}
                                value={this.props.filter.familyId}
                                onChange={(value) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    familyId: value
                                })}
                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 1px", width: '16.65%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferSubfamily")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <Select options={[{ label: "------", value: undefined }]
                                .concat(_.values(this.props.subFamilies)
                                    .filter(x => x.familyId === this.props.filter.familyId)
                                    .map(x => ({
                                        label: x.name,
                                        value: x.subFamilyId
                                    })))}
                                value={this.props.filter.subFamilyId}
                                onChange={(value) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    subFamilyId: value
                                })}
                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", width: 290 }} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 1px", width: '33.3%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferName")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <input type="text"
                                style={{ height: 40 }}
                                className="form-control input-sm"
                                value={this.props.filter.productName}
                                onChange={(e) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    productName: e.target.value
                                })} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 1px", width: '33.3%' }}>
                        <div style={Styles.filterLabelStyle}>{getText("OfferHavePromo")} :</div>
                        <div className="card" style={{ ...Styles.cardStyle, ...Styles.filterControlStyle }}>
                            <Select options={[
                                { label: "------", value: undefined },
                                { label: getText("OfferYes"), value: true },
                                { label: getText("OfferNo"), value: false }]}
                                value={this.props.filter.hasOffer}
                                onChange={(value) => this.props.updateOfferFilter({
                                    ...this.props.filter,
                                    hasOffer: value
                                })}
                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                        </div>
                    </div>
                </div>
                <div className="selectors borderSelectors">
                    <div style={{ display: 'flex', fontSize: 12, margin: '1% 1%' }}>
                        <div /*className="card"*/ style={{ textAlign: "center", minHeight: 80, width: '33.3%', margin: "0px 1px" }}>
                        </div>
                        <div className="card" style={{ ...Styles.cardStyle, textAlign: "center", minHeight: 80, width: '33.3%', margin: "0px 1px" }}>
                            <div style={{ padding: '10px 2px' }}>
                                <div style={{ marginTop: '1%' }}>
                                    <div style={{ display: "flex", alignItems: 'center', marginBottom: "1%" }}>
                                        <div style={Styles.filterLabelStyle}>{getText("OfferDLC")}</div>
                                        <div className="col-md-6" style={{ width: '-webkit-fill-available', padding: 0 }}>
                                            <button
                                                className="btn btn-primary"
                                                style={{ fontSize: 12, fontWeight: 'bold', width: 150, height: 40, float: 'right' }}
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                onClick={(e) => {
                                                    this.props.products.forEach(product => {
                                                        if (product.expirityType === "None" || product.expirityType !== "Normal")
                                                            return;
                                                        let itemOffer = this.props.itemOfferResult
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];
                                                        if (itemOffer) {
                                                            let days = this.props.DLCDays && this.props.DLCDays > 0 ? this.props.DLCDays : undefined;
                                                            let experityDefaultPriceNameId = this.props.experityDefaultPriceNameId
                                                                && product.prices.some(y => y.priceNameId == this.props.experityDefaultPriceNameId) ?
                                                                this.props.experityDefaultPriceNameId : undefined;
                                                            //changeDLC
                                                            if (days) {
                                                                this.props.updateItemExpirityOffer(itemOffer.storeItemId, 0, days, itemOffer.priceNameId);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                            if (experityDefaultPriceNameId) {
                                                                this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, experityDefaultPriceNameId);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                        }
                                                    });
                                                }}>
                                                {getText("OfferUpdateAll")}
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: 'center', marginTop: '1%' }}>
                                        <input type="checkbox"
                                            className="offer-checkbox"
                                            style={{ flex: "0 0 auto", margin: 0 }}
                                            disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                            onChange={(e) => {
                                                this.props.products.forEach(product => {
                                                    let itemOffer = this.props.itemOfferResult
                                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];
                                                    if (product.expirityType === "Normal") {
                                                        if (e.target.checked) {

                                                            if (itemOffer) {
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            } else {
                                                                (this.props.requestCreateItemExpirity(new Date().getTime(), product.storeItemId, 0) as any as Promise<any>)
                                                                    .then(() => {
                                                                        itemOffer = this.props.itemOfferResult
                                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                                                                        if (itemOffer) {
                                                                            let days = this.props.DLCDays && this.props.DLCDays > 0 ? this.props.DLCDays : undefined;
                                                                            let experityDefaultPriceNameId = this.props.experityDefaultPriceNameId
                                                                                && product.prices.some(y => y.priceNameId == this.props.experityDefaultPriceNameId) ?
                                                                                this.props.experityDefaultPriceNameId : undefined;
                                                                            //changeDLC
                                                                            if (days) {
                                                                                this.props.updateItemExpirityOffer(itemOffer.storeItemId, 0, days, itemOffer.priceNameId);
                                                                                this.props.requestUpdateItemExpirity(
                                                                                    new Date().getTime(),
                                                                                    product.storeItemId,
                                                                                    0);
                                                                            }
                                                                            if (experityDefaultPriceNameId) {
                                                                                this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, experityDefaultPriceNameId);
                                                                                this.props.requestUpdateItemExpirity(
                                                                                    new Date().getTime(),
                                                                                    product.storeItemId,
                                                                                    0);
                                                                            }
                                                                        }
                                                                    });
                                                            }
                                                        } else {
                                                            if (itemOffer) {
                                                                this.props.requestDeleteItemOffer(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            } else {
                                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                                            }
                                                        }
                                                    }
                                                });
                                            }} />
                                        <div style={{ flex: "0 0 auto", padding: "0px 10px" }}>
                                            <InputNumber
                                                style={{ width: 50, height: 40 }}
                                                value={this.props.DLCDays}
                                                className="form-control input-sm"
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                onChange={(newValue) => {
                                                    this.props.updateDLCDays(newValue);
                                                }} />
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            <Select options={[{ label: "------", value: undefined }]
                                                .concat(_.values(this.props.priceNames)
                                                    .map(x => ({
                                                        label: x.name,
                                                        value: x.priceNameId
                                                    })))}
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                value={this.props.experityDefaultPriceNameId}
                                                onChange={(value) => this.props.updateExpirityDefaultPriceNameId(value)}
                                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ ...Styles.cardStyle, textAlign: "center", minHeight: 80, width: '33.33%', margin: "0px 1px" }}>
                            <div style={{ padding: '10px 2px' }}>
                                <div style={{ marginTop: '1%' }}>
                                    <div style={{ display: "flex", alignItems: 'center', marginBottom: "1%" }}>
                                        <div style={Styles.filterLabelStyle}>{getText("OfferDDM")}</div>
                                        <div className="col-md-6" style={{ width: '-webkit-fill-available', padding: 0 }}>
                                            <button
                                                className="btn btn-primary"
                                                style={{ fontSize: 12, fontWeight: 'bold', width: 150, height: 40, float: 'right' }}
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                onClick={(e) => {
                                                    this.props.products.forEach(product => {
                                                        if (product.expirityType !== "Ddm")
                                                            return;
                                                        let itemOffer = this.props.itemOfferResult
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];
                                                        if (itemOffer) {
                                                            let days = this.props.DDMDays && this.props.DDMDays > 0 ? this.props.DDMDays : undefined;
                                                            let experityDdmDefaultPriceNameId = this.props.experityDdmDefaultPriceNameId
                                                                && product.prices.some(y => y.priceNameId == this.props.experityDdmDefaultPriceNameId) ?
                                                                this.props.experityDdmDefaultPriceNameId : undefined;
                                                            //changeDDM
                                                            if (days) {
                                                                this.props.updateDaysBeforeExpirityItemOffer(product.storeItemId, 0, days);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                            if (experityDdmDefaultPriceNameId) {
                                                                this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, experityDdmDefaultPriceNameId);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                        }
                                                    });
                                                }}>
                                                {getText("OfferUpdateAll")}
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: 'center', marginTop: '1%' }}>
                                        <input type="checkbox"
                                            className="offer-checkbox"
                                            style={{ flex: "0 0 auto", margin: 0 }}
                                            disabled={this.props.disabled}
                                            onChange={(e) => {
                                                this.props.products.forEach(product => {
                                                    let itemOffer = this.props.itemOfferResult
                                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];
                                                    if (product.expirityType === "Ddm") {
                                                        if (e.target.checked) {

                                                            if (itemOffer) {
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            } else {
                                                                (this.props.requestCreateItemExpirity(new Date().getTime(), product.storeItemId, 0) as any as Promise<any>)
                                                                    .then(() => {
                                                                        itemOffer = this.props.itemOfferResult
                                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];
                                                                        if (itemOffer) {
                                                                            let days = this.props.DDMDays && this.props.DDMDays > 0 ? this.props.DDMDays : undefined;
                                                                            let experityDdmDefaultPriceNameId = this.props.experityDdmDefaultPriceNameId
                                                                                && product.prices.some(y => y.priceNameId == this.props.experityDdmDefaultPriceNameId) ?
                                                                                this.props.experityDdmDefaultPriceNameId : undefined;
                                                                            //changeDDM
                                                                            if (days) {
                                                                                this.props.updateDaysBeforeExpirityItemOffer(product.storeItemId, 0, days)
                                                                                this.props.requestUpdateItemExpirity(
                                                                                    new Date().getTime(),
                                                                                    product.storeItemId,
                                                                                    0);
                                                                            }
                                                                            if (experityDdmDefaultPriceNameId) {
                                                                                this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, experityDdmDefaultPriceNameId);
                                                                                this.props.requestUpdateItemExpirity(
                                                                                    new Date().getTime(),
                                                                                    product.storeItemId,
                                                                                    0);
                                                                            }
                                                                        }
                                                                    });
                                                            }

                                                        } else {
                                                            if (itemOffer) {
                                                                this.props.requestDeleteItemOffer(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            } else {
                                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                                            }
                                                        }
                                                    }
                                                });
                                            }} />
                                        <div style={{ flex: "1", padding: "0px 10px" }}>
                                            <Select options={[{ label: "------", value: undefined }]
                                                .concat(_.values(this.props.priceNames)
                                                    .map(x => ({
                                                        label: x.name,
                                                        value: x.priceNameId
                                                    })))}
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                value={this.props.experityDdmDefaultPriceNameId}
                                                onChange={(value) => this.props.updateExpirityDdmDefaultPriceNameId(value)}
                                                style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                                        </div>
                                        <div style={{ flex: "0 0 auto" }}>
                                            <InputNumber
                                                style={{ height: 40, width: 50, fontSize: 12 }}
                                                value={this.props.DDMDays}
                                                className="form-control input-sm"
                                                disabled={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading)) || this.props.disabled}
                                                onChange={(newValue) => {
                                                    this.props.updateDDMDays(newValue)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offer-table card selectors borderSelectors" style={{ ...Styles.cardStyle, margin: "0 1%", height: "80%" }}>
                    <Table tableKey={"offerPromotions"}
                        style={{ fontSize: 11, overflow: "hidden", height: '100%' }}
                        loading={_.values(this.props.itemOfferStates).some(y => _.values(y).some(z => z && z.isLoading))
                            || this.props.stock.isLoading
                            || this.props.isLoading}
                        columns={[
                            {
                                id: "name",
                                Header: <div style={{ lineHeight: '65px', width: '-webkit-fill-available' }}><strong>{getText("OfferName")}</strong></div>,
                                accessor: d => d.name,
                                style: {
                                    width: '-webkit-fill-available',
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1,
                                    textAlign: 'left',
                                    lineHeight: '31px'
                                },
                            }
                        ].concat([
                            {
                                id: "expirity",
                                Header:
                                    <div>
                                        <div style={{
                                            padding: '2%', height: 34,
                                            borderBottom: this.isDarkMode() ? '1px solid transparent' : '1px solid rgba(255, 255, 255, 0.16)'
                                        }}>
                                            <strong>{getText("OfferDLC")}</strong>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ margin: 'auto' }}>
                                                {getText("OfferDaysBefore")}
                                            </div>
                                            <div style={{ margin: 'auto' }}>
                                                {getText("OfferPromoRate")}
                                            </div>
                                        </div>
                                    </div>,
                                accessor: d => d,
                                style: {
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, height: "100%", overflow: "visible"
                                } as CSSProperties,
                                Cell: row => {
                                    let product = row.value as Api.ProductModel;
                                    let itemOffer = this.props.itemOfferResult
                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                                    if (product.expirityType === "None")
                                        return <div><h5><strong>{getText("OfferNoPromo")}</strong></h5></div>;
                                    if (product.expirityType !== "Normal")
                                        return <div></div>;

                                    return <ItemOffer currency={this.props.currency}
                                        itemOfferState={this.props.itemOfferStates[product.storeItemId]
                                            ? this.props.itemOfferStates[product.storeItemId][0]
                                            : undefined}
                                        disabled={this.props.disabled}
                                        priceNames={this.props.priceNames}
                                        prices={product.prices}
                                        defaultPriceNameId={this.props.experityDefaultPriceNameId}
                                        itemOffer={itemOffer ? {
                                            dateEnd: itemOffer.dateEnd,
                                            priceNameId: itemOffer.priceNameId,
                                            daysBeforeExpirity: itemOffer.daysBeforeExpirity
                                        } : undefined}
                                        hasDateEnd={false}
                                        isDLC={true}
                                        hasDaysBeforeExpirity={true}
                                        onValidate={() => {
                                            if (itemOffer) {
                                                this.props.requestUpdateItemExpirity(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    0);
                                            } else {
                                                (this.props.requestCreateItemExpirity(new Date().getTime(), product.storeItemId, 0) as any as Promise<any>)
                                                    .then(() => {
                                                        itemOffer = this.props.itemOfferResult
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                                                        if (itemOffer) {
                                                            let days = this.props.DLCDays && this.props.DLCDays > 0 ? this.props.DLCDays : undefined;
                                                            let experityDefaultPriceNameId = this.props.experityDefaultPriceNameId
                                                                && product.prices.some(y => y.priceNameId == this.props.experityDefaultPriceNameId) ?
                                                                this.props.experityDefaultPriceNameId : undefined;

                                                            //changeDLC
                                                            if (days) {
                                                                this.props.updateItemExpirityOffer(itemOffer.storeItemId, 0, days, itemOffer.priceNameId);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                            if (experityDefaultPriceNameId) {
                                                                this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, experityDefaultPriceNameId);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                        }
                                                    });

                                            }
                                        }}
                                        onDelete={() => {
                                            if (itemOffer) {
                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                                this.props.requestDeleteItemOffer(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    0);
                                            } else {
                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                            }
                                        }}
                                        updateDaysBeforeExpirity={(value) => this.props.updateDaysBeforeExpirityItemOffer(product.storeItemId, 0, value)}
                                        updateDateEnd={(value) => this.props.updateDateEndItemOffer(product.storeItemId, 0, value)}
                                        updateChecked={(value) => this.props.updateCheckedItemOffer(product.storeItemId, 0, value)}
                                        updatePriceNameId={(value) => this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, value)} />;
                                },
                            } as any,
                            {
                                id: "expirityDdm",
                                Header:
                                    <div>
                                        <div style={{
                                            padding: '2%', height: 34,
                                            borderBottom: this.isDarkMode() ? '1px solid transparent' : '1px solid rgba(255, 255, 255, 0.16)'
                                        }}>
                                            <strong>{getText("OfferDDM")}</strong>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ margin: 'auto' }}>
                                                {getText("OfferPromoRate")}
                                            </div>
                                            <div style={{ margin: 'auto' }}>
                                                {getText("OfferDaysAfter")}
                                            </div>
                                        </div>
                                    </div>,
                                accessor: d => d,
                                style: {
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, height: "100%", overflow: "visible"
                                } as CSSProperties,
                                Cell: row => {
                                    let product = row.value as Api.ProductModel;
                                    let itemOffer = this.props.itemOfferResult
                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                        && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                                    if (product.expirityType !== "Ddm")
                                        return <div></div>;

                                    return <ItemOffer currency={this.props.currency}
                                        itemOfferState={this.props.itemOfferStates[product.storeItemId]
                                            ? this.props.itemOfferStates[product.storeItemId][0]
                                            : undefined}
                                        disabled={this.props.disabled}
                                        priceNames={this.props.priceNames}
                                        prices={product.prices}
                                        defaultPriceNameId={this.props.experityDdmDefaultPriceNameId}
                                        itemOffer={itemOffer ? {
                                            dateEnd: itemOffer.dateEnd,
                                            priceNameId: itemOffer.priceNameId,
                                            daysBeforeExpirity: itemOffer.daysBeforeExpirity
                                        } : undefined}
                                        hasDateEnd={false}
                                        isDLC={false}
                                        hasDaysBeforeExpirity={true}
                                        onValidate={() => {
                                            if (itemOffer) {
                                                this.props.requestUpdateItemExpirity(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    0);
                                            } else {
                                                (this.props.requestCreateItemExpirity(new Date().getTime(), product.storeItemId, 0) as any as Promise<any>)
                                                    .then(() => {
                                                        itemOffer = this.props.itemOfferResult
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId]
                                                            && this.props.itemOfferResult.itemExpirityOffers[product.storeItemId][0];

                                                        if (itemOffer) {
                                                            if (this.props.DDMDays && this.props.DDMDays > 0) {
                                                                this.props.updateItemExpirityOffer(itemOffer.storeItemId, 0, this.props.DDMDays, itemOffer.priceNameId)
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                            let experityDdmDefaultPriceNameId = this.props.experityDdmDefaultPriceNameId &&
                                                                product.prices.some(y => y.priceNameId == this.props.experityDdmDefaultPriceNameId) ?
                                                                this.props.experityDdmDefaultPriceNameId : undefined;
                                                            if (experityDdmDefaultPriceNameId) {
                                                                this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, experityDdmDefaultPriceNameId);
                                                                this.props.requestUpdateItemExpirity(
                                                                    new Date().getTime(),
                                                                    product.storeItemId,
                                                                    0);
                                                            }
                                                        }
                                                    });
                                            }
                                        }}
                                        onDelete={() => {
                                            if (itemOffer) {
                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                                this.props.requestDeleteItemOffer(
                                                    new Date().getTime(),
                                                    product.storeItemId,
                                                    0);
                                            } else {
                                                this.props.resetItemOfferState(product.storeItemId, 0);
                                            }
                                        }}
                                        updateDaysBeforeExpirity={(value) => this.props.updateDaysBeforeExpirityItemOffer(product.storeItemId, 0, value)}
                                        updateDateEnd={(value) => this.props.updateDateEndItemOffer(product.storeItemId, 0, value)}
                                        updateChecked={(value) => this.props.updateCheckedItemOffer(product.storeItemId, 0, value)}
                                        updatePriceNameId={(value) => this.props.updatePriceNameIdItemOffer(product.storeItemId, 0, value)} />;
                                }
                            } as any
                        ]) as Array<OfferColumn>}
                        pageSize={Math.max(this.props.products.length, 12)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        data={this.props.products}>
                    </Table>
                </div>
                <DialogModal contentLabel={"Offer dialog"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                        width: 500, 
                        maxHeight: 400,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    title={getText( "OfferDialogTitle")}
                    isOpen={this.props.dialogOpenOffer.isOpen}
                    onRequestClose={() => this.props.closeDialogModalOffer()}>
                    <div>
                        <h4 style={{margin: "40 30", overflowWrap: 'break-word'}}>
                            {this.props.dialogOpenOffer.message}
                        </h4>
                        <button className="btn btn-secondary btn-sm"
                            type={"button"}
                            style={{
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.props.closeDialogModalOffer()}> {getText( "MaintenanceCorrect")} </button>
                    </div>
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.offer,
    stock: state.stock,
    currency: state.seed.seed.currencies[state.settings.storeSettings.currencyId],
    products: OfferStore.productExpirityOfferFilteredSelector(state),
    promotions: state.seed.seed.promotions,
    priceNames: state.seed.seed.priceNames,
    families: state.seed.seed.families,
    subFamilies: state.seed.seed.subFamilies,
    disabled: !canUpdate(state),
    currentUser: state.account.currentUser,
} as OfferOwnProps), {
    ...OfferStore.actionCreators,
    ...StockStore.actionCreators
})(Offer as any)