import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as HistoryStore from "../store/History";
import Table from "./Table";
import { getText } from '../utils/texts';
import {generateCsvFromTable, CsvData } from "../utils/csv";
import * as Styles from '../styles/baseStyle';
import Moment from 'moment';
import * as ReactTable from "react-table";
import { ExportToCsv } from 'export-to-csv';

interface HistoryTurnoverTotalV6Props {
    isLoading: boolean;
    turnoverV6: Array<Api.HistoCAModel>;
}
type HistoryTurnoverV6Column = ReactTable.Column
    & { accessor: (d: Api.HistoCAModel) => any; }

export default class HistoryTurnoverTotalV6 extends React.Component<HistoryTurnoverTotalV6Props, {}> {
    tableContainer: any = null;
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get csvData(): CsvData {
        if(!this.tableContainer)
            return {
                data: [],
                headers: []
            };
        
        return generateCsvFromTable(this.tableContainer);
    }

    get dataInfo() {
        let arr: any[] = [];
        this.props.turnoverV6 &&
            arr.push({
                cahtv1: `${(_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HTTVA1)))).toFixed(2)}` || "0",
                catvav1: `${(_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HTTVA1))) / 100 * 5.5).toFixed(2)}` || "0",
                cahtv2: `${(_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HTTVA2)))).toFixed(2)}` || "0",
                catvav2: `${(_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HTTVA2))) / 100 * 10).toFixed(2)}` || "0",
                cahtv3: `${(_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HTTVA3)))).toFixed(2)}` || "0",
                catvav3: `${(_.sum(_.map(this.props.turnoverV6, x => parseFloat(x.cA_HTTVA3))) / 100 * 20).toFixed(2)}` || "0",
            });
        return arr;
    }

    public render() {
        return (
            <div>
                { this.props.turnoverV6 &&
                    <div className="card" style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "20%"}}>
                    <div style={{ flex: "0 0 auto" , direction:"rtl", paddingRight: 12, paddingTop: 12}}>
                        <button type={"button"}
                            className="btn btn-primary"
                            style={{height: 40}}
                            onClick={(e) => {
                                const options = {
                                    fieldSeparator: ';',
                                    quoteStrings: '"',
                                    decimalSeparator: ',',
                                    showLabels: false,
                                    showTitle: false,
                                    filename: `${getText("HistoTitle")}-${getText("HistoTurnoverTotal")}V6-${Moment().format('YYYYMMDDHHmm')}`,
                                    useTextFile: false,
                                    useBom: true,
                                    useKeysAsHeaders: false,
                                    headers: this.csvData.headers,
                                };
                                const csvExporter = new ExportToCsv(options);
                                csvExporter.generateCsv(this.csvData.data);  
                            }}>
                                {getText( "HistoExport")}
                                <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                        </button>
                    </div>
                    <div ref={(r) => this.tableContainer = r}>
                        <Table 
                            style={{margin: 0}}
                            loading={this.props.isLoading}
                            defaultPageSize = {2} 
                            showPageSizeOptions={false}
                            showPagination={false}
                            showPaginationBottom={false}
                            columns={[
                                {
                                    Header: <div>{`${getText( "HistoCAHT")} 5.5%`}</div>,
                                    id: 'cahtv1',
                                    accessor: x => x.cahtv1
                                },
                                {
                                    Header: <div>{`${getText( "ProductsTVA")} 5.5%`}</div>,
                                    id: 'catvav1',
                                    accessor: x => x.catvav1
                                },
                                {
                                    Header: <div>{`${getText( "HistoCAHT")} 10%`}</div>,
                                    id: 'cahtv2',
                                    accessor: x => x.cahtv2
                                },
                                {
                                    Header: <div>{`${getText( "ProductsTVA")} 10%`}</div>,
                                    id: 'catvav2',
                                    accessor: x => x.catvav2
                                },
                                {
                                    Header: <div>{`${getText( "HistoCAHT")} 20%`}</div>,
                                    id: 'cahtv3',
                                    accessor: x => x.cahtv3
                                },
                                {
                                    Header: <div>{`${getText( "ProductsTVA")} 20%`}</div>,
                                    id: 'catvav3',
                                    accessor: x => x.catvav3
                                },
                            ] as Array<HistoryTurnoverV6Column>}
                            data={this.dataInfo} />
                    </div>
                </div>
                }
            </div>
        );
    }
}