import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { ApplicationState } from '../store/index';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

const locationHelper = locationHelperBuilder({})

export default connectedRouterRedirect({
    // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
    redirectPath: (state: ApplicationState, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/home',
    // This prevents us from adding the query parameter when we send the user away from the login page
    allowRedirectBack: false,
    // Determine if the user is authenticated or not
    authenticatedSelector: (state: ApplicationState) => !state.account.currentUser
        || !state.account.currentUser.expiration
        || new Date(state.account.currentUser.expiration) < new Date(),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsNotAuthenticated'
})