import * as React from "react";
import { Tabs } from "react-tabs-redux";
import * as TabsStore from "../store/Tabs";
import { ApplicationState } from "../store";
import { connect } from "react-redux";

type ConnectedTabsProps = TabsStore.TabsState
    & typeof TabsStore.actionCreators
    & ConnectedTabsOwnProps;

interface ConnectedTabsOwnProps {
    name: string;
    height?: any;
}

class ConnectedTabs extends React.Component<ConnectedTabsProps, {}> {
    public render() {
        return (<Tabs
            name={this.props.name}
            style={{ height: this.props.height ? this.props.height : "100%" }}
            selectedTab={this.props[this.props.name]
                ? this.props[this.props.name].selectedTab : undefined}
            handleSelect={(tabName, namespace) => this.props.selectTab(namespace, tabName)}
            activeLinkStyle={{ position: "relative", top: 1 }}>
            {this.props.children}
        </Tabs>);
    }
}

export default connect((state: ApplicationState) => state.tabs,
    TabsStore.actionCreators)(ConnectedTabs as any) as any as React.ComponentClass<ConnectedTabsOwnProps>;