import * as React from "react";
import * as _ from 'lodash';
import Moment from 'moment';
import * as Api from '../api/api';
import * as VoucherStore from '../store/Voucher';
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { CreateVouchersForm } from "./VouchersForm";
import VoucherCampaignForm from "./VoucherCampaignForm";
import DialogModal from "./DialogModal";
import Table from "./Table";
import Select from "./Select";
import * as ReactTable from "react-table";
import * as Styles from '../styles/baseStyle';
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import Confirm from "react-confirm-bootstrap";
import { canEditSettings } from "../security/roles";
import { ExportToCsv } from 'export-to-csv';
import {generateCsvFromTable, CsvData } from "../utils/csv";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type VoucherCurrentProps = VoucherCurrentOwnProps
    & VoucherStore.VoucherState
    & typeof VoucherStore.actionCreators;

interface VoucherCurrentOwnProps {
    currentUser: Api.CurrentUserManagerModel;
}

class VoucherCurrent extends React.Component<VoucherCurrentProps, {}> {

    componentDidMount() {
        this.props.requestVouchers(new Date().getTime());
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    csvData(): CsvData {
        if(!document.getElementById("vouchers-container"))
            return {
                data: [],
                headers: []
            };

        return generateCsvFromTable(document.getElementById("vouchers-container"));
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF('l');
        autoTable(pdf, {body: data});
        pdf.save(`${getText("VoucherTitle")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    public render() {
        return (
            <div>
                <div className="row" style={{margin: '1% 0'}}>
                    <div className="col-md-2">
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 15}}
                            disabled={!this.props.result.voucherCampaigns
                                || (this.props.result.voucherCampaigns[this.props.voucherCampaignId]
                                    && this.props.result.voucherCampaigns[this.props.voucherCampaignId].type === "Automatic")}
                            onClick={(e) => this.props.openCreateVouchers()}>
                            {getText( "VoucherNew")} <i className="glyphicon glyphicon-pencil"></i>
                        </button>
                    </div>
                    <div className="col-md-4">
                        <div className="col-md-8 col-sm-7 selectors" style={{ paddingRight: 5 }}>
                            <div className="card" style={{ ...Styles.cardStyle }}>
                                <Select options={[{
                                        label: "------",
                                        value: undefined
                                    }].concat(this.props.result
                                        ? _.values(this.props.result.voucherCampaigns)
                                            .map(x => ({ label: x.name, value: x.voucherCampaignId }))
                                        : [])}
                                        value={this.props.voucherCampaignId}
                                        onChange={(value) => this.props.updateVoucherCampaignId(value)}
                                        style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", fontSize: 15}}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-5" style={{ paddingLeft: 5 }}>
                            <Confirm
                                style={{ pointerEvents: (this.props.voucherCampaignId == undefined) ? "none" : "initial", 
                                    opacity: (this.props.voucherCampaignId == undefined) ? 0.7 : 1, color: "#FFFFFF",
                                    height: 40 }}
                                onConfirm={() => {
                                    this.props.requestDeleteVouchers(new Date().getTime(), this.props.voucherCampaignId);
                                }}
                                body={getText("VouchersDeleteDialog")}
                                confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}>
                                    <button style={{height: 40, fontWeight: 'bold'}}
                                        className="btn btn-primary"
                                        disabled={this.props.voucherCampaignId == undefined}>
                                            {getText("VouchersDelete")} <i className="glyphicon glyphicon-trash icon-dm"/>
                                    </button>
                            </Confirm>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                    {/*<div className="col-md-2">
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 15}}
                            disabled={!this.props.result.voucherCampaigns
                                || (this.props.result.voucherCampaigns[this.props.voucherCampaignId]
                                    && this.props.result.voucherCampaigns[this.props.voucherCampaignId].type === "Automatic")}
                            onClick={(e) => this.props.openCreateVouchers()}>
                            {getText( "VoucherAdd")}
                        </button>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 15}}
                            onClick={(e) => this.props.openCreateVoucherCampaign()}>
                            {getText( "VoucherNew")} <i className="glyphicon glyphicon-pencil"></i>
                        </button>
                    </div> */}
                    <div className="col-md-2" style={{textAlign: "end"}}>
                        <button type={"button"}
                                className="btn btn-primary"
                                style={{height: 40, padding: "0px 18px"}}
                        onClick={(e) => {
                            const options = {
                                fieldSeparator: ';',
                                quoteStrings: '"',
                                decimalSeparator: ',',
                                showLabels: false,
                                showTitle: false,
                                filename: `${getText("VoucherTitle")}-${Moment().format('YYYYMMDDHHmm')}`,
                                useTextFile: false,
                                useBom: true,
                                useKeysAsHeaders: false,
                                headers: this.csvData().headers,
                            };
                            const csvExporter = new ExportToCsv(options);
                            csvExporter.generateCsv(this.csvData().data);  
                            this.pdfData(this.csvData().data);
                        }}>
                            {getText( "HistoExport")}
                            <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                        </button>
                    </div>
                </div>
                <div id={"vouchers-container"} className="card" style={{ ...Styles.cardStyle, margin: '1% 1% 0'}}>
                <Table
                    className="tableHeightReductionActual"
                    style={{margin: 0}}
                    data={(this.props.result && this.props.result.vouchers)
                    ? this.props.result.vouchers
                        .filter(x => !this.props.voucherCampaignId
                            || x.voucherCampaignId === this.props.voucherCampaignId)
                    : []}
                    pageSize={Math.max((this.props.result && this.props.result.vouchers)
                        ? this.props.result.vouchers
                            .filter(x => !this.props.voucherCampaignId
                                || x.voucherCampaignId === this.props.voucherCampaignId).length : 0,20)}
                    columns={[{
                        Header: getText( "VoucherCampaign"),
                        id: "VoucherCampaignId",
                        accessor: d => <div style={{lineHeight: '31px'}}>{this.props.result.voucherCampaigns[d.voucherCampaignId].name}</div>
                    },
                    {
                        Header: getText( "VoucherCode"),
                        id: "code",
                        accessor: d => <div style={{lineHeight: '31px'}}>{d.code}</div>
                    },
                    {
                        Header: getText( "VoucherAmount"),
                        id: "amount",
                        accessor: d => d,
                        Cell: row => {
                            let voucher = row.value as Api.VoucherModel;
                            return (<div style={{lineHeight: '31px'}}>
                                {voucher.amountType === "Flat" && voucher.amount}
                                {voucher.amountType === "Percentage" && voucher.amount + "%"}
                            </div>);
                        }
                    },
                    {
                        Header: getText( "VoucherMiniBasket"),
                        id: "minOrderAmount",
                        accessor: d => <div style={{lineHeight: '31px'}}>{d.minOrderAmount}</div>
                    },
                    {
                        Header: getText( "VoucherMaxUsed"),
                        id: "maxUse",
                        accessor: d => <div style={{lineHeight: '31px'}}>{d.maxUse}</div>
                    },
                    {
                        Header: getText( "VoucherUsed"),
                        id: "voucherOrders",
                        accessor: d => <div style={{lineHeight: '31px'}}>{d.voucherOrders.length}</div>
                    },
                    {
                        Header: getText( "VoucherStart"),
                        id: "startDate",
                        accessor: d => d.startDate,
                        Cell: row => <div style={{lineHeight: '31px'}}>{Moment(row.value).format("DD/MM/YYYY")}</div>
                    },
                    {
                        Header: getText( "VoucherEnd"),
                        id: "endDate",
                        accessor: d => d.endDate,
                        Cell: row => <div style={{lineHeight: '31px'}}>{Moment(row.value).format("DD/MM/YYYY")}</div>
                    },
                    {
                        id: "actions",
                        Header: "Actions",
                        accessor: d => d.voucherId,
                        Cell: row => <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        cursor: "pointer",
                                        borderRadius: 5
                                        }}>
                                            <Confirm
                                                style={{ pointerEvents: "initial", 
                                                    opacity: 1, color: "#FFFFFF", 
                                                    marginTop: '15%',
                                                    marginRight: 1,  }}
                                                onConfirm={() => {
                                                    this.props.requestDeleteVoucher(new Date().getTime(), row.value);
                                                }}
                                                body={getText( "VoucherDeleteDialog")}
                                                confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}>
                                                    <i className="glyphicon glyphicon-trash icon-dm"/>
                                            </Confirm>
                                    </div>
                                </div>
                    }] as Array<ReactTable.Column & { accessor: (d: Api.VoucherModel) => any }>}
                    showPageSizeOptions={false}
                    showPagination={false}
                    showPaginationBottom={false} />
                </div>
                <DialogModal isOpen={this.props.createVouchers.isOpen}
                    contentLabel={"createVouchers"}
                    contentStyle={{
                        width: "40%", 
                        padding: 10,
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)"
                    }}
                    title={getText("VoucherModalCreateTitle")}
                    onRequestClose={() => this.props.closeCreateVouchers()}>
                    <VoucherCampaignForm
                        onSubmit={(values) => this.props.requestCreateVoucherCampaign(new Date().getTime(), values) as any}
                        text={getText( "VoucherModalCreate")} />
                    <div style={{fontSize: 22, textAlign: 'center', width: '100%', paddingTop: '1%'}}>{getText( "VoucherAdd")}</div>
                    <CreateVouchersForm
                        onSubmit={(values) => this.props.requestCreateVouchers(new Date().getTime(), values) as any}
                        updateCreateVouchersMode={(value) => this.props.updateCreateVoucherMode(value)} />
                </DialogModal>
                {/*<DialogModal isOpen={this.props.createVoucherCampaign.isOpen}
                    contentStyle={{
                        width: "35%", 
                        padding: 10,
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);"
                    }}
                    contentLabel={"createVoucherCampaign"}
                    onRequestClose={() => this.props.closeCreateVoucherCampaign()}>
                    <VoucherCampaignForm
                        onSubmit={(values) => this.props.requestCreateVoucherCampaign(new Date().getTime(), values) as any}
                        text={getText( "VoucherModalCreate")} />
                </DialogModal>*/}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.voucher
}), VoucherStore.actionCreators)(VoucherCurrent as any);