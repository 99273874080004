import * as Api from '../api/api';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import RemoteCollectionDialog from './RemoteCollectionDialog';

interface HistoryRemoteCollectionProps {
    isLoading: boolean;
    seed: Api.SeedMngModel;
    selectedIndex: number;
    dayStoreDatas: Array<Api.DayStoreDataModel>;
    remoteCollections: Array<HistoryStore.RemoteCollectionWithData>;
    updateSelectedIndex: (value: number) => void;
    openViewRemoteCollection: (remoteCollection: Api.StoreRemoteCollectionModel, dataRemoteC: HistoryStore.RemoteCollectionData) => void;
}

type NeoEventColumn = ReactTable.Column
    & {
        accessor: (d: HistoryStore.RemoteCollectionWithData) => any;
        style?: React.CSSProperties;
    };
const rowStyle: React.CSSProperties = { display: 'flex', justifyContent: 'space-between', paddingBottom: '1%' };

export default class HistoryRemoteCollection extends React.Component<HistoryRemoteCollectionProps, {}> {

    get selectedDayStoreData(): Api.DayStoreDataModel {
        return this.props.dayStoreDatas[this.props.selectedIndex];
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        const idSet = new Set();
        const idLabel = new Set();
        let filteredData = this.props.remoteCollections
            ? this.props.remoteCollections
                .slice()
                .reverse()
                .filter((row) => {
                    const id = row.data.remissions.length > 0 ? row.data.remissions[0].id : '';
                    const label = row.remoteCollection.label;

                    const isIdDuplicate = idSet.has(id);
                    const isLabelDuplicate = idLabel.has(label);

                    if (!isIdDuplicate) {
                        idSet.add(id);
                    }

                    if (!isLabelDuplicate) {
                        idLabel.add(label);
                    }

                    return !isIdDuplicate && !isLabelDuplicate;
                })
            : [];

        return (
            <div style={{ ...rowStyle }}>
                <div className="heightHisto" style={{ marginTop: 2, paddingLeft: '1%', overflow: 'auto', width: '20%' }}>
                    {this.props.dayStoreDatas.map((dayStoreData, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: xi === this.props.selectedIndex
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.updateSelectedIndex(xi)}>
                        {Moment(dayStoreData.dayDate).locale(getCurrentLang()).format("dddd DD / MM / YYYY")}
                    </div>)}
                </div>
                <div style={{ paddingLeft: '1%', width: '100%' }}>
                    <Table className="heightHisto"
                        style={{ margin: 0 }}
                        key={"historyRemoteCollection"}
                        loading={this.props.isLoading}
                        pageSize={this.props.remoteCollections
                            && Math.max(this.props.remoteCollections.length, 20)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        columns={[
                            {
                                id: "actions",
                                maxWidth: 80,
                                accessor: (d) => d,
                                filterable: false,
                                Header: <div>{getText("HistoRemoteCollectionAction")}</div>,
                                style: {
                                    height: "100%"
                                },
                                Cell: row => <div
                                    className="icon-container-dm"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center",
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5,
                                    }}>
                                    <i className="glyphicon glyphicon-search icon-dm"
                                        style={{ margin: "11% 0", cursor: "pointer", color: "#FFFFFF" }}
                                        onClick={(e) => {
                                            let remoteCollection = row.value.remoteCollection as Api.StoreRemoteCollectionModel;
                                            let dataRemoteC = row.value.data as HistoryStore.RemoteCollectionData;
                                            this.props.openViewRemoteCollection(remoteCollection, dataRemoteC);
                                        }} />
                                </div>
                            },
                            {
                                Header: <div>{getText("HistoDate")}</div>,
                                id: 'creationDate',
                                accessor: x => x.data.date + x.data.time,

                                Cell: row => <div>{row.value ? Moment(row.value, "DDMMYYHHmmss").format("DD/MM/YYYY HH:mm:ss") : ""}</div>
                                //accessor: x => x.remoteCollection.creationDate,
                                //Cell: row => <div>{Moment(row.value).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY HH:mm:ss")}</div>,
                            },
                            {
                                Header: <div>{getText("HistoLabel")}</div>,
                                id: 'label',
                                accessor: x => x.remoteCollection.label,
                            },
                            {
                                Header: <div>{getText("HistoRemoteCollectionReply")}</div>,
                                id: 'reply',
                                accessor: x => x.data.responseLabel,
                            },
                            {
                                Header: <div>{getText("HistoRemoteCollectionSequence")}</div>,
                                id: 'sequence',
                                accessor: x => x.data.remissions.length > 0 ? x.data.remissions[0].acquirerNumber : ""
                            },
                            {
                                Header: <div>{getText("HistoRemoteCollectionDelivery")}</div>,
                                id: 'delivery',
                                accessor: x => x.data.remissions.length > 0 ? x.data.remissions[0].id : "",
                            },
                            {
                                Header: <div>{getText("HistoRemoteCollectionTransaction")}</div>,
                                id: 'transaction',
                                accessor: x => x.data.remissions.length,
                            },
                            {
                                Header: <div>{getText("HistoRemoteCollectionTransactionTotal")}</div>,
                                id: 'transactionTotal',
                                accessor: x => x.data.remissions.length > 0 ? x.data.remissions[0].amountTotal : "",
                            },
                            {
                                Header: <div>{getText("HistoRemoteCollectionTransactionName")}</div>,
                                id: 'transactionName',
                                accessor: x => x.data.remissions.length > 0 ? x.data.remissions[0].nbFailedTransaction : "",
                            },
                            {
                                Header: <div>{getText("HistoModule")}</div>,
                                id: 'collectionModule',
                                accessor: x => x.remoteCollection.collectionModule,
                            },
                            {
                                Header: <div>{getText("HistoState")}</div>,
                                id: 'collectionStatus',
                                accessor: x => x.remoteCollection.collectionStatus,
                            }
                        ] as Array<NeoEventColumn>}
                        data={filteredData}
                    />
                </div>
                <RemoteCollectionDialog />
            </div>
        );
    }
}