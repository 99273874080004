import * as Api from '../api/api';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import PrintTest from "./PrintTest";
import CashTest from "./CashTest";
import CardTest from "./CardTest";
import EventViewer from "./EventViewer";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';

type MoneyTestProps = MoneyTestOwnProps;

interface MoneyTestOwnProps {
    neoEvents: Array<Api.NeoEventModel>;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
}

const relevantCode = [142, 800, 801];

interface MoneyTestState {
}

class MoneyTest extends React.Component<MoneyTestProps, MoneyTestState> {
    constructor(props: MoneyTestProps) {
        super(props);
    }

    public render() {
        return (
            <div style={{height: "100%"}}>
                <div className="card row" style={{...Styles.cardStyle}}>
                    <div style={{fontWeight: "bold", fontSize: 22, padding: '0.5rem 0 0  1%'}}>
                        {getText( "MaintenancePrinter")}
                    </div>
                    <div className="col-md-12" style={{padding: 0, height: "35vh"}}>
                        <EventViewer                        
                            tableKey={"printEvents"}
                            neoEventCodes={this.props.neoEventCodes}
                            events={this.props.neoEvents
                                .filter(x => relevantCode.some(y => this.props.neoEventCodes[x.neoEventCodeId].code === y))} />
                    </div>
                </div>
                <div className="row" style={{marginTop: '1%'}}>
                    <div className="card col-md-4" style={{...Styles.cardStyle, padding: '0.5rem 1%'}}>
                        <PrintTest />
                    </div>
                    <div className="col-md-8" style={{paddingRight: 0}}>
                        <CashTest />
                        <CardTest />
                    </div>
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    neoEvents: state.home.neoEvents.neoEvents,
    neoEventCodes: state.seed.seed.neoEventCodes,
} as MoneyTestOwnProps), {})(MoneyTest);