import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import { ProductPriceCalculator } from '../utils/priceUtils';
import * as ReorderStore from "../store/Reorder";

interface HistoryProductStockProps {
    isLoading: boolean;
    selectedIndex: number;
    dayStoreDatas: Array<Api.DayStoreDataModel>;
    updateSelectedIndex: (value: number) => void;
    seed: Api.SeedMngModel;
    productStockDatas: Array<HistoryStore.ProductStockData>;
}

type ProductSellColumn = ReactTable.Column
    & {
        accessor: (d: HistoryStore.ProductStockData) => any;
        style?: React.CSSProperties;
        columns?: Array<ProductSellColumn>
    };

const numberWidth: number = 70;
const rowStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', paddingBottom: '1%'};

export default class HistoryProductStock extends React.Component<HistoryProductStockProps, {}> {

    get selectedDayStoreData(): Api.DayStoreDataModel {
        return this.props.dayStoreDatas[this.props.selectedIndex];
    }

    priceCalculator: ProductPriceCalculator = new ProductPriceCalculator(
        this.props.seed.units,
        this.props.seed.vats,
        this.props.seed.suppliers,
        this.props.seed.currencies);

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    product(productId): Api.ProductModel {
        return this.props.seed.products[productId];
    }

    public render() {
        return (
            <div style={{...rowStyle}}>
                {<div className="heightHisto" style={{marginTop: 2, paddingLeft: '1%', overflow: 'auto', width: '20%'}}>
                    {this.props.dayStoreDatas.map((dayStoreData, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: xi === this.props.selectedIndex
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.updateSelectedIndex(xi)}>
                        {Moment(dayStoreData.dayDate).locale(getCurrentLang()).format("dddd DD / MM / YYYY")}
                    </div>)}
                </div>}
                <div style={{paddingLeft: '1%', width: '100%'}}>
                    <Table className="heightHisto"
                        style={{margin: 0}}
                        key={"historyProductStock"}
                        loading={this.props.isLoading}
                        pageSize={Math.max(this.props.productStockDatas.length, 20)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        columns={[
                            {
                                Header: <div>{getText( "HistoWord")}</div>,
                                style: {textAlign: 'left'},
                                id: 'productId',
                                accessor: x => this.props.seed.products[x.productId].name,
                                minWidth: 150,
                            },
                            {
                                Header: <div>{getText( "HistoInStock")}</div>,
                                id: 'stock',
                                accessor: x => x.stock,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productStockDatas, x => x.stock)) || 0}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText( "HistoInBackStock")}</div>,
                                id: 'storedStock',
                                accessor: x => x.storedStock,
                                Footer: (
                                    <span><strong>{_.sum(_.map(this.props.productStockDatas, x => x.storedStock)) || 0}</strong></span>
                                )
                            },
                            {
                                Header: <div>{getText("HistoStockTotalNoVat")}</div>,
                                id: 'totalNoVat',
                                accessor: x => x,
                                Cell: row => {
                                    let productId = row.value.productId;
                                    let inStock = row.value.stock + row.value.storedStock;
                                    let productSupplier = ReorderStore.getProductSupplier(productId, this.props.seed.products)
                                    let price = _.sortBy(this.product(productId).prices,
                                        x => (0) + (x.priceNameId !== this.product(productId).defaultPriceNameId ? 2 : 1))[0];
                                    let priceInfo = this.priceCalculator.calculate(
                                        this.product(productId) as any,
                                        price,
                                        productSupplier);
                                    let noVatUnitBuyPrice = priceInfo.noVatUnitBuyPrice;
                                    return (<div>
                                        {(inStock * noVatUnitBuyPrice).toFixed(2)} {this.props.seed.currencies[price.currencyId].symbol}
                                    </div>);
                                },
                                Footer: (f => {
                                    return <span><strong>{getText( "HistoTotalHT")}: {
                                        _.sum(_.map(this.props.productStockDatas, 
                                            x => x && Math.max(
                                                ((x.stock + x.storedStock ) * 
                                                    this.priceCalculator.calculate(
                                                        this.product(x.productId) as any,
                                                        _.sortBy(this.product(x.productId).prices,
                                                            y => (0) + (y.priceNameId !== this.product(x.productId).defaultPriceNameId ? 2 : 1))[0],
                                                        ReorderStore.getProductSupplier(x.productId, this.props.seed.products)).noVatUnitBuyPrice),
                                                0) || 0
                                        )).toFixed(2)  + " \u20AC" || 0  + " \u20AC"}</strong></span>
                                    })
                            },
                        ] as Array<ProductSellColumn>}
                        data={this.props.productStockDatas
                            ? this.props.productStockDatas.sort((a, b) => this.props.seed.products[a.productId].name.localeCompare(this.props.seed.products[b.productId].name))
                            : []
                        } />
                </div>
            </div>
        );
    }
}