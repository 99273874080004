import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as HelpStore from '../store/Help';
import { getText } from '../utils/texts';

type PDFReaderProps = PDFReaderOwnProps
    & PDFReaderExternalProps
    & HelpStore.HelpState
    & typeof HelpStore.actionCreators;;

interface PDFReaderOwnProps {
}

interface PDFReaderExternalProps {
    containerStyle?: React.CSSProperties;
    readerStyle?: React.CSSProperties;
    showToolBar?: boolean;
    name: string;
}

class PDFReader extends React.Component<PDFReaderProps, {}> {

    get pageFiles(){
        return this.props.helpUrls ? this.props.helpUrls.find(x => x.Page === this.props.name)?.Files || null : null;
    } 

    public render() {
        return (<div style={{display: "flex"}}>
            <div style={{
                    width: '20%',
                    height: "77vh",
                    overflow: "auto",
                    ...this.props.containerStyle
                }}>
                    <h1 style={{fontWeight: "bold"}}>{getText("HelpTitle")}</h1>
                   {this.pageFiles != null ? this.pageFiles.map((link, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: link.Name === this.props.currentUrl.Name
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.setCurrentUrl(link)}>
                        {link.Text}
                    </div>)
                    : getText("HelpLinksNoFound")}
            </div>
            <div className='helpPdf' style={{
                width: "80%",
                height: "78vh",
                marginTop: 5,
                ...this.props.containerStyle
            }}>
                {this.props.currentUrl && this.props.currentUrl.Url &&
                    <iframe src={this.props.currentUrl.Url} width="640" height="480" allow="autoplay"></iframe>
                }
            </div>
        </div>);
    }
}

export default connect((state: ApplicationState) => ({...state.help}), HelpStore.actionCreators)(PDFReader as any) as any as React.ComponentClass<PDFReaderExternalProps>;
