import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { compose } from 'redux';
import { canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import Tooltip from "./Tooltip";
import Confirm from "react-confirm-bootstrap";

type RobotFormProps = ReduxForm.InjectedFormProps<Api.RobotModel, {}, {}>
    & RobotFormOwnProps
    & RobotFormExternalProps;

interface RobotFormExternalProps {
    robotId: number;
    onSubmit: (values: any) => any;
    calculateOffsets: () => void;
}

interface RobotFormOwnProps {
    disabled: boolean;
    shelvingPoints: Array<IOffsetPointsShelving>;
}

const getExtractorSensOptions = (): Array<{ label: string, value: Api.RobotExtractorModelSensEnum }> => [
    { label: getText( "SettingsRobotSensNormal"), value: "Normal" },
    { label: getText( "SettingsRobotSensInverted"), value: "Inverted" }
];

const colStyle: React.CSSProperties = {padding: '1% 2%'}
const inputStyle: React.CSSProperties = {minWidth: 92, maxWidth: 92, height: 32};
const contentStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', alignItems: 'center'};
const subContentStyle: React.CSSProperties = {display: 'flex', alignItems: 'center'};
const robotSettingsContainerStyle: React.CSSProperties = {maxHeight: 300, overflow: "scroll", paddingRight: "2%"};
class RobotForm extends React.Component<RobotFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card" style={{...Styles.cardStyle, ...colStyle}}>
                                    <div style={{fontSize: 22, marginTop: "2%"}}>
                                        {getText( "SettingsDeliveryTimes")}
                                    </div>
                                    <div style={{...contentStyle, marginTop: '2%'}}>
                                        <div>{getText( "SettingsBaseTime")}</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.deliveryTimeBase"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>                                    
                                    <div style={{...contentStyle, marginTop: '2%'}}>
                                        <div>{getText( "SettingsDifferentTime")}</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.deliveryTimeDifferentProducts"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>                                    
                                    <div style={{...contentStyle, marginTop: '2%'}}>
                                        <div>{getText( "SettingsSameTime")}</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.deliveryTimeSameProducts"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '1%'}}>
                            <div className="col-md-12">
                                <div className="card" style={{...Styles.cardStyle, padding: '1% 1% 0 2%', overflow: 'scroll', height: '19vh'}}>
                                    <ReduxForm.FieldArray
                                        validate={[FormField.requiredOne]}
                                        name="deliveryStations"
                                        component={DeliveryStations}
                                        props={{
                                        } as DeliveryStationsOwnProps} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '1%'}}>
                            <div className="col-md-6">
                                <div className="card" style={{...Styles.cardStyle, padding: '3%'}}>
                                    <div style={{fontSize: 22}}>{getText( "SettingsRepos")}</div>
                                    <div style={subContentStyle}>
                                        <div style={{padding: "0 5%"}}>X</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.idleX"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{...subContentStyle, marginTop: '1%'}}>
                                        <div style={{padding: "0 5%"}}>Z</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.idleZ"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{paddingLeft: 0}}>
                                <div className="card" style={{...Styles.cardStyle, padding: '3%'}}>
                                    <div style={{fontSize: 22}}>{getText( "SettingsDelivery")}</div>
                                    <div style={subContentStyle}>
                                        <div style={{padding: "0 5%"}}>X</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.deliveryX"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{...subContentStyle, marginTop: '1%'}}>
                                        <div style={{padding: "0 5%"}}>Z</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.deliveryZ"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '1%'}}>
                            <div className="col-md-6">
                                <div className="card" style={{...Styles.cardStyle, padding: '3%'}}>
                                    <div style={{fontSize: 22}}>{getText( "SettingsMaintenance1")}</div>
                                    <div style={subContentStyle}>
                                        <div style={{padding: "0 5%"}}>X</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.maintenanceX1"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{...subContentStyle, marginTop: '1%'}}>
                                        <div style={{padding: "0 5%"}}>Z</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.maintenanceZ1"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{paddingLeft: 0}}>
                                <div className="card" style={{...Styles.cardStyle, padding: '3%'}}>
                                    <div style={{fontSize: 22}}>{getText( "SettingsMaintenance2")}</div>
                                    <div style={subContentStyle}>
                                        <div style={{padding: "0 5%"}}>X</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.maintenanceX2"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{...subContentStyle, marginTop: '1%'}}>
                                        <div style={{padding: "0 5%"}}>Z</div>
                                        <ReduxForm.Field
                                            name="robotConfiguration.maintenanceZ2"
                                            style={inputStyle}
                                            type="text"
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '1%'}}>
                            <div className="col-md-12">
                                <div className="card" style={{...Styles.cardStyle, ...colStyle}}>
                                    <div style={{fontSize: 22}}>{getText( "SettingsMW")}</div>
                                    <div style={{display: 'flex'}}>{/*}, justifyContent: 'space-between'}}>*/}
                                        <div style={{...subContentStyle, width: 400}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW270Message")}
                                                </div>}
                                                    placement={"top"}>
                                                    <div style={{padding: "0 5%"}}>{getText("SettingsMW270")}</div>
                                            </Tooltip>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW270Message2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div> 
                                                    <ReduxForm.Field
                                                        name="robotConfiguration.mW270"
                                                        style={inputStyle}
                                                        type="text"
                                                        validate={[FormField.required]}
                                                        component={FormField.NumberField} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div style={{...subContentStyle, width: 400}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW280Message")}
                                                </div>}
                                                    placement={"top"}> 
                                                    <div style={{padding: "0 5%"}}>{getText("SettingsMW280")}</div>
                                            </Tooltip>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW280Message2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div> 
                                                <ReduxForm.Field
                                                    name="robotConfiguration.mW280"
                                                    style={inputStyle}
                                                    type="text"
                                                    validate={[FormField.required]}
                                                    component={FormField.NumberField} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', /*justifyContent: 'space-between',*/ marginTop: '1%'}}>
                                        <div style={{...subContentStyle, width: 400}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW272Message")}
                                                </div>}
                                                    placement={"top"}> 
                                                    <div style={{padding: "0 5%"}}>{getText("SettingsMW272")}</div>
                                            </Tooltip>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW272Message2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div> 
                                                <ReduxForm.Field
                                                    name="robotConfiguration.mW272"
                                                    style={inputStyle}
                                                    type="text"
                                                    validate={[FormField.required]}
                                                    component={FormField.NumberField} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div style={{...subContentStyle, width: 400}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW282Message")}
                                                </div>}
                                                    placement={"top"}> 
                                                    <div style={{padding: "0 5%"}}>{getText("SettingsMW282")}</div>
                                            </Tooltip>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW282Message2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div> 
                                                <ReduxForm.Field
                                                    name="robotConfiguration.mW282"
                                                    style={inputStyle}
                                                    type="text"
                                                    validate={[FormField.required]}
                                                    component={FormField.NumberField} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', /*justifyContent: 'space-between',*/ marginTop: '1%'}}>
                                        <div style={{...subContentStyle, width: 400}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW274Message")}
                                                </div>}
                                                    placement={"top"}> 
                                                    <div style={{padding: "0 5%"}}>{getText("SettingsMW274")}</div>
                                            </Tooltip>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW274Message2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div> 
                                                <ReduxForm.Field
                                                    name="robotConfiguration.mW274"
                                                    style={inputStyle}
                                                    type="text"
                                                    validate={[FormField.required]}
                                                    component={FormField.NumberField} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div style={{...subContentStyle, width: 400}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW284Message")}
                                                </div>}
                                                    placement={"top"}> 
                                                    <div style={{padding: "0 5%"}}>{getText( "SettingsMW284")}</div>
                                            </Tooltip>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMW284Message2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div> 
                                                <ReduxForm.Field
                                                    name="robotConfiguration.mW284"
                                                    style={inputStyle}
                                                    type="text"
                                                    validate={[FormField.required]}
                                                    component={FormField.NumberField} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>                                    
                                    <div style={{display: 'flex', marginTop: '1%'}}>
                                        <div style={{...subContentStyle, width: 400}}>
                                        </div>
                                        <div style={{...subContentStyle, width: 400}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingLeft: 0}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card" style={{...Styles.cardStyle, padding: '1% 3%'}}>
                                    <div style={{fontSize: 22}}>
                                        {getText( "SettingsTitleConfig")}
                                    </div>
                                    <div style={robotSettingsContainerStyle}>
                                        <div style={contentStyle}>
                                            <div>{getText( "SettingsAccumulation")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.maxVolume"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <div>{getText( "SettingsInterShelving")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.shelveGap"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <div>{getText( "SettingsSpeed")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.railSpeed"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <div>{getText( "SettingsPercentageExtractor")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.extractionPerc"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMWLivraisonMessage")}
                                                </div>}
                                                    placement={"top"}>
                                                    <div>{getText("SettingsMWLivraison")/*{getText( "SettingsDoorDelay")}*/}</div>
                                            </Tooltip>                                             
                                            <Tooltip overlay={<div style={{maxWidth: 300}}>
                                                    {getText("SettingsMWLivraisonMessage2")}
                                                </div>}
                                                    placement={"top"}>
                                                <div>
                                                <ReduxForm.Field
                                                    name="robotConfiguration.reopenDoorDelay"
                                                    style={inputStyle}
                                                    type="text"
                                                    validate={[FormField.required]}
                                                    component={FormField.NumberField} /> 
                                                </div>                                              
                                            </Tooltip>
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <div>{getText( "SettingsRecoveryTime")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.collectionDelay"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <div>{getText( "SettingsDoorOpenDelay")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.doorOpeningTimeBase"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                        <div style={{...contentStyle, marginTop: '2%'}}>
                                            <div>{getText( "SettingsDoorPerProduct")}</div>
                                            <ReduxForm.Field
                                                name="robotConfiguration.doorOpeningTimePerProduct"
                                                style={inputStyle}
                                                type="text"
                                                validate={[FormField.required]}
                                                component={FormField.NumberField} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row borderSelectors">
                            <div className="col-md-12" style={{overflow: 'auto', height: '25vh', marginTop: 10}}>
                                <ReduxForm.FieldArray
                                    validate={[FormField.requiredOne]}
                                    name="robotExtractors"
                                    component={RobotExtractors}
                                    props={{
                                    } as RobotExtractorsOwnProps} />
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '2%'}}>
                            <div className="col-md-12" style={{display:'flex', flexDirection:'row-reverse'}}>
                                <Confirm
                                    style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                        opacity: this.props.disabled ? 0.7 : 1, color: "#FFFFFF", 
                                        marginRight: 1,  }}
                                    onConfirm={() => {
                                        this.props.calculateOffsets();
                                    }}
                                    body={getText("MaintenanceRobotBodyConfirm")}
                                    confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmChangeTitle")}>
                                        <div className="btn btn-primary"
                                            style={{width: '30%', margin:'10px'}}>
                                            {getText( "SettingsCalculatePoints")}
                                        </div>
                                </Confirm>
                            </div>
                        </div>
                        <div className="row borderSelectors">
                            <div className="col-md-12" style={{overflow: 'auto', height: '29vh'}}>
                                <ReduxForm.FieldArray
                                    validate={[]}
                                    name="shelvings"
                                    component={OffsetPoints}
                                    props={{
                                        points: this.props.shelvingPoints
                                    } as OffsetPointsOwnProps} />
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '2%'}}>
                            <div className="col-md-12">
                                <button className="btn btn-primary"
                                style={{width: '100%'}}
                                    type={"submit"}
                                    disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                                    {getText( "SettingsSave")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
            );
    }
}

type RobotExtractorsProps = RobotExtractorsOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.RobotExtractorModel>;
interface RobotExtractorsOwnProps {
}

class RobotExtractors extends React.Component<RobotExtractorsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => 
                    <div key={index} className="card" style={{...Styles.cardStyle, padding: '2%',marginTop: '2%' }}>
                        <div style={{fontSize: 22}}>
                            {getText( "SettingsExtractor")} ({index + 1})
                        </div>
                        <div style={contentStyle}>
                            <div>{getText( "SettingsPosition")}</div>
                            <ReduxForm.Field
                                name={`${member}.sens`}
                                style={{width: 200, height: 34}}
                                validate={[FormField.required]}
                                component={FormField.getSelectField(getExtractorSensOptions())} />
                        </div>
                        <div style={{...contentStyle, marginTop: '2%'}}>
                            <div>{getText( "SettingsX")}</div>
                            <ReduxForm.Field
                                name={`${member}.offsetX`}
                                style={{width: 200, height: 34}}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        <div  style={{...contentStyle, marginTop: '2%'}}>
                            <div>{getText( "SettingsZ")}</div>
                            <ReduxForm.Field
                                name={`${member}.offsetZ`}
                                style={{width: 200, height: 34}}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                    </div>)
                }
            </div>);
    }
}


export interface IOffsetPointsShelving { 
    shelvingId: number;
    code: string; 
    offsetX: number; 
    offsetZ: number;
    lane: number;
}

type OffsetPointsProps = OffsetPointsOwnProps
    & ReduxForm.WrappedFieldArrayProps<IOffsetPointsShelving>;
interface OffsetPointsOwnProps {
    points: Array<IOffsetPointsShelving>;
}

class OffsetPoints extends React.Component<OffsetPointsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => 
                this.props.points[index] != undefined &&
                    <div key={index} className="card" style={{...Styles.cardStyle, padding: '2%',marginTop: '2%' }}>
                        <div style={{fontSize: 22}}>
                            {`${getText("SettingsTitleShelving")}: ${this.props.points[index].code} - Ext(${this.props.points[index].lane})`}
                        </div>
                        <div style={{...contentStyle, marginTop: '2%'}}>
                            <div>{getText( "SettingsX")}</div>
                            <ReduxForm.Field
                                name={`${member}.offsetX`}
                                style={{width: 200, height: 34}}
                                validate={[FormField.requiredOrZero]}
                                component={FormField.NumberField} />
                        </div>
                        <div  style={{...contentStyle, marginTop: '2%'}}>
                            <div>{getText( "SettingsZ")}</div>
                            <ReduxForm.Field
                                name={`${member}.offsetZ`}
                                style={{width: 200, height: 34}}
                                validate={[FormField.requiredOrZero]}
                                component={FormField.NumberField} />
                        </div>
                    </div>)
                }
            </div>);
    }
}

type DeliveryStationsProps = DeliveryStationsOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.DeliveryStationModel>;
interface DeliveryStationsOwnProps {
}

class DeliveryStations extends React.Component<DeliveryStationsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => 
                    <div key={index} style={{padding: 0 }}>
                        <div style={{fontSize: 22}}>{getText( "SettingsTemp")}</div>
                        <div style={contentStyle}>
                            <div>{getText( "SettingsTemperatureWarning")}</div>
                            <ReduxForm.Field
                                name={`${member}.warnTemp`}
                                style={inputStyle}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        <div style={{...contentStyle, marginTop: '1%'}}>
                            <div>{getText( "SettingsTemperatureAlert")}</div>
                            <ReduxForm.Field
                                name={`${member}.alertTemp`}
                                style={inputStyle}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        {/*<div style={{...contentStyle}}>
                            <ReduxForm.Field
                                name={`${member}.bagDispenserEnabled`}
                                style={{width: 15, height: 15, fontSize: 8}}
                                validate={[FormField.required]}
                                label={getText( "SettingsBagDispenser")}
                                component={FormField.CheckBoxField} />
                        </div>
                        <div style={{...contentStyle}}>
                            <div>{getText( "SettingsSacVolume")}</div>
                            <ReduxForm.Field
                                name={`${member}.bagCbcm`}
                                style={inputStyle}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>*/}
                    </div>)
                }
            </div>);
    }
}

export default compose(connect((state: ApplicationState, props: RobotFormProps) => {
    let values = state.stock.stockStatus ? {
        ...state.robot.robotsState.robots.find(x => x.robotId === props.robotId), 
        shelvings: _.values(state.stock.stockStatus.shelvings)
                    .map(x => ({
                        shelvingId: x.shelvingId,
                        code: x.code,
                        offsetX: x.offsetX,
                        offsetZ: x.offsetZ,
                        lane: x.lane
                    } as IOffsetPointsShelving)),   
    } : {};
    return {
        form: "robot-" + props.robotId,
        initialValues: values,
        disabled: !canUpdate(state),
        shelvingPoints:  state.stock.stockStatus ? _.values(state.stock.stockStatus.shelvings).map(x => ({
            shelvingId: x.shelvingId,
            code: x.code,
            offsetX: x.offsetX,
            offsetZ: x.offsetZ,
            lane: x.lane
        } as IOffsetPointsShelving)) : {}
    } as RobotFormOwnProps;
}), ReduxForm.reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true
    }))(RobotForm as any) as any as React.ClassicComponentClass<RobotFormExternalProps>