import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as _ from 'lodash';

export interface PricePeriodState {
    isLoading: boolean;
    requestTime?: number;
    pricePeriods: { [id: number]: Api.PricePeriodModel };
    createState: {
        isLoading: boolean;
        requestTime?: number;
    },
    updatesState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    },      
    deletePricePeriod: {
        isLoading: boolean;
        requestTime?: number;
    },
    selectedPricePeriodId?: number;
    pricePeriodStoreItemsState: {
        [key: string]: {
            isPricePeriodStoreItemDialogOpen: boolean;
            familiesGrouped: Array<FamilyGroup>;
            subFamiliesGrouped: Array<SubFamilyGroup>;
        },
    },
}

export interface FamilyGroup {
    familyId: number;
    familyPriceNameId: number;
}

export interface SubFamilyGroup {
    subFamilyId: number;
    subFamilyPriceNameId: number;
}

interface RequestPricePeriods {
    type: "REQUEST_PRICEPERIODS";
    payload: { requestTime: number }
}
interface ReceivePricePeriods {
    type: "RECEIVE_PRICEPERIODS";
    payload: { requestTime: number; pricePeriods?: { [id: number]: Api.PricePeriodModel }; };
    error?: any;
}

interface RequestCreatePricePeriod {
    type: "REQUEST_CREATE_PRICEPERIOD";
    payload: { requestTime: number; }
}
interface ReceiveCreatePricePeriod {
    type: "RECEIVE_CREATE_PRICEPERIOD";
    payload: { requestTime: number; pricePeriod?: Api.PricePeriodModel; };
    error?: any;
}

interface RequestUpdatePricePeriod {
    type: "REQUEST_UPDATE_PRICEPERIOD";
    payload: { requestTime: number; id: number; }
}
interface ReceiveUpdatePricePeriod {
    type: "RECEIVE_UPDATE_PRICEPERIOD";
    payload: { requestTime: number; pricePeriod?: Api.PricePeriodModel; id: number; };
    error?: any;
}

interface SelectPricePeriod {
    type: "SELECT_PRICEPERIOD";
    payload: { id: number };
}

interface OpenPricePeriodStoreItemDialog {
    type: "OPEN_PRICEPERIODSTOREITEM_DIALOG";
    payload: { key: string };
}
interface ClosePricePeriodStoreItemDialog {
    type: "CLOSE_PRICEPERIODSTOREITEM_DIALOG";
    payload: { key: string };
}
interface RequestDeletePricePeriod {
    type: "REQUEST_DELETE_PRICEPERIOD";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeletePricePeriod {
    type: "RECEIVE_DELETE_PRICEPERIOD";
    payload: { requestTime: number; id: number; };
    error?: any;
}

interface UpdatePricePeriodFamiliesIds {
    type: "UPDATE_FAMILIES_IDS";
    payload: {  key: string, value: Array<FamilyGroup> };
}
interface UpdatePricePeriodSubFamiliesIds {
    type: "UPDATE_SUBFAMILIES_IDS";
    payload: {  key: string, value: Array<SubFamilyGroup> };
}

export type KnownAction = RequestPricePeriods
    | ReceivePricePeriods
    | RequestCreatePricePeriod
    | ReceiveCreatePricePeriod
    | RequestUpdatePricePeriod
    | ReceiveUpdatePricePeriod
    | SelectPricePeriod
    | OpenPricePeriodStoreItemDialog
    | ClosePricePeriodStoreItemDialog
    | RequestDeletePricePeriod
    | ReceiveDeletePricePeriod
    | UpdatePricePeriodFamiliesIds
    | UpdatePricePeriodSubFamiliesIds;

export const actionCreators = {
    requestPricePeriods: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PricePeriodApi();
        let fetchTask = api.getPricePeriods({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(pricePeriods => {
                dispatch({
                    type: "RECEIVE_PRICEPERIODS",
                    payload: {
                        pricePeriods: pricePeriods,
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_PRICEPERIODS",
                    payload: {
                        requestTime: requestTime
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_PRICEPERIODS",
            payload: {
                requestTime: requestTime
            }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreatePricePeriod: (requestTime: number, model: Api.PricePeriodModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PricePeriodApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(pricePeriod => {
                dispatch({
                    type: "RECEIVE_CREATE_PRICEPERIOD",
                    payload: { requestTime: requestTime, pricePeriod: pricePeriod }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_PRICEPERIOD",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CREATE_PRICEPERIOD",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdatePricePeriod: (requestTime: number, model: Api.PricePeriodModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PricePeriodApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(pricePeriod => {
                dispatch({
                    type: "RECEIVE_UPDATE_PRICEPERIOD",
                    payload: { requestTime: requestTime, pricePeriod: pricePeriod, id: model.pricePeriodId }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_PRICEPERIOD",
                    payload: { requestTime: requestTime, id: model.pricePeriodId },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_PRICEPERIOD",
            payload: { requestTime: requestTime, id: model.pricePeriodId }
        });
        return fetchTask;
    },
    selectPricePeriod: (id: number) => <SelectPricePeriod>{
        type: "SELECT_PRICEPERIOD",
        payload: { id: id }
    },
    openPricePeriodStoreItemDialog: (key: string) => <OpenPricePeriodStoreItemDialog>{
        type: "OPEN_PRICEPERIODSTOREITEM_DIALOG",
        payload: { key: key }
    },
    closePricePeriodStoreItemDialog: (key: string) => <ClosePricePeriodStoreItemDialog>{
        type: "CLOSE_PRICEPERIODSTOREITEM_DIALOG",
        payload: { key: key }
    },
    requestDeletePricePeriod: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PricePeriodApi();
        let fetch = api._delete({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_DELETE_PRICEPERIOD",
                    payload: {
                        requestTime: requestTime,
                        id: id
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_PRICEPERIOD",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_DELETE_PRICEPERIOD", payload: { requestTime: requestTime, id: id } });
        return fetch;
    },
    updateFamiliesIds: (key: string, value: Array<FamilyGroup>) => <UpdatePricePeriodFamiliesIds>{
        type: "UPDATE_FAMILIES_IDS",
        payload: { key: key, value: value }
    },
    updateSubFamiliesIds: (key: string, value: Array<SubFamilyGroup>) => <UpdatePricePeriodSubFamiliesIds>{
        type: "UPDATE_SUBFAMILIES_IDS",
        payload: {  key: key, value: value }
    },
}

const unloadedState: PricePeriodState = {
    isLoading: false,
    updatesState: {},
    createState: {
        isLoading: false
    },    
    deletePricePeriod: {
        isLoading: false
    },
    pricePeriods: [],
    pricePeriodStoreItemsState: {},
}

export const reducer: Reducer<PricePeriodState> = (state: PricePeriodState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_PRICEPERIODS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_PRICEPERIODS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                pricePeriods: action.error
                    ? state.pricePeriods
                    : action.payload.pricePeriods
            };
        case "REQUEST_UPDATE_PRICEPERIOD":
            return {
                ...state,
                updatesState: {
                    ...state.updatesState,
                    [action.payload.id]: {
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_UPDATE_PRICEPERIOD":
            if (!state.updatesState[action.payload.id]
                || state.updatesState[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                pricePeriods: action.error
                    ? state.pricePeriods
                    : {
                        ...state.pricePeriods,
                        [action.payload.pricePeriod.pricePeriodId]: action.payload.pricePeriod
                    },
                updatesState: {
                    ...state.updatesState,
                    [action.payload.id]: {
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_CREATE_PRICEPERIOD":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_PRICEPERIOD":
            if (action.payload.requestTime !== state.createState.requestTime)
                return state;

            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: false,
                },
                pricePeriods: action.error
                    ? state.pricePeriods
                    : {
                        ...state.pricePeriods,
                        [action.payload.pricePeriod.pricePeriodId]: action.payload.pricePeriod
                    }
            };
        case "SELECT_PRICEPERIOD":
            return {
                ...state,
                selectedPricePeriodId: action.payload.id
            };
        case "OPEN_PRICEPERIODSTOREITEM_DIALOG":
            return {
                ...state,
                pricePeriodStoreItemsState: {
                    ...state.pricePeriodStoreItemsState,
                    [action.payload.key]: {
                        ...state.pricePeriodStoreItemsState[action.payload.key],
                        isPricePeriodStoreItemDialogOpen: true
                    }
                }
            };
        case "CLOSE_PRICEPERIODSTOREITEM_DIALOG":
            return {
                ...state,
                pricePeriodStoreItemsState: {
                    ...state.pricePeriodStoreItemsState,
                    [action.payload.key]: {
                        ...state.pricePeriodStoreItemsState[action.payload.key],
                        isPricePeriodStoreItemDialogOpen: false
                    }
                }
            };
        case "REQUEST_DELETE_PRICEPERIOD":
            return {
                ...state,
                deletePricePeriod: {
                    ...state.deletePricePeriod,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DELETE_PRICEPERIOD":
            let entitiesAfterDelete = {
                ...state.pricePeriods
            };
            if (!action.error) {
                delete entitiesAfterDelete[action.payload.id];
            }

            return {
                ...state,
                deletePricePeriod: {
                    ...state.deletePricePeriod,
                    isLoading: false
                },
                pricePeriods: entitiesAfterDelete
            };
        case "UPDATE_FAMILIES_IDS":
            return {
                ...state,
                pricePeriodStoreItemsState: {
                    ...state.pricePeriodStoreItemsState,
                    [action.payload.key]: {
                        ...state.pricePeriodStoreItemsState[action.payload.key],
                        familiesGrouped: action.payload.value
                    }
                }
            };
        case "UPDATE_SUBFAMILIES_IDS":
            return {
                ...state,
                pricePeriodStoreItemsState: {
                    ...state.pricePeriodStoreItemsState,
                    [action.payload.key]: {
                        ...state.pricePeriodStoreItemsState[action.payload.key],
                        subFamiliesGrouped: action.payload.value
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}