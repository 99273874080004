import * as React from 'react';
import * as Api from '../api/api';
import NavMenu from "./NavMenu";
import Header from "./Header";
import Notificator from './Notificator';
import PeriodDialog from "./PeriodDialog"
import NavMenuMinimal from './NavMenuMinimal';
import {connect} from "react-redux";
import {ApplicationState} from "../store";
import {isAuthenticated} from "../security/UserIsAuthenticated";
import * as StockStore from "../store/Stock";
import * as NeoEventAlarmStore from "../store/NeoEventAlarm";
import * as HistoryStore from "../store/History";
import * as CashStore from "../store/Cash";
import * as CardStore from "../store/Card";
import * as RobotStore from "../store/Robot";
import * as TempStore from "../store/Temp";
import * as HomeStore from "../store/Home";
import * as ContactStore from "../store/Contact";
import * as HmiApplicationStore from "../store/HmiApplication";
import * as PrintStore from "../store/Print";

interface LayoutState {
    isMenuOpened: boolean;
    isDarkMode: boolean;
}

interface LayoutOwnProps {
    isAuthenticated: boolean
    hmis: Array<Api.HmiModel>;
}

type LayoutProps = LayoutOwnProps
    & typeof StockStore.actionCreators
    & typeof NeoEventAlarmStore.actionCreators
    & typeof HistoryStore.actionCreators
    & typeof CashStore.actionCreators
    & typeof CardStore.actionCreators
    & typeof RobotStore.actionCreators
    & typeof TempStore.actionCreators
    & typeof HomeStore.actionCreators
    & typeof ContactStore.actionCreators
    & typeof HmiApplicationStore.actionCreators
    & typeof PrintStore.actionCreators
    ;

class Layout extends React.Component<LayoutProps, LayoutState> {

    constructor(props: any) {
        super(props);
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        this.state = {
            isDarkMode: isTrueSet == 'true' ? true : false,
            isMenuOpened: true,
        }
    }
    
    componentDidMount() {
        this.props.requestRobots(new Date().getTime());
        (this.props.requestHmis(new Date().getTime()) as any as Promise<any>)
            .then(() => {
                //small delay for initial connexion
                setTimeout(() => {
                    this.props.hmis.forEach(hmi => {
                        this.props.requestRequestHmiState(new Date().getTime(), hmi.hmiId);
                        this.props.checkAviablePaymentMethods(hmi.hmiId);
                    });
                }, 3000);
            });
        
        //Is managed by peripheral component
        // this.props.requestCardState(new Date().getTime());
        // this.props.requestCashState(new Date().getTime());
        // this.props.requestPrintState(new Date().getTime());
        // this.props.requestRobotState(new Date().getTime());
        // this.props.requestTempTemperature(new Date().getTime());
        // this.props.requestTempState(new Date().getTime());
        // this.props.requestSmartPhoneStatus(new Date().getTime());
        
        if(this.props.isAuthenticated){
            this.requestAuthData(this.props);
        }
    }

    requestAuthData(props: LayoutProps){
        console.debug("Loading auth data");
        props.requestStockStatus(new Date().getTime());
        (props.requestNeoEventAlarms(new Date().getTime()) as any as Promise<any>)
            .then(() => props.requestAlarmEvents(new Date().getTime()));
        (props.requestTodayDayStoreData(new Date().getTime()) as any as Promise<any>)
            .then(() => props.requestTodayStoreOrders(new Date().getTime()));
        props.requestTodayDayMoneyStocks(new Date().getTime());
        props.requestHoppers(new Date().getTime());
        (props.requestContacts(new Date().getTime()) as any as Promise<any>)
            .then(() => props.requestNeoEventAlarms(new Date().getTime()));
    }
    
    componentWillReceiveProps(nextProps: Readonly<LayoutProps>, nextContext: any) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated){
            this.requestAuthData(nextProps);
        }
    }

    setIsMenuOpened(value: boolean) {
        this.setState({
            ...this.state,         
            isMenuOpened: value
        });
    }

    setIsDarkMode(value: boolean) {
        /*this.setState({
            ...this.state,         
            isDarkMode: value
        });*/
        if (typeof window !== 'undefined') {
            localStorage.removeItem('isDarkMode');
            localStorage.setItem('isDarkMode', value as any);
            window.location.href="/";
        }
    }    
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    chevronImage() {        
        let chevronButton = require('../assets/images/light-button.png') as any;
        if(!this.isDarkMode() && !this.state.isMenuOpened) {
            chevronButton = require('../assets/images/light-button-right.png') as any;
        } else if(this.isDarkMode() && this.state.isMenuOpened) {
            chevronButton = require('../assets/images/dark-button.png') as any;
        }  else if(this.isDarkMode() && !this.state.isMenuOpened) {
            chevronButton = require('../assets/images/dark-button-right.png') as any;
        }
        return chevronButton;
    }

    public render() {
        return <div style={{ height: "100%" }}>
            <input type="checkbox" id="switch" checked={this.isDarkMode()} onChange={(e) => {
                this.setIsDarkMode(e.target.checked);
            }}></input>
            <div className="app" style= {{ display: "flex", minHeight: "100%" }}>
                {this.state.isMenuOpened ?
                    <div className='menu'>
                        <NavMenu />
                    </div> 
                :
                    <div className='menu-minimal'>
                        <NavMenuMinimal />
                    </div>
                }
                <button className={this.state.isMenuOpened ? 'menuButton' : 'menuButton fixedButton'} onClick={(e) => 
                    {
                        this.setIsMenuOpened(!this.state.isMenuOpened);
                }}><img style={{width: 30}} src={this.chevronImage()} alt="menu"/></button>
                <div className='app-container'>
                    <div style={{ 
                        width: "100%",
                        height: "100vh",
                        maxHeight: "100vh",
                        display: "grid",
                        gridTemplateRows: "auto auto auto 1fr 1fr",
                        gridTemplateColumns: "1fr"
                    }}>
                        <div style={{ gridRow: "2 / 3", gridColumn: "1 / 2", padding: '0 2%', height: 'auto' }}>
                            <Header />
                        </div>
                        <div style={{ gridRow: "3 / 6", gridColumn: "1 / 2", overflowY: "auto", padding: '0 1.5% 0 2%', height: 'auto'}}>
                            {this.props.children}
                        </div>
                        <PeriodDialog />
                        <Notificator />
                    </div>
                </div>
            </div>     
        </div>;
    }
}

export default connect((state: ApplicationState) => ({
    isAuthenticated: isAuthenticated(state),
    hmis: state.hmiApplication.hmis
}), {
    ...StockStore.actionCreators,
    ...NeoEventAlarmStore.actionCreators,
    ...HistoryStore.actionCreators,
    ...CashStore.actionCreators,
    ...CardStore.actionCreators,
    ...RobotStore.actionCreators,
    ...TempStore.actionCreators,
    ...HomeStore.actionCreators,
    ...ContactStore.actionCreators,
    ...HmiApplicationStore.actionCreators,
    ...PrintStore.actionCreators,
})(Layout as any);
