import * as Api from '../api/api';
import * as React from 'react';
import * as ImportStore from '../store/Import';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type ImportProps = ImportStore.ImportState
    & ImportOwnProps
    & typeof ImportStore.actionCreators;

interface ImportOwnProps {
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;
}

interface ImportState{
    token: string;
}

class Import extends React.Component<ImportProps, ImportState> {

    constructor(props: ImportProps) {
        super(props);
        this.state = {
            token: ""
        }
    }

    componentDidMount() {
        this.props.requestHasStoreToken(new Date().getTime());
    }

    public render() {
        return (
            <div style={{  pointerEvents: this.props.disabled ? "none" : "initial", 
                        opacity: this.props.disabled ? 0.7 : 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{
                    flex: "0 0 auto",
                    padding: "3px 6px",
                    fontSize: 20,
                    fontWeight: "bold",
                    ...(this.props.storeTokenState.hasToken
                        ? {
                            color: "green"
                        } : {
                            color: "red"
                        })
                }}>
                    {this.props.storeTokenState.hasToken
                        ? getText("SettingsHasToken")
                        : getText("SettingsNoToken")}
                </div>
                {!this.props.storeTokenState.hasToken
                    && <div style={{ flex: "0 0 auto", padding: "3px 6px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input className="form-control"
                        type="password"
                        disabled={this.props.disabled}
                        value={this.state.token}
                        onChange={(e) => this.setState({ token: e.target.value })} />
                    <button className="btn btn-primary btn-sm"
                        disabled={this.props.storeTokenState.setTokenState.isLoading || this.props.disabled}
                        style={{}}
                        onClick={(e) => this.props.requestSetStoreToken(new Date().getTime(), this.state.token)}>
                        {getText("SettingsSaveToken")}
                        </button>
                    </div>}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.import,
    disabled: !canUpdate(state),    
    currentUser: state.account.currentUser,
}), ImportStore.actionCreators)(Import as any);