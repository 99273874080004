import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from "react-table";
import * as StockStore from "../store/Stock";
import * as PurchaseTariffStore from '../store/PurchaseTariff';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import Table from "./Table";
import HelpHeader from './HelpHeader';
import Select from "./Select";
import InputNumber from "./InputNumber";
import * as ReorderStore from "../store/Reorder";


type PurchaseTariffProps = PurchaseTariffStore.PurchaseTariffState
    & PurchaseTariffOwnProps
    & typeof PurchaseTariffStore.actionCreators;

interface PurchaseTariffOwnProps {
    stock: StockStore.StockState;
    products: { [id: number]: Api.ProductModel };
    vats: { [key: string]: Api.VatModel; };
    countryId: number;
    suppliers: { [id: number]: Api.SupplierModel };
    countryVats: Array<Api.CountryVatModel>;
    productRailsInPlano: Array<Api.ProductRailModel>;
}

interface PurchaseTariffOwnState {
    supplierMainIdSelected: number;
}

export interface PurchaseTariffForTableModel {
    "productId"?: number;
    "name"?: string;
    "productSupplier"?: Api.ProductSupplierModel
    "buyPriceUnit"?: number;
    "deliveryCondPrice"?: number;
}

type PurchaseTariffColumn = ReactTable.Column
    & {
        accessor: (d: PurchaseTariffForTableModel) => any;
        columns?: Array<PurchaseTariffColumn>
    }

class PurchaseTariff extends React.Component<PurchaseTariffProps, PurchaseTariffOwnState> {

    constructor(props: any) {
        super(props);
        this.state = {
            supplierMainIdSelected: null
        };
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    isInPlanogram(supplierId: number): boolean {
        if(!this.props.stock.stockStatus)
            return false;
        
        return this.props.productRailsInPlano.some(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products)
            ? ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === supplierId
            : false);
    }

    get productsInPRails(): PurchaseTariffForTableModel[] {
        if (!this.props.stock.stockStatus)
            return [];

        let planogramId = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0].planogramId;
        
        let productRailsFiltered = this.props.stock.stockStatus.productRails.filter(x => x.planogramId === planogramId);

        if (this.state.supplierMainIdSelected != null) {
            productRailsFiltered = productRailsFiltered.filter(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products) ?
                ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === this.state.supplierMainIdSelected
                : ''
            )
        }  

        let resArr: PurchaseTariffForTableModel[] = [];
        
        productRailsFiltered.filter(item => {
                var i = resArr.findIndex(x => x.productId == item.productId);
                if(i <= -1) {
                    let productId = item.productId;
                    var psupplier = ReorderStore.getProductSupplier(this.props.products[productId].storeItemId, this.props.products);

                    psupplier = this.props.prodSuppliersUpdated[psupplier.productSupplierId] != undefined ?
                        this.props.prodSuppliersUpdated[psupplier.productSupplierId] : psupplier;

                    let purchaseTariff : PurchaseTariffForTableModel = {
                        productId: productId,
                        name: this.props.products[productId].name,
                        productSupplier: psupplier,
                        buyPriceUnit: (psupplier.buyPriceNoVat / psupplier.packCondition),
                        deliveryCondPrice: (psupplier.buyPriceNoVat * psupplier.deliveryCondition)
                    };

                    resArr.push(purchaseTariff);
                }
            });
        
            return resArr.sort((a, b) => this.props.products[a.productId].name.localeCompare(this.props.products[b.productId].name));
    }

    get isDisabledByUpdating() : boolean{
        return (Object.keys(this.props.prodSuppliersUpdated).length == 0);
    }

    public render() {
        return (
            <div >
            <div 
            className="card"
            style={{
                ...Styles.cardStyle,
                ...Styles.pageTitle
            }}><span style={{paddingLeft: 30}}>{getText( "PurchaseTariffTitle")}</span> <HelpHeader /></div>
            <div className="card purchasetariff-table" style={{ ...Styles.cardStyle, margin: "1%", height: "100%" }}>
            <div className="row" style={{margin: "1% 1% 0.2rem 1%"}}>
                <div className="col-md-12" style={{display: 'flex', justifyContent: 'flex-end', margin: 5, fontSize: 16}}>
                    <button className="btn btn-primary"
                        style={{height: 40, fontSize: 16, fontWeight: 'bold'}}
                        disabled={ this.isDisabledByUpdating }
                        onClick={(e) => { 
                            let model: Api.UpdateProdSupplierResultModel = {
                                prodSuppliersUp: this.props.prodSuppliersUpdated
                            };
                            this.props.savePurchaseTariff(new Date().getTime(), model);
                        }}>
                            {getText( "SalesTariffButtonSave")}
                    </button>
                </div>
            </div>
            <Table
                    tableKey={"purchasetariff"}
                    loading={this.props.isSaving || this.props.stock.isLoading}
                    style={{ height: '75vh' }}
                    columns={[
                        {
                            id: "general",
                            Header: 
                            <div  style={{
                                margin: 0, display: "flex", flexDirection: "row", 
                                columnGap: 4, flexWrap: "wrap",
                                width: "100%"
                            }}>
                                <div style={{lineHeight: "40px", flex: "0 0 1" }}>{getText( "StockSource")}: </div>
                                    <div style={{padding: 0, flex: "0 0 1", minWidth: 150 }}>
                                        <div>
                                            <Select options={[{
                                                label: "------",
                                                value: null
                                                }].concat(_.sortBy(_.values(this.props.suppliers)
                                                    .filter(x => this.isInPlanogram(x.supplierId))
                                                    .map(z => ({ label: z.name, value: z.supplierId })), x => x.label))}
                                                    style={{
                                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                                    }}
                                                    value={this.state.supplierMainIdSelected}
                                                    onChange={(newSupplierMainId) => {
                                                        this.setState({ ...this.state, supplierMainIdSelected: newSupplierMainId});
                                                    }}
                                            />
                                        </div>
                                </div>
                            </div>,
                            columns: [
                                {
                                    id: "name",
                                    accessor: (d) => d.name,
                                    Header: <div>{getText( "SalesTariffTabName")}</div>,
                                    Cell: row => { return (<div style={{textAlign: 'initial', margin: 7}}> {row.value}</div>) },
                                    style: { height: '100%' },
                                    width: 400,
                                },
                                {
                                    id: "reference",
                                    accessor: (d) => d.productSupplier,
                                    Header: <div>{getText( "PurchaseTariffTabReference")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <input className="form-control input-sm"
                                                style= {{height: 32}}
                                                type={"text"}
                                                value={row.value.reference}
                                                onChange={(e) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                        ...row.value,
                                                        reference: e.target.value
                                                    }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}
                                                onBlur={(e) => { 
                                                    let pS: Api.ProductSupplierModel = {
                                                        ...row.value,
                                                        reference: e.target.value
                                                    }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                } }/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "vatId",
                                    accessor: (d) => d.productSupplier,
                                    Header: <div>{getText( "HistoTVA")}</div>,
                                    Cell: row =>{
                                        return (
                                            <Select options={(_.values(this.props.vats)
                                                    .filter(x => this.props.countryVats.some(y => y.vatId == x.vatId))
                                                    .map(z => ({ label: z.name, value: z.vatId })))} 
                                                    style={{
                                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                                    }}
                                                    value={row.value.vatId}
                                                    onChange={(element) => {
                                                        let pS: Api.ProductSupplierModel = {
                                                            ...row.value,
                                                            vatId: element
                                                        }   
                                                        this.props.addToUpdatedProdSuppliersList(pS);
                                                    }} 
                                            />)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "packCondition",
                                    accessor: (d) => d.productSupplier,
                                    Header: <div>{getText( "PurchaseTariffTabPackCond")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <InputNumber value={row.value.packCondition}
                                                style={{ width: 50, textAlign: 'center' }}
                                                onChange={(element) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                    ...row.value,
                                                    packCondition: element
                                                    }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}
                                                onBlur={(element) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                        ...row.value,
                                                        packCondition: element
                                                        }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "buyPriceNoVat",
                                    accessor: (d) => d.productSupplier,
                                    Header: <div>{getText( "PurchaseTariffTabBuyPrice")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <InputNumber value={row.value.buyPriceNoVat}
                                                style={{ width: 50, textAlign: 'center' }}
                                                onChange={(element) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                    ...row.value,
                                                    buyPriceNoVat: element
                                                    }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}
                                                onBlur={(element) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                        ...row.value,
                                                        buyPriceNoVat: element
                                                        }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "buyPriceUnit",
                                    accessor: (d) => d.buyPriceUnit.toFixed(2),
                                    Header: <div>{getText( "PurchaseTariffTabBPUnit")}</div>,
                                    style: { height: '100%' }
                                },
                                {
                                    id: "deliveryCondition",
                                    accessor: (d) => d.productSupplier,
                                    Header: <div>{getText( "PurchaseTariffTabDelCond")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <InputNumber value={row.value.deliveryCondition}
                                                style={{ width: 50, textAlign: 'center' }}
                                                onChange={(element) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                    ...row.value,
                                                    deliveryCondition: element
                                                    }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}
                                                onBlur={(element) => {
                                                    let pS: Api.ProductSupplierModel = {
                                                        ...row.value,
                                                        deliveryCondition: element
                                                        }   
                                                    this.props.addToUpdatedProdSuppliersList(pS);
                                                }}/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "deliveryCondPrice",
                                    accessor: (d) => d.deliveryCondPrice.toFixed(2),
                                    Header: <div>{getText( "PurchaseTariffTabDelCondPrice")}</div>,
                                    style: { height: '100%' }
                                }
                            ] as Array<PurchaseTariffColumn>
                        },
                    ] as Array<PurchaseTariffColumn>}
                    pageSize={Math.max(this.productsInPRails.length, 15)}
                    showPagination={false}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    data={this.productsInPRails}>
                </Table>
            </div>
        </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.purchaseTariff,
    stock: state.stock,
    products: state.seed.seed.products,
    vats: state.seed.seed.vats,
    countryId: state.seed.seed.countryVats[0] ? state.seed.seed.countryVats[0].countryId : 1,
    suppliers: state.seed.seed.suppliers, 
    countryVats: state.seed.seed.countryVats,
    productRailsInPlano: StockStore.productRailsInPlanoSelector(state)
}),{ ...PurchaseTariffStore.actionCreators })(PurchaseTariff as any)