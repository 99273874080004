import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import DialogModal from "./DialogModal";
import Contacts from "./Contacts";
import { getText } from '../utils/texts';

type NeoEventAlarmContactsProps = NeoEventAlarmContactsOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.PricePeriodStoreItemModel>;

export interface NeoEventAlarmContactsOwnProps {
    isDialogOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
    disabled?: boolean;
    neoEventAlarmContacts: Array<Api.NeoEventAlarmContactModel>;
    contacts: Array<Api.ContactModel>;
}

export default class NeoEventAlarmContacts extends React.Component<NeoEventAlarmContactsProps, {}> {
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div>
                <div className="row">
                    {this.props.fields.map((member, index) => <div key={index}>
                        <div className="col-md-3" style={{paddingBottom: '1%'}}>
                            <ReduxForm.Field className="form-control input-sm"
                                validate={[FormField.required]}
                                name={`${member}.contactId`}
                                style={{width: '100%'}}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField(this.props.contacts
                                    .map(x => ({ label: `${x.lastName} ${x.firstName}`, value: x.contactId })))} />
                        </div>
                        <div className="col-md-1" style={{padding: 0, width: 20}}>
                            <div
                                style={{ marginTop: '15%', cursor: 'pointer' }}
                                onClick={() => {
                                    if(!this.props.disabled) {
                                        this.props.fields.remove(index)
                                    }
                                }}>
                                <i className="glyphicon glyphicon-remove"></i>
                            </div>
                        </div>
                    </div>)}
                    {/* <div className="col-md-6">
                        <button type="button" className="btn btn-primary"
                            style={{ height: 40, width: '100%', fontWeight: 'bold',fontSize: 15, margin: 0,
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            onClick={(e) => {
                                this.props.openDialog();
                                e.stopPropagation();
                            }}>
                                {getText( "MaintenanceNewContact")}
                                <i style={{marginLeft: '8%'}} 
                                    className="glyphicon glyphicon-edit"></i>
                        </button>
                        
                    </div> */}
                </div>
                <FormField.FieldErrors {...this.props.meta} />
                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-md-6">
                        <button style={{width: '100%', height: 40, fontSize: 15, fontWeight: 'bold',
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            type="button"
                            className="btn btn-primary"
                            disabled={this.props.disabled}
                            onClick={() => this.props.fields.push({
                            })}>
                            <i className="glyphicon glyphicon-arrow-down"></i> {getText( "MaintenanceAdd")} <i className="glyphicon glyphicon-arrow-down"></i>
                        </button>
                    </div>
                </div>
                {/* <DialogModal isOpen={this.props.isDialogOpen}
                    contentStyle={{
                        width:'50%', padding: '0 10px',
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);"
                    }}
                    contentLabel={"Contacts"}
                    onRequestClose={() => this.props.closeDialog()}>
                    <div>
                        <Contacts />
                    </div>
                </DialogModal> */}
            </div>
         );
                
       
    }
}
