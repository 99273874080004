import { ApplicationState } from "../store/index"
import * as Api from "../api/api";
import * as _ from 'lodash';

export let BASE_PATH = "";

export const setBasePath = (basePath: string) => {
    BASE_PATH = basePath;
}

export const isClient = (): boolean => {
    return typeof window !== "undefined";
}

export const authorizationCookie = 'access_token';

export const getDefaultHeaders = (state: ApplicationState): any => {
    return {
        Authorization: 'Bearer ' + state.account.token
    };
}

const getExtension = (fileName: string): string => {
    return fileName.split('.')[1];
};

export const getImagePath = (image: Api.ImageModel) => {
    return "/api/Image/GetImage?imageName=" + image.md5 + "." + getExtension(image.fileName);
}

export const getStoreItemImage = (item: Api.ProductModel): Api.ImageModel => {
    let customGeneric = item.productCustoms && item.productCustoms.find(x => x);
    return customGeneric
        ? customGeneric.image || item.image
        : item.image;
}

//Y1 = 1, //DxW
//X1 = 2, //DxH
//Z1 = 3, //WxH
//Z2 = 4, //DxH
//Y2 = 5, //DxW
//X2 = 6  //WxH

export const calculateProductDepth = (product: Api.ProductModel, sens: Api.ProductRailModelSensEnum): number => {
    if (sens === "Y1")
        return product.depth;
    if (sens === "X1")
        return product.depth;
    if (sens === "Z1")
        return product.height;
    if (sens === "X2")
        return product.width;
    if (sens === "Y2")
        return product.width;
    if (sens === "Z2")
        return product.height;
}

export const calculateViewScale = (totalSize: number, scale: number): Number => {
    if(scale != 0)
        return (Number.parseInt(Number(totalSize/scale).toFixed()) + Number.parseInt("1"));
    return 1
}

export const calculateProductWidth = (product: Api.ProductModel, sens: Api.ProductRailModelSensEnum): number => {
    if (sens === "Y1")
        return product.width;
    if (sens === "X1")
        return product.height;
    if (sens === "Z1")
        return product.width;
    if (sens === "X2")
        return product.height;
    if (sens === "Y2")
        return product.depth;
    if (sens === "Z2")
        return product.depth;
}

export const calculateProductHeight = (product: Api.ProductModel, sens: Api.ProductRailModelSensEnum): number => {
    if (sens === "Y1")
        return product.height;
    if (sens === "X1")
        return product.width;
    if (sens === "Z1")
        return product.depth;
    if (sens === "X2")
        return product.depth;
    if (sens === "Y2")
        return product.height;
    if (sens === "Z2")
        return product.width;
}

export function padLeft(nr, n, str?: string): string {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
}

export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

export const getExpirityTypeDesc = (product: Api.ProductModel):string => {
    const expirityType = product.expirityType.toString().toUpperCase();
    switch (expirityType){
        case 'NONE':
            return 'Aucune (none)';
        case 'NORMAL':
            return 'DLC';
        case 'EGGS':
            return 'DLC oeufs (-7j)';
        default:
            return expirityType;
    }
}

export enum NeoEventSource
{
    Status = 0,
    Management = 1,//Exploitant
    Client = 2,
    Robot = 3,
    Computer = 4,//Ordinateur
    Cash = 5,//Espèces
    Card = 6,//CB
    Printer = 7,//Imprimante
    SmartPhone = 8
}

export const filterWithoutAccents = (value: string): string => {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}

export const validRobotPosition = (CurrentX : number, CurrentZ: number, ShelveGap: number,
        stockStatus: Api.StockStatusModel, existEx2: boolean): boolean => {
    let X : number;
    let Z : number;
    let lane1 : boolean = false;
    let lane2 : boolean = false;

    let shelvingsLane1 = _.values(stockStatus.shelvings)
                        .filter(x => x.lane == 1)
                        .sort((a,b) => a.position - b.position);

    X = shelvingsLane1[0].offsetX;

    for (let iShel = 0; iShel < shelvingsLane1.length; iShel++) {
        X = X + shelvingsLane1[iShel].width;

        if( X > CurrentX){
            X = X - shelvingsLane1[iShel].width;
            for (let index = 0; index < 18; index++) {
                if(X != CurrentX && X < CurrentX){
                    X = X + 48
                }
                else{
                    break;
                }
            }
            if(X == CurrentX){
                Z = shelvingsLane1[0].offsetZ;

                let shelviTrayPlaLane1 = _.values(stockStatus.shelvingTrayPlacements)
                    .filter(x => x.planogramId == (_.sortBy(stockStatus.planograms, x => !x.active)[0]).planogramId
                        &&  x.shelvingId == shelvingsLane1[iShel].shelvingId)
                    .sort((a,b) => a.level - b.level);

                for (let iShelTrayP = 0; iShelTrayP < shelviTrayPlaLane1.length; iShelTrayP++) {
                    if(Z != CurrentZ && Z < CurrentZ){
                        Z = shelvingsLane1[0].offsetZ + (shelvingsLane1[iShel].railHeight * (shelviTrayPlaLane1[iShelTrayP].level - 1))
                    }
                    else{
                        break;
                    }
                }
                if(Z == CurrentZ){
                    lane1 = true;
                    break;
                }
                if( Z > CurrentZ){
                    break;
                }
            }

            if( X != CurrentX){
                break;
            }
            
        }
        else {
            X = X + ShelveGap
        }
    }

    if(existEx2){
        let shelvingsLane2 = _.values(stockStatus.shelvings)
                        .filter(x => x.lane == 2)
                        .sort((a,b) => a.position - b.position);

        X = shelvingsLane2[0].offsetX;

        for (let iShel = 0; iShel < shelvingsLane2.length; iShel++) {
            X = X + shelvingsLane2[iShel].width;
        
            if( X > CurrentX){
                X = X - shelvingsLane2[iShel].width;
                for (let index = 0; index < 18; index++) {
                    if(X != CurrentX && X < CurrentX){
                        X = X + 48
                    }
                    else{
                        break;
                    }
                }
                if(X == CurrentX){
                    Z =  shelvingsLane2[0].offsetZ;

                    let shelviTrayPlaLane2 = _.values(stockStatus.shelvingTrayPlacements)
                            .filter(x => x.planogramId == (_.sortBy(stockStatus.planograms, x => !x.active)[0]).planogramId
                                && x.shelvingId == shelvingsLane2[iShel].shelvingId)
                            .sort((a,b) => a.level - b.level);
        
                    for (let iShelTrayP = 0; iShelTrayP < shelviTrayPlaLane2.length; iShelTrayP++) {
                        if(Z != CurrentZ && Z < CurrentZ){
                            Z = shelvingsLane2[0].offsetZ + (shelvingsLane2[iShel].railHeight * (shelviTrayPlaLane2[iShelTrayP].level - 1))
                        }
                        else{
                            break;
                        }
                    }
                    if(Z == CurrentZ){
                        lane2 = true;
                        break;
                    }
                    if( Z > CurrentZ){
                        break;
                    }
                }
        
                if( X != CurrentX){
                    break;
                }
                
            }
            else{
                X = X + ShelveGap
            }
        }
    }

    if(lane1 == true || lane2 == true){
        return true;
    }

    return false;
}