import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as _ from 'lodash';
import * as Api from "../api/api";
import { AppThunkAction } from './';

export interface MenuState {
    updateEnabled: {
        isLoading: boolean;
        requestTime?: number;
    };
    selectedMenuId?: number;
}

interface RequestUpdateMenuEnabled {
    type: 'REQUEST_UPDATE_MENUENABLED';
    payload: {
        requestTime: number;
        id: number;
    }
}
export interface ReceiveUpdateMenuEnabled {
    type: 'RECEIVE_UPDATE_MENUENABLED';
    payload: {
        requestTime: number;
        id: number;
        value: boolean;
    };
    error?: any;
}

interface MenuUpdateSelectedMenu {
    type: "MENU_UPDATE_SELECTEDMENU";
    payload: { value?: number; }
}

export type KnownAction = RequestUpdateMenuEnabled
    | ReceiveUpdateMenuEnabled
    | MenuUpdateSelectedMenu
    ;

export const actionCreators = {
    requestUpdateMenuEnabled: (requestTime: number, id: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuApi();
        let fetchTask = api.updateMenuEnabled({ menuId: id, value: value }).then(() => {
            dispatch({
                type: "RECEIVE_UPDATE_MENUENABLED",
                payload: {
                    requestTime: requestTime,
                    id: id,
                    value: value
                }
            });
        }).catch(error => {
            dispatch({
                type: "RECEIVE_UPDATE_MENUENABLED",
                payload: {
                    requestTime: requestTime,
                    id: id,
                    value: value
                },
                error: error
            });
        });

        //Tell the server to wait for this promise to end
        addTask(fetchTask);
        dispatch({ type: "REQUEST_UPDATE_MENUENABLED", payload: { requestTime: requestTime, id: id } });
        return fetchTask;
    },
    updateSelectedMenu: (id?: number) => <MenuUpdateSelectedMenu>{
        type: "MENU_UPDATE_SELECTEDMENU", payload: { value: id }
    },

};

const unloadedState: MenuState = {
    updateEnabled: {
        isLoading: false
    }
};

export const reducer: Reducer<MenuState> = (state: MenuState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_UPDATE_MENUENABLED':
            return {
                ...state,
                updateEnabled: {
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_UPDATE_MENUENABLED':
            if (state.updateEnabled.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateEnabled: {
                    isLoading: false,
                }
            };
        case "MENU_UPDATE_SELECTEDMENU":
            return {
                ...state,
                selectedMenuId: action.payload.value
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};