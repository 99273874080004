import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as _ from 'lodash';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { createSelector } from 'reselect';
import { ReceiveUpdateMenuEnabled } from "./Menu";
import { ReceiveUpdateStatusLanguage } from "./Settings";
import { SalesTariffReceiveSave } from "./SalesTariff";
import { PTReceiveSave } from "./PurchaseTariff";
import { ProdSizesReceiveSave } from './ProductsDetails';
import { MenuCategoryReceiveCreate,
        MenuCategoryReceiveUpdate,
        MenuCategoryReceiveDelete,
        MenuCategoryTransReceiveCreate,
        MenuCategoryTransReceiveUpdate,
        MenuCategoryTransReceiveDelete
    } from './MenuCategories';

export interface SeedState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime: number;
    seed: Api.SeedMngModel;
    isDialogVersionOpen: boolean;
}

interface RequestSeed {
    type: 'REQUEST_SEED';
    payload: { requestTime: number; }
}
interface ReceiveSeed {
    type: 'RECEIVE_SEED';
    payload: {
        requestTime: number;
        seed?: Api.SeedMngModel;
    };
    error?: any;
}

interface OpenVersionDialog {
    type: "OPEN_VERSION_DIALOG";
}
interface CloseVersionDialog {
    type: "CLOSE_VERSION_DIALOG";
}

export type KnownAction = RequestSeed
    | ReceiveSeed
    | ReceiveUpdateMenuEnabled
    | OpenVersionDialog
    | CloseVersionDialog
    | ReceiveUpdateStatusLanguage
    | SalesTariffReceiveSave
    | PTReceiveSave
    | ProdSizesReceiveSave
    | MenuCategoryReceiveCreate
    | MenuCategoryReceiveUpdate
    | MenuCategoryReceiveDelete
    | MenuCategoryTransReceiveCreate
    | MenuCategoryTransReceiveUpdate
    | MenuCategoryTransReceiveDelete;

export const requestSeed = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.SeedApi();
    let fetchTask = api.getSeed().then(seed => {
        dispatch({
            type: "RECEIVE_SEED",
            payload: {
                requestTime: requestTime,
                seed: seed
            }
        });
    }).catch(error => {
        dispatch({
            type: "RECEIVE_SEED",
            payload: {
                requestTime: requestTime,
            },
            error: error
        });
    });

    //Tell the server to wait for this promise to end
    addTask(fetchTask);
    dispatch({ type: "REQUEST_SEED", payload: { requestTime: requestTime } });
    return fetchTask;
}

export const actionCreators = {
    requestSeed: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestSeed(requestTime, dispatch, getState);
    },
    openVersionDialog: () => <OpenVersionDialog>{
        type: "OPEN_VERSION_DIALOG"
    },
    closeVersionDialog: () => <CloseVersionDialog>{
        type: "CLOSE_VERSION_DIALOG"
    },    
};

const unloadedState: SeedState = {
    isLoading: false,
    isLoaded: false,
    requestTime: 0,
    seed: {
        families: {},
        languages: {},
        priceNames: {},
        products: {},
        menus: {},
        subFamilies: {},
        suppliers: {},
        menuCategories: {},
        externalProducts: {}
    },
    isDialogVersionOpen: false
};

const productsSelector = (state: ApplicationState) => state.seed.seed.products;
const externalPSelector = (state: ApplicationState) => state.seed.seed.externalProducts;

export const productSuppliersSelector = createSelector(
    productsSelector,
    products => _.keyBy(_.values(products)
        .map(x => x.productSuppliers)
        .reduce((a, b) => a.concat(b), []), x => x.productSupplierId)
);

export const externalPSuppliersSelector = createSelector(
    externalPSelector,
    eproducts => _.keyBy(_.values(eproducts)
        .map(x => x.productSuppliers)
        .reduce((a, b) => a.concat(b), []), x => x.productSupplierId)
);

export const reducer: Reducer<SeedState> = (state: SeedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SEED':
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case 'RECEIVE_SEED':
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                seed: action.error
                    ? state.seed
                    : action.payload.seed
            };
        case "RECEIVE_UPDATE_MENUENABLED":
            return {
                ...state,
                seed: {
                    ...state.seed,
                    menus: {
                        ...state.seed.menus,
                        [action.payload.id]: {
                            ...state.seed.menus[action.payload.id],
                            hmiEnabled: action.payload.value
                        }
                    }
                }
            };
        case "RECEIVE_UPDATE_STATUSLANGUAGE":
            return {
                ...state,
                seed: {
                    ...state.seed,
                    languages: action.error
                        ? state.seed.languages
                        : action.payload.languages
                }
            };
        case "OPEN_VERSION_DIALOG":
            return {
                ...state,
                isDialogVersionOpen: true
            };
        case "CLOSE_VERSION_DIALOG":
            return {
                ...state,
                isDialogVersionOpen: false
            };
        case "SALESTARIFF_RECEIVE_SAVE":
        case "PT_RECEIVE_SAVE":     
        case "PRODSIZES_RECEIVE_SAVE":       
            return {
                ...state,
                seed: {
                    ...state.seed,
                    products: action.error
                        ? state.seed.products
                        : {
                            ...state.seed.products,
                            ...action.payload.values
                        },
                } 
            };
        case "MENUCAT_RECEIVE_CREATE":
            return {
                ...state,
                seed: {
                    ...state.seed,
                    menuCategories: action.error
                        ? state.seed.menuCategories
                        : {
                            ...state.seed.menuCategories,
                            [action.payload.model.menuCategoryId]: action.payload.model
                    }
                }
            };
        case "MENUCAT_RECEIVE_UPDATE":
                return {
                    ...state,
                    seed: {
                        ...state.seed,
                        menuCategories: action.error
                            ? state.seed.menuCategories
                            : {
                                ...state.seed.menuCategories,
                                [action.payload.model.menuCategoryId]: action.payload.model
                        }
                    }
                };
        case "MENUCAT_RECEIVE_DELETE":

                let menuCatAfterDelete = {
                    ...state.seed.menuCategories
                };
                if (!action.error) {
                    delete menuCatAfterDelete[action.payload.idMenuCategory];
                }
                return {
                    ...state,
                    seed: {
                        ...state.seed,
                        menuCategories: menuCatAfterDelete
                    }
                };
        case "MENUCAT_RECEIVE_CREATE_TRANSLATION":
            return {
                ...state,
                seed: {
                    ...state.seed,
                    menuCategories: action.error
                        ? state.seed.menuCategories
                        : {
                            ...state.seed.menuCategories, 
                            [action.payload.model.menuCategoryId]: {
                                ...state.seed.menuCategories[action.payload.model.menuCategoryId],
                                menuCategoryTranslations: state.seed.menuCategories[action.payload.model.menuCategoryId].menuCategoryTranslations
                                            .concat([action.payload.model])
                            }
                        }
                    }
            };
        case "MENUCAT_RECEIVE_UPDATE_TRANSLATION":
                return {
                    ...state,
                    seed: {
                        ...state.seed,
                        menuCategories: action.error
                        ? state.seed.menuCategories
                        : {
                            ...state.seed.menuCategories, 
                            [action.payload.model.menuCategoryId]: {
                                ...state.seed.menuCategories[action.payload.model.menuCategoryId],
                                menuCategoryTranslations: state.seed.menuCategories[action.payload.model.menuCategoryId].menuCategoryTranslations
                                    .map(x => x.menuCategoryTranslationId == action.payload.model.menuCategoryTranslationId ? 
                                        action.payload.model : x)
                            }
                        }
                    }
                };
        case "MENUCAT_RECEIVE_DELETE_TRANSLATION":
            return {
                ...state,
                seed: {
                    ...state.seed,
                    menuCategories: action.error
                    ? state.seed.menuCategories
                    : {
                        ...state.seed.menuCategories, 
                        [action.payload.idMC]: {
                            ...state.seed.menuCategories[action.payload.idMC],
                            menuCategoryTranslations: state.seed.menuCategories[action.payload.idMC].menuCategoryTranslations
                            .filter(x => x.menuCategoryTranslationId != action.payload.idMCT)
                        }
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};