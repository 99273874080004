import { CSSProperties } from "react";

export const colors = {
    main1: { color: '#382E2B', backgroundColor: '#FFFFFF' },
    main2: { color: '#FFFFFF', backgroundColor: '#040930' },
    planogram1: { opacity: 0.7, backgroundColor: 'rgba(59,67,112, 0.05)' },
    planogram2: { opacity: 1, backgroundColor: '#3B4370', color: '#FFFFFF' },
    main3: { color: '#382E2B', backgroundColor: '#FFD100' },
    main4: { color: '#382E2B', backgroundColor: '#FFDA32' },
    warning: { color: '#FF0000', backgroundColor: '#FFFFFF' }
}

export const colorsPalette = [
    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#B3B31A', '#CC9999', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

export const filterLabelStyle: CSSProperties = {
    flex: "0 0 auto",
    paddingRight: "5%",
    fontWeight: "bold",
    fontSize: 16,
    marginLeft: 5,
    display: 'contents'
};

export const filterControlStyle: CSSProperties = {
    flex: "0 0 auto",
};

export const cardStyle: CSSProperties = {
    backgroundColor: '#fff',
    borderRadius: '4px',
};

export const titleCard: CSSProperties = {
    fontSize: 24,
    //marginTop: '2%'
}
export const pageTitle: CSSProperties = {
    display: 'flex',
    height: 60,
    margin: '0px 1% 1% 1%',
    padding: '0.5rem',
    alignItems: "center",
    justifyContent: 'space-between',
    flexDirection: "row",
    fontSize: 24,
    fontWeight: 600
}