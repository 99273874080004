import * as Api from '../api/api';
import * as React from 'react';
import * as SupplierOrderStore from '../store/SupplierOrder';
import * as _ from 'lodash';
import Moment from 'moment';
import Table from "./Table";
import * as ReactTable from "react-table";
import * as Styles from '../styles/baseStyle';
import CheckBox from './CheckBox';
import DialogModal from './DialogModal';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Component } from 'react';
import * as ReorderStore from "../store/Reorder";
import { ProductPriceCalculator } from '../utils/priceUtils';
import HelpHeader from './HelpHeader';
import * as StockStore from "../store/Stock";
import { getText } from '../utils/texts';
import { ExportToCsv } from 'export-to-csv';
import {generateCsvFromTable, CsvData } from "../utils/csv";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type SupplierOrdersProps = SupplierOrdersOwnProps
    & SupplierOrderStore.SupplierOrderState
    & typeof ReorderStore.actionCreators
    & typeof StockStore.actionCreators
    & typeof SupplierOrderStore.actionCreators;

interface SupplierOrdersOwnProps {
    seed: Api.SeedMngModel;
    currencies: { [id: number]: Api.CurrencyModel };
    products: { [id: number]: Api.ProductModel | Api.ExternalProductModel };
    suppliers: { [id: number]: Api.SupplierModel };
    units: { [id: number]: Api.UnitModel };
    vats: { [id: number]: Api.VatModel };
    isSupplierOrderOpen: boolean;
    storeType: string;
}

type SupplierOrderColumn = ReactTable.Column
    & { accessor: (d: Api.SupplierOrderModel) => any; };

type SupplierOrderProductSupplierColumn = ReactTable.Column
    & { accessor: (d: Api.SupplierOrderProductSupplierModel) => any; };

class SupplierOrders extends Component<SupplierOrdersProps, {}> {

    constructor(props: SupplierOrdersProps) {
        super(props);
    }

    componentDidMount() {
        this.props.requestSupplierOrders(new Date().getTime());
    }

    componentWillUnmount() {        
        this.props.updateSupplierOrderSelectedId(undefined)
    }

    product(productId): Api.ProductModel | Api.ExternalProductModel {
        return this.props.products[productId];
    }

    handleSupplierOrderSelectedId(value: number) {
        this.props.updateSupplierOrderSelectedId(value);
        this.props.openSupplierOrderDialog();
    }

    supplierOrderSelected(value: number) {
        let supplierOrderSelected = null;
        if (this.props.supplierOrders && value) {
            supplierOrderSelected = this.props.supplierOrders.find(x => x.supplierOrderId === value)
        }
        return supplierOrderSelected;
    }

    priceCalculator: ProductPriceCalculator = new ProductPriceCalculator(
        this.props.units,
        this.props.vats,
        this.props.suppliers,
        this.props.currencies);

    getSupplierOrderProductSuppliers(supplierOrderId: number) {
        return this.props.storeType == "Ximiti" ? (
         this.props.supplierOrders && supplierOrderId
            ? this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId).supplierOrderProductSuppliers
                .sort((a, b) => this.props.seed.products[a.productId].name.localeCompare(this.props.seed.products[b.productId].name))
            : []
        )
        : (
            this.props.supplierOrders && supplierOrderId
            ? this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId).supplierOrderProductSuppliers
                .sort((a, b) => this.props.seed.externalProducts[a.externalProductId].name.localeCompare(this.props.seed.externalProducts[b.externalProductId].name))
            : []
            )
    }
    
    get supplierOrdersFiltered() {
        return !this.props.showHisto ?
            this.props.supplierOrders.filter(x => x.status === "NotReceived") :
            this.props.supplierOrders
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getTotalOrder(supplierOrderId): number {        
        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId)
        return this.props.storeType == "Ximiti" ?   _.sum(_.map(supplierOrder.supplierOrderProductSuppliers, d =>
            (Math.round(d.packQuantity  * d.productSupplier.buyPriceNoVat * 100) / 100)
        ))
        :
        _.sum(_.map(supplierOrder.supplierOrderProductSuppliers, d =>
            (Math.round(d.packQuantity  * 100) / 100)
        ))
    }

    getTotalPrice(supplierOrderId) 
    {
        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId)
        let totalPrice = 0;
        let price;
        if (supplierOrder) {
            supplierOrder.supplierOrderProductSuppliers.forEach(element => {
                let productId = element.productId;
                let productSupplier = ReorderStore.getProductSupplier(productId, this.props.seed.products)
                price = _.sortBy(this.product(productId).prices,
                    x => (0)
                        + (x.priceNameId !== this.product(productId).defaultPriceNameId ? 2 : 1))[0];
                let priceInfo = this.priceCalculator.calculate(
                    this.product(productId) as any, //cause of model generation making stuff optional
                    price,
                    productSupplier);
                totalPrice = totalPrice + (priceInfo.noVatPrice * element.packQuantity);
            });
        }
        return totalPrice && price ? totalPrice.toFixed(2) + " " + this.props.currencies[price.currencyId].symbol : '0.00';
    }

    getTotalUnitsNumber(supplierOrderId): number {
        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId);
        
        if(supplierOrder){
            return _.sum(_.map(supplierOrder.supplierOrderProductSuppliers, d => (d.packQuantity * d.productSupplier.packCondition)));
        }
    }

    csvData(): CsvData {
        if(!document.getElementById("suppOrder-container"))
            return {
                data: [],
                headers: []
            };

        return generateCsvFromTable(document.getElementById("suppOrder-container"));
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF();
        autoTable(pdf, {body: data});
        pdf.save(`${getText("ReceptionOrders")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    public render() {
        return (
            <div style={{height: "100%"}}>
                <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                    <span style={{paddingLeft: 30}}> {getText( "OrderTitle")} </span>
                    <HelpHeader />
                </div>
                <div className="card" style={{ height: "75vh", ...Styles.cardStyle, margin: "1%"}}>
                    <div style={{padding: '1% 1% 0', display: 'flex', marginBottom:"10px"}}>
                        <div style={{ fontSize: 20 }}> {getText( "OrderProducts")}</div>
                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 250}}>
                            <CheckBox value={this.props.showHisto}
                                label={getText( "OrderFilter")}
                                onChange={(v) => this.props.updateSupplierOrderShowHisto(v)} />
                        </div>
                    </div>
                    <div style={{ height: "90%" }}>
                        <Table
                            className="reorder-table"
                            style={{ height: '100%' }}
                            data={this.supplierOrdersFiltered ? this.supplierOrdersFiltered : []}
                            pageSize={this.supplierOrdersFiltered ? Math.max(this.supplierOrdersFiltered.length, 25) : 15}
                            showPageSizeOptions={false}
                            showPagination={false}
                            showPaginationBottom={false}
                            columns={[
                                {
                                    id: "supplierOrderId",
                                    Header: <div>{getText( "OrderNom")}</div>,
                                    minWidth: 120,
                                    accessor: d => d.name,
                                    Cell: row => {
                                        let supplierName = row.value;
                                        return (
                                            <div style={{textAlign: 'initial', paddingLeft: 8, height: "100%"}}>{supplierName}</div>
                                        )
                                    }
                                },
                                {
                                    id: "date",
                                    Header: <div>{getText( "OrderDate")}</div>,
                                    accessor: d => d.creationDate,
                                    Cell: row => {
                                        let creationDate = row.value;
                                        return (
                                            <div style={{paddingLeft: 8}}>{Moment(creationDate).format("DD/MM/YYYY")}</div>
                                        )
                                    }
                                },
                                {
                                    id: "supplierOrderProductSuppliersLength",
                                    Header: <div>{getText( "OrderNumber")}</div>,
                                    accessor: d => d.supplierOrderId,
                                    Cell: row => {
                                        let supplierOrderId = row.value;
                                        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId)
                                        let supplierOrderProductSuppliersLength = supplierOrder ? supplierOrder.supplierOrderProductSuppliers.length : 0;
                                        return (
                                            <div style={{ paddingLeft: 8 }}>{supplierOrderProductSuppliersLength}</div>
                                        )
                                    }
                                },
                                {
                                    id: "conditionnements",
                                    Header: <div>{getText( "OrderCond")}</div>,
                                    accessor: d => d.supplierOrderId,
                                    Cell: row => {
                                        let supplierOrderId = row.value;
                                        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId)
                                        let conditionnements = supplierOrder ? _.sum(_.map(supplierOrder.supplierOrderProductSuppliers, d => d.packQuantity)) : 0;
                                        return (
                                            <div style={{paddingLeft: 8}}>{conditionnements}</div>
                                        )
                                    }
                                },
                                {
                                    id: "unites",
                                    Header: <div>{getText( "ReceptionUnits")}</div>,
                                    accessor: d => this.getTotalUnitsNumber(d.supplierOrderId),
                                },
                                {
                                    id: "BPWT",
                                    Header: <div>{getText( "OrderPAHT")}</div>,
                                    accessor: d => d.supplierOrderId,
                                    Cell: row => {
                                        let supplierOrderId = row.value;
                                        return (
                                            <div style={{paddingLeft: 8}}>{this.getTotalOrder(supplierOrderId).toFixed(2)}</div>
                                        )
                                    }
                                },                        
                                {
                                    id: "receptionned",
                                    Header: <div>{getText( "OrderRecive")}</div>,
                                    accessor: d => d.supplierOrderId,
                                    Cell: row => {
                                        let supplierOrderId = row.value;
                                        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId)
                                        return <div style={{paddingLeft: 8}}>{supplierOrder.status === "Received" ? "Vrai" : "Faux"} </div>;
                                    }
                                },
                                {
                                    id: "produits",
                                    Header: <div>{getText( "OrderProductsTitle")}</div>,
                                    accessor: d => d.supplierOrderId,
                                    Cell: row => {
                                        let supplierOrderId = row.value;
                                        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId === supplierOrderId)
                                        let supplierOrderProductSuppliersLength = supplierOrder ? supplierOrder.supplierOrderProductSuppliers.length : 0;
                                        return (
                                            <div>
                                                <button className="btn btn-sm btn-primary"
                                                    style={{ fontSize: 13, width: 24, height: 24, padding: 2 }}
                                                    disabled={supplierOrderProductSuppliersLength <= 0}
                                                    onClick={(e) => {
                                                        this.handleSupplierOrderSelectedId(supplierOrderId);                                                        
                                                    }}>
                                                    <i className="glyphicon glyphicon-search"></i>
                                                </button>
                                            </div>
                                        )
                                    }
                                }
                                /*{
                                    id: "receptionned",
                                    Header: <div>Réceptionnée</div>,
                                    accessor: d => d.supplierOrderId,
                                    Cell: row => {
                                                let data = row.value as Api.SupplierOrderProductSupplierModel;
                                                return <div>
                                                    <button className="btn btn-sm btn-secondary"
                                                        style={{ backgroundColor: "white", color: "black", border: "1px solid" }}
                                                        disabled={this.props.validateSupplierOrder.isLoading}
                                                        onClick={(e) => this.props.requestValidateReception(new Date().getTime(), data.supplierOrderProductSupplierId)}>
                                                        Valider reception
                                                    </button>
                                                </div>;
                                            }
                                }*/
                            ] as Array<SupplierOrderColumn>} />
                   </div>
                </div>
                <DialogModal contentLabel={"supplierOrderProductSuppliers"}
                    contentStyle={{
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);", 
                        minWidth: 1000, 
                        maxHeight: 600,
                        overflow: 'hidden'
                    }}
                    title={this.supplierOrderSelected(this.props.supplierOrderSelectedId) ? this.supplierOrderSelected(this.props.supplierOrderSelectedId).name + " - " + getText("OrderPAHT") + ": " + this.getTotalOrder(this.props.supplierOrderSelectedId).toFixed(2) : ''}
                    isOpen={this.props.isOpen}
                    onRequestClose={() => this.props.closeSupplierOrderDialog()}>
                    {this.supplierOrderSelected(this.props.supplierOrderSelectedId) && <div id={"suppOrder-container"}>
                        <div style={{ textAlign: "end" }}>
                            <button type={"button"}
                                    className="btn btn-primary"
                                    style={{width: 110, height: 40, margin: 8}}
                            onClick={(e) => {
                                const options = {
                                    fieldSeparator: ';',
                                    quoteStrings: '"',
                                    decimalSeparator: ',',
                                    showLabels: false,
                                    showTitle: false,
                                    filename: `${getText("ReceptionOrders")}-${this.supplierOrderSelected(this.props.supplierOrderSelectedId) ? this.supplierOrderSelected(this.props.supplierOrderSelectedId).name : ""}-${Moment().format('YYYYMMDDHHmm')}`,
                                    useTextFile: false,
                                    useBom: true,
                                    useKeysAsHeaders: false,
                                    headers: this.csvData().headers,
                                };
                                const csvExporter = new ExportToCsv(options);
                                csvExporter.generateCsv(this.csvData().data);  
                            }}>
                                {getText( "HistoExport")}
                                <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                            </button>
                        </div>
                        <div>
                            <Table
                                style={{height: '50vh'}}
                                showPagination={false}
                                showPaginationBottom={false}
                                pageSize={Math.max(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId).length, 8)}
                                data={this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId)}
                                columns={[
                                    {
                                        id: "productId",
                                        style: {textAlign: 'left'},
                                        accessor: d => this.props.storeType == "Ximiti" ? d.productId : d.externalProductId,
                                        minWidth: 150,
                                        Header: <div>{getText( "OrderProductTitle")}</div>,
                                        Cell: row => <div>{this.props.storeType == "Ximiti" ? this.props.seed.products[row.value].name : this.props.seed.externalProducts[row.value].name}</div>
                                    },
                                    {
                                        id: "productSupplierId",
                                        accessor: d => d,
                                        maxWidth: 100,
                                        Header: <div>{getText( "OrderSource")}</div>,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            return <div>{this.props.seed.suppliers[data.productSupplier.supplierMainId].name}</div>;
                                        }
                                    },
                                    {
                                        id: "unites",
                                        Header: <div>{getText("ReceptionUnits")}</div>,
                                        accessor: d => d,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            let packQuantity = data.packQuantity;
                                            let packCondition = data.productSupplier.packCondition;
                                            return <div>{(packQuantity * packCondition)}</div>;
                                        }
                                    },
                                    {
                                        id: "pack",
                                        accessor: d => d,
                                        maxWidth: 100,
                                        Header: <div>{getText( "OrderPack")}</div>,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            return <div>{data.productSupplier.packCondition}</div>;
                                        }
                                    },
                                    {
                                        id: "quantity",
                                        accessor: d => d,
                                        maxWidth: 80,
                                        Header: <div>{getText( "OrderPackNumber")}</div>,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            return (
                                                <div> {data.packQuantity}
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        id: "packPrice",
                                        accessor: d => d,
                                        maxWidth: 100,
                                        Header: <div>{getText( "OrderPAHTPack")}</div>,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            return <div>{data.productSupplier.buyPriceNoVat} {this.props.seed.currencies[data.productSupplier.buyPriceCurrencyId].symbol}</div>;
                                        }
                                    },
                                    {
                                        id: "total",
                                        accessor: d => d,
                                        maxWidth: 100,
                                        Header: <div>{getText( "OrderTotal")}</div>,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            return (
                                                <div>
                                                    {(Math.round(data.packQuantity * data.productSupplier.buyPriceNoVat * 100) / 100).toFixed(2)}
                                                    {" "}
                                                    {this.props.seed.currencies[data.productSupplier.buyPriceCurrencyId].symbol}
                                                </div>
                                            );
                                        }
                                    }
                                ] as Array<SupplierOrderProductSupplierColumn>} />
                        </div>
                    </div>}
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.supplierOrder,
    seed: state.seed.seed,
    currencies: state.seed.seed.currencies,
    products: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? state.seed.seed.products : state.seed.seed.externalProducts,
    suppliers: state.seed.seed.suppliers,
    units: state.seed.seed.units,
    vats: state.seed.seed.vats,
    isSupplierOrderOpen: state.supplierOrder.isOpen,
    storeType : state.seed.seed.storeAppSettings.typeStore
} as SupplierOrdersOwnProps), { 
    ...SupplierOrderStore.actionCreators,
    ...ReorderStore.actionCreators,
    ...StockStore.actionCreators
})(SupplierOrders as any);