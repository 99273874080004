import * as React from "react";
import * as Api from "../api/api";
import * as _ from "lodash";
import { getText } from "../utils/texts";

interface RobotCoordCalculatorProps {
    stockStatus: Api.StockStatusModel;
    railCoordState: {
        railCoord: Api.RailCoordModel;
        robotCoord: Api.RobotCoordModel;
        isLoading: boolean;
    }
    setRailCoord: (coord: Api.RailCoordModel) => void;
    requestRailCoordToRobotCoord: (requestTime: number) => void;
    copyRobotCoord: () => void;
}

const btnShevlingStyle: React.CSSProperties = { height: 12, width: 12, fontSize: 10, cursor: "pointer" };
export default class RobotCoordCalculator extends React.Component<RobotCoordCalculatorProps, {}>{
    public render() {
        return (
            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(10, 1fr)",
                gridTemplateRows: "2fr 1fr 1fr"
            }}>
                {this.props.stockStatus && <div style={{ gridRow: "1 / 2", gridColumn: "1 / 11" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "stretch", height: "100%" }}>   
                        <div style={{writingMode: 'vertical-lr', textOrientation: 'upright'}}>
                            <h6 style={{fontWeight: 'bolder'}}>{getText("MaintenanceCoordCalDelivery")}</h6>
                        </div>
                        {_.values(this.props.stockStatus.shelvings)
                            .map(shelving => {
                                let planogramId = _.sortBy(this.props.stockStatus.planograms, x => !x.active)[0].planogramId;
                                let highestTrayLevel = _.max(this.props.stockStatus.shelvingTrayPlacements
                                    .filter(x => x.shelvingId === shelving.shelvingId
                                        && x.planogramId === planogramId)
                                    .map(x => x.level));

                                return (
                                    <div key={shelving.shelvingId} style={{
                                    flex: 1,
                                    borderTop: "1px solid yellow",
                                    borderBottom: "1px solid yellow",
                                    borderLeft: "1px solid gray",
                                    borderRight: "1px solid gray",
                                    padding: 5,
                                    margin: 3,
                                    position: "relative"
                                }}>
                                    <div style={{
                                        position: "absolute", top: 0, left: 0,
                                        ...btnShevlingStyle
                                    }} onClick={(e) => this.props.setRailCoord({
                                        r: parseInt(shelving.name),
                                        a: highestTrayLevel ? highestTrayLevel : 28,
                                        d: 1,
                                        f: 4
                                    })}>
                                        {highestTrayLevel ? highestTrayLevel : 28}
                                    </div>
                                    <div style={{
                                        position: "absolute", top: 0, right: 0,
                                        ...btnShevlingStyle
                                    }} onClick={(e) => this.props.setRailCoord({
                                        r: parseInt(shelving.name),
                                        a: 28,
                                        d: 20,
                                        f: 24
                                    })}>
                                        {24}
                                    </div>
                                    <h1 style={{marginTop: '20%'}}>{shelving.name}</h1>
                                    <div style={{
                                        position: "absolute", bottom: 0, right: 0,
                                        ...btnShevlingStyle
                                    }} onClick={(e) => this.props.setRailCoord({
                                        r: parseInt(shelving.name),
                                        a: 1,
                                        d: 20,
                                        f: 24
                                    })}>
                                        {24}
                                    </div>
                                    <div style={{
                                        position: "absolute", bottom: 0, left: 0,
                                        ...btnShevlingStyle
                                    }} onClick={(e) => this.props.setRailCoord({
                                        r: parseInt(shelving.name),
                                        a: 1,
                                        d: 1,
                                        f: 4
                                    })}>
                                        {1}
                                    </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>}
                <div style={{ gridRow: "2 / 3", gridColumn: "1 / 5", textAlign: "center" }}>
                    {getText("MaintenanceCoordCalProd")}
                        </div>
                <div style={{ gridRow: "2 / 3", gridColumn: "5 / 7", textAlign: "center" }}>
                    <button style={{}}
                        disabled={this.props.railCoordState.isLoading}
                        onClick={(e) => { this.props.requestRailCoordToRobotCoord(new Date().getTime()) }}>
                        {getText("MaintenanceCoordCalculerButton")}
                            </button>
                </div>
                <div style={{ gridRow: "2 / 3", gridColumn: "7 / 9", textAlign: "center" }}>
                    {/*Coordonnées*/}
                        </div>
                <div style={{ gridRow: "2 / 3", gridColumn: "9 / 11", textAlign: "center" }}>
                    {/*Bandes*/}
                        </div>
                <div style={{
                    gridRow: "3 / 4",
                    gridColumn: "1 / 2",
                    display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                    <div>
                        <div>R</div>
                        <div>
                            <input type={"text"}
                                style={{ width: 60 }}
                                onChange={(e) => this.props.setRailCoord({ ...this.props.railCoordState.railCoord, r: parseInt(e.target.value) || 0 })}
                                value={this.props.railCoordState.railCoord.r} />
                        </div>
                    </div>
                </div>
                <div style={{
                    gridRow: "3 / 4",
                    gridColumn: "2 / 3",
                    display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                    <div>
                        <div>A</div>
                        <div>
                            <input type={"text"}
                                style={{ width: 60 }}
                                onChange={(e) => this.props.setRailCoord({ ...this.props.railCoordState.railCoord, a: parseInt(e.target.value) || 0 })}
                                value={this.props.railCoordState.railCoord.a} />
                        </div>
                    </div>
                </div>
                <div style={{
                    gridRow: "3 / 4",
                    gridColumn: "3 / 4",
                    display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                    <div>
                        <div>D</div>
                        <div>
                            <input type={"text"}
                                style={{ width: 60 }}
                                onChange={(e) => this.props.setRailCoord({ ...this.props.railCoordState.railCoord, d: parseInt(e.target.value) || 0 })}
                                value={this.props.railCoordState.railCoord.d} />
                        </div>
                    </div>
                </div>
                <div style={{
                    gridRow: "3 / 4",
                    gridColumn: "4 / 5",
                    display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                    <div>
                        <div>F</div>
                        <div>
                            <input type={"text"}
                                style={{ width: 60 }}
                                onChange={(e) => this.props.setRailCoord({ ...this.props.railCoordState.railCoord, f: parseInt(e.target.value) || 0 })}
                                value={this.props.railCoordState.railCoord.f} />
                        </div>
                    </div>
                </div>
                <div style={{
                    gridRow: "3 / 4",
                    gridColumn: "5 / 7",
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "flex-end"
                }}>
                    <button style={{}}
                        onClick={(e) => { this.props.copyRobotCoord() }}>
                        {getText("MaintenanceCoordCalCopyVal")}
                            </button>
                </div>
                <div style={{
                    gridRow: "3 / 4", gridColumn: "7 / 8",
                    display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                    <div>
                        <div>X</div>
                        <div>
                            <input type={"text"}
                                style={{ width: 60 }}
                                disabled
                                value={this.props.railCoordState.robotCoord.x} />
                        </div>
                    </div>
                </div>
                <div style={{
                    gridRow: "3 / 4", gridColumn: "8 / 9",
                    display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                    <div>
                        <div>Z</div>
                        <div>
                            <input type={"text"}
                                style={{ width: 60 }}
                                disabled
                                value={this.props.railCoordState.robotCoord.z} />
                        </div>
                    </div>
                </div>
                <div style={{ gridRow: "3 / 4", gridColumn: "9 / 11" }}>
                </div>
            </div>
            );
    }
}