import * as React from "react";
import Tooltip from "./Tooltip";
import { CSSProperties } from "react";

interface SubmitButtonProps {
    type?: string;
    children: JSX.Element | string;
    className?: string;
    style?: CSSProperties;
    disabled: boolean;
    disablingMessages?: Array<string>;
    onClick?: () => void;
}

export class SubmitButton extends React.Component<SubmitButtonProps, {}> {
    public render() {
        return (
            <Tooltip overlay={<div>{this.props.disablingMessages
                && this.props.disablingMessages.map(x => <div>{x}</div>)}</div>}
                trigger={this.props.disablingMessages
                    && this.props.disablingMessages.length !== 0 ? ["hover"] : []}>
                <button className={this.props.className || "btn btn-primary"}
                    type={this.props.type as any}
                    style={this.props.style}
                    onClick={(e) => {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }
                    }}
                    disabled={this.props.disabled
                        || (this.props.disablingMessages && this.props.disablingMessages.length !== 0)}>
                    {this.props.children}
                </button>
            </Tooltip>
        );
    }
}