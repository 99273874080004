import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import {calculateProductDepth, getDefaultHeaders} from '../utils/utils';
import { ReceiveValidateSupplierOrder } from "./SupplierOrder";
import {
    PlanogramReceiveDeleteProductRail,
    PlanogramReceiveCreateProductRail,
    PlanogramReceiveUpdateActivePlanogram,
    PlanogramReceiveDeletePlanogram,
    PlanogramReceiveDeleteShelvingTrayPlacement
} from "./Planogram";
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { PlanogramReceiveCreatePlanogram, PlanogramReceiveCreateShelvingTrayPlacement } from "./Planogram";
import * as Notification from "react-notification-system-redux";
import { ReceiveCreateReceptions, ReceiveUpdateQuantityRemaining, ReceiveDeleteQuantityRemaining } from './Reception';
import * as ReorderStore from "./Reorder";
import { getText } from '../utils/texts';
import Moment from "moment";
import {getCurrentLang} from "../utils/language";

export interface StockState {
    isLoading: boolean;
    requestTime?: number;
    loadedTime?: number;
    stockStatus?: Api.StockStatusModel;
    createStock: CreateStockState;
    stockFilter: {
        supplierMainId?: number;
        reference?: string;
        expirityType?: Api.ExternalProductModelExpirityTypeEnum;
    },
    storeStockState: {
        [productRailId: number]: number;
    }
    //createReception: {
    //    isLoading: boolean;
    //    requestTime?: number;
    //};
    warnPercentage: number;
    selectedShelvingId?: number;
    selectedShelvingTrayId?: number;
    createShelving: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateShelving: {
        isLoading: boolean;
        requestTime?: number;
    };
    deleteShelving: {
        isLoading: boolean;
        requestTime?: number;
    };
    createShelvingTray: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateShelvingTray: {
        isLoading: boolean;
        requestTime?: number;
    };
    deleteShelvingTray: {
        isLoading: boolean;
        requestTime?: number;
    };
    dialogOpen: {
        isOpen?: boolean;
        message?: string;
    };
}

export interface CreateStockState {
    isLoading: boolean;
    requestTime?: number;
    productRailId?: number;
    stockArr?: number;
    actionStock?: ActionStockEnum,
    isOpen: boolean;
}
export type ActionStockEnum = 'create' | 'delete' | 'fill';


interface RequestStockStatus {
    type: "REQUEST_STOCKSTATUS";
    payload: { requestTime: number };
}
export interface ReceiveStockStatus {
    type: "RECEIVE_STOCKSTATUS";
    payload: {
        requestTime: number;
        stockStatus?: Api.StockStatusModel
    };
    error?: any;
}

interface OpenCreateStock {
    type: "OPEN_CREATE_STOCK";
    payload: { productRailId: number, stockArr?: number };
}
interface CloseCreateStock {
    type: "CLOSE_CREATE_STOCK";
}
interface RequestActionProductStock {
    type: "REQUEST_ACTION_PRODUCTSTOCK";
    payload: {
        actionStock: ActionStockEnum
    }
}
interface RequestCreateProductStock {
    type: "REQUEST_CREATE_PRODUCTSTOCK";
    payload: { requestTime: number; }
}
interface RequestDeleteProductStock {
    type: "REQUEST_DELETE_PRODUCTSTOCK",
    payload: {requestTime:number}
}
interface ReceiveCreateProductStock {
    type: "RECEIVE_CREATE_PRODUCTSTOCK";
    payload: {
        requestTime: number;
        result?: Api.CreateProductStockResultModel;
    };
    error?: any;
}
interface DeleteCreateProductStock {
    type: "RECEIVE_DELETE_PRODUCTSTOCK";
    payload: {
        requestTime: number;
        result?: Api.DeleteProductStockResultModel;
    };
    error?: any;
}

interface UpdateStockWarnPercentage {
    type: "UPDATE_STOCK_WARN_PERCENTAGE";
    payload: { value: number };
}

interface UpdateSupplierMainId {
    type: "UPDATE_SUPPLIERMAINID";
    payload: { value: number; }
}

interface UpdateReference {
    type: "UPDATE_REFERENCE";
    payload: { value: string; }
}

interface UpdateExpirityType {
    type: "UPDATE_EXPIRITYTYPE";
    payload: { value: Api.ProductExpirityTypeEnum; }
}

interface SelectShelving {
    type: "SELECT_SHELVING";
    paylod: { value: number }
}
interface SelectShelvingTray {
    type: "SELECT_SHELVINGTRAY";
    paylod: { value: number }
}

interface RequestCreateShelving {
    type: "REQUEST_CREATE_SHELVING";
    payload: { requestTime: number; }
}
interface ReceiveCreateShelving {
    type: "RECEIVE_CREATE_SHELVING";
    payload: { requestTime: number; value?: Api.ShelvingModel; };
    error?: any;
}

interface RequestCreateShelvingsAuto {
    type: "REQUEST_CREATE_SHELVINGS_AUTO";
    payload: { requestTime: number; }
}
interface ReceiveCreateShelvingsAuto {
    type: "RECEIVE_CREATE_SHELVINGS_AUTO";
    payload: { requestTime: number; shelvings?:  { [key: string]: Api.ShelvingModel; } };
    error?: any;
}

interface RequestUpdateShelving {
    type: "REQUEST_UPDATE_SHELVING";
    payload: { requestTime: number; }
}
interface ReceiveUpdateShelving {
    type: "RECEIVE_UPDATE_SHELVING";
    payload: { requestTime: number; value?: Api.ShelvingModel; };
    error?: any;
}

interface RequestUpdateShelvings {
    type: "REQUEST_UPDATE_SHELVINGS";
    payload: { requestTime: number; }
}
interface ReceiveUpdateShelvings {
    type: "RECEIVE_UPDATE_SHELVINGS";
    payload: { requestTime: number; value?: { [key: string]: Api.ShelvingModel; } };
    error?: any;
}

interface RequestCalculatedOffsets {
    type: "REQUEST_CALCULATED_OFFSETS";
    payload: { requestTime: number; }
}
interface ReceiveCalculatedOffsets {
    type: "RECEIVE_CALCULATED_OFFSETS";
    payload: { requestTime: number; value?: { [key: string]: Api.ShelvingModel; } };
    error?: any;
}

interface RequestDeleteShelving {
    type: "REQUEST_DELETE_SHELVING";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeleteShelving {
    type: "RECEIVE_DELETE_SHELVING";
    payload: { requestTime: number; id: number; };
    error?: any;
}


interface RequestCreateShelvingTray {
    type: "REQUEST_CREATE_SHELVINGTRAY";
    payload: { requestTime: number; }
}
interface ReceiveCreateShelvingTray {
    type: "RECEIVE_CREATE_SHELVINGTRAY";
    payload: { requestTime: number; value?: Api.ShelvingTrayModel; };
    error?: any;
}

interface RequestCreateShelvingsTrayAuto {
    type: "REQUEST_CREATE_SHELVINGSTRAY_AUTO";
    payload: { requestTime: number; }
}
interface ReceiveCreateShelvingsTrayAuto {
    type: "RECEIVE_CREATE_SHELVINGSTRAY_AUTO";
    payload: { requestTime: number; shelvingsTray?:  { [key: string]: Api.ShelvingTrayModel; } };
    error?: any;
}

interface RequestUpdateShelvingTray {
    type: "REQUEST_UPDATE_SHELVINGTRAY";
    payload: { requestTime: number; }
}
interface ReceiveUpdateShelvingTray {
    type: "RECEIVE_UPDATE_SHELVINGTRAY";
    payload: { requestTime: number; value?: Api.ShelvingTrayModel; };
    error?: any;
}

interface RequestDeleteShelvingTray {
    type: "REQUEST_DELETE_SHELVINGTRAY";
    payload: { requestTime: number; id: number; }
}
interface ReceiveDeleteShelvingTray {
    type: "RECEIVE_DELETE_SHELVINGTRAY";
    payload: { requestTime: number; id: number; };
    error?: any;
}
interface ProductRailUpdateStoredStock {
    type: "PRODUCTRAIL_UPDATE_STOREDSTOCK";
    payload: { productRailId: number; value: number; };
}
interface OpenErrorDialogModal {
    type: "OPEN_ERROR_MODAL";
    payload: { message: string }
}
interface CloseErrorDialogModal {
    type: "CLOSE_ERROR_MODAL";
}


//We dont want duplicate of data nor a gigantic store so we apply aaction that modify stock element here
type KnownAction = RequestStockStatus
    | ReceiveStockStatus
    | RequestActionProductStock
    | RequestCreateProductStock
    | RequestDeleteProductStock
    | ReceiveCreateProductStock
    | DeleteCreateProductStock
    | OpenCreateStock | CloseCreateStock
    | PlanogramReceiveDeleteProductRail
    | PlanogramReceiveCreateProductRail
    | PlanogramReceiveUpdateActivePlanogram
    | UpdateStockWarnPercentage
    | PlanogramReceiveCreatePlanogram
    | RequestCreateShelving
    | ReceiveCreateShelving
    | RequestUpdateShelving
    | ReceiveUpdateShelving
    | RequestDeleteShelving
    | ReceiveDeleteShelving
    | RequestCreateShelvingTray
    | ReceiveCreateShelvingTray
    | RequestUpdateShelvingTray
    | ReceiveUpdateShelvingTray
    | RequestDeleteShelvingTray
    | ReceiveDeleteShelvingTray
    | SelectShelving
    | SelectShelvingTray
    | PlanogramReceiveDeletePlanogram
    | PlanogramReceiveCreateShelvingTrayPlacement
    | PlanogramReceiveDeleteShelvingTrayPlacement
    | ReceiveValidateSupplierOrder
    | ReceiveCreateReceptions
    | ReceiveUpdateQuantityRemaining
    | ReceiveDeleteQuantityRemaining
    | ProductRailUpdateStoredStock
    | UpdateSupplierMainId
    | UpdateReference
    | RequestUpdateShelvings
    | ReceiveUpdateShelvings
    | RequestCalculatedOffsets
    | ReceiveCalculatedOffsets
    | OpenErrorDialogModal
    | CloseErrorDialogModal
    | UpdateExpirityType
    | RequestCreateShelvingsAuto
    | ReceiveCreateShelvingsAuto
    | RequestCreateShelvingsTrayAuto
    | ReceiveCreateShelvingsTrayAuto
    ;

const unitsSelector = (state: ApplicationState) => state.seed.seed.units;
const productsSelector = (state: ApplicationState) => state.seed.seed.products;
const stockStatusSelector = (state: ApplicationState) => state.stock.stockStatus;
const externalProductsSelector = (state: ApplicationState) => state.seed.seed.externalProducts;
const storeTypeSelector = (state: ApplicationState) => state.seed.seed.storeAppSettings.typeStore;
const itemOfferResultSelector = (state: ApplicationState) => state.offer.itemOfferResult;
const promotionsSelector = (state: ApplicationState) => state.seed.seed.promotions;
const pricePeriodsSelector = (state: ApplicationState) => state.pricePeriod.pricePeriods;
const productRailsSelector = (state: ApplicationState) => {
    if(!state.stock.stockStatus)
        return [];
    
   return state.stock.stockStatus.productRails;
};
const planoIdSelector = (state: ApplicationState) => {
    if(!state.stock.stockStatus)
        return 0;

    return _.sortBy(
        state.stock.stockStatus.planograms, x => !x.active)[0].planogramId;
};
const stockFilterSelector = (state: ApplicationState) => state.stock.stockFilter;

export const productRailsInPlanoSelector = createSelector(productRailsSelector, planoIdSelector, (productRails, planoId) => {
    return productRails
        .filter(x => x.planogramId === planoId);
});

export const productRailsById = createSelector(productRailsSelector, planoIdSelector, (productRails, planoId) => {
    console.log("productRailsById calc");
    return _.keyBy(productRails
        .filter(x => x.planogramId === planoId), x => x.productRailId);
});

export const formatRadf = (radf: string): string => {
    let splitRADF = radf.split("-");
    let r = ('0' + splitRADF[0]).slice(-3);
    let a = ('0' + splitRADF[1]).slice(-2);
    let d = ('0' + splitRADF[2]).slice(-2);
    let f = ('0' + splitRADF[3]).slice(-2);
    return r + "-" + a + "-" + d + "-" + f;
}
export type ProductOfferState = "Normal" | "Potd" | "Offer" | "TimedOffer" | "Expirity";
export const getProductOfferState = (productRail: Api.ProductRailModel
                                  , itemOfferResult: Api.ItemOfferResultModel
                                  , products: { [id: number] : Api.ProductModel }
                                , promotions: { [id: number] : Api.PromotionModel }
                                , pricePeriods: { [id: number] : Api.PricePeriodModel }) 
    : ProductOfferState => {
    let product = products[productRail.productId];

    if(itemOfferResult && itemOfferResult.itemOfTheDay){
        let isProductOfTheDay = itemOfferResult.itemOfTheDay.storeItemId === product.storeItemId;
        if (isProductOfTheDay)
            return "Potd";
    }

    let itemOffer = itemOfferResult
        && itemOfferResult.itemPromotions[product.storeItemId]
        && itemOfferResult.itemPromotions[product.storeItemId]
            .some(x => promotions[x.promotionId] !== undefined);
    if (itemOffer)
        return "Offer";

    let isInPricePeriod = _.values(pricePeriods)
        .some(x => x.pricePeriodStoreItems
            .some(y => y.storeItemId === product.storeItemId));
    if (isInPricePeriod)
        return "TimedOffer";

    let isDdmOrDlc = itemOfferResult
        && itemOfferResult.itemExpirityOffers[product.storeItemId]
        && itemOfferResult.itemExpirityOffers[product.storeItemId][0];
    let expirityType = product.expirityType;
    if ((isDdmOrDlc && (expirityType === "Normal" || expirityType === "Ddm" )))
        return "Expirity";

    return "Normal";
};

export interface StockProductData {
    name?: string,
    volumeUnit?: string,
    practicUnit?: string,
    stockUp?: number,
    backStockUp?: number,
    expirityType?: Api.ProductExpirityTypeEnum,
    stockNumber?: number,
    radf?: string,
    stockState?: number,
    days?: string,
    date?: string,
    backStock?: number,
    productRailId: number;
    storeItemId?: number;
    offerState?: ProductOfferState;
}

export const stockFilteredProductRailSelector = createSelector(
    productRailsSelector, planoIdSelector, productsSelector
    , externalProductsSelector, stockFilterSelector, storeTypeSelector
    , unitsSelector, stockStatusSelector, itemOfferResultSelector
    , promotionsSelector, pricePeriodsSelector,
    (productRails, planoId, products, externalProducts, filter, storeType, units, stockStatus, itemOfferResult, promotions, pricePeriods) => {
    console.log("stock list updated")
    let productRailsFiltered = productRails.filter(x => x.planogramId === planoId);
    if (filter.supplierMainId) {
        productRailsFiltered = productRailsFiltered.filter(x => ReorderStore.getProductSupplier(products[x.productId].storeItemId, products) ?
            ReorderStore.getProductSupplier(products[x.productId].storeItemId, products).supplierMainId === filter.supplierMainId
            : ''
        )
    }
    if (filter.reference) {
        productRailsFiltered = productRailsFiltered.filter(x => ReorderStore.getProductSupplier(products[x.productId].storeItemId, products) ?
            ReorderStore.getProductSupplier(products[x.productId].storeItemId, products).reference === filter.reference
            : ''
        )
    }
    if (filter.expirityType) {
        productRailsFiltered = productRailsFiltered
            .filter(x => (products[x.productId]?.expirityType 
                || externalProducts[x.externalProductId]?.expirityType) === filter.expirityType)
    }
    productRailsFiltered = storeType == "Ximiti"
        ? productRailsFiltered.sort((x, y) => products[x.productId].name.localeCompare(products[y.productId].name))
        : productRailsFiltered.sort((x, y) => externalProducts[x.externalProductId].name.localeCompare(externalProducts[y.externalProductId].name));

    return productRailsFiltered.map(x => {
        let robotStock = x.productStocks.length;
        let backStock = _.sum(stockStatus.productReceptions
            .filter(y => (x.productId && y.productId === x.productId)
                || (x.externalProductId && y.externalProductId === x.externalProductId))
            .map(x => x.quantityRemaining));
        let fullStock = Math.floor(stockStatus.shelvingTrays[x.shelvingTrayId].depth
            / calculateProductDepth(storeType == "Ximiti" ? products[x.productId] : externalProducts[x.externalProductId], x.sens));
        let stateColor = (robotStock <= 0 && backStock <= 0) ?
            0
            : (robotStock <= 0 && backStock > 0) ?
                1
                : (robotStock == fullStock) ?
                    3
                    : (robotStock != 0 && backStock != 0) ?
                        2
                        :   3;

        let stockUpById = storeType == "Ximiti" 
            ? {} 
            : _.keyBy(_.map(_.groupBy(productRailsFiltered
            , x => x.externalProductId), xs => ({
            externalProductId: xs[0].externalProductId,
            stockUp: _.sumBy(xs, x => x.productStocks.length)
        })), x => x.externalProductId);
        
        let externalStock = storeType === "Ximiti" ? {} : {
            name: externalProducts[x.externalProductId].name,
            volumeUnit: `units[externalProducts[x.externalProductId].volumeUnitId].name (${externalProducts[x.externalProductId].conversionFactor})`,
            practicUnit: units[externalProducts[x.externalProductId].practicUnit].name,
            stockUp: stockUpById[x.externalProductId].stockUp * externalProducts[x.externalProductId].conversionFactor,
            expirityType: externalProducts[x.externalProductId].expirityType,
            maxStock: Math.floor(stockStatus.shelvingTrays[x.shelvingTrayId].depth
                / calculateProductDepth(externalProducts[x.externalProductId], x.sens)),
            backStockUp: backStock * externalProducts[x.externalProductId].conversionFactor
        };
        let ximitiStock = storeType === "Ximiti" 
            ? {
                name: products[x.productId].name,
                offerState: getProductOfferState(x, itemOfferResult, products, promotions, pricePeriods),
                expirityType: products[x.productId].expirityType,
                maxStock: Math.floor(stockStatus.shelvingTrays[x.shelvingTrayId].depth
                    / calculateProductDepth(products[x.productId], x.sens))
            } : {};
        let railStock = {
            radf: formatRadf(`${x.r}-${x.a}-${x.d}-${x.f}`),
            stockState: stateColor,
            stockNumber: x.productStocks.length,
            days: x.productStocks.length > 0 && !x.productStocks[0].expirity
                ? "-"
                : (x.productStocks.length > 0
                    ?  Moment.utc(x.productStocks[0].expirity).diff(Moment().startOf('day'), 'days')
                    : '0'),
            date: x.productStocks.length > 0
                ? (x.productStocks[0].expirity ? Moment(x.productStocks[0].expirity).locale(getCurrentLang()).format("DD/MM/YY") : "-")
                : " ",
            backStock: backStock,
            productRailId: x.productRailId,
            storeItemId: x.productId || x.externalProductId
        };
        
        return (storeType === "Ximiti"
            ? { ...railStock, ...ximitiStock }
            : { ...railStock, ...externalStock }) as StockProductData
    });
});



export const availableProductsSelector = createSelector(productsSelector,
    stockStatusSelector,
    (products, stockStatus) => {
        if (!products || !stockStatus)
            return [];

        let activePlano = _.values(stockStatus.planograms).find(x => x.active);
        return _.uniqBy(_.values(stockStatus.productRails)
            .filter(x => activePlano && x.planogramId === activePlano.planogramId), x => x.productId)
            .map(x => products[x.productId]);
    });

export const requestStockStatus = (requestTime: number, dispatch: (a: any) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.StockApi();
    let fetch = api.getStockStatus(
        { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(stockStatus => {
            dispatch({
                type: "RECEIVE_STOCKSTATUS",
                payload: { requestTime: requestTime, stockStatus: stockStatus }
            });
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_STOCKSTATUS",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({ type: "REQUEST_STOCKSTATUS", payload: { requestTime: requestTime } });
    addTask(fetch);
    return fetch;
}

export const actionCreators = {
    requestStockStatus: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestStockStatus(requestTime, dispatch, getState);
    },
    requestActionProductStock: (action: ActionStockEnum): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_ACTION_PRODUCTSTOCK", payload: { actionStock: action } });
    },
    requestCreateProductStock: (requestTime: number, model: Api.CreateProductStocksModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.createProductStocks({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_CREATE_PRODUCTSTOCK",
                    payload: {
                        requestTime: requestTime,
                        result: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_PRODUCTSTOCK",
                    payload: { requestTime: requestTime },
                    error: err
                });

                if (err.text) {
                    err.text().then(x => {
                        dispatch({ type: "OPEN_ERROR_MODAL", payload: { message: x } });
                        //dispatch(Notification.error({ title: "Erreur", message: x, position: "tc", level: "error" }) as any);
                    });
                } else {
                    dispatch({ type: "OPEN_ERROR_MODAL", payload: { message: getText( "StockDialogErrorMsg") } });
                    //dispatch(Notification.error({ title: "Erreur", message: "Request failed", position: "tc", level: "error" }) as any);
                }
            });

        dispatch({ type: "REQUEST_CREATE_PRODUCTSTOCK", payload: { requestTime: requestTime } });
        addTask(fetch);
        return fetch;
    },
    requestDeleteProductStock: (requestTime: number, model: Api.DeleteProductStocksModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.deleteProductStocks({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_DELETE_PRODUCTSTOCK",
                    payload: {
                        requestTime: requestTime,
                        result: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_PRODUCTSTOCK",
                    payload: { requestTime: requestTime },
                    error: err
                });

                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
                    });
                } else {
                    dispatch(Notification.error({ title: getText("NotificationTitleError"), message: getText("NotificationRequestFailed"), position: "tc", level: "error" }) as any);
                }
            });

        dispatch({ type: "REQUEST_DELETE_PRODUCTSTOCK", payload: { requestTime: requestTime } });
        addTask(fetch);
        return fetch;
    },
    openCreateStock: (productRailId: number, stockArr?: number) => <OpenCreateStock>{
        type: "OPEN_CREATE_STOCK",
        payload: { productRailId: productRailId , stockArr: stockArr && stockArr}
    },
    closeCreateStock: () => <CloseCreateStock>{ type: "CLOSE_CREATE_STOCK" },
    updateWarnPercentage: (value: number) => <UpdateStockWarnPercentage>{
        type: "UPDATE_STOCK_WARN_PERCENTAGE",
        payload: { value: value }
    },
    requestCreateShelving: (requestTime: number, model: Api.ShelvingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.createShelving({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVING",
                    payload: {
                        requestTime: requestTime,
                        value: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_CREATE_SHELVING", payload: { requestTime: requestTime } });
        return fetch;
    },
    requestCreateShelvingAuto: (requestTime: number, qty: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.createShelvingsAuto({
            quantity: qty
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(results => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVINGS_AUTO",
                    payload: { requestTime: requestTime, shelvings: results }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVINGS_AUTO",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CREATE_SHELVINGS_AUTO",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateShelving: (requestTime: number, model: Api.ShelvingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.updateShelving({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_UPDATE_SHELVING",
                    payload: {
                        requestTime: requestTime,
                        value: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_SHELVING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_UPDATE_SHELVING", payload: { requestTime: requestTime } });
        return fetch;
    },
    requestUpdateShelvings: (requestTime: number, models: Array<Api.ShelvingModel>): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.updateShelvings({
            model: models
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_UPDATE_SHELVINGS",
                    payload: {
                        requestTime: requestTime,
                        value: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_SHELVINGS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_UPDATE_SHELVINGS", payload: { requestTime: requestTime } });
        return fetch;
    },
    requestCalculateOffsets: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.calculateOffsets(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_CALCULATED_OFFSETS",
                    payload: {
                        requestTime: requestTime,
                        value: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CALCULATED_OFFSETS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_CALCULATED_OFFSETS", payload: { requestTime: requestTime } });
        return fetch;
    },
    requestDeleteShelving: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.deleteShelving({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_DELETE_SHELVING",
                    payload: {
                        requestTime: requestTime,
                        id: id
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_SHELVING",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_DELETE_SHELVING", payload: { requestTime: requestTime, id: id } });
        return fetch;
    },
    requestCreateShelvingTray: (requestTime: number, model: Api.ShelvingTrayModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.createShelvingTray({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVINGTRAY",
                    payload: {
                        requestTime: requestTime,
                        value: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVINGTRAY",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_CREATE_SHELVINGTRAY", payload: { requestTime: requestTime } });
        return fetch;
    },
    requestCreateShelvingTrayAuto: (requestTime: number, qty: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetchTask = api.createShelvingsTrayAuto({
            quantity: qty
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(results => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVINGSTRAY_AUTO",
                    payload: { requestTime: requestTime, shelvingsTray: results }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_SHELVINGSTRAY_AUTO",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CREATE_SHELVINGSTRAY_AUTO",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateShelvingTray: (requestTime: number, model: Api.ShelvingTrayModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.updateShelvingTray({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_UPDATE_SHELVINGTRAY",
                    payload: {
                        requestTime: requestTime,
                        value: result
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_SHELVINGTRAY",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_UPDATE_SHELVINGTRAY", payload: { requestTime: requestTime } });
        return fetch;
    },
    requestDeleteShelvingTray: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.StockApi();
        let fetch = api.deleteShelvingTray({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(result => {
                dispatch({
                    type: "RECEIVE_DELETE_SHELVINGTRAY",
                    payload: {
                        requestTime: requestTime,
                        id: id
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_SHELVINGTRAY",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({ type: "REQUEST_DELETE_SHELVINGTRAY", payload: { requestTime: requestTime, id: id } });
        return fetch;
    },
    selectShelving: (value: number) => <SelectShelving>{
        type: "SELECT_SHELVING",
        paylod: { value: value }
    },
    selectShelvingTray: (value: number) => <SelectShelvingTray>{
        type: "SELECT_SHELVINGTRAY",
        paylod: { value: value }
    },
    productStockUpdateStoredStock: (productRailId:number, value: number) => <ProductRailUpdateStoredStock>{
        type: "PRODUCTRAIL_UPDATE_STOREDSTOCK",
        payload: { productRailId: productRailId, value: value }
    },
    updateSupplierMainId: (value: number) => <UpdateSupplierMainId>{
        type: "UPDATE_SUPPLIERMAINID",
        payload: { value: value }
    },
    updateReference: (value: string) => <UpdateReference>{
        type: "UPDATE_REFERENCE",
        payload: { value: value }
    },
    updateExpirityType: (value: Api.ProductExpirityTypeEnum) => <UpdateExpirityType>{
        type: "UPDATE_EXPIRITYTYPE",
        payload: { value: value }
    },
    closeErrorDialogModal: () => <CloseErrorDialogModal>{ type: "CLOSE_ERROR_MODAL" }
};

const unloadedState: StockState = {
    isLoading: false,
    createStock: {
        isLoading: false,
        isOpen: false,
        actionStock: undefined
    },
    stockFilter:{
    },
    storeStockState: {},
    //createReception: {
    //    isLoading: false,
    //},
    warnPercentage: 40,
    createShelving: {
        isLoading: false
    },
    updateShelving: {
        isLoading: false
    },
    deleteShelving: {
        isLoading: false
    },
    createShelvingTray: {
        isLoading: false
    },
    updateShelvingTray: {
        isLoading: false
    },
    deleteShelvingTray: {
        isLoading: false
    },
    dialogOpen: {
        isOpen: false
    }
};

export const reducer: Reducer<StockState> = (state: StockState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_STOCKSTATUS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_STOCKSTATUS":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                loadedTime: action.error
                    ? state.loadedTime
                    : action.payload.requestTime,
                stockStatus: action.error
                    ? state.stockStatus
                    : action.payload.stockStatus
            };
        case "OPEN_CREATE_STOCK":
            return {
                ...state,
                createStock: {
                    ...state.createStock,
                    isOpen: true,
                    productRailId: action.payload.productRailId,
                    stockArr: action.payload.stockArr && action.payload.stockArr
                }
            };
        case "CLOSE_CREATE_STOCK":
            return {
                ...state,
                createStock: {
                    ...state.createStock,
                    isOpen: false,
                }
            };
        case "REQUEST_ACTION_PRODUCTSTOCK":
            return {
                ...state,
                createStock: {
                    ...state.createStock,
                    actionStock: action.payload.actionStock
                }
            };
        case "REQUEST_CREATE_PRODUCTSTOCK":
            return {
                ...state,
                createStock: {
                    ...state.createStock,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "REQUEST_DELETE_PRODUCTSTOCK":
            return {
                ...state,
                createStock: {
                    ...state.createStock,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_PRODUCTSTOCK":

            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productRails: action.error
                        ? state.stockStatus.productRails
                        : state.stockStatus.productRails
                            .map(x => x.productRailId === action.payload.result.productStocks[0].productRailId
                                ? { ...x, productStocks: x.productStocks.concat(action.payload.result.productStocks) }
                                : x),
                    productReceptions: action.error
                        ? state.stockStatus.productReceptions
                        : (state.stockStatus.productReceptions
                            .map(x => {
                                let y = action.payload.result.productReceptions
                                    .find(y => x.productReceptionId == y.productReceptionId);
                                return y ? { ...x, ...y } : x;
                            })
                            .concat(action.payload.result.productReceptionCreated ? [action.payload.result.productReceptionCreated] : []))
                },
                createStock: {
                    ...state.createStock,
                    isLoading: false
                }
            };


        case "RECEIVE_DELETE_PRODUCTSTOCK":

            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productRails: action.error
                        ? state.stockStatus.productRails
                        : state.stockStatus.productRails
                            .map(x => x.productRailId === action.payload.result.productStocks[0].productRailId
                                ? { ...x, productStocks: x.productStocks.filter(x => !action.payload.result.productStocks.some(y => x.productStockId == y.productStockId)) }
                                : x),

                    productReceptions: action.error
                        ? state.stockStatus.productReceptions
                        : state.stockStatus.productReceptions
                            .map(x => {
                                let y = action.payload.result.productReceptions
                                    .find(y => x.productReceptionId == y.productReceptionId);
                                return y ? { ...x, ...y } : x;
                            })
                            .concat(action.payload.result.productReceptions
                                .filter(x => !state.stockStatus.productReceptions
                                    .some(y => y.productReceptionId === x.productReceptionId))),    

                },
                createStock: {
                    ...state.createStock,
                    isLoading: false
                }
            };
        case "PLANOGRAM_RECEIVE_DELETE_PRODUCTRAIL":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productRails: action.error
                        ? state.stockStatus.productRails
                        : state.stockStatus.productRails.filter(x => x.productRailId !== action.payload.productRailId),
                    productReceptions: action.error
                        ? state.stockStatus.productReceptions
                        : state.stockStatus.productReceptions.concat(action.payload.productReceptions)
                }
            };
        case "PLANOGRAM_RECEIVE_CREATE_PRODUCTRAIL":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productRails: action.error
                        ? state.stockStatus.productRails
                        : state.stockStatus.productRails.concat([action.payload.productRail]),
                }
            };
        case "PLANOGRAM_RECEIVE_ACTIVATE_PLANOGRAM":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    planograms: action.error
                        ? state.stockStatus.planograms
                        : state.stockStatus.planograms
                            .map(x => ({ ...x, active: x.planogramId === action.payload.planogramId })),
                }
            };
        case "UPDATE_STOCK_WARN_PERCENTAGE":
            return {
                ...state,
                warnPercentage: action.payload.value
            };
        case "PLANOGRAM_RECEIVE_CREATE_PLANOGRAM":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    planograms: action.error
                        ? state.stockStatus.planograms
                        : state.stockStatus.planograms.concat(action.payload.planogram)
                }
            };
        case "REQUEST_CREATE_SHELVING":
            return {
                ...state,
                createShelving: {
                    ...state.createShelving,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_SHELVING":
            if (state.createShelving.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createShelving: {
                    ...state.createShelving,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvings: {
                        ...state.stockStatus.shelvings,
                        [action.payload.value.shelvingId]: action.payload.value
                    }
                }
            };
        case "REQUEST_CREATE_SHELVINGS_AUTO":
            return {
                ...state,
                createShelving: {
                    ...state.createShelving,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_SHELVINGS_AUTO":
            if (state.createShelving.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createShelving: {
                    ...state.createShelving,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvings: action.payload.shelvings
                }
            };
        case "REQUEST_UPDATE_SHELVING":
            return {
                ...state,
                updateShelving: {
                    ...state.updateShelving,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_UPDATE_SHELVING":
            if (state.updateShelving.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateShelving: {
                    ...state.updateShelving,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvings: {
                        ...state.stockStatus.shelvings,
                        [action.payload.value.shelvingId]: action.payload.value
                    }
                }
            };
        case "REQUEST_UPDATE_SHELVINGS":
            return {
                ...state,
                updateShelving: {
                    ...state.updateShelving,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_UPDATE_SHELVINGS":
            if (state.updateShelving.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateShelving: {
                    ...state.updateShelving,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvings:  action.payload.value
                }
            };
        case "REQUEST_CALCULATED_OFFSETS":
            return {
                ...state,
                updateShelving: {
                    ...state.updateShelving,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CALCULATED_OFFSETS":
            if (state.updateShelving.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateShelving: {
                    ...state.updateShelving,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvings:  action.payload.value
                }
            };
        case "REQUEST_DELETE_SHELVING":
            return {
                ...state,
                deleteShelving: {
                    ...state.deleteShelving,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DELETE_SHELVING":
            if (state.deleteShelving.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                deleteShelving: {
                    ...state.deleteShelving,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvings: _.pickBy(state.stockStatus.shelvings, x => x.shelvingId !== action.payload.id)
                }
            };
        case "REQUEST_CREATE_SHELVINGTRAY":
            return {
                ...state,
                createShelvingTray: {
                    ...state.createShelvingTray,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_SHELVINGTRAY":
            if (state.createShelvingTray.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createShelvingTray: {
                    ...state.createShelvingTray,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvingTrays: {
                        ...state.stockStatus.shelvingTrays,
                        [action.payload.value.shelvingTrayId]: action.payload.value
                    }
                }
            };
        case "REQUEST_CREATE_SHELVINGSTRAY_AUTO":
            return {
                ...state,
                createShelvingTray: {
                    ...state.createShelvingTray,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_CREATE_SHELVINGSTRAY_AUTO":
            if (state.createShelvingTray.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createShelvingTray: {
                    ...state.createShelvingTray,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvingTrays: action.payload.shelvingsTray
                }
            };
        case "REQUEST_UPDATE_SHELVINGTRAY":
            return {
                ...state,
                updateShelvingTray: {
                    ...state.updateShelvingTray,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_UPDATE_SHELVINGTRAY":
            if (state.updateShelvingTray.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                updateShelvingTray: {
                    ...state.updateShelvingTray,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvingTrays: {
                        ...state.stockStatus.shelvingTrays,
                        [action.payload.value.shelvingTrayId]: action.payload.value
                    }
                }
            };
        case "REQUEST_DELETE_SHELVINGTRAY":
            return {
                ...state,
                deleteShelvingTray: {
                    ...state.deleteShelvingTray,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_DELETE_SHELVINGTRAY":
            if (state.deleteShelvingTray.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                deleteShelvingTray: {
                    ...state.deleteShelvingTray,
                    isLoading: false
                },
                stockStatus: action.error ? state.stockStatus : {
                    ...state.stockStatus,
                    shelvingTrays: _.pickBy(state.stockStatus.shelvingTrays, x => x.shelvingTrayId !== action.payload.id)
                }
            };
        case "SELECT_SHELVING":
            return {
                ...state,
                selectedShelvingId: action.paylod.value
            };
        case "SELECT_SHELVINGTRAY":
            return {
                ...state,
                selectedShelvingTrayId: action.paylod.value
            };
        case "PLANOGRAM_RECEIVE_DELETE_PLANOGRAM":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    planograms: state.stockStatus.planograms.filter(x => x.planogramId !== action.payload.id)
                }
            };
        case "PLANOGRAM_RECEIVE_CREATE_SHELVINGTRAYPLACEMENT":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    shelvingTrayPlacements: action.error
                        ? state.stockStatus.shelvingTrayPlacements
                        : state.stockStatus.shelvingTrayPlacements.concat([action.payload.value])
                }
            };
        case "PLANOGRAM_RECEIVE_DELETE_SHELVINGTRAYPLACEMENT":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    shelvingTrayPlacements: action.error
                        ? state.stockStatus.shelvingTrayPlacements
                        : state.stockStatus.shelvingTrayPlacements
                            .filter(x => x.shelvingTrayPlacementId !== action.payload.shelvingTrayPlacementId)
                }
            };
        case "RECEIVE_VALIDATE_SUPPLIERORDER":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productReceptions: {
                        ...state.stockStatus.productReceptions,
                        productReceptions: action.error
                            ? state.stockStatus.productReceptions
                            : state.stockStatus.productReceptions
                                .concat([action.payload.productReception]),
                    }
                }
            };
        case "RECEIVE_CREATE_RECEPTIONS":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productReceptions: action.error
                        ? state.stockStatus.productReceptions
                        : state.stockStatus.productReceptions.concat(action.payload.productReceptions),
                }
            };
        case "RECEIVE_UPDATE_QUANTITYREMAINING":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productReceptions: action.error
                        ? state.stockStatus.productReceptions
                        : state.stockStatus.productReceptions
                            .map(x => {
                                let updated = action.payload.productReceptions.find(y => y.productReceptionId === x.productReceptionId);
                                return updated ? updated : x;
                            })
                            .concat(action.payload.productReceptions
                                .filter(x => !state.stockStatus.productReceptions
                                    .some(y => y.productReceptionId === x.productReceptionId))),
                },
                storeStockState: {}
            };
        case "PRODUCTRAIL_UPDATE_STOREDSTOCK":

            return {
                ...state,
                storeStockState: {
                    ...state.storeStockState,
                    [action.payload.productRailId]: action.payload.value
                }
            };
        case "RECEIVE_DELETE_QUANTITYREMAINING":
            return {
                ...state,
                stockStatus: {
                    ...state.stockStatus,
                    productReceptions: action.error
                        ? state.stockStatus.productReceptions
                        : state.stockStatus.productReceptions
                            .map(x => {
                                let updated = action.payload.productReceptions.find(y => y.productReceptionId === x.productReceptionId);
                                return updated ? updated : x;
                            })
                            .concat(action.payload.productReceptions
                                .filter(x => !state.stockStatus.productReceptions
                                    .some(y => y.productReceptionId === x.productReceptionId))),
                },
                storeStockState: {}
            };
        case "UPDATE_SUPPLIERMAINID":
            return {
                ...state,
                stockFilter: {
                    ...state.stockFilter,
                    supplierMainId: action.payload.value
                }
            };

        case "UPDATE_REFERENCE":
            return {
                ...state,
                stockFilter: {
                    ...state.stockFilter,
                    reference: action.payload.value
                }
            };
        case "UPDATE_EXPIRITYTYPE":
            return {
                ...state,
                stockFilter: {
                    ...state.stockFilter,
                    expirityType: action.payload.value
                }
            };
        case "OPEN_ERROR_MODAL":
            return {
                ...state,
                dialogOpen: {
                    isOpen: true,
                    message: action.payload.message
                }
            };
        case "CLOSE_ERROR_MODAL":
            return {
                ...state,
                dialogOpen: {
                    isOpen: false,
                    message: ""
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};