import * as _ from "lodash";

export interface CsvData {
    headers: Array<string>;
    data: Array<Array<string>>;
}

export const generateCsvFromTable = (tableContainer): CsvData => {
    let headers: Array<string> = [];
    let data: Array<Array<string>> = [];

    let maxCol = _.max(Array.from<HTMLDivElement>(tableContainer
        .getElementsByClassName("rt-thead -header") as any)
        .map(x => x.getElementsByClassName("rt-th").length));
    if(Array.from<HTMLDivElement>(tableContainer.getElementsByClassName("rthcg-0") as any).length > 0) {
        data.push([]);
        for (let i = 0; i < maxCol; i++) {
            let subHeader = Array.from<HTMLDivElement>(tableContainer.getElementsByClassName(`rthc-${i}`) as any);
            if(subHeader && subHeader.length !== 0){
                data[0].push(subHeader[0].innerText);
            } else {
                data[0].push("");
                headers.push("");
                continue;
            }
            let groupClass = subHeader[0].className.split(' ').map(x => x.trim()).find(x => x.startsWith('rthcg-'));
            let header = Array.from<HTMLDivElement>(tableContainer.getElementsByClassName(groupClass) as any);
            if(header && header.length !== 0 && !header[0].children[0].className.includes("csv-ignore")){
                headers.push(header[0].innerText);
            } else{
                headers.push("");
            }
        }
    } else {
        for (let i = 0; i < maxCol; i++) {
            let subHeader = Array.from<HTMLDivElement>(tableContainer.getElementsByClassName(`rthc-${i}`) as any);
            if(subHeader && subHeader.length !== 0){
                headers.push(subHeader[0].innerText);
            } else {
                headers.push("");
            }
        }
    }

    let dataRows = Array.from<HTMLDivElement>(tableContainer.getElementsByClassName(`rt-tr-group`) as any);
    dataRows.forEach((row, rowIndex) => {
        data.push([]);
        for (let i = 0; i < maxCol; i++) {
            let cell = Array.from<HTMLDivElement>(row.getElementsByClassName(`rtc-${i}`) as any);
            if(cell && cell.length !== 0){
                let input = cell[0].getElementsByTagName('input');
                if(input && input.length !== 0){
                    data[data.length - 1].push(input[0].value);
                } else {
                    data[data.length - 1].push(cell[0].innerText);
                }
            } else {
                data[data.length - 1].push("");
            }
        }
    });

    let footerRow = Array.from<HTMLDivElement>(tableContainer.getElementsByClassName(`rt-tfoot`) as any);
    if(footerRow && footerRow.length !== 0){
        data.push([]);
        for (let i = 0; i < maxCol; i++) {
            let cell = Array.from<HTMLDivElement>(footerRow[0].getElementsByClassName(`rtc-${i}`) as any);
            if(cell && cell.length !== 0){
                data[data.length - 1].push(cell[0].innerText);
            } else {
                data[data.length - 1].push("");
            }
        }
    }

    let antiDate = /^\d+\/\d+$/;
    
    let auxData = data.map(x => x.map(y => antiDate.test(y) ? y.replace('/', '|') : y));
    auxData.unshift(headers);//To display headers into the csv

    return {
        headers: headers,
        data: auxData
    };
}

export const generateCsvFromCashRegister = (cashRegContainer, type): CsvData => {
    let data: Array<Array<string>> = [];
    let maxCol = Array.from<HTMLDivElement>(cashRegContainer.getElementsByClassName(type == 1 ? "cashRegisterRow" : "cashRegisterRow2") as any);
    let maxValuesCol = _.max(Array.from<HTMLDivElement>(cashRegContainer
        .getElementsByClassName(type == 1 ? "cashRegisterRow" :"cashRegisterRow2" ) as any)
        .map(x => x.getElementsByClassName(type == 1 ? "cashRegisterValue" : "cashRegisterValue2").length))
    
    maxCol.forEach((row, rowIndex) => {
        data.push([]);
        let values = Array.from<HTMLDivElement>(row.getElementsByClassName(type == 1 ? 'cashRegisterValue' : 'cashRegisterValue2') as any);
        for (let i = 0; i < maxValuesCol; i++) {
            data[data.length - 1].push(values[i] != undefined ? values[i].innerText : '');
        }
    });
    
    return {
        headers: [],
        data: data
    };
}