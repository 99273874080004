import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as HistoryStore from "../store/History";
import * as SupplierOrderStore from "../store/SupplierOrder";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Select from "./Select";
import DatePicker from "./DatePicker";
import ConnectedTabs from "./ConnectedTabs";
import HistoryTurnover from "./HistoryTurnover";
import HistoryProductSell from "./HistoryProductSell";
import HistoryCashRegister from "./HistoryCashRegister";
import HistoryOrder from "./HistoryOrder";
import HistoryProductStock from "./HistoryProductStock";
import HistoryRemoteCollection from "./HistoryRemoteCollection";
import HistoryProductReception from "./HistoryProductReception";
import HistoryCardCollection from "./HistoryCardCollection";
import DialogModal from './DialogModal';
import { TabLink, TabContent } from "react-tabs-redux";
import HistoryStockOperations from "./HistoryStockOperations";
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import { TabState } from '../store/Tabs';
import { productSuppliersSelector, externalPSuppliersSelector } from '../store/Seed';
import {CsvData, generateCsvFromTable, generateCsvFromCashRegister} from "../utils/csv";
import * as ReactDOM from "react-dom";
import Moment from 'moment';
import CheckBox from "./CheckBox";
import * as StockStore from "../store/Stock";
import HistoryTurnoverTotal from './HistoryTurnoverTotal';
import { ExportToCsv } from 'export-to-csv';
import HistoryTurnoverV6 from './HistoryTurnoverV6';
import HistoryTurnoverTotalV6 from './HistoryTurnoverTotalV6';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type HistoryProps = HistoryOwnProps
    & HistoryStore.HistoryState
    & SupplierOrderStore.SupplierOrderState
    & typeof StockStore.actionCreators
    & typeof HistoryStore.actionCreators
    & typeof SupplierOrderStore.actionCreators;

interface HistoryOwnProps {
    hoppers: Array<Api.HmiMoneyHopperModel>;
    seed: Api.SeedMngModel;
    groupedStoreDatas: Array<HistoryStore.GroupedStoreData>;
    productSellDatas: Array<HistoryStore.ProductSellData>;
    productStockDatas: Array<HistoryStore.ProductStockData>;
    totalStockDatas: Array<HistoryStore.ProductStockData>;
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
    stockStatus: Api.StockStatusModel;
    remoteCollections: Array<HistoryStore.RemoteCollectionWithData>;
    tab: TabState;
    supplierOrders: Array<Api.SupplierOrderModel>;
    storeType: string;
    currencies: { [id: number]: Api.CurrencyModel };
}

export const timeGroupByOptions= (): Array<{ label: string, value: HistoryStore.TimeGroupBy } > => ([
    { label: getText("HistoDays"), value: "Day" },
    { label: getText("HistoWeeks"), value: "Week" },
    { label: getText("HistoMonths"), value: "Month" },
    { label: getText("HistoYears"), value: "Year" },
]);

const tabHeadStyle: React.CSSProperties = { margin: "auto" };
const tabStyle: React.CSSProperties = {width: '10%', marginRight: '0.5rem', display: 'flex', fontSize: 14};
const tabStyleExternal: React.CSSProperties = {width: '10%', marginRight: '0.5rem', display: 'flex', fontSize: 14, cursor:"no-drop"};
const rowFilterStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1%'};
const columFilterStyle : React.CSSProperties = {display: 'block', alignItems: 'center'};
const columButtonsFilterStyle : React.CSSProperties = {display: 'inline-flex', alignItems: 'center'};
const checkboxStyle: React.CSSProperties = {width: 16, height: 16, fontSize: 8};
const containerCheckboxStyle: React.CSSProperties = {minHeight: 24, maxHeight: 24, paddingBottom: 0};

interface HistoryState {
    orderReceipt: Api.StoreOrderModel;
}

class History extends React.Component<HistoryProps, HistoryState> {
    turnoverRef: any = null;
    turnoverv6Ref: any = null;
    orderRef: any = null;
    productSellsRef: any = null;
    productStocksRef: any = null;
    productReceptionsRef: any = null;
    cashRegisterRef: any = null;
    cardCollectionRef: any = null;
    remoteCollectionRef: any = null;
    stockOperationsRef: any = null;
    
    constructor(props) {
        super(props);
        this.state = {
            orderReceipt: undefined
        };
    }

    componentDidMount() {
        this.props.requestSupplierOrders(new Date().getTime());
    }

    csvData(): CsvData {
        switch (this.props.tab.selectedTab){
            case 'turnover':
                return this.generateCsvFromRef(this.turnoverRef);
            case 'turnoverv6':
                return this.generateCsvFromRef(this.turnoverv6Ref);
            case 'order':
                return this.generateCsvFromRef(this.orderRef);
            case 'productSells':
                return this.generateCsvFromRef(this.productSellsRef);
            case 'productStocks':
                return this.generateCsvFromRef(this.productStocksRef);
            case 'productReceptions':
                return this.generateCsvFromRef(this.productReceptionsRef);
            case 'cashRegister':
                return this.generateCsvFromCashRegisterRef(this.cashRegisterRef);
            case 'cardCollection':
                return this.generateCsvFromRef(this.cardCollectionRef);
            case 'remoteCollection':
                return this.generateCsvFromRef(this.remoteCollectionRef);
            case 'stockOperations':
                return this.generateCsvFromRef(this.stockOperationsRef);
            default:
                return {
                    headers: [],
                    data: []
                };
        }
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF('l');
        autoTable(pdf, {body: data});
        pdf.save(`${getText("HistoTitle")}-${this.page}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    get page() : string{
        switch (this.props.tab.selectedTab){
            case 'turnover': return getText( "HistoCA");
            case 'turnoverv6': return `${getText( "HistoCA")} v6`;
            case 'order': return getText( "HistoTabOrders");
            case 'productSells': return getText( "HistoTabSales");
            case 'productStocks':  return getText( "HistoTabStock");
            case 'productReceptions': return getText( "HistoTabReorders");
            case 'cashRegister': return `${getText( "HistoTabCashRegister")}${getText( "CashRegisterOperations")}`;
            case 'cardCollection': return getText( "HistoTransactions");
            case 'remoteCollection': return getText( "HistoTabRemote");
            case 'stockOperations': return getText( "HistoTabStockOperations");
            default:
                return '';
        }
    }
    
    generateCsvFromRef(ref: any): CsvData {
        let tableContainer = ReactDOM.findDOMNode(ref);
        if(tableContainer){
            return generateCsvFromTable(ref);
        }
        
        return {
            headers: [],
            data: []
        };
    }

    generateCsvFromCashRegisterRef(ref: any): CsvData {
        let cashRegContainer = ReactDOM.findDOMNode(ref);
        if(cashRegContainer && document.getElementsByClassName("cashRegisterContainer").length != 0){
            return generateCsvFromCashRegister(ref, 1);
        }
        
        return {
            headers: [''],
            data: [[getText("HistoCashRegisterNoValues")]]
        };
    }
    
    filters() {
        //Active tab is in the store.... (tabs)
        return (<div style={{padding: '1% 1% 0'}}>
            <div style={rowFilterStyle}>
                <div style={columFilterStyle}>
                    <div style={Styles.filterLabelStyle}>{this.props.tab.selectedTab !== "productStocks" ? getText( "HistoSince") : getText("HistoDate")}</div>
                    <DatePicker value={this.props.getHistoryModel.fromDate}
                        showTimeSelect={this.props.tab.selectedTab !== "productStocks" ? true : false}
                        dateFormat={this.props.tab.selectedTab !== "productStocks" ? 'dd DD/MM/YYYY, HH:mm' : 'dd DD/MM/YYYY'}
                        maxDate={this.props.getHistoryModel.toDate}
                        onChange={(value) =>{ 
                            this.props.updateHistoryFromDate(value)
                            if(this.props.tab.selectedTab === "productStocks")
                                this.props.updateHistoryToDate(Moment(value).endOf("day").toDate());
                        }} />
                </div>
                {this.props.tab.selectedTab !== "productStocks"
                    && <div style={columFilterStyle}>
                    <div style={Styles.filterLabelStyle}>{getText( "HistoUntil")}</div>
                    <DatePicker value={this.props.getHistoryModel.toDate}
                    minDate={this.props.getHistoryModel.fromDate}
                        showTimeSelect={true}
                        onChange={(value) => this.props.updateHistoryToDate(value)} />
                </div>}
                {this.props.tab.selectedTab !== "productStocks" && this.props.tab.selectedTab !== "order"
                 && this.props.tab.selectedTab !== "productSells" 
                    && <div style={{ maxWidth: 110, ...columFilterStyle }} className="selectors borderSelectors">
                        <div style={Styles.filterLabelStyle}>{getText("HistoAccumulation")}</div>
                        <Select options={timeGroupByOptions()}
                            value={this.props.timeGroupBy}
                            style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }}
                            onChange={(value) => this.props.updateHistoryTimeGroupBy(value)} />
                    </div>}
                {this.props.tab.selectedTab != "events" 
                    && <div style={{ maxWidth: 150, ...columFilterStyle }} className="selectors borderSelectors">
                    {(this.props.tab.selectedTab === "turnover" || this.props.tab.selectedTab === "order") && <div>
                        <div style={Styles.filterLabelStyle}>{getText("HistoPaymentType")}:</div>
                        <Select options={[
                                { label: "--------", value: null },
                                { label: getText("HistoTPA"), value: "Normal" },
                                { label: getText("HistoNFC"), value: "NoContact" },
                                { label: getText("HistoTPN"), value: "Cash" },
                                { label: getText("HistoSmart"), value: "SmartPhone" },
                                { label: getText("HistoDelivery"), value: "Livraison" },
                                { label: getText("HistoCE"), value: "CE" },
                                { label: getText("HistoUberEats"), value: "UberEats"},
                                { label: getText("HistoDeliveroo"), value: "Deliveroo"},
                                { label: getText("HistoJustEat"), value: "JustEat"}
                            ] as Array<{ label: string, value: Api.SalePaymentPaymentTypeEnum}>}
                            value={this.props.turnoverFilter.paymentType}
                            onChange={(value) => this.props.updateTurnoverFilter({ ...this.props.turnoverFilter, paymentType: value })}
                            style={{ background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} />
                    </div>}
                {this.props.tab.selectedTab === "productSells" && this.props.storeType == "Ximiti"
                    && <div style={{ ...columFilterStyle }} className="selectors borderSelectors">                        
                        <CheckBox label={getText("HistoUnSouldProds")}
                            value={this.props.salesFilter.showUnsold}
                            style={checkboxStyle}
                            contentStyle={containerCheckboxStyle}
                            onChange={(value) => this.props.updateSalesFilter({ ...this.props.salesFilter, showUnsold: value })} />
                    </div>}
                </div>}
                <div style={columButtonsFilterStyle}>
                    <div style={{width: 115}}>
                        <button className="btn btn-primary"
                            style={{width: '100%', height: 40}}
                            onClick={(e) => {
                                this.props.requestDayStoreDatas(new Date().getTime());
                                this.props.seed.storeAppSettings.displayCAv6 == true && this.props.requestHistoCAv6(new Date().getTime());
                            }}>
                            {getText( "HistoApply")}
                        </button>
                    </div>
                    {this.props.tab.selectedTab != "events" && <div style={{ marginLeft: 5, width: 115}}>
                        <button type={"button"}
                            className="btn btn-primary"
                            style={{width: '100%', height: 40}}
                            onClick={(e) => {
                                const options = {
                                    fieldSeparator: ';',
                                    quoteStrings: '"',
                                    decimalSeparator: ',',
                                    showLabels: false,
                                    showTitle: false,
                                    filename: `${getText("HistoTitle")}-${this.page}-${Moment().format('YYYYMMDDHHmm')}`,
                                    useTextFile: false,
                                    useBom: true,
                                    useKeysAsHeaders: false,
                                    headers: this.csvData().headers,
                                };
                                const csvExporter = new ExportToCsv(options);
                                csvExporter.generateCsv(this.csvData().data);  
                                this.pdfData(this.csvData().data);
                            }}>
                                {getText( "HistoExport")}
                                <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                        </button>
                    </div>}
                </div>
            </div>
        </div>);
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    handleClick = (e) => {
         e.preventDefault();
    }

    public render() {
        return (
            <div style={{height: "100%"}}>
                <ConnectedTabs name={"history"}>
                    <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                        <span style={{padding: '0 2%'}}>
                            {getText( "HistoTitle")}
                        </span>
                        <TabLink to="turnover" style={tabStyle}>
                            <div style={tabHeadStyle}>{getText( "HistoCA")}</div>
                        </TabLink>
                        { this.props.seed.storeAppSettings.displayCAv6 == true &&
                        <TabLink to="turnoverv6" style={tabStyle}>
                            <div style={tabHeadStyle}>{`${getText( "HistoCA")} v6`}</div>
                        </TabLink>}
                        <TabLink to="order" style={{...tabStyle, width: '15%'}}>
                            <div style={tabHeadStyle}>{getText( "HistoTabOrders")}</div>
                        </TabLink>
                        <TabLink to="productSells" style={tabStyle}>
                            <div style={tabHeadStyle}>{getText( "HistoTabSales")}</div>
                        </TabLink>
                        <TabLink to={this.props.storeType == "Ximiti" && "productStocks"} onClick={this.handleClick}  style={this.props.storeType == "Ximiti" ? tabStyle : tabStyleExternal}>
                            <div style={tabHeadStyle}>{getText( "HistoTabStock")}</div>
                        </TabLink>
                        <TabLink to="productReceptions" style={tabStyle}>
                            <div style={tabHeadStyle}>{getText( "HistoTabReorders")}</div>
                        </TabLink>
                        <TabLink to={this.props.storeType == "Ximiti" && "cashRegister"} onClick={this.handleClick}  style={this.props.storeType == "Ximiti" ? tabStyle : tabStyleExternal} >
                            <div style={tabHeadStyle}>{getText( "HistoTabCashRegister")}</div>
                        </TabLink>
                        <TabLink to={this.props.storeType == "Ximiti" && "cardCollection"} onClick={this.handleClick}  style={this.props.storeType == "Ximiti" ? tabStyle : tabStyleExternal}>
                            <div style={tabHeadStyle}>{getText( "HistoTransactions")}</div>
                        </TabLink>
                        <TabLink to={this.props.storeType == "Ximiti" && "remoteCollection"}  onClick={this.handleClick}  style={this.props.storeType == "Ximiti" ? tabStyle : tabStyleExternal}>
                            <div style={tabHeadStyle}>{getText( "HistoTabRemote")}</div>
                        </TabLink> 
                        <TabLink to="stockOperations" style={{...tabStyle, width: '15%'}}>
                            <div style={tabHeadStyle}>{getText( "HistoTabStockOperations")}</div>
                        </TabLink>
                        <HelpHeader />
                    </div>
                    <TabContent for="turnover" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "turnover"
                        && <div>
                            <div className="card" ref={(r) => this.turnoverRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                                { this.filters() }
                                <HistoryTurnover isLoading={this.props.historyData.isLoading}
                                                 groupedStoreDatas={this.props.groupedStoreDatas}
                                                 timeGroupBy={this.props.timeGroupBy}
                                                 seed={this.props.seed}
                                                 productSuppliers={this.props.productSuppliers} />
                            </div>
                            <div>
                                <HistoryTurnoverTotal isLoading={this.props.historyData.isLoading}
                                                      groupedStoreDatas={this.props.groupedStoreDatas}
                                                      seed={this.props.seed}/>
                            </div>
                        </div>}
                    </TabContent>
                    { this.props.seed.storeAppSettings.displayCAv6 == true &&
                    <TabContent for="turnoverv6" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "turnoverv6"
                        && <div>
                            <div className="card" ref={(r) => this.turnoverv6Ref = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                                { this.filters() }
                                <HistoryTurnoverV6 isLoading={this.props.historyData.isLoadingTV6}
                                            turnoverV6={this.props.historyData.turnoverV6}    
                                            timeGroupBy={this.props.timeGroupBy}/>
                            </div>
                            <div>
                                <HistoryTurnoverTotalV6  isLoading={this.props.historyData.isLoadingTV6}
                                            turnoverV6={this.props.historyData.turnoverV6} />
                            </div>
                        </div>}
                    </TabContent>}
                    <TabContent for="order" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "order"
                        && <div className="card" ref={(r) => this.orderRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryOrder isLoading={this.props.historyData.isLoading}
                                storeType={this.props.storeType}
                                dayStoreDatas={this.props.historyData.dayStoreDatas}
                                selectedIndex={this.props.historyData.selectedIndex}
                                seed={this.props.seed}
                                paymentTypeFilter={this.props.turnoverFilter.paymentType}
                                currencies={this.props.currencies}
                                updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)}
                                openReceiptDialog={(storeOrder) => {
                                    this.setState({ orderReceipt : undefined});
                                    let payment = storeOrder.storeOrderPayments
                                        .find(x => x.status === "Success");
                                    if(payment){
                                        if(!storeOrder.receipt) {
                                            this.props.requestOrderReceipt(new Date().getTime(), storeOrder.storeOrderId);
                                        }
                                        else {
                                            this.setState({ orderReceipt : storeOrder});
                                        }
                                        this.props.requestCardReceipt(new Date().getTime(), payment.storeOrderPaymentId);
                                        this.props.openCardDialog(payment.storeOrderPaymentId);
                                    }
                                }} />
                        </div>}
                    </TabContent>
                    <TabContent for="productSells" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "productSells"
                        && <div className="card" ref={(r) => this.productSellsRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryProductSell isLoading={this.props.historyData.isLoading}
                                storeType={this.props.storeType}
                                productSellDatas={this.props.productSellDatas}
                                seed={this.props.seed} 
                                groupedStoreDatas={this.props.groupedStoreDatas}
                                productSuppliers={this.props.productSuppliers}  />
                        </div>}
                    </TabContent>
                    {this.props.storeType == "Ximiti" &&
                    <>
                    <TabContent for="productStocks" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "productStocks"
                        && <div className="card" ref={(r) => this.productStocksRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryProductStock isLoading={this.props.historyData.isLoading}
                                dayStoreDatas={this.props.historyData.dayStoreDatas}
                                selectedIndex={this.props.historyData.selectedIndex}
                                productStockDatas={this.props.productStockDatas}
                                seed={this.props.seed}
                                updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)} />
                        </div>}
                    </TabContent>
                     </>
                    }
                    <TabContent for="productReceptions" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "productReceptions"
                        && <div className="card" ref={(r) => this.productReceptionsRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryProductReception isLoading={this.props.historyData.isLoading}
                                storeType={this.props.storeType}
                                dayStoreDatas={this.props.historyData.dayStoreDatas}
                                selectedIndex={this.props.historyData.selectedIndex}
                                seed={this.props.seed}
                                updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)} 
                                supplierOrders={this.props.supplierOrders} 
                                productSuppliers={this.props.productSuppliers}/>
                        </div>}
                    </TabContent> 
                    {this.props.storeType == "Ximiti" &&
                    <>
                    <TabContent for="cashRegister" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "cashRegister"
                        && <div className="card" ref={(r) => this.cashRegisterRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <div>
                                <HistoryCashRegister
                                    hoppers={this.props.hoppers}
                                    isLoading={this.props.historyData.isLoading}
                                    groupedStoreDatas={this.props.groupedStoreDatas}
                                    timeGroupBy={this.props.timeGroupBy}
                                    selectedIndex={this.props.historyData.selectedIndex}
                                    seed={this.props.seed}
                                    updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)} />
                            </div>
                        </div>}
                    </TabContent>
                    <TabContent for="cardCollection" style={{height: "100%"}}>
                        {this.props.tab.selectedTab === "cardCollection"
                        && <div className="card" ref={(r) => this.cardCollectionRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryCardCollection isLoading={this.props.historyData.isLoading}
                                dayStoreDatas={this.props.historyData.dayStoreDatas}
                                selectedIndex={this.props.historyData.selectedIndex}
                                seed={this.props.seed}
                                updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)} 
                                openViewCardCollection = {(currentCollection, currentPayment) => this.props.openViewCardCollection(currentCollection, currentPayment)}/>
                        </div>}
                    </TabContent>
                    <TabContent for="remoteCollection" style={{heheightight: "100%"}}>
                        {this.props.tab.selectedTab === "remoteCollection"
                        && <div className="card" ref={(r) => this.remoteCollectionRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryRemoteCollection isLoading={this.props.historyData.isLoading}
                                dayStoreDatas={this.props.historyData.dayStoreDatas}
                                selectedIndex={this.props.historyData.selectedIndex}
                                remoteCollections={this.props.remoteCollections}
                                seed={this.props.seed}
                                updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)} 
                                openViewRemoteCollection = {(currentRemoteCollection, currentDataRemoteC) => this.props.openViewRemoteCollection(currentRemoteCollection, currentDataRemoteC)}/>
                        </div>}
                    </TabContent>
                    </>
                    }
                    <TabContent for="stockOperations" style={{heheightight: "100%"}}>
                        {this.props.tab.selectedTab === "stockOperations"
                        && <div className="card" ref={(r) => this.stockOperationsRef = r} style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "90%"}}>
                            { this.filters() }
                            <HistoryStockOperations isLoading={this.props.historyData.isLoading}
                                storeType={this.props.storeType}
                                dayStoreDatas={this.props.historyData.dayStoreDatas}
                                stockStatus={this.props.stockStatus}
                                selectedIndex={this.props.historyData.selectedIndex}
                                productSellDatas={this.props.productSellDatas}
                                seed={this.props.seed}
                                updateSelectedIndex={(value) => this.props.updateHistoryIndex(value)}
                                totalStockDatas={this.props.totalStockDatas}
                                productSuppliers={this.props.productSuppliers}/>
                        </div>}
                    </TabContent>
                </ConnectedTabs>
                <DialogModal contentLabel={"Receipts"}
                    isOpen={this.props.receiptState.isOpen}
                    onRequestClose={() => this.props.closeCardDialog()}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <div>{getText("HistoTicket")}</div>
                            <div style={{ whiteSpace: "pre-wrap", border: "1px solid", 
                                padding: 5, minHeight: 400, 
                                minWidth: 300, marginRight: 20,
                                fontSize: 12 }}>
                                {(this.state.orderReceipt
                                    && this.state.orderReceipt.receipt)
                                ? this.state.orderReceipt.receipt
                                : this.props.receiptState.order.receiptContent}
                            </div>
                            <div style={{ textAlign: "center", marginTop: 20 }}>
                                <button className="btn btn-primary"
                                    disabled={this.props.printReceiptState.isLoading
                                        || ((!this.state.orderReceipt
                                        || !this.state.orderReceipt.receipt) && !this.props.receiptState.order.receiptContent)}
                                    onClick={(e) => this.props.requestPrintReceipt(new Date().getTime(), this.state.orderReceipt.storeOrderId)}>
                                    {getText("MaintenanceToPrint")}
                                </button>
                            </div>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <div>{getText("HistoTicketCB")}</div>
                            <div style={{ whiteSpace: "pre-wrap", border: "1px solid", 
                                padding: 5, minHeight: 400, 
                                minWidth: 300, fontSize: 12 }}>
                                {this.props.receiptState.card.receiptContent}
                            </div>
                            <div style={{ textAlign: "center", marginTop: 20 }}>
                                <button className="btn btn-primary"
                                    disabled={!this.props.receiptState.card.receiptContent
                                        || this.props.printCardReceiptState.isLoading
                                        || !this.props.receiptState.selectedPaymentId}
                                    onClick={(e) => this.props.requestPrintCardReceipt(new Date().getTime())}>
                                    {getText("MaintenanceToPrint")}
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogModal>
        </div>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.history,
    seed: state.seed.seed,
    hoppers: state.cash.hoppers.hoppers,
    groupedStoreDatas: HistoryStore.groupedStoreDatas(state),
    productSellDatas: HistoryStore.productSellDatas(state),
    productStockDatas: HistoryStore.productStockDatas(state),
    totalStockDatas: HistoryStore.totalStocksDatas(state),
    stockStatus: state.stock.stockStatus,
    tab: state.tabs["history"] || { selectedTab: "turnover" },
    productSuppliers: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? productSuppliersSelector(state) : externalPSuppliersSelector(state),
    supplierOrders: state.supplierOrder.supplierOrders,
    remoteCollections: HistoryStore.remoteCollections(state),
    storeType: state.seed.seed.storeAppSettings.typeStore,
    currencies: state.seed.seed.currencies
} as HistoryOwnProps), {
    ...HistoryStore.actionCreators,
    ...SupplierOrderStore.actionCreators,
    ...StockStore.actionCreators
})(History as any);