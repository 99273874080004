import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as SettingsStore from '../store/Settings';
import * as CardStore from "../store/Card";
import * as RobotStore from '../store/Robot';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate, maint } from '../security/roles';
import { getText } from '../utils/texts';
import StoreSettingsForm from './StoreSettingsForm';

type ExploitantProps = ExploitantOwnProps
    & SettingsStore.SettingsState
    & RobotStore.RobotState
    & typeof RobotStore.actionCreators
    & typeof SettingsStore.actionCreators
    & typeof CardStore.actionCreators;

interface ExploitantOwnProps {
    currentUser: Api.CurrentUserManagerModel;
    card: CardStore.CardState;
    disabled: boolean;
}

 class Exploitant extends React.Component<ExploitantProps, {}> {

    componentDidMount() {
        this.props.requestStoreSettings(new Date().getTime());
    }

    public render() {
        return (
            <div>
                <div style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                    opacity: this.props.disabled ? 0.7 : 1 }}>
                    {this.props.robotsState.robots.map(x => <div key={x.robotId}>
                        <StoreSettingsForm robotId={x.robotId}
                                           onSubmit={(values) => {
                                               this.props.requestUpdateStoreSettings(new Date().getTime(), values);
                                               this.props.requestUpdateRobot(new Date().getTime(), values);
                                           }} />
                    </div>)}
                </div>
                <div className="row" >
                    <div className="col-md-6" style={{fontSize: 14, paddingRight: 0}}>
                        <div style={{ marginTop: 10, textAlign: "center" }}>
                            <button className="btn btn-primary"
                                type={"button"}
                                onClick={(e) => this.props.requestUpdateCardDateTime(new Date().getTime())}
                                style={{width: '100%', height: 34}}
                                disabled={this.props.card.updateDateTimeState.isLoading || this.props.disabled}>
                                    {getText( "SettingsUpdateDateCB")}
                            </button>   
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.settings,
    ...state.robot,
    currentUser: state.account.currentUser,
    card: state.card,
    disabled: !canUpdate(state),
} as ExploitantOwnProps), { ...SettingsStore.actionCreators, ...RobotStore.actionCreators, ...CardStore.actionCreators })(Exploitant as any)