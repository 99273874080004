import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as StockStore from '../store/Stock';
import * as ReactTable from "react-table";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import ProductStockForm, { ProductStockFormModel } from "./ProductStockForm";
import DialogModal from "./DialogModal";
import Table from "./Table";
import { calculateProductDepth } from '../utils/utils';
import { getText } from '../utils/texts';
import * as ReorderStore from "../store/Reorder";

type ProductStockDialogProps = StockStore.CreateStockState
    & ProductStockDialogOwnProps
    & typeof StockStore.actionCreators
    & typeof ReorderStore.actionCreators;

interface ProductStockDialogOwnProps {
    shelvingTray: Api.ShelvingTrayModel;
    productRail: Api.ProductRailModel;
    product: Api.ProductModel | Api.ExternalProductModel;
    suppliers: { [id: number]: Api.SupplierModel };
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
    dialogOpen: any;
}

type ProductStockColumn = ReactTable.Column
    & {
        accessor: (d: Api.ProductStockModel) => any;
        columns?: Array<ProductStockColumn>
    }

class ProductStockDialog extends React.Component<ProductStockDialogProps, {}> {

    get maxStock(): number {
        return Math.floor(this.props.shelvingTray.depth
            / calculateProductDepth(this.props.product, this.props.productRail.sens));
    }
    get currentStock(): number {
        return this.props.productRail.productStocks.length;
    }
    constructor(props: ProductStockDialogProps) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onSubmit(values: ProductStockFormModel) {
        
        return new Promise((resolve) => {
            switch (this.props.actionStock) {
                case 'create':
                    this.onCreateProductStockSubmit(values, this.props);
                    break;
                case 'delete':
                    this.onDeleteProductStockSubmit(values, this.props);
                    break;
                case 'fill':
                    this.onFillProductStockSubmit(values, this.props);
                    break;
                
            }
            resolve(true);
            
        });
    }
    onCreateProductStockSubmit(values: ProductStockFormModel, props: ProductStockDialogProps) {
        //ReduxForm takes care of the validation, it should be done from the fields not after submit.
        //If more check are needed after submit there is an api for that in reduxform, validation can also be async
        //let productStock = {
        //As of now dates in DB are UTC and if it causes issues it should be on the client side display that it should be changed
        //We could switch to DateTimeOffSet everywhere in the DB, but not prio
        //    expirity: values.expirity ? Moment(values.expirity).toDate() : undefined,
        //    productRailId: values.productRailId,
        //    quantity: values.quantity,
        //    productSupplierId: values.productSupplierId,
        //}
            //Not consistent with the expirty system, if no expirty then it should be null, the robot wont remove it from the stock 1 year later anyway
        //if (!values.expirity) {
        //    productStock.expirity = Moment().add(1, "years").toDate()
        //}

        //if (productStock.expirity && productStock.productRailId && productStock.quantity && productStock.productSupplierId) {
        props.requestCreateProductStock(new Date().getTime(), values)
        //}

    }
    onDeleteProductStockSubmit(values: ProductStockFormModel, props: ProductStockDialogProps){
        //See up
        //let quantity = values.quantity;
        //if (quantity <= props.productRail.productStocks.length) {

        //    let productStock = {
        //        productRailId: props.productRailId,
        //        quantity: quantity
        //    }
        //    if (productStock.productRailId && productStock.quantity) {
        props.requestDeleteProductStock(new Date().getTime(), values)
        //    }
        //}

    }
    onFillProductStockSubmit(values: ProductStockFormModel, props: ProductStockDialogProps) {
        let actualMaxStock = Math.floor(props.shelvingTray.depth
            / calculateProductDepth(props.product, props.productRail.sens));
        let auxValue = actualMaxStock - props.productRail.productStocks.length;
        // let stockDifference = this.props.stockArr >= auxValue ? auxValue : this.props.stockArr;
        // if (stockDifference > 0) {
        let stockToAdd = Math.min(auxValue, this.props.stockArr);
        let productStock = {
            expirity: values.expirity ? Moment(values.expirity).toDate() : undefined,
            productRailId: values.productRailId,
            quantity: stockToAdd,
            productSupplierId: values.productSupplierId,
        }
        //Not consistent with the expirty system, if no expirty then it should be null, the robot wont remove it from the stock 1 year later anyway
        //if (!values.expirity) {
        //    productStock.expirity = Moment().add(1, "years").toDate()
        //}
        //Ok here, although I would go for another action than a submit but its hte same (button type button that call fillStock and do not submit)
        props.requestCreateProductStock(new Date().getTime(), productStock)
        // }
                            //window.location.reload();
    }
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    public render() {
        if(!this.props.product)
            return <React.Fragment></React.Fragment>;
        
        return (
            <DialogModal isOpen={this.props.isOpen}
                contentLabel={"stocks"}
                contentStyle={{ 
                    width: "50%", 
                    background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                    border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)",
                    top: (window as any).visualViewport.height > 930 && (window as any).visualViewport.height < 1100 ? -80 : 0
                }}
                title={this.props.productRail ? `${this.props.product.name} : ${this.currentStock}/${this.maxStock}`: ""}
                onRequestClose={() => {
                   this.props.closeCreateStock();
                   this.props.requestActionProductStock(undefined);
                }}>
                { 
                    this.props.productRail && 
                    <div style={{ }}>
                        <div style={{ textAlign: "center" }}>
                            {getText( "PlanogramRADF")}: {this.props.productRail.r} {this.props.productRail.a} {this.props.productRail.d} {this.props.productRail.f}
                        </div>                  
                        <div>
                            <ProductStockForm productStock={this.props.productRail.productStocks} stockArr={this.props.stockArr} onSubmit={this.onSubmit} />                        
                        </div>
                        <div className="card">
                            <Table
                                loading={this.props.isLoading}
                                style={{ height: '32vh' }}
                                columns={[
                                    {
                                        id: "position",
                                        accessor: (d) => d,
                                        Header: <div>{getText( "PlanogramModalPosition")}</div>,
                                        Cell: row => <div>{this.props.productRail.productStocks.indexOf(row.value) + 1}</div>
                                    },
                                    {
                                        id: "expirity",
                                        accessor: (d) => d.expirity,
                                        Header: <div>{getText( "PlanogramModalDLC")}</div>,
                                        Cell: row => <div>{row.value ? Moment(row.value).format("DD/MM/YY") : "-"}</div>
                                    }
                                ] as Array<ProductStockColumn>}
                                pageSize={Math.max(this.maxStock,10)}
                                showPagination={false}
                                showPaginationBottom={false}
                                data={this.props.productRail.productStocks}>
                            </Table>
                        </div>
                </div>}
                <DialogModal contentLabel={"Stock dialog error"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                        width: 500, 
                        maxHeight: 400,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    title={getText( "StockDialogErrorTitle")}
                    isOpen={this.props.dialogOpen.isOpen}
                    onRequestClose={() => this.props.closeErrorDialogModal()}>
                    <div>
                        <h4 style={{margin: "40 30", overflowWrap: 'break-word'}}>
                            {this.props.dialogOpen.message}
                        </h4>
                        <button className="btn btn-secondary btn-sm"
                            type={"button"}
                            style={{
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.props.closeErrorDialogModal()}> {getText( "MaintenanceCorrect")} </button>
                    </div>
                </DialogModal>
            </DialogModal>
        );
    }
}

export default connect((state: ApplicationState) => {
    //
    if (!state.stock.stockStatus
        || !state.stock.createStock.productRailId)
        return {
            ...state.stock.createStock,
            productRail: null,
            product: null,
            shelvingTray: null
        };

    let productRail = StockStore.productRailsById(state)[state.stock.createStock.productRailId];
    return {
        ...state.stock.createStock,
        productRail: productRail,
        product: productRail ? state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? state.seed.seed.products[productRail.productId] : state.seed.seed.externalProducts[productRail.externalProductId] : undefined,
        stockArr: state.stock.createStock.stockArr,
        shelvingTray: productRail ? state.stock.stockStatus.shelvingTrays[productRail.shelvingTrayId] : undefined,
        productSuppliers: state.stock.stockStatus.productSuppliers,
        suppliers: state.seed.seed.suppliers,
        dialogOpen: state.stock.dialogOpen
    } as ProductStockDialogOwnProps
}, {...StockStore.actionCreators, ...ReorderStore.actionCreators})(ProductStockDialog as any)