import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import Tooltip from 'rc-tooltip';
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import { getPriceFromMenu } from '../store/History';

interface HistoryOrderProps {
    isLoading: boolean;
    storeType: string;
    seed: Api.SeedMngModel;
    selectedIndex: number;
    dayStoreDatas: Array<Api.DayStoreDataModel>;
    paymentTypeFilter: any;
    currencies: { [id: number]: Api.CurrencyModel };
    updateSelectedIndex: (value: number) => void;
    openReceiptDialog: (order: Api.StoreOrderModel) => void;
}

type NeoOrderColumn = ReactTable.Column
    & {
    accessor: (d: StoreOrderModelTable) => any;
    style?: React.CSSProperties;
    columns?: Array<NeoOrderColumn>
};

interface StoreOrderModelTable {
    "storeOrderId"?: number;
    "type"?: Api.StoreOrderModelTypeEnum;
    "code"?: string;
    "status"?: Api.StoreOrderModelStatusEnum;
    "receipt"?: string;
    "orderDate"?: Date;
    "deliveredDate"?: Date;
    "dayStoreDataId"?: number;
    "platformId"?: number;
    "platform"?: Api.PlatformModel;
    "storeOrderPayments"?: Array<Api.StoreOrderPaymentModel>;
    "storeOrderProductDeliveries"?: Array<Api.StoreOrderProductDeliveryModel>;
    "storeOrderItemOrders"?: Array<Api.StoreOrderItemOrderModel>;
    "storeVouchersOrder"?: Array<Api.StoreVoucherOrderModel>;
    "storeItemOrderName"?: string;
    "storeItemOrderQty"?: number;
    "storeItemOrderPrice"?: number;
    "storeItemOrderCurrency"?: number;
    "storeItemOrderMarge"?: number;
}

const rowStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', paddingBottom: '1%'};

export default class HistoryOrder extends React.Component<HistoryOrderProps, {}> {

    get allStoreOrdersData(): Api.StoreOrderModel[] {
        return this.props.dayStoreDatas.map(x => x.storeOrders).reduce((a, b) => a.concat(b), []);
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get data(): Api.StoreOrderModel[] {
        return this.allStoreOrdersData 
            ? _.sortBy(this.allStoreOrdersData
                .filter(x => x.status === "Delivered"
                    && x.storeOrderPayments.length
                    && (!this.props.paymentTypeFilter
                    || (x.storeOrderPayments.some(y => ((y.paymentType === this.props.paymentTypeFilter && x.platform === null)
                        || (y.cardType === this.props.paymentTypeFilter)
                        || (x.platform !== null && x.platform.remoteId === "4" && y.paymentType === "SmartPhone" && this.props.paymentTypeFilter === "Livraison")
                        || (x.platform !== null && x.platform.remoteId !== "4" && y.paymentType === "SmartPhone" && this.props.paymentTypeFilter === "CE")
                        || (x.platform !== null && x.platform.remoteId === "2" && this.props.paymentTypeFilter === "UberEats")
                        || (x.platform !== null && x.platform.remoteId === "5" && this.props.paymentTypeFilter === "Deliveroo")
                        || (x.platform !== null && x.platform.remoteId === "6" && this.props.paymentTypeFilter === "JustEat")
                        )
                            && (y.collectiondDate ? true : false))))), x => x.orderDate)
            : []
    }

    get dataWithProducts(): StoreOrderModelTable[] {
        let storeOrders = this.data;
        let newData = [];
        let isFirsRow = false;
        storeOrders.map(storeOrder => {
                storeOrder.storeOrderItemOrders
                .filter(x => (x.storeOrderItemOrderType === "Product" || x.storeOrderItemOrderType === "ExternalProduct"))
                .map(y => {
                    let pvht = 0;
                    let quantity = storeOrder.storeOrderProductDeliveries
                                        .filter(x => x.storeOrderProductOrderId === y.storeOrderItemOrderId
                                            && x.status === "Ok").length;
                    let marge = 0;
                    if(y.storeOrderMenuOrderId) {
                        pvht = getPriceFromMenu(y, storeOrder) / (1 + (this.props.seed.vats[y.vatId].value / 100));
                        marge = (pvht - y.productPmp) * quantity;
                    }

                    pvht = y.price.value / (1 + (this.props.seed.vats[y.vatId].value / 100));
                    marge = (pvht - y.productPmp) * quantity;

                    if(!isFirsRow){
                        let auxSO : StoreOrderModelTable = {
                            ...storeOrder,
                            storeItemOrderName: this.props.storeType == "Ximiti" ? this.props.seed.products[y.productId].name
                                                        : this.props.seed.externalProducts[y.externalProductId].name,
                            storeItemOrderQty: quantity,
                            storeItemOrderPrice: y.price.value,
                            storeItemOrderCurrency: y.price.currencyId,
                            storeItemOrderMarge: quantity != 0 ? (marge / (pvht * quantity)) : 0
                        }
                        newData.push(auxSO);
                        isFirsRow = true;
                    }
                    else{
                        let auxSO : StoreOrderModelTable = {
                            storeItemOrderName: this.props.storeType == "Ximiti" ? this.props.seed.products[y.productId].name
                                                        : this.props.seed.externalProducts[y.externalProductId].name,
                            storeItemOrderQty: quantity,
                            storeItemOrderPrice: y.price.value,
                            storeItemOrderCurrency: y.price.currencyId,
                            storeItemOrderMarge: quantity != 0 ? (marge / (pvht * quantity)) : 0
                        }
                        newData.push(auxSO);
                    }
                });
                isFirsRow = false;
            }
        );
       return newData;
    }

    getTypeLabel(x: Api.StoreOrderModel): string {
        let payment = _.sortBy(x.storeOrderPayments, y => y.collectiondDate ? -1 : 1)[0] || {};
        if(payment.paymentType === 'Cash')
            return getText("HistoTPN") 
        if(payment.paymentType === 'Card')
            return payment.cardType === 'Normal' ? getText("HistoTPA") : getText("HistoNFC")
        if(payment.paymentType === 'MealVoucher')
            return getText("HistoMealVoucher")
        return payment.paymentType;
    }

    getTypeSmarphoneLabel(x: Api.StoreOrderModel): string {
        if(x.platform != null){
            return (!x.platform || x.platform.platformId === 4) ? getText("HistoDelivery") : `${getText("HistoCE")}/${x.platform.name}`
        }        
        return getText("HistoSmart")
    }

    getBackgroundColor(stringInput) {
        if(stringInput == `${getText("HistoCE")}/deliveroo`)
            return `hsl(175, 100%, 40%, 0.4)`;

        let stringUniqueHash = [...stringInput].reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        return `hsl(${stringUniqueHash % 360}, 95%, 35%, 0.4)`;
    }

    public render() {
        return (
            <div style={{...rowStyle}}>
                <div style={{paddingLeft: '1%', width: '100%'}}>
                    <Table className="heightHisto"
                           style={{margin: 0}}
                           key={"historyOrder"}
                           loading={this.props.isLoading}
                           pageSize={Math.max(this.dataWithProducts ? this.dataWithProducts.length : 0,20)}
                           showPageSizeOptions={false}
                           showPagination={false}
                           showPaginationBottom={false}
                           columns={[
                                {
                                   Header: <div>{getText( "HistoDate")}</div>,
                                   id: 'orderDate',
                                   accessor: x => x.orderDate ? x.orderDate : "",
                                   Cell: row => <div>{row.value != "" ? Moment(row.value).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY HH:mm:ss") : ""}</div>,
                                   width: 165
                                },
                                {
                                   Header: <div>{getText( "HistoType")}</div>,
                                   id: 'type',
                                   width: 120,
                                   accessor: x => x.type ? (x.type === "SaleOrder" ? this.getTypeLabel(x) : this.getTypeSmarphoneLabel(x)) : "",
                                   Cell: row => <div style={{backgroundColor: row.value.startsWith(getText("HistoCE")) && `${this.getBackgroundColor(row.value)}`}}>{row.value}</div>,
                                },
                                {
                                   Header: <div>{getText( "HistoCode")}</div>,
                                   id: 'code',
                                   width: 110,
                                   accessor: x => x.type ? (x.type === "PreOrder" ? x.code : "N/A") : "",
                                   Cell: (row) => <div style={{backgroundColor: row.row.type.startsWith(getText("HistoCE")) && `${this.getBackgroundColor(row.row.type)}`}}>{row.value}</div>,
                                },
                                {
                                   Header: <div>{getText( "HistoTotalBasket")}</div>,
                                   id: 'solvency',
                                   accessor: x => x.orderDate ? (_.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0] && _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0].askedSolvency.toFixed(2) + " \u20AC") : "",
                                   width: 100,
                                   Footer: (
                                       <span><strong>{_.sum(_.map(this.data, x => _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0] && _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0].askedSolvency)).toFixed(2) + " \u20AC"  || "0"}</strong></span>
                                   )
                                },
                                {
                                   Header: <div>{getText( "HistoTotalPayment")}</div>,
                                   id: 'toCollect',
                                   accessor: x => {
                                        if(x.orderDate){
                                            var collectedFromOrder = HistoryStore.totalToCollectFromOrder(x);
                                            var withDiscound = collectedFromOrder != 0 ? (collectedFromOrder - HistoryStore.vouchersDiscounts(x, collectedFromOrder)) : collectedFromOrder;
                                            return ((Math.round(withDiscound * 100) / 100).toFixed(2) + " \u20AC");
                                        }
                                        
                                        return "";
                                    },
                                   width: 100,
                                   Footer: (
                                       <span><strong>{(Math.round(_.sum(this.data.map(x => {
                                            var collectedFromOrder = HistoryStore.totalToCollectFromOrder(x);
                                            return collectedFromOrder != 0 ? (collectedFromOrder - HistoryStore.vouchersDiscounts(x, collectedFromOrder)) : collectedFromOrder;
                                        })) * 100) / 100).toFixed(2) + " \u20AC" || "0"}</strong></span>
                                   )
                                },
                                {
                                   Header: <div>{getText( "HistoTotalCashed")}</div>,
                                   id: 'collection',
                                   accessor: x => {
                                        if (x.orderDate){
                                            let payment = x.storeOrderPayments
                                                .find(y => y.status !== "Failed");
                                            return payment
                                                ? (Math.round(payment.collection * 100) / 100).toFixed(2) + " \u20AC"
                                                : "0 \u20AC";
                                        } 
                                        else
                                            return "";
                                   },
                                   width: 100,
                                   Footer: (
                                       <span><strong>{(Math.round(_.sum(this.data.map(x => {
                                           let payment = x.storeOrderPayments
                                               .find(y => y.status !== "Failed");
                                           return payment ? payment.collection : 0;
                                       })) * 100) / 100).toFixed(2) + " \u20AC" || "0"}</strong></span>
                                   )
                                },
                                {
                                   Header: <div>{getText( "HistoProductsQty")}</div>,
                                   id: 'products',
                                   accessor: x => x,
                                   width: 100,
                                   Cell: row => {
                                    if (row.value.orderDate){
                                       let storeOrder = row.value as Api.StoreOrderModel;
                                       return (
                                           <div>
                                               <Tooltip overlay={<div style={{
                                                   width: 400,
                                                   display: "flex",
                                                   flexDirection: "column",
                                                   alignItems: "stretch"
                                               }}>
                                                   {storeOrder.storeOrderItemOrders
                                                       .filter(x => (x.storeOrderItemOrderType === "Product" || x.storeOrderItemOrderType === "ExternalProduct"))
                                                       .map(itemOrder => {
                                                           let qty = itemOrder.storeOrderMenuOrderId
                                                               ? _.sum(storeOrder.storeOrderItemOrders
                                                                   .filter(x => x.storeOrderItemOrderId === itemOrder.storeOrderMenuOrderId)
                                                                   .map(x => x.quantity))
                                                               : itemOrder.quantity;
                                                           return (
                                                               <div key={itemOrder.storeOrderItemOrderId} style={{
                                                                   display: "flex",
                                                                   flexDirection: "row",
                                                                   alignItems: "center"
                                                               }}>
                                                                   <div style={{ flex: 1 }}>{this.props.storeType == "Ximiti" ? this.props.seed.products[itemOrder.productId].name
                                                                       : this.props.seed.externalProducts[itemOrder.externalProductId].name}</div>
                                                                   <div style={{ flex: "0 0 auto" }}>{storeOrder.storeOrderProductDeliveries
                                                                       .filter(x => x.storeOrderProductOrderId === itemOrder.storeOrderItemOrderId
                                                                           && x.status === "Ok")
                                                                       .length}</div>
                                                                   <div style={{ flex: "0 0 auto" }}> / </div>
                                                                   <div style={{ flex: "0 0 auto" }}>{qty}</div>
                                                               </div>
                                                           );
                                                       })}
                                               </div>}>
                                                   <div>
                                                       {storeOrder.storeOrderProductDeliveries
                                                           .filter(x => x.status === "Ok").length}
                                                       {" / "}
                                                       {_.sum(storeOrder.storeOrderItemOrders
                                                           .filter(x => (x.storeOrderItemOrderType === "Product" || x.storeOrderItemOrderType === "ExternalProduct"))
                                                           .map(x => x.quantity))
                                                       + _.sum(storeOrder.storeOrderItemOrders
                                                           .filter(x => x.storeOrderItemOrderType === "Menu")
                                                           .map(x => x.quantity
                                                               * storeOrder.storeOrderItemOrders.filter(y => y.storeOrderMenuOrderId === x.storeOrderItemOrderId).length))}
                                                   </div>
                                               </Tooltip>
                                           </div>
                                           
                                       );
                                        }
                                       else
                                        return "";
                                   },
                                   Footer: ( f => {
                                       let storeOrderProductDeliveriesLength = _.sum(_.map(this.data, x => (x as Api.StoreOrderModel).storeOrderProductDeliveries.filter(x => x.status === "Ok").length));
                                       let storeOrderItemOrdersQuantity = this.props.storeType == "Ximiti" ? 
                                            _.sum(_.map(this.data, x => _.sum((x as Api.StoreOrderModel).storeOrderItemOrders.filter(y => y.storeOrderItemOrderType === "Product").map(x => x.quantity))))
                                            +  _.sum(_.map(this.data, x => _.sum(
                                                (x as Api.StoreOrderModel).storeOrderItemOrders.filter(y => y.storeOrderItemOrderType === "Menu")
                                                    .map(y => y.quantity * x.storeOrderItemOrders.filter(z => z.storeOrderMenuOrderId === y.storeOrderItemOrderId).length))))
                                           : _.sum(_.map(this.data, x => _.sum((x as Api.StoreOrderModel).storeOrderItemOrders.filter(y => (y.storeOrderItemOrderType === "ExternalProduct")).map(x => x.quantity))));
                                       return <span><strong>{Math.max(storeOrderProductDeliveriesLength,0)} / {Math.max(storeOrderItemOrdersQuantity,0)}</strong></span>
                                   })
                                },
                                {
                                   Header: <div></div>,
                                   id: 'actions',
                                   accessor: x => x,
                                   width: 120,
                                   Cell: row => {
                                        if (row.value.orderDate){
                                            let storeOrder = row.value as Api.StoreOrderModel;
                                            return (
                                                <div>
                                                    <a onClick={(e) => this.props.openReceiptDialog(storeOrder)}>{getText("HistoDisplayTickets")}</a>
                                                </div>
                                            );
                                        }
                                        else
                                        return "";
                                   }
                                },
                                {
                                    Header: <div>{getText( "HistoWord")}</div>,
                                    id: 'storeItemOrderName',
                                    minWidth: 250,
                                    accessor: x => <div style={{textAlign: 'left', paddingLeft: 2}}>{x.storeItemOrderName}</div>
                                },
                                {
                                    Header: <div>{getText( "HistoQty")}</div>,
                                    id: 'storeItemOrderQty',
                                    accessor: x => x.storeItemOrderQty,
                                   width: 80
                                },
                                {
                                    Header: <div>{getText( "HistoPVTTC")}</div>,
                                    id: 'storeItemOrderPrice',
                                    accessor: x => `${(x.storeItemOrderPrice).toFixed(2)} ${this.props.currencies[x.storeItemOrderCurrency].symbol}`,
                                    width: 100
                                },
                                {
                                    Header: <div>{`% ${getText("HistoMargin")}`}</div>,
                                    id: 'storeItemOrderMarge',
                                    accessor: x => (x.storeItemOrderMarge * 100).toFixed(2),
                                    width: 100
                                }
                           ] as Array<NeoOrderColumn>}
                           data={this.dataWithProducts}
                    />
                </div>
            </div>
        );
    }
}