import * as React from 'react';
import * as PeriodStore from "../store/Period";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import DialogModel from "./DialogModal";
import Periods from "./Periods";
import { getText } from '../utils/texts';

type PeriodDialogProps = PeriodDialogOwnProps
    & typeof PeriodStore.actionCreators;

interface PeriodDialogOwnProps {
    isDialogOpen: boolean;
}

class PeriodDialog extends React.Component<PeriodDialogProps, {}> {
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <DialogModel contentLabel={getText( "PricePeriodModalPeriods")}
                contentStyle={{ 
                    background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                    border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                    width:'60%',  
                    padding: '0 10px' }}
                title={getText( "PricePeriodModalPeriods")}
                onRequestClose={() => this.props.closePeriodDialog()}
                isOpen={this.props.isDialogOpen}>
                <Periods />
            </DialogModel>
            );
    }
}

export default connect((state: ApplicationState) => ({
    isDialogOpen: state.period.isDialogOpen
} as PeriodDialogOwnProps), { ...PeriodStore.actionCreators })(PeriodDialog as any);