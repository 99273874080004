import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/texts';
import * as _ from 'lodash';

type TextTranslationHmiFormProps = ReduxForm.InjectedFormProps<Api.TextTranslationHmiModel, {}, {}>
    & { submitting: boolean; pristine: boolean; }
    & TextTranslationHmiFormOwnProps
    & TextTranslationHmiFormExternalProps;

interface TextTranslationHmiFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.TextTranslationHmiModel) => any;
}

interface TextTranslationHmiFormOwnProps {
    languagesState: Array<Api.LanguageModel>;
}

const inputStyle: React.CSSProperties = {minWidth: 300, maxWidth: 300, height: 35};

const textAreaField = FormField.textAreaField(4, 45);

class TextTranslationHmiForm extends React.Component<TextTranslationHmiFormProps, {}> {
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    public render() {
        return (
            <FormField.BaseForm 
                {...this.props}
                className="form-vertical" 
                style={{ maxWidth: '50%' }}>
                <div className="row" style={{padding: '0 5px', textAlign: 'left', marginTop: 5}}>
                    <div className="col-md-12 form-group">
                        <label className="control-label">{getText( "SettingsTextTransHmiLanguage")}  </label>
                        <ReduxForm.Field 
                            style={inputStyle}
                            name={'languageId'}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(
                                this.props.languagesState
                                    .map(x => ({
                                        label: x.name,
                                        value: x.languageId
                                    })))} 
                        />
                    </div>
                    <div className="col-md-12 form-group">
                        <label className="control-label">{getText( "SettingsTextTransHmi")}  </label>
                        <ReduxForm.Field
                            name="translation"
                            type={"text"}
                            style={{width: '100%'}}
                            className="form-control"
                            validate={[FormField.required]}
                            component={textAreaField} />
                    </div>
                    <div className="col-md-12 form-group">
                        <button className="btn btn-primary"
                            style={{width: '100%', height: 40, fontWeight: 'bold', fontSize: 15, 
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            type={"submit"}
                            disabled={this.props.submitting || this.props.pristine}>
                            {this.props.actionName}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createTextTranslationHmi'));

let createForm = ReduxForm.reduxForm({
    form: 'createTextTranslationHmi',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(TextTranslationHmiForm as any) as any;
const createSelector = ReduxForm.formValueSelector('createTextTranslationHmi');
export const CreateTextTranslationHmiForm = connect((state: ApplicationState) => {
    return {
        initialValues: {
           textHmiId: state.textTranslationHmi.selectedTextHmiId
        } as Api.TextTranslationHmiModel,
        languagesState: _.values(state.seed.seed.languages),
    } as TextTranslationHmiFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<TextTranslationHmiFormExternalProps>;

let updateForm = ReduxForm.reduxForm({
    form: 'updateTextTranslationHmi',
    enableReinitialize: true,
    destroyOnUnmount: true
})(TextTranslationHmiForm as any) as any;
const updateSelector = ReduxForm.formValueSelector('updateTextTranslationHmi');
export const UpdateTextTranslationHmiForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.textTranslationHmi.selectedTextHmiId && state.textTranslationHmi.selectedTextTranslationHmiId
            ? state.textTranslationHmi.textsHmi[state.textTranslationHmi.selectedTextHmiId].textsTranslations.find(x => x.textTranslationHmiId == state.textTranslationHmi.selectedTextTranslationHmiId)
            : {},
        languagesState: _.values(state.seed.seed.languages),
    } as TextTranslationHmiFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<TextTranslationHmiFormExternalProps>;