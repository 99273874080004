import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import LoginLgapForm from "./LoginLgapForm";

interface LoginLgapOwnProps {
    lgapUri: string;
 }

class LoginLgap extends React.Component<LoginLgapOwnProps, {}> {
   
    public render() {
        return (
            <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div 
                className="card"
                style={{
                    ...Styles.cardStyle,  
                    width: '40%',
                    padding: '8%',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                }}>
                    <LoginLgapForm lgapUri={this.props.lgapUri} />
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    lgapUri: state.seed.seed.storeAppSettings.lgapImportApiUrl
}), {})(LoginLgap as any) as any as React.ComponentClass<any>;
