import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as Download from "downloadjs";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import {NeoEventAdded} from "./NeoEvent";
import {createSelector} from "reselect";
import {getText} from "../utils/texts";
import * as _ from "lodash";
import * as HistoryStore from "./History";
import * as moment from "moment";

export interface HomeState {
    version: string;
    lastHomeUpdate?: number;
    neoEvents: {
        isLoading: boolean;
        requestTime?: number;
        neoEvents: Array<Api.NeoEventModel>;
        filter: Api.NeoEventFilter;
        downloadState: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
    // turnover: {
    //     isLoading: boolean;
    //     requestTime?: number;
    //     turnover: Api.TurnoverModel;
    // };
    lastPayments: {
        isLoading: boolean;
        requestTime?: number;
        lastPayments: Api.LastPaymentsModel;
    };
    smartPhoneState: {
        isLoading: boolean;
        requestTime?: number;
        smartPhoneStatus?: Api.SmartPhoneStatusModel;
    },
    alarmEventsState: {
        isLoading: boolean;
        requestTime?: number;
        neoEvents: Array<Api.NeoEventModel>;
        acquitStates: { [id: number]: { isLoading: boolean; requestTime?: number } }
    }
}

//Actions
interface HomeRequestneoEvents {
    type: "HOME_REQUEST_STORE_EVENTS";
    payload: { requestTime: number; }
}
interface HomeReceiveneoEvents {
    type: "HOME_RECEIVE_STORE_EVENTS";
    payload: { requestTime: number; neoEvents: Array<Api.NeoEventModel> };
    error?: any
}

// interface HomeRequestTurnover {
//     type: "HOME_REQUEST_TURNOVER";
//     payload: { requestTime: number; }
// }
// interface HomeReceiveTurnover {
//     type: "HOME_RECEIVE_TURNOVER";
//     payload: { requestTime: number; turnover: Api.TurnoverModel };
//     error?: any
// }

interface HomeRequestLastPayments {
    type: "HOME_REQUEST_LAST_PAYMENTS";
    payload: { requestTime: number; }
}
interface HomeReceiveLastPayments {
    type: "HOME_RECEIVE_LAST_PAYMENTS";
    payload: { requestTime: number; lastPayments: Api.LastPaymentsModel };
    error?: any
}

interface HomeSetLastUpdate {
    type: "HOME_SET_LASTUPDATE";
    payload: { time: number; }
}
interface HomeUpdateNeoEventFilter {
    type: "HOME_UPDATE_NEOEVENTFILTER";
    payload: { value: Api.NeoEventFilter; }
}

interface HomeSetLastUpdate {
    type: "HOME_SET_LASTUPDATE";
    payload: { time: number; }
}
interface HomeUpdateNeoEventFilter {
    type: "HOME_UPDATE_NEOEVENTFILTER";
    payload: { value: Api.NeoEventFilter; }
}

interface HomeRequestDownloadNeoEvents {
    type: "HOME_REQUEST_DOWNLOAD_NEOEVENTS";
    payload: { requestTime: number; }
}
interface HomeReceiveDownloadNeoEvents {
    type: "HOME_RECEIVE_DOWNLOAD_NEOEVENTS";
    payload: { requestTime: number; };
    error?: any
}

interface HomeRequestSmartPhoneStatus {
    type: "HOME_REQUEST_SMARTPHONESTATUS";
    payload: { requestTime: number; }
}
interface HomeReceiveSmartPhoneStatus {
    type: "HOME_RECEIVE_SMARTPHONESTATUS";
    payload: { requestTime: number; value?: Api.SmartPhoneStatusModel };
    error?: any
}

interface RequestAlarmNeoEvents {
    type: "REQUEST_ALARM_NEO_EVENTS";
    payload: { requestTime: number; }
}

interface ReceiveAlarmNeoEvents {
    type: "RECEIVE_ALARM_NEO_EVENTS";
    payload: { requestTime: number; neoEvents: Array<Api.NeoEventModel> };
    error?: any
}

export interface HomeSetVersion {
    type: "HOME_SET_VERSION";
    payload: { value: string }
}

type KnownAction = HomeRequestneoEvents | HomeReceiveneoEvents
    // | HomeRequestTurnover | HomeReceiveTurnover
    | HomeRequestLastPayments | HomeReceiveLastPayments
    | HomeSetLastUpdate
    | HomeUpdateNeoEventFilter
    | HomeRequestDownloadNeoEvents
    | HomeReceiveDownloadNeoEvents
    | HomeSetVersion
    | HomeRequestSmartPhoneStatus
    | HomeReceiveSmartPhoneStatus
    | RequestAlarmNeoEvents
    | ReceiveAlarmNeoEvents
    | NeoEventAdded
    ;

export const requestNeoEvents = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.NeoEventApi();
    let fetch = api.searchEvents({
        model: getState().home.neoEvents.filter
    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(neoEvents => {
            dispatch({
                type: "HOME_RECEIVE_STORE_EVENTS",
                payload: { requestTime: requestTime, neoEvents: neoEvents }
            });
        })
        .catch(error => {
            dispatch({
                type: "HOME_RECEIVE_STORE_EVENTS",
                payload: { requestTime: requestTime, neoEvents: [] },
                error: error
            });
        });

    dispatch({ type: "HOME_REQUEST_STORE_EVENTS", payload: { requestTime: requestTime } });
    return fetch;
}

export const requestAlarmEvents = (requestTime: number, dispatch: (action: KnownAction) => any, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.NeoEventApi();
    let fetch = api.getAlarmEvents({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(neoEvents => {
            dispatch({
                type: "RECEIVE_ALARM_NEO_EVENTS",
                payload: { requestTime: requestTime, neoEvents: neoEvents }
            });
        })
        .catch(error => {
            dispatch({
                type: "RECEIVE_ALARM_NEO_EVENTS",
                payload: { requestTime: requestTime, neoEvents: [] },
                error: error
            });
        });

    dispatch({ type: "REQUEST_ALARM_NEO_EVENTS", payload: { requestTime: requestTime } });
    addTask(fetch);
    return fetch;
}

export const requestTheLastPayments = (requestTime: number, dispatch: (a: KnownAction) => void, getState : () => ApplicationState): Promise<any>=> {
    let api = new Api.PaymentApi();
    let fetch = api.getLastPayments(
        { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(lastPayments => {
            dispatch({
                type: "HOME_RECEIVE_LAST_PAYMENTS",
                payload: { requestTime: requestTime, lastPayments: lastPayments }
            });
        })
        .catch(err => {
            dispatch({
                type: "HOME_RECEIVE_LAST_PAYMENTS",
                payload: { requestTime: requestTime, lastPayments: null },
                error: err
            });
        });

    dispatch({ type: "HOME_REQUEST_LAST_PAYMENTS", payload: { requestTime: requestTime } });
    return fetch;
}

export const actionCreators = {
    updateNeoEventFilter: (model: Api.NeoEventFilter) => <HomeUpdateNeoEventFilter>{
        type: "HOME_UPDATE_NEOEVENTFILTER",
        payload: { value: model }
    },
    requestDownloadNeoEvents: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventApi();
        let task = api.downloadEvents({
            model: getState().home.neoEvents.filter
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "HOME_RECEIVE_DOWNLOAD_NEOEVENTS", payload: { requestTime: requestTime } });
                let fileName = "Events_" + Moment().format("YYYY_MM_DD") + ".csv";
                return Download(blob,
                    fileName,
                    "text/csv");
            }).catch(err => {
                dispatch({ type: "HOME_RECEIVE_DOWNLOAD_NEOEVENTS", payload: { requestTime: requestTime }, error: err });
            });

        dispatch({ type: "HOME_REQUEST_DOWNLOAD_NEOEVENTS", payload: { requestTime: requestTime } });
        return task;
    },
    requestNeoEvents: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestNeoEvents(requestTime, dispatch, getState);
    },
    requestAlarmEvents: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestAlarmEvents(requestTime, dispatch, getState);
    },
    requestLastPayments: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestTheLastPayments(requestTime, dispatch, getState);
    },
    homeSetLastUpdate: (time: number) => <HomeSetLastUpdate>{
        type: "HOME_SET_LASTUPDATE",
        payload: { time: time }
    },
    setVersion: (value: string) => <HomeSetVersion>{
        type: "HOME_SET_VERSION",
        payload: { value: value }
    },
    requestSmartPhoneStatus: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ApplicationApi();
        let fetch = api.smartPhoneStatus({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(smartPhoneStatus => {
                dispatch({
                    type: "HOME_RECEIVE_SMARTPHONESTATUS",
                    payload: { requestTime: requestTime, value: smartPhoneStatus }
                });
            })
            .catch(err => {
                dispatch({
                    type: "HOME_RECEIVE_SMARTPHONESTATUS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({ type: "HOME_REQUEST_SMARTPHONESTATUS", payload: { requestTime: requestTime } });
        return fetch;
    },
};

const todayStoreDataSelector = (state: ApplicationState) => state.history.todayData.dayStoreData;
const todayStoreOrderSelector = (state: ApplicationState) => state.history.todayData.dayStoreOrders;
const seedSelector = (state: ApplicationState) => state.seed.seed;
const neoEventsSelector = (state: ApplicationState) => state.home.neoEvents.neoEvents;

export type SmartPhoneTypeEnum = "SmartPhone" | "CE" | "Livraison";

const getSmartValue = (type: SmartPhoneTypeEnum, todayStoreOrders: Array<Api.StoreOrderModel>) => {
    let storeOrders;
    let valueSum = 0;
    switch(type){
        case 'SmartPhone':
            storeOrders = todayStoreOrders
                .filter(x => x.platform == null)
                .map(x => _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0]);
            break;
        case 'Livraison':
            storeOrders = todayStoreOrders
                .filter(x => x.platform != null && x.platform.remoteId === "4")
                .map(x => _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0]);
            break;
        case 'CE':
            storeOrders = todayStoreOrders
                .filter(x => x.platform != null && x.platform.remoteId !== "4")
                .map(x => _.sortBy(x.storeOrderPayments, p => p.status !== "Success")[0]);
            break;
    }
    valueSum = _.sum(storeOrders
        .filter(x => x.status == "Success" && x.paymentType === "SmartPhone"
            && x.collection)
        .map(x => x.collection))

    return valueSum >= 0 ? valueSum : 0;
}
    
const coinsChart = (todayPayments: Array<Api.StoreOrderPaymentModel>, seed: Api.SeedMngModel) => {
    let coins = 0;
    todayPayments.filter(x => x.status == "Success" && x && x.paymentType == "Cash" && x.collection && x.status && x.status == "Success")
        .forEach(paymentIn => {
            let partialCoins = 0;
            partialCoins = _.sum(paymentIn.storeMoneyTraffics
                .map(x => x.type == "PaymentIn" && seed.moneys[x.moneyId].type == "Coin" ?
                    (x.quantity * seed.moneys[x.moneyId].value) : 0));
            if(partialCoins > paymentIn.collection) {
                partialCoins = partialCoins - (partialCoins - paymentIn.collection);
            }
            coins = coins + partialCoins;
        });
    return coins >= 0 ? coins : 0;
}
    
const billsChart = (todayPayments: Array<Api.StoreOrderPaymentModel>, seed: Api.SeedMngModel): number => {
    let bills = 0;
    todayPayments.filter(x => x.status == "Success" &&  x && x.paymentType == "Cash" && x.collection && x.status && x.status == "Success")
        .forEach(paymentIn => {
            let partialBills = 0;
            let partialCoins = 0;
            partialBills = _.sum(paymentIn.storeMoneyTraffics
                .map(x => x.type == "PaymentIn" && seed.moneys[x.moneyId].type == "Bill" ?
                    (x.quantity * seed.moneys[x.moneyId].value) : 0));
            partialCoins = _.sum(paymentIn.storeMoneyTraffics
                .map(x => x.type == "PaymentIn" && seed.moneys[x.moneyId].type == "Coin" ?
                    (x.quantity * seed.moneys[x.moneyId].value) : 0));
            if(partialBills > paymentIn.collection) {
                partialBills = partialBills - (partialBills - paymentIn.collection);
                if(partialCoins > 0) {
                    partialBills = partialBills - partialCoins;
                }
            }else if(partialBills > partialCoins && partialCoins > 0) {
                partialBills = (paymentIn.collection - partialCoins);
            }
            bills = bills + partialBills;
        });
    return bills >= 0 ? bills : 0;
}

export const paymentGraphSelector = createSelector(todayStoreDataSelector, seedSelector, (todayStoreData, seed) => {
    console.log("Calculating graph");
    let todayPayments = HistoryStore.todayPayments(todayStoreData);
    let todayStoreOrders = HistoryStore.todayStoreOrders(todayStoreData);
    
    let cash = coinsChart(todayPayments, seed);
    let delivery = getSmartValue('Livraison', todayStoreOrders);
    let smartPhone = getSmartValue('SmartPhone', todayStoreOrders);
    let bills = billsChart(todayPayments, seed);
    let cardCc = _.sum(todayPayments
        .filter(x => x.status == "Success" && x.paymentType === "Card"
            && x.cardType === "Normal"
            && x.collection)
        .map(x => x.collection));
    let cardNoContact = _.sum(todayPayments
        .filter(x => x.status == "Success" && x.paymentType === "Card"
            && x.cardType === "NoContact"
            && x.collection)
        .map(x => x.collection));
    let smartCe = getSmartValue('CE', todayStoreOrders);


    let objectLabels = [
        {
            id: "Livraison",
            valueSum: delivery,
            labelText: getText( "HomeGraphLabel9"),
            colorRGB: "rgb(102, 170, 0)",
            isSideLeft: false,
            graphIndex: 1
        },
        {
            id: "TR",
            valueSum: 0,
            labelText: getText( "HomeGraphLabel7"),
            colorRGB: "rgb(221, 68, 119)",
            isSideLeft: false,
            graphIndex: 2
        },
        {
            id: "BILL",
            valueSum: bills,
            labelText: getText( "HomeGraphLabel6"),
            colorRGB: "rgb(0, 153, 198)",
            isSideLeft: false,
            graphIndex: 3
        },
        {
            id: "SMAR",
            valueSum: smartPhone,
            labelText: getText( "HomeGraphLabel5"),
            colorRGB: "rgb(153, 0, 153)",
            isSideLeft: false,
            graphIndex: 4
        },
        {
            id: "CB",
            valueSum: cardCc,
            labelText: getText( "HomeGraphLabel4"),
            colorRGB: "rgb(16, 150, 24)",
            isSideLeft: false,
            graphIndex: 5
        },
        {
            id: "CE",
            valueSum: smartCe,
            labelText: getText( "HomeGraphLabel3"),
            colorRGB: "rgb(255, 153, 0)",
            isSideLeft: false,
            graphIndex: 6
        },
        {
            id: "NFC",
            valueSum: cardNoContact,
            labelText: getText( "HomeGraphLabel2"),
            colorRGB: "rgb(220, 57, 18)",
            isSideLeft: false,
            graphIndex: 7
        },
        {
            id: "ESP",
            valueSum: cash,
            labelText: getText( "HomeGraphLabel1"),
            colorRGB: "rgb(51, 102, 204)",
            isSideLeft: false,
            graphIndex: 8
        }
    ]

    let totalSum = objectLabels.reduce((sum, value) => (sum + value.valueSum), 0);
    let auxArrayLeft = [];
    let auxArrayRight = [];
    let middleValue = Math.round(objectLabels.length/2);

    for(let x = 0; x < objectLabels.length; x++){
        let sumAux = 0;
        let prevPercentage = 0;
        if(x < middleValue){
            for(let y = 0; y < x; y++){
                sumAux += objectLabels[y].valueSum;
            }
            prevPercentage = (sumAux / totalSum) * 100;
            objectLabels[x].isSideLeft = prevPercentage > 50 ? false : true;
        }
        else{
            for(let y = (x+1); y < objectLabels.length; y++){
                sumAux += objectLabels[y].valueSum;
            }
            prevPercentage = (sumAux / totalSum) * 100;
            objectLabels[x].isSideLeft = prevPercentage > 50 ? true : false;
        }
    }

    objectLabels.sort(((a, b) => b.valueSum - a.valueSum));

    objectLabels.forEach((x) => {
        if((x.isSideLeft && auxArrayLeft.length < middleValue) || auxArrayRight.length == middleValue)
            auxArrayLeft.push(x);
        else
            auxArrayRight.push(x);
    })

    auxArrayLeft.sort((a, b) => {
        if(a.valueSum != 0)
            return  a.graphIndex - b.graphIndex
        else
            return 1
    });
    auxArrayRight.sort((a, b) => {
        if(a.valueSum != 0)
            return  b.graphIndex - a.graphIndex
        else
            return 1
    });
    let labels = auxArrayLeft.concat(auxArrayRight);
    
    
    return {
        graphData: [
            ['Type', 'Daily turnover'],
            [
                getText( "HomeGraphLabel1"), // ESP-color: rgb(51, 102, 204);
                cash
            ],
            [
                getText( "HomeGraphLabel2"), // NFC-color: rgb(220, 57, 18);
                cardNoContact
            ],
            [
                getText( "HomeGraphLabel3"),// CE-color: rgb(255, 153, 0);
                smartCe
            ],
            [
                getText( "HomeGraphLabel4"),// CB-color: rgb(16, 150, 24);
                cardCc
            ],
            [
                getText( "HomeGraphLabel5"), // SMAR-color: rgb(153, 0, 153);
                smartPhone
            ],
            [
                getText( "HomeGraphLabel6"),// BILL-color: rgb(0, 153, 198);
                bills
            ],
            [
                getText( "HomeGraphLabel7"),// TR-color: rgb(221, 68, 119);
                0
            ],
            // [
            //     getText( "HomeGraphLabel8"),// ATT-color: rgb(102, 170, 0);;
            //     0
            // ],
            [
                getText( "HomeGraphLabel9"), // Livraison-color: rgb(102, 170, 0);
                delivery
            ]
        ],
        graphLabels: labels || []
    }
})

export const totalSelector = createSelector(todayStoreDataSelector, seedSelector, (todayStoreData, seed) => {
        let todayOrderMoneyTraffics = HistoryStore.todayOrderMoneyTraffics(todayStoreData);
        let todayPayments = HistoryStore.todayPayments(todayStoreData);
        return {
            totalCashRegister: _.sum(todayOrderMoneyTraffics
                    .map(x => (x.type === "PaymentIn" ? x.quantity : -x.quantity)
                        * seed.moneys[x.moneyId].value))
                + _.sum(todayPayments
                    .filter(x => x.status == "Success" && x.paymentType !== "Cash" && x.collection)
                    .map(x => x.collection)),
            totalTurnover: todayStoreData
                ? _.sum(todayStoreData.storeOrders.map(x => _.sum(x.storeOrderPayments.filter(y => y.status !== "Failed").map(y => y.collection || 0))))
                : 0
        };
})

export const homeNeoEventsSelector = createSelector(neoEventsSelector, (neoEvents) => {
    return _.sortBy(
        neoEvents || [],
        x => -new Date(x.creationDate).getTime());
})

const getTotalClients = (todayStoreOrders: Array<Api.StoreOrderModel>, seed: Api.SeedMngModel, startHour: Date, index: number) => {
    let storeOrders : Array<Api.StoreOrderModel> = seed.storeAppSettings.typeStore == "Ximiti" 
        ? todayStoreOrders.filter(x => x.status == "Delivered")
        : todayStoreOrders.filter(x => x.type == "PreOrder" && x.status == "Delivered");

    let valueSum = 0;
    let start = Moment(startHour).add(index, 'hours').toDate();
    let end = Moment(start).add(3, 'hours').toDate();
    let min = new Date(start.getFullYear(),start.getMonth(),start.getDate(),start.getHours(),start.getMinutes(),start.getSeconds());
    let max = new Date(end.getFullYear(),end.getMonth(),end.getDate(),end.getHours(),end.getMinutes(),start.getSeconds()-1);

    storeOrders.forEach(x => {
        let toLocal =  moment.utc(seed.storeAppSettings.typeStore == "Ximiti" 
            ? x.orderDate 
            : x.deliveredDate).toDate();
        if(Moment(toLocal).isBetween(min, max)){
            valueSum++;
        }
    });
    return valueSum >= 0 ? valueSum : 0;
}
const getTotalProducts = (todayStoreOrders: Array<Api.StoreOrderModel>, seed: Api.SeedMngModel, startHour: Date, index: number) => {
    let storeOrders : Array<Api.StoreOrderModel> = seed.storeAppSettings.typeStore == "Ximiti" 
        ? todayStoreOrders.filter(x => x.status == "Delivered")
        : todayStoreOrders.filter(x => x.type == "PreOrder" && x.status == "Delivered");

    let valueSum = 0;
    let start = Moment(startHour).add(index, 'hours').toDate();
    let end = Moment(start).add(3, 'hours').toDate();
    let min = new Date(start.getFullYear(),start.getMonth(),start.getDate(),start.getHours(),start.getMinutes(),start.getSeconds());
    let max = new Date(end.getFullYear(),end.getMonth(),end.getDate(),end.getHours(),end.getMinutes(),start.getSeconds()-1);

    storeOrders.forEach(x => {
        x.storeOrderProductDeliveries.forEach(y => {
            let toLocal =  moment.utc(y.deliveryDate).toDate();
            if(Moment(toLocal).isBetween(min, max)){
                valueSum++;
            }
        })
    });
    return valueSum >= 0 ? valueSum : 0;
}
const getAccumulation = (index: number, auxDataClients: Array<number>) => {
    let sum = 0;
    for(let x = 0 ; x < index ; x++ ){
        sum+=auxDataClients[x];
    }
    return sum;
}

export const clientsGraphSelector = createSelector(todayStoreOrderSelector, seedSelector, (todayStoreOrders, seed) => {
    if(!todayStoreOrders)
        return [];
    let todayStoreOrders24h = HistoryStore.todayStoreOrders24h(todayStoreOrders);
    console.log("Calculating clients graph...");
    let startHour = Moment().subtract(1, 'days').toDate()
    let auxDataClients = [
        getTotalClients(todayStoreOrders24h, seed, startHour,0),
        getTotalClients(todayStoreOrders24h, seed, startHour,3),
        getTotalClients(todayStoreOrders24h, seed, startHour,6),
        getTotalClients(todayStoreOrders24h, seed, startHour,9),
        getTotalClients(todayStoreOrders24h, seed, startHour,12),
        getTotalClients(todayStoreOrders24h, seed, startHour,15),
        getTotalClients(todayStoreOrders24h, seed, startHour,18),
        getTotalClients(todayStoreOrders24h, seed, startHour,21)
    ];
    let acumm = getAccumulation(8, auxDataClients);
    let maxNumberClients = (acumm > 0 ? (acumm+1) : 1)
    
    return {
        graphData: [
            [
                getText("HomeComboChartHour"),
                getText("HomeComboCharSold"),
                getText("HomeComboChartTotal"),
            ],
            [`${Moment().subtract(24, 'h').hour()}h-${Moment().subtract(21, 'h').hour()}h`, auxDataClients[0], auxDataClients[0]],
            [`${Moment().subtract(21, 'h').hour()}h-${Moment().subtract(18, 'h').hour()}h`, auxDataClients[1], getAccumulation(2, auxDataClients)],
            [`${Moment().subtract(18, 'h').hour()}h-${Moment().subtract(15, 'h').hour()}h`, auxDataClients[2], getAccumulation(3, auxDataClients)],
            [`${Moment().subtract(15, 'h').hour()}h-${Moment().subtract(12, 'h').hour()}h`, auxDataClients[3], getAccumulation(4, auxDataClients)],
            [`${Moment().subtract(12, 'h').hour()}h-${Moment().subtract(9, 'h').hour()}h`, auxDataClients[4], getAccumulation(5, auxDataClients)],
            [`${Moment().subtract(9, 'h').hour()}h-${Moment().subtract(6, 'h').hour()}h`, auxDataClients[5], getAccumulation(6, auxDataClients)],
            [`${Moment().subtract(6, 'h').hour()}h-${Moment().subtract(3, 'h').hour()}h`, auxDataClients[6], getAccumulation(7, auxDataClients)],
            [`${Moment().subtract(3, 'h').hour()}h-${Moment().hour()}h`, auxDataClients[7], getAccumulation(8, auxDataClients)],
        ],
        graphMax: maxNumberClients
    };
});

export const productsGraphSelector = createSelector(todayStoreOrderSelector, seedSelector, (todayStoreOrders, seed) => {
    if(!todayStoreOrders)
        return [];

    let todayStoreOrders24h = HistoryStore.todayStoreOrders24h(todayStoreOrders);
    console.log("Calculating products graph...");
    let startHour = Moment().subtract(1, 'days').toDate()
    let auxDataProducts = [
        getTotalProducts(todayStoreOrders24h, seed, startHour,0),
        getTotalProducts(todayStoreOrders24h, seed, startHour,3),
        getTotalProducts(todayStoreOrders24h, seed, startHour,6),
        getTotalProducts(todayStoreOrders24h, seed, startHour,9),
        getTotalProducts(todayStoreOrders24h, seed, startHour,12),
        getTotalProducts(todayStoreOrders24h, seed, startHour,15),
        getTotalProducts(todayStoreOrders24h, seed, startHour,18),
        getTotalProducts(todayStoreOrders24h, seed, startHour,21)
    ];
    let acumm = getAccumulation(8, auxDataProducts);
    let maxNumberProducts = (acumm > 0 ? (acumm+1) : 1)
    
    return {
        graphData: [
            [
                getText("HomeComboChartHour"),
                getText("HomeComboCharSold"),
                getText("HomeComboChartTotal"),
            ],
            [`${Moment().subtract(24, 'h').hour()}h-${Moment().subtract(21, 'h').hour()}h`, auxDataProducts[0], auxDataProducts[0]],
            [`${Moment().subtract(21, 'h').hour()}h-${Moment().subtract(18, 'h').hour()}h`, auxDataProducts[1], getAccumulation(2, auxDataProducts)],
            [`${Moment().subtract(18, 'h').hour()}h-${Moment().subtract(15, 'h').hour()}h`, auxDataProducts[2], getAccumulation(3, auxDataProducts)],
            [`${Moment().subtract(15, 'h').hour()}h-${Moment().subtract(12, 'h').hour()}h`, auxDataProducts[3], getAccumulation(4, auxDataProducts)],
            [`${Moment().subtract(12, 'h').hour()}h-${Moment().subtract(9, 'h').hour()}h`, auxDataProducts[4], getAccumulation(5, auxDataProducts)],
            [`${Moment().subtract(9, 'h').hour()}h-${Moment().subtract(6, 'h').hour()}h`, auxDataProducts[5], getAccumulation(6, auxDataProducts)],
            [`${Moment().subtract(6, 'h').hour()}h-${Moment().subtract(3, 'h').hour()}h`, auxDataProducts[6], getAccumulation(7, auxDataProducts)],
            [`${Moment().subtract(3, 'h').hour()}h-${Moment().hour()}h`, auxDataProducts[7], getAccumulation(8, auxDataProducts)],
        ],
        graphMax: maxNumberProducts
    };
});

const unloadedState: HomeState = {
    version: "NO_VERSION",
    neoEvents: {
        isLoading: false,
        neoEvents: [],
        filter: {
            fromDate: Moment().add(-new Date().getTimezoneOffset(), "minutes").startOf('day').toDate()
        },
        downloadState: {
            isLoading: false
        }
    },
    lastPayments: {
        isLoading: false,
        lastPayments: null
    },
    // turnover: {
    //     isLoading: false,
    //     turnover: null
    // },
    smartPhoneState: {
        isLoading: false
    },
    alarmEventsState: {
        isLoading: true,
        neoEvents: [],
        acquitStates: {}
    }
};

export const reducer: Reducer<HomeState> = (state: HomeState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "HOME_SET_VERSION":
            return {
                ...state,
                version: action.payload.value
            };
        case "HOME_REQUEST_STORE_EVENTS":
            return {
                ...state,
                neoEvents: {
                    ...state.neoEvents,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "HOME_RECEIVE_STORE_EVENTS":
            if (action.payload.requestTime !== state.neoEvents.requestTime)
                return state;

            return {
                ...state,
                neoEvents: {
                    ...state.neoEvents,
                    isLoading: false,
                    neoEvents: action.error
                        ? state.neoEvents.neoEvents
                        : action.payload.neoEvents
                }
            };
        case "NEO_EVENT_ADDED":
            return {
                ...state,
                neoEvents: {
                    ...state.neoEvents,
                    neoEvents: state.neoEvents.neoEvents.concat([ action.payload.value ])
                }
            };
        case "HOME_REQUEST_LAST_PAYMENTS":
            return {
                ...state,
                lastPayments: {
                    ...state.lastPayments,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "HOME_RECEIVE_LAST_PAYMENTS":
            if (action.payload.requestTime !== state.lastPayments.requestTime)
                return state;

            return {
                ...state,
                lastPayments: {
                    ...state.lastPayments,
                    isLoading: false,
                    lastPayments: action.error
                        ? state.lastPayments.lastPayments
                        : action.payload.lastPayments
                }
            };
        // case "HOME_REQUEST_TURNOVER":
        //     return {
        //         ...state,
        //         turnover: {
        //             ...state.turnover,
        //             isLoading: true,
        //             requestTime: action.payload.requestTime
        //         }
        //     };
        // case "HOME_RECEIVE_TURNOVER":
        //     if (action.payload.requestTime !== state.turnover.requestTime)
        //         return state;
        //
        //     return {
        //         ...state,
        //         turnover: {
        //             ...state.turnover,
        //             isLoading: false,
        //             turnover: action.error
        //                 ? state.turnover.turnover
        //                 : action.payload.turnover
        //         }
        //     };
        case "HOME_SET_LASTUPDATE":
            return {
                ...state,
                lastHomeUpdate: action.payload.time
            };
        case "HOME_UPDATE_NEOEVENTFILTER": 
            return {
                ...state,
                neoEvents: {
                    ...state.neoEvents,
                    filter: action.payload.value
                }
            };
        case "HOME_REQUEST_DOWNLOAD_NEOEVENTS":
            return {
                ...state,
                neoEvents: {
                    ...state.neoEvents,
                    downloadState: {
                        ...state.neoEvents.downloadState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "HOME_RECEIVE_DOWNLOAD_NEOEVENTS":
            if (action.payload.requestTime !== state.neoEvents.downloadState.requestTime)
                return state;

            return {
                ...state,
                neoEvents: {
                    ...state.neoEvents,
                    downloadState: {
                        ...state.neoEvents.downloadState,
                        isLoading: false,
                    }
                }
            };
        case "HOME_REQUEST_SMARTPHONESTATUS":
            return {
                ...state,
                smartPhoneState: {
                    ...state.smartPhoneState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "HOME_RECEIVE_SMARTPHONESTATUS":
            if (action.payload.requestTime !== state.smartPhoneState.requestTime)
                return state;

            return {
                ...state,
                smartPhoneState: {
                    ...state.smartPhoneState,
                    isLoading: false,
                    smartPhoneStatus: action.error
                        ? state.smartPhoneState.smartPhoneStatus
                        : action.payload.value
                }
            };
        case "REQUEST_ALARM_NEO_EVENTS":
            return {
                ...state,
                alarmEventsState: {
                    ...state.alarmEventsState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_ALARM_NEO_EVENTS":
            if (action.payload.requestTime !== state.alarmEventsState.requestTime)
                return state;

            return {
                ...state,
                alarmEventsState: {
                    ...state.alarmEventsState,
                    isLoading: false,
                    neoEvents: action.error
                        ? action.payload.neoEvents
                        : action.payload.neoEvents
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};