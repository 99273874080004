import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from 'react-table';
import * as PeriodStore from '../store/Period';
import * as TabsStore from '../store/Tabs';
import { CreatePeriodForm, UpdatePeriodForm } from "./PeriodForm";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Table from './Table';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import { padLeft } from '../utils/utils';
import { getText } from '../utils/texts';
import Confirm from "react-confirm-bootstrap";
import Tooltip from "./Tooltip";

type PeriodsProps = PeriodStore.PeriodState
    & PeriodsOwnProps
    & typeof PeriodStore.actionCreators
    & typeof TabsStore.actionCreators;

interface PeriodsOwnProps {
    pricesPs: { [id: number]: Api.PricePeriodModel };
}

type PeriodColumn = ReactTable.Column
    & { accessor: (d: Api.PeriodModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class Periods extends React.Component<PeriodsProps, {}> {
    componentDidMount() {
        this.props.requestPeriods(new Date().getTime());
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{ minWidth: 600, marginTop:'2%' }}>
                <div>
                    <Table key={"periods"}
                        style={{ height: '20vh', textAlign: "center" } as React.CSSProperties}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        data={_.values(this.props.periods)}
                        columns={[
                            {
                                id: "name",
                                Header: getText( "PricePeriodName"),
                                accessor: (d) => d.name
                            },
                            {
                                id: "hours",
                                Header: getText( "PricePeriodModalHours"),
                                accessor: (d) => d.hourStart && d.hourEnd
                                    ? `${padLeft(d.hourStart, 2)}-${padLeft(d.hourEnd, 2)}`
                                    : <span>&nbsp;</span>
                            },
                            {
                                id: "months",
                                Header: getText( "PricePeriodModalMonth"),
                                accessor: (d) => d.monthStart && d.monthEnd
                                    ? `${padLeft(d.monthStart, 2)}-${padLeft(d.monthEnd, 2)}`
                                    : <span>&nbsp;</span>
                            },
                            {
                                id: "days",
                                Header: getText( "PricePeriodModalDays"),
                                accessor: (d) => [
                                    d.monday && getText( "PricePeriodMonday")
                                    , d.tuesday && getText( "PricePeriodTuesday")
                                    , d.wednesday && getText( "PricePeriodWednesday")
                                    , d.thursday && getText( "PricePeriodThursday")
                                    , d.friday && getText( "PricePeriodFriday")
                                    , d.saturday && getText( "PricePeriodSaturday")
                                    , d.sunday && getText( "PricePeriodSunday")]
                                    .filter(x => x)
                                    .join(", ")
                            },
                            {
                                id: "actions",
                                Header: getText( "PricePeriodActions"),
                                accessor: (d) => d.periodId,
                                Cell: row => {
                                    return <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                        <div className="icon-container-dm" style={{
                                            width: 20,
                                            height: 20,
                                            placeSelf: "center", 
                                            backgroundColor: this.isDarkMode() ? '#F2C94C' : '#007AFF',
                                            borderRadius: 5
                                        }}>
                                            <i className="glyphicon glyphicon-pencil icon-dm"
                                                style={{
                                                    margin: "11% 0", 
                                                    cursor: "pointer",
                                                    color: this.isDarkMode() ? '#181F3A' : "#FFFFFF" }}
                                                onClick={(e) => {
                                                    this.props.selectPeriod(row.value);
                                                    this.props.selectTab("periods", "update");
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </div>
                                        {_.values(this.props.pricesPs).some(x => x.periodId == row.value) ?
                                            <Tooltip overlay={<div>{getText( "PricePeriodTooltip")}</div>} >
                                            <div className="icon-container-dm" style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: "rgba(191, 191, 191, 1)",
                                                borderRadius: 5
                                            }}>
                                                <div style={{ color: "#FFFFFF", 
                                                        marginTop: '15%',
                                                        marginRight: 1,
                                                        cursor: "pointer"  }}>
                                                    <i className="glyphicon glyphicon-trash icon-dm"/>
                                                </div>
                                            </div>
                                        </Tooltip>
                                        :  <div className="icon-container-dm" style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: this.isDarkMode() ? '#F2C94C' : '#007AFF',
                                                borderRadius: 5
                                            }}>
                                                <Confirm
                                                    style={{ color: "#FFFFFF", 
                                                        marginTop: '15%',
                                                        marginRight: 1,
                                                        cursor: "pointer"  }}
                                                    onConfirm={() => {
                                                        this.props.requestDeletePeriod(new Date().getTime(), row.value);
                                                    }}
                                                    body={getText("MaintenanceBodyConfirmDelete")} 
                                                    confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}>
                                                        <i className="glyphicon glyphicon-trash icon-dm"/>
                                                </Confirm>
                                            </div>
                                        }
                                    </div>
                                }
                            }
                        ] as Array<PeriodColumn>} />
                    <div style={{marginTop: '1%'}}>
                        <ConnectedTabs name={"periods"}>
                            <div style={{backgroundColor: this.isDarkMode() ? 'rgba(59,67,112, 0.3)' : ''}}>
                                <TabLink style={{width: '50%'}} to="create">
                                    <div style={tabHeadStyle}>{getText( "PricePeriodCreate")}</div>
                                </TabLink>
                                <TabLink style={{width: '50%'}} to="update">
                                    <div style={tabHeadStyle}>{getText( "PricePeriodEdit")}</div>
                                </TabLink>
                            </div>
                            <TabContent for="create">
                                <div style={{ padding: '10px 10px 0 10px' }}>
                                    <CreatePeriodForm actionName={getText( "PricePeriodCreate")}
                                        onSubmit={(values) => this.props.requestCreatePeriod(new Date().getTime(), values)} />
                                </div>
                            </TabContent>
                            <TabContent for="update">
                                <div style={{ padding: '10px 10px 0 10px' }}>
                                    {this.props.selectedPeriodId
                                        && <UpdatePeriodForm actionName={getText( "PricePeriodSave")}
                                            onSubmit={(values) => this.props.requestUpdatePeriod(new Date().getTime(), values)} />}
                                </div>
                            </TabContent>
                        </ConnectedTabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.period,
    pricesPs: state.pricePeriod.pricePeriods
}), {
        ...PeriodStore.actionCreators,
        ...TabsStore.actionCreators
    })(Periods as any)