import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as ProductsDetailsStore from "../store/ProductsDetails";
import * as StockStore from "../store/Stock";
import Select from "./Select";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getImagePath, getStoreItemImage } from '../utils/utils';
import { ProductPriceCalculator, ProductPriceInfo } from '../utils/priceUtils';
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import { canUpdate } from '../security/roles';
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from "react-windowed-select";

type ProductsDetailsProps = ProductsDetailsOwnProps
    & ProductsDetailsStore.ProductsDetailsState
    & typeof StockStore.actionCreators
    & typeof ProductsDetailsStore.actionCreators;

interface ProductsDetailsOwnProps {
    priceNames: { [id: number]: Api.PriceNameModel };
    currencies: { [id: number]: Api.CurrencyModel };
    suppliers: { [id: number]: Api.SupplierModel };
    storeType: string;
    subFamilies: { [id: number]: Api.SubFamilyModel };
    products: { [id: number]: Api.ProductModel};
    externalProducts : { [id: number]: Api.ExternalProductModel};
    menuCategories: { [id: number]: Api.MenuCategoryModel };
    vats: { [id: number]: Api.VatModel };
    units: { [id: number]: Api.UnitModel };
    stock: StockStore.StockState;
    currentUser: Api.CurrentUserManagerModel;
    storeAppSettings: Api.StoreAppSettingsModel;
    disabled: boolean;
}

const textStyle: React.CSSProperties = {
    fontSize: 24,
    fontWeight: "bold",
};
const fieldStyle: React.CSSProperties = {
    padding: "1px 8px",
    display: "flex",
    alignItems: "center"
};

const inputStyle: React.CSSProperties = {
    flex: 1,
    marginLeft: "0.5rem"
}
const countryId = 1;

interface ProductsDetailsState {
    productsFilter: Array<Api.ProductModel>;
    externalProductsFilter: Array<Api.ExternalProductModel>;
}

const customFilter = createFilter({ ignoreAccents: true });
class ProductsDetails extends React.Component<ProductsDetailsProps, ProductsDetailsState> {
    priceCalculator: ProductPriceCalculator = null;

    constructor(props: ProductsDetailsProps) {
        super(props);
        this.state = {
            productsFilter: [],
            externalProductsFilter: []
        }
        this.handleSelectP = this.handleSelectP.bind(this);
    }

    get product(): any {
        return this.props.storeType == "Ximiti" ? this.props.products[this.props.productId] : this.props.externalProducts[this.props.productId];
    }

    get activePlanogram(): Api.PlanogramModel {
        return _.sortBy(this.props.stock.stockStatus.planograms, x => !x.active)[0];
    }

    getFilter(planogrammeFilter: boolean, familyId: number): Api.ProductDetailsFilterModel {
        return {
            filterByProducts: (planogrammeFilter !== null && planogrammeFilter !== undefined) ? planogrammeFilter : false,
            familyId: familyId && familyId > 0 ? familyId : 0
        } as Api.ProductDetailsFilterModel;
    }

    componentDidMount() {
        if(this.props.storeType == "Ximiti"){
            let filter = this.getFilter(null, this.props.familyId);
            this.props.updateFamilyId(undefined);
            this.props.updateSubFamilyId(undefined);
            this.props.updateProductId(undefined);
            this.props.updateFilterPlanogram(null);
            this.props.requestFamilies(new Date().getTime(), filter);
            this.setState({
                productsFilter: _.values(this.props.products).filter(x =>  x.enabled)
            });
            
            this.updateCalculator(this.props);
        }
        else{
            this.setState({
                externalProductsFilter: _.values(this.props.externalProducts).filter(x =>  x.enabled)
            });
        }
    }

    componentWillReceiveProps(nextProps: ProductsDetailsProps) {
        if(this.props.storeType == "Ximiti"){
            if (this.props.currencies !== nextProps.currencies
                || this.props.units !== nextProps.units
                || this.props.suppliers !== nextProps.suppliers
                || this.props.vats !== nextProps.vats) {
                this.updateCalculator(nextProps);
            }
        }
    }

    updateCalculator(props: ProductsDetailsProps) {
        this.priceCalculator = new ProductPriceCalculator(
            props.units,
            props.vats,
            props.suppliers,
            props.currencies)
    }

    getExpirityLabel(expirityType: string) {
        if (expirityType === "None") {
            return getText("SettingsExpirityTypeNone")
        } else if (expirityType === "Normal") {
            return getText("SettingsExpirityTypeNormal")
        } else if (expirityType === "Ddm") {
            return getText("SettingsExpirityTypeDdm")
        } else if (expirityType === "Eggs") {
            return getText("SettingsExpirityTypeEggs")
        }
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }


    handleFilter(value: boolean) {
        this.props.updateFilterPlanogram(value);
        this.props.updateProductId(undefined);
        
        if(this.props.storeType == "Ximiti"){
            if(value == true){
                this.setState({
                    productsFilter: _.values(this.props.products)
                    .filter(x =>  x.enabled
                        && (this.props.stock.stockStatus.productRails.some(z => z.planogramId === this.activePlanogram.planogramId && z.productId === x.storeItemId)))
                })
            }
            else{
                this.setState({
                    productsFilter:  _.values(this.props.products).filter(x =>  x.enabled)
                });
            }
            let filter = this.getFilter(value, this.props.familyId);
            this.props.updateFamilyId(undefined);
            this.props.updateSubFamilyId(undefined);
            this.props.requestFamilies(new Date().getTime(), filter);
        }
        else{
            if(value == true){
                this.setState({
                    externalProductsFilter: _.values(this.props.externalProducts)
                    .filter(x =>  x.enabled
                        && (this.props.stock.stockStatus.productRails.some(z => z.planogramId === this.activePlanogram.planogramId && z.externalProductId === x.storeItemId)))
                })
            }
            else{
                this.setState({
                    externalProductsFilter:  _.values(this.props.externalProducts).filter(x =>  x.enabled)
                });
            }
        }
        
    }

    handleSelectP(valueSelected){
        this.props.updateProductId(valueSelected.value);
    }

    public render() {

        let price: Api.PriceModel
            , productSupplier: Api.ProductSupplierModel
            , priceInfo: ProductPriceInfo;

        if (this.props.storeType == "Ximiti" && this.props.productId) {
            price = _.sortBy(this.product.prices,
                x => (x.priceId !== this.props.priceId ? 2 : 0)
                    + (x.priceNameId !== this.product.defaultPriceNameId ? 2 : 1))[0];
            productSupplier = _.sortBy(this.product.productSuppliers,
                x => (x.productSupplierId !== this.props.productSupplierId ? 2 : 0)
                    + (x.supplierMainId !== this.product.defaultSupplierId ? 2 : 1))[0];
            this.priceCalculator == null && this.updateCalculator(this.props);
            priceInfo = this.priceCalculator.calculate(
                this.product as any, //cause of model generation making stuff optional
                price,
                productSupplier);
        }

        let filterProductsPlano = this.props.storeType == "Ximiti" ?
            _.sortBy(this.state.productsFilter
                .filter(x => (!this.props.familyId
                        || x.productSubFamilies
                            .some(y => this.props.subFamilies[y.subFamilyId].familyId === this.props.familyId))
                    && (!this.props.subFamilyId
                        || x.productSubFamilies
                            .some(y => y.subFamilyId === this.props.subFamilyId))), x => x.name)
                .map(x => ({ label: x.name, value: x.storeItemId }))
            :
            _.sortBy(this.state.externalProductsFilter, x => x.name)
                    .map(x => ({ label: x.name, value: x.storeItemId }))

        const countryId = { "FR": 1, "CH": 6 }[this.props.storeAppSettings.storeCountry] || 1;
        
        return (<div className="products">
            <div 
            className="card"
            style={{
                ...Styles.cardStyle,
                ...Styles.pageTitle
            }}><span style={{paddingLeft: 30}}>{getText( "ProductsTitle")}</span> <HelpHeader /></div>
            <div className="selectors" style={{
                flex: "0 0 auto",
                display: "flex",
                justifyContent: 'space-between',
                alignItems: "center",
                padding: "0 0.5rem"
            }}>
                <div style={{ margin: "0px 10px", minWidth: '18%', flex: "0 0 auto" }}>
                    <span style={{ fontSize: 16}}>{getText( "ProductsInPlanogram")}</span>
                    <div className="card" style={{ ...Styles.cardStyle }}>
                    <Select options={[
                        { label: "------", value: null },
                        { label: getText("OfferYes"), value: true },
                        { label: getText("OfferNo"), value: false }
                        ]}
                        value={this.props.planogrammeFilter}
                        onChange={(value) => this.handleFilter(value)}
                        style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} /></div>
                </div>
                <div style={{ margin: "0px 10px", minWidth: '25%', flex: "0 0 auto" }}>
                    <span style={{ fontSize: 16}}>{getText( "ProductsFamily")}</span>
                    <div className="card" style={{ ...Styles.cardStyle }} >
                    <Select options={[{ label: "------", value: null }]
                        .concat(_.values(this.props.familiesState.families)
                        .map(x => ({ label: x.name, value: x.familyId }))
                        .sort((a, b) => a.label.localeCompare(b.label)))}
                        value={this.props.familyId}
                        disabled = {this.props.storeType == "Ximiti" ? false : true}
                        onChange={(value) => {
                            this.props.updateFamilyId(value);
                        }}
                        style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} /></div>
                </div>
                <div style={{ margin: "0px 10px", minWidth: '20%', flex: "0 0 auto" }}>
                    <span style={{ fontSize: 16}}>{getText( "ProductsSubfamily")}</span>
                    <div className="card" style={{ ...Styles.cardStyle }}>
                    <Select options={[{ label: "------", value: null }]
                        .concat(_.values(this.props.subFamilies)
                        .filter(x => x.familyId === this.props.familyId)
                        .map(x => ({ label: x.name, value: x.subFamilyId }))
                        .sort((a, b) => a.label.localeCompare(b.label)))}
                        disabled = {this.props.storeType == "Ximiti" ? false : true}
                        value={this.props.subFamilyId}
                        onChange={(value) => this.props.updateSubFamilyId(value)}
                        style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} /></div>
                </div>
                <div style={{ margin: "0px 10px", minWidth: '30%', flex: "0 0 auto" }}>
                    <span style={{ fontSize: 16}} >{getText( "ProductsProduct")}</span>                    
                    <div className="card" style={{ ...Styles.cardStyle }}>
                        <WindowedSelect
                            styles={{
                                control: (base) => ({
                                ...base,
                                backgroundColor: "transparent",
                                boxShadow:"0px 0px 20px rgb(66 73 120 / 10%)",
                                border: "1px solid white",
                                color: "black",
                                cursor: "pointer"
                                }),
                                option: (styles, { data, isFocused, isSelected }) => {
                                    const bColor = this.isDarkMode() ? "#181F3A" : "white";
                                    return {
                                      ...styles,
                                      backgroundColor:  isSelected
                                            ? "#ddd"
                                            : isFocused
                                            ? "#5897fb"
                                            : bColor,
                                      color: this.isDarkMode() ? "white" : "#666",   
                                      cursor: "pointer"
                                    };
                                  },
                            }}
                            options={filterProductsPlano}
                            value={filterProductsPlano.find(x => x.value === this.props.productId) || null}
                            onChange={this.handleSelectP} 
                            filterOption={customFilter}
                            placeholder = {""}
                            components={{
                                Option: (props) => <components.Option {...props}>
                                <div onMouseUp={() => props.innerProps.onClick()}>
                                    {props.data.label}
                                </div>
                            </components.Option>
                            }}
                        />
                    </div>
                </div>
                {/* <div>
                    <input type="checkbox" id="PlanoPresentFilter" name="PlanoPresentFilter" onClick={() => this.filterPlanoPresents(!this.state.planogrammeFilter)} /> &nbsp;
                    <label htmlFor="PlanoPresentFilter"> Presents en Planogramme</label>
                </div> */}
            </div>
            {this.props.storeType == "Ximiti" ?
                <div style={{
                    pointerEvents: this.props.disabled ? "none" : "initial",
                    opacity: this.props.disabled ? 0.7 : 1,
                    flex: 1,
                    padding: "1%",
                    height: "79%"
                }}>
                    {this.props.productId 
                        && <div style={{
                            display: "grid",
                            gridGap: 15,
                            gridTemplateRows: "48% 48%",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            height: "100%"
                        }}>
                            <div 
                            className="card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,
                                gridRow: "1 / 2",
                                gridColumn: "1 / 2",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                            }}>
                                <div style={{
                                    flex: "0 0 auto",
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    paddingBottom: '1%'
                                }}>{this.product.name}</div>
                                <div style={{
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    position: "relative"
                                }}>
                                    <img src={getImagePath(getStoreItemImage(this.product))}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                            position: "absolute",
                                            width: "100%", height: "100%"
                                        }} />
                                </div>
                            </div>
                            <div 
                            className="borderSelectors card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,
                                overflow: 'scroll'
                            }}>
                                <div>
                                    <div style={{
                                        ...textStyle
                                    }}>
                                        {getText( "ProductsMargin")}
                                    </div>
                                    <div style={{ ...fieldStyle }}>
                                        <div style={{minWidth: 51}}>{getText( "ProductsRate")}</div>
                                        <div style={{ ...inputStyle, minWidth: 150 }}>
                                            <Select options={this.product.prices.map(x => ({
                                                label: this.props.priceNames[x.priceNameId].name,
                                                value: x.priceId
                                            }))}
                                                value={this.props.priceId
                                                    || price.priceId}
                                                onChange={(value) => this.props.updatePriceId(value)} />
                                        </div>
                                    </div>
                                    <div style={{ ...fieldStyle }}>
                                        <div>{getText( "ProductsSource")}</div>
                                        <div style={{ ...inputStyle, minWidth: 150 }}>
                                            <Select options={this.product.productSuppliers.map(x => ({
                                                label: this.props.suppliers[x.supplierMainId].name,
                                                value: x.productSupplierId
                                            }))}
                                                value={this.props.productSupplierId
                                                    || productSupplier.productSupplierId}
                                                onChange={(value) => this.props.updateProductSupplierId(value)} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    padding: '1% 0'
                                }}>
                                    <div>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsUnitPrice")}</div>
                                            <div style={{ ...inputStyle }}>
                                                {priceInfo.unitPrice.toFixed(2)} {this.props.currencies[price.currencyId].symbol} / {this.props.units[this.product.relativeUnitId].name}
                                            </div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsPVTTC")}</div>
                                            <div style={{ ...inputStyle }}>
                                                {priceInfo.price.toFixed(2)} {this.props.currencies[price.currencyId].symbol}
                                            </div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsBrandTaxes")}</div>
                                            <div style={{ ...inputStyle }}>
                                                {(Math.round(priceInfo.markup * 100) / 100).toFixed(2)} %
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsTVA")}</div>
                                            <div style={{ ...inputStyle }}>{this.props.vats[
                                                _.sortBy(this.product.productVats, x => x.countryId !== countryId)[0].vatId].value.toFixed(2)} %
                                            </div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsGrossMargin")}</div>
                                            <div style={{ ...inputStyle }}>
                                                {(Math.round(priceInfo.grossMargin * 100) / 100).toFixed(2)} {this.props.currencies[price.currencyId].symbol}
                                            </div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsPrice2")}</div>
                                            <div style={{ ...inputStyle }}>
                                                {(Math.round(priceInfo.noVatUnitBuyPrice * 100) / 100).toFixed(2)} {this.props.currencies[price.currencyId].symbol}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{paddingBottom: '3%'}}>
                                    <div style={{
                                        ...textStyle
                                    }}>
                                    {getText( "ProductsSales")}  {/* Tarif */}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <ul>
                                            {
                                            this.product.prices.map((x, index) => (
                                                    index < this.product.prices.length / 2 && (
                                                        <li style={{marginBottom: '3%'}}  key={x.priceId}>
                                                            <span style={{ marginRight: 5}}>
                                                                {this.props.priceNames[x.priceNameId].name} : 
                                                            </span>
                                                            <span>{x.value.toFixed(2)} € </span>
                                                        </li>
                                                    )
                                            ))
                                            }
                                        </ul>
                                        <ul>
                                        {
                                            this.product.prices.map((x, index) => (
                                                index >= this.product.prices.length / 2 && (
                                                    <li style={{marginBottom: '3%'}} key={x.priceId}>
                                                        <span style={{ marginRight: 5}}>
                                                            {this.props.priceNames[x.priceNameId].name} : 
                                                        </span>
                                                        <span>{x.value.toFixed(2)} € </span>
                                                    </li>
                                                )
                                            ))
                                        }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                            className="card" 
                            style={{
                                ...Styles.cardStyle,                                                
                                padding: 10,
                                gridRow: "1 / 2",
                                gridColumn: "3 / 4",
                                overflow: 'scroll'
                            }}>
                                <div style={{
                                    ...textStyle
                                }}>
                                    {getText( "ProductsSubfamilies")}
                                </div>
                                {Array.apply(null, { length: this.product.productSubFamilies.length })
                                    .map(Number.call, Number)
                                    .map(index => 
                                    <div key={index} style={{
                                        padding: '2% 0',
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <div style={{  fontWeight: "bold" }}>{getText( "ProductsSubfamily")} {index + 1} :</div>
                                        { this.props.subFamilies[this.product.productSubFamilies[index].subFamilyId] &&
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div style={{minWidth: 200, maxWidth: 200}}>
                                                    {this.product.productSubFamilies[index]
                                                        && this.props.familiesState.families[this.props.subFamilies[this.product.productSubFamilies[index].subFamilyId].familyId].name}
                                                    -
                                                    {this.product.productSubFamilies[index]
                                                        && this.props.subFamilies[this.product.productSubFamilies[index].subFamilyId].name}
                                                </div>
                                                <div>
                                                    {this.product.productSubFamilies[index]
                                                        && this.product.productSubFamilies[index].order}
                                                </div>
                                                <div style={{ paddingRight: '5%'}}>
                                                    {this.product.productSubFamilies[index]
                                                        && <img height={40} src={getImagePath(this.props.subFamilies[this.product.productSubFamilies[index].subFamilyId].image)} />}
                                                </div>
                                            </div>
                                        }
                                    </div>)
                                }
                                <div style={{
                                    ...textStyle,
                                    paddingTop: '2%',
                                }}>
                                    {getText( "ProductsCategories")}
                                </div>
                                <div>
                                    {Array.apply(null, { length: Math.max(3, this.product.productMenuCategories.length) })
                                        .map(Number.call, Number)
                                        .map(index => <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: '2% 0'
                                        }}  key={index}>
                                            <div style={{ flex: "0 0 auto", fontWeight: "bold" }}>{getText( "ProductsCategory")} {index + 1} :</div>
                                            <div style={{ flex: 3, textAlign: "center" }}>
                                                {this.product.productMenuCategories[index]
                                                    && this.props.menuCategories[this.product.productMenuCategories[index].menuCategoryId].name}
                                            </div>
                                            <div style={{ flex: 1, textAlign: "center" }}>

                                            </div>
                                        </div>)}
                                </div>
                            </div>
                            <div 
                            className="card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,
                                gridRow: "2 / 3",
                                gridColumn: "1 / 2",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch"
                            }}>
                                <div style={{
                                    ...textStyle,
                                    marginBottom: 20
                                }}>{getText( "ProductsComments")}</div>
                                <div style={{
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    position: "relative",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}>
                                    <div style={{
                                        width: "100%",
                                        whiteSpace: "pre-wrap",
                                        position: "absolute",
                                        maxHeight: "100%",
                                        maxWidth: "100%"
                                    }}>
                                        {this.product.comment}
                                    </div>
                                </div>
                            </div>
                            <div     
                            className="card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,
                                gridRow: "2 / 3",
                                gridColumn: "2 / 3",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                                overflow: 'scroll'
                            }}>
                                <div style={{
                                    ...textStyle
                                }}>
                                    {getText( "ProductsSourceSupply")}
                                </div>
                                <div style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                    position: "relative"
                                }}>
                                    <div style={{
                                        position: "absolute",
                                        height: "100%",
                                        width: "100%",
                                        maxHeight: "100%",
                                        maxWidth: "100%"
                                    }}>
                                        {this.product.productSuppliers.map(productSupplier =>
                                            <div style={{ padding: "2% 0" }} key={productSupplier.productSupplierId}>
                                                <div>
                                                    <div style={{ ...fieldStyle }}>
                                                        <div>{getText( "ProductsSource")}: </div>
                                                        <div style={{ ...inputStyle }}>{this.props.suppliers[productSupplier.supplierMainId].name}</div>
                                                    </div>
                                                    <div style={{ ...fieldStyle }}>
                                                        <div>{getText( "ProductsReference")}: </div>
                                                        <div style={{ ...inputStyle }}>{productSupplier.reference}</div>
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <div>
                                                        <div style={{ ...fieldStyle }}>
                                                            <div>{getText( "ProductsTVA")}: </div>
                                                            <div style={{ ...inputStyle }}>{this.props.vats[productSupplier.vatId].name}</div>
                                                        </div>
                                                        <div style={{ ...fieldStyle }}>
                                                            <div>{getText( "ProductsPAHT")}: </div>
                                                            <div style={{ ...inputStyle }}>{Number.parseFloat((Math.round(priceInfo.noVatUnitBuyPrice * 100) / 100).toFixed(2)) * productSupplier.deliveryCondition } {this.props.currencies[productSupplier.buyPriceCurrencyId].symbol}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{paddingRight: '15%'}}>
                                                        <div style={{ ...fieldStyle }}>
                                                            <div>{getText( "ProductsQty")}: </div>
                                                            <div style={{ ...inputStyle }}>{productSupplier.packCondition}</div>
                                                        </div>
                                                        <div style={{ ...fieldStyle }}>
                                                            <div>{getText( "ProductsDeliveryQty")}: </div>
                                                            <div style={{ ...inputStyle }}>{productSupplier.deliveryCondition}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                            <div 
                            className="card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,                          
                                gridRow: "2 / 3",
                                gridColumn: "3 / 4",
                                overflow: 'scroll'
                            }}>
                                <div style={{
                                    flex: 1, display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch"
                                }}>
                                    <div style={{
                                        ...textStyle
                                    }}>
                                        {getText( "ProductsInfo")}
                                </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        padding: 10
                                    }}>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsCode")}: </div>
                                            <div style={{ ...inputStyle }}> {this.product.code}</div>
                                        </div>
                                        <div style={{display: 'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsType")}: </div>
                                                    <div style={{ ...inputStyle }}> {this.getExpirityLabel(this.product.expirityType)}</div>
                                                </div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsDayNumber")}: </div>
                                                    <div style={{ ...inputStyle }}> {this.product.expirityDays}</div>
                                                </div>
                                            </div>
                                            <div style={{paddingRight: '10%'}}>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsStable")}: </div>
                                                    <div style={{ ...inputStyle }}>{this.product.stability === "Stable" ? " Oui" : " Non"}</div>
                                                </div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsInStock")}: </div>
                                                    <div style={{ ...inputStyle }}> {this.props.stock
                                                        && _.sum(this.props.stock.stockStatus.productRails
                                                            .filter(x => x.planogramId === this.activePlanogram.planogramId
                                                                && x.productId === this.product.storeItemId).map(x => x.productStocks.length))}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    flex: 1, display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch"
                                }}>
                                    <div style={{
                                        ...textStyle
                                    }}>
                                        {getText( "ProductsDimensions")}
                                </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        padding: 10
                                    }}>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsDepth")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.depth} mm</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsHeight")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.height} mm</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsWidth")}</div>
                                            <div style={{ ...inputStyle }}>{this.product.width} mm</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsWeightVolume")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.volume}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsVolumeUnit")}: </div>
                                            <div style={{ ...inputStyle }}>{this.props.units[this.product.volumeUnitId].name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
                : //ExternalProduct
                <div style={{
                    pointerEvents: this.props.disabled ? "none" : "initial",
                    opacity: this.props.disabled ? 0.7 : 1,
                    flex: 1,
                    padding: "1%",
                    height: "79%"
                }}>
                    {this.props.productId 
                        && <div style={{
                            display: "grid",
                            gridGap: 15,
                            gridTemplateRows: "48% 48%",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            height: "100%"
                        }}>
                            <div 
                            className="card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,
                                gridRow: "1 / 2",
                                gridColumn: "1 / 2",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                            }}>
                                <div style={{
                                    flex: "0 0 auto",
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    paddingBottom: '1%'
                                }}>{this.product.name}</div>
                                
                            </div>
                            <div 
                            className="borderSelectors card"
                            style={{
                                ...Styles.cardStyle,
                                padding: 10,
                                overflow: 'scroll'
                            }}>
                                <div style={{
                                    flex: 1, display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch"
                                }}>
                                    <div style={{
                                        ...textStyle
                                    }}>
                                        {getText( "ProductsInfo")}
                                </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        padding: 10
                                    }}>
                                        <div style={{ ...fieldStyle }}>
                                            <div>{getText( "ProductsCode")}: </div>
                                            <div style={{ ...inputStyle }}> {this.product.remoteId}</div>
                                        </div>
                                        <div style={{display: 'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsType")}: </div>
                                                    <div style={{ ...inputStyle }}> {this.getExpirityLabel(this.product.expirityType)}</div>
                                                </div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsDayNumber")}: </div>
                                                    <div style={{ ...inputStyle }}> {this.product.expirityDays}</div>
                                                </div>
                                            </div>
                                            <div style={{paddingRight: '10%'}}>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsStable")}: </div>
                                                    <div style={{ ...inputStyle }}>{this.product.stability === "Stable" ? " Oui" : " Non"}</div>
                                                </div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div>{getText( "ProductsInStock")}: </div>
                                                    <div style={{ ...inputStyle }}> {this.props.stock
                                                        && _.sum(this.props.stock.stockStatus.productRails
                                                            .filter(x => x.planogramId === this.activePlanogram.planogramId
                                                                && x.externalProductId === this.product.storeItemId).map(x => x.productStocks.length))}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    flex: 1, display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch"
                                }}>
                                    <div style={{
                                        ...textStyle
                                    }}>
                                        {getText( "ProductsDimensions")}
                                </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        padding: 10
                                    }}>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsDepth")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.depth} mm</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsHeight")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.height} mm</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsWidth")}</div>
                                            <div style={{ ...inputStyle }}>{this.product.width} mm</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsWeightVolume")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.volume}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsVolumeUnit")}: </div>
                                            <div style={{ ...inputStyle }}>{this.props.units[this.product.volumeUnitId].name}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsPracticUnit")}: </div>
                                            <div style={{ ...inputStyle }}>{this.props.units[this.product.practicUnit].name}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div> {getText( "ProductsConversionFactor")}: </div>
                                            <div style={{ ...inputStyle }}>{this.product.conversionFactor}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
                }
        </div>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.productsDetails,
    currency: state.seed.seed.currencies[state.settings.storeSettings.currencyId],
    storeType: state.seed.seed.storeAppSettings.typeStore,
    products: state.seed.seed.products,
    externalProducts: state.seed.seed.externalProducts,
    subFamilies: state.seed.seed.subFamilies,
    menuCategories: state.seed.seed.menuCategories,
    suppliers: state.seed.seed.suppliers,
    vats: state.seed.seed.vats,
    units: state.seed.seed.units,
    currencies: state.seed.seed.currencies,
    priceNames: state.seed.seed.priceNames,
    stock: state.stock,
    currentUser: state.account.currentUser,
    storeAppSettings: state.seed.seed.storeAppSettings,
    disabled: !canUpdate(state)
} as ProductsDetailsOwnProps), {
    ...ProductsDetailsStore.actionCreators,
    ...StockStore.actionCreators
})(ProductsDetails as any)