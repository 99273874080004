import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canEditSettings, canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { compose } from 'redux';

type StoreSettingsFormGeneralProps = ReduxForm.InjectedFormProps<Api.StoreSettingsModel, {}, {}>
    & StoreSettingsFormGeneralOwnProps
    & StoreSettingsFormGeneralExternalProps;

interface StoreSettingsFormGeneralExternalProps {
    robotId: number;
    onSubmit: (values: any) => any;
}

interface StoreSettingsFormGeneralOwnProps {
    currencies: { [id: number]: Api.CurrencyModel };
    disabled: boolean;
    languagesState: Array<Api.LanguageModel>;
    hmisStates: Array<Api.HmiModel>;
}

const textAreaField = FormField.textAreaField(4, 45);

const rowStyle: React.CSSProperties = { margin: 0};
const inputStyle: React.CSSProperties = {minWidth: 200, maxWidth: 200, height: 34};
const colStyle: React.CSSProperties = {padding: '1% 2%'}

const getSettingsProductCodeUsage = (enableProductCodeUsage: boolean): Array<{ label: string, value: boolean }> => [
    { label: getText("OfferActivate"), value: true }
    , { label: getText("OfferDisable"), value: false }
];

class StoreSettingsFormGeneral extends React.Component<StoreSettingsFormGeneralProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="selectors borderSelectors" style={rowStyle}>
                    <div className="col-md-6" style={{paddingLeft: 0}}>
                        <div className="card" style={{...Styles.cardStyle, ...colStyle}}>
                            <div style={{fontSize: 22}}>
                                {getText( "SettingsTitleGeneral")}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <ReduxForm.Field
                                    name="initRobotAuto"
                                    style={{width: 15, height: 15, fontSize: 8}}
                                    label={getText( "SettingsGeneralInit")}
                                    component={FormField.CheckBoxField} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>{getText( "SettingsBadge")}</div>
                                <ReduxForm.Field
                                    style={inputStyle}
                                    name="currencyId"
                                    validate={[FormField.required]}
                                    component={FormField.getSelectField(
                                        _.values(this.props.currencies)
                                            .map(x => ({
                                                label: x.name,
                                                value: x.currencyId
                                            })))} 
                                />
                            </div>
                            <div className="settingsMaxQty" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>{getText( "SettingsMaxQty")}</div>
                                <ReduxForm.Field
                                    name="maxOrderQuantity"
                                    style={inputStyle}
                                    validate={[FormField.required]}
                                    component={FormField.NumberField} />
                            </div>
                            <div className="settingspProductCodeUsage" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>{getText( "SettingsProductCodeUsage")}</div>
                                <ReduxForm.Field
                                    name="productCodeUsage"
                                    style={inputStyle}
                                    component={FormField.getSelectField(getSettingsProductCodeUsage(this.props.initialValues.productCodeUsage))} />
                            </div>
                            <div className="settings">
                                <div>{getText( "SettingsUpdateLGAP")} : </div>
                                <ReduxForm.Field
                                    name="lgapUpdateCron"
                                    className="form-control"
                                    validate={[]}
                                    component={FormField.renderField} />
                            </div>
                            <div className="settings">
                                <div>{getText( "SettingsSaveCron")}</div>
                                <ReduxForm.Field
                                    name="saveHistoryCron"
                                    className="form-control"
                                    validate={[]}
                                    component={FormField.renderField} />
                            </div>
                            <div className="settings">
                                <div>{getText( "SettingsDownloadCsvHistoCron")}</div>
                                <ReduxForm.Field
                                    name="downloadCsvHistos"
                                    className="form-control"
                                    validate={[]}
                                    component={FormField.renderField} />
                            </div>
                            <div className="settings">
                                <div>{getText( "SettingsExportCron")} : </div>
                                <ReduxForm.Field
                                    name="exportHistoryCron"
                                    className="form-control"
                                    validate={[]}
                                    component={FormField.renderField} />
                            </div>
                            <div className="settings">
                                <div>{getText( "SettingsDLCDownload")}</div>
                                <ReduxForm.Field
                                    name="expirityCron"
                                    className="form-control"
                                    validate={[]}
                                    component={FormField.renderField} />
                            </div>
                            <div className="settings">
                                <div>{getText( "SettingsExpirationTime")}</div>
                                <ReduxForm.Field
                                    name="orderExpirationTime"
                                    className="form-control"
                                    validate={[]}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6"  style={{padding: 0}}>
                        <div className="card" style={{...Styles.cardStyle, ...colStyle, marginBottom: 5}}>
                            <div className="settings">
                                <ReduxForm.Field
                                    name="smartPhoneEnabled"
                                    style={{width: 15, height: 15, fontSize: 8}}
                                    label={getText( "settingsSmartphoneEnabled")}
                                    component={FormField.CheckBoxField} />
                            </div>
                            <div className="settings">
                                <ReduxForm.Field
                                    name="enableSellDDM"
                                    style={{width: 15, height: 15, fontSize: 8}}
                                    label={getText( "settingsEnableSellDDM")}
                                    component={FormField.CheckBoxField} />
                            </div>
                        </div>
                        <div className="card" style={{...Styles.cardStyle, ...colStyle, marginBottom: 5}}>
                            <div style={{fontSize: 22}}>{getText( "settingsLangs")}</div>
                            <div className="row borderSelectors">
                                <div className="col-md-12" style={{overflow: 'auto', height: '11vh', marginTop: 10}}>
                                    <ReduxForm.FieldArray
                                        validate={[FormField.requiredOne]}
                                        name="hmisApps"
                                        component={Hmis}
                                        props={{
                                            HmisState : this.props.hmisStates,
                                            langs:  this.props.languagesState
                                        } as HmisPropsOwnProps} />
                                </div>
                            </div>
                            <div className="row borderSelectors">
                                <div className="col-md-12" style={{overflow: 'auto', height: '18vh', marginTop: 5}}>
                                    <ReduxForm.FieldArray
                                        validate={[FormField.requiredOne]}
                                        name="languages"
                                        component={Languages}
                                        props={{
                                            languagesState : this.props.languagesState
                                        } as LanguagesPropsOwnProps} />
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{...Styles.cardStyle, ...colStyle}}>
                            <div style={{fontSize: 22}}>
                                {getText( "SettingsTitleSacs")}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                                <ReduxForm.Field
                                    name="deliveryStations[0].bagDispenserEnabled"
                                    style={{width: 15, height: 15, fontSize: 8}}
                                    validate={[]}
                                    label={getText( "SettingsBagDispenser")}
                                    component={FormField.CheckBoxField} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                                <div>{getText( "SettingsSacVolume")}</div>
                                <ReduxForm.Field
                                    name="deliveryStations[0].bagCbcm"
                                    style={inputStyle}
                                    validate={[]}
                                    component={FormField.NumberField} />
                            </div>
                            <div className="settingsMinQty" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>{getText( "SettingsMinQty")}</div>
                                <ReduxForm.Field
                                    name="bagMinProducts"
                                    style={inputStyle}
                                    validate={[]}
                                    component={FormField.NumberField} />
                            </div>
                            <div className="settingsMinVolume" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>{getText( "SettingsMinVolume")}</div>
                                <ReduxForm.Field
                                name="bagMinVolume"
                                    style={inputStyle}
                                    validate={[]}
                                    component={FormField.NumberField} />
                            </div>
                            <div style={{ marginTop: '2%'}}>
                                <button className="btn btn-primary"
                                    type={"submit"}
                                    style={{width: '100%', height: 34}}
                                    disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                                    {getText( "SettingsSave")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

const languageAvailabilityOptions = (): Array<{ label: string; value: Api.LanguageStatusEnum } > => ([
    { label: getText( "OfferActivate"), value: "Available" }, 
    { label: getText( "OfferDisable"), value: "Unavailable" }
]);

type LanguagesProps = LanguagesPropsOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.LanguageModel>;
interface LanguagesPropsOwnProps {
    languagesState: Array<Api.LanguageModel>;
}

class Languages extends React.Component<LanguagesProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => 
                    <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                        <div>{this.props.languagesState[index].name}</div>
                        <ReduxForm.Field
                            name={`${member}.status`}
                            style={{width: '30%'}}
                            validate={[FormField.required]}
                            component={FormField.getSelectField(languageAvailabilityOptions())} />
                    </div>)
                }
            </div>);
    }
}


type HmisProps = HmisPropsOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.LanguageModel>;
interface HmisPropsOwnProps {
    HmisState: Array<Api.Hmi>;
    langs: Array<Api.LanguageModel>;
}

class Hmis extends React.Component<HmisProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => 
                    <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                        <div>{`${getText( "settingsDefaultLang")} ${this.props.HmisState[index].name}`}</div>
                        <ReduxForm.Field 
                                    style={inputStyle}
                                    name={`${member}.languageId`}
                                    validate={[FormField.required]}
                                    component={FormField.getSelectField(
                                        _.values(this.props.langs)
                                            .map(x => ({
                                                label: x.name,
                                                value: x.languageId
                                            })))} 
                                />
                    </div>)
                }
            </div>);
    }
}

const selector = ReduxForm.formValueSelector('storeSettingsGeneral');

export default compose(connect((state: ApplicationState, props: StoreSettingsFormGeneralProps) => {
    let values = {
        ...state.settings.storeSettings,
        ...state.robot.robotsState.robots.find(x => x.robotId === props.robotId),  
        languages: _.values(state.seed.seed.languages),
        hmisApps: _.values(state.hmiApplication.hmis)
    };
    return {
        initialValues: values,
        currencies: state.seed.seed.currencies,
        disabled: !canEditSettings(state),
        languagesState: _.values(state.seed.seed.languages),
        hmisStates:  _.values(state.hmiApplication.hmis)
    } as StoreSettingsFormGeneralOwnProps
}), ReduxForm.reduxForm({
    form: 'storeSettingsGeneral',
    enableReinitialize: true,
    destroyOnUnmount: false
    }))(StoreSettingsFormGeneral) as any as React.ClassicComponentClass<StoreSettingsFormGeneralExternalProps>