import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from 'react-table';
import * as ContactStore from '../store/Contact';
import * as TabsStore from '../store/Tabs';
import { CreateContactForm, UpdateContactForm } from "./ContactForm";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Table from './Table';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { canEditSettings } from '../security/roles';
import Confirm from "react-confirm-bootstrap";
import * as NeoEventAlarmStore from '../store/NeoEventAlarm';

type ContactsProps = ContactStore.ContactState
    & ContactsOwnProps
    & typeof ContactStore.actionCreators
    & typeof NeoEventAlarmStore.actionCreators
    & typeof TabsStore.actionCreators;

interface ContactsOwnProps {
    currentUser: Api.CurrentUserManagerModel;
    disabled: boolean;
}

type ContactColumn = ReactTable.Column
    & { accessor: (d: Api.ContactModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };
const supportMapEmail: string = "support.map@neovendis-sas.com";

class Contacts extends React.Component<ContactsProps, {}> {
    componentDidMount() {
        this.props.requestContacts(new Date().getTime());
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{minWidth: 600 }}>
                <div className="card" style={{...Styles.cardStyle, marginBottom: "1%", background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                    <div style={{ fontSize: 22, padding: '0.5rem 0 0  1%'}}>{getText( "MaintenanceTabContacts")}</div>
                    <div style={{paddingTop: '1%'}}>
                        <Table key={"contacts"}
                            className="tableHeightSmall"
                            style={{margin: 0}}
                            pageSize={Math.max(_.values(this.props.contacts).length,15)}
                            showPagination={false}
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            data={_.values(this.props.contacts)}
                            columns={[
                                {
                                    id: "lastName",
                                    Header: getText( "MaintenanceLastName"),
                                    accessor: (d) => <div style={{padding: 5}}>{d.lastName}</div>
                                },
                                {
                                    id: "firstName",
                                    Header: getText( "MaintenanceFirstName"),
                                    accessor: (d) => <div style={{padding: 5}}>{d.firstName}</div>
                                },
                                {
                                    id: "actions",
                                    Header: getText( "MaintenanceAction"),
                                    accessor: (d) => d.contactId,
                                    Cell: row => <div style={{display: 'flex', justifyContent: 'center'}}> <div style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",                                 
                                        borderRadius: 5,
                                        marginTop: 6,
                                        marginRight: 10
                                        }}>
                                            <i className="glyphicon glyphicon-pencil"
                                                style={{ pointerEvents: row.original.email === supportMapEmail && this.props.disabled ? "none" : "initial",
                                                    opacity: row.original.email === supportMapEmail && this.props.disabled ? 0.7 : 1,
                                                    margin: "11% 0",  cursor: "pointer", color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}                                                
                                            onClick={(e) => {
                                                    this.props.updateSelectedContactId(row.value);
                                                    this.props.selectTab("contacts", "update");
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </div>
                                        <div className="icon-container-dm" style={{
                                            width: 20,
                                            height: 20,
                                            placeSelf: "center", 
                                            backgroundColor: "#007AFF",
                                            borderRadius: 5,
                                            marginTop: 6
                                            }}>
                                                <Confirm
                                                    style={{ pointerEvents: row.original.email === supportMapEmail && this.props.disabled ? "none" : "initial", 
                                                        cursor: "pointer",
                                                        opacity: row.original.email === supportMapEmail && this.props.disabled ? 0.7 : 1, color: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                                                        marginTop: '15%',
                                                        marginRight: 1,  }}
                                                    onConfirm={() => {
                                                        (this.props.requestDeleteContact(new Date().getTime(), row.value) as any as Promise<any>)
                                                            .then(() => this.props.requestNeoEventAlarms(new Date().getTime()));
                                                    }}
                                                    body={getText("MaintenanceContactBodyConfirm")}
                                                    confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}>
                                                        <i className="glyphicon glyphicon-trash icon-dm"/>
                                                </Confirm>
                                        </div>
                                    </div>                
                                }
                            ] as Array<ContactColumn>} />
                    </div>
                </div>
                <div>
                    <ConnectedTabs name={"contacts"}>
                        <div  style={{backgroundColor: this.isDarkMode() ? 'rgba(59,67,112, 0.3)' : ''}}>
                            <TabLink style={{width: '50%'}} to="create">
                                <div style={tabHeadStyle}>{getText( "MaintenanceCreate")}</div>
                            </TabLink>
                            <TabLink style={{width: '50%'}} to="update">
                                <div style={tabHeadStyle}>{getText( "MaintenanceEdit")}</div>
                            </TabLink>
                        </div>
                        <TabContent for="create">
                            <div style={{ paddingTop: 5 }}>
                                <div className="card" style={{...Styles.cardStyle, padding: '1%', background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                    <CreateContactForm actionName={getText( "MaintenanceCreate")}
                                        onSubmit={(values) => {
                                            (this.props.requestCreateContact(new Date().getTime(), values) as any as Promise<any>)
                                                            .then(() => this.props.requestNeoEventAlarms(new Date().getTime()));
                                        }} />
                                </div>
                            </div>
                        </TabContent>
                        <TabContent for="update">
                            <div style={{ paddingTop: 5 }}>
                                {this.props.selectedContactId
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%', background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                        <UpdateContactForm actionName={getText( "MaintenanceSave")}
                                        onSubmit={(values) => this.props.requestUpdateContact(new Date().getTime(), values)} />
                                    </div> 
                                }
                            </div>
                        </TabContent>
                    </ConnectedTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.contact,    
    currentUser: state.account.currentUser,
    disabled: !canEditSettings(state)
}), {
        ...ContactStore.actionCreators,
        ...NeoEventAlarmStore.actionCreators,
        ...TabsStore.actionCreators
    })(Contacts as any)