import * as React from "react";

interface ImageProps {
    src: string;
    fit?: string;
    style?: any;
}

const containerStyle = { width: "100%", height: "100%" }
const baseBgImageStyle = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    height: "100%",
    width: "100%",
}

export default class Image extends React.Component<ImageProps, {}> {
    public render() {
        return (
            <div style={containerStyle}>
                <div style={{
                    backgroundImage: "url(" + this.props.src + ")",
                    ...baseBgImageStyle,
                    backgroundSize: this.props.fit || "cover",
                    ...this.props.style
                }}>
                </div>
            </div>
        );
    }
}