import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import { getText } from '../utils/texts';

export interface ImportState {
    lgapImportState: {
        isLoading: boolean;
        requestTime?: number;
        lastLgapImport?: Api.StoreImportModel;
        runState: {
            isLoading: boolean;
            requestTime?: number;
        }
    },
    storeTokenState: {
        isLoading: boolean;
        requestTime?: number;
        hasToken: boolean;
        setTokenState: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
}


interface RequestRunLgapImport {
    type: "REQUEST_RUN_LGAPIMPORT";
    payload: { requestTime: number; }
}
interface ReceiveRunLgapImport {
    type: "RECEIVE_RUN_LGAPIMPORT";
    payload: { requestTime: number; storeImport?: Api.StoreImportModel; }
    error?: any;
}

interface RequestLastLgapImport {
    type: "REQUEST_LAST_LGAPIMPORT";
    payload: { requestTime: number }
}
interface ReceiveLastLgapImport {
    type: "RECEIVE_LAST_LGAPIMPORT";
    payload: { requestTime: number; storeImport?: Api.StoreImportModel; };
    error?: any;
}

interface RequestHasStoreToken {
    type: "REQUEST_HAS_STORETOKEN";
    payload: { requestTime: number; }
}
interface ReceiveHasStoreToken {
    type: "RECEIVE_HAS_STORETOKEN";
    payload: { requestTime: number; hasStoreToken?: boolean; }
    error?: any;
}

interface RequestSetStoreToken {
    type: "REQUEST_SET_STORETOKEN";
    payload: { requestTime: number; }
}
interface ReceiveSetStoreToken {
    type: "RECEIVE_SET_STORETOKEN";
    payload: { requestTime: number; }
    error?: any;
}

export type KnownAction = RequestRunLgapImport
    | ReceiveRunLgapImport
    | RequestLastLgapImport
    | ReceiveLastLgapImport
    | RequestHasStoreToken
    | ReceiveHasStoreToken
    | RequestSetStoreToken
    | ReceiveSetStoreToken
    ;

export const actionCreators = {
    requestRunLgapImport: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ImportApi();
        let fetchTask = api.runLgapImport({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(storeImport => {
            dispatch({
                type: "RECEIVE_RUN_LGAPIMPORT",
                payload: { requestTime: requestTime, storeImport: storeImport }
            });
        }).catch(err => {
            dispatch({
                type: "RECEIVE_RUN_LGAPIMPORT",
                payload: { requestTime: requestTime },
                error: err
            });
            dispatch(Notifications.error({ title: getText("NotificationTitleError"), message: `${getText("NotificationErrorImport")}: ${err}` }) as any);
        });

        dispatch({
            type: "REQUEST_RUN_LGAPIMPORT",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestLastLgapImport: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ImportApi();
        let fetchTask = api.getLastLgapImport({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(storeImport => {
            dispatch({
                type: "RECEIVE_LAST_LGAPIMPORT",
                    payload: { requestTime: requestTime, storeImport: storeImport }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_LAST_LGAPIMPORT",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_LAST_LGAPIMPORT",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestHasStoreToken: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ImportApi();
        let fetchTask = api.hasStoreToken({
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(hasStoreToken => {
            dispatch({
                type: "RECEIVE_HAS_STORETOKEN",
                payload: { requestTime: requestTime, hasStoreToken: hasStoreToken }
            });
        })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_HAS_STORETOKEN",
                    payload: { requestTime: requestTime },
                    error: err
                });
            dispatch(Notifications.error({ title: getText("NotificationTitleError"), message: getText("NotificationErrorToken")}) as any);
            });

        dispatch({
            type: "REQUEST_HAS_STORETOKEN",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestSetStoreToken: (requestTime: number, storeToken: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ImportApi();
        let fetchTask = api.setStoreToken({
            storeToken: storeToken
        }, {
            credentials: "same-origin", headers: getDefaultHeaders(getState())
        }).then(hasStoreToken => {
            dispatch({
                type: "RECEIVE_SET_STORETOKEN",
                payload: { requestTime: requestTime }
            });
        })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_SET_STORETOKEN",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_SET_STORETOKEN",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
}

const unloadedState: ImportState = {
    lgapImportState: {
        isLoading: false,
        runState: {
            isLoading: false
        }
    },
    storeTokenState: {
        hasToken: false,
        isLoading: false,
        setTokenState: {
            isLoading: false
        }
    }
}

export const reducer: Reducer<ImportState> = (state: ImportState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_LAST_LGAPIMPORT":
            return {
                ...state,
                lgapImportState: {
                    ...state.lgapImportState,
                    isLoading: true,
                    requestTime: action.payload.requestTime,
                }
            };
        case "RECEIVE_LAST_LGAPIMPORT":
            if (state.lgapImportState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                lgapImportState: {
                    ...state.lgapImportState,
                    isLoading: false,
                    lastLgapImport: action.error
                        ? state.lgapImportState.lastLgapImport
                        : action.payload.storeImport
                }
            };
        case "REQUEST_RUN_LGAPIMPORT":
            return {
                ...state,
                lgapImportState: {
                    ...state.lgapImportState,
                    runState: {
                        ...state.lgapImportState.runState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_RUN_LGAPIMPORT":
            if (state.lgapImportState.runState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                lgapImportState: {
                    ...state.lgapImportState,
                    lastLgapImport: action.error
                        ? state.lgapImportState.lastLgapImport
                        : action.payload.storeImport,
                    runState: {
                        ...state.lgapImportState.runState,
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_HAS_STORETOKEN":
            return {
                ...state,
                storeTokenState: {
                    ...state.storeTokenState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_HAS_STORETOKEN":
            if (state.storeTokenState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                storeTokenState: {
                    ...state.storeTokenState,
                    hasToken: action.error
                        ? state.storeTokenState.hasToken
                        : action.payload.hasStoreToken,
                    isLoading: false,
                }
            };
        case "REQUEST_SET_STORETOKEN":
            return {
                ...state,
                storeTokenState: {
                    ...state.storeTokenState,
                    setTokenState: {
                        ...state.storeTokenState.setTokenState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_SET_STORETOKEN":
            if (state.storeTokenState.setTokenState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                storeTokenState: {
                    ...state.storeTokenState,
                    hasToken: action.error
                        ? state.storeTokenState.hasToken
                        : true,
                    setTokenState: {
                        ...state.storeTokenState.setTokenState,
                        isLoading: false
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}