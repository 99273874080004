import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface MaintenanceState {
    restartState: {
        isLoading: boolean;
        requestTime?: number;
    }
}

interface MaintenanceRequestRestartApplications {
    type: "MAINTENANCE_REQUEST_RESTART_APPLICATIONS";
    payload: { requestTime: number; }
}
interface MaintenanceReceiveRestartApplications {
    type: "MAINTENANCE_RECEIVE_RESTART_APPLICATIONS";
    payload: { requestTime: number; };
    error?: any;
}

//placeholder
type KnownAction = MaintenanceRequestRestartApplications
    | MaintenanceReceiveRestartApplications;

export const actionCreators = {
    requestRestartApplications: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ApplicationApi();
        let fetch = api.restart({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "MAINTENANCE_RECEIVE_RESTART_APPLICATIONS",
                    payload: { requestTime: requestTime }
                });
            }).catch(err => {
                dispatch({
                    type: "MAINTENANCE_RECEIVE_RESTART_APPLICATIONS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "MAINTENANCE_REQUEST_RESTART_APPLICATIONS",
            payload: { requestTime: requestTime }
        });
        return fetch;
    }
};

const unloadedState: MaintenanceState = {
    restartState: {
        isLoading: false
    }
};

export const reducer: Reducer<MaintenanceState> = (state: MaintenanceState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "MAINTENANCE_REQUEST_RESTART_APPLICATIONS":
            return {
                ...state,
                restartState: {
                    ...state.restartState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "MAINTENANCE_RECEIVE_RESTART_APPLICATIONS":
            if (action.payload.requestTime !== state.restartState.requestTime)
                return state;

            return {
                ...state,
                restartState: {
                    ...state.restartState,
                    isLoading: false,
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};