import * as Api from '../api/api';
import * as React from 'react';
import * as MenuStore from '../store/Menu';
import * as _ from 'lodash';
import Table from "./Table";
import * as ReactTable from "react-table";
import DialogModal from './DialogModal';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import MenuDetails from './MenuDetails';
import { canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import HelpHeader from "./HelpHeader";
import { getText } from '../utils/texts';
import * as StockStore from "../store/Stock";

type MenuProps = MenuOwnProps
    & MenuStore.MenuState
    & typeof MenuStore.actionCreators
    & typeof StockStore.actionCreators;

interface MenuOwnProps {
    seed: Api.SeedMngModel;
    currencies: { [id: number]: Api.CurrencyModel };
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;
    stockStatus: Api.StockStatusModel;
}

type MenuColumn = ReactTable.Column
    & { accessor: (d: Api.MenuModel) => any; }

class Menu extends React.Component<MenuProps, {}> {

    componentDidMount() {
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get menus() {
        return _.values(this.props.seed.menus).filter(x => x.prices.length !== 0);
    }

    get menusSelectedCount() {
        return this.menus.filter(x => x.hmiEnabled).length;
    }

    get productRails() {
        if (!this.props.stockStatus)
            return [];

        let planogramId = _.sortBy(
            this.props.stockStatus.planograms, x => !x.active)[0].planogramId;

        return this.props.stockStatus.productRails
            .filter(x => x.planogramId === planogramId);
    }

    getProductFromCategoryId(id: number): Array<Api.ProductModel> {
        return _.sortBy(_.values(this.props.seed.products)
            .filter(x => x.productMenuCategories.some(y => y.menuCategoryId === id)
                && x.prices.length !== 0), x => x.name);
    }

    public render() {
        return (
            <div>
                <div 
                className="card"
                style={{
                    ...Styles.cardStyle,
                    ...Styles.pageTitle
                }}><span style={{paddingLeft: 30}}>{getText( "MenuTitle")}</span> <HelpHeader /></div>
                <div className="card" style={{...Styles.cardStyle, margin: "0 1%"}}>
                    <Table
                        style={{ height: '75vh' }}
                        key={"menus"}
                        showPagination={false}
                        showPaginationBottom={false}
                        pageSize={Math.max(this.menus.length,20)}
                        data={_.sortBy(this.menus, x => x.name)}
                        columns={[
                            {
                                id: "name",
                                Header: <div>{getText( "MenuName")}</div>,
                                accessor: d => d,
                                Cell: row => {
                                    return <div style={{ marginLeft: 8, float: "left"}}>{row.value.name}</div>;
                                },
                            },
                            {
                                id: "price",
                                Header: <div>{getText( "MenuPrice")}</div>,
                                accessor: d => d.prices[0] ? `${d.prices[0].value.toFixed(2)} ${this.props.currencies[d.prices[0].currencyId].symbol}` : 0.00,
                                Cell: row => { return row.value },
                            },
                            {
                                id: "action",
                                Header: <div>{getText( "MenuActive")}</div>,
                                accessor: d => d,
                                Cell: row => {
                                    let visible = row.value.hmiEnabled;
                                    return <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <input type="checkbox"
                                            className="offer-checkbox"
                                            style={{ flex: "0 0 auto", margin: 0 }}
                                            checked={visible}
                                            disabled={this.props.disabled || (this.menusSelectedCount > 11 && visible=== false)}
                                            onChange={(e) => {
                                                this.props.requestUpdateMenuEnabled(new Date().getTime(), row.value.storeItemId,e.target.checked);
                                            }} />
                                    </div>;
                                },
                                width: 150
                            },
                            {
                                id: "stockState",
                                accessor: (d) => d,
                                Header: <div>{getText( "StockState")}</div>,
                                Cell: row => {
                                    let menu = row.value as Api.MenuModel;
                                    let color = 'green'
                                    let categoryMissed = menu.menuCategoryMenus.some(m => 
                                        this.getProductFromCategoryId(m.menuCategoryId)
                                            .every(p => !this.productRails
                                                .some(pr => pr.productId === p.storeItemId))
                                    );
                                    let stockMissed = !(menu.menuCategoryMenus.every(m => 
                                        this.getProductFromCategoryId(m.menuCategoryId)
                                            .some(p => this.productRails
                                                .some(pr => pr.productId === p.storeItemId && 
                                                    (pr.productStocks && pr.productStocks.length > 0)))
                                    ));
                                    if(stockMissed)
                                        color = 'orange';
                                    if(categoryMissed)
                                        color = 'red';
                                    return (
                                        <div style={{width: 25}}>
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                viewBox="0 0 512 512" fill={color}>
                                                    <path d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
                                                        c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
                                                        c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
                                                        M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
                                                        V317.241z"/>
                                            </svg>
                                        </div>
                                    );
                                },
                                width: 150
                            },
                            {
                                id: "details",
                                Header: <div>{getText( "MenuDetails")}</div>,
                                accessor: d => d,
                                Cell: row => {
                                    let menu = row.value as Api.MenuModel;
                                    return <div 
                                    className="icon-container-dm"
                                    style={{ 
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5, }}>
                                        <i className="glyphicon glyphicon-search icon-dm"
                                            style={{ margin: "11% 0",  cursor: "pointer", color: "#FFFFFF" }}
                                            onClick={(e) => this.props.updateSelectedMenu(menu.storeItemId)} />
                                    </div>;
                                },
                                width: 150
                            },
                        ] as Array<MenuColumn>} />
                </div>
                <DialogModal contentLabel={"Menu details"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);", 
                        minWidth: 800, 
                        maxHeight: 600 
                    }}
                    title={this.props.selectedMenuId ? this.props.seed.menus[this.props.selectedMenuId].name : ""}
                    isOpen={this.props.selectedMenuId != undefined ? true : false}
                    onRequestClose={() => this.props.updateSelectedMenu(undefined)}>
                    {this.props.selectedMenuId
                        && <MenuDetails menu={this.props.seed.menus[this.props.selectedMenuId]}
                            seed={this.props.seed} stockStatus={this.props.stockStatus} />}
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.menu,
    seed: state.seed.seed,
    currencies: state.seed.seed.currencies,
    disabled: !canUpdate(state),
    currentUser: state.account.currentUser,
    stockStatus: state.stock.stockStatus
} as MenuOwnProps), {
        ...MenuStore.actionCreators,
        ...StockStore.actionCreators
    })(Menu as any);