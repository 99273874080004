import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as AccountStore from './store/Account';
import * as SeedStore from './store/Seed';
import * as SettingsStore from './store/Settings';
import { ApplicationState } from './store';
import { getAllTexts, loadTexts } from './utils/texts';
import * as Api from "./api/api";
import {isAuthenticated} from "./security/UserIsAuthenticated";
import {connectUserHub} from "./signalR/connectedUsers";
import * as HistoryStore from "./store/History";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });
const initData = (langCode: string, requestTime: number, getState: () => ApplicationState,
                  dispatch: (action: AccountStore.KnownAction
                      | SeedStore.KnownAction
                      | SettingsStore.KnownAction
                      | HistoryStore.KnownAction) => void): Promise<any> => {

    let requestUserTask = AccountStore.requestCurrentUser(requestTime, dispatch, getState)
        .catch(() => {
            throw "Failed to request current user";
        });
    let requestSeed = SeedStore.requestSeed(requestTime, dispatch, getState)
        .catch(() => {
            throw "Failed to request seed";
        });
    let requestSettings = SettingsStore.requestStoreSettings(requestTime, dispatch, getState)
        .catch(() => {
            throw "Failed to request settings";
        });
    let requestHistory = HistoryStore.requestTodayDayStoreData(requestTime, dispatch, getState)
        .catch(() => {
            throw "Failed to request todayStoreData";
        });
    let seedApi = new Api.SeedApi();
    let requestTextTask = new Promise((resolve, reject) => {
        seedApi.getTexts({ langCode: langCode })
            .then(texts => {
                loadTexts(texts);
                resolve();
            })
            .catch(err => {
                console.log("ERROR: " + (err.message || JSON.stringify(err)));
                reject("Failed to load language texts");
            });
    });

    return Promise.all([requestUserTask, requestSeed, requestSettings, requestHistory, requestTextTask]);
}

const store = configureStore(history);

let token = window.localStorage.getItem("token");
if(token) {
    store.dispatch({ type: "LOAD_TOKEN", payload: { token: token } });
}

initData('FR', new Date().getTime(), store.getState, store.dispatch).then(() => {
    if(isAuthenticated(store.getState())){
        connectUserHub(store.dispatch, store.getState);
    }
    loadTexts(getAllTexts());
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root'));

    registerServiceWorker();
}).catch(err => {
    console.error(err);
    window.alert("An error has occured while rendering the application, check the logs and the console")
});

//Reconnection to web sockets if closed for some reasons (server shutdown)
let startTime = new Date().getTime();
setInterval(() => {
    if (!store.getState().mngHub.isConnected 
        && isAuthenticated(store.getState())) {
        connectUserHub(store.dispatch, store.getState);
    }
    if(isAuthenticated(store.getState())){
        let lastActionTime = store.getState().account.lastAction
            || startTime;

        let automaticLogout = store.getState().settings.storeSettings.automaticLogout;
        let mngAfkExpiration = (store.getState().settings.storeSettings.mngAfkExpiration ? store.getState().settings.storeSettings.mngAfkExpiration : 300);

        if(automaticLogout && (new Date().getTime() - lastActionTime > 1000 * mngAfkExpiration)){
            AccountStore.requestLogout(new Date().getTime(), store.dispatch as any, store.getState);
        }
    }
}, 10000);

//Global application events
document.addEventListener("click", function (evnt) {
    AccountStore.resetTimer(new Date().getTime(), store.dispatch as any, store.getState)
});

(window as any).store = store;