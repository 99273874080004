import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { compose } from 'redux';

type StoreSettingsFormProps = ReduxForm.InjectedFormProps<Api.StoreSettingsModel, {}, {}>
    & StoreSettingsFormOwnProps
    & StoreSettingsFormExternalProps;

interface StoreSettingsFormExternalProps {
    robotId: number;
    onSubmit: (values: Api.StoreSettingsModel) => any;
}

interface StoreSettingsFormOwnProps {
    currencies: { [id: number]: Api.CurrencyModel };
    disabled: boolean;
}

const textAreaField = FormField.textAreaField(6, 45);

const rowStyle: React.CSSProperties = { margin: 0};
const inputStyle: React.CSSProperties = {minWidth: 200, maxWidth: 200, height: 34};
const colStyle: React.CSSProperties = {padding: '1% 2%'}

const getSettingsLogoutOptions = (enableAutomaticLogout: boolean): Array<{ label: string, value: boolean }> => [
    { label: getText("OfferActivate"), value: true }
    , { label: getText("OfferDisable"), value: false }
];

class StoreSettingsForm extends React.Component<StoreSettingsFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                 <div className="card row settingsCard" style={{...Styles.cardStyle, padding: '0.5rem 1% 0', margin:"10px 0px"}}>
                    <div className="settingsTitleTickets">
                        {getText( "SettingsTitleTickets")}
                    </div>
                    <div className="col-md-6" style={{paddingLeft: 0, paddingRight: 30}}>
                        <div className="settings">
                            <div>{getText( "SettingsTicketComment")}</div>
                            <ReduxForm.Field
                                name="receiptTop"
                                style={{width: '100%'}}
                                validate={[]}
                                component={textAreaField} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsTimeSalesMin")}</div>
                            <ReduxForm.Field
                                name="noSellTimeout"
                                className="form-control"
                                validate={[]}
                                component={FormField.NumberField} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsTimePaymentMin")}</div>
                            <ReduxForm.Field
                                name="noPaymentTimeout" 
                                className="form-control"
                                validate={[]}
                                component={FormField.NumberField} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsStateReceipt")}</div>
                            <ReduxForm.Field
                                name="receiptValDefault"
                                style={{minWidth: 216, maxWidth: 216, height: 34}}
                                component={FormField.getSelectField(getSettingsLogoutOptions(this.props.initialValues.receiptValDefault))} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsTimeReceiptQR")}</div>
                            <ReduxForm.Field
                                name="receiptQRTimeout" 
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsPromoIntervalHmi")}</div>
                            <ReduxForm.Field
                                name="promoIntervalHmi"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsOrderTimeoutHmi")}</div>
                            <ReduxForm.Field
                                name="orderTimeoutHmi"
                                className="form-control"
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingLeft: 0, paddingRight: 30}}>
                        <div className="settings">
                            <div>{getText( "SettingsTicketLowComment")}</div>
                            <ReduxForm.Field
                                name="receiptBottom"
                                style={{width: '100%'}}
                                validate={[]}
                                component={textAreaField} />
                        </div>
                        <div className="settings" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsDaysDLC")}</div>
                            <ReduxForm.Field
                                name="expirityDaysBeforeThrow"
                                style={inputStyle}
                                validate={[]}
                                component={FormField.NumberField} />
                        </div>
                        <div className="settingsReturnHome" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsReturnHome")}</div>
                            <ReduxForm.Field
                                name="saleTimeout"
                                style={inputStyle}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        <div className="settingsAutoLogout" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsAutoLogout")}</div>
                            <ReduxForm.Field
                                name="automaticLogout"
                                style={inputStyle}
                                component={FormField.getSelectField(getSettingsLogoutOptions(this.props.initialValues.automaticLogout))} />
                        </div>
                        <div className="settingsExpiration" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>{getText( "SettingsExpiration")}</div>
                            <ReduxForm.Field
                                name="mngAfkExpiration"
                                style={inputStyle}
                                validate={[FormField.required]}
                                component={FormField.NumberField} />
                        </div>
                        <div style={{margin:"10px 0px"}}>
                            <button className="btn btn-primary"
                                type={"submit"}
                                style={{width: '100%', height: 34}}
                                disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                                {getText( "SettingsSave")}
                            </button>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

const selector = ReduxForm.formValueSelector('storeSettings');

export default compose(connect((state: ApplicationState, props: StoreSettingsFormProps) => {
    let values = {
        ...state.settings.storeSettings,
        ...state.robot.robotsState.robots.find(x => x.robotId === props.robotId),    
    };
    return {
        initialValues: values,
        currencies: state.seed.seed.currencies,
        disabled: !canUpdate(state)
    } as StoreSettingsFormOwnProps
}), ReduxForm.reduxForm({
    form: 'storeSettings',
    enableReinitialize: true,
    destroyOnUnmount: false
    }))(StoreSettingsForm) as any as React.ClassicComponentClass<StoreSettingsFormExternalProps>