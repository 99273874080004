import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Download from "downloadjs";
import * as MimeTypes from "mime-types";

export interface ReceptionState {
    createState: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateState: {
        isLoading: boolean;
        requestTime?: number;
    };
    storeStockState: {
        [productSupplierId: number]: number;
    }
}

interface ReceptionUpdateStoredStock {
    type: "RECEPTTION_UPDATE_STOREDSTOCK";
    payload: { value: number; productSupplierId: number; };
}

interface RequestCreateReceptions {
    type: "REQUEST_CREATE_RECEPTIONS";
    payload: { requestTime: number };
}
export interface ReceiveCreateReceptions {
    type: "RECEIVE_CREATE_RECEPTIONS";
    payload: {
        requestTime: number;
        productReceptions?: Array<Api.ProductReceptionModel>;
    };
    error?: any;
}

interface RequestUpdateQuantityRemaining {
    type: "REQUEST_UPDATE_QUANTITYREMAINING";
    payload: { requestTime: number };
}
export interface ReceiveUpdateQuantityRemaining {
    type: "RECEIVE_UPDATE_QUANTITYREMAINING";
    payload: {
        requestTime: number;
        productReceptions?: Array<Api.ProductReceptionModel>;
    };
    error?: any;
}
interface RequestDeleteQuantityRemaining {
    type: "REQUEST_DELETE_QUANTITYREMAINING";
    payload: { requestTime: number };
}
export interface ReceiveDeleteQuantityRemaining {
    type: "RECEIVE_DELETE_QUANTITYREMAINING";
    payload: {
        requestTime: number;
        productReceptions?: Array<Api.ProductReceptionModel>;
    };
    error?: any;
}

type KnownAction = RequestCreateReceptions
    | ReceiveCreateReceptions
    | RequestUpdateQuantityRemaining
    | ReceiveUpdateQuantityRemaining
    | RequestDeleteQuantityRemaining
    | ReceiveDeleteQuantityRemaining
    | ReceptionUpdateStoredStock
    ;

export const actionCreators = {
    //requestReceptions: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
    //    let api = new Api.ReceptionApi();
    //    let fetchTask = api.searchEntities({
    //        model: {
    //            dateFrom: Moment().add(-1, "months").toDate(),
    //            dateTo: Moment().add(6, "hours").toDate()
    //        }
    //    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
    //        .then(productReceptions => {
    //            dispatch({
    //                type: "RECEIVE_RECEPTIONS",
    //                payload: { requestTime: requestTime, productReceptions: productReceptions }
    //            });
    //        })
    //        .catch(err => {
    //            dispatch({
    //                type: "RECEIVE_RECEPTIONS",
    //                payload: { requestTime: requestTime },
    //                error: err
    //            });
    //        });
    //    dispatch({ type: "REQUEST_RECEPTIONS", payload: { requestTime: requestTime } });
    //    addTask(fetchTask);
    //    return fetchTask;
    //},
    requestCreateReceptions: (requestTime: number, model: Api.CreateProductReceptionsModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReceptionApi();
        let fetchTask = api.createProductReceptions({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productReceptions => {
                dispatch({
                    type: "RECEIVE_CREATE_RECEPTIONS",
                    payload: {
                        requestTime: requestTime,
                        productReceptions: productReceptions
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_RECEPTIONS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });
        dispatch({ type: "REQUEST_CREATE_RECEPTIONS", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestUpdateQuantityRemaining: (requestTime: number, model: Api.UpdateQuantityRemainingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReceptionApi();
        let fetchTask = api.updateQuantityRemaining({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productReceptions => {
                dispatch({
                    type: "RECEIVE_UPDATE_QUANTITYREMAINING",
                    payload: {
                        requestTime: requestTime,
                        productReceptions: productReceptions
                    }
                });

            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_QUANTITYREMAINING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });
        dispatch({ type: "REQUEST_UPDATE_QUANTITYREMAINING", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestDeleteQuantityRemaining: (requestTime: number, model: Api.UpdateQuantityRemainingModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ReceptionApi();
        let fetchTask = api.deleteQuantityRemaining({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productReceptions => {
                dispatch({
                    type: "RECEIVE_DELETE_QUANTITYREMAINING",
                    payload: {
                        requestTime: requestTime,
                        productReceptions: productReceptions
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DELETE_QUANTITYREMAINING",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });
        dispatch({ type: "REQUEST_DELETE_QUANTITYREMAINING", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    downloadProductReceptionCsv: (): AppThunkAction < KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ExportApi();
        let fetchTask = api.productReception({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                let fileName = `Receptions.csv`;
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            }).catch(err => {
                console.error(err);
            });
        return fetchTask;
    },
    receptionUpdateStoredStock: (productSupplierId: number, value: number) => <ReceptionUpdateStoredStock>{
        type: "RECEPTTION_UPDATE_STOREDSTOCK",
        payload: { productSupplierId: productSupplierId, value: value }
    }
};

const unloadedState: ReceptionState = {
    createState: {
        isLoading: false
    },
    updateState: {
        isLoading: false
    },
    storeStockState: {}
};

export const reducer: Reducer<ReceptionState> = (state: ReceptionState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEPTTION_UPDATE_STOREDSTOCK": {
            return {
                ...state,
                storeStockState: {
                    ...state.storeStockState,
                    [action.payload.productSupplierId]: action.payload.value
                }
            };
        }
        case "REQUEST_CREATE_RECEPTIONS":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_RECEPTIONS":
            return {
                ...state,
                //productReceptions: {
                //    ...state.productReceptions,
                //    productReceptions: action.error
                //        ? state.productReceptions.productReceptions
                //        : state.productReceptions.productReceptions.concat(action.payload.productReceptions),
                //},
                createState: {
                    ...state.createState,
                    isLoading: action.payload.requestTime === state.createState.requestTime
                        ? false
                        : state.createState.isLoading
                },
                storeStockState: {}
            };
        case "REQUEST_UPDATE_QUANTITYREMAINING":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_QUANTITYREMAINING":
            return {
                ...state,
                //productReceptions: {
                //    ...state.productReceptions,
                //    productReceptions: action.error
                //        ? state.productReceptions.productReceptions
                //        : state.productReceptions.productReceptions
                //            .map(x => {
                //                let updated = action.payload.productReceptions.find(y => y.productReceptionId === x.productReceptionId);
                //                return updated ? updated : x;
                //            })
                //            .concat(action.payload.productReceptions
                //                .filter(x => !state.productReceptions.productReceptions
                //                    .some(y => y.productReceptionId === x.productReceptionId))),
                //},
                updateState: {
                    ...state.updateState,
                    isLoading: action.payload.requestTime === state.updateState.requestTime
                        ? false
                        : state.updateState.isLoading
                },
                storeStockState: {}
            };
            case "REQUEST_DELETE_QUANTITYREMAINING":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
            case "RECEIVE_DELETE_QUANTITYREMAINING":
            return {
                ...state,
                updateState: {
                    ...state.updateState,
                    isLoading: action.payload.requestTime === state.updateState.requestTime
                        ? false
                        : state.updateState.isLoading
                },
                storeStockState: {}
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};