import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from './FormField';
import {
    Field, reduxForm, InjectedFormProps,
} from 'redux-form';
import { getText } from '../utils/texts';

type LoginFormProps = InjectedFormProps<{}, {}, {}>;

class LoginForm extends React.Component<LoginFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}
                className="form-vertical"
                contentContainerStyle={{fontSize: '16px'}}
                disableResetOnSubmit={true}>
                <div className="form-group">
                    <label className="control-label">{getText( "GenUsername")} </label>
                    <Field name="username"
                        className="form-control"
                        type="text"
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="form-group">
                    <label className="control-label">{getText( "GenPassword")} </label>
                    <Field name="password"
                        className="form-control"
                        type="password"
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="form-group">
                    <button className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting
                            || this.props.pristine}
                        type={"submit"}>{getText("GenLogin")}</button>
                </div>
            </FormField.BaseForm>
        );
    }
}

export default reduxForm({
    form: 'login',
})(LoginForm as any) as React.ComponentClass<{ onSubmit: (value: Api.LoginModel) => Promise<any> }>;

