import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import HelpHeader from "./HelpHeader";
import { getText } from '../utils/texts';

export default class SalesAnalysis extends React.Component<{}, {}> {
    public render() {
        return (
            <div>
                <div className="card" style={{ ...Styles.cardStyle,  ...Styles.pageTitle}}>
                    <span style={{paddingLeft: 30}}>
                        {getText( "SalesAnalysisTitle")}
                    </span>
                    <HelpHeader />
                </div>
                <div style={{height: '70vh', color: 'red', 
                    fontSize: 60, fontWeight: 100, display: 'flex', 
                    justifyContent: 'center', margin: '1%',
                    alignItems: 'center',
                    }}>
                    Page en construction
                </div>
            </div>
        );
    }
}
