import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import DialogModal from "./DialogModal";
import { getImagePath } from '../utils/utils';
import Select from "./Select";
import * as PricePeriodStore from "../store/PricePeriod";
import { getText } from '../utils/texts';
import { FamilyGroup, SubFamilyGroup } from '../store/PricePeriod';

type PricePeriodStoreItemsProps = PricePeriodStoreItemsOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.PricePeriodStoreItemModel>
    & PricePeriodStore.PricePeriodState
    & typeof PricePeriodStore.actionCreators;

export interface PricePeriodStoreItemsOwnProps {
    isPricePeriodStoreItemDialogOpen: boolean;
    openPricePeriodStoreItemsDialog: () => void;
    closePricePeriodStoreItemsDialog: () => void;
    savePricePeriodStoreItemsDialog: () => void;
    onSubmit: (e: any) => void;
    defautPriceNameId: number;
    pricePeriodStoreItems: Array<Api.PricePeriodStoreItemModel>;
    priceNames: { [id: number]: Api.PriceNameModel };
    products: { [id: number]: Api.ProductModel };
    menus: { [id: number]: Api.MenuModel };
    stockStatus: Api.StockStatusModel;
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
    updateFamilyId: (value: any) => any;
    updateSubFamilyId: (value: any) => any;
    familyId: number;
    subFamilyId: number;
    updateFamiliesIds: (key: string, e: Array<FamilyGroup>) => any;
    updateSubFamiliesIds: (key: string, e: Array<SubFamilyGroup>) => any;
    familiesGrouped: Array<FamilyGroup>;
    subFamiliesGrouped: Array<SubFamilyGroup>;
    keyAction: string;
}

const itemContainerStyle: React.CSSProperties = {
    padding: "5px 10px"
};

export default class PricePeriodStoreItems extends React.Component<PricePeriodStoreItemsProps, {}> {
    closeModal() {
        this.props.closePricePeriodStoreItemsDialog();
    }

    saveProducts() {
        this.props.savePricePeriodStoreItemsDialog();
    }

    get activePlanogram(): Api.PlanogramModel {
        return _.sortBy(this.props.stockStatus.planograms, x => !x.active)[0];
    }

    getProductRails() {
        return this.props.stockStatus.productRails.filter(z => z.planogramId === this.activePlanogram.planogramId)
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    hasProductsInPlanogram(familyId?: number, subFamilyId?: number): boolean {
        return _.values(this.props.products)
            .filter(x => (!familyId
                    || x.productSubFamilies.some(y => this.props.subFamilies[y.subFamilyId].familyId === familyId))
                && (!subFamilyId
                    || x.productSubFamilies.some(y => y.subFamilyId === subFamilyId))
                && (this.props.stockStatus.productRails.some(z => z.planogramId === this.activePlanogram.planogramId && z.productId === x.storeItemId))
            ).length > 0 ? true : false;
    }    

    public render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6">{this.props.fields.length} {getText( "PricePeriodProduct")}</div>
                    <div className="col-md-6" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button className="btn btn-primary"
                            style={{width: '73%'}}
                            onClick={(e) => {
                                this.props.openPricePeriodStoreItemsDialog();
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                {getText( "PricePeriodAdd")}
                        </button>
                    </div>
                </div>
                <DialogModal contentLabel={"Période de ventes"}
                    contentStyle={{ 
                        width:'60%', 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                    }}
                    title={getText( "PricePeriodProducts")}
                    isOpen={this.props.isPricePeriodStoreItemDialogOpen}
                    onRequestClose={() => this.closeModal()}>
                    <div className="selectors borderSelectors" style={{marginTop: '2%'}}>
                        <div className="row" style={{textAlign: 'left'}}>
                            <div className="col-md-6">
                                <div style={{fontSize: 16}}>{getText( "PricePeriodFamilies")}</div>
                                <Select 
                                style={{
                                    backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                    color: this.isDarkMode() ? "white" : "#3B4370"
                                }}
                                options={[{
                                    label: "------",
                                    value: undefined
                                }].concat(_.values(this.props.families)
                                    .filter(x => this.hasProductsInPlanogram(x.familyId))
                                    .map(x => ({ label: x.name, value: x.familyId })))}
                                    value={this.props.familyId}
                                    onChange={(value) => this.props.updateFamilyId(value)} />
                                </div>
                            <div className="col-md-6">
                                <div style={{fontSize: 16}}>{getText( "PricePeriodSubfamilies")}</div>
                                <Select 
                                style={{
                                    backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                    color: this.isDarkMode() ? "white" : "#3B4370"
                                }}
                                options={[{
                                    label: "------",
                                    value: undefined
                                }].concat(_.values(this.props.subFamilies)
                                .filter(x => x.familyId === this.props.familyId && this.hasProductsInPlanogram(this.props.familyId, x.subFamilyId))
                                    .map(x => ({ label: x.name, value: x.subFamilyId })))}
                                    value={this.props.subFamilyId}
                                    onChange={(value) => this.props.updateSubFamilyId(value)} />
                            </div>
                        </div>
                        <div className="row" style={{padding: '1% 0'}}>
                            <div className="col-md-6">
                                <button
                                    className="btn btn-primary"
                                    style={{ 
                                        width: '100%',
                                        marginRight: "10px",
                                        backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                        color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                        borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                                    }}
                                    onClick={(e) => {
                                        if (!this.props.familyId) {
                                            return
                                        }
                                        let productsToAdd = [];
                                        var family = this.props.families[this.props.familyId];

                                        let familiesIds = this.props.familiesGrouped ?
                                            this.props.familiesGrouped.concat([{
                                                familyPriceNameId: this.props.defautPriceNameId,
                                                familyId: family.familyId
                                            }])
                                            : [{
                                                familyPriceNameId: this.props.defautPriceNameId,
                                                familyId: family.familyId
                                            }];
                                        this.props.updateFamiliesIds(this.props.keyAction,familiesIds);

                                        productsToAdd = _.sortBy(_.uniq(this.getProductRails()
                                            .map(x => x.productId)
                                            .filter(x => !this.props.pricePeriodStoreItems.some(y => y.storeItemId === x)))
                                            .map(x => this.props.products[x]), x => x.name).filter(z => (!this.props.familyId
                                                || z.productSubFamilies.some(y => this.props.subFamilies[y.subFamilyId].familyId === this.props.familyId)))

                                        productsToAdd.forEach(product => {
                                            this.props.fields.push({
                                                storeItemId: product.storeItemId,
                                                priceNameId: this.props.defautPriceNameId ? this.props.defautPriceNameId : _.sortBy(product.prices,
                                                    x => x.priceNameId !== this.props.defautPriceNameId)[0].priceNameId
                                            } as Api.PricePeriodStoreItemModel);
                                            e.stopPropagation();
                                        });
                                    }}>
                                    {getText( "PricePeriodAddFamily")}
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button
                                    className="btn btn-primary"
                                    style={{ 
                                        width: '100%', 
                                        marginRight: "10px",
                                        backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                        color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                        borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                                    }}
                                    onClick={(e) => {
                                        if (!this.props.subFamilyId) {
                                            return
                                        }
                                        let productsToAdd = [];
                                        var subFamily = this.props.subFamilies[this.props.subFamilyId];  

                                        let subFamiliesIds = this.props.subFamiliesGrouped ?
                                            this.props.subFamiliesGrouped.concat([{
                                                subFamilyPriceNameId: this.props.defautPriceNameId,
                                                subFamilyId: subFamily.subFamilyId
                                            }])
                                            : [{
                                                subFamilyPriceNameId: this.props.defautPriceNameId,
                                                subFamilyId: subFamily.subFamilyId
                                            }];
                                        this.props.updateSubFamiliesIds(this.props.keyAction, subFamiliesIds);

                                        productsToAdd = _.sortBy(_.uniq(this.getProductRails()
                                            .map(x => x.productId)
                                            .filter(x => !this.props.pricePeriodStoreItems.some(y => y.storeItemId === x)))
                                            .map(x => this.props.products[x]), x => x.name).filter(z => (!this.props.familyId
                                                || z.productSubFamilies.some(y => this.props.subFamilies[y.subFamilyId].familyId === this.props.familyId))
                                                && (!this.props.subFamilyId
                                                    || z.productSubFamilies.some(y => y.subFamilyId === this.props.subFamilyId)))

                                        productsToAdd.forEach(product => {
                                            this.props.fields.push({
                                                storeItemId: product.storeItemId,
                                                priceNameId: this.props.defautPriceNameId ? this.props.defautPriceNameId : _.sortBy(product.prices,
                                                    x => x.priceNameId !== this.props.defautPriceNameId)[0].priceNameId
                                            } as Api.PricePeriodStoreItemModel);
                                            e.stopPropagation();
                                        });
                                    }}>
                                    {getText( "PricePeriodAddSubfamily")}
                                </button>
                            </div>
                        </div>
                        <div className="row" style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="col-md-6">
                                <button
                                    className="btn btn-primary"
                                    style={{ 
                                        width: '100%', 
                                        marginRight: "10px",
                                        backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                        color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                        borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                                    }}
                                    onClick={(e) => {
                                        let productsToAdd = [];
                                        this.getProductRails().forEach(productRail => {
                                            let product = this.props.products[productRail.productId];
                                            if (!productsToAdd.some(x => x.storeItemId === product.storeItemId)) {
                                                productsToAdd.push(product);
                                            }
                                        });
                                        productsToAdd.forEach(product => {
                                            this.props.fields.push({
                                                storeItemId: product.storeItemId,
                                                priceNameId: _.sortBy(product.prices,
                                                    x => x.priceNameId !== this.props.defautPriceNameId)[0].priceNameId
                                            } as Api.PricePeriodStoreItemModel);
                                            e.stopPropagation();
                                        });
                                    }}>
                                    {getText( "PricePeriodAddAll")}
                                </button>
                            </div>
                        </div>
                        <div style={{
                            marginTop: "20px",
                            height: 350,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "stretch"
                        }}>
                            <div style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                marginRight: 5,
                                flex: 1,
                                padding: "10px 5px",
                                overflowY: "scroll",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch"
                            }}>
                                {_.sortBy(_.uniq(this.getProductRails()
                                    .map(x => x.productId)
                                    .filter(x => !this.props.pricePeriodStoreItems.some(y => y.storeItemId === x)))
                                    .map(x => this.props.products[x]), x => x.name).filter(z => (!this.props.familyId
                                        || z.productSubFamilies.some(y => this.props.subFamilies[y.subFamilyId].familyId === this.props.familyId))
                                        && (!this.props.subFamilyId
                                            || z.productSubFamilies.some(y => y.subFamilyId === this.props.subFamilyId)))
                                    .map((product, index) => <div key={product.storeItemId} style={{
                                        padding: "5px 10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        backgroundColor: index % 2 != 0 ? "rgba(59, 67, 112, 0.1)" : ""
                                    }}
                                        onClick={(e) => {
                                            this.props.fields.push({
                                                storeItemId: product.storeItemId,
                                                priceNameId: _.sortBy(product.prices,
                                                    x => x.priceNameId !== this.props.defautPriceNameId)[0].priceNameId
                                            } as Api.PricePeriodStoreItemModel);
                                            e.stopPropagation();
                                        }}>
                                        <div>
                                            <img height={20} src={getImagePath(product.image)} />
                                        </div>
                                        <div style={{ marginLeft: '5%' }}>
                                            {product.name}
                                        </div>
                                    </div>)}
                            </div>
                            <div style={{
                                flex: 1,
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                padding: "10px 5px",
                                overflowY: "scroll",
                                marginLeft: '2%'
                            }}>
                                {this.props.familiesGrouped && _.values(this.props.familiesGrouped).map((familyGrouped, index) => {
                                    let family = this.props.families[familyGrouped.familyId]
                                    if (!family)
                                        return null;

                                    return (
                                        <div
                                            key={family.familyId}
                                            style={{
                                                padding: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "stretch",
                                                backgroundColor: index % 2 != 0 ? "rgba(59, 67, 112, 0.1)" : ""
                                            }}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img height={20} src={getImagePath(family.image)} />
                                                </div>
                                                <div className="col-md-8" style={{textAlign: 'start'}}>
                                                    {getText( "PricePeriodFamily")}  {family.name}
                                                </div>
                                                <div className="col-md-2">
                                                    <i className="glyphicon glyphicon-remove"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            for (let index = this.props.fields.length - 1; index >= 0; index--) {
                                                                const pricePeriodStoreItem = this.props.pricePeriodStoreItems[index];
                                                                if (this.props.products[pricePeriodStoreItem.storeItemId].productSubFamilies.find(y => this.props.subFamilies[y.subFamilyId].familyId === family.familyId)) {
                                                                    this.props.fields.remove(index);
                                                                    e.stopPropagation();
                                                                }
                                                            }
                                                            const famylyToIndex = _.values(this.props.familiesGrouped).find(x => x.familyId === family.familyId);
                                                            const index = _.values(this.props.familiesGrouped).indexOf(famylyToIndex);
                                                            if (index > -1) {
                                                                let familiesIds = this.props.familiesGrouped.filter(x => x.familyId !== family.familyId);
                                                                this.props.updateFamiliesIds(this.props.keyAction, familiesIds);
                                                            }
                                                        }}></i>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginTop: '2%'}}>
                                                <div className="col-md-2"  style={{marginLeft: '3%'}}>{getText( "PricePeriodRate")} : </div>
                                                <div className="col-md-8">
                                                    {this.props.fields.map((member, index) => {
                                                        let product = this.props.products[this.props.pricePeriodStoreItems[index].storeItemId];
                                                        if(product.productSubFamilies.some(x => this.props.subFamilies[x.subFamilyId].familyId === family.familyId)) {
                                                            return (<div key={product.storeItemId}>
                                                                <span>{product.name} : </span>
                                                                <ReduxForm.Field
                                                                    name={`${member}.priceNameId`}
                                                                    validate={[FormField.required]}
                                                                    component={FormField.getSelectField(
                                                                        _.values(this.props.products[this.props.pricePeriodStoreItems[index].storeItemId].prices)
                                                                            .map(x => ({
                                                                                label: `${this.props.priceNames[x.priceNameId].name} | ${x.value.toFixed(2)}`,
                                                                                value: x.priceNameId
                                                                            }
                                                                        )
                                                                    )
                                                                )}/>
                                                            </div>)}
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {this.props.subFamiliesGrouped && _.values(this.props.subFamiliesGrouped).map((subFamilyGrouped, index) => {
                                    let subFamily = this.props.subFamilies[subFamilyGrouped.subFamilyId]
                                    if (!subFamily)
                                        return null;

                                    return (
                                        <div
                                            key={subFamily.subFamilyId}
                                            style={{
                                                padding: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "stretch",
                                                backgroundColor: index % 2 != 0 ? "rgba(59, 67, 112, 0.1)" : ""
                                            }}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img height={20} src={getImagePath(subFamily.image)} />
                                                </div>
                                                <div className="col-md-8" style={{textAlign: 'start'}}>
                                                    {getText( "PricePeriodSubfamily")} {subFamily.name}
                                                </div>
                                                <div className="col-md-2">
                                                    <i className="glyphicon glyphicon-remove"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            for (let index = this.props.fields.length - 1; index >= 0; index--) {
                                                                const pricePeriodStoreItem = this.props.pricePeriodStoreItems[index];
                                                                if (pricePeriodStoreItem && this.props.products[pricePeriodStoreItem.storeItemId].productSubFamilies.find(y => this.props.subFamilies[y.subFamilyId].familyId === subFamily.familyId)
                                                                    && (!subFamily.subFamilyId || this.props.products[pricePeriodStoreItem.storeItemId].productSubFamilies.find(z => z.subFamilyId === subFamily.subFamilyId))) {
                                                                    this.props.fields.remove(index);
                                                                    e.stopPropagation();
                                                                }
                                                            }
                                                            const subFamilyToIndex = _.values(this.props.subFamiliesGrouped).find(x => x.subFamilyId === subFamily.subFamilyId);
                                                            const index = _.values(this.props.subFamiliesGrouped).indexOf(subFamilyToIndex);
                                                            if (index > -1) {
                                                                let subFamiliesIds = this.props.subFamiliesGrouped.filter(x => x.subFamilyId !== subFamily.subFamilyId);
                                                                this.props.updateSubFamiliesIds(this.props.keyAction, subFamiliesIds);
                                                            }
                                                        }}></i>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginTop: '2%'}}>
                                                <div className="col-md-2"  style={{marginLeft: '3%'}}>{getText( "PricePeriodRate")}: </div>
                                                <div className="col-md-8">
                                                    {this.props.fields.map((member, index) => {
                                                        let product = this.props.products[this.props.pricePeriodStoreItems[index].storeItemId];
                                                        if(product.productSubFamilies.some(x => x.subFamilyId === subFamily.subFamilyId)) {
                                                            return (<div key={product.storeItemId}>
                                                                <span>{product.name} : </span>
                                                                <ReduxForm.Field
                                                                    name={`${member}.priceNameId`}
                                                                    validate={[FormField.required]}
                                                                    component={FormField.getSelectField(
                                                                        _.values(this.props.products[this.props.pricePeriodStoreItems[index].storeItemId].prices)
                                                                            .map(x => ({
                                                                                label: `${this.props.priceNames[x.priceNameId].name} | ${x.value.toFixed(2)}`,
                                                                                value: x.priceNameId
                                                                            }
                                                                        )
                                                                    )
                                                                )}/>
                                                            </div>)}
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {this.props.fields.map((member, index) => {
                                    let pricePeriodStoreItem = this.props.pricePeriodStoreItems[index];
                                    if (!pricePeriodStoreItem 
                                        || (this.props.familiesGrouped && this.props.familiesGrouped.length > 0 && _.values(this.props.familiesGrouped).some(z => this.props.products[pricePeriodStoreItem.storeItemId].productSubFamilies.some(y => this.props.subFamilies[y.subFamilyId].familyId === z.familyId)))
                                        || (this.props.subFamiliesGrouped && this.props.subFamiliesGrouped.length > 0 && _.values(this.props.subFamiliesGrouped).some(z => this.props.products[pricePeriodStoreItem.storeItemId].productSubFamilies.some(y => y.subFamilyId === z.subFamilyId))
                                    ))
                                        return null;

                                    return (
                                        <div
                                            key={pricePeriodStoreItem.storeItemId}
                                            style={{
                                                padding: '10px',
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "stretch",
                                                backgroundColor: index % 2 != 0 ? "rgba(59, 67, 112, 0.1)" : ""
                                            }}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img height={20} src={getImagePath(this.props.products[pricePeriodStoreItem.storeItemId].image)} />
                                                </div>
                                                <div className="col-md-8" style={{textAlign: 'start'}}>
                                                    {this.props.products[pricePeriodStoreItem.storeItemId].name}
                                                </div>
                                                <div className="col-md-2">
                                                    <i className="glyphicon glyphicon-remove"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            this.props.fields.remove(index);
                                                            e.stopPropagation();
                                                        }}></i>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginTop: '2%'}}>
                                                <div className="col-md-2"  style={{marginLeft: '3%'}}>{getText( "PricePeriodRate")}: </div>
                                                <div className="col-md-8">
                                                    <ReduxForm.Field
                                                        name={`${member}.priceNameId`}
                                                        validate={[FormField.required]}
                                                        component={FormField.getSelectField(
                                                            _.values(this.props.products[pricePeriodStoreItem.storeItemId].prices)
                                                                .map(x => ({
                                                                    label: `${this.props.priceNames[x.priceNameId].name} | ${x.value.toFixed(2)}`,
                                                                    value: x.priceNameId
                                                                })))} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div style={{ textAlign: "center", paddingTop: 20 }}>
                            <button className="btn btn-primary"
                                style={{
                                    width: '50%',
                                    backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                    color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                    borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                                }}
                                onClick={(e) => {
                                    //this.props.onSubmit(e);
                                    this.saveProducts();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}>
                                {getText( "PricePeriodSave")} 
                            </button>
                        </div>
                    </div>
                </DialogModal>
                <FormField.FieldErrors {...this.props.meta} />
            </div >
        );
    }
}
