import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as HistoryStore from '../store/History';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import DialogModal from "./DialogModal";
import { getText } from '../utils/texts';

type CardCollectionDialogProps = HistoryStore.ViewCardCollection
    & CardCollectionDialogOwnProps
    & typeof HistoryStore.actionCreators;

interface CardCollectionDialogOwnProps {
}

class CardCollectionDialog extends React.Component<CardCollectionDialogProps, {}> {

    get dataStyle1() {
        return ([
            {text: getText("HistoCardCollectionDiTransType"), value: this.props.currentCollection.transactionType},
            {text: getText("HistoCardCollectionDiTransNum"), value: this.props.currentCollection.transactionNumber},
            {text: getText("HistoCardCollectionDiSiret"), value: this.props.currentCollection.siret},
            {text: getText("HistoCardCollectionDiRemissionNum"), value: this.props.currentCollection.remissionNumber},
            {text: getText("HistoCardCollectionDiCurrencyNum"), value: this.props.currentCollection.currencyNumber},
            {text: getText("HistoCardCollectionDiTransAmount"), value: this.props.currentCollection.transactionAmount},
            {text: getText("HistoCardCollectionDiAcceptorNum"), value: this.props.currentCollection.acceptorNumber},
            {text: getText("HistoCardCollectionDiDate"), value: this.props.currentCollection.dateTime ? Moment(this.props.currentCollection.dateTime, "DDMMYY-hhmmss").format("DD/MM/YYYY HH:mm:ss") : ""},
            {text: getText("HistoCardCollectionDiCvPart"), value: this.props.currentCollection.cvPart},
            {text: getText("HistoCardCollectionDiCardValidity"), value: this.props.currentCollection.cardValidity},
            {text: getText("HistoCardCollectionDiCurrencyCode"), value: this.props.currentCollection.currencyCode},
            {text: getText("HistoCardCollectionDiAccountNum"), value: this.props.currentCollection.accountNumber},
            {text: getText("HistoCardCollectionDiReadingMode"), value: this.props.currentCollection.readingMode},
            {text: getText("HistoCardCollectionDiActivityType"), value: this.props.currentCollection.activityType},
            {text: getText("HistoCardCollectionDiCvAmount"), value: this.props.currentCollection.cvAmount},
            {text: getText("HistoCardCollectionDiContractNum"), value: this.props.currentCollection.contractNumber},
            {text: getText("HistoCardCollectionDiCurrencyPart"), value: this.props.currentCollection.currencyPart},
            {text: getText("HistoCardCollectionDiAID"), value: this.props.currentCollection.aid},
            {text: getText("HistoCardCollectionDiCvCode"), value: this.props.currentCollection.cvCode},           
            {text: getText("HistoCardCollectionDiLabel"), value: this.props.currentCollection.label},
            {text: getText("HistoCardCollectionDiPaymentType"), value: this.props.currentCollection.paymentType},
            {text: getText("HistoCardCollectionDiLocationType"), value: this.props.currentCollection.locationType},
            {text: getText("HistoCardCollectionDiAppType"), value: this.props.currentCollection.applicationType},
            {text: getText("HistoCardCollectionDiCrypto"), value: this.props.currentCollection.crypto},
            {text: getText("HistoCardCollectionDiAuthoNum"), value: this.props.currentCollection.authorizationNumber},
            {text: getText("HistoCardCollectionDiForceCode"), value: this.props.currentCollection.forceCode},
            {text: getText("HistoCardCollectionDiEstimatedAmount"), value: this.props.currentCollection.estimatedAmount},
            {text: getText("HistoCardCollectionDiServiceCode"), value: this.props.currentCollection.serviceCode}
        ])
    }
   
    get dataStyle2() {
        return ([
            {text: getText("HistoCardCollectionDiAcceptorTitle"), value: this.props.currentCollection.acceptorTitle},
            {text: getText("HistoCardCollectionDiReceiptTitle"), value: this.props.currentCollection.receiptTitle},
            {text: getText("HistoCardCollectionDiReceiptFooter"), value: this.props.currentCollection.receiptFooter}
        ])
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        if(this.props.currentCollection == undefined || !this.props.currentPayment == undefined)
            return <React.Fragment></React.Fragment>;
        
        return (
            <DialogModal isOpen={this.props.isOpen}
                contentLabel={"cardCollection"}
                contentStyle={{ 
                    width: 940,
                    maxHeight: 600, 
                    background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                    border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)"
                }}
                title={getText("HistoCardCollectionDialogTitle")}
                onRequestClose={() => {
                   this.props.closeViewCardCollection();
                }}>
                { 
                    <div style={{ marginTop: 10 }}>
                        <div style={{ textAlign: "left", fontSize: "12px", padding: "15px 5px"}} className="row"> 
                            <div style={{width: "55%", position: "relative", minHeight: 1, paddingRight: 15, paddingLeft: 15, float: 'left'}}>
                            {
                                this.dataStyle1.map((x, index) => (
                                    (index % 2 == 0) && (
                                        <div key={index} className="row" style={{ marginBottom: "5px" }}>
                                            <div className="col-md-7">
                                                {x.text}
                                            </div>
                                            <div className="col-md-5">
                                                <input disabled
                                                    style={{fontSize: "12px"}}
                                                    className="form-control"
                                                    type="text"
                                                    value={x.value ? x.value : ""} />
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                            </div>
                            <div style={{width: "45%", position: "relative", minHeight: 1, paddingRight: 15, paddingLeft: 15, float: 'left'}}>
                            {
                                this.dataStyle1.map((x, index) => (
                                    (index % 2 != 0) && (
                                        <div key={index} className="row" style={{ marginBottom: "5px" }}>
                                            <div className="col-md-6">
                                                {x.text}
                                            </div>
                                            <div className="col-md-6">
                                                <input disabled
                                                    style={{fontSize: "12px"}}
                                                    className="form-control"
                                                    type="text"
                                                    value={x.value ? x.value : ""} />
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                            </div>
                            <div className="col-md-12">
                                {
                                this.dataStyle2.map((x, index) => ( 
                                    <div  key={index} className="row" style={{ marginBottom: "5px" }}>
                                        <div className="col-md-4">
                                            {x.text}
                                        </div>
                                        <div className="col-md-8">
                                            <input disabled
                                                style={{fontSize: "12px"}}
                                                className="form-control"
                                                type="text"
                                                value={x.value ? x.value : ""} />
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary btn-sm"
                            type={"button"}
                            style={{
                                width: 175,
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.props.closeViewCardCollection()}> {getText("HistoCollectionCloseButton")} </button>
                    </div>
                }
            </DialogModal>
        );
    }
}

export default connect((state: ApplicationState) => {
    return {
        ...state.history.viewCardCollection,
    } as CardCollectionDialogOwnProps
}, {...HistoryStore.actionCreators,})(CardCollectionDialog as any)