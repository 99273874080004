import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import NeoEventAlarmContacts, { NeoEventAlarmContactsOwnProps } from "./NeoEventAlarmContacts";
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type NeoEventAlarmFormProps = ReduxForm.InjectedFormProps<Api.NeoEventAlarmModel, {}, {}>
    & NeoEventAlarmFormOwnProps
    & NeoEventAlarmFormExternalProps;

interface NeoEventAlarmFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.NeoEventAlarmModel) => any;
    openContactDialog: () => void;
    closeContactDialog: () => void;
}

interface NeoEventAlarmFormOwnProps {
    neoEventAlarmContacts: Array<Api.NeoEventAlarmContactModel>;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
    contacts: { [id: number]: Api.ContactModel };
    isDialogOpen: boolean;
    disabled: boolean;
}


class NeoEventAlarmForm extends React.Component<NeoEventAlarmFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-vertical selectors borderSelectors">
                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-md-5 form-group">
                        <label className="control-label">{getText( "MaintenanceEventCode")} </label>
                        <ReduxForm.Field
                            name="neoEventCodeId"
                            validate={[FormField.required]}
                            disabled={true}
                            component={FormField.getSelectField(
                                _.sortBy(
                                    _.values(this.props.neoEventCodes)
                                        .filter(x => (800 <= x.code && x.code < 1000) ||
                                            x.code == 402 || x.code == 105 || x.code == 47 || x.code == 48 || x.code == 143 || x.code == 147)
                                        .map(x => ({
                                            label: `${x.code}|${x.name}`,
                                            value: x.neoEventCodeId
                                        })), x => x.label))} />
                        <div style={{marginTop: '2%'}}>
                            <button className="btn btn-primary"
                                style={{width: '100%', height: 40, fontSize: 15, fontWeight: 'bold'}}
                                type={"submit"}
                                disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                                {this.props.actionName}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-7 form-group">
                        <label className="control-label">{getText( "MaintenanceTabContacts")} </label>
                        <ReduxForm.FieldArray
                            name="neoEventAlarmContacts"
                            component={NeoEventAlarmContacts}
                            props={{
                                openDialog: () => this.props.openContactDialog(),
                                closeDialog: () => this.props.closeContactDialog(),
                                isDialogOpen: this.props.isDialogOpen,
                                contacts: _.values(this.props.contacts),
                                neoEventAlarmContacts: this.props.neoEventAlarmContacts,
                                disabled: this.props.disabled
                            } as NeoEventAlarmContactsOwnProps}
                        />
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}



// let createForm = ReduxForm.reduxForm({
//     form: 'createNeoEventAlarm',
//     enableReinitialize: true,
//     destroyOnUnmount: true
// })(NeoEventAlarmForm as any) as any;
// const createSelector = ReduxForm.formValueSelector('createNeoEventAlarm');
// export const CreateNeoEventAlarmForm = connect((state: ApplicationState) => {
//     return {
//         initialValues: {
           
//         } as Api.NeoEventAlarmModel,
//         neoEventCodes: state.seed.seed.neoEventCodes,
//         contacts: state.contact.contacts,
//         isDialogOpen: state.contact.isDialogOpen,
//         neoEventAlarmContacts: createSelector(state, "neoEventAlarmContacts") || [],
//         disabled: !canEditSettings(state)
//     } as NeoEventAlarmFormOwnProps
// }, null)(createForm as any) as any as React.ComponentClass<NeoEventAlarmFormExternalProps>;

let updateForm = ReduxForm.reduxForm({
    form: 'updateNeoEventAlarm',
    enableReinitialize: true,
    destroyOnUnmount: true
})(NeoEventAlarmForm as any) as any;
const updateSelector = ReduxForm.formValueSelector('updateNeoEventAlarm');
export const UpdateNeoEventAlarmForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.neoEventAlarm.selectedNeoEventAlarmId
            ? state.neoEventAlarm.neoEventAlarms[state.neoEventAlarm.selectedNeoEventAlarmId]
            : {},
        neoEventCodes: state.seed.seed.neoEventCodes,
        contacts: state.contact.contacts,
        isDialogOpen: state.contact.isDialogOpen,
        neoEventAlarmContacts: updateSelector(state, "neoEventAlarmContacts") || [],
        disabled: !canUpdate(state)
    } as NeoEventAlarmFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<NeoEventAlarmFormExternalProps>;