import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notification from "react-notification-system-redux";
import { getText } from '../utils/texts';

export interface CardState {
    isLoading: boolean;
    requestTime?: number;
    state?: Api.CardHandlerStateModel;
    cardEnabledState: {
        isLoading: boolean;
        requestTime?: number;
    };
    noContactEnabledState: {
        isLoading: boolean;
        requestTime?: number;
    };
    updateDateTimeState: {
        isLoading: boolean;
        requestTime?: number;
    };
    testState: {
        isCardTest: boolean;
        solvency: number;
        collect: number;
        paymentRunning: boolean;
        startPaymentState: {
            isLoading: boolean;
            requestTime?: number;
        };
        stopPaymentState: {
            isLoading: boolean;
            requestTime?: number;
        };
        collectAmountState: {
            isLoading: boolean;
            requestTime?: number;
        };
        cancelPaymentState: {
            isLoading: boolean;
            requestTime?: number;
        };
    }
}

interface RequestCardState {
    type: "REQUEST_CARD_STATE";
    payload: { requestTime: number }
}
interface ReceiveCardState {
    type: "RECEIVE_CARD_STATE";
    payload: { requestTime: number; state?: Api.CardHandlerStateModel };
    error?: any;
}
export interface ReceiveCardStateUpdated {
    type: "RECEIVE_CARD_STATE_UPDATED";
    payload: { state?: Api.CardHandlerStateModel };
}

interface RequestUpdateCardEnabled {
    type: "REQUEST_UPDATE_CARD_ENABLED";
    payload: { requestTime: number; };
}
export interface ReceiveUpdateCardEnabled {
    type: "RECEIVE_UPDATE_CARD_ENABLED";
    payload: { value: boolean; requestTime: number; };
    error?: any;
}

interface RequestUpdateNoContactEnabled {
    type: "REQUEST_UPDATE_NOCONTACT_ENABLED";
    payload: { requestTime: number; };
}
export interface ReceiveUpdateNoContactEnabled {
    type: "RECEIVE_UPDATE_NOCONTACT_ENABLED";
    payload: { value: boolean; requestTime: number; };
    error?: any;
}

interface RequestUpdateCardDateTime {
    type: "REQUEST_UPDATE_CARDDATETIME";
    payload: { requestTime: number; };
}
interface ReceiveUpdateCardDateTime {
    type: "RECEIVE_UPDATE_CARDDATETIME";
    payload: { requestTime: number; };
    error?: any;
}

interface RequestCardStartTestPayment {
    type: "REQUEST_CARD_START_TESTPAYMENT";
    payload: { requestTime: number; };
}
interface ReceiveCardStartTestPayment {
    type: "RECEIVE_CARD_START_TESTPAYMENT";
    payload: { requestTime: number; };
    error?: any;
}

interface RequestCardCancelPayment {
    type: "REQUEST_CARD_CANCEL_PAYMENT";
    payload: { requestTime: number; };
}
interface ReceiveCardCancelPayment {
    type: "RECEIVE_CARD_CANCEL_PAYMENT";
    payload: { requestTime: number; };
    error?: any;
}

interface RequestCardCollectAmount {
    type: "REQUEST_CARD_COLLECT_AMOUNT";
    payload: { requestTime: number; };
}
interface ReceiveCardCollectAmount {
    type: "RECEIVE_CARD_COLLECT_AMOUNT";
    payload: { requestTime: number; };
    error?: any;
}

export interface ReceiveCardSolvencyChanged {
    type: "RECEIVE_CARD_SOLVENCY_CHANGED";
    payload: { value: number; };
}
export interface ReceiveCardPaymentCancelled {
    type: "RECEIVE_CARD_PAYMENT_CANCELLED";
}

export interface ReceiveCardAmountCollected {
    type: "RECEIVE_CARD_AMOUNT_COLLECTED";
    payload: { value: number; };
}
interface CardUpdateSolvencyAmount {
    type: "CARD_UPDATE_SOLVENCY_AMOUNT";
    payload: { value: number; };
}
interface CardUpdateCollectAmount {
    type: "CARD_UPDATE_COLLECT_AMOUNT";
    payload: { value: number; };
}
interface CardUpdateIsTestCard {
    type: "CARD_UPDATE_ISTESTCARD";
    payload: { value: boolean; };
}

interface RequestCardStopPaymentSession {
    type: "REQUEST_CARD_STOPPAYMENTSESSION";
    payload: { requestTime: number; };
}
interface ReceiveCardStopPaymentSession {
    type: "RECEIVE_CARD_STOPPAYMENTSESSION";
    payload: { requestTime: number; };
    error?: any;
}

type KnownAction = RequestCardState
    | ReceiveCardState
    | RequestUpdateCardEnabled
    | ReceiveUpdateCardEnabled
    | RequestUpdateNoContactEnabled
    | ReceiveUpdateNoContactEnabled
    | RequestCardStartTestPayment
    | ReceiveCardStartTestPayment
    | RequestCardCancelPayment
    | ReceiveCardCancelPayment
    | RequestCardCollectAmount
    | ReceiveCardCollectAmount
    | ReceiveCardSolvencyChanged
    | ReceiveCardAmountCollected
    | ReceiveCardPaymentCancelled
    | CardUpdateSolvencyAmount
    | CardUpdateCollectAmount
    | CardUpdateIsTestCard
    | RequestCardStopPaymentSession
    | ReceiveCardStopPaymentSession
    | ReceiveCardStateUpdated
    | RequestUpdateCardDateTime
    | ReceiveUpdateCardDateTime
    ;

export const actionCreators = {
    requestCardState: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetch = api.getState({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(state => {
                dispatch({
                    type: "RECEIVE_CARD_STATE",
                    payload: { requestTime: requestTime, state: state }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARD_STATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARD_STATE",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestUpdateCardDateTime: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetch = api.requestUpdateDateTime({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(state => {
                dispatch(Notification.success({ title: getText("NotificationTitleSuccess"), message: getText("NotificationSuccessCard") , position: "tc", level: "error" }) as any);
                dispatch({
                    type: "RECEIVE_UPDATE_CARDDATETIME",
                    payload: { requestTime: requestTime }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARDDATETIME",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_CARDDATETIME",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requesUpdateCardEnabled: (requestTime: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetchTask = api.updateCardEnabled({
            value: value
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARD_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: value
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_CARD_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: !value
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_CARD_ENABLED",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requesUpdateNoContactEnabled: (requestTime: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetchTask = api.updateNoContactEnabled({
            value: value
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_NOCONTACT_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: value
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_NOCONTACT_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: !value
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_NOCONTACT_ENABLED",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestStartCardTestPayment: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetchTask = api.startTestPayment({
            model: {
                test: getState().card.testState.isCardTest,
                value: getState().card.testState.solvency
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CARD_START_TESTPAYMENT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARD_START_TESTPAYMENT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARD_START_TESTPAYMENT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestCollectAmount: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetchTask = api.askCollectTestPayment({
            value: getState().card.testState.collect
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CARD_COLLECT_AMOUNT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARD_COLLECT_AMOUNT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARD_COLLECT_AMOUNT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestCancelPayment: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetchTask = api.cancelTestPayment({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CARD_CANCEL_PAYMENT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARD_CANCEL_PAYMENT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARD_CANCEL_PAYMENT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    updateCardCollectAmount: (value: number) => <CardUpdateCollectAmount>{
        type: "CARD_UPDATE_COLLECT_AMOUNT",
        payload: { value: value }
    },
    updateCardSolvencyAmount: (value: number) => <CardUpdateSolvencyAmount>{
        type: "CARD_UPDATE_SOLVENCY_AMOUNT",
        payload: { value: value }
    },
    updateIsTestCard: (value: boolean) => <CardUpdateIsTestCard>{
        type: "CARD_UPDATE_ISTESTCARD",
        payload: { value: value }
    },
    requestCardStopPaymentSession: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CardApi();
        let fetchTask = api.stopPaymentSession({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CARD_STOPPAYMENTSESSION",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CARD_STOPPAYMENTSESSION",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CARD_STOPPAYMENTSESSION",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
}

const unloadedState: CardState = {
    isLoading: false,
    cardEnabledState: {
        isLoading: false
    },
    noContactEnabledState: {
        isLoading: false
    },
    updateDateTimeState: {
        isLoading: false  
    },
    testState: {
        collect: 0,
        solvency: 0,
        isCardTest: false,
        paymentRunning: false,
        cancelPaymentState: {
            isLoading: false
        },
        collectAmountState: {
            isLoading: false
        },
        startPaymentState: {
            isLoading: false
        },
        stopPaymentState: {
            isLoading: false
        }
    }
}


export const reducer: Reducer<CardState> = (state: CardState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CARD_STATE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_CARD_STATE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                state: action.error
                    ? state.state
                    : action.payload.state
            };
        case "RECEIVE_CARD_STATE_UPDATED":
            return {
                ...state,
                state: action.payload.state
            };
        case "REQUEST_UPDATE_CARD_ENABLED":
            return {
                ...state,
                cardEnabledState: {
                    ...state.cardEnabledState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_CARD_ENABLED":
            if (action.payload.requestTime !== state.cardEnabledState.requestTime)
                return state;

            return {
                ...state,
                cardEnabledState: {
                    ...state.cardEnabledState,
                    isLoading: false
                }
            };
        case "REQUEST_UPDATE_NOCONTACT_ENABLED":
            return {
                ...state,
                noContactEnabledState: {
                    ...state.noContactEnabledState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_NOCONTACT_ENABLED":
            if (action.payload.requestTime !== state.noContactEnabledState.requestTime)
                return state;

            return {
                ...state,
                noContactEnabledState: {
                    ...state.noContactEnabledState,
                    isLoading: false
                }
            };
        case "REQUEST_CARD_START_TESTPAYMENT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    startPaymentState: {
                        ...state.testState.startPaymentState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CARD_START_TESTPAYMENT":
            if (state.testState.startPaymentState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    paymentRunning: action.error
                        ? state.testState.paymentRunning
                        : true,
                    startPaymentState: {
                        ...state.testState.startPaymentState,
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_CARD_COLLECT_AMOUNT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    collectAmountState: {
                        ...state.testState.collectAmountState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CARD_COLLECT_AMOUNT":
            if (state.testState.collectAmountState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    collectAmountState: {
                        ...state.testState.collectAmountState,
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_CARD_CANCEL_PAYMENT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    cancelPaymentState: {
                        ...state.testState.collectAmountState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CARD_CANCEL_PAYMENT":
            if (state.testState.cancelPaymentState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    paymentRunning: action.error
                        ? state.testState.paymentRunning
                        : false,
                    cancelPaymentState: {
                        ...state.testState.collectAmountState,
                        isLoading: false
                    }
                }
            };
        case "RECEIVE_CARD_PAYMENT_CANCELLED":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    paymentRunning: false
                }
            };
        case "CARD_UPDATE_SOLVENCY_AMOUNT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvency: action.payload.value
                }
            };
        case "CARD_UPDATE_COLLECT_AMOUNT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    collect: action.payload.value
                }
            };
        case "CARD_UPDATE_ISTESTCARD":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    isCardTest: action.payload.value
                }
            };
        case "RECEIVE_CARD_SOLVENCY_CHANGED":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvency: action.payload.value
                }
            };
        case "RECEIVE_CARD_AMOUNT_COLLECTED":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvency: action.payload.value,
                    paymentRunning: false
                }
            };
        case "REQUEST_CARD_STOPPAYMENTSESSION":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    stopPaymentState: {
                        ...state.testState.stopPaymentState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CARD_STOPPAYMENTSESSION":
            if (action.payload.requestTime !== state.testState.stopPaymentState.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    stopPaymentState: {
                        ...state.testState.stopPaymentState,
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_UPDATE_CARDDATETIME":
            return {
                ...state,
                updateDateTimeState: {
                    ...state.updateDateTimeState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_CARDDATETIME":
            if(action.payload.requestTime !== state.updateDateTimeState.requestTime)
                return state;
                
            return {
                ...state,
                updateDateTimeState: {
                    ...state.updateDateTimeState,
                    isLoading: false,
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}