import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import { ReceiveUpdateCashEnabled, ReceiveUpdateCashBillEnabled } from "./Cash";
import { ReceiveUpdateCardEnabled, ReceiveUpdateNoContactEnabled } from "./Card";
import { ReceiveUpdatePrintEnabled } from "./Print";

export interface HmiMoneyState {
    isLoading: boolean;
    requestTime?: number;
    hmiMonies?: Array<Api.HmiMoneyModel>;
    updatesState: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
}

interface RequestHmiMonies {
    type: "REQUEST_HMIMONIES";
    payload: { requestTime: number }
}
interface ReceiveStoreHmiMonies {
    type: "RECEIVE_HMIMONIES";
    payload: { requestTime: number; hmiMonies?: Array<Api.HmiMoneyModel>; };
    error?: any;
}

interface RequestUpdateHmiMoney {
    type: "REQUEST_UPDATE_HMIMONEY";
    payload: { requestTime: number; hmiMoneyId: number }
}
interface ReceiveUpdateHmiMoney {
    type: "RECEIVE_UPDATE_HMIMONEY";
    payload: { requestTime: number; hmiMoneyId: number; hmiMoney?: Api.HmiMoneyModel; };
    error?: any;
}

type KnownAction = RequestHmiMonies
    | ReceiveStoreHmiMonies
    | RequestUpdateHmiMoney
    | ReceiveUpdateHmiMoney
    | ReceiveUpdateCashEnabled
    | ReceiveUpdateCardEnabled
    | ReceiveUpdateCashBillEnabled
    | ReceiveUpdateNoContactEnabled
    | ReceiveUpdatePrintEnabled;

export const actionCreators = {
    requestHmiMonies: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.HmiMoneyApi();
        let fetchTask = api.getHmiMonies({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hmiMonies => {
                dispatch({
                    type: "RECEIVE_HMIMONIES",
                    payload: { requestTime: requestTime, hmiMonies: hmiMonies }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_HMIMONIES",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_HMIMONIES",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestUpdateHmiMoney: (requestTime: number, model: Api.HmiMoneyModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.HmiMoneyApi();
        let fetchTask = api.updateHmiMoney({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hmiMoney => {
                dispatch({
                    type: "RECEIVE_UPDATE_HMIMONEY",
                    payload: {
                        requestTime: requestTime,
                        hmiMoneyId: model.hmiMoneyId,
                        hmiMoney: hmiMoney
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_HMIMONEY",
                    payload: {
                        requestTime: requestTime,
                        hmiMoneyId: model.hmiMoneyId
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_HMIMONEY",
            payload: {
                requestTime: requestTime,
                hmiMoneyId: model.hmiMoneyId
            }
        });
        return fetchTask;
    },
}

const unloadedState: HmiMoneyState = {
    isLoading: false,
    hmiMonies: [],
    updatesState: {}
}

export const reducer: Reducer<HmiMoneyState> = (state: HmiMoneyState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_HMIMONIES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_HMIMONIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                hmiMonies: action.error
                    ? []
                    : action.payload.hmiMonies
            };
        case "REQUEST_UPDATE_HMIMONEY":
            return {
                ...state,
                updatesState: {
                    ...state.updatesState,
                    [action.payload.hmiMoneyId]: {
                        ...state.updatesState[action.payload.hmiMoneyId],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                    }
                }
            };
        case "RECEIVE_UPDATE_HMIMONEY":
            if (!state.updatesState[action.payload.hmiMoneyId].requestTime
                || state.updatesState[action.payload.hmiMoneyId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                hmiMonies: action.error
                    ? state.hmiMonies
                    : state.hmiMonies.map(x => x.hmiMoneyId === action.payload.hmiMoneyId
                        ? action.payload.hmiMoney
                        : x),
                updatesState: {
                    ...state.updatesState,
                    [action.payload.hmiMoneyId]: {
                        ...state.updatesState[action.payload.hmiMoneyId],
                        isLoading: true,
                        requestTime: action.payload.requestTime,
                    }
                }
            };
        case "RECEIVE_UPDATE_CASH_ENABLED":
            return {
                ...state,
                hmiMonies: action.error
                    ? state.hmiMonies
                    : state.hmiMonies.map(x => ({
                        ...x,
                        cashEnabled: action.payload.value
                    }))
            };
        case "RECEIVE_UPDATE_CASH_BILL_ENABLED":
            return {
                ...state,
                hmiMonies: action.error
                    ? state.hmiMonies
                    : state.hmiMonies.map(x => ({
                        ...x,
                        cashBillEnabled: action.payload.value
                    }))
            };
        case "RECEIVE_UPDATE_CARD_ENABLED":
            return {
                ...state,
                hmiMonies: action.error
                    ? state.hmiMonies
                    : state.hmiMonies.map(x => ({
                        ...x,
                        cardEnabled: action.payload.value
                    }))
            };
        case "RECEIVE_UPDATE_NOCONTACT_ENABLED":
            return {
                ...state,
                hmiMonies: action.error
                    ? state.hmiMonies
                    : state.hmiMonies.map(x => ({
                        ...x,
                        noContactEnabled: action.payload.value
                    }))
            };
        case "RECEIVE_UPDATE_PRINT_ENABLED":
            return {
                ...state,
                hmiMonies: action.error
                    ? state.hmiMonies
                    : state.hmiMonies.map(x => ({
                        ...x,
                        printEnabled: action.payload.value
                    }))
            };

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}