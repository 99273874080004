import * as Api from '../api/api';
import * as React from 'react';
import { getText } from '../utils/texts';

interface HmiStatusProps {
    status: Api.HmiApplicationStateModel;
    lastUpdate: number;
    lastRequest: number;
    onRequestUpdate: (requestTime: number) => any;
}

interface HmiStatusState {
    isOff: boolean;
}

export default class HmiStatus extends React.Component<HmiStatusProps, HmiStatusState> {
    interval = null;
    
    constructor(props: HmiStatusProps) {
        super(props);
        this.state = { isOff: true };
    }
    
    componentDidMount() {
        this.interval = setInterval(() => {
            let isOff = !this.props.status
                || this.props.lastUpdate + (1000 * 300) < new Date().getTime()
                || (this.props.lastRequest
                    && this.props.lastRequest - this.props.lastUpdate > 1000 * 3);
            if(isOff !== this.state.isOff){
                this.setState({ isOff: isOff });
                console.log("Switching isOff to " + isOff)
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = null;
    }

    get version(): string {
        return this.props.status
            && this.props.status.version;
    }
    
    get background(): string {
        if (this.state.isOff)
            return 'rgba(191, 191, 191, 1)';
            
        if ((!this.state.isOff && this.props.status.applicationState === "Stopped") 
            || (!this.state.isOff && this.props.status.applicationState === "Off"))
            return 'red';
        
        if ((!this.state.isOff && this.props.status.applicationState === "Maintainance")
            || (!this.state.isOff && this.props.status.applicationState === "Test"))
            return 'orange';
        
        if ((!this.state.isOff && this.props.status.applicationState === "Running")
            || (!this.state.isOff && this.props.status.applicationState === "Selling"))
            return 'green';
    }

    public render() {
        return (
            <div onClick={(e) => this.props.onRequestUpdate(new Date().getTime())} 
                 style={{
                     cursor: 'pointer',
                border: 'none',
                color: 'white',
                padding: '8px 0px',
                width: 125,
                textAlign: 'center',
                display: 'inline-block',
                borderRadius: '4px',
                backgroundColor: this.background}}>
                    {this.state.isOff && <div>{getText( "HMIStatusIsOff")}</div>}
                    {!this.state.isOff && this.props.status.applicationState === "Maintainance"
                        && <div>{getText( "HMIStatusMaintainance")}</div>}
                    {!this.state.isOff && this.props.status.applicationState === "Stopped"
                        && <div>{getText( "HMIStatusStopped")}</div>}
                    {!this.state.isOff && this.props.status.applicationState === "Off"
                        && <div>{getText( "HMIStatusOff")}</div>}
                    {!this.state.isOff && this.props.status.applicationState === "Running"
                        && <div>{getText( "HMIStatusRunning")}</div>}
                    {!this.state.isOff && this.props.status.applicationState === "Selling"
                        && <div>{getText( "HMIStatusSelling")}</div>}
                    {!this.state.isOff && this.props.status.applicationState === "Test"
                        && <div>{getText( "HMIStatusTest")}</div>}
            </div>
        );
     }
}