import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import PricePeriodStoreItems, { PricePeriodStoreItemsOwnProps } from "./PricePeriodStoreItems";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canUpdate } from '../security/roles';
import PeriodDialog from './PeriodDialog';
import { getText } from '../utils/texts';
import { FamilyGroup, SubFamilyGroup } from '../store/PricePeriod';

type PricePeriodFormProps = ReduxForm.InjectedFormProps<Api.PricePeriodModel, {}, {}>
    & PricePeriodFormOwnProps
    & PricePeriodFormExternalProps;

interface PricePeriodFormExternalProps {
    actionName: string;
    isPricePeriodStoreItemDialogOpen: boolean;
    familiesGrouped: Array<FamilyGroup>;
    subFamiliesGrouped: Array<SubFamilyGroup>;
    openPeriodDialog: () => void;
    onSubmit: (values: Api.PricePeriodModel) => any;
    openPricePeriodStoreItemDialog: () => void;
    closePricePeriodStoreItemDialog: () => void;
    updateFamilyId: (value: any) => any;
    updateSubFamilyId: (value: any) => any;
    familyId: number;
    subFamilyId: number;
    updateFamiliesIds: (key: string, e: Array<FamilyGroup>) => any;
    updateSubFamiliesIds: (key: string, e: Array<SubFamilyGroup>) => any;
    keyAction: string;
}

interface PricePeriodFormOwnProps {
    priceNameId: number;
    pricePeriodStoreItems: Array<Api.PricePeriodStoreItemModel>;
    stockStatus: Api.StockStatusModel;
    periods: { [id: number]: Api.PeriodModel };
    priceNames: { [id: number]: Api.PriceNameModel };
    products: { [id: number]: Api.ProductModel };
    menus: { [id: number]: Api.MenuModel };
    disabled: boolean;
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
}

class PricePeriodForm extends React.Component<PricePeriodFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-vertical selectors borderSelectors" style={{ maxWidth: '40%' }}>
                <div className="form-group">
                    <label className="control-label">{getText( "PricePeriodName")} : </label>
                    <ReduxForm.Field
                        style={{height: 40}}
                        name="name"
                        className={"form-control"}
                        type={"text"}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="form-group">
                    <label className="control-label">{getText( "PricePeriodPeriod")} : </label>
                    <div className="row">
                        <div className="col-md-8">
                            <ReduxForm.Field
                                name="periodId"
                                validate={[FormField.required]}
                                component={FormField.getSelectField(
                                    _.values(this.props.periods)
                                        .map(x => ({ label: x.name, value: x.periodId })))} />
                        </div>
                        <div className="col-md-4">
                            <button className="btn btn-primary"
                                style={{width: '100%'}}
                                onClick={(e) => {
                                    this.props.openPeriodDialog();
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}>
                                    {getText( "PricePeriodNewPeriod")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label"> {getText( "PricePeriodDefaultRateName")} : </label>
                    <ReduxForm.Field
                        name="priceNameId"
                        validate={[FormField.required]}
                        component={FormField.getSelectField(
                            _.values(this.props.priceNames)
                                .map(x => ({ label: x.name, value: x.priceNameId })))} />
                </div>
                <div className="form-group" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <label className="control-label pricePeriodFormWidth">
                        {getText( "PricePeriodProducts")} : 
                    </label>
                    <div style={{width: '87%'}}>
                        <ReduxForm.FieldArray
                            validate={[FormField.requiredOne]}
                            name="pricePeriodStoreItems"
                            component={PricePeriodStoreItems}
                            props={{
                                openPricePeriodStoreItemsDialog: () => this.props.openPricePeriodStoreItemDialog(),
                                closePricePeriodStoreItemsDialog: () => {
                                    this.props.change("pricePeriodStoreItems", this.props.initialValues.pricePeriodStoreItems);
                                    this.props.closePricePeriodStoreItemDialog();
                                },
                                savePricePeriodStoreItemsDialog: () => {
                                    this.props.closePricePeriodStoreItemDialog()
                                },
                                onSubmit: (e) => {
                                    //this.props.handleSubmit(e);
                                },
                                defautPriceNameId: this.props.priceNameId,
                                stockStatus: this.props.stockStatus,
                                isPricePeriodStoreItemDialogOpen: this.props.isPricePeriodStoreItemDialogOpen,
                                pricePeriodStoreItems: this.props.pricePeriodStoreItems,
                                products: this.props.products,
                                menus: this.props.menus,
                                priceNames: this.props.priceNames,
                                families: this.props.families,
                                subFamilies: this.props.subFamilies,
                                updateFamilyId: (value) => { this.props.updateFamilyId(value) },
                                updateSubFamilyId: (value) => { this.props.updateSubFamilyId(value) },
                                familyId: this.props.familyId,
                                subFamilyId: this.props.subFamilyId,
                                updateFamiliesIds: (key, value) => { this.props.updateFamiliesIds(key, value) },
                                updateSubFamiliesIds: (key, value) => { this.props.updateSubFamiliesIds(key, value) },
                                familiesGrouped: this.props.familiesGrouped,
                                subFamiliesGrouped: this.props.subFamiliesGrouped,
                                keyAction: this.props.keyAction
                            } as PricePeriodStoreItemsOwnProps} />
                    </div>
                </div>
                <div style={{marginTop: '3%'}}>
                    <button className="btn btn-primary"
                        style={{width: '100%'}}
                        type={"submit"}
                        disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                        {this.props.actionName}
                    </button>
                </div>
                <PeriodDialog />
            </FormField.BaseForm>
        );
    }
}

const afterCreateSubmit = (result, dispatch) =>
  dispatch(ReduxForm.reset('createPricePeriod'));

let createForm = ReduxForm.reduxForm({
    form: 'createPricePeriod',
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmitSuccess: afterCreateSubmit
})(PricePeriodForm as any) as any;
const createSelector = ReduxForm.formValueSelector('createPricePeriod');
export const CreatePricePeriodForm = connect((state: ApplicationState) => {
    return {
        initialValues: {
        } as Api.PricePeriodModel,
        periods: state.period.periods,
        priceNames: state.seed.seed.priceNames,
        menus: state.seed.seed.menus,
        products: state.seed.seed.products,
        priceNameId: createSelector(state, "priceNameId"),
        pricePeriodStoreItems: createSelector(state, "pricePeriodStoreItems") || [],
        stockStatus: state.stock.stockStatus,
        families: state.seed.seed.families,
        subFamilies: state.seed.seed.subFamilies,
        disabled: !canUpdate(state)
    } as PricePeriodFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<PricePeriodFormExternalProps>;

let updateForm = ReduxForm.reduxForm({
    form: 'updatePricePeriod',
    enableReinitialize: true,
    destroyOnUnmount: true
})(PricePeriodForm as any) as any;
const updateSelector = ReduxForm.formValueSelector('updatePricePeriod');
export const UpdatePricePeriodForm = connect((state: ApplicationState) => {
    return {
        initialValues: state.pricePeriod.selectedPricePeriodId
            ? state.pricePeriod.pricePeriods[state.pricePeriod.selectedPricePeriodId]
            : {},
        periods: state.period.periods,
        priceNames: state.seed.seed.priceNames,
        menus: state.seed.seed.menus,
        products: state.seed.seed.products,
        priceNameId: updateSelector(state, "priceNameId"),
        pricePeriodStoreItems: updateSelector(state, "pricePeriodStoreItems") || [],
        families: state.seed.seed.families,
        subFamilies: state.seed.seed.subFamilies,
        stockStatus: state.stock.stockStatus,
        disabled: !canUpdate(state)
    } as PricePeriodFormOwnProps
}, null)(updateForm as any) as any as React.ComponentClass<PricePeriodFormExternalProps>;