import * as Account from "./Account";
import * as Seed from "./Seed";
import * as Home from "./Home";
import * as Stock from "./Stock";
import * as Reception from "./Reception";
import * as Maintenance from "./Maintenance";
import * as Planogram from "./Planogram";
import * as Image from "./Image";
import * as NeoEvent from "./NeoEvent";
import * as Tables from "./Tables";
import * as Voucher from "./Voucher";
import * as HmiApplication from "./HmiApplication";
import * as Offer from "./Offer";
import * as Settings from "./Settings";
import * as Tabs from "./Tabs";
import * as Robot from "./Robot";
import * as Cash from "./Cash";
import * as Card from "./Card";
import * as Print from "./Print";
import * as History from "./History";
import * as ProductsDetails from "./ProductsDetails";
import * as Reorder from "./Reorder";
import * as MngHub from "./MngHub";
import * as HmiMoney from "./HmiMoney";
import * as Period from "./Period";
import * as PricePeriod from "./PricePeriod";
import * as Import from "./Import";
import * as Contact from "./Contact";
import * as NeoEventAlarm from "./NeoEventAlarm";
import * as Temp from "./Temp";
import * as Menu from "./Menu";
import * as Help from "./Help";
import * as SupplierOrder from "./SupplierOrder";
import * as Interventions from "./Interventions";
import * as TextTranslationHmi from "./TextTranslationHmi";
import * as SalesTariff from "./SalesTariff";
import * as PurchaseTariff from "./PurchaseTariff";
import * as MenuCategories from "./MenuCategories";
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as reduxForm } from 'redux-form';

// The top-level state object
export interface ApplicationState {
    account: Account.AccountState;
    seed: Seed.SeedState;
    home: Home.HomeState;
    neoEvent: NeoEvent.NeoEventState;
    stock: Stock.StockState;
    reception: Reception.ReceptionState;
    maintenance: Maintenance.MaintenanceState;
    planogram: Planogram.PlanogramState;
    offer: Offer.OfferState;
    image: Image.ImageState;
    voucher: Voucher.VoucherState;
    tables: Tables.TablesState;
    hmiApplication: HmiApplication.HmiApplicationState;
    robot: Robot.RobotState;
    cash: Cash.CashState;
    card: Card.CardState;
    print: Print.PrintState;
    tabs: Tabs.TabsState;
    history: History.HistoryState;
    settings: Settings.SettingsState;
    reorder: Reorder.ReorderState;
    productsDetails: ProductsDetails.ProductsDetailsState;
    mngHub: MngHub.MngHubState;
    hmiMoney: HmiMoney.HmiMoneyState;
    period: Period.PeriodState;
    pricePeriod: PricePeriod.PricePeriodState;
    import: Import.ImportState;
    contact: Contact.ContactState;
    neoEventAlarm: NeoEventAlarm.NeoEventAlarmState;
    temp: Temp.TempState;
    help: Help.HelpState;
    menu: Menu.MenuState;
    supplierOrder: SupplierOrder.SupplierOrderState;
    form: any;
    notifications: any;
    interventions: Interventions.InterventionsState;
    textTranslationHmi : TextTranslationHmi.TextTranslationHmiState;
    salesTariff: SalesTariff.SalesTariffState;
    purchaseTariff: PurchaseTariff.PurchaseTariffState;
    menuCategories: MenuCategories.MenuCategoriesState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    account: Account.reducer,
    seed: Seed.reducer,
    home: Home.reducer,
    neoEvent: NeoEvent.reducer,
    stock: Stock.reducer,
    reception: Reception.reducer,
    maintenance: Maintenance.reducer,
    planogram: Planogram.reducer,
    offer: Offer.reducer,
    image: Image.reducer,
    voucher: Voucher.reducer,
    tables: Tables.reducer,
    hmiApplication: HmiApplication.reducer,
    robot: Robot.reducer,
    cash: Cash.reducer,
    card: Card.reducer,
    print: Print.reducer,
    tabs: Tabs.reducer,
    history: History.reducer,
    settings: Settings.reducer,
    reorder: Reorder.reducer,
    productsDetails: ProductsDetails.reducer,
    mngHub: MngHub.reducer,
    hmiMoney: HmiMoney.reducer,
    period: Period.reducer,
    pricePeriod: PricePeriod.reducer,
    import: Import.reducer,
    contact: Contact.reducer,
    neoEventAlarm: NeoEventAlarm.reducer,
    temp: Temp.reducer,
    help: Help.reducer,
    supplierOrder: SupplierOrder.reducer,
    menu: Menu.reducer,
    form: reduxForm,
    notifications: notifications,
    interventions:  Interventions.reducer,
    textTranslationHmi: TextTranslationHmi.reducer,
    salesTariff: SalesTariff.reducer,
    purchaseTariff: PurchaseTariff.reducer,
    menuCategories: MenuCategories.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction, TResult> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): TResult;
}