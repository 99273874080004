import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';

interface SupportOwnProps {
 }

 export default class Support extends React.Component<SupportOwnProps, {}> {
   
    public render() {
        return (
            <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div 
                className="card"
                style={{
                    ...Styles.cardStyle,  
                    width: '40%',
                    padding: '8%',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                }}>
                    <div style={{fontSize: 30, paddingBottom: '10%'}}>
                        {getText( "SupportTitle")}
                    </div>
                    <button className="btn btn-primary"
                        style={{width: '100%'}}
                        type={"button"}
                        onClick={() => window.open("https://support-ximiti.freshdesk.com/", '_blank')}>{getText( "GenLogin")}</button>
                </div>
            </div>
        );
    }
}