import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as HmiApplicationStore from "../store/HmiApplication";
import * as AccountStore from "../store/Account";
import * as ImportStore from "../store/Import";
import HmiStatus from "./HmiStatus";
import Peripherals from "./Peripherals";
import { isAuthenticated } from '../security/UserIsAuthenticated';
import { getText } from '../utils/texts';
import * as HomeStore from "../store/Home";
import * as _ from "lodash";
import * as HistoryStore from "../store/History";
import * as CashStore from "../store/Cash";
import * as CardStore from "../store/Card";
import * as PrintStore from "../store/Print";
import * as RobotStore from "../store/Robot";
import * as TempStore from "../store/Temp";
import UserHub from './UserHub';
import {master, visual, maint, admin} from '../security/roles'

type HeaderProps = HeaderOwnProps
    & HmiApplicationStore.HmiApplicationState
    & HomeStore.HomeState
    & typeof HomeStore.actionCreators
    & typeof AccountStore.actionCreators
    & typeof HmiApplicationStore.actionCreators
    & typeof ImportStore.actionCreators
    & typeof HistoryStore.actionCreators
    & typeof CashStore.actionCreators
    & typeof CardStore.actionCreators
    & typeof PrintStore.actionCreators
    & typeof RobotStore.actionCreators
    & typeof TempStore.actionCreators
    ;

interface HeaderOwnProps {
    storeAppSettings: Api.StoreAppSettingsModel;
    currentUser: Api.CurrentUserManagerModel;
    hasToken: boolean;
    hasTokenLoading: boolean;
    isAuthenticated: boolean;
    version: string;
    dayStoreData: Api.DayStoreDataModel;
    storeType: string;
}
const user = require('../assets/images/user.png') as any;
const userDarkMode = require('../assets/images/user-dm.png') as any;
class Header extends React.Component<HeaderProps, {}> {
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get totalTurnover(): number {
        return this.props.dayStoreData
            ? _.sum(this.props.dayStoreData.storeOrders.map(x => _.sum(x.storeOrderPayments.filter(y => y.status !== "Failed").map(y => y.collection || 0))))
            : 0;
    }

    get rolName(): string {
        if(_.values(this.props.currentUser.userManager.storeAccount.roles).some(x => x === maint))
            return maint
        if(_.values(this.props.currentUser.userManager.storeAccount.roles).some(x => x === master))
            return master
        if(_.values(this.props.currentUser.userManager.storeAccount.roles).some(x => x === admin))
            return admin
        if(_.values(this.props.currentUser.userManager.storeAccount.roles).some(x => x === visual))
            return visual
        return this.props.currentUser.userManager.storeAccount.roles[0]
    }

    public render() {
        return (            
            <div
            className="card"
            style={{
                ...Styles.cardStyle,
                display: 'flex',
                margin: '1%',
                padding: '0.5rem',
                alignItems: "center",
                justifyContent: 'space-between'
            }}>
                <div style={{fontSize: 20, marginLeft: '1%'}}>
                    <div>{this.props.storeType == "Ximiti" && getText( "HeaderTitleStore")} {this.props.storeAppSettings.storeName}</div>
                    <div>{getText( "HeaderTitle")}</div>
                </div>
                <div style={{maxHeight: 30}}>
                    <Peripherals />
                </div>
                {
                    this.props.isAuthenticated && this.props.storeType == "Ximiti" && <div style={{ fontSize: 20, fontFamily: 'sans-serif', minWidth: 'fit-content', margin: '0px 5px'}}>
                        <div>{getText( "HeaderCash")} {this.totalTurnover.toFixed(2)} {"\u20AC"} </div>
                    </div>
                }
                <div style={{
                    flex: "0 0 10%", display: "flex", flexDirection: "row",
                    alignItems: "center", padding: "10px 0px"
                }}>
                    {this.props.hmis.map(hmi => <div key={hmi.hmiId} style={{
                        margin: "auto"
                    }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 3 }}>
                            <HmiStatus status={this.props.hmiStates[hmi.hmiId]
                                ? this.props.hmiStates[hmi.hmiId].applicationState
                                : null}
                                lastUpdate={this.props.hmiStates[hmi.hmiId]
                                    ? this.props.hmiStates[hmi.hmiId].lastUpdate
                                    : 0}
                               lastRequest={this.props.hmiStates[hmi.hmiId]
                                       ? this.props.hmiStates[hmi.hmiId].lastRequest
                                       : 0}
                            onRequestUpdate={(rt) => this.props.requestRequestHmiState(rt, hmi.hmiId)} />
                        </div>
                    </div>)}
                </div>
                <div style={{
                    flex: "0 0 auto",
                    marginRight: '1%'
                }}>
                    {this.props.isAuthenticated &&
                        <div style={{display: 'flex'}}>
                            <div style={{marginRight: '8px' }}>
                                <div className="white-font-dm" style={{fontSize: 16}}>{this.props.currentUser.userManager.name}</div>
                                {
                                    this.props.currentUser.userManager.storeAccount
                                    && this.props.currentUser.userManager.storeAccount.roles
                                    && this.props.currentUser.userManager.storeAccount.roles.length !== 0 && 
                                    <div style={{ alignItems: "center", textAlign: 'end', fontSize: '13px' }}>
                                        {this.rolName}
                                       
                                    </div>
                                }
                                <div className="hover-blue" style={{ textAlign: 'end', cursor: "pointer", fontSize: '13px' }}>
                                    {this.props.isAuthenticated
                                        && <div onClick={(e) => {
                                            this.props.requestLogout(new Date().getTime());
                                            e.preventDefault();
                                        }}>{getText( "HeaderLogOut")}</div>}
                                </div>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div>
                                    <img alt="Logo" width="55" height="60" style={{backgroundColor: 'transparent', padding: '1%'}} src={this.isDarkMode() ? userDarkMode : user}/>
                                </div>
                                { this.props.currentUser&& this.props.currentUser.userManager.storeAccount.roles.some(x => x === maint)
                                    && <div> <UserHub/> </div>}
                            </div>                            
                        </div>}
                    {!this.props.isAuthenticated
                        && <NavLink style={{ textDecoration: "underline" }} to={"/login"}>{getText( "HeaderLogIn")}</NavLink>}
                    {/* {this.props.currentUser.userManager.storeAccount.roles.map(r => <div
                        key={r}
                        style={{ padding: "3px 4px", backgroundColor: "#5C5C5D", color: "white", borderRadius: 4, fontSize: 12 }}>
                        {r}
                    </div>)} */}
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.home,
    ...state.hmiApplication,
    version: state.home.version,
    storeAppSettings: state.seed.seed.storeAppSettings,
    currentUser: state.account.currentUser,
    hasToken: state.import.storeTokenState.hasToken,
    hasTokenLoading: state.import.storeTokenState.isLoading,
    isAuthenticated: isAuthenticated(state),
    dayStoreData: state.history.todayData.dayStoreData,
    storeType: state.seed.seed.storeAppSettings.typeStore
} as HeaderOwnProps), {
    ...HomeStore.actionCreators,
    ...AccountStore.actionCreators,
    ...HmiApplicationStore.actionCreators,
    ...ImportStore.actionCreators,
    ...HistoryStore.actionCreators,
    ...CashStore.actionCreators,
    ...CardStore.actionCreators,
    ...PrintStore.actionCreators,
    ...RobotStore.actionCreators,
    ...TempStore.actionCreators,
})(Header as any) as any as React.ComponentClass<{}>;