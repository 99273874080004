import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { calculateProductHeight } from '../utils/utils';
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type CreateShelvingTrayPlacementFormProps = ReduxForm.InjectedFormProps<Api.ShelvingTrayPlacementModel, {}, {}>
    & CreateShelvingTrayPlacementFormOwnProps
    & CreateShelvingTrayPlacementFormExternalProps;

interface CreateShelvingTrayPlacementFormExternalProps {
    onSubmit: (values: Api.ShelvingTrayPlacementModel) => any;
}

interface CreateShelvingTrayPlacementFormOwnProps {
    floors: any;
    disabled: boolean;
    shelvingTrayPlacements: Array<Api.ShelvingTrayPlacementModel>;
    productRails: Array<Api.ProductRailModel>;
    products: { [key: number]: Api.ProductModel | Api.ExternalProductModel };
    shelvingTrays: { [key: string]: Api.ShelvingTrayModel };
    shelvings: { [key: string]: Api.ShelvingModel };
    shelvingId: number;
    storeType: string;
}

const fieldStyle: React.CSSProperties = {
    flexDirection: "row",
    columnGap: 5,
    width: "100%",
    textAlign: "left"
};
const labelStyle: React.CSSProperties = {
    minWidth: 120,
    textAlign: "left"
};

class CreateShelvingTrayPlacementForm extends React.Component<CreateShelvingTrayPlacementFormProps, {}> {
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getProduct(y : Api.ProductRailModel) {
        return this.props.storeType == "Ximiti" ? this.props.products[y.productId] : this.props.products[y.externalProductId]
    }
    
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="borderSelectors" style={{ maxWidth: 600, minWidth: 360 }}>
                <div style={{ flexDirection: "column", alignItems: "stretch", rowGap: 10 }}>
                    <div style={{ ...fieldStyle }}>
                        <label style={{ ...labelStyle }} className="control-label">{getText( "PlanogramModalRayon")} : </label>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field
                                name="shelvingId"
                                validate={[FormField.required]}
                                component={FormField.getSelectField(
                                    _.values(this.props.shelvings).map(x => ({
                                        label: x.name,
                                        value: x.shelvingId
                                    })))} />
                        </div>
                    </div>
                    <div style={{ ...fieldStyle }}>
                        <label style={{ ...labelStyle }} className="control-label">{getText( "PlanogramModalFloor")} : </label>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field
                                name="shelvingTrayId"
                                validate={[FormField.required]}
                                component={FormField.getSelectField(
                                    _.values(this.props.shelvingTrays)
                                    .filter(x => !this.props.floors.some(y => y.shelvingTrayId === x.shelvingTrayId))
                                    .map(x => ({
                                        label: x.name,
                                        value: x.shelvingTrayId
                                    })))} />
                        </div>
                    </div>
                    <div style={{ ...fieldStyle }}>
                        <label style={{ ...labelStyle }} className="control-label">{getText( "PlanogramModalLevel")} : ( {this.props.productRails.length} ) </label>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field
                                name="level"
                                validate={[FormField.required]}
                                component={FormField.getSelectField(
                                    Array.from(Array(35).keys())
                                        .map(x => x + 1)
                                        .filter(x => !this.props.shelvingTrayPlacements.some(y => y.level === x)
                                            && !this.props.productRails
                                                .some(y => y.a <= x
                                                    && x < y.a + Math.ceil(calculateProductHeight(this.getProduct(y), y.sens) / this.props.shelvings[this.props.shelvingId].railHeight)))
                                        .map(x => ({
                                            label: `${x}`,
                                            value: x
                                        })))} />
                        </div>
                    </div>
                    <div style={{ }}>
                        <button className="btn btn-primary"
                            style={{ 
                                width: "100%", 
                                height: 40, 
                                marginTop: 10, 
                                marginLeft: 0,
                                fontSize: 14,
                                backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF"
                            }}
                            type={"submit"}
                            disabled={this.props.submitting || this.props.disabled}>
                            {getText( "PlanogramCreate")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createCreateShelvingTrayPlacement',
    enableReinitialize: true,
    destroyOnUnmount: true
})(CreateShelvingTrayPlacementForm as any) as any;
const createSelector = ReduxForm.formValueSelector('createCreateShelvingTrayPlacement');
export default connect((state: ApplicationState) => {
    let shelvingId = createSelector(state, "shelvingId");
    let floorsAux = state.stock.stockStatus.shelvingTrayPlacements.filter(x => state.stock.stockStatus.shelvingTrayPlacements.some(z => z.shelvingTrayId === x.shelvingTrayId  && x.planogramId === state.planogram.selectedPlanogramId));
    let sTrays = _.values(state.stock.stockStatus.shelvingTrays).find(x => !floorsAux.some(y => y.shelvingTrayId === x.shelvingTrayId));

    return {
        initialValues: {
            level: 1,
            planogramId: state.planogram.selectedPlanogramId,
            shelvingId: state.planogram.shelvingId,
            shelvingTrayId: sTrays && sTrays.shelvingTrayId 
        } as Api.ShelvingTrayPlacementModel,
        shelvingId: shelvingId,
        disabled: !canUpdate(state),
        shelvingTrays: state.stock.stockStatus.shelvingTrays,
        productRails: state.stock.stockStatus.productRails
            .filter(x => x.planogramId === state.planogram.selectedPlanogramId
                && state.stock.stockStatus.shelvingTrayPlacements.some(y => y.shelvingTrayId === x.shelvingTrayId && y.shelvingId === shelvingId)),
        products: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? state.seed.seed.products : state.seed.seed.externalProducts,
        storeType: state.seed.seed.storeAppSettings.typeStore,
        shelvings: state.stock.stockStatus.shelvings,
        shelvingTrayPlacements: state.stock.stockStatus.shelvingTrayPlacements
            .filter(x => x.planogramId === state.planogram.selectedPlanogramId
                && x.shelvingId === shelvingId),
        floors: floorsAux
    } as CreateShelvingTrayPlacementFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<CreateShelvingTrayPlacementFormExternalProps>;