import { ApplicationState } from "../store";
import { isAuthenticated } from "./UserIsAuthenticated";

export const master = "Master";
export const visual = "Visual";
export const maint = "Maintenance";
export const admin = "Admin";

export const canSeeData = (state: ApplicationState) => {
    return isAuthenticated(state)
        && state.account.currentUser.userManager
            .storeAccount.roles.some(x => x === master
                || x === visual
                || x === maint
                || x === admin);
}

export const canUpdate = (state: ApplicationState) => {
    return isAuthenticated(state)
        && state.account.currentUser.userManager
            .storeAccount.roles.some(x => x === master
                || x === maint                
                || x === admin);
}

export const canEditSettings = (state: ApplicationState) => {
    return isAuthenticated(state)
        && state.account.currentUser.userManager
            .storeAccount.roles.some(x => x === maint);
}