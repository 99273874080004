import * as Api from '../api/api';
import * as React from 'react';
import Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import HelpHeader from "./HelpHeader";
import DatePicker from "./DatePicker";
import CheckBox from "./CheckBox";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import { isNumber } from 'lodash';
import { filterWithoutAccents, NeoEventSource } from '../utils/utils';
import * as _ from 'lodash';
import { CsvData, generateCsvFromTable } from '../utils/csv';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type EventProps = EventOwnProps
    & HistoryStore.HistoryState
    & typeof HistoryStore.actionCreators;

interface EventOwnProps {
    hoppers: Api.HmiMoneyHopperModel;
    seed: Api.SeedMngModel;
    groupedStoreDatas: Array<HistoryStore.GroupedStoreData>;
    productSellDatas: Array<HistoryStore.ProductSellData>;
    productStockDatas: Array<HistoryStore.ProductStockData>;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
}

export const timeGroupByOptions: Array<{ label: string, value: HistoryStore.TimeGroupBy }> = [
    { label: getText("HistoDays"), value: "Day" },
    { label: getText("HistoWeeks"), value: "Week" },
    { label: getText("HistoMonths"), value: "Month" },
    { label: getText("HistoYears"), value: "Year" },
]
type NeoEventColumn = ReactTable.Column
    & {
        accessor: (d: Api.StoreNeoEventModel) => any;
        style?: React.CSSProperties;
        columns?: Array<NeoEventColumn>
    };

const rowStyle : React.CSSProperties = {display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1%'};
const columStyle : React.CSSProperties = {display: 'block', alignItems: 'center'};
const checkboxStyle: React.CSSProperties = {width: 16, height: 16, fontSize: 8};
const containerCheckboxStyle: React.CSSProperties = {minHeight: 24, maxHeight: 24, paddingBottom: 0};
const columButtonsFilterStyle : React.CSSProperties = {display: 'inline-flex', alignItems: 'center'};

class Event extends React.Component<EventProps, {}> {

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }  

    cleanStoreNeoEventsFilterCodes() {
        /* Clean all the filters */
    }    

    updateCodes(values: string) {
        let valuesSplit = values.split(';');
        let codesFilter = []
        valuesSplit.forEach(element => {
            if(isNumber(Number.parseInt(element))) {
                if(!isNaN(Number.parseInt(element))) {
                    codesFilter.push(Number.parseInt(element)); 
                }
            }
        });
        this.props.updateCodes(codesFilter);
    }

    getDataFiltered(): Array<Api.DayStoreDataModel> {
        var filteredHistoryData = [];
        if(this.selectedDayStoreData && this.selectedDayStoreData.storeNeoEvents) {
            if(filteredHistoryData){           
                if(this.props.eventsFilter.minorEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error"
                    ))
                }
                if(this.props.eventsFilter.majorEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError"
                    ))
                }
                if(this.props.eventsFilter.generalEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal"
                    ))
                }
                // Minor Events
                if(!this.props.eventsFilter.minorEvents && this.props.eventsFilter.minorInformatique) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Computer"
                    ))
                }
                if(!this.props.eventsFilter.minorEvents && this.props.eventsFilter.minorRobot) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Robot"
                    ))
                }
                if(!this.props.eventsFilter.minorEvents && this.props.eventsFilter.minorImprimante) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Printer"
                    ))
                }
                if(!this.props.eventsFilter.minorEvents && this.props.eventsFilter.minorTPN) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Cash"
                    ))
                }
                if(!this.props.eventsFilter.minorEvents && this.props.eventsFilter.minorTPA) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Error" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Card"
                    ))
                }
                // Major Events
                if(!this.props.eventsFilter.majorEvents && this.props.eventsFilter.majorInformatique) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Computer"
                    ))
                }
                if(!this.props.eventsFilter.majorEvents && this.props.eventsFilter.majorRobot) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Robot"
                    ))
                }
                if(!this.props.eventsFilter.majorEvents && this.props.eventsFilter.majorMonetique) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "FatalError" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Cash" ||
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Card"
                    ))
                }
                // General Events
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.statusEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Status"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.robotEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Robot"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.actionClientEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Client"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.phoneEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "SmartPhone"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.TPNEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Cash"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.TPAEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Card"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.computerEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].source === "Computer"
                    ))
                }
                if(!this.props.eventsFilter.generalEvents && this.props.eventsFilter.exploitantEvents) {
                    filteredHistoryData = filteredHistoryData.concat(this.selectedDayStoreData.storeNeoEvents.filter(
                        x => this.props.neoEventCodes[x.neoEventCodeId].type === "Normal" &&
                        this.props.neoEventCodes[x.neoEventCodeId].source === "Management"
                    ))
                }
            }
            if(this.props.codes && this.props.codes.length > 0) {
                filteredHistoryData = filteredHistoryData.filter(x => this.props.codes.find(element => element === this.props.neoEventCodes[x.neoEventCodeId].code));
            }

            if(this.props.eventsFilter.searchText != "") {
                filteredHistoryData = filteredHistoryData.filter(
                    x => filterWithoutAccents(x.message).toLowerCase()
                    .includes(filterWithoutAccents(this.props.eventsFilter.searchText).toLowerCase())
                )
            }
        }
        return _.sortBy(filteredHistoryData,x => -new Date(x.eventDate).getTime());
    }

    componentDidMount() {
        this.props.updateCodes([]);
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get selectedDayStoreData(): Api.DayStoreDataModel {
        return this.props.historyData.dayStoreDatas[this.props.historyData.selectedIndex];
    }

    getCodeLabel(type: string) {
        if(type===NeoEventSource[0]) {
            return getText("HistoState");
        }        
        if(type===NeoEventSource[1]) {
            return getText("HistoExploitant");
        }        
        if(type===NeoEventSource[2]) {
            return getText("HistoClient");
        }
        if(type===NeoEventSource[3]) {
            return getText("HistoRobot");
        }
        if(type===NeoEventSource[4]) {
            return getText("HistoOrdinateur");
        }
        if(type===NeoEventSource[5]) {
            return getText("HistoTPN");
        }
        if(type===NeoEventSource[6]) {
            return getText("HistoTPA");
        }
        if(type===NeoEventSource[7]) {
            return getText("HistoPrinter");
        }
        if(type===NeoEventSource[8]) {
            return getText("HistoSmart");
        }
        return getText("HistoCode");
    }

    getEventLabelColor(x: Api.StoreNeoEventModel) {
        let neoEvent = this.props.neoEventCodes[x.neoEventCodeId]
        let color = this.isDarkMode() ? 'white' : '#3B4370';
        let isKO = x.message.toUpperCase().includes("KO");

        switch (neoEvent.code) {
            case 828:
                color = neoEvent.type == "FatalError" ? 'red' : 'orange';
                break;
            case 831:
                color = neoEvent.type == "FatalError" ? 'red' : 'orange';
                break;
            case 501:
                color = isKO ? 'red' : color;
                break;
            case 502:
                color = isKO ? 'red' : color;
                break;       
            case 601:
                color = isKO ? 'red' : color;
                break;
            case 606:
                color = isKO ? 'red' : color;
                break;
            default:
                break;
        }
        if(neoEvent.code >= 900 && neoEvent.code <= 999) {
            color = 'red';
        }   
        return color;
    }

    csvData(): CsvData {
        if(!document.getElementById("events-container"))
            return {
                data: [],
                headers: []
            };
        
        return generateCsvFromTable(document.getElementById("events-container"));
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF();
        autoTable(pdf, {body: data});
        pdf.save(`${getText( "HistoEvents")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }
    public render() {
        return (
            <div>
                <div className="card" style={{ ...Styles.cardStyle,  ...Styles.pageTitle, height:"100%"}}>
                    <span style={{marginLeft: 30}}>
                        {getText( "HistoEvents")}
                    </span>
                    <HelpHeader />
                </div>
                <div className="card" style={{ ...Styles.cardStyle, margin: "1% 1% 0"}}>
                    <div style={rowStyle} className="selectors borderSelectors">
                        <div style={columStyle}>
                            <div style={Styles.filterLabelStyle}>{getText( "HistoSince")}</div>
                            <DatePicker value={this.props.getHistoryModel.fromDate}
                                dateFormat={'dd DD/MM/YYYY'}
                                onChange={(value) => this.props.updateHistoryFromDate(value)} />
                        </div>
                        <div style={columStyle}>
                            <div style={Styles.filterLabelStyle}>{getText( "HistoUntil")}</div>
                            <DatePicker value={this.props.getHistoryModel.toDate}
                                dateFormat={'dd DD/MM/YYYY'}
                                onChange={(value) => this.props.updateHistoryToDate(value)} />
                        </div>
                        <div style={columStyle}>
                            <div style={Styles.filterLabelStyle}>{getText( "HistoCode")}</div>
                            <input style={{minWidth: 100}} className="form-control"
                                id="codes"
                                placeholder="001;013;143..."
                                type={"text"}
                                pattern={"^\d+(;\d+)*$"}
                                name={"codes"}
                                onChange={(e) => this.updateCodes(e.target.value)} />
                        </div>
                        <div style={columStyle}>
                            <div style={Styles.filterLabelStyle}>{getText( "HomeSearch")}</div>
                            <input style={{minWidth: 100}} className="form-control"
                                id="searcher"
                                type={"text"}
                                name={"searcher"}
                                value={this.props.eventsFilter.searchText}
                                onChange={(e) => this.props.updateEventsFilter({
                                    ...this.props.eventsFilter,
                                    searchText: e.target.value
                                })} />
                        </div>
                        {/* <button className="btn btn-primary"
                            style={{width: '100%', height: 40, fontSize: 11, fontWeight: 'bold', marginTop: '8%'}}
                            onClick={(e) => this.cleanStoreNeoEventsFilterCodes()}>
                            {getText( "HistoClearAll")}
                        </button> */}
                        <div style={columButtonsFilterStyle}>
                            <div style={{width: 150}}>
                                <button className="btn btn-primary"
                                    style={{width: '100%', height: 40}}
                                    onClick={(e) => this.props.requestDayStoreDatas(new Date().getTime())}>
                                    {getText( "HistoApply")}
                                </button>
                            </div>
                            <div style={{marginLeft: 5, width: 150}}>
                                <button type={"button"}
                                        className="btn btn-primary"
                                        style={{width: '100%', height: 40}}
                                onClick={(e) => {
                                    const options = {
                                        fieldSeparator: ';',
                                        quoteStrings: '"',
                                        decimalSeparator: ',',
                                        showLabels: false,
                                        showTitle: false,
                                        filename: `${getText("HistoEvents")}-${Moment().format('YYYYMMDDHHmm')}`,
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: false,
                                        headers: this.csvData().headers,
                                    };
                                    const csvExporter = new ExportToCsv(options);
                                    csvExporter.generateCsv(this.csvData().data); 
                                    this.pdfData(this.csvData().data);
                                }}>
                                    {getText( "HistoExport")}
                                    <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{...rowStyle, alignItems: 'initial', padding: '0 1%'}}>
                        <div>
                            <CheckBox label={getText( "HistoMinorEvents")}
                                value={this.props.eventsFilter.minorEvents}
                                style={checkboxStyle}
                                contentStyle={containerCheckboxStyle}
                                onChange={(value) => {
                                    this.props.updateEventsFilter({
                                        ...this.props.eventsFilter,
                                        minorEvents: value,
                                        minorInformatique: value,
                                        minorRobot: value,
                                        minorImprimante: value,
                                        minorTPN: value,
                                        minorTPA: value
                                    })
                                }}/>
                            <div style={{paddingLeft: 20, maxWidth:"100%"}}>
                                <CheckBox label={getText( "HistoComputer")}
                                    value={this.props.eventsFilter.minorInformatique || this.props.eventsFilter.minorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            minorInformatique: value,
                                        })
                                        if(value == false && this.props.eventsFilter.minorEvents == true) {                                            
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                minorEvents: value,
                                            })
                                        }
                                    }} />
                                <CheckBox label={getText( "HistoRobot")}
                                    value={this.props.eventsFilter.minorRobot || this.props.eventsFilter.minorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            minorRobot: value,
                                        })
                                        if(value == false && this.props.eventsFilter.minorEvents == true) {                                            
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                minorRobot: value,
                                            })
                                        }
                                    }} />
                                <CheckBox label={getText( "HistoPrinter")}
                                    value={this.props.eventsFilter.minorImprimante || this.props.eventsFilter.minorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            minorImprimante: value,
                                        })
                                        if(value == false && this.props.eventsFilter.minorEvents == true) {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                minorEvents: value,
                                            })
                                        }
                                    }} />
                                <CheckBox label={getText( "HistoTPN")}
                                    value={this.props.eventsFilter.minorTPN || this.props.eventsFilter.minorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            minorTPN: value,
                                        })
                                        if(value == false && this.props.eventsFilter.minorEvents == true) {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                minorEvents: value,
                                            })
                                        }
                                    }} />
                                <CheckBox label={getText( "HistoTPA")}
                                    value={this.props.eventsFilter.minorTPA || this.props.eventsFilter.minorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            minorTPA: value,
                                        })
                                        if(value == false && this.props.eventsFilter.minorEvents == true) {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                minorEvents: value,
                                            })
                                        }
                                    }} />
                            </div>
                        </div>
                        <div>
                            <CheckBox label={getText( "HistoMajorEvents")}
                                value={this.props.eventsFilter.majorEvents}
                                style={checkboxStyle}
                                contentStyle={containerCheckboxStyle}
                                onChange={(value) => {
                                    this.props.updateEventsFilter({
                                        ...this.props.eventsFilter,
                                        majorEvents: value,
                                        majorInformatique: value,
                                        majorRobot: value,
                                        majorMonetique: value
                                    })
                                }} />
                            <div style={{paddingLeft: 20, maxWidth:"100%"}}>
                                <CheckBox label={getText( "HistoComputer")}
                                    value={this.props.eventsFilter.majorInformatique || this.props.eventsFilter.majorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            majorInformatique: value
                                        })
                                        if(value == false && this.props.eventsFilter.majorEvents == true) {                                            
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                majorEvents: value
                                            })
                                        }
                                    }} />
                                <CheckBox label={getText( "HistoRobot")}
                                    value={this.props.eventsFilter.majorRobot || this.props.eventsFilter.majorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            majorRobot: value
                                        })
                                        if(value == false && this.props.eventsFilter.majorEvents == true) {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                majorEvents: value
                                            })
                                        }
                                    }} />
                                <CheckBox label={getText( "HistoMoney")}
                                    value={this.props.eventsFilter.majorMonetique || this.props.eventsFilter.majorEvents}
                                    style={checkboxStyle}
                                    contentStyle={containerCheckboxStyle}
                                    onChange={(value) => {
                                        this.props.updateEventsFilter({
                                            ...this.props.eventsFilter,
                                            majorMonetique: value
                                        })
                                        if(value == false && this.props.eventsFilter.majorEvents == true) {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                majorEvents: value
                                            })
                                        }
                                    }} />
                            </div>
                        </div>
                        <div>
                            <CheckBox label={getText( "HistoEvents")}
                                value={this.props.eventsFilter.generalEvents}
                                style={checkboxStyle}
                                contentStyle={containerCheckboxStyle}
                                onChange={(value) => {
                                    this.props.updateEventsFilter({
                                        ...this.props.eventsFilter,
                                        generalEvents: value,
                                        statusEvents: value,
                                        robotEvents: value,
                                        actionClientEvents: value,
                                        phoneEvents: value,
                                        TPNEvents: value,
                                        TPAEvents: value,
                                        exploitantEvents: value,
                                        computerEvents: value
                                    })
                                }} />
                                <div style={{paddingLeft: 20, maxWidth:"100%"}}>
                                    <CheckBox label={getText( "HistoState")}
                                    value={this.props.eventsFilter.statusEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                statusEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoRobot")}
                                        value={this.props.eventsFilter.robotEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                robotEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoActionClient")}
                                        value={this.props.eventsFilter.actionClientEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                actionClientEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoTPA")}
                                        value={this.props.eventsFilter.TPAEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                TPAEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoExploitant")}
                                        value={this.props.eventsFilter.exploitantEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                exploitantEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoPhone")}
                                        value={this.props.eventsFilter.phoneEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                phoneEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoTPN")}
                                        value={this.props.eventsFilter.TPNEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                TPNEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                                    <CheckBox label={getText( "HistoOrdinateur")}
                                        value={this.props.eventsFilter.computerEvents || this.props.eventsFilter.generalEvents}
                                        style={checkboxStyle}
                                        contentStyle={containerCheckboxStyle}
                                        onChange={(value) => {
                                            this.props.updateEventsFilter({
                                                ...this.props.eventsFilter,
                                                computerEvents: value
                                            })
                                            if(value == false && this.props.eventsFilter.generalEvents == true) {
                                                this.props.updateEventsFilter({
                                                    ...this.props.eventsFilter,
                                                    generalEvents: value
                                                })
                                            }
                                        }} />
                            </div>
                        </div>
                    </div>
                    <div id={"events-container"} style={rowStyle}>
                        <div style={{paddingLeft: '1%', overflow: 'auto', width: '20%', height: '65vh'}}>
                            {this.props.historyData.dayStoreDatas.map((dayStoreData, xi) => <div key={xi}
                                className="histo-date"
                                style={{
                                    color: xi === this.props.historyData.selectedIndex
                                        ? "blue"
                                        : "inherit",
                                }}
                                onClick={(e) => this.props.updateHistoryIndex(xi)}>
                                {Moment(dayStoreData.dayDate).locale(getCurrentLang()).format("dddd DD / MM / YYYY")}
                            </div>)}
                        </div>
                        <div style={{paddingLeft: '1%', width: '100%'}}>
                            <Table 
                                style={{margin: 0, height: '65vh'}}
                                key={"historyEvent"}
                                loading={this.props.historyData.isLoading}
                                pageSize={Math.max(this.getDataFiltered().length, 18)}
                                showPageSizeOptions={false}
                                showPagination={false}
                                showPaginationBottom={false}
                                columns={[
                                    {
                                        Header: <div>{getText( "HistoDate")}</div>,
                                        style: {
                                            height: '100%'
                                        },
                                        width: 150,
                                        id: 'eventDate',
                                        accessor: x => <div style={{
                                            padding: 5,
                                            color: this.getEventLabelColor(x)
                                        }}>{Moment(x.eventDate).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY HH:mm:ss")}</div>,
                                    },
                                    {
                                        Header: <div>{getText( "HistoCode")}</div>,
                                        style: {
                                            height: '100%'
                                        },
                                        width: 52,
                                        id: 'code',
                                        accessor: x => <div style={{
                                            padding: 5,
                                            color: this.getEventLabelColor(x)
                                        }}>{this.props.seed.neoEventCodes[x.neoEventCodeId].code}</div>,
                                    },
                                    {
                                        id: "source",                                        
                                        Header: <div>{getText( "HomeType")}</div>,
                                        style: {
                                            height: '100%'
                                        },
                                        width: 90,
                                        accessor: (x) => <div style={{
                                            padding: 5,
                                            color: this.getEventLabelColor(x)
                                        }}>{this.getCodeLabel(this.props.seed.neoEventCodes[x.neoEventCodeId].source)}</div>
                                    },
                                    {
                                        Header: <div>{getText( "HistoMessage")}</div>,
                                        id: 'message',
                                        accessor: x => <div style={{
                                            padding: 5, 
                                            whiteSpace: 'pre-wrap', 
                                            float: 'left',
                                            color: this.getEventLabelColor(x)
                                        }}>{x.message}</div>,
                                        style: {
                                            height: '100%',
                                            width: '100%'
                                        }
                                    },
                                ] as Array<NeoEventColumn>}
                                /*data={
                                    this.selectedDayStoreData && this.selectedDayStoreData.storeNeoEvents
                                    ? this.selectedDayStoreData.storeNeoEvents : []
                                }*/
                                data={
                                    this.getDataFiltered() ? this.getDataFiltered()
                                    : []
                                }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.history,
    seed: state.seed.seed,
    hoppers: state.cash.hoppers.hoppers,
    groupedStoreDatas: HistoryStore.groupedStoreDatas(state),
    productSellDatas: HistoryStore.productSellDatas(state),
    productStockDatas: HistoryStore.productStockDatas(state),
    neoEventCodes: state.seed.seed.neoEventCodes
} as EventOwnProps), {
    ...HistoryStore.actionCreators
})(Event as any);
