import * as React from 'react';
import * as RcTooltip from 'rc-tooltip';
import ReactTooltip from 'rc-tooltip';

interface TooltipProps {
    children: JSX.Element | string;
    trigger?: Array<RcTooltip.RCTooltip.Trigger>;
    placement?: RCTooltip.RCTooltip.Placement;
    visible?: boolean;
    overlay: any;
    hideDelay?: number;
    style?: any;
    containerStyle?: any;
    onVisibleChange?: (value: boolean) => void;
}

interface TooltipState {

}

let isServer = typeof window === 'undefined';
const defaultTigger = ["hover"] as Array<RcTooltip.RCTooltip.Trigger>;

export default class Tooltip extends React.Component<TooltipProps, TooltipState> {

    public render() {
        return (
            <ReactTooltip
                    {...(this.props.visible !== undefined
                        ? { visible: this.props.visible }
                        : {}) }
                    overlayStyle={this.props.style}
                    onVisibleChange={(value) => {
                        if (this.props.onVisibleChange)
                            this.props.onVisibleChange(value)
                    }}
                    trigger={this.props.trigger || defaultTigger}
                    placement={this.props.placement}
                    overlay={this.props.overlay}
                    mouseLeaveDelay={this.props.hideDelay || 0.1}>
                {this.props.children}
            </ReactTooltip>
        );
    }
}