import * as React from "react";
import { getText } from "../utils/texts";

type ImportCSVStockProps = ImportCSVStockOwnProps

interface ImportCSVStockOwnProps {
    uploadFile: (file: File) => void;
    isLoading: boolean;
    isDisabled: boolean;
}

export default class ImportCSVStock extends React.Component<ImportCSVStockProps, {}> {
    fileInput: HTMLInputElement;


    public render() {
        return (
            <span>
                <div style={{ textAlign: "center",  marginBottom: 25 }}>
                    <label>
                        <span style={{ fontSize: 20}}>
                            {getText("SettingsUploadCSVStockTitle")}
                        </span>
                        <input
                            disabled = {this.props.isDisabled}
                            type="file"
                            accept={".csv, application/vnd.ms-excel"}
                            style={{ order: 0, flex: "0 0 auto", fontSize: 15}}
                            ref={(input) => this.fileInput = input} 
                            />
                    </label>
                </div>
                <button className="btn btn-primary btn-lg"
                    disabled = {this.props.isLoading || this.props.isDisabled}
                    onClick={(e) => {
                            (this.props.uploadFile(this.fileInput.files.item(0)) as any as Promise<any>)
                            .then(() => {
                                this.fileInput.value = null;
                            });
                            e.preventDefault();
                            e.stopPropagation();}
                        }>
                        {getText("SettingsUploadCSVStockButton")}
                </button>
            </span>
        );
    }
}