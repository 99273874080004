import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as ReceptionStore from "../store/Reception";
import * as ReactTable from "react-table";
import Table from "./Table";
import * as StockStore from "../store/Stock";
import * as Styles from '../styles/baseStyle';
import Select from "./Select";
import InputNumber from "./InputNumber";
import * as SupplierOrderStore from '../store/SupplierOrder';
import DialogModal from './DialogModal';
import { productSuppliersSelector } from '../store/Help';
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import * as ReorderStore from "../store/Reorder";

type ReceptionProps = ReceptionOwnProps
    & ReceptionStore.ReceptionState
    & SupplierOrderStore.SupplierOrderState
    & typeof SupplierOrderStore.actionCreators
    & typeof StockStore.actionCreators
    & typeof ReceptionStore.actionCreators
    & typeof ReorderStore.actionCreators;

type ReceptionColumn = ReactTable.Column
    & {
        accessor: (d: Api.ProductRailModel) => any;
        columns?: Array<ReceptionColumn>
    }

type SupplierOrderProductSupplierColumn = ReactTable.Column
    & { accessor: (d: Api.SupplierOrderProductSupplierModel) => any; };

interface ReceptionOwnProps {
    seed: Api.SeedMngModel;
    suppliers: { [id: number]: Api.SupplierModel };
    products: { [id: number]: Api.ProductModel | Api.ExternalProductModel};
    internalProducts: { [id: number]: Api.ProductModel};
    externalProducts: { [id: number]: Api.ExternalProductModel }
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
    currentUser: Api.CurrentUserManagerModel;
    stock: StockStore.StockState;
    supplierOrder: SupplierOrderStore.SupplierOrderState;
    supplierOrders: Array<Api.SupplierOrderModel>;
    reorderDatas: Array<ReorderStore.ReorderData>;
    storeType: string;
    units: { [id: number]: Api.UnitModel };
}

interface SupplierOrderToRecieve {
    productId: number;
    qtyToOrder: number;
    productSupplierId: number;
    unitEdit: boolean;
}

interface ReceptionState {
    stateSuppOrdersToRecieve: SupplierOrderToRecieve[];
    stateProdRecepUnits: Api.CreateProductReceptionModel[];
    stateProdRecepCdt: Api.CreateProductReceptionModel[];
    filterInNameTab: string;
    filterInSourceTab: string;
    filterInCodeTab: string;
}

const columnXimitiStyle: React.CSSProperties = { backgroundColor: 'rgba(245, 243, 146, 0.4)', padding: 7 }
const columnRexelStyle: React.CSSProperties = { backgroundColor: 'rgba(167, 212, 252, 0.4)', padding: 7 }
const headerColumns: React.CSSProperties = { overflow: 'visible' , whiteSpace: 'normal' }

class Reception extends React.Component<ReceptionProps, ReceptionState> {
    //This thing will have to go, keep the pattern
    supplierOrdersToRecieve: SupplierOrderToRecieve[] = [];
    productReceptionsUnits: Api.CreateProductReceptionModel[] = [];
    productReceptionsCdt: Api.CreateProductReceptionModel[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            stateSuppOrdersToRecieve: [],
            stateProdRecepUnits: [],
            stateProdRecepCdt: [],
            filterInNameTab: '',
            filterInSourceTab: '',
            filterInCodeTab: ''
        };
    }

    setStateSuppOrder(newValue: SupplierOrderToRecieve[]) {
        this.setState({
            ...this.state,         
            stateSuppOrdersToRecieve: newValue
        });
    }

    setStateUnits(newValue: Api.CreateProductReceptionModel[]) {
        this.setState({
            ...this.state,         
            stateProdRecepUnits: newValue
        });
    }

    setStateCdt(newValue: Api.CreateProductReceptionModel[]) {
        this.setState({
            ...this.state,         
            stateProdRecepCdt: newValue
        });
    }
    

    componentDidMount() {
        this.props.requestSupplierOrders(new Date().getTime());
        this.props.requestReorderData(new Date().getTime());
    }

    componentWillUnmount() {
        this.props.updateSupplierOrderSelectedId(undefined);
        this.supplierOrdersToRecieve = [];
        this.productReceptionsUnits = [];
        this.productReceptionsCdt = [];
        this.setStateSuppOrder(this.supplierOrdersToRecieve);
        this.setStateUnits(this.productReceptionsUnits);
        this.setStateCdt(this.productReceptionsCdt);
    }

    get activePlanogram(): Api.PlanogramModel {
        return _.sortBy(this.props.stock.stockStatus.planograms, x => !x.active)[0];
    }

    get productRails(): Api.ProductRailModel[] {
        if (!this.props.stock.stockStatus)
            return [];

        let planogramId = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0].planogramId;

        let resArr: Api.ProductRailModel[] = [];
        if(this.props.storeType == "Ximiti"){
        this.props.stock.stockStatus.productRails.filter(x => x.planogramId === planogramId)
            .filter(function(item){
                var i = resArr.findIndex(x => x.productId == item.productId);
                if(i <= -1){
                    resArr.push(item);
                }
            });
            return resArr.sort((a, b) => this.props.products[a.productId].name.localeCompare(this.props.products[b.productId].name));
        }else{
            this.props.stock.stockStatus.productRails.filter(x => x.planogramId === planogramId)
            .filter(function(item){
                var i = resArr.findIndex(x => x.externalProductId == item.externalProductId);
                if(i <= -1){
                    resArr.push(item);
                }
            });
            return resArr.sort((a, b) => this.props.products[a.externalProductId].name.localeCompare(this.props.products[b.externalProductId].name));
        }
    }

    stockArr(productId: number) {
        if (productId && this.props.storeType == "Ximiti") {
            return _.sum(this.props.stock.stockStatus.productReceptions
                .filter(y => y.productId === productId)
                .map(y => y.quantityRemaining))
        } else if(productId) {
            return _.sum(this.props.stock.stockStatus.productReceptions
                .filter(y => y.externalProductId === productId)
                .map(y => y.quantityRemaining))
         }else{ 
             return 0
        }
    }

    stockRobot(productId: number) {
        if (!this.props.stock.stockStatus)
            return 0;

        let planogramId = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0].planogramId;

        let totalProductRails = this.props.stock.stockStatus.productRails.filter(x => x.planogramId === planogramId);

        if (productId && this.props.storeType == "Ximiti") { 
            return _.sum(totalProductRails
                .filter(y => y.productId == productId)
                .map(x => x.productStocks.length))
        } else if (productId) { 
            return _.sum(totalProductRails
                .filter(y => y.externalProductId == productId)
                .map(x => x.productStocks.length))
        } else{
            return 0;
        }
    }

    getSupplierOrderProductSuppliers(supplierOrderId: number) {
        return this.props.storeType == "Ximiti" ? (
        this.nonReceptionedSupplierOrders && supplierOrderId
            ? this.nonReceptionedSupplierOrders.find(x => x.supplierOrderId === supplierOrderId).supplierOrderProductSuppliers
                .sort((a, b) => this.props.seed.products[a.productId].name.localeCompare(this.props.seed.products[b.productId].name))
            : []
        )
        : (
            this.nonReceptionedSupplierOrders && supplierOrderId
            ? this.nonReceptionedSupplierOrders.find(x => x.supplierOrderId === supplierOrderId).supplierOrderProductSuppliers
                .sort((a, b) => this.props.seed.externalProducts[a.externalProductId].name.localeCompare(this.props.seed.externalProducts[b.externalProductId].name))
            : []
        )
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get nonReceptionedSupplierOrders() {
        return this.props.supplierOrder  && this.props.supplierOrder.supplierOrders ?
            this.props.supplierOrder.supplierOrders.filter(x => x.status === "NotReceived") : [];
    }

    getNonReceptionedQty(storeItemId): number {
        return _.sum(this.props.supplierOrders.filter(x => x.status != "Received").map(x => x.supplierOrderProductSuppliers)
        .reduce((a, b) => a.concat(b), []).filter(x => x.productId === storeItemId).map(x => x.packQuantity));
    }

    getCorrectProductSupplier(data : Api.SupplierOrderProductSupplierModel)
    {
        return this.props.storeType == "Ximiti" ? this.props.seed.products[data.productId].productSuppliers
                 .find(x => x.productSupplierId === data.productSupplierId)
                 : this.props.seed.externalProducts[data.externalProductId].productSuppliers
                 .find(x => x.productSupplierId === data.productSupplierId);
    }

    get productsReceptions() {
        return _.values(this.props.products)
            .filter(x => this.props.stock.stockStatus.productRails.some(z => z.planogramId === this.activePlanogram.planogramId && z.productId === x.storeItemId))
            .sort((a, b) => this.props.products[a.storeItemId].name.localeCompare(b.name));
    }

    get reorderDatas() {
        if(this.props.storeType == "Ximiti"){
        return this.props.reorderDatas
            .sort((a, b) => this.props.seed.products[a.storeItemId].name.localeCompare(this.props.seed.products[b.storeItemId].name));
        }else{
            return this.props.reorderDatas
            .sort((a, b) => this.props.seed.externalProducts[a.storeItemId].name.localeCompare(this.props.seed.externalProducts[b.storeItemId].name));
        }
    }

    getCurrenStock(storeItemId: number): number {
        let d = this.reorderDatas.find(x => x.storeItemId == storeItemId)
        if(!d)
            return 0;

        return d.currentStock;
    }

    getProbableTotalStock(storeItemId: number, packCondition: number): number {
        let d = this.reorderDatas.find(x => x.storeItemId == storeItemId)
        if(!d)
            return 0;

        if(this.props.storeType == "Ximiti"){
            return d.currentStock + (_.sum(this.props.supplierOrders.filter(x => x.status != "Received").map(x => x.supplierOrderProductSuppliers)
            .reduce((a, b) => a.concat(b), []).filter(x => x.productId === d.storeItemId ).map(x => x.packQuantity)) * packCondition);
        }
        else{
            return d.currentStock + (_.sum(this.props.supplierOrders.filter(x => x.status != "Received").map(x => x.supplierOrderProductSuppliers)
            .reduce((a, b) => a.concat(b), []).filter(x => x.externalProductId === d.storeItemId ).map(x => x.packQuantity)) * packCondition);
        }
    }

    get supplierOrderSelected(){
        return this.props.supplierOrders.find(x => x.supplierOrderId == this.props.supplierOrderSelectedId)
    }

    handleQtyToOrderChange(qtyToOrder: number, productId: number,productSupplierId: number, unitE: boolean){
        qtyToOrder = Math.abs(qtyToOrder);
        let supplierOrderToRecieve = {
            productId: productId,
            qtyToOrder: qtyToOrder,
            productSupplierId: productSupplierId,
            unitEdit: unitE
        }

        if(!this.supplierOrdersToRecieve.find(x => x.productId === productId)) {
            this.supplierOrdersToRecieve.push(supplierOrderToRecieve);
        } else {
            let supplierOrderToRecieve = this.supplierOrdersToRecieve.find(x => x.productId === productId);
            supplierOrderToRecieve.qtyToOrder = qtyToOrder;
            supplierOrderToRecieve.unitEdit = unitE;
        }

        this.setStateSuppOrder(this.supplierOrdersToRecieve);
    };

    handleSupplierOrderSelectedIdChanged(supplierOrderSelectedIdductId){
        this.props.updateSupplierOrderSelectedId(supplierOrderSelectedIdductId);
        this.supplierOrdersToRecieve = [];
        if(supplierOrderSelectedIdductId && this.props.storeType == "Ximiti") {
            this.getSupplierOrderProductSuppliers(supplierOrderSelectedIdductId).forEach(item => {
                let supplierOrderToRecieve = {
                    productId: item.productId,
                    qtyToOrder: item.packQuantity * this.props.seed.products[item.productId].productSuppliers
                        .find(x => x.productSupplierId === item.productSupplierId).packCondition,
                    productSupplierId: item.productSupplierId,
                    unitEdit: false
                }
                this.supplierOrdersToRecieve.push(supplierOrderToRecieve);
            });
        }else if (supplierOrderSelectedIdductId){
            this.getSupplierOrderProductSuppliers(supplierOrderSelectedIdductId).forEach(item => {
                let supplierOrderToRecieve = {
                    productId: item.externalProductId,
                    qtyToOrder: item.packQuantity * this.props.seed.externalProducts[item.externalProductId].productSuppliers
                        .find(x => x.productSupplierId === item.productSupplierId).packCondition,
                    productSupplierId: item.productSupplierId,
                    unitEdit: false
                }
                this.supplierOrdersToRecieve.push(supplierOrderToRecieve);
            });
        }
        this.setStateSuppOrder(this.supplierOrdersToRecieve);
    };

    handleClick = () => {
        let model: Api.CreateProductReceptionsModel;
        let productReceptions: Api.CreateProductReceptionModel[] = [];
        let productReception: Api.CreateProductReceptionModel;
        if(this.props.storeType == "Ximiti")
        {
            this.supplierOrdersToRecieve.forEach(element => { 
                productReception = {
                    productId: element.productId, 
                    productSupplierId: element.productSupplierId, 
                    quantity: element.qtyToOrder && !isNaN(element.qtyToOrder) ? element.qtyToOrder : 0,
                    supplierOrderId: this.supplierOrderSelected.supplierOrderId 
                }
                productReceptions.push(productReception);
            }); 
        }
        else
        {
            this.supplierOrdersToRecieve.forEach(element => { 
                productReception = {
                    externalProductId: element.productId, 
                    productSupplierId: element.productSupplierId, 
                    quantity: element.qtyToOrder && !isNaN(element.qtyToOrder) ? element.qtyToOrder : 0,
                    supplierOrderId: this.supplierOrderSelected.supplierOrderId 
                }
                productReceptions.push(productReception);
            }); 
        }        
        model = {
            createProductReceptions: productReceptions
        }
        if (model) {
            (this.props.requestCreateReceptions(new Date().getTime(), model) as any as Promise<any>)
            .then(() => { 
                (this.props.requestUpdateSupplierOrderStatus(this.props.supplierOrderSelectedId) as any as Promise<any>)
                .then(() => { 
                    this.props.requestSupplierOrders(new Date().getTime());
                    alert(getText("NotificationCreatedSuccess"));
                    this.props.closeSupplierOrderDialog();
                });
            });
        }
    }    

    productQtyToOrderValue(productId : number){
        return this.supplierOrdersToRecieve.find(x => x.productId == productId) ?
        this.supplierOrdersToRecieve.find(x => x.productId == productId) :
        { qtyToOrder: 1 }
    }

    productQtyToOrderPackValue(productId : number, cdtPack: number){
        let supplierOrder = this.supplierOrdersToRecieve.find(x => x.productId == productId);
        if(!supplierOrder.unitEdit){
            return (supplierOrder.qtyToOrder / cdtPack);
        }
        else return (0);
    }

    handleReceptionsUnit(productId: number,value: number) {
        let qty = Math.abs(value);
        if(!Number.isNaN(qty) && qty != null){
            let productReception: Api.CreateProductReceptionModel;
            let productSupplier = ReorderStore.getProductSupplier(productId, this.props.seed.products);
            productReception = {
                productId: productId,
                productSupplierId: productSupplier.productSupplierId,
                quantity: qty
            }
            let exist =  this.productReceptionsUnits.findIndex(x =>
                x.productId === productReception.productId && x.productSupplierId === productReception.productSupplierId);

            if(exist == -1){
                qty != 0 && this.productReceptionsUnits.push(productReception);
            }
            else{
                qty != 0 ? this.productReceptionsUnits[exist] = productReception
                            : this.productReceptionsUnits = this.productReceptionsUnits.filter(x =>
                                x.productId !== productReception.productId && x.productSupplierId !== productReception.productSupplierId);
            }

            if(this.productReceptionsCdt.find(x =>
                x.productId === productReception.productId && x.productSupplierId === productReception.productSupplierId
            )){
                this.productReceptionsCdt = this.productReceptionsCdt.filter(x =>
                    x.productId !== productReception.productId && x.productSupplierId !== productReception.productSupplierId);
            }
            this.setStateUnits(this.productReceptionsUnits);
            this.setStateCdt(this.productReceptionsCdt);
        }
    }

    handleReceptionCdt(productId: number, value: number){
        let packQty = Math.abs(value);
        if(!Number.isNaN(packQty) && packQty != null){
            // Validation: Have no units and cdt
            let productSupplier = ReorderStore.getProductSupplier(productId, this.props.seed.products);
            let existUnitReception =  this.productReceptionsUnits.find(x =>
                x.productId === productId && x.productSupplierId === productSupplier.productSupplierId);

            if(!existUnitReception){
                let productReception: Api.CreateProductReceptionModel;
                productReception = {
                    productId: productId,
                    productSupplierId: productSupplier.productSupplierId,
                    quantity: packQty
                }

                let exist =  this.productReceptionsCdt.findIndex(x =>
                    x.productId === productReception.productId && x.productSupplierId === productReception.productSupplierId);

                if(exist == -1){
                    packQty != 0 && this.productReceptionsCdt.push(productReception);
                }
                else{
                    packQty != 0 ? this.productReceptionsCdt[exist] = productReception
                                : this.productReceptionsCdt = this.productReceptionsCdt.filter(x =>
                                    x.productId !== productReception.productId && x.productSupplierId !== productReception.productSupplierId);
                }
            }
            this.setStateCdt(this.productReceptionsCdt);
        }

    }

    handleSendReceptions(){
        let model: Api.CreateProductReceptionsModel;
        this.productReceptionsCdt.forEach(x => {
            let productSupplier = ReorderStore.getProductSupplier(x.productId, this.props.seed.products);
            x.quantity = (x.quantity * productSupplier.packCondition);
        });
        
        model = {
            createProductReceptions: this.productReceptionsUnits.concat(this.productReceptionsCdt)
        }
        
        this.props.requestCreateReceptions(new Date().getTime(), model);
        this.productReceptionsUnits = [];
        this.productReceptionsCdt = [];
        this.setStateUnits(this.productReceptionsUnits);
        this.setStateCdt(this.productReceptionsCdt);
    }

    productReceptionValue(productId: number, array: Array<Api.CreateProductReceptionModel>): Api.CreateProductReceptionModel {
        return array.find(x => x.productId == productId) ?
            array.find(x => x.productId == productId) :
            { quantity: 0 }
    }

    get isMobileDevice(){
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        return regexp.test(details);
    }

    public render() {
        return (
            <div >
                <div 
                className="card"
                style={{
                    ...Styles.cardStyle,
                    ...Styles.pageTitle
                }}><span style={{paddingLeft: 30}}>{getText( "ReceptionTitle")}</span> <HelpHeader /></div>
                <div className="row" style={{margin: "1% 1% 0.2rem 1%"}}>
                    <div className="col-md-12" style={{padding: 0, fontSize: 16}}>
                        {getText( "ReceptionOrders")}:
                    </div>
                </div>
                <div className="row" style={{margin: "0 1%"}}>
                    <div className="col-md-3 selectors" style={{paddingLeft: 0}}>
                        <div className="card" style={{ ...Styles.cardStyle }}>
                            <Select options={[{
                                label: "------",
                                value: undefined 
                                }].concat(_.values(this.nonReceptionedSupplierOrders)
                                .filter(x => x.supplierOrderProductSuppliers.length > 0)
                                .map(z => ({ label: z.name, value: z.supplierOrderId })))}
                                value={this.props.supplierOrderSelectedId}
                                onChange={(supplierOrderId) => {
                                    this.handleSupplierOrderSelectedIdChanged(supplierOrderId);
                                }}
                                style={{background: this.isDarkMode() ? "#181F3A" : "#FFFFFF" }} 
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type={"submit"}
                            style={{height: 40, fontSize: 15, fontWeight: 'bold'}}
                            className="btn btn-primary"
                            disabled={!this.props.supplierOrderSelectedId} 
                            onClick={(e) => {
                                this.props.openSupplierOrderDialog();
                        }}> {getText( "ReceptionReceiveOrder")} </button>
                    </div>
                    { this.props.storeType == "Ximiti" &&
                    <div className="col-md-6" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 15, fontWeight: 'bold'}}
                            onClick={(e) => {
                               this.handleSendReceptions();
                            }}>
                                {getText( "ReceptionManual")}
                        </button>
                    </div>
                    }
                    {/*<div className="col-md-6" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 15, fontWeight: 'bold'}}
                            disabled={this.isDisable()}
                            onClick={(e) => {
                                this.props.downloadProductReceptionCsv();
                            }}>
                                {getText( "ReceptionDownloadCSV")}
                                <i style={{marginLeft: '5%'}} className="glyphicon glyphicon-download-alt"></i>
                        </button>
                    </div>*/}
                </div>
                <div className="card reception-table" style={{ ...Styles.cardStyle, margin: "1%", height: "100%" }}>
                <Table
                        tableKey={"reception"}
                        loading={this.props.stock.isLoading}
                        style={{ height: '70vh' }}
                        getTheadFilterThProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    borderRight: column.filterable && (this.isDarkMode() ? '1px solid transparent' : '1px solid rgba(255, 255, 255, 0.16)')
                                }
                            };
                        }}
                        columns={[
                            {
                                id: "position",
                                Header: <span>&nbsp;</span>,
                                columns: [
                                    {
                                        id: "name",
                                        accessor: (d) => this.props.storeType == "Ximiti" ? this.props.products[d.productId].name : this.props.products[d.externalProductId].name,
                                        Header: <div>{getText( "ReceptionNom")}</div>,
                                        Cell: row => { return (<div style={{textAlign: 'initial', margin: 7}}> {row.value}</div>) },
                                        style: { height: '100%' },
                                        width: 350,
                                        filterable: true,
                                        Filter: ({filter, onChange}) => (
                                            <div style={{padding: '0 2%'}}>
                                                 { !this.isMobileDevice ? <input
                                                    onChange={event => onChange(event.target.value)}
                                                    value={filter ? filter.value : ''}
                                                    placeholder= {getText( "ReceptionSearchName")}
                                                    style={{
                                                        width: '100%',
                                                        color: this.isDarkMode() ? "white" : '#181F3A',
                                                        background: this.isDarkMode() ? 'transparent' : 'white'
                                                    }}
                                                />
                                                :<>
                                                <input
                                                    onChange={event => this.setState({
                                                        ...this.state,         
                                                        filterInNameTab: event.target.value
                                                    })}
                                                    value={this.state.filterInNameTab}
                                                    placeholder= {getText( "ReceptionSearchName")}
                                                    style={{
                                                        width: '70%',
                                                        color: this.isDarkMode() ? "white" : '#181F3A',
                                                        background: this.isDarkMode() ? 'transparent' : 'white'
                                                    }}
                                                />
                                                    <button type={"button"}
                                                        className="btn btn-primary"
                                                        style={{width: '30%', padding: 5}}
                                                        onClick={() => onChange(this.state.filterInNameTab)}>
                                                        {getText( "HistoFilter")}
                                                    </button>
                                                </>
                                                }
                                            </div>
                                        ),
                                    },
                                    {
                                        id: "supplier",
                                        accessor: (d) => this.props.storeType == "Ximiti" ? 
                                            this.props.suppliers[ReorderStore.getProductSupplier(d.productId, this.props.seed.products).supplierMainId].name
                                            : this.props.suppliers[ReorderStore.getExternalProductSupplier(d.externalProductId, this.props.seed.externalProducts).supplierMainId].name,
                                        Cell: row => { return (<div style={{textAlign: 'initial', margin: 7}}> {row.value}</div>) },
                                        style: { height: '100%' },
                                        Header: <div>{getText( "ReceptionSource")}</div>,
                                        filterable: true,
                                        Filter: ({filter, onChange}) => (
                                            <div style={{padding: '0 2%'}}>
                                                { !this.isMobileDevice ?  <input
                                                onChange={event => onChange(event.target.value)}
                                                value={filter ? filter.value : ''}
                                                placeholder= {getText( "ReceptionSearchSource")}
                                                style={{
                                                    width: '100%',
                                                    color: this.isDarkMode() ? "white" : '#181F3A',
                                                    background: this.isDarkMode() ? 'transparent' : 'white'
                                                }} />
                                                :<>
                                                <input
                                                    onChange={event => this.setState({
                                                        ...this.state,         
                                                        filterInSourceTab: event.target.value
                                                    })}
                                                    value={this.state.filterInSourceTab}
                                                    placeholder= {getText( "ReceptionSearchSource")}
                                                    style={{
                                                        width: '70%',
                                                        color: this.isDarkMode() ? "white" : '#181F3A',
                                                        background: this.isDarkMode() ? 'transparent' : 'white'
                                                    }}
                                                />
                                                    <button type={"button"}
                                                        className="btn btn-primary"
                                                        style={{width: '30%', padding: 5}}
                                                        onClick={() => onChange(this.state.filterInSourceTab)}>
                                                            {getText( "HistoFilter")}
                                                    </button>
                                                </>
                                                }
                                           </div>
                                        ),
                                    },
                                    {
                                        id: "reference",
                                        accessor: (d) => this.props.storeType == "Ximiti" ? ReorderStore.getProductSupplier(d.productId, this.props.seed.products).reference
                                        : ReorderStore.getExternalProductSupplier(d.externalProductId, this.props.seed.externalProducts).reference,
                                        Cell: row => { return (<div style={{margin: 7}}>{row.value}</div>) },
                                        style: { height: '100%'},
                                        Header: <div>{getText( "ReceptionCode")}</div>,
                                        filterable: true,
                                        Filter: ({filter, onChange}) => (
                                            <div style={{padding: '0 2%'}}>
                                                { !this.isMobileDevice ? <input
                                                    onChange={event => onChange(event.target.value)}
                                                    value={filter ? filter.value : ''}
                                                    placeholder= {getText( "ReceptionCode")}
                                                    style={{
                                                    width: '100%',
                                                    color: this.isDarkMode() ? "white" : '#181F3A',
                                                    background: this.isDarkMode() ? 'transparent' : 'white'
                                                }}
                                                />
                                                :<>
                                                <input
                                                        onChange={event => this.setState({
                                                            ...this.state,         
                                                            filterInCodeTab: event.target.value
                                                        })}
                                                        value={this.state.filterInCodeTab}
                                                        placeholder= {getText( "ReceptionCode")}
                                                        style={{
                                                        width: '70%',
                                                        color: this.isDarkMode() ? "white" : '#181F3A',
                                                        background: this.isDarkMode() ? 'transparent' : 'white'
                                                    }}
                                                />
                                                    <button type={"button"}
                                                        className="btn btn-primary"
                                                        style={{width: '30%', padding: 5}}
                                                        onClick={() => onChange(this.state.filterInCodeTab)}>
                                                            {getText( "HistoFilter")}
                                                    </button>
                                                </>
                                                }
                                            </div>
                                        ),
                                        Footer: ( <div><strong>{getText( "ReceptionTotal")}: </strong></div> )
                                    },
                                ]
                            },
                            {
                                id: "inventory",
                                Header: <div>{this.props.storeType == "Ximiti" ? getText( "ReceptionInventory") : getText( "ReceptionInventoryRexel")}</div>,
                                columns: [
                                    {
                                        id: "robot",
                                        accessor: (d) => d,
                                        Cell: row => { return ( <div style={this.props.storeType != "Ximiti" ? columnXimitiStyle : {}}>{this.props.storeType == "Ximiti" ? this.stockRobot(row.value.productId) : this.stockRobot(row.value.externalProductId)}</div> )},
                                        Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "ReceptionStockRobot") : getText( "ReceptionStockRobotRexel")}</div>,
                                        filterable: true,
                                        maxWidth: 90,
                                        Filter: ({}) => (<span>&nbsp;</span>),
                                        style: { height: '100%' },
                                        Footer: (
                                            <div>
                                                <strong>
                                                    {this.props.storeType == "Ximiti" ?
                                                    Number(_.sum(_.map(this.productRails, x => this.stockRobot(x.productId) )))
                                                    :
                                                    Number(_.sum(_.map(this.productRails, x => this.stockRobot(x.externalProductId) )))
                                                }
                                                </strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "stkArr",
                                        Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "ReceptionBackStock") : getText( "ReceptionBackStockRexel")}</div>,
                                        style: { height: '100%' },
                                        maxWidth: 90,
                                        filterable: true,
                                        Filter: ({}) => ( <span>&nbsp;</span> ),
                                        accessor: (d) => d,
                                        Cell: row => {
                                            let storedStock = this.props.storeType == "Ximiti" ? this.stockArr(row.value.productId) : this.stockArr(row.value.externalProductId);
                                            
                                            let productSupplier = this.props.storeType == "Ximiti" ? 
                                                ReorderStore.getProductSupplier(row.value.productId, this.props.seed.products) : 
                                                ReorderStore.getExternalProductSupplier(row.value.externalProductId, this.props.seed.externalProducts)
                                            return (
                                                <div style={this.props.storeType == "Ximiti" ? {height: 35} : {...columnXimitiStyle, padding:0, height: 35}}>
                                                    <input
                                                        className="form-control"
                                                        style={{ width: '80%', textAlign: 'center', marginTop: '3%' }}
                                                        type={"number"}
                                                        min="0"
                                                        disabled={this.props.updateState.isLoading}
                                                        value={this.props.storeStockState[productSupplier.productSupplierId]
                                                            || this.props.storeStockState[productSupplier.productSupplierId] === 0
                                                            ? this.props.storeStockState[productSupplier.productSupplierId]
                                                            : storedStock}
                                                        onChange={(e) => this.props.receptionUpdateStoredStock(productSupplier.productSupplierId, parseInt(e.target.value) || 0)}
                                                        onKeyUp={(e) => {
                                                            if (e.keyCode === 13) {
                                                                let value = parseInt(e.currentTarget.value);
                                                                if ((value || value === 0) && value >= 0) {
                                                                    this.props.requestUpdateQuantityRemaining(new Date().getTime(), {
                                                                        productId: this.props.storeType == "Ximiti" ? row.value.productId : row.value.externalProductId,
                                                                        quantity: value
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                        />
                                                </div>
                                            )
                                        },
                                        Footer: (
                                            <div>
                                            <strong>
                                            {this.props.storeType == "Ximiti" ?
                                                Number(_.sum(_.map(this.productRails, x => this.stockArr(x.productId))))
                                                :
                                                Number(_.sum(_.map(this.productRails, x => this.stockArr(x.externalProductId))))
                                            }</strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "stkTotal",
                                        accessor: d => d,
                                        Cell: row => { return ( <div style={this.props.storeType != "Ximiti" ? columnXimitiStyle : {}}>{this.props.storeType == "Ximiti" ? this.getCurrenStock(row.value.productId) : this.getCurrenStock(row.value.externalProductId)}</div> )},
                                        Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "ReceptionTotalStock") : getText( "ReceptionTotalStockRexel")}</div>,
                                        filterable: true,
                                        maxWidth: 90,
                                        Filter: ({}) => (<span>&nbsp;</span>),
                                        style: { height: '100%' },
                                        Footer: (
                                            <div>
                                                <strong>
                                                    {this.props.storeType == "Ximiti" ?
                                                    Number(_.sum(_.map(this.productRails, x => this.getCurrenStock(x.productId))))
                                                    :
                                                    Number(_.sum(_.map(this.productRails, x => this.getCurrenStock(x.externalProductId))))
                                                    }
                                                </strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "stkProb",
                                        accessor: d => d,
                                        Cell: row => { return ( <div style={this.props.storeType != "Ximiti" ? columnXimitiStyle : {}}>{this.props.storeType == "Ximiti" ? this.getProbableTotalStock(row.value.productId,ReorderStore.getProductSupplier(row.value.productId, this.props.seed.products).packCondition)
                                         : this.getProbableTotalStock(row.value.externalProductId,ReorderStore.getExternalProductSupplier(row.value.externalProductId, this.props.seed.externalProducts).packCondition)}</div> )},
                                        Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "ReceptionProbableStock") : getText( "ReceptionProbableStockRexel") }</div>,
                                        filterable: true,
                                        maxWidth: 100,
                                        Filter: ({}) => (<span>&nbsp;</span> ),
                                        style: { height: '100%'},
                                        Footer: (
                                            <div>
                                                <strong>
                                                    { this.props.storeType == "Ximiti" ?
                                                    Number(_.sum(_.map(this.productRails, a => this.getProbableTotalStock(a.productId,ReorderStore.getProductSupplier(a.productId, this.props.seed.products).packCondition))))
                                                    :
                                                    Number(_.sum(_.map(this.productRails, a => this.getProbableTotalStock(a.externalProductId,ReorderStore.getExternalProductSupplier(a.externalProductId, this.props.seed.externalProducts).packCondition))))
                                                }
                                                </strong>
                                            </div>
                                        )
                                    }
                                ] as Array<ReceptionColumn>
                            },
                            {
                                id: "inventoryUp",
                                Header: <div>{getText( "ReceptionInventoryUp")}</div>,
                                columns: [
                                    {
                                        id: "stkArrUp",
                                        accessor: (d) => d,
                                        Header: <div style={headerColumns}>{getText( "ReceptionBackStockUp")}</div>,
                                        filterable: true,
                                        maxWidth: 85,
                                        show: this.props.storeType == "Ximiti" ? false : true,
                                        Filter: ({}) => ( <span>&nbsp;</span> ),
                                        Cell: row => {
                                            let storedStock = this.stockArr(row.value.externalProductId);
                                            
                                            let productSupplier = ReorderStore.getExternalProductSupplier(row.value.externalProductId, this.props.seed.externalProducts)
                                            return ( <div style={columnRexelStyle}>{
                                            (this.props.storeStockState[productSupplier.productSupplierId]
                                                || this.props.storeStockState[productSupplier.productSupplierId] === 0
                                                ? this.props.storeStockState[productSupplier.productSupplierId]
                                                : storedStock) * this.props.externalProducts[row.value.externalProductId].conversionFactor
                                        }</div> )},
                                        style: { height: '100%' },
                                        Footer: (
                                            <div>
                                                <strong>
                                            {this.props.storeType == "Ximiti" ?
                                                Number(_.sum(_.map(this.productRails, x => this.stockArr(x.productId))))
                                                :
                                                Number(_.sum(_.map(this.productRails, x => (this.stockArr(x.externalProductId)
                                                * this.props.externalProducts[x.externalProductId].conversionFactor))))
                                            }</strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "stkTotalUp",
                                        accessor: d => d,
                                        Cell: row => { return ( <div style={columnRexelStyle}>{this.getCurrenStock(row.value.externalProductId) * this.props.externalProducts[row.value.externalProductId].conversionFactor}</div> )},
                                        Header: <div style={headerColumns}>{getText( "ReceptionTotalStockUp")}</div>,
                                        filterable: true,
                                        maxWidth: 100,
                                        show: this.props.storeType == "Ximiti" ? false : true,
                                        Filter: ({}) => (<span>&nbsp;</span>),
                                        style: { height: '100%' },
                                        Footer: (
                                            <div>
                                                <strong>
                                                    {this.props.storeType == "Ximiti" ?
                                                    Number(_.sum(_.map(this.productRails, x => this.getCurrenStock(x.productId))))
                                                    :
                                                    Number(_.sum(_.map(this.productRails, x => (this.getCurrenStock(x.externalProductId)
                                                        * this.props.externalProducts[x.externalProductId].conversionFactor))))
                                                    }
                                                </strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "stkProbUp",
                                        accessor: d => d,
                                        Cell: row => { return ( <div style={columnRexelStyle}>{this.getProbableTotalStock(row.value.externalProductId,ReorderStore.getExternalProductSupplier(row.value.externalProductId, this.props.seed.externalProducts).packCondition)
                                            * this.props.externalProducts[row.value.externalProductId].conversionFactor}</div> )},
                                        Header: <div style={headerColumns}>{getText( "ReceptionProbableStockUp")}</div>,
                                        filterable: true,
                                        maxWidth: 100,
                                        show: this.props.storeType == "Ximiti" ? false : true,
                                        Filter: ({}) => (<span>&nbsp;</span> ),
                                        style: { height: '100%'},
                                        Footer: (
                                            <div>
                                                <strong>
                                                    { this.props.storeType == "Ximiti" ?
                                                    Number(_.sum(_.map(this.productRails, a => this.getProbableTotalStock(a.productId,ReorderStore.getProductSupplier(a.productId, this.props.seed.products).packCondition))))
                                                    :
                                                    Number(_.sum(_.map(this.productRails, a => (this.getProbableTotalStock(a.externalProductId,ReorderStore.getExternalProductSupplier(a.externalProductId, this.props.seed.externalProducts).packCondition)
                                                    * this.props.externalProducts[a.externalProductId].conversionFactor ))))
                                                }
                                                </strong>
                                            </div>
                                        )
                                    }
                                ] as Array<ReceptionColumn>
                            },
                            {
                                id: "validation",
                                Header: <div>{getText( "ReceptionDayOrder")}</div>,
                                columns: [
                                    {
                                        id: "unites",
                                        accessor: (d) => d,
                                        Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "ReceptionUnits") : getText( "ReceptionUnitsRexel")}</div>,
                                        filterable: true,
                                        maxWidth: 100,
                                        Filter: ({}) => ( <span>&nbsp;</span>),
                                        Cell: row => {
                                            return (
                                                <div style={this.props.storeType == "Ximiti" ? {height: 35} : {...columnXimitiStyle,padding:0, height: 35}}>
                                                    {this.props.storeType == "Ximiti" ?
                                                        <InputNumber value={ this.productReceptionValue(row.value.productId, this.productReceptionsUnits).quantity}
                                                        style={{ width: '80%', textAlign: 'center', marginTop: '3%' }}
                                                        onChange={(e) => this.handleReceptionsUnit(row.value.productId, Math.trunc(e))}
                                                        onBlur={(e) => this.handleReceptionsUnit(row.value.productId, Math.trunc(e))}/>
                                                    : 
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        style={{ width: '80%', textAlign: 'center', marginTop: '3%' }}
                                                        defaultValue="0"
                                                        min="0"
                                                        //Probably better
                                                        disabled={this.props.createState.isLoading || true}
                                                     />
                                                    }
                                                </div>
                                            )
                                        },
                                        style: {height: '100%' },
                                        Footer: (
                                            <div>
                                                {this.props.storeType == "Ximiti" ?
                                                <strong>
                                                    { Number(_.sum(_.map(this.state.stateProdRecepUnits, a => a.quantity)))}
                                                </strong>
                                                : <span>&nbsp;</span>}
                                            </div>
                                        )
                                    },
                                    {
                                        id: "volumeUnit",
                                        accessor: (x) => x,
                                        Cell: row => { return ( <div style={this.props.storeType != "Ximiti" ? columnXimitiStyle : {}}>{`${this.props.units[this.props.externalProducts[row.value.externalProductId].volumeUnitId].name} (${this.props.externalProducts[row.value.externalProductId].conversionFactor})`}</div> )},
                                        Header: <div style={headerColumns}>{getText("ReceptionVolUnit")}</div>,
                                        maxWidth: 100,
                                        style: {
                                            height: "100%"
                                        },
                                        filterable: false,
                                        show: this.props.storeType == "Ximiti" ? false : true
                                    },    
                                    {
                                        id: "practicUnit",
                                        accessor: (x) => x,
                                        Cell: row => { return ( <div style={columnRexelStyle}>{this.props.units[this.props.externalProducts[row.value.externalProductId].practicUnit].name}</div> )},
                                        Header: <div style={headerColumns}>{getText("ReceptionPracticUnit")}</div>,
                                        maxWidth: 100,
                                        style: {
                                            height: "100%"
                                        },
                                        filterable: false,
                                        show: this.props.storeType == "Ximiti" ? false : true
                                    },
                                    {
                                        id: "cdt",
                                        accessor: (d) => d,
                                        Header: <div style={headerColumns}>{getText( "ReceptionQty")}</div>,
                                        filterable: true,
                                        maxWidth: 100,
                                        show: this.props.storeType == "Ximiti" ? true : false,
                                        Filter: ({}) => ( <span>&nbsp;</span>),
                                        Cell: row => {
                                            let cdt = this.props.storeType == "Ximiti" ? ReorderStore.getProductSupplier(row.value.productId, this.props.seed.products).packCondition
                                                : ReorderStore.getExternalProductSupplier(row.value.externalProductId, this.props.seed.externalProducts).packCondition
                                            return (<div style={{margin: 7}}>{cdt}</div>);
                                        },
                                        style: {height: '100%'},
                                        Footer: (<span>&nbsp;</span>)
                                    },
                                    {
                                        id: "NbCdt",
                                        accessor: (d) => d,
                                        Header: <div>{getText( "ReceptionQtyNumber")}</div>,
                                        maxWidth: 100,
                                        filterable: false,
                                        Cell: row => {
                                            return (
                                                <div>
                                                    {this.props.storeType == "Ximiti" ?
                                                        <InputNumber value={this.productReceptionValue(row.value.productId, this.productReceptionsCdt).quantity}
                                                            style={{ width: '80%', textAlign: 'center', marginTop: '3%' }}
                                                            onChange={(e) =>  this.handleReceptionCdt(row.value.productId, Math.trunc(e))}
                                                            onBlur={(e) => this.handleReceptionCdt(row.value.productId, Math.trunc(e))}/>
                                                        :
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ width: '80%', textAlign: 'center', marginTop: '3%' }}
                                                            //Probably better
                                                            disabled={this.props.createState.isLoading || true}
                                                            defaultValue="0"
                                                            min="0"
                                                        />
                                                    }
                                                </div>
                                            )
                                        },
                                        style: {height: '100%'},
                                        Footer: (
                                            <div>
                                                {this.props.storeType == "Ximiti" ?
                                                <strong>
                                                    { Number(_.sum(_.map(this.state.stateProdRecepCdt, a => a.quantity)))}
                                                </strong> : <span>&nbsp;</span>
                                                }
                                            </div>
                                        )
                                    }
                                ] as Array<ReceptionColumn>
                            }
                        ] as Array<ReceptionColumn>}
                        pageSize={Math.max(this.productRails.length, 15)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        data={this.productRails}>
                    </Table>
                </div>
                {/*<div style={{ flex: "0 0 auto", paddingBottom: 10 }}>
                    <div style={{
                        pointerEvents: this.isDisable() ? "none" : "initial",
                        opacity: this.isDisable() ? 0.7 : 1, maxWidth: 600
                    }}>
                        <ProductReceptionsForm onSubmit={(values) =>
                            this.props.requestCreateReceptions(new Date().getTime(), values) as any} />
                    </div>
                </div>*/}
                <DialogModal contentLabel={"supplierOrderProductSuppliers"}
                    contentStyle={{
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                        minWidth: 1000, 
                        maxHeight: 600,
                        overflow: 'hidden'
                    }}
                    isOpen={this.props.isOpen}
                    onRequestClose={() => this.props.closeSupplierOrderDialog()}>
                    {this.supplierOrderSelected && <div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                            <div style={{
                                flex: "0 0 auto", display: "flex",
                                flexDirection: "row", alignItems: "center",
                                padding: "10px 0px",
                                minWidth: "200px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    paddingRight: 15
                                }}>
                                    <span style={{fontSize: 22, alignSelf: 'center'}}>{this.supplierOrderSelected.name}</span>
                                    <button type={"submit"}
                                        style={{
                                            height: 40, 
                                            fontSize: 15, 
                                            fontWeight: 'bold',
                                            alignSelf: 'center',
                                            marginLeft: 600,
                                            background: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                                            borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                                            color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"
                                        }}
                                        className="btn btn-primary"
                                        disabled={!this.props.supplierOrderSelectedId}
                                        onClick={this.handleClick}>
                                        {getText("ReceptionReceiveOrder")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Table key={"receptionModal"}
                                style={{height: '50vh'}}
                                showPagination={false}
                                showPaginationBottom={false}
                                pageSize={Math.max(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId).length, 8)}
                                data={this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId)}
                                columns={[
                                    {
                                        id: "productId",
                                        style: { textAlign: 'left' },
                                        minWidth: 250,
                                        accessor: d => this.props.storeType == "Ximiti" ? d.productId : d.externalProductId,
                                        Header: <div>{getText("ReceptionProduct")}</div>,
                                        Cell: row => <div>{this.props.products[row.value].name}</div>,
                                        Footer: (
                                            <div><span>&nbsp;</span></div>
                                        )
                                    },
                                    {
                                        id: "productSupplierId",
                                        accessor: d => d,
                                        width: 100,
                                        Header: <div>{getText("ReceptionSource")}</div>,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            let productSupplier = this.getCorrectProductSupplier(data);
                                            return <div>{this.props.seed.suppliers[productSupplier.supplierMainId].name}</div>;
                                        },
                                        Footer: (
                                            <div><span>&nbsp;</span></div>
                                        )
                                    },
                                    {
                                        id: "packNumber",
                                        accessor: d => d,
                                        Header: <div style={headerColumns}>{getText("OrderPackNumber")}</div>,
                                        show: this.props.storeType == "Ximiti" ? true : false,
                                        Cell: row => {
                                             let data = row.value as Api.SupplierOrderProductSupplierModel;
                                             let productSupplier =  this.getCorrectProductSupplier(data);
                                            return (
                                                <div>
                                                    {this.props.storeType == "Ximiti" ?
                                                    <InputNumber value={this.productQtyToOrderPackValue(data.productId, productSupplier.packCondition)}
                                                        style={{ width: 50, textAlign: 'center' }}
                                                        onChange = {(e) =>{
                                                            let value = Math.trunc(e) * productSupplier.packCondition;
                                                            this.handleQtyToOrderChange(value,data.productId,data.productSupplierId, false)
                                                        }}
                                                    />
                                                    : <div>{data.packQuantity}</div>
                                                    }
                                                </div>
                                            );
                                        },
                                        Footer: (
                                            <div>
                                                <strong>
                                                    {_.sum(_.map(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId), 
                                                        x => (x as Api.SupplierOrderProductSupplierModel).packQuantity ))}
                                                </strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "unites",
                                        Header: <div style={headerColumns}>{this.props.storeType == "Ximiti" ? getText( "ReceptionUnits") : getText( "ReceptionUnitsRexel")}</div>,
                                        accessor: d => d,
                                        maxWidth: 100,
                                        Cell: row => {
                                             let data = row.value as Api.SupplierOrderProductSupplierModel;
                                             let packQuantity = data.packQuantity
                                             let packCondition =  this.getCorrectProductSupplier(data).packCondition;
                                             let qtyToOrder = packQuantity * packCondition;
                                            return (
                                                <div style={this.props.storeType != "Ximiti" ? columnXimitiStyle : {}}>
                                                    {this.props.storeType == "Ximiti" ?
                                                        <InputNumber value={this.productQtyToOrderValue(row.value.productId).qtyToOrder}
                                                            style={{ width: 50, textAlign: 'center' }}
                                                            onChange = {(e) => this.handleQtyToOrderChange(Math.trunc(e),data.productId,data.productSupplierId, true)}
                                                        />
                                                        :
                                                        <input
                                                        defaultValue={`${qtyToOrder}`}
                                                        style={{ width: 50, textAlign: 'center' }}
                                                        type="number"
                                                        min="1"
                                                        onChange = {(e) => this.handleQtyToOrderChange(parseInt(e.target.value),data.externalProductId,data.productSupplierId, true)}
                                                        />
                                                    }
                                                </div>
                                            );
                                        },
                                        Footer: (<div>
                                                <strong>
                                                    {_.sum(_.map(this.state.stateSuppOrdersToRecieve, x => x.qtyToOrder))}
                                                </strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "volumeUnit",
                                        style: { textAlign: 'left' },
                                        maxWidth: 100,
                                        accessor: d => d,
                                        Cell: row => { return ( <div style={this.props.storeType != "Ximiti" ? columnXimitiStyle : {}}>{`${this.props.units[this.props.externalProducts[row.value.externalProductId].volumeUnitId].name} (${this.props.externalProducts[row.value.externalProductId].conversionFactor})`}</div> )},
                                        Header: <div style={headerColumns}>{getText("ReceptionVolUnit")}</div>,
                                        show: this.props.storeType == "Ximiti" ?  false : true,
                                        Footer: (
                                            <div><span>&nbsp;</span></div>
                                        )
                                    },
                                    {
                                        id: "practicUnit",
                                        style: { textAlign: 'left' },
                                        maxWidth: 100,
                                        accessor: d => d,
                                        Cell: row => { return ( <div style={columnRexelStyle}>{this.props.units[this.props.externalProducts[row.value.externalProductId].practicUnit].name}</div> )},
                                        Header: <div style={headerColumns}>{getText("ReceptionPracticUnit")}</div>,
                                        show: this.props.storeType == "Ximiti" ?  false : true,
                                        Footer: (
                                            <div><span>&nbsp;</span></div>
                                        )
                                    },
                                    {
                                        id: "qtyPack",
                                        accessor: d => d,
                                        width: 80,
                                        Header: <div style={headerColumns}>{getText("ReceptionQtyPack")}</div>,
                                        show: this.props.storeType == "Ximiti" ? true : false,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            let productSupplier = this.getCorrectProductSupplier(data);
                                            return <div>{productSupplier.packCondition}</div>;
                                        },
                                        Footer: (
                                            <div><span>&nbsp;</span></div>
                                        )
                                    },
                                    {
                                        id: "unitesUp",
                                        Header: <div style={headerColumns}>{getText("ReceptionUnitsUp")}</div>,
                                        accessor: d => d,
                                        maxWidth: 100,
                                        show: this.props.storeType == "Ximiti" ? false : true,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            let qtyToOrder = this.supplierOrdersToRecieve.find(x => x.productId === data.externalProductId).qtyToOrder
                                            return ( this.props.storeType == "Ximiti" ? 
                                            <div>  {qtyToOrder} </div> :
                                            <div style={columnRexelStyle}>
                                                {qtyToOrder * this.props.externalProducts[row.value.externalProductId].conversionFactor}
                                            </div>);
                                        },
                                        Footer: (<div>
                                            <strong>
                                                { this.props.storeType == "Ximiti" ?
                                                    _.sum(_.map(this.state.stateSuppOrdersToRecieve, x => (x.qtyToOrder)))
                                                    : _.sum(_.map(this.state.stateSuppOrdersToRecieve, x => (x.qtyToOrder * this.props.externalProducts[x.productId].conversionFactor)))}
                                            </strong>
                                        </div>
                                    )
                                    },
                                    {
                                        id: "packPrice",
                                        accessor: d => d,
                                        Header: <div style={headerColumns}>{getText("ReceptionPAHTPack")}</div>,
                                        show: this.props.storeType == "Ximiti" ? true : false,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            let productSupplier =  this.getCorrectProductSupplier(data);
                                            return <div>{productSupplier.buyPriceNoVat.toFixed(2)} {this.props.seed.currencies[productSupplier.buyPriceCurrencyId].symbol}</div>;
                                        },
                                        Footer: (
                                            <div>
                                                <strong>
                                                    {this.props.storeType == "Ximiti" ?
                                                    _.sum(_.map(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId), 
                                                        x => this.props.internalProducts[x.productId].productSuppliers
                                                        .find(x => x.productSupplierId === x.productSupplierId).buyPriceNoVat )).toFixed(2)
                                                    :
                                                    _.sum(_.map(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId), 
                                                        x => this.props.externalProducts[x.externalProductId].productSuppliers
                                                        .find(x => x.productSupplierId === x.productSupplierId).buyPriceNoVat )).toFixed(2)
                                                    }
                                                </strong>
                                            </div>
                                        )
                                    },
                                    {
                                        id: "total",
                                        accessor: d => d,
                                        Header: <div>{getText("ReceptionTotal")}</div>,
                                        show: this.props.storeType == "Ximiti" ? true : false,
                                        Cell: row => {
                                            let data = row.value as Api.SupplierOrderProductSupplierModel;
                                            let productSupplier =  this.getCorrectProductSupplier(data);
                                            let quantity = this.props.storeType == "Ximiti" ? this.supplierOrdersToRecieve.find(x => x.productId === data.productId).qtyToOrder
                                                : this.supplierOrdersToRecieve.find(x => x.productId === data.externalProductId).qtyToOrder
                                            return (
                                                <div>
                                                    {(Math.round(quantity * productSupplier.buyPriceNoVat * 100) / 100).toFixed(2)}
                                                    {" "}
                                                    {this.props.seed.currencies[productSupplier.buyPriceCurrencyId].symbol}
                                                </div>
                                            );
                                        },
                                        Footer: (
                                            <div>
                                                {this.state.stateSuppOrdersToRecieve.length != 0 && 
                                                <strong>
                                                    {this.props.storeType == "Ximiti" ?
                                                    _.sum(_.map(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId), 
                                                        x => (Math.round(this.state.stateSuppOrdersToRecieve.find(y => y.productId === x.productId).qtyToOrder * this.props.internalProducts[x.productId].productSuppliers
                                                            .find(x => x.productSupplierId === x.productSupplierId).buyPriceNoVat * 100) / 100))).toFixed(2)
                                                            :
                                                            _.sum(_.map(this.getSupplierOrderProductSuppliers(this.props.supplierOrderSelectedId), 
                                                        x => (Math.round(this.state.stateSuppOrdersToRecieve.find(y => y.productId === x.externalProductId).qtyToOrder * this.props.externalProducts[x.externalProductId].productSuppliers
                                                            .find(x => x.productSupplierId === x.productSupplierId).buyPriceNoVat * 100) / 100))).toFixed(2)
                                                         }
                                                </strong>}
                                            </div>
                                        )
                                    }
                                ] as Array<SupplierOrderProductSupplierColumn>} />
                        </div>
                    </div>}
                </DialogModal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => {
    return {
        ...state.reception,
        ...state.supplierOrder,
        reorderDatas: ReorderStore.reorderDataSelector(state),
        productSuppliers: productSuppliersSelector(state),
        storeType: state.seed.seed.storeAppSettings.typeStore,
        seed: state.seed.seed,
        internalProducts: state.seed.seed.products,
        externalProducts: state.seed.seed.externalProducts,
        products: state.seed.seed.storeAppSettings.typeStore == "Ximiti" ? state.seed.seed.products : state.seed.seed.externalProducts,
        suppliers: state.seed.seed.suppliers,
        currentUser: state.account.currentUser,
        stock: state.stock,
        supplierOrder: state.supplierOrder,
        supplierOrders: state.supplierOrder.supplierOrders,
        units: state.seed.seed.units,
    } as ReceptionOwnProps
}, {
    ...ReceptionStore.actionCreators,
    ...StockStore.actionCreators,
    ...SupplierOrderStore.actionCreators,
    ...ReorderStore.actionCreators
})(Reception as any) as any as React.ComponentClass<{}>