import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { push } from "connected-react-router";
import { createSelector } from 'reselect';

export interface NeoEventAlarmState {
    isLoading: boolean;
    requestTime?: number;
    selectedNeoEventAlarmId?: number;
    neoEventAlarms: { [id: number]: Api.NeoEventAlarmModel };
    createState: {
        isLoading: boolean;
        requestTime?: number;
    };
    neoEventAlarmUpdateStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
    neoEventAlarmDeleteStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
}

interface NeoEventAlarmRequestEntities {
    type: "NEOEVENTALARM_REQUEST_ENTITIES";
    payload: { requestTime: number }
}
interface NeoEventAlarmReceiveEntities {
    type: "NEOEVENTALARM_RECEIVE_ENTITIES";
    payload: {
        requestTime: number;
        neoEventAlarms?: { [id: number]: Api.NeoEventAlarmModel };
    },
    error?: any
}

interface NeoEventAlarmRequestCreate {
    type: "NEOEVENTALARM_REQUEST_CREATE";
    payload: { requestTime: number; }
}
interface NeoEventAlarmReceiveCreate {
    type: "NEOEVENTALARM_RECEIVE_CREATE";
    payload: { requestTime: number; value?: Api.NeoEventAlarmModel }
    error?: any
}

interface NeoEventAlarmRequestUpdate {
    type: "NEOEVENTALARM_REQUEST_UPDATE";
    payload: { requestTime: number; id: number; }
}
interface NeoEventAlarmReceiveUpdate {
    type: "NEOEVENTALARM_RECEIVE_UPDATE";
    payload: { requestTime: number; id: number; value?: Api.NeoEventAlarmModel }
    error?: any
}

interface NeoEventAlarmRequestDelete {
    type: "NEOEVENTALARM_REQUEST_DELETE";
    payload: { requestTime: number; id: number; }
}
interface NeoEventAlarmReceiveDelete {
    type: "NEOEVENTALARM_RECEIVE_DELETE";
    payload: { requestTime: number; id: number; }
    error?: any
}

interface NeoEventAlarmUpdateSelectedNeoEventAlarmId {
    type: "NEOEVENTALARM_UPDATE_SELECTED_NEOEVENTALARMID";
    payload: { id: number; }
}

type KnownAction = NeoEventAlarmRequestEntities
    | NeoEventAlarmReceiveEntities
    | NeoEventAlarmRequestCreate
    | NeoEventAlarmReceiveCreate
    | NeoEventAlarmRequestUpdate
    | NeoEventAlarmReceiveUpdate
    | NeoEventAlarmRequestDelete
    | NeoEventAlarmReceiveDelete
    | NeoEventAlarmUpdateSelectedNeoEventAlarmId;

export const actionCreators = {
    requestNeoEventAlarms: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventAlarmApi();
        let fetchTask = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(neoEventAlarms => {
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime, neoEventAlarms: neoEventAlarms }
            });
        })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "NEOEVENTALARM_REQUEST_ENTITIES",
            payload: { requestTime: requestTime }
        });
        addTask(fetchTask);
        return fetchTask;
    },
    requestCreateNeoEventAlarm: (requestTime: number, model: Api.NeoEventAlarmModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventAlarmApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contact => {
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, value: contact }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_CREATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "NEOEVENTALARM_REQUEST_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateNeoEventAlarm: (requestTime: number, model: Api.NeoEventAlarmModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventAlarmApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contact => {
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.neoEventAlarmId, value: contact }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.neoEventAlarmId },
                    error: err
                });
            });

        dispatch({
            type: "NEOEVENTALARM_REQUEST_UPDATE",
            payload: { requestTime: requestTime, id: model.neoEventAlarmId }
        });
        return fetchTask;
    },
    requestDeleteNeoEventAlarm: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventAlarmApi();
        let fetchTask = api._delete({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(contact => {
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "NEOEVENTALARM_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "NEOEVENTALARM_REQUEST_DELETE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetchTask;
    },
    updateSelectedNeoEventAlarmId: (id: number) => <NeoEventAlarmUpdateSelectedNeoEventAlarmId>{
        type: "NEOEVENTALARM_UPDATE_SELECTED_NEOEVENTALARMID",
        payload: { id: id }
    }
};

const unloadedState: NeoEventAlarmState = {
    isLoading: false,
    neoEventAlarms: [],
    neoEventAlarmUpdateStates: {},
    neoEventAlarmDeleteStates: {},
    createState: {
        isLoading: false
    }
};

export const reducer: Reducer<NeoEventAlarmState> = (state: NeoEventAlarmState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "NEOEVENTALARM_REQUEST_ENTITIES":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "NEOEVENTALARM_RECEIVE_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isloading: false,
                neoEventAlarms: action.payload.neoEventAlarms,
            };
        case "NEOEVENTALARM_REQUEST_CREATE":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "NEOEVENTALARM_RECEIVE_CREATE":
            return {
                ...state,
                neoEventAlarms: action.error
                    ? state.neoEventAlarms
                    : {
                        ...state.neoEventAlarms,
                        [action.payload.value.neoEventAlarmId]: action.payload.value
                    },
                createState: {
                    ...state.createState,
                    isLoading: action.payload.requestTime !== state.createState.requestTime
                        ? state.createState.isLoading
                        : false
                }
            };
        case "NEOEVENTALARM_REQUEST_UPDATE":
            return {
                ...state,
                neoEventAlarmUpdateStates: {
                    ...state.neoEventAlarmUpdateStates,
                    [action.payload.id]: {
                        ...state.neoEventAlarmUpdateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "NEOEVENTALARM_RECEIVE_UPDATE":
            if (!state.neoEventAlarmUpdateStates[action.payload.id]
                || state.neoEventAlarmUpdateStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                neoEventAlarmUpdateStates: {
                    ...state.neoEventAlarmUpdateStates,
                    [action.payload.id]: {
                        ...state.neoEventAlarmUpdateStates[action.payload.id],
                        isLoading: false,
                    }
                },
                neoEventAlarms: action.error
                    ? state.neoEventAlarms
                    : {
                        ...state.neoEventAlarms,
                        [action.payload.value.neoEventAlarmId]: action.payload.value
                    }
            };
        case "NEOEVENTALARM_REQUEST_DELETE":
            return {
                ...state,
                neoEventAlarmDeleteStates: {
                    ...state.neoEventAlarmDeleteStates,
                    [action.payload.id]: {
                        ...state.neoEventAlarmDeleteStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "NEOEVENTALARM_RECEIVE_DELETE":
            return {
                ...state,
                neoEventAlarms: action.error
                    ? state.neoEventAlarms
                    : _.filter(state.neoEventAlarms, x => x.neoEventAlarmId !== action.payload.id),
                neoEventAlarmDeleteStates: {
                    ...state.neoEventAlarmDeleteStates,
                    [action.payload.id]: {
                        ...state.neoEventAlarmDeleteStates[action.payload.id],
                        isLoading: false,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "NEOEVENTALARM_UPDATE_SELECTED_NEOEVENTALARMID":
            return {
                ...state,
                selectedNeoEventAlarmId: action.payload.id
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};