import * as React from "react";
import * as Api from "../api/api";
import * as CashStore from "../store/Cash";
import * as CardStore from "../store/Card";
import * as PrintStore from "../store/Print";
import * as HmiMoneyStore from "../store/HmiMoney";
import Switch from "react-switch";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';
import * as HmiApplicationStore from "../store/HmiApplication";

type PeripheralsControlProps = PeripheralsControlOwnProps
    & typeof CashStore.actionCreators
    & typeof CardStore.actionCreators
    & typeof PrintStore.actionCreators
    & typeof HmiMoneyStore.actionCreators    
    & typeof HmiApplicationStore.actionCreators;

interface PeripheralsControlOwnProps {
    cash: CashStore.CashState;
    card: CardStore.CardState;
    print: PrintStore.PrintState;
    hmiMoney: Api.HmiMoneyModel;
    disabled: boolean;
    hmiApplication: HmiApplicationStore.HmiApplicationState;
}

interface PeripheralControlProps {
    name: string;
    value: boolean;
    disabled: boolean;
    onChange: (value: boolean) => void;
    styleRow?:  React.CSSProperties;
}

class PeripheralControl extends React.Component<PeripheralControlProps, {}> {
    public render() {
        return (
            <div className="row" 
                style={{borderBottom: '1px solid rgba(59, 67, 112, 0.08)',
                    display: 'flex', alignItems: 'center', padding: '1% 0',
                    ...this.props.styleRow}}>
                <div className="col-md-9" style={{fontSize: 14, paddingRight: 0}}>{this.props.name}</div>
                <div className="col-md-3">
                    <Switch onChange={(value) => this.props.onChange(value)}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        disabled={this.props.disabled}
                        checked={this.props.value} />
                </div>
            </div>
        );
    }
}

class PeripheralsControl extends React.Component<PeripheralsControlProps, {}> {

    componentDidMount() {
        this.props.requestHmiMonies(new Date().getTime());
    }

    get cashState() {
        return this.props.cash.state
        && this.props.cash.state.coinAcceptorEnabled
        && this.props.cash.state.coinAcceptorStatus === "Ok"
        && this.props.cash.state.status === "Ok";
    }

    get cashBillState() {
        return this.props.cash.state
        && this.props.cash.state.status === "Ok"
        && this.props.hmiMoney.cashBillEnabled;
    }

    get creditCardState() {
        return this.props.card.state && this.props.card.state.status === "Ok";
    }

    public render() {
        return (
            <div>
                <PeripheralControl name={getText( "MaintenanceCoinTerminal")}
                    disabled={this.props.disabled || this.props.cash.cashEnabledState.isLoading}
                    value={this.cashState}
                    onChange={(value) => this.props.requesUpdateCashEnabled(new Date().getTime(), value)} />
                <PeripheralControl name={getText( "MaintenanceTicketReader")}
                    disabled={this.props.disabled
                        || this.props.cash.cashEnabledState.isLoading
                        || !this.props.cash.state
                        || this.props.cash.state.status !== "Ok"}
                    value={this.cashBillState}
                    onChange={(value) =>  this.props.requesUpdateCashBillEnabled(new Date().getTime(), value)} />
                {/*<PeripheralControl name={"NFC sans contact"}
                    disabled={this.props.disabled}
                    value={this.props.hmiMoney.noContactEnabled}
                    onChange={(value) => this.props.requesUpdateNoContactEnabled(new Date().getTime(), value)} />*/}
                <PeripheralControl name={getText( "MaintenanceCreditCard")}
                    disabled={this.props.disabled || this.props.card.cardEnabledState.isLoading}
                    value={this.creditCardState}
                    onChange={(value) => this.props.requesUpdateCardEnabled(new Date().getTime(), value)} />
                <PeripheralControl name={getText( "MaintenancePrinter")}
                    styleRow={{borderBottom: 'none'}}
                    disabled={this.props.disabled || this.props.print.printEnabledState.isLoading}
                    value={this.props.print.state 
                        && this.props.print.state.status === "Ok"
                    && this.props.print.state.paperStatus !== "NoPaper"}
                    onChange={(value) => this.props.requesUpdatePrintEnabled(new Date().getTime(), value)} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    cash: state.cash,
    card: state.card,
    print: state.print,
    hmiMoney: state.hmiMoney.hmiMonies[0] || {},
    disabled: !canUpdate(state),
    hmiApplication: state.hmiApplication,
} as PeripheralsControlOwnProps)
    , {
        ...CashStore.actionCreators,
        ...CardStore.actionCreators,
        ...PrintStore.actionCreators,
        ...HmiMoneyStore.actionCreators,
        ...HmiApplicationStore.actionCreators
    })(PeripheralsControl as any)