import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from "react-table";
import * as MenuCategoriesStore from '../store/MenuCategories';
import * as TabsStore from '../store/Tabs';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import Table from "./Table";
import HelpHeader from './HelpHeader';
import Select from "./Select";
import ConnectedTabs from './ConnectedTabs';
import { TabLink, TabContent } from "react-tabs-redux";
import Confirm from "react-confirm-bootstrap";
import { CreateMenuCategoryForm, UpdateMenuCategoryForm } from './MenuCategoryForm';
import { CreateMenuCategoryTranslationsForm, UpdateMenuCategoryTranslationsForm } from './MenuCategoryTranslationsForm';

type MenuCategoriesProps = MenuCategoriesStore.MenuCategoriesState
    & MenuCategoriesOwnProps
    & typeof MenuCategoriesStore.actionCreators
    & typeof TabsStore.actionCreators
;

interface MenuCategoriesOwnProps {
    languages: { [key: string]: Api.LanguageModel; };
    menuCategories: { [key: string]: Api.MenuCategoryModel; };
}

type MenuCategoryColumn = ReactTable.Column
    & { accessor: (d: Api.MenuCategoryModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class MenuCategories extends React.Component<MenuCategoriesProps, {}> {

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get menuCategoriesStore(): Api.MenuCategoryModel[]{
        return _.values(this.props.menuCategories).filter(x => !x.name.includes("(Globale)"))
    }

    public render() {
        return (
            <div>
                <div className="card" style={{...Styles.cardStyle, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                    <div style={{ fontSize: 22, padding: '0.5rem 0 0  1%'}}>{getText( "MenuCatTitle")}</div>
                   
                        <div style={{}}>
                            <Table key={"MenuCategories"}
                                style={{margin: 0, height: "30vh"}}
                                pageSize={this.menuCategoriesStore.length}
                                showPagination={false}
                                showPaginationBottom={false}
                                showPageSizeOptions={false}
                                data={this.menuCategoriesStore}
                                columns={[
                                    {
                                        id: "name",
                                        Header: getText( "MenuCatTitle"),
                                        accessor: (d) => <div style={{padding: 5}}>{d.name}</div>
                                    },
                                    {
                                        id: "actions",
                                        Header: getText( "SettingsActions"),
                                        accessor: (d) => d.menuCategoryId,
                                        Cell: row => <div style={{display: 'flex', justifyContent: 'center'}}> <div style={{
                                            width: 20,
                                            height: 20,
                                            placeSelf: "center", 
                                            backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",                                 
                                            borderRadius: 5,
                                            marginTop: 6,
                                            marginRight: 10
                                            }}>
                                                <i className="glyphicon glyphicon-pencil"
                                                    style={{ margin: "11% 0",  cursor: "pointer", color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}                                                
                                                    onClick={(e) => {
                                                        this.props.UpdateSelectedMenuCategory(row.value);
                                                        this.props.UpdateSelectedMenuCategoryTranslation(undefined);
                                                        this.props.selectTab("menuCategories", "update");
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </div>
                                            {/* <div className="icon-container-dm" style={{
                                                width: 20,
                                                height: 20,
                                                placeSelf: "center", 
                                                backgroundColor: "#007AFF",
                                                cursor: "pointer",
                                                borderRadius: 5,
                                                marginTop: 6
                                                }}>
                                                    <Confirm
                                                        style={{ pointerEvents: "initial", 
                                                            opacity: 1, color: "#FFFFFF", 
                                                            marginTop: '15%',
                                                            marginRight: 1,  }}
                                                        onConfirm={() => {
                                                            this.props.requestDeleteMenuCategory(new Date().getTime(), row.value);
                                                        }}
                                                        body={"Êtes-vous sûre de vouloir supprimer ce menu categorie ?"}
                                                        confirmText="Confirmer" title="Confirmation de supression">
                                                            <i className="glyphicon glyphicon-trash icon-dm"/>
                                                    </Confirm>
                                            </div> */}
                                        </div>                
                                    }
                                ] as Array<MenuCategoryColumn>} />
                        </div>
                        <div style={{}}>
                        <div style={{ fontSize: 22, padding: '0.5rem 0 0  1%'}}>
                                   { getText( "MenuCatSubTitle")}
                            </div>
                            <Table key={"MenuCategoriesTranslations"}
                                style={{margin: 0, height: "20vh"}}
                                pageSize={Math.max(this.props.selectedMenuCategoryId ? this.menuCategoriesStore.find(x => x.menuCategoryId == this.props.selectedMenuCategoryId).menuCategoryTranslations.length : 0, 7)}
                                showPagination={false}
                                showPaginationBottom={false}
                                showPageSizeOptions={false}
                                data={this.props.selectedMenuCategoryId ? this.menuCategoriesStore.find(x => x.menuCategoryId == this.props.selectedMenuCategoryId).menuCategoryTranslations : []}
                                columns={[
                                    {
                                        id: "languageId",
                                        Header: getText( "SettingsTextTransHmiLanguage"),
                                        accessor: (d) => <div style={{padding: 5}}>{this.props.languages[d.languageId].name}</div>
                                    },
                                    {
                                        id: "translation",
                                        Header: getText( "SettingsTextTransHmi"),
                                        accessor: (d) => <div style={{padding: 5, whiteSpace:"break-spaces"}}>{d.translation}</div>
                                    },
                                    {
                                        id: "actions",
                                        Header: getText( "SettingsActions"),
                                        accessor: (d) => d.menuCategoryTranslationId,
                                        Cell: row => <div style={{display: 'flex', justifyContent: 'center'}}> <div style={{
                                            width: 20,
                                            height: 20,
                                            placeSelf: "center", 
                                            backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",                                 
                                            borderRadius: 5,
                                            marginTop: 6,
                                            marginRight: 10
                                            }}>
                                                <i className="glyphicon glyphicon-pencil"
                                                    style={{ margin: "11% 0",  cursor: "pointer", color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}                                                
                                                    onClick={(e) => {
                                                        this.props.UpdateSelectedMenuCategoryTranslation(row.value);
                                                        this.props.selectTab("menuCategoriesTranslation", "update");
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </div>
                                            <div className="icon-container-dm" style={{
                                                width: 20,
                                                height: 20,
                                                placeSelf: "center", 
                                                backgroundColor: "#007AFF",
                                                cursor: "pointer",
                                                borderRadius: 5,
                                                marginTop: 6
                                                }}>
                                                    <Confirm
                                                        style={{ pointerEvents: "initial", 
                                                            opacity: 1, color: "#FFFFFF", 
                                                            marginTop: '15%',
                                                            marginRight: 1,  }}
                                                        onConfirm={() => {
                                                            this.props.requestDeleteMenuCategoryTranslation(new Date().getTime(),this.props.selectedMenuCategoryId, row.value);
                                                        }}
                                                        body={getText( "SettingsTextTransHmiQuestionDel")}
                                                        confirmText={getText("MaintenanceConfirm")} title={getText("MaintenanceConfirmDeleteTitle")}>
                                                            <i className="glyphicon glyphicon-trash icon-dm"/>
                                                    </Confirm>
                                            </div>
                                        </div>                        
                                    }
                                ] as Array<MenuCategoryColumn>} />
                        </div>
                    </div>
               
                <div className='row'>
                    <div className='col-md-5'>
                        <ConnectedTabs name={"menuCategories"}>
                                <div style={{backgroundColor: this.isDarkMode() ? 'rgba(59,67,112, 0.3)' : ''}}>
                                    {/* <TabLink style={{width: '50%'}} to="create">
                                        <div style={tabHeadStyle}>{getText( "MaintenanceCreate")}</div>
                                    </TabLink> */}
                                    <TabLink style={{width: '50%'}} to="update">
                                        <div style={tabHeadStyle}>{getText( "MaintenanceEdit")}</div>
                                    </TabLink>
                                </div>
                                {/* <TabContent for="create">
                                    <div style={{ paddingTop: 5 }}>
                                        <div className="card" style={{...Styles.cardStyle, padding: 5, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                            <CreateMenuCategoryForm actionName={getText( "SettingsTextTransHmiCreate")}
                                                onSubmit={(value) => this.props.requestCreateMenuCategory(new Date().getTime(), value)} />
                                        </div>
                                    </div>
                                </TabContent> */}
                                 <TabContent for="update">
                                    <div style={{ paddingTop: 5 }}>
                                        { this.props.selectedMenuCategoryId && 
                                        <div className="card" style={{...Styles.cardStyle, padding: 5, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                            <UpdateMenuCategoryForm actionName={getText( "SettingsTextHmiSave")}
                                                onSubmit={(value) => this.props.requestUpdateMenuCategory(new Date().getTime(), value)} /> 
                                        </div>
                                        }
                                    </div>
                                </TabContent>
                         </ConnectedTabs>
                    </div>
                    <div className='col-md-7'>
                        { this.props.selectedMenuCategoryId &&
                        <ConnectedTabs name={"menuCategoriesTranslation"}>
                            <div  style={{backgroundColor: this.isDarkMode() ? 'rgba(59,67,112, 0.3)' : ''}}>
                                <TabLink style={{width: '50%'}} to="create">
                                    <div style={tabHeadStyle}>{getText( "MaintenanceCreate")}</div>
                                </TabLink>
                                <TabLink style={{width: '50%'}} to="update">
                                    <div style={tabHeadStyle}>{getText( "MaintenanceEdit")}</div>
                                </TabLink>
                            </div>
                            <TabContent for="create">
                                <div style={{ paddingTop: 5 }}>
                                    <div className="card" style={{...Styles.cardStyle, padding: 5, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                        <CreateMenuCategoryTranslationsForm actionName={getText( "SettingsTextTransHmiCreate")}
                                            onSubmit={(value) => this.props.requestCreateMenuCategoryTranslation(new Date().getTime(), value)} />
                                    </div>
                                </div>
                            </TabContent>
                            <TabContent for="update">
                                <div style={{ paddingTop: 5 }}>
                                    {this.props.selectedMenuCategoryTranslationId
                                        && <div className="card" style={{...Styles.cardStyle, padding: 5, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                            <UpdateMenuCategoryTranslationsForm actionName={getText( "SettingsTextTransHmiSave")}
                                                onSubmit={(value) => this.props.requestUpdateMenuCategoryTranslation(new Date().getTime(), value)} />
                                        </div> 
                                    }
                                </div>
                            </TabContent>
                        </ConnectedTabs>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.menuCategories,
    languages : state.seed.seed.languages,
    menuCategories: state.seed.seed.menuCategories
}), {
        ...MenuCategoriesStore.actionCreators,
        ...TabsStore.actionCreators
})(MenuCategories as any)