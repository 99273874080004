import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from 'react-table';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Table from './Table';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import * as TextTranslationHmiStore from '../store/TextTranslationHmi';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import Confirm from "react-confirm-bootstrap";
import { UpdateTextHmiForm } from "./TextHmiForm";
import * as TabsStore from '../store/Tabs';
import { CreateTextTranslationHmiForm, UpdateTextTranslationHmiForm } from "./TextTranslationHmiForm";


type SettingsTranslationHmiProps = TextTranslationHmiStore.TextTranslationHmiState
    & SettingsTranslationHmiOwnProps
    & typeof TextTranslationHmiStore.actionCreators
    & typeof TabsStore.actionCreators;

interface SettingsTranslationHmiOwnProps {
    languages: { [key: string]: Api.LanguageModel; };
}

type TranslationHmiColumn = ReactTable.Column
    & { accessor: (d: Api.TextHmi) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class SettingsTranslationHmi extends React.Component<SettingsTranslationHmiProps, {}> {
    componentDidMount() {
        this.props.requestTextsHmi(new Date().getTime());
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getTextName(textName: string){
        if(textName == "MaintainanceState")
            return getText( "SettingsTranslationMaintainanceState");
        if(textName == "StoppedState")
            return getText( "SettingsTranslationStoppedState");
        if(textName == "ExpirityState")
            return getText( "SettingsTranslationExpirityState");
        if(textName == "HomeMessage")
            return getText( "SettingsTranslationHomeMessage");
        
        return getText("SettingsTranslationHmi");
    }

    public render() {
        return (
            <div style={{minWidth: 600 }}>
                <div className="card" style={{...Styles.cardStyle, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                    <div style={{ fontSize: 22, padding: '0.5rem 0 0  1%'}}>{getText( "SettingsTabTranslationHmi")}</div>
                   
                        <div style={{}}>
                            <Table key={"TextsHmi"}
                                style={{margin: 0, height: "30vh"}}
                                pageSize={_.values(this.props.textsHmi).length}
                                showPagination={false}
                                showPaginationBottom={false}
                                showPageSizeOptions={false}
                                data={_.values(this.props.textsHmi)}
                                columns={[
                                    {
                                        id: "textName",
                                        Header: getText("SettingsTextHmiName"),
                                        accessor: (d) => <div style={{padding: 5}}>{this.getTextName(d.textName)}</div>
                                    },
                                    {
                                        id: "defaultText",
                                        Header: getText( "SettingsTextHmiDefault"),
                                        accessor: (d) => <div style={{padding: 5, whiteSpace:"break-spaces"}}>{d.defaultText}</div>
                                    },
                                    {
                                        id: "actions",
                                        Header: getText( "SettingsActions"),
                                        accessor: (d) => d.textHmiId,
                                        Cell: row => <div style={{display: 'flex', justifyContent: 'center'}}> <div style={{
                                            width: 20,
                                            height: 20,
                                            placeSelf: "center", 
                                            backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",                                 
                                            borderRadius: 5,
                                            marginTop: 6,
                                            marginRight: 10
                                            }}>
                                                <i className="glyphicon glyphicon-pencil"
                                                    style={{ margin: "11% 0",  cursor: "pointer", color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}                                                
                                                    onClick={(e) => {
                                                        this.props.UpdateSelectedTextHmiId(row.value);
                                                        this.props.UpdateSelectedTextTranslationHmiId(undefined);
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </div>
                                        </div>                
                                    }
                                ] as Array<TranslationHmiColumn>} />
                        </div>
                        <div style={{}}>
                            <div style={{ fontSize: 22, padding: '0.5rem 0 0  1%'}}>
                                {this.props.selectedTextHmiId ? 
                                    `Texte: ${this.getTextName(this.props.textsHmi[this.props.selectedTextHmiId].textName)}` : "Text: "}
                            </div>
                            <Table key={"TextsTranslationsHmi"}
                                style={{margin: 0, height: "20vh"}}
                                pageSize={Math.max(this.props.selectedTextHmiId ? this.props.textsHmi[this.props.selectedTextHmiId].textsTranslations.length : 0, 12)}
                                showPagination={false}
                                showPaginationBottom={false}
                                showPageSizeOptions={false}
                                data={this.props.selectedTextHmiId ? this.props.textsHmi[this.props.selectedTextHmiId].textsTranslations : []}
                                columns={[
                                    {
                                        id: "languageId",
                                        Header: getText( "SettingsTextTransHmiLanguage"),
                                        accessor: (d) => <div style={{padding: 5}}>{this.props.languages[d.languageId].name}</div>
                                    },
                                    {
                                        id: "translation",
                                        Header: getText( "SettingsTextTransHmi"),
                                        accessor: (d) => <div style={{padding: 5, whiteSpace:"break-spaces"}}>{d.translation}</div>
                                    },
                                    {
                                        id: "actions",
                                        Header: getText( "SettingsActions"),
                                        accessor: (d) => d.textTranslationHmiId,
                                        Cell: row => <div style={{display: 'flex', justifyContent: 'center'}}> <div style={{
                                            width: 20,
                                            height: 20,
                                            placeSelf: "center", 
                                            backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",                                 
                                            borderRadius: 5,
                                            marginTop: 6,
                                            marginRight: 10
                                            }}>
                                                <i className="glyphicon glyphicon-pencil"
                                                    style={{ margin: "11% 0",  cursor: "pointer", color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}                                                
                                                    onClick={(e) => {
                                                        this.props.UpdateSelectedTextTranslationHmiId(row.value);
                                                        this.props.selectTab("textTranslationHmi", "update");
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </div>
                                            <div className="icon-container-dm" style={{
                                                width: 20,
                                                height: 20,
                                                placeSelf: "center", 
                                                backgroundColor: "#007AFF",
                                                cursor: "pointer",
                                                borderRadius: 5,
                                                marginTop: 6
                                                }}>
                                                    <Confirm
                                                        style={{ pointerEvents: "initial", 
                                                            opacity: 1, color: "#FFFFFF", 
                                                            marginTop: '15%',
                                                            marginRight: 1,  }}
                                                        onConfirm={() => {
                                                            this.props.requestDeleteTextTranslationHmi(new Date().getTime(), row.value, this.props.selectedTextHmiId);
                                                        }}
                                                        body={getText( "SettingsTextTransHmiQuestionDel")}
                                                        confirmText={getText( "MaintenanceConfirm")} title={getText( "MaintenanceConfirmDeleteTitle")}>
                                                            <i className="glyphicon glyphicon-trash icon-dm"/>
                                                    </Confirm>
                                            </div>
                                        </div>                        
                                    }
                                ] as Array<TranslationHmiColumn>} />
                        </div>
                    </div>
               
                <div>
                    {/* <div>
                        { this.props.selectedTextHmiId &&
                            <UpdateTextHmiForm actionName={getText( "SettingsTextHmiSave")}
                                onSubmit={(value) => this.props.requestUpdateTextHmi(new Date().getTime(), value)} />
                        }
                    </div> */}
                    <div>
                        { this.props.selectedTextHmiId &&
                        <ConnectedTabs name={"textTranslationHmi"}>
                            <div  style={{backgroundColor: this.isDarkMode() ? 'rgba(59,67,112, 0.3)' : ''}}>
                                <TabLink style={{width: '50%'}} to="create">
                                    <div style={tabHeadStyle}>{getText( "MaintenanceCreate")}</div>
                                </TabLink>
                                <TabLink style={{width: '50%'}} to="update">
                                    <div style={tabHeadStyle}>{getText( "MaintenanceEdit")}</div>
                                </TabLink>
                            </div>
                            <TabContent for="create">
                                <div style={{ paddingTop: 5 }}>
                                    <div className="card" style={{...Styles.cardStyle, padding: 5, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                        <CreateTextTranslationHmiForm actionName={getText( "SettingsTextTransHmiCreate")}
                                            onSubmit={(value) => {
                                                this.props.requestCreateTextTranslationHmi(new Date().getTime(), value);
                                            }} />
                                    </div>
                                </div>
                            </TabContent>
                            <TabContent for="update">
                                <div style={{ paddingTop: 5 }}>
                                    {this.props.selectedTextTranslationHmiId
                                        && <div className="card" style={{...Styles.cardStyle, padding: 5, background: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}>
                                            <UpdateTextTranslationHmiForm actionName={getText( "SettingsTextTransHmiSave")}
                                                onSubmit={(value) => this.props.requestUpdateTextTranslationHmi(new Date().getTime(), value)} />
                                        </div> 
                                    }
                                </div>
                            </TabContent>
                        </ConnectedTabs>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.textTranslationHmi,
    languages : state.seed.seed.languages
}), {
        ...TextTranslationHmiStore.actionCreators,
        ...TabsStore.actionCreators
    })(SettingsTranslationHmi as any)