import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import * as ReorderStore from "../store/Reorder";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as ReactTable from "react-table";
import Table from "./Table";
import { canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type SupplierOrderFormProps = ReduxForm.InjectedFormProps<Api.SupplierOrderModel, {}, {}>
    & SupplierOrderFormOwnProps
    & SupplierOrderFormExternalProps;

interface SupplierOrderFormExternalProps {
    onSubmit: (values: Api.SupplierOrderModel) => any;
}

interface SupplierOrderFormOwnProps {
    seed: Api.SeedMngModel;
    disabled: boolean;
    supplierOrderProductSuppliers: Array<Api.SupplierOrderProductSupplierModel>;
}

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 10
};
const labelStyle: React.CSSProperties = {
    flex: "0 0 auto",
    minWidth: 150,
    textAlign: "right",
    marginRight: 6
};
const inputStyle: React.CSSProperties = {
    flex: 1,
};

class SupplierOrderForm extends React.Component<SupplierOrderFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-vertical">
                <ReduxForm.FieldArray
                    validate={[FormField.requiredOne]}
                    name="supplierOrderProductSuppliers"
                    component={SupplierOrderProductSuppliers}
                    props={{
                        seed: this.props.seed,
                        supplierOrderProductSuppliers: this.props.supplierOrderProductSuppliers,
                        disabled: this.props.disabled
                    } as SupplierOrderProductSupplierOwnProps} />
            </FormField.BaseForm>
        );
    }
}

type SupplierOrderProductSupplierProps =
    SupplierOrderProductSupplierOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.SupplierOrderModel>;

interface SupplierOrderProductSupplierOwnProps {
    seed: Api.SeedMngModel;
    supplierOrderProductSuppliers: Array<Api.SupplierOrderProductSupplierModel>;
    disabled: boolean;
}

type SupplierOrderProductSupplierColumn = ReactTable.Column
    & {
    accessor: (d: { member: string; data: Api.SupplierOrderProductSupplierModel }) => any;
    };

export default class SupplierOrderProductSuppliers extends React.Component<SupplierOrderProductSupplierProps, {}> {

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get getData(): Array<{ member: string; data: Api.SupplierOrderProductSupplierModel }> {
        return this.props.fields.map((member, index) => ({
            member: member,
            data: this.props.supplierOrderProductSuppliers[index]
        }));
    }

    get totalOrder(): number {
        return _.sum(_.map(this.getData, d => d.data ?
            (Math.round(d.data.packQuantity  * this.props.seed.products[d.data.productId].productSuppliers
                .find(x => x.productSupplierId === d.data.productSupplierId).buyPriceNoVat * 100) / 100)
            : 0
        ));
    }

    public render() {
        return (
            <div>
                
                <div className="row" style={{margin: 0}}>
                    <div className="form-group" style={{ marginBottom: 9, textAlign: 'start', display: 'flex', placeContent: 'center' }}>
                        <div>
                            <label className="control-label">{getText( "ReorderModalName")}: </label>
                            <ReduxForm.Field
                                style= {{
                                    color: this.isDarkMode() ? "#FFFFFF" : "#3B4370",
                                }}
                                className="form-control"
                                name={`name`}
                                type={"text"}
                                validate={[FormField.required]}
                                component={FormField.renderField} />
                        </div> 
                        <div style={{ float: 'left', margin: '5px 40px', alignSelf: 'center' }}>
                            <div>
                                <strong>
                                    <span style={{ marginRight: '5% 10px' }}>{getText("ReorderModalTotal")}: </span>
                                    {this.totalOrder.toFixed(2)}
                                </strong>
                            </div>
                        </div>
                        <div style={{ alignSelf: 'center' }}>
                            <button className="btn btn-primary"
                                style={{
                                    backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF", 
                                    color: this.isDarkMode() ? "#181F3A" : "#FFFFFF",
                                    borderColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",
                                    fontSize: 13, 
                                    fontWeight: 'bold', 
                                    width: 250, 
                                    float: 'right'
                                }}
                                disabled={this.props.disabled}
                                type={"submit"}>
                                {getText( "ReorderModalCreate")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-md-12">
                        <Table
                            style={{
                                height: '30vh', marginBottom: 10
                            }}
                            showPagination={false}
                            showPaginationBottom={false}
                            key={"supplierOrderProductSuppliers"}
                            pageSize={Math.max(this.getData.filter(x => x.data).length, 6)}
                            data={this.getData.filter(x => x.data).sort((a, b) => this.props.seed.products[a.data.productId].name.localeCompare(this.props.seed.products[b.data.productId].name))}
                            columns={[
                                {
                                    id: "productId",
                                    minWidth: 250,
                                    style: {width: '100%', textAlign: 'left'},
                                    accessor: d => d.data.productId,
                                    Header: <div style={{width: '100%'}}>{getText("ReorderModalProductTable")}</div>,
                                    Cell: row => <div style={{width: '100%'}}>{this.props.seed.products[row.value].name}</div>
                                },
                                {
                                    id: "productSupplierId",
                                    width: 80,
                                    accessor: d => d.data,
                                    Header: <div>{getText("ReorderSource")}</div>,
                                    Cell: row => {                                        
                                        let data = row.value as Api.SupplierOrderProductSupplierModel;
                                        let productSupplier = this.props.seed.suppliers[ReorderStore.getProductSupplier(data.productId, this.props.seed.products).supplierMainId].name;
                                        return <div>{productSupplier}</div>;
                                    }
                                },
                                {
                                    id: "pack",
                                    width: 95,
                                    accessor: d => d.data,
                                    Header: <div>{getText("ReorderPackCond")}</div>,
                                    Cell: row => {
                                        let data = row.value as Api.SupplierOrderProductSupplierModel;
                                        let productSupplier = this.props.seed.products[data.productId].productSuppliers
                                            .find(x => x.productSupplierId === data.productSupplierId);
                                        return <div>{productSupplier.packCondition}</div>;
                                    }
                                },
                                {
                                    id: "packPrice",
                                    width: 90,
                                    accessor: d => d.data,
                                    Header: <div>{getText("ReorderPATHPack")}</div>,
                                    Cell: row => {
                                        let data = row.value as Api.SupplierOrderProductSupplierModel;
                                        let productSupplier = this.props.seed.products[data.productId].productSuppliers
                                            .find(x => x.productSupplierId === data.productSupplierId);
                                        return <div>{productSupplier.buyPriceNoVat} {this.props.seed.currencies[productSupplier.buyPriceCurrencyId].symbol}</div>;
                                    }
                                },
                                {
                                    id: "quantity",
                                    width: 80,
                                    accessor: d => d,
                                    Header: <div>{getText("ReorderQtyPackTable")}</div>,
                                    Cell: row => {
                                        let data = row.value as { member: string; data: Api.SupplierOrderProductSupplierModel };
                                        return (
                                            <div>
                                                <ReduxForm.Field
                                                    name={`${data.member}.packQuantity`}
                                                    style={{ width: 50, alignContent: 'center', textAlign: 'center' }}
                                                    validate={[FormField.requiredOrZero]}
                                                    component={FormField.renderField} />
                                            </div>
                                        );
                                    }
                                },
                                {
                                    id: "total",
                                    width: 85,
                                    accessor: d => d.data,
                                    Header: <div>{getText("ReorderModalTotal")}</div>,
                                    Cell: row => {
                                        let data = row.value as Api.SupplierOrderProductSupplierModel;
                                        let productSupplier = this.props.seed.products[data.productId].productSuppliers
                                            .find(x => x.productSupplierId === data.productSupplierId);
                                        return (
                                            <div>
                                                {(Math.round(data.packQuantity * productSupplier.buyPriceNoVat * 100) / 100).toFixed(2)}
                                                {" "}
                                                {this.props.seed.currencies[productSupplier.buyPriceCurrencyId].symbol}
                                            </div>
                                        );
                                    }
                                }
                            ] as Array<SupplierOrderProductSupplierColumn>} />
                    </div>
                </div>
            </div>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createSupplierOrder',
    enableReinitialize: true,
    destroyOnUnmount: true
})(SupplierOrderForm as any) as any;
const createSelector = ReduxForm.formValueSelector('createSupplierOrder');
export const CreateSupplierOrderForm = connect((state: ApplicationState) => {
    let productsDataCompleted = ReorderStore.productsDataCompletedSelector(state);

    return {
        initialValues: {
            supplierOrderProductSuppliers: ReorderStore.reorderDataSelector(state)
                .map(x => ({
                    productId: x.storeItemId,
                    productSupplierId: ReorderStore.getProductSupplier(x.storeItemId, state.seed.seed.products).productSupplierId,
                    packQuantity: state.reorder.productsData[x.storeItemId]
                        ? state.reorder.productsData[x.storeItemId].toOrder
                        : ReorderStore.getToOrder(
                            x,
                            state.reorder.filter,
                            state.supplierOrder.supplierOrders,
                            state.reorder.productsData,
                            state.seed.seed.products
                        )
                } as Api.SupplierOrderProductSupplierModel)).filter(x => 0 < x.packQuantity)
        } as Api.SupplierOrderModel,
        seed: state.seed.seed,
        supplierOrderProductSuppliers: createSelector(state, "supplierOrderProductSuppliers") || [],
        disabled: !canUpdate(state)
    } as SupplierOrderFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<SupplierOrderFormExternalProps>;