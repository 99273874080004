import * as React from "react";
import * as VoucherStore from '../store/Voucher';
import * as Styles from '../styles/baseStyle';
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import VoucherCurrent from "./VoucherCurrent";
import VoucherHistory from "./VoucherHistory";
import HelpHeader from "./HelpHeader";
import { getText } from '../utils/texts';

type VoucherProps = VoucherOwnProps
    & VoucherStore.VoucherState
    & typeof VoucherStore.actionCreators;

interface VoucherOwnProps {

}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class Voucher extends React.Component<VoucherProps, {}> {

    componentDidMount() {
        this.props.requestVouchers(new Date().getTime());
    }

    public render() {
        return (
            <div>
                <ConnectedTabs name={"voucher"}>
                    <div className="card" style={{ ...Styles.cardStyle,  ...Styles.pageTitle}}>
                        <div style={{paddingRight: '2%'}}>
                            {getText( "VoucherTitle")}
                        </div>
                        <TabLink style={{width: '50%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}} to="current">
                            <div style={tabHeadStyle}>{getText( "VoucherCurrent")}</div>
                        </TabLink>
                        <TabLink style={{width: '50%', padding: '0.2rem 0', fontSize: 14}} to="history">
                            <div style={tabHeadStyle}>{getText( "VoucherHisto")}</div>
                        </TabLink>
                        <HelpHeader />
                    </div>
                    <TabContent for="current">
                        <VoucherCurrent />
                    </TabContent>
                    <TabContent for="history">
                        <VoucherHistory />
                    </TabContent>
                </ConnectedTabs>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.voucher
}), VoucherStore.actionCreators)(Voucher as any);