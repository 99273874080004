import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as RobotStore from '../store/Robot';
import * as HmiApplicationStore from '../store/HmiApplication';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import Checkbox from "./CheckBox";
import DialogModal from "./DialogModal";
import RobotAnimation from "./RobotAnimation";
import RobotIndicators from "./RobotIndicators";
import RobotCoordCalculator from "./RobotCoordCalculator";
import RobotSimulationForm from "./RobotSimulationForm";
import { canEditSettings, canUpdate } from '../security/roles';
import { getText } from '../utils/texts';
import RobotExtractors from './RobotExtractors';

type RobotControlProps = RobotStore.RobotState
    & RobotControlOwnProps
    & RobotControlExternalProps
    & typeof RobotStore.actionCreators;

interface RobotControlOwnProps {
    hmiStates: { [id: number]: HmiApplicationStore.HmiState };
    stockStatus: Api.StockStatusModel;
    disabled: boolean;
}

interface RobotControlExternalProps {
    robotId: number;
}

const btnStyle: React.CSSProperties = { backgroundColor: "rgba(59, 67, 112, 0.25)", height: 30, fontSize: 14 };
const titleStyle: React.CSSProperties = { fontWeight: "bold", fontSize: 16, paddingRight: '2%' };
const subTitleStyle: React.CSSProperties = {fontWeight: "bold", fontSize: 14, paddingRight: '2%', 
    color: 'rgba(59, 67, 112, 0.6)', textAlign: 'center'};
const btnShevlingStyle: React.CSSProperties = { height: 12, width: 12, fontSize: 10 };

class RobotControl extends React.Component<RobotControlProps, {}> {

    get disabled(): boolean {
        return (_.values(this.props.hmiStates).length > 0
            && _.values(this.props.hmiStates)
                .some(x => !x.applicationState
                    || x.applicationState.applicationState === "Running"
                    || x.applicationState.applicationState === "Selling"))
            || !this.props.state
            || this.props.disabled;
    }

    isCommandLoading(command: string): boolean {
        return this.props.robotCommandStates[command]
            && this.props.robotCommandStates[command].isLoading;
    }

    isCoordValid(x: number, z: number): boolean {
        // var xMax = this.robot.robotConfiguration.deliveryX + 20;
        // var zMax = this.props.state.version == "modbus 1.0" ? -40 : -100;
        // if(x <= -1 && x >= xMax && z <= zMax && z >= -1600)
        //     return true;
        // return false;
        return true;
    }

    get robot(): Api.RobotModel {
        return this.props.robotsState.robots
            .find(x => x.robotId === this.props.robotId);
    }

    get robotData(): Api.RobotData {
        return this.props.state
            ? this.props.state.robotData
            : {};
    }


    public render() {
        return (
            <>
            <div className="preloader" style={{display: (this.isCommandLoading("initTotal") || this.isCommandLoading("initPartial")) ? "block" : "none"}}></div>
            <div className="robot-control"  
              style={{ pointerEvents: (this.isCommandLoading("initTotal") || this.isCommandLoading("initPartial")) ? "none" : "initial", 
              opacity: (this.isCommandLoading("initTotal") || this.isCommandLoading("initPartial")) ? 0.5 : 1}}>
                <div className="card" style={{...Styles.cardStyle, padding: '0.5rem 1%'}}>
                    <div style={{fontWeight: "bold", fontSize: 22}}>{getText( "MaintenanceUnitTest")}</div>
                    <div style={{
                        display: "grid",
                        gridTemplateRows: "auto 1fr 100px 100px 1fr",
                        gridTemplateColumns: "180px 180px 1fr 1fr 1fr"
                    }}>
                        <div style={{ gridRow: "1 / 2", gridColumn: "1 / 6" }}>
                            {this.props.state && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 12 }}>
                                    <div style={{ minWidth: 120, textAlign: "right", marginRight: 6 }}>{getText( "MaintenanceLastName")} : </div>
                                    <div>{this.robot.name}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 12 }}>
                                    <div style={{ minWidth: 120, textAlign: "right", marginRight: 6 }}>{getText( "MaintenanceVersion")} : </div>
                                    <div>{this.robot.version}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 12 }}>
                                    <div style={{ minWidth: 120, textAlign: "right", marginRight: 6 }}>{getText( "MaintenanceStatusInit")} : </div>
                                    <div>{this.props.state.initStatus}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 12 }}>
                                    <div style={{ minWidth: 120, textAlign: "right", marginRight: 6 }}>{getText( "MaintenanceStatus")} : </div>
                                    <div>{this.props.state.status}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 12 }}>
                                    <div style={{ minWidth: 120, textAlign: "right", marginRight: 6 }}>{getText( "MaintenancePosition")} : </div>
                                    <div>{this.props.state.position}</div>
                                </div>
                            </div>}
                        </div>
                        <div style={{
                            gridRow: "2 / 4",
                            gridColumn: "1 / 2",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{ ...titleStyle, textAlign: 'left' }}>{getText( "MaintenanceRobotSAS")}</div>
                            <div style={{
                                borderRadius: 9,
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch"
                            }}>
                                <div style={subTitleStyle}>{getText( "MaintenanceLock")}</div>
                                <div style={{
                                    borderRadius: 9,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    padding: '0 5px'
                                }}>
                                    <button style={btnStyle}
                                        disabled={this.disabled
                                            || this.isCommandLoading("unlockInsideDoor")}
                                        onClick={() => this.props.requestRobotUnLockInsideDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceOpening")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled
                                            || this.isCommandLoading("lockInsideDoor")}
                                        onClick={() => this.props.requestRobotLockInsideDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceClosing")}
                                    </button>
                                </div>
                                <div style={subTitleStyle}>{getText( "MaintenanceDoor")}</div>
                                <div style={{
                                    borderRadius: 9,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    padding: '0 5px'
                                }}>
                                    <button style={btnStyle}
                                        disabled={this.disabled
                                            || this.robotData.isSasDoorOpen
                                            || this.isCommandLoading("openInsideDoor")}
                                        onClick={() => this.props.requestRobotOpenInsideDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceOpening")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled
                                            || !this.robotData.isSasDoorOpen
                                            || this.isCommandLoading("closeInsideDoor")}
                                        onClick={() => this.props.requestRobotCloseInsideDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceClosing")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            gridRow: "4 / 6",
                            gridColumn: "1 / 2",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{ ...titleStyle, textAlign: 'left' }}>{getText( "MaintenanceDelivery")}</div>
                            <div style={{
                                borderRadius: 9,
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch"
                            }}>
                                <div style={{...subTitleStyle}}>{getText( "MaintenanceLock")}</div>
                                <div style={{
                                    borderRadius: 9,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    padding: '0 5px'
                                }}>
                                    <button style={btnStyle}
                                        disabled={this.disabled
                                            || this.isCommandLoading("unlockDoor")}
                                        onClick={() => this.props.requestRobotUnLockDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceOpening")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled
                                            || this.isCommandLoading("lockDoor")}
                                        onClick={() => this.props.requestRobotLockDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceClosing")}
                                    </button>
                                </div>
                                <div style={{...subTitleStyle}}>{getText( "MaintenanceDoor")}</div>
                                <div style={{
                                    borderRadius: 9,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    justifyContent: 'flex-end',
                                    padding: '0 5px'
                                }}>
                                    <button style={btnStyle}
                                        disabled={this.disabled
                                            || this.robotData.isDeliveryDoorOpen
                                            || this.isCommandLoading("openDoor")}
                                        onClick={() => this.props.requestRobotOpenDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceOpening")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled
                                            || !this.robotData.isDeliveryDoorOpen
                                            || this.isCommandLoading("closeDoor")}
                                        onClick={() => this.props.requestRobotCloseDoor(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceClosing")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            gridRow: "2 / 4",
                            gridColumn: "2 / 3",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{ ...titleStyle, textAlign: 'left' }}>{getText( "MaintenanceMovements")}</div>
                            <div style={{
                                    borderRadius: 9,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    padding: '0 5px'
                                }}>
                                    <button style={btnStyle}
                                        disabled={this.disabled || this.isCommandLoading("toCoord")}
                                        onClick={() => this.props.requestRobotToCoord(new Date().getTime(), {
                                            lane: 1,
                                            x: this.robot.robotConfiguration.idleX,
                                            z: this.robot.robotConfiguration.idleZ
                                        })}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceRestPoint")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled || this.isCommandLoading("toCoord")}
                                        onClick={() => this.props.requestRobotToCoord(new Date().getTime(), {
                                            lane: 1,
                                            x: this.robot.robotConfiguration.deliveryX + 200, //v6 add 200 to this
                                            z: this.robot.robotConfiguration.deliveryZ
                                        })}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceDeliveryPoint2")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled || this.isCommandLoading("toCoord")}
                                        onClick={() => this.props.requestRobotToCoord(new Date().getTime(), {
                                            lane: 1,
                                            x: this.robot.robotConfiguration.deliveryX,
                                            z: this.robot.robotConfiguration.deliveryZ
                                        })}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceDeliveryPoint")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={this.disabled
                                            || this.isCommandLoading("toCoord")
                                            || !this.isCoordValid(this.props.coordToSend.x, this.props.coordToSend.z)}
                                        onClick={() => this.props.requestRobotToCoord(new Date().getTime(), {
                                            lane: 1,
                                            x: this.props.coordToSend.x,
                                            z: this.props.coordToSend.z
                                        })}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceToSend")}
                                    </button>
                                    <button style={{...btnStyle, marginTop: '3%'}}
                                        disabled={false}
                                        onClick={() => { this.props.openRailCoordDialog(); }}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceCalculate")}
                                    </button>
                                </div>
                        </div>
                        <div style={{
                            gridRow: "4 / 6",
                            gridColumn: "2 / 3",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{ ...titleStyle, textAlign: 'left', margin: '5% 0' }}>
                                {getText( "MaintenancePositionOf")} X
                            </div>
                            <input style={{ width: '90%', marginLeft: '5%' }}
                                className="form-control" type="text" disabled={true} 
                                value={this.props.state ? this.props.state.robotData.coord.x : 0} />
                            <div style={{ ...titleStyle, textAlign: 'left', margin: '5% 0' }}>{getText( "MaintenancePositionOf")} Z</div>
                            <input style={{ width: '90%', marginLeft: '5%' }}
                                className="form-control" type="text" disabled={true} 
                                value={this.props.state ? this.props.state.robotData.coord.z : 0} />
                        </div>
                        <div style={{
                            gridRow: "2 / 3",
                            gridColumn: "3 / 4",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{ ...titleStyle, textAlign: 'left' }}>{getText( "MaintenanceDeliveryMat")}</div>
                            <div style={{
                                borderRadius: 9,
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "stretch",
                                padding: '0 5px'
                            }}>
                                <div style={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: '5%' }}
                                        onClick={(e) => this.props.updateDeliveryBeltDestination("DeliveryDoor")}>
                                        <input style={{ margin: "0px 6px" }} type="radio" checked={this.props.deliveryBeltDirection === "DeliveryDoor"} readOnly />
                                        <div style={{fontSize: 12}}>{getText( "MaintenanceDirectionDelivery")}</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: '13%' }}
                                        onClick={(e) => this.props.updateDeliveryBeltDestination("Trash")}>
                                        <input style={{ margin: "0px 6px" }} type="radio" checked={this.props.deliveryBeltDirection === "Trash"} readOnly/>
                                        <div style={{fontSize: 12}}>{getText( "MaintenanceDirectionDLC")}</div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <button style={{ ...btnStyle, margin: "3px 0px" }}
                                        disabled={this.disabled
                                            || !this.robotData.isSasDoorOpen
                                            || (this.robotData.deliveryBeltState ? true : false)
                                            || this.isCommandLoading("runDeliveryBelt")}
                                        onClick={() => this.props.requestRobotRunDeliveryBelt(new Date().getTime(), this.props.deliveryBeltDirection)}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceIHMMarket")}
                                    </button>
                                    <button style={{ ...btnStyle, margin: "3px 0px" }}
                                        disabled={this.disabled
                                            || (this.robotData.deliveryBeltState ? false : true)
                                            || this.isCommandLoading("stopDeliveryBelt")}
                                        onClick={() => this.props.requestRobotStopDeliveryBelt(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceIHMStop")}
                                    </button>
                                </div>
                            </div>                            
                        </div>
                        <div style={{ 
                            gridRow: "3 / 5",
                            gridColumn: "3 / 5", 
                            position: "relative" }}>
                                <RobotAnimation
                                    robot={this.robot}
                                    roobotData={this.robotData} />
                        </div>
                        <div style={{
                            gridRow: "5 / 6",
                            gridColumn: "3 / 5",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{
                                borderRadius: 9,
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "stretch",
                                padding: '0 5px'
                            }}>
                                <div style={{ flex: 2, display: "flex", flexDirection: "column", 
                                    alignItems: "stretch", justifyContent: 'flex-end', marginLeft: '30%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ ...titleStyle, textAlign: 'left', fontSize: 14 }}>
                                            {getText( "MaintenancePositionOf")} X
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input style={{ width: 100 }} className="form-control" type="text"
                                            pattern="^([-+]?[0-9]+[-,])*[+-]?[0-9]+$"
                                            onChange={(e) => {
                                                this.props.updateCoordTextToSend({
                                                    ...this.props.coordToSendText,
                                                    x: e.target.value
                                                });
                                                this.props.updateCoordToSend({
                                                    ...this.props.coordToSend,
                                                    x: parseInt(e.target.value) ? parseInt(e.target.value) : 0
                                                });
                                            }}
                                            value={this.props.coordToSendText.x} />
                                    </div>
                                </div>
                                <div style={{ flex: 1, display: "flex", flexDirection: "column", 
                                    alignItems: "stretch", justifyContent: 'flex-end'}}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ ...titleStyle, textAlign: 'left', fontSize: 14 }}>
                                            {getText( "MaintenancePositionOf")} Z
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input style={{ width: 100 }} className="form-control" type="text"
                                            pattern="^([-+]?[0-9]+[-,])*[+-]?[0-9]+$"
                                            onChange={(e) => {
                                                this.props.updateCoordTextToSend({
                                                    ...this.props.coordToSendText,
                                                    z: e.target.value
                                                });
                                                this.props.updateCoordToSend({
                                                    ...this.props.coordToSend,
                                                    z: parseInt(e.target.value) ? parseInt(e.target.value) : 0
                                                });
                                            }}
                                            value={this.props.coordToSendText.z} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            gridRow: "2 / 3",
                            gridColumn: "4 / 5",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <div style={{ ...titleStyle, textAlign: 'left' }}>{getText( "MaintenanceAcucumulation")}</div>
                            <div style={{
                                borderRadius: 9,
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "stretch",
                                padding: '0 5px'
                            }}>
                                <div style={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: '5%' }}
                                        onClick={(e) => this.props.updateAccumulatorBeltDestination("DeliveryDoor")}>
                                        <input style={{ margin: "0px 6px" }} type="radio" checked={this.props.accumulatoryBeltDirection === "DeliveryDoor"} readOnly/>
                                        <div style={{fontSize: 12}}>{getText( "MaintenanceDirectionDelivery")}</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: '13%' }}
                                        onClick={(e) => this.props.updateAccumulatorBeltDestination("Accumulator")}>
                                        <input style={{ margin: "0px 6px" }} type="radio" checked={this.props.accumulatoryBeltDirection === "Accumulator"} readOnly/>
                                        <div style={{fontSize: 12}}>{getText( "MaintenanceDirectionAccumulation")}</div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <button style={{ ...btnStyle, margin: "3px 0px" }}
                                        disabled={this.disabled
                                            || (this.robotData.accumulatorState ? true : false)
                                            || this.isCommandLoading("runAccumulatorBelt")}
                                        onClick={() => this.props.requestRobotRunAccumulatorBelt(new Date().getTime(), this.props.accumulatoryBeltDirection)}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceIHMMarket")}
                                    </button>
                                    <button style={{ ...btnStyle, margin: "3px 0px" }}
                                        disabled={this.disabled
                                            || (this.robotData.accumulatorState ? false : true)
                                            || this.isCommandLoading("stopAccumulatorBelt")}
                                        onClick={() => this.props.requestRobotStopAccumulatorBelt(new Date().getTime())}
                                        className="btn btn-sm btn-secondary">
                                        {getText( "MaintenanceIHMStop")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            gridRow: "5 / 6",
                            gridColumn: "5 / 6",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            justifyContent: "flex-end"
                        }}>
                            <RobotIndicators robotData={this.robotData} robotState={this.props.state} />
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: "stretch"}}>
                                <button style={{ ...btnStyle, margin: "3px 3px", width: '50%' }}
                                    disabled={this.disabled
                                        || this.isCommandLoading("initTotal")}
                                    onClick={() => this.props.requestInitRobotTotal(new Date().getTime())}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceGeneralInit")}
                                </button>
                                <button style={{ ...btnStyle, margin: "3px 3px", width: '50%' }}
                                    disabled={this.disabled || this.isCommandLoading("initPartial")}
                                    onClick={() => this.props.requestInitRobotPartial(new Date().getTime())}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceRobotInit")}
                                </button>
                                {/* <button style={{ ...btnStyle, margin: "3px 3px", width: '50%' }}
                                    disabled={this.disabled || this.isCommandLoading("initMW232")}
                                    onClick={() => this.props.requestInitMW232(new Date().getTime())}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceInitMW232")}
                                </button> */}
                            </div>
                        </div> 
                        <div style={{
                            gridRow: "2 / 5",
                            gridColumn: "5 / 6",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch"
                        }}>
                            <RobotExtractors
                                robotId={this.props.robotId}
                                disabled={this.disabled}
                            />
                        </div>
                        
                        {/* <div style={{
                            gridRow: "6 / 7",
                            gridColumn: "2 / 4",
                            gridGap: 3,
                            display: "grid",
                            gridTemplateRows: "1fr 1fr 1fr",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                        }}>
                            
                            <div style={{ gridRow: "1 / 2", gridColumn: "2 / 3" }}>{"Position en X"}</div>
                            <div style={{ gridRow: "2 / 3", gridColumn: "2 / 3" }}>
                                {this.props.state
                                    && <input style={{ width: 100, backgroundColor: "black", color: "white" }}
                                        className="form-control" type="text" disabled={true} value={this.props.state.robotData.coord.x} />}
                            </div>
                            <div style={{ gridRow: "1 / 2", gridColumn: "3 / 4" }}>{"Position en Z"}</div>
                            <div style={{ gridRow: "2 / 3", gridColumn: "3 / 4" }}>
                                {this.props.state
                                    && <input style={{ width: 100, backgroundColor: "black", color: "white" }}
                                        className="form-control" type="text" disabled={true} value={this.props.state.robotData.coord.z} />}
                            </div>
                            <div style={{ gridRow: "1 / 2", gridColumn: "4 / 7", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ flex: 1, border: "1px solid", backgroundColor: "yellow" }}>
                                    etat robot? etat init?
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    
                                </div>
                            </div>
                           
                        </div> */}
                        <DialogModal contentLabel={"Calculate coords"}
                            onRequestClose={() => this.props.closeRailCoordDialog()}
                            contentStyle={{}}
                            isOpen={this.props.railCoordState.isOpen}>
                            <RobotCoordCalculator stockStatus={this.props.stockStatus}
                                railCoordState={this.props.railCoordState}
                                copyRobotCoord={() => this.props.copyRobotCoord()}
                                requestRailCoordToRobotCoord={(time) => this.props.requestRailCoordToRobotCoord(time)}
                                setRailCoord={(coord) => this.props.setRailCoord(coord)}
                            />
                        </DialogModal>
                    </div>
                </div>
                <div className="card" style={{...Styles.cardStyle, marginTop: 10, padding: '0 1% 6px'}}>
                    <div style={{fontWeight: "bold", fontSize: 22}}>{getText( "MaintenanceSimulation")}</div>
                    <div>
                        <RobotSimulationForm
                            disabled={this.props.disabled}
                            onSubmit={(model) => this.props.requestStartSimulation(new Date().getTime(), model)}
                            stopSimulation={() => this.props.requestStopSimulation(new Date().getTime())} />
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.robot,
    hmiStates: state.hmiApplication.hmiStates,
    stockStatus: state.stock.stockStatus,
    disabled: !canEditSettings(state)
} as RobotControlOwnProps), {
        ...RobotStore.actionCreators
    })(RobotControl as any) as any as React.ComponentClass<RobotControlExternalProps>;