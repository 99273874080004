import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';


export interface TextTranslationHmiState {
    isLoading: boolean;
    requestTime?: number;
    selectedTextHmiId?: number;
    selectedTextTranslationHmiId?: number;
    textsHmi: { [id: number]: Api.TextHmiModel};
    textTranslationCreateState: {
        isLoading: boolean;
        requestTime?: number;
    };
    textTranslationUpdateStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
    textHmiUpdateStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    };
    textTranslationDeleteStates: {
        [id: number]: {
            isLoading: boolean;
            requestTime?: number;
        }
    }
}

interface TextTranslationRequestEntities {
    type: "TEXTS_TRAN_REQUEST_ENTITIES";
    payload: { requestTime: number }
}
interface TextTranslationReceiveEntities {
    type: "TEXTS_TRAN_RECEIVE_ENTITIES";
    payload: {
        requestTime: number;
        textsHmi?: { [id: number]: Api.TextHmiModel };
    },
    error?: any
}

interface UpdateSelectedTextHmiId {
    type: "TEXTHMI_UPDATE_SELECTED_TEXTHMIID";
    payload: { id: number; }
}
interface UpdateSelectedTextTranslationHmiId {
    type: "UPDATE_SELECTED_TEXTTRANSTALTIONHMITID";
    payload: { id: number; }
}

interface TextHmiRequestUpdate {
    type: "TEXTHMI_REQUEST_UPDATE";
    payload: { requestTime: number; id: number; }
}
interface TextHmiReceiveUpdate {
    type: "TEXTHMI_RECEIVE_UPDATE";
    payload: { requestTime: number; id: number; value?: Api.TextHmiModel }
    error?: any
}

interface TextTranslationRequestCreate {
    type: "TEXTTRANS_REQUEST_CREATE";
    payload: { requestTime: number; }
}
interface TextTranslationReceiveCreate {
    type: "TEXTTRANS_RECEIVE_CREATE";
    payload: { requestTime: number; value?: Api.TextTranslationHmiModel }
    error?: any
}

interface TextTranslationRequestUpdate {
    type: "TEXTTRANS_REQUEST_UPDATE";
    payload: { requestTime: number; id: number; }
}
interface TextTranslationReceiveUpdate {
    type: "TEXTTRANS_RECEIVE_UPDATE";
    payload: { requestTime: number; id: number; value?: Api.TextTranslationHmiModel }
    error?: any
}

interface TextTranslationRequestDelete {
    type: "TEXTTRANS_REQUEST_DELETE";
    payload: { requestTime: number; id: number; }
}
interface TextTranslationReceiveDelete {
    type: "TEXTTRANS_RECEIVE_DELETE";
    payload: { requestTime: number; id: number; idTextHmi: number}
    error?: any
}

type KnownAction = TextTranslationRequestEntities
    | TextTranslationReceiveEntities
    | UpdateSelectedTextHmiId
    | UpdateSelectedTextTranslationHmiId
    | TextHmiRequestUpdate
    | TextHmiReceiveUpdate
    | TextTranslationRequestCreate
    | TextTranslationReceiveCreate
    | TextTranslationRequestUpdate
    | TextTranslationReceiveUpdate
    | TextTranslationRequestDelete
    | TextTranslationReceiveDelete;

export const actionCreators = {
    requestTextsHmi: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TextTranslationHmiApi();
        let fetchTask = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(textsHmi => {
                dispatch({
                    type: "TEXTS_TRAN_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime, textsHmi: textsHmi }
            });
        })
            .catch(err => {
                dispatch({
                    type: "TEXTS_TRAN_RECEIVE_ENTITIES",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "TEXTS_TRAN_REQUEST_ENTITIES",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateTextHmi: (requestTime: number, model: Api.TextHmiModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TextTranslationHmiApi();        
        let fetchTask = api.updateDefaultText({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(textHmi => {
                dispatch({
                    type: "TEXTHMI_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.textHmiId, value: textHmi }
                });
            })
            .catch(err => {
                dispatch({
                    type: "TEXTHMI_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.textHmiId },
                    error: err
                });
            });

        dispatch({
            type: "TEXTHMI_REQUEST_UPDATE",
            payload: { requestTime: requestTime, id: model.textHmiId }
        });
        return fetchTask;
    },
    requestCreateTextTranslationHmi: (requestTime: number, model: Api.TextTranslationHmiModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TextTranslationHmiApi();
        let fetchTask = api.create({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(textTranslation => {
                dispatch({
                    type: "TEXTTRANS_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, value: textTranslation }
                });
            })
            .catch(err => {
                dispatch({
                    type: "TEXTTRANS_RECEIVE_CREATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "TEXTTRANS_REQUEST_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateTextTranslationHmi: (requestTime: number, model: Api.TextTranslationHmiModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TextTranslationHmiApi();
        let fetchTask = api.update({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(textTranslation => {
                dispatch({
                    type: "TEXTTRANS_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.textTranslationHmiId, value: textTranslation }
                });
            })
            .catch(err => {
                dispatch({
                    type: "TEXTTRANS_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, id: model.textTranslationHmiId },
                    error: err
                });
            });

        dispatch({
            type: "TEXTTRANS_REQUEST_UPDATE",
            payload: { requestTime: requestTime, id: model.textTranslationHmiId }
        });
        return fetchTask;
    },
    requestDeleteTextTranslationHmi: (requestTime: number, id: number, idTextHmi: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TextTranslationHmiApi();
        let fetchTask = api._delete({
            id: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(textTranslation => {
                dispatch({
                    type: "TEXTTRANS_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id, idTextHmi : idTextHmi }
                });
            })
            .catch(err => {
                dispatch({
                    type: "TEXTTRANS_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, id: id, idTextHmi : idTextHmi },
                    error: err
                });
            });

        dispatch({
            type: "TEXTTRANS_REQUEST_DELETE",
            payload: { requestTime: requestTime, id: id }
        });
        return fetchTask;
    },
    UpdateSelectedTextHmiId: (id: number) => <UpdateSelectedTextHmiId>{
        type: "TEXTHMI_UPDATE_SELECTED_TEXTHMIID",
        payload: { id: id }
    },
    UpdateSelectedTextTranslationHmiId: (id: number) => <UpdateSelectedTextTranslationHmiId>{
        type: "UPDATE_SELECTED_TEXTTRANSTALTIONHMITID",
        payload: { id: id }
    },
}

const unloadedState: TextTranslationHmiState = {
    isLoading: false,
    textsHmi: [],
    textTranslationUpdateStates: {},
    textHmiUpdateStates: {},
    textTranslationDeleteStates: {},
    textTranslationCreateState: {
        isLoading: false
    }
};

export const reducer: Reducer<TextTranslationHmiState> = (state: TextTranslationHmiState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TEXTS_TRAN_REQUEST_ENTITIES":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "TEXTS_TRAN_RECEIVE_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isloading: false,
                textsHmi: action.payload.textsHmi,
            };
        case "TEXTHMI_UPDATE_SELECTED_TEXTHMIID":
            return {
                ...state,
                selectedTextHmiId: action.payload.id
            };
        case "UPDATE_SELECTED_TEXTTRANSTALTIONHMITID":
            return {
                ...state,
                selectedTextTranslationHmiId: action.payload.id
            };
        case "TEXTHMI_REQUEST_UPDATE":
            return {
                ...state,
                textHmiUpdateStates: {
                    ...state.textHmiUpdateStates,
                    [action.payload.id]: {
                        ...state.textHmiUpdateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "TEXTHMI_RECEIVE_UPDATE":
            if (!state.textHmiUpdateStates[action.payload.id]
                || state.textHmiUpdateStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                textHmiUpdateStates: {
                    ...state.textHmiUpdateStates,
                    [action.payload.id]: {
                        ...state.textHmiUpdateStates[action.payload.id],
                        isLoading: false,
                    }
                },
                textsHmi: action.error
                    ? state.textsHmi
                    : {
                        ...state.textsHmi,
                        [action.payload.value.textHmiId]: {
                            ...state.textsHmi[action.payload.value.textHmiId],
                            defaultText: action.payload.value.defaultText
                        }
                    }
            };
        case "TEXTTRANS_REQUEST_CREATE":
            return {
                ...state,
                textTranslationCreateState: {
                    ...state.textTranslationCreateState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "TEXTTRANS_RECEIVE_CREATE":
            return {
                ...state,
                textsHmi: action.error
                    ? state.textsHmi
                    : {
                        ...state.textsHmi,
                        [action.payload.value.textHmiId]: {
                            ...state.textsHmi[action.payload.value.textHmiId],
                            textsTranslations: state.textsHmi[action.payload.value.textHmiId].textsTranslations.concat([action.payload.value])
                        },

                },
                textTranslationCreateState: {
                    ...state.textTranslationCreateState,
                    isLoading: action.payload.requestTime !== state.textTranslationCreateState.requestTime
                        ? state.textTranslationCreateState.isLoading
                        : false
                }
            };
        case "TEXTTRANS_REQUEST_UPDATE":
            return {
                ...state,
                textTranslationUpdateStates: {
                    ...state.textTranslationUpdateStates,
                    [action.payload.id]: {
                        ...state.textTranslationUpdateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "TEXTTRANS_RECEIVE_UPDATE":
            if (!state.textTranslationUpdateStates[action.payload.id]
                || state.textTranslationUpdateStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                textTranslationUpdateStates: {
                    ...state.textTranslationUpdateStates,
                    [action.payload.id]: {
                        ...state.textTranslationUpdateStates[action.payload.id],
                        isLoading: false,
                    }
                },
                textsHmi: action.error
                    ? state.textsHmi
                    : {
                        ...state.textsHmi,
                        [action.payload.value.textHmiId]: {
                            ...state.textsHmi[action.payload.value.textHmiId],
                            textsTranslations: state.textsHmi[action.payload.value.textHmiId].textsTranslations
                                .map(x => x.textTranslationHmiId == action.payload.id ? 
                                        action.payload.value : x)
                        },

                },
            };
        case "TEXTTRANS_REQUEST_DELETE":
            return {
                ...state,
                textTranslationDeleteStates: {
                    ...state.textTranslationDeleteStates,
                    [action.payload.id]: {
                        ...state.textTranslationDeleteStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "TEXTTRANS_RECEIVE_DELETE":
            if (state.textTranslationDeleteStates[action.payload.id]
                && action.payload.requestTime !== state.textTranslationDeleteStates[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                textsHmi: action.error
                    ? state.textsHmi
                    : {
                        ...state.textsHmi,
                        [action.payload.idTextHmi]: {
                            ...state.textsHmi[action.payload.idTextHmi],
                            textsTranslations: state.textsHmi[action.payload.idTextHmi].textsTranslations
                                .filter(x => x.textTranslationHmiId != action.payload.id)
                        },

                },
                textTranslationDeleteStates: {
                    ...state.textTranslationDeleteStates,
                    [action.payload.id]: {
                        ...state.textTranslationDeleteStates[action.payload.id],
                        isLoading: false,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}