import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import {createSelector} from 'reselect';
import * as Notification from "react-notification-system-redux";
import { getText } from '../utils/texts';

export interface PurchaseTariffState {
    isSaving: boolean;
    requestTime?: number;
    prodSuppliersUpdated:  { [id: number]: Api.ProductSupplierModel};  //[productSupplierId]
}

interface PTUpdatedPSupplierList {
    type: "PT_UPDATED_PSUPPLIERLIST";
    pSupp: Api.ProductSupplierModel
}

interface PTRequestSave {
    type: "PT_REQUEST_SAVE";
    payload: { requestTime: number; }
}
export interface  PTReceiveSave{
    type: "PT_RECEIVE_SAVE";
    payload: { requestTime: number; values?: { [key: string]: Api.ProductSupplierModel; } }
    error?: any
}
type KnownAction = PTUpdatedPSupplierList 
    | PTRequestSave
    | PTReceiveSave;

export const actionCreators = {
    addToUpdatedProdSuppliersList: (pSupp: Api.ProductSupplierModel) => <PTUpdatedPSupplierList>{
        type: "PT_UPDATED_PSUPPLIERLIST",
        pSupp: pSupp
    },
    savePurchaseTariff: (requestTime: number, model: Api.UpdateProdSupplierResultModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.ProductsLgapApi();
        let fetchTask = api.updatePurchaseTariff({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(aux => {

                let prods: { [id: number]: Api.ProductModel} = {};
                _.values(model.prodSuppliersUp).map(x => {
                    var prod = JSON.parse(JSON.stringify(getState().seed.seed.products[x.productId]));
                    let index = prod.productSuppliers.findIndex(y => y.productSupplierId == x.productSupplierId);
                    prod.productSuppliers = prods[x.productId] != undefined ? prods[x.productId].productSuppliers : prod.productSuppliers;
                    prod.productSuppliers[index] = x;
                    prods = {...prods, [x.productId]: prod};
                });
              
                dispatch({
                    type: "PT_RECEIVE_SAVE",
                    payload: { requestTime: requestTime, values: prods }
                });
                dispatch(Notification.success({ title: getText("NotificationTitleSuccess"), message: getText("NotificationUpdatedSuccess"), position: "tc", level: "success" }) as any);
            })
            .catch(err => {
                dispatch(Notification.error({ title: getText("NotificationTitleError"), message: getText("NotificationUpdatedError"), position: "tc", level: "error" }) as any);
                dispatch({
                    type: "PT_RECEIVE_SAVE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "PT_REQUEST_SAVE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
}

const unloadedState: PurchaseTariffState = {
    isSaving: false,
    prodSuppliersUpdated : {}
};

export const reducer: Reducer<PurchaseTariffState> = (state: PurchaseTariffState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "PT_UPDATED_PSUPPLIERLIST":
            return {
                ...state,
                prodSuppliersUpdated: {
                    ...state.prodSuppliersUpdated,
                    [action.pSupp.productSupplierId]: action.pSupp  
                }
            };
       case "PT_REQUEST_SAVE":
            return {
                ...state,
                isSaving: true,
                requestTime: action.payload.requestTime
            };
         case "PT_RECEIVE_SAVE":
            return {
                ...state,
                isSaving: false,
                prodSuppliersUpdated: unloadedState.prodSuppliersUpdated
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};