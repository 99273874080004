import * as Api from '../api/api';
import * as React from 'react';
import * as RobotStore from '../store/Robot';
import { getText } from '../utils/texts';
import Checkbox from "./CheckBox";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';

type RobotControlProps = RobotStore.RobotState
    & RobotExtractorsProps
    & RobotExtractorsExternalProps
    & typeof RobotStore.actionCreators;

interface RobotExtractorsProps {
}

interface RobotExtractorsExternalProps {
    robotId: number;
    disabled: boolean;
    areValidCoords?: boolean;
}

const btnStyle: React.CSSProperties = { backgroundColor: "rgba(59, 67, 112, 0.25)", height: 30, fontSize: 14 };
const titleStyle: React.CSSProperties = { fontWeight: "bold", fontSize: 16, paddingRight: '2%' };
const subTitleStyle: React.CSSProperties = {fontWeight: "bold", fontSize: 14, paddingRight: '2%', 
    color: 'rgba(59, 67, 112, 0.6)', textAlign: 'center'};

class RobotExtractors extends React.Component<RobotControlProps, {}>{

    get robot(): Api.RobotModel {
        return this.props.robotsState.robots
            .find(x => x.robotId === this.props.robotId);
    }

    get robotData(): Api.RobotData {
        return this.props.state
            ? this.props.state.robotData
            : {};
    }

    getExtractorData(index: number): Api.ExtractorData {
        if (index === 0) {
            return this.robotData.extractor1 || {};
        } else {
            return this.robotData.extractor2 || {};
        }
    }

    isCommandLoading(command: string): boolean {
        return this.props.robotCommandStates[command]
            && this.props.robotCommandStates[command].isLoading;
    }

    public render() {
        return(
            <>
                <div style={{ ...titleStyle, textAlign: 'left' }}>{getText( "MaintenanceExtractors")}</div>
                <div style={{
                    borderRadius: 9,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    padding: '0 5px'
                }}>
                    {this.robot.robotExtractors.map((extractor, index) => <div style={{
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch"
                    }} key={extractor.robotExtractorId}>
                        <div style={subTitleStyle}>{getText( "MaintenanceExtractor")} {index + 1}</div>
                        <div style={{
                            borderRadius: 9,
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "stretch",
                            padding: '0 5px'
                        }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch", paddingRight: 3 }}>
                                <button style={{ ...btnStyle, margin: "3px 0px" }}
                                    disabled={this.props.disabled
                                        || this.getExtractorData(index).isExtracted
                                        || this.isCommandLoading("extendExtractor")
                                        || (this.props.areValidCoords != undefined && !this.props.areValidCoords)}
                                    onClick={() => this.props.requestRobotExtendExtractor(new Date().getTime(), index + 1)}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceExit")}
                                </button>
                                <button style={{ ...btnStyle, margin: "3px 0px" }}
                                    disabled={this.props.disabled
                                        || !this.getExtractorData(index).isExtracted
                                        || this.isCommandLoading("retractExtractor")
                                        || (this.props.areValidCoords != undefined  && !this.props.areValidCoords)}
                                    onClick={() => this.props.requestRobotRetractExtractor(new Date().getTime(), index + 1)}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceEnter")}
                        </button>
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch", paddingLeft: 3 }}>
                                <button style={{ ...btnStyle, margin: "3px 0px" }}
                                    disabled={this.props.disabled
                                        || this.getExtractorData(index).isRunning
                                        || this.isCommandLoading("runDeliveryBelt")}
                                    onClick={() => this.props.requestRobotRunExtractorBelt(new Date().getTime(), { extractorNumber: index + 1, selectedBelts: this.props.selectedExtractorBelts })}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceIHMMarket")}
                                </button>
                                <button style={{ ...btnStyle, margin: "3px 0px" }}
                                    disabled={this.props.disabled
                                        || !this.getExtractorData(index).isRunning
                                        || this.isCommandLoading("stopDeliveryBelt")}
                                    onClick={() => this.props.requestRobotStopExtractorBelt(new Date().getTime(), { extractorNumber: index + 1, selectedBelts: this.props.selectedExtractorBelts })}
                                    className="btn btn-sm btn-secondary">
                                    {getText( "MaintenanceIHMStop")}
                                </button>
                            </div>
                        </div>
                    </div>)}
                    <div style={{
                        flex: "0 0 auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch"
                    }}>
                        <div style={{ flex: "0 0 auto", textAlign: "center" }}>{getText( "MaintenanceBands")}</div>
                        <div style={{
                            borderRadius: 9,
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: '0 5px'
                        }}>
                            {[1, 2, 3, 4, 5, 6].map(n => <div key={n} style={{ flex: 1 }}>
                                <div style={{ textAlign: "center" }}>{n}</div>
                                <div style={{ textAlign: "center" }}>
                                    <Checkbox value={this.props.selectedExtractorBelts.some(x => x === n)}
                                        onChange={(value) => {
                                            if (value) {
                                                this.props.addExctratorBelt(n);
                                            } else {
                                                this.props.removeExctratorBelt(n);
                                            }
                                        }} />
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.robot
} as RobotExtractorsProps),{ 
    ...RobotStore.actionCreators 
})(RobotExtractors as any) as any as React.ComponentClass<RobotExtractorsExternalProps>;