import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from "react-table";
import * as StockStore from "../store/Stock";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import Table from "./Table";
import HelpHeader from './HelpHeader';
import * as ReorderStore from "../store/Reorder";
import * as ProductsDetailsStore from '../store/ProductsDetails';
import InputNumber from "./InputNumber";
import Select from "./Select";
import { calculateProductDepth, calculateProductHeight, calculateProductWidth } from '../utils/utils';

type ProductSizesProps = ProductSizesOwnProps
    & typeof ProductsDetailsStore.actionCreators;

interface ProductSizesOwnProps {
    productSizes: ProductsDetailsStore.productSizesState;
    stock: StockStore.StockState;
    products: { [id: number]: Api.ProductModel };
    suppliers: { [id: number]: Api.SupplierModel };
    productRailsInPlano: Array<Api.ProductRailModel>;
}

export interface ProductSizesForTableModel {
    "productId"?: number;
    "name"?: string;
    "width"?: number;
    "height"?: number;
    "depth"?: number;
    "currentSens"?: Api.ProductRailModelSensEnum;
    "planoHeight"?: number;
    "planoWidth"?: number;
    "planoDepth"?: number;
}

interface ProductSizesOwnState {
    supplierMainIdSelected: number;
    referenceSelected: string;
}

type ProductSizesColumn = ReactTable.Column
    & {
        accessor: (d: ProductSizesForTableModel) => any;
        columns?: Array<ProductSizesColumn>
    }

class ProductSizes extends React.Component<ProductSizesProps, ProductSizesOwnState> {

    constructor(props: any) {
        super(props);
        this.state = {
            supplierMainIdSelected: undefined,
            referenceSelected: undefined
        };
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    isInPlanogram(supplierId: number): boolean {
        if(!this.props.stock.stockStatus)
            return false;
        
        return this.props.productRailsInPlano.some(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products)
            ? ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === supplierId
            : false);
    }

    getProductSuppliersReference(): Array<string> {
        return _.uniq(_.uniq(this.props.productRailsInPlano.map(x => x.productId).filter(x => x))
            .map(x => ReorderStore.getProductSupplier(x, this.props.products))
            .map(x => x.reference));
    }

    get productsInPRails(): ProductSizesForTableModel[] {
        if (!this.props.stock.stockStatus)
            return [];

        let planogramId = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0].planogramId;
        
        let productRailsFiltered = this.props.stock.stockStatus.productRails.filter(x => x.planogramId === planogramId);

        if (this.state.supplierMainIdSelected != undefined) {
            productRailsFiltered = productRailsFiltered.filter(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products) ?
                ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === this.state.supplierMainIdSelected
                : ''
            )
        } 
        
        if (this.state.referenceSelected != undefined) {
            productRailsFiltered = productRailsFiltered.filter(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products) ?
                ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).reference === this.state.referenceSelected
                : ''
            )
        }

        let resArr: ProductSizesForTableModel[] = [];
        
        productRailsFiltered.filter(item => {
                var i = resArr.findIndex(x => x.productId == item.productId);
                if(i <= -1) {
                    let productId = item.productId;

                    let prodAux = this.props.productSizes.productsUpdated[productId] != undefined ?
                        this.props.productSizes.productsUpdated[productId] : this.props.products[productId];

                    let prodsizes : ProductSizesForTableModel = {
                        productId: productId,
                        name: this.props.products[productId].name,
                        width: calculateProductWidth(prodAux, item.sens),
                        height: calculateProductHeight(prodAux, item.sens),
                        depth: calculateProductDepth(prodAux, item.sens),
                        currentSens: item.sens,
                        planoWidth: Math.ceil(
                            (calculateProductWidth(prodAux, item.sens) / 48)),
                        planoHeight: Math.ceil(
                            (calculateProductHeight(prodAux, item.sens) / 50)),
                        planoDepth: Math.floor( 
                            800 / (calculateProductDepth(prodAux, item.sens)))
                    };

                    resArr.push(prodsizes);
                }
            });
        
            return resArr.sort((a, b) => this.props.products[a.productId].name.localeCompare(this.props.products[b.productId].name));
    }

    get isDisabledByUpdating() : boolean{
        return (Object.keys(this.props.productSizes.productsUpdated).length == 0);
    }

    getBDSize(sizeCal: "w" | "h" | "d", currentSens: Api.ProductRailModelSensEnum) : string {
        if((sizeCal == "w" && (currentSens === "Y1" || currentSens === "Z1")) ||
            (sizeCal == "h" && (currentSens === "X1" || currentSens === "Z2")) ||
            (sizeCal == "d" && (currentSens === "X2" || currentSens === "Y2"))) {
                return "w";
        }
        if((sizeCal == "w" && (currentSens === "X1" || currentSens === "X2")) ||
            (sizeCal == "h" && (currentSens === "Y1" || currentSens === "Y2")) ||
            (sizeCal == "d" && (currentSens === "Z1" || currentSens === "Z2"))) {
            return "h";
        }
        if((sizeCal == "w" && (currentSens === "Y2" || currentSens === "Z2")) ||
            (sizeCal == "h" && (currentSens === "Z1" || currentSens === "X2")) ||
            (sizeCal == "d" && (currentSens === "Y1" || currentSens === "X1"))) {
            return "d";
        }
    }

    getBDSizes(calcSize: "w" | "h" | "d", rowValues: ProductSizesForTableModel, newValue: number) : Api.ProductModel {
        let depthBD = (rowValues.currentSens == "Y1" || rowValues.currentSens == "X1") ? rowValues.depth 
                        : (rowValues.currentSens == "Z1" || rowValues.currentSens == "Z2") ? rowValues.height 
                        :  rowValues.width; //X2 || Y2

        let widthBD = (rowValues.currentSens == "Y1" || rowValues.currentSens == "Z1") ? rowValues.width 
                        : (rowValues.currentSens == "X1" || rowValues.currentSens == "X2") ? rowValues.height 
                        :  rowValues.depth; //Y2 || Z2

        let heightBD = (rowValues.currentSens == "Y1" || rowValues.currentSens == "Y2") ? rowValues.height 
                        : (rowValues.currentSens == "X1" || rowValues.currentSens == "Z2") ? rowValues.width 
                        :  rowValues.depth; //Z1 || X2

        let sizeCal = this.getBDSize(calcSize, rowValues.currentSens); 
        return {
            storeItemId: rowValues.productId,
            height: sizeCal == "h" ? newValue : heightBD,
            depth: sizeCal == "d" ? newValue : depthBD,
            width: sizeCal == "w" ? newValue : widthBD,
        }   
    }

    public render() {
        return (
            <div >
            <div 
            className="card"
            style={{
                ...Styles.cardStyle,
                ...Styles.pageTitle
            }}><span style={{paddingLeft: 30}}>{getText("ProdSizesTitle")}</span> <HelpHeader /></div>
            <div className="card prodSizes-table" style={{ ...Styles.cardStyle, margin: "1%", height: "100%" }}>
            <div className="row" style={{margin: "1% 1% 0.2rem 1%"}}>
                <div className="col-md-12" style={{display: 'flex', justifyContent: 'flex-end', margin: 5, fontSize: 16}}>
                    <button className="btn btn-primary"
                        style={{height: 40, fontSize: 16, fontWeight: 'bold'}}
                        disabled={ this.isDisabledByUpdating }
                        onClick={(e) => { 
                            let model: Api.UpdateProductSizeResultModel = {
                                prodSizesUp: this.props.productSizes.productsUpdated
                            };
                            this.props.saveProductSizes(new Date().getTime(), model);
                        }}>
                            {getText( "SalesTariffButtonSave")}
                    </button>
                </div>
            </div>
            <Table
                    tableKey={"productSizes"}
                    loading={this.props.productSizes.isSaving || this.props.stock.isLoading}
                    style={{ height: '75vh' }}
                    columns={[
                        {
                            id: "general",
                            Header: 
                            <div  style={{
                                margin: 0, display: "flex", flexDirection: "row", 
                                columnGap: 4, flexWrap: "wrap",
                                width: "100%"
                            }}>
                                <div style={{lineHeight: "40px", flex: "0 0 1" }}>{getText( "StockSource")}: </div>
                                    <div style={{padding: 0, flex: "0 0 1", minWidth: 150, marginRight: 15  }}>
                                        <div>
                                            <Select options={[{
                                                label: "------",
                                                value: undefined
                                                }].concat(_.sortBy(_.values(this.props.suppliers)
                                                    .filter(x => this.isInPlanogram(x.supplierId))
                                                    .map(z => ({ label: z.name, value: z.supplierId })), x => x.label))}
                                                    style={{
                                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                                    }}
                                                    value={this.state.supplierMainIdSelected}
                                                    onChange={(newSupplierMainId) => {
                                                        this.setState({ ...this.state, supplierMainIdSelected: newSupplierMainId});
                                                    }}
                                            />
                                        </div>
                                </div>
                                <div style={{lineHeight: "40px", flex: "0 0 1" }}>{getText( "StockCodeSource")}: </div>
                                <div style={{padding: 0, flex: "0 0 1", minWidth: 150 }}>
                                        <div style={{/*width: '100%'*/}}>
                                            <Select options={[{
                                                label: "------",
                                                value: undefined
                                            }].concat(_.sortBy(this.getProductSuppliersReference()
                                                .map(z => ({ label: z, value: z })), x => x.label))}
                                                    style={{
                                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                                    }}
                                                    value={this.state.referenceSelected}
                                                    onChange={(newReference) => {
                                                        this.setState({ ...this.state, referenceSelected: newReference});
                                                    }} 
                                            />
                                        </div>
                                    </div>
                            </div>,
                            columns: [
                                {
                                    id: "name",
                                    accessor: (d) => d.name,
                                    Header: <div>{getText( "SalesTariffTabName")}</div>,
                                    Cell: row => { return (<div style={{textAlign: 'initial', margin: 7}}> {row.value}</div>) },
                                    style: { height: '100%' },
                                    width: 400,
                                },
                                {
                                    id: "width",
                                    accessor: (d) => d,
                                    Header: <div>{getText("ProdSizesTabWidth")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <InputNumber value={row.value.width}
                                                style={{ width: 50, textAlign: 'center' }}
                                                onChange={(element) => {
                                                    if(element >= 8 && element <= 280){
                                                        let sizesBD = this.getBDSizes('w', row.value, element);
                                                        this.props.addToUpdatedProdList(sizesBD);
                                                    }
                                                }}
                                                onBlur={(element) => {
                                                    if(element >= 8 && element <= 280){
                                                        let sizesBD = this.getBDSizes('w', row.value, element);
                                                        this.props.addToUpdatedProdList(sizesBD);
                                                    }
                                                }}/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "depth",
                                    accessor: (d) => d,
                                    Header: <div>{getText("ProdSizesTabDepth")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <InputNumber value={row.value.depth}
                                                style={{ width: 50, textAlign: 'center' }}
                                                onChange={(element) => {
                                                    if(element >= 40 && element <= 350){
                                                        let sizesBD = this.getBDSizes('d', row.value, element);
                                                        this.props.addToUpdatedProdList(sizesBD);
                                                    }
                                                }}
                                                onBlur={(element) => {
                                                    if(element >= 40 && element <= 350){
                                                        let sizesBD = this.getBDSizes('d', row.value, element);
                                                        this.props.addToUpdatedProdList(sizesBD);
                                                    }
                                                }}/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "height",
                                    accessor: (d) => d,
                                    Header: <div>{getText("ProdSizesTabHeight")}</div>,
                                    Cell: row => {
                                        return (
                                         <div>
                                            <InputNumber value={row.value.height}
                                                style={{ width: 50, textAlign: 'center' }}
                                                onChange={(element) => {
                                                    if(element >= 8 && element <= 350){
                                                        let sizesBD = this.getBDSizes('h', row.value, element);
                                                        this.props.addToUpdatedProdList(sizesBD);
                                                    }
                                                }}
                                                onBlur={(element) => {
                                                    if(element >= 8 && element <= 350){
                                                        let sizesBD = this.getBDSizes('h', row.value, element);
                                                        this.props.addToUpdatedProdList(sizesBD);
                                                    }
                                                }}/>
                                        </div>)
                                    },
                                    style: { height: '100%' }
                                },
                                {
                                    id: "planoWidth",
                                    accessor: (d) => d.planoWidth,
                                    Header: <div>{getText("ProdSizesTabPlanoWidth")}</div>,
                                    style: { height: '100%' }
                                },
                                {
                                    id: "planoHeight",
                                    accessor: (d) => d.planoHeight,
                                    Header: <div>{getText("ProdSizesTabPlanoHeight")}</div>,
                                    style: { height: '100%' }
                                },
                                {
                                    id: "planoDepth",
                                    accessor: (d) => d.planoDepth,
                                    Header: <div>{getText("ProdSizesTabPlanoDepth")}</div>,
                                    style: { height: '100%' }
                                }
                            ] as Array<ProductSizesColumn>
                        },
                    ] as Array<ProductSizesColumn>}
                    pageSize={Math.max(this.productsInPRails.length, 15)}
                    showPagination={false}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    data={this.productsInPRails}>
                </Table>
            </div>
        </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    productSizes: state.productsDetails.productSizes,
    stock: state.stock,
    products: state.seed.seed.products,
    suppliers: state.seed.seed.suppliers, 
    productRailsInPlano: StockStore.productRailsInPlanoSelector(state)
}), {
    ...ProductsDetailsStore.actionCreators,
})(ProductSizes as any)