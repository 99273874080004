import * as Api from '../api/api';
import * as TempStore from '../store/Temp';
import * as RobotStore from '../store/Robot';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';

type TemperatureProps = TempStore.TempState
    & TemperatureOwnProps
    & typeof TempStore.actionCreators
    & typeof RobotStore.actionCreators;

interface TemperatureOwnProps {
    deliveryStation: Api.DeliveryStationModel;
}

class Temperature extends React.Component<TemperatureProps, {}>{
    interval = null;


    componentDidMount() {
        this.props.requestTempTemperature(new Date().getTime());
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.props.requestTempTemperature(new Date().getTime());
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    get isTempWarning() {
        return this.props.deliveryStation
            && this.props.temperatureState.value
            && this.props.deliveryStation.warnTemp < this.props.temperatureState.value;
    }

    get isTempAlert() {
        return this.props.deliveryStation
            && this.props.temperatureState.value
            && this.props.deliveryStation.alertTemp < this.props.temperatureState.value;
    }

    get tempColor() {
        if (this.isTempAlert)
            return "red";
        if (this.isTempWarning)
            return "orange";

        return "green";
    }
    public render() {
        return ( 
            <div>
                {this.props.temperatureState.value
                    && <span style={{color: this.tempColor}}>
                        {this.props.temperatureState.value.toFixed(2)} C°
                    </span>}
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.temp,
    deliveryStation: state.robot.robotsState.robots.length !== 0
        ? state.robot.robotsState.robots[0].deliveryStations[0]
        : undefined
} as TemperatureOwnProps), {
    ...TempStore.actionCreators,
     ...RobotStore.actionCreators,
    })(Temperature as any)