import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as ReactTable from "react-table";
import * as TablesStore from "../store/Tables";
import * as _ from "lodash";
import { CSVLink } from "react-csv";
import { getText } from '../utils/texts';
import { filterWithoutAccents } from '../utils/utils'

type TableProps = typeof ReactTable.default
    & TableExternalProps
    & TableOwnProps
    & { columns: ReactTable.Column[] }
    & typeof TablesStore.actionCrators;

interface TableExternalProps {
    tableKey: string;
    style?: React.CSSProperties;
    theadStyle?: React.CSSProperties;
    showCsvDownload?: boolean;
}

interface TableOwnProps {
    tables: TablesStore.TablesState;
}

class NoData extends React.Component<{}, {}> {
    public render() {
        return null;
    }
}

class Table extends React.Component<TableProps, {}> {
    tableRef: any = null;

    constructor(props: TableProps) {
        super(props);
    }

    get tableState(): TablesStore.TableState {
        return this.props.tableKey && this.props.tables[this.props.tableKey] ? this.props.tables[this.props.tableKey]
            : TablesStore.unloadedTableState;
    }

    get csvHeaders(): Array<string> {
        //let test = (this.props as any).columns.map(x => ReactDOM.findDOMNode(x.Header));

        let tableElement = ReactDOM.findDOMNode(this.tableRef) as any;
        if (tableElement) {
            return Array.from<HTMLDivElement>(tableElement.getElementsByClassName("rt-th") as any).map(x => x.innerText);
        }
        return [];
    }

    get csvData(): Array<any> {
        let tableElement = ReactDOM.findDOMNode(this.tableRef) as any;
        if (tableElement) {
            let data = Array.from<HTMLDivElement>(tableElement.getElementsByClassName("rt-tr-group") as any)
                .map(x => Array.from<HTMLDivElement>(x.getElementsByClassName("rt-td") as any).map(y => y.innerText));
            return data;
        }

        return [];
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get currentColumns(){
        let indexX = 0;
        let indexY = 0;

        return this.props.columns.map((x, xIndex) => {
            if(x.show == false)
                 indexX++;
            return ({ 
                ...x,
                headerClassName: x.columns && x.columns.length !== 0
                    ? `rthcg-${(xIndex-indexX)}`
                    : `rthc-${(xIndex-indexX)}`,
                className: x.columns && x.columns.length !== 0 
                    ? `rtcg-${(xIndex-indexX)} rtc-${(xIndex-indexX)}` 
                    : `rtc-${(xIndex-indexX)}`,
                columns: x.columns 
                    ? x.columns.map((y, yIndex) => {
                        if(y.show == false)
                            indexY++;
                        let index = _.sum(
                            _.map(
                                _.take(this.props.columns, (xIndex-indexX)), x => (x as any).columns 
                                    ? (x as any).columns.length 
                                    : 1)) + (yIndex-indexY);
                        return {
                            ...y,
                            headerClassName: `rthc-${index} rthcg-${(xIndex-indexX)}`,
                            className: `rtc-${index} rtcg-${(xIndex-indexX)}`
                        };
                    })
                    : undefined
        })})
    }

    public render() {
        let colorTd = this.isDarkMode() ? "rgba(255, 255, 255, 0.26);" : "rgba(59, 67, 112, 0.16);";
        let colorTh = this.isDarkMode() ? "1px solid rgba(59, 67, 112, 0.56);" : "rgba(255, 255, 255, 0.16);";
        const css = `
        .ReactTable .rt-thead.-filters, .ReactTable .rt-thead.-headerGroups {
            border-bottom: none;
        }
        .ReactTable .rt-tbody .rt-td {
            border-right: 1px solid ` +  colorTd + `
        }
        .ReactTable .rt-thead .rt-th {
            border-right: 1px solid ` +  colorTh + `
        }
        `
        let colIndex = 0;
        return (
            <div style={{height: "100%"}}>
                <style>{css}</style> 
                {this.props.showCsvDownload && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <CSVLink data={this.csvData} headers={this.csvHeaders} separator={";"}>
                        {getText( "GenTableExport")}
                    </CSVLink>
                </div>}
                <ReactTable.default
                    //showPageJump={false}
                    loadingText={getText("GenTableLoadingText")}
                    ofText={getText( "GenTableOf")}
                    nextText={getText( "GenTableNext")}
                    previousText={getText( "GenTablePrevious")}
                    pageText={getText( "GenTablePage")}
                    rowsText={getText( "GenTableLines")}
                    ref={ref => this.tableRef = ref}
                    style={{ ...this.props.style, textAlign: "center", margin: "1%", height: "100%",
                        border: 'none', background: this.isDarkMode() ? "#181F3A" : "#fff"
                    }}
                    NoDataComponent={NoData}
                    noDataText={""}
                    defaultFilterMethod={(filter, row) => {
                        const id = filter.pivotId || filter.id;
                        return (
                            row[id]
                                ? (row[id] == filter.value
                                    || (row[id].toLowerCase
                                        && String(filterWithoutAccents(row[id]).toLowerCase()).includes(filterWithoutAccents(filter.value).toLowerCase())))
                                : true
                        );
                    }}
                    filtered={this.tableState.filtered}
                    sorted={this.tableState.sorted}
                    getTheadTrProps={() => ({
                        style: {
                            backgroundColor: this.isDarkMode()? "rgba(255, 255, 255, 0.35)" : "#3B4370",
                            color: "white",
                            fontWeight: "bold",
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            ...this.props.theadStyle
                        } as React.CSSProperties
                    })}
                    getTrProps={(state, rowInfo, column) => {
                        if (!rowInfo)
                            return {};

                        let baseGetTrProps = (this.props as any).getTrProps;
                        return {
                            ...{
                                style: rowInfo.viewIndex % 2 == 0
                                    ? {
                                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                                        fontWeight: "bold", 
                                    }
                                    : { 
                                        backgroundColor: this.isDarkMode() ?  "rgba(59, 67, 112, 0.9)" : "rgba(59, 67, 112, 0.1)", 
                                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                                        fontWeight: "bold"
                                    }
                            },
                            ...(baseGetTrProps
                                ? baseGetTrProps(state, rowInfo, column)
                                : {})
                        };
                    }}
                    onFilteredChange={(filtered) => this.props.updateFiltered(this.props.tableKey, filtered as any)}
                    onSortedChange={(sorted) => this.props.updateSorted(this.props.tableKey, sorted)}
                    {...this.props}
                    columns={this.currentColumns}>
                </ReactTable.default>
            </div>
            );
    }
}

type ExternalType = (typeof ReactTable.default) & React.ComponentClass<TableExternalProps>;
export default connect((state: ApplicationState) => ({
    tables: state.tables
} as TableOwnProps), TablesStore.actionCrators)(Table) as ExternalType;