import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from 'react-table';
import * as NeoEventAlarmStore from '../store/NeoEventAlarm';
import * as TabsStore from '../store/Tabs';
import * as ContactStore from '../store/Contact';
import { UpdateNeoEventAlarmForm } from "./NeoEventAlarmForm";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Table from './Table';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import { canUpdate } from '../security/roles';

type NeoEventAlarmsProps = NeoEventAlarmStore.NeoEventAlarmState
    & NeoEventAlarmsOwnProps
    & typeof NeoEventAlarmStore.actionCreators
    & typeof TabsStore.actionCreators
    & typeof ContactStore.actionCreators;

interface NeoEventAlarmsOwnProps {
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel};
    currentUser: Api.CurrentUserManagerModel;
    disabled: boolean;
    contacts: { [id: number]: Api.ContactModel };
}

type NeoEventAlarmColumn = ReactTable.Column
    & { accessor: (d: Api.NeoEventAlarmModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class NeoEventAlarms extends React.Component<NeoEventAlarmsProps, {}> {
    componentDidMount() {
        (this.props.requestContacts(new Date().getTime()) as any as Promise<any>)
            .then(() => this.props.requestNeoEventAlarms(new Date().getTime()));
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }
    
    public render() {
        return (
            <div >
                <div className="card" style={{...Styles.cardStyle, marginBottom: "1%"}}>
                    <div style={{fontWeight: "bold", fontSize: 22, padding: '0.5rem 0 0  1%'}}>
                        {getText( "MaintenanceAlarms")}
                    </div>
                    <Table key={"neoEventAlarms"}
                        className="tableHeight"
                        style={{ margin: '1% 0' }}
                        pageSize={Math.max(_.values(this.props.neoEventAlarms).length,15)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        data={_.values(this.props.neoEventAlarms).sort(((a, b) => this.props.neoEventCodes[a.neoEventCodeId].code - this.props.neoEventCodes[b.neoEventCodeId].code))}
                        columns={[
                            {
                                id: "code",
                                Header: getText( "MaintenanceCode"),
                                accessor: (d) => d,
                                Cell: row => <div style={{
                                    textAlign: 'left',
                                    marginLeft: '1%',
                                    whiteSpace: 'pre-wrap',
                                }}>{`${this.props.neoEventCodes[row.value.neoEventCodeId].code} | ${this.props.neoEventCodes[row.value.neoEventCodeId].name}`}</div>
                            },
                            {
                                id: "contacts",
                                Header: getText( "MaintenanceTabContacts"),
                                accessor: (d) => d,
                                Cell: row => <div style={{
                                    textAlign: 'left',
                                    marginLeft: '1%',
                                    whiteSpace: 'pre-wrap'}}>{`${row.value.neoEventAlarmContacts.map(x => this.props.contacts[x.contactId] &&
                                        `${this.props.contacts[x.contactId].firstName} ${this.props.contacts[x.contactId].lastName}`).join(" | ")}`}</div>
                            },
                            {
                                id: "actions",
                                Header: getText( "MaintenanceAction"),
                                width: 100,
                                accessor: (d) => d.neoEventAlarmId,
                                Cell: row => <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center",    
                                        backgroundColor: this.isDarkMode() ? "#F2C94C" : "#007AFF",                                 
                                        borderRadius: 5,
                                        marginTop: 6,
                                        marginRight: 10
                                    }}>
                                        <i style={{ margin: "11% 0",  cursor: "pointer", color: this.isDarkMode() ? "#181F3A" : "#FFFFFF"}}
                                         className="glyphicon glyphicon-pencil"
                                        onClick={(e) => {
                                            if(this.props.disabled){
                                                return
                                            }
                                            this.props.updateSelectedNeoEventAlarmId(row.value);
                                            this.props.selectTab("neoEventAlarms", "update");
                                            e.stopPropagation();
                                        }}></i>
                                    </div>
                                    {" "}
                                    {/* Modify user permission if deleting NeoEvent Alarm is now possible
                                    <span style={{ cursor: "pointer", color: "blue" }}
                                        onClick={(e) => {
                                            if(this.props.disabled){
                                                return
                                            }
                                            this.props.requestDeleteNeoEventAlarm(new Date().getTime(), row.value);
                                            e.stopPropagation();
                                        }}><i className="glyphicon glyphicon-trash icon-dm"/></span> */}
                                    </div>
                            }
                        ] as Array<NeoEventAlarmColumn>} />
                </div>
                <div>
                    <div style={{ paddingTop: 5 }}>
                                {this.props.selectedNeoEventAlarmId
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <UpdateNeoEventAlarmForm actionName={getText( "MaintenanceSave")}
                                            openContactDialog={() => this.props.openContactsDialog()}
                                            closeContactDialog={() => this.props.closeContactsDialog()}
                                            onSubmit={(values) => this.props.requestUpdateNeoEventAlarm(new Date().getTime(), values)} />
                                    </div>
                                }
                            </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.neoEventAlarm,
    neoEventCodes: state.seed.seed.neoEventCodes,
    currentUser: state.account.currentUser,
    disabled: !canUpdate(state),
    contacts: state.contact.contacts
} as NeoEventAlarmsOwnProps), {
        ...NeoEventAlarmStore.actionCreators,
        ...TabsStore.actionCreators,
        ...ContactStore.actionCreators
    })(NeoEventAlarms as any)