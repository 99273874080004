import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as _ from 'lodash';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { createSelector } from 'reselect';
import { getCurrentLang } from '../utils/language';

export interface HelpState {
    helpState: {
        [name: string]: {
            isLoading: boolean;
            requestTime?: number;
            isOpen: boolean;
        }
    },
    helpUrls?: Array<IHelpUrls>,
    currentUrl?: IFilesHelp;
}

export interface IHelpUrls {
    Page: string;
    Files: Array<IFilesHelp>
}

export interface IFilesHelp {
    Name: string;
    Url: string;
    Text: string;
}
interface RequestHelp {
    type: 'REQUEST_HELP';
    payload: { requestTime: number; name: string; }
}
interface ReceiveHelp {
    type: 'RECEIVE_HELP';
    payload: {
        requestTime: number; name: string;
    };
    error?: any;
}

interface OpenHelp {
    type: "OPEN_HELP";
    payload: { name: string; }
}
interface CloseHelp {
    type: "CLOSE_HELP";
    payload: { name: string; }
}

interface SetHelpUrls {
    type: "SET_URLS_HELP";
    payload: { helpUrls: Array<IHelpUrls> }
}
interface SetCurrentUrl {
    type: "SET_CURRENT_URL";
    payload: { fileHelp: IFilesHelp; }
}

export type KnownAction = RequestHelp
    | ReceiveHelp
    | OpenHelp
    | CloseHelp
    | SetHelpUrls
    | SetCurrentUrl;


export const actionCreators = {
    requestHelp: (requestTime: number, name: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SeedApi();
        let fetchTask = api.getHelp({ name: name, lang: getCurrentLang() }).then(help => {
            dispatch({
                type: "RECEIVE_HELP",
                payload: {
                    requestTime: requestTime,
                    name: name
                }
            });
            return help;
        }).catch(error => {
            dispatch({
                type: "RECEIVE_HELP",
                payload: {
                    requestTime: requestTime,
                    name: name
                },
                error: error
            });
        });

        //Tell the server to wait for this promise to end
        addTask(fetchTask);
        dispatch({ type: "REQUEST_HELP", payload: { requestTime: requestTime, name: name } });
        return fetchTask;
    },
    openHelpDialog: (name: string) => <OpenHelp>{ type: "OPEN_HELP", payload: { name: name } },
    closeHelpDialog: (name: string) => <CloseHelp>{ type: "CLOSE_HELP", payload: { name: name } },
    setHelpUrls: (helpUrls: Array<IHelpUrls> ) => <SetHelpUrls>{ type: "SET_URLS_HELP", payload: { helpUrls: helpUrls } },
    setCurrentUrl: (fileHelp: IFilesHelp) => <SetCurrentUrl>{ type: "SET_CURRENT_URL", payload: { fileHelp: fileHelp } },
};

const unloadedState: HelpState = {
    helpState: {}
};

const productsSelector = (state: ApplicationState) => state.seed.seed.products;
export const productSuppliersSelector = createSelector(
    productsSelector,
    products => _.keyBy(_.values(products)
        .map(x => x.productSuppliers)
        .reduce((a, b) => a.concat(b), []), x => x.productSupplierId)
);

export const reducer: Reducer<HelpState> = (state: HelpState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_HELP':
            return {
                ...state,
                helpState: {
                    ...state.helpState,
                    [action.payload.name]: {
                        ...state.helpState[action.payload.name],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case 'RECEIVE_HELP':
            if (!state.helpState[action.payload.name]
                || action.payload.requestTime !== state.helpState[action.payload.name].requestTime)
                return state;

            return {
                ...state,
                helpState: {
                    ...state.helpState,
                    [action.payload.name]: {
                        ...state.helpState[action.payload.name],
                        isLoading: false,
                    }
                }
            };
        case "OPEN_HELP":
            return {
                ...state,
                helpState: {
                    ...state.helpState,
                    [action.payload.name]: {
                        ...state.helpState[action.payload.name],
                        isOpen: true
                    }
                }
            };
        case "CLOSE_HELP":
            return {
                ...state,
                helpState: {
                    ...state.helpState,
                    [action.payload.name]: {
                        ...state.helpState[action.payload.name],
                        isOpen: false
                    }
                }
            };
         case "SET_URLS_HELP":
            return {
                ...state,
                helpUrls : action.payload.helpUrls
            };
        case "SET_CURRENT_URL":
            return {
                ...state,
                currentUrl : action.payload.fileHelp
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};