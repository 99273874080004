import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from "react-table";
import * as SalesTariffStore from '../store/SalesTariff';
import * as StockStore from "../store/Stock";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import * as ReorderStore from "../store/Reorder";
import { SalesTariffForTableModel } from '../store/SalesTariff';
import Table from "./Table";
import HelpHeader from './HelpHeader';
import Select from "./Select";
import InputNumber from "./InputNumber";


type SalesTariffProps = SalesTariffStore.SalesTariffState
    & SalesTariffOwnProps
    & typeof SalesTariffStore.actionCreators;

interface SalesTariffOwnProps {
    stock: StockStore.StockState;
    products: { [id: number]: Api.ProductModel };
    vats: { [key: string]: Api.VatModel; };
    countryId: number;
    priceNames: { [key: string]: Api.PriceNameModel; }
    suppliers: { [id: number]: Api.SupplierModel };
    countryVats: Array<Api.CountryVatModel>;
    productRailsInPlano: Array<Api.ProductRailModel>;
}

interface SalesTariffOwnState {
    priceNameIdSelected1: number;
    priceNameIdSelected2: number;
    supplierMainIdSelected: number;
}

type SalesTariffColumn = ReactTable.Column
    & {
        accessor: (d: SalesTariffForTableModel) => any;
        columns?: Array<SalesTariffColumn>
    }

class SalesTariff extends React.Component<SalesTariffProps, SalesTariffOwnState> {

    constructor(props: any) {
        super(props);
        this.state = {
            priceNameIdSelected1: this.tariffPlain,
            priceNameIdSelected2: 2,
            supplierMainIdSelected: null
        };
    }

    get tariffPlain(): number{
        return _.values(this.props.priceNames).find(x => x.name == "TarifPlein").priceNameId;
    }

    get productsInPRails(): SalesTariffForTableModel[] {
        if (!this.props.stock.stockStatus)
            return [];

        let planogramId = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0].planogramId;
        
        let productRailsFiltered = this.props.stock.stockStatus.productRails.filter(x => x.planogramId === planogramId);

        if (this.state.supplierMainIdSelected != null) {
            productRailsFiltered = productRailsFiltered.filter(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products) ?
                ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === this.state.supplierMainIdSelected
                : ''
            )
        }  

        let resArr: SalesTariffForTableModel[] = [];
        
        productRailsFiltered.filter(item => {
                var i = resArr.findIndex(x => x.productId == item.productId);
                if(i <= -1) {
                    let productId = item.productId;
                    
                    let auxProductVat = _.sortBy(this.props.products[productId].productVats, x => (x.countryId ==  this.props.countryId ? 0 : 1))[0];
                    let vatIdAux = this.props.tvasUpdated[auxProductVat.productVatId] != undefined 
                        ? this.props.tvasUpdated[auxProductVat.productVatId].vatId
                        : auxProductVat.vatId;
                    
                    let price : Api.PriceModel = this.props.products[productId].prices.find(x => x.priceNameId == this.state.priceNameIdSelected1);
                    price = price != undefined && this.props.pricesUpdated[price.priceId] ? this.props.pricesUpdated[price.priceId] : price;
                    let pvht = (price?.value || 0) / (1 + (this.props.vats[vatIdAux].value / 100));

                    let price2 : Api.PriceModel = this.props.products[productId].prices.find(x => x.priceNameId == this.state.priceNameIdSelected2);
                    price2 =  price2 != undefined && this.props.pricesUpdated[price2.priceId] ? this.props.pricesUpdated[price2.priceId] : price2;
                    let pvht2 = (price2?.value || 0) / (1 + (this.props.vats[vatIdAux].value / 100));                        
                    
                    var supplier = ReorderStore.getProductSupplier(this.props.products[productId].storeItemId, this.props.products);

                    let saleTariff : SalesTariffForTableModel = {
                        productId: productId,
                        name: this.props.products[productId].name,
                        pmp: this.props.products[productId].pmp,
                        buyPrice: (supplier.buyPriceNoVat / supplier.packCondition),
                        pVatId: auxProductVat.productVatId,
                        vatId: vatIdAux,
                        vatName: this.props.vats[vatIdAux].name,
                        //Table part 1
                        price: price,
                        priceNoVat: pvht,
                        delta: price ? (price.value / this.props.products[productId].prices.find(x => x.priceNameId == this.tariffPlain).value - 1) : null,
                        marge: price ? (pvht - this.props.products[productId].pmp) : null,
                        marquePercentage: price ? (((pvht - this.props.products[productId].pmp) / pvht) * 100) : null,
                        margePercentage: price ? (((pvht - this.props.products[productId].pmp) / this.props.products[productId].pmp) * 100) : null,
                        //Table part 2
                        price2: price2,
                        priceNoVat2: pvht2,
                        delta2: price2 ? (price2?.value / this.props.products[productId].prices.find(x => x.priceNameId == this.tariffPlain).value - 1) : null,
                        marge2: price2 ? (pvht2 - this.props.products[productId].pmp) : null,
                        marquePercentage2: price2 ? (((pvht2 - this.props.products[productId].pmp) / pvht2) * 100) : null,
                        margePercentage2: price2 ? (((pvht2 - this.props.products[productId].pmp) / this.props.products[productId].pmp) * 100) : null
                    };

                    resArr.push(saleTariff);
                }
            });
        
            return resArr.sort((a, b) => this.props.products[a.productId].name.localeCompare(this.props.products[b.productId].name));
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    isInPlanogram(supplierId: number): boolean {
        if(!this.props.stock.stockStatus)
            return false;
        
        return this.props.productRailsInPlano.some(x => ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products)
            ? ReorderStore.getProductSupplier(this.props.products[x.productId].storeItemId, this.props.products).supplierMainId === supplierId
            : false);
    }

    get isDisabledByUpdating() : boolean{
        return (Object.keys(this.props.pricesUpdated).length == 0 && Object.keys(this.props.tvasUpdated).length == 0);
    }

    calculatePriceTable(valueP: number, partTable: number){
        if(valueP != 0){
            this.productsInPRails.map(x =>{
                let newPrice = Math.round((this.props.products[x.productId].prices.find(x => x.priceNameId == this.tariffPlain).value * valueP) * 10) / 10;
                newPrice =  newPrice < (Math.ceil(x.buyPrice * 100) / 100) ? (Math.ceil(x.buyPrice * 10) / 10) : newPrice;
                
                if(partTable == 1 && x.price != undefined){
                    this.props.addToUpdatedPricesList(newPrice, x.price);
                }
                if(partTable == 2 && x.price2 != undefined){
                    this.props.addToUpdatedPricesList(newPrice, x.price2);
                }
            });
        }
        this.props.updateConvFac1('');
        this.props.updateConvFac2('');
    }

    public render() {
        return (
            <div >
                <div 
                className="card"
                style={{
                    ...Styles.cardStyle,
                    ...Styles.pageTitle
                }}><span style={{paddingLeft: 30}}>{getText( "SalesTariffTitle")}</span> <HelpHeader /></div>
                <div className="card salestariff-table" style={{ ...Styles.cardStyle, margin: "1%", height: "100%" }}>
                <div className="row" style={{margin: "1% 1% 0.2rem 1%"}}>
                    <div className="col-md-12" style={{display: 'flex', justifyContent: 'flex-end', margin: 5, fontSize: 16}}>
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 16, fontWeight: 'bold'}}
                            disabled={ this.isDisabledByUpdating }
                            onClick={(e) => { 
                                let model: Api.UpdatePricesResultModel = {
                                    pricesUp: this.props.pricesUpdated,
                                    productVatsUp: this.props.tvasUpdated
                                };
                                this.props.saveSalesTariff(new Date().getTime(), model);
                            }}>
                                {getText( "SalesTariffButtonSave")}
                        </button>
                    </div>
                </div>
                <Table
                        tableKey={"salestariff"}
                        loading={this.props.isSaving || this.props.stock.isLoading}
                        style={{ height: '75vh' }}
                        columns={[
                            {
                                id: "general",
                                Header: 
                                <div  style={{
                                    margin: 0, display: "flex", flexDirection: "row", 
                                    columnGap: 4, flexWrap: "wrap",
                                    width: "100%"
                                }}>
                                    <div style={{lineHeight: "40px", flex: "0 0 1" }}>{getText( "StockSource")}: </div>
                                        <div style={{padding: 0, flex: "0 0 1", minWidth: 150 }}>
                                            <div>
                                                <Select options={[{
                                                    label: "------",
                                                    value: null
                                                    }].concat(_.sortBy(_.values(this.props.suppliers)
                                                        .filter(x => this.isInPlanogram(x.supplierId))
                                                        .map(z => ({ label: z.name, value: z.supplierId })), x => x.label))}
                                                        style={{
                                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                                        }}
                                                        value={this.state.supplierMainIdSelected}
                                                        onChange={(newSupplierMainId) => {
                                                            this.setState({ ...this.state, supplierMainIdSelected: newSupplierMainId});
                                                        }}
                                                />
                                            </div>
                                    </div>
                                </div>,
                                columns: [
                                {
                                    id: "name",
                                    accessor: (d) => d.name,
                                    Header: <div>{getText( "SalesTariffTabName")}</div>,
                                    Cell: row => { return (<div style={{textAlign: 'initial', margin: 7}}> {row.value}</div>) },
                                    style: { height: '100%' },
                                    width: 400,
                                },
                                {
                                    id: "pmp",
                                    accessor: (d) => d.pmp.toFixed(2),
                                    Header: <div>{getText("SalesTariffTabPmp")}</div>,
                                    style: { height: '100%' },
                                    width: 85,
                                },
                                {
                                    id: "vatId",
                                    accessor: (d) => d,
                                    Cell: row =>{
                                        return row.value.vatName ?
                                            <Select options={(_.values(this.props.vats)
                                                    .filter(x => this.props.countryVats.some(y => y.vatId == x.vatId))
                                                    .map(z => ({ label: z.name, value: z.vatId })))} 
                                                    style={{
                                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                                    }}
                                                    value={row.value.vatId}
                                                    onChange={(element) => {
                                                        let pV: Api.ProductVatModel = {
                                                            productId: row.value.productId,
                                                            productVatId: row.value.pVatId,
                                                            vatId: element,
                                                            countryId: this.props.countryId
                                                        }   
                                                        this.props.addToUpdatedTVAsList(pV);
                                                    }} 
                                            />
                                        : <div>{""}</div>
                                    },
                                    Header: <div>{getText( "HistoTVA")}</div>,
                                    style: { height: '100%' },
                                }] as Array<SalesTariffColumn>
                            },
                            {
                                id: "pricevalues1",
                                Header: 
                                        <div className="row" style={{margin: "0 1%", display: 'flex', justifyContent: 'space-between'}}>
                                            <div className="col-md-5" style={{paddingLeft: 0}}>
                                                <div className="salesTariffSelect" >
                                                    <Select options={(_.values(this.props.priceNames)
                                                        .map(z => ({ label: z.name, value: z.priceNameId })))}
                                                        value={this.state.priceNameIdSelected1}
                                                        onChange={(priceNameId) => {
                                                            if(priceNameId != this.state.priceNameIdSelected2){
                                                                this.setState({
                                                                    ...this.state,
                                                                    priceNameIdSelected1: priceNameId
                                                                });
                                                            }
                                                        }}
                                                        disabled={ !this.isDisabledByUpdating }
                                                        style={{
                                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                            color: this.isDarkMode() ? "white" : "#3B4370",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <input 
                                                    className="form-control salesTariffInput"
                                                    type={"text"}
                                                    disabled={this.state.priceNameIdSelected1 == this.tariffPlain}
                                                    value={this.props.convFac1}
                                                    onChange={(e) => this.props.updateConvFac1(e.target.value)}
                                                />
                                                <button className="btn btn-primary"
                                                    style={{height: 33, fontSize: 15, fontWeight: 'bold', marginLeft: 5}}
                                                    disabled={this.state.priceNameIdSelected1 == this.tariffPlain}
                                                    onClick={(e) => {
                                                        let aux = parseFloat(this.props.convFac1) || 0;
                                                        this.props.updateConvFac1(aux.toString())
                                                        this.calculatePriceTable(aux, 1);
                                                    }}>
                                                        {getText("SalesTariffButtonCalculate")}
                                                </button>
                                            </div>
                                        </div>,
                                    columns: [
                                        {
                                            id: "sellPrice",
                                            accessor: (d) => d,
                                            Cell: row => {
                                                return row.value.price ?  
                                                 <div>
                                                    <InputNumber value={row.value.price.value.toFixed(2)}
                                                        style={{ width: 50, textAlign: 'center' }}
                                                        onChange={(e) => this.props.addToUpdatedPricesList(e, row.value.price) }
                                                        onBlur={(e) => this.props.addToUpdatedPricesList(e, row.value.price) }/>
                                                </div> 
                                                : <div>{""}</div> },
                                            Header: <div>{getText( "HistoPVTTC")}</div>,
                                            style: { height: '100%' },
                                        },
                                        {
                                            id: "delta",
                                            accessor: (d) => d.delta != null ? d.delta.toFixed(2) : "",
                                            Header: <div>{`% ${getText( "SalesTariffTabDelta")}`}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                        {
                                            id: "marge",
                                            accessor: (d) => d.marge != null ? d.marge.toFixed(2) : "",
                                            Header: <div>{getText( "HistoMargin")}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                        {
                                            id: "marquePercentage",
                                            accessor: (d) => d.marquePercentage != null ? d.marquePercentage.toFixed(2) : "",
                                            Header: <div>{`% ${getText( "SalesTariffTabMarque")}`}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                        {
                                            id: "margePercentage",
                                            accessor: (d) => d.margePercentage != null ? d.margePercentage.toFixed(2) : "",
                                            Header: <div>{`% ${getText( "HistoMargin")}`}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                ] as Array<SalesTariffColumn>
                            },
                            {
                                id: "pricevalues2",
                                Header: 
                                    <div className="row" style={{margin: "0 1%", display: 'flex', justifyContent: 'space-between'}}>
                                        <div className="col-md-5" style={{paddingLeft: 0}}>
                                            <div className="salesTariffSelect" >
                                                <Select options={(_.values(this.props.priceNames)
                                                    .map(z => ({ label: z.name, value: z.priceNameId })))}
                                                    value={this.state.priceNameIdSelected2}
                                                    onChange={(priceNameId) => {
                                                        if(priceNameId != this.state.priceNameIdSelected1){
                                                            this.setState({
                                                                ...this.state,
                                                                priceNameIdSelected2: priceNameId
                                                            });
                                                        }
                                                    }}
                                                    disabled={ !this.isDisabledByUpdating }
                                                    style={{
                                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                        color: this.isDarkMode() ? "white" : "#3B4370",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                className="form-control salesTariffInput"
                                                type={"text"}
                                                disabled={this.state.priceNameIdSelected2 == this.tariffPlain}
                                                value={this.props.convFac2}
                                                onChange={(e) => this.props.updateConvFac2(e.target.value)}
                                            />
                                            <button className="btn btn-primary"
                                                style={{height: 33, fontSize: 15, fontWeight: 'bold', marginLeft: 5}}
                                                disabled={this.state.priceNameIdSelected2 == this.tariffPlain}
                                                onClick={(e) => {
                                                    let aux = parseFloat(this.props.convFac2) || 0;
                                                    this.props.updateConvFac2(aux.toString())
                                                    this.calculatePriceTable(aux, 2);
                                                }}>
                                                    {getText("SalesTariffButtonCalculate")}
                                            </button>
                                        </div>
                                    </div>,
                                    columns: [
                                        {
                                            id: "sellPrice2",
                                            accessor: (d) => d,
                                            Cell: row => {
                                                return row.value.price2 ?  
                                                 <div>
                                                    <InputNumber value={row.value.price2.value.toFixed(2)}
                                                        style={{ width: 50, textAlign: 'center' }}
                                                        onChange={(e) => this.props.addToUpdatedPricesList(e, row.value.price2) }
                                                        onBlur={(e) => this.props.addToUpdatedPricesList(e, row.value.price2) }/>
                                                </div> 
                                                : <div>{""}</div> },
                                            Header: <div>{getText( "HistoPVTTC")}</div>,
                                            style: { height: '100%' },
                                        },
                                        {
                                            id: "delta2",
                                            accessor: (d) => d.delta2 != null ? d.delta2.toFixed(2) : "",
                                            Header: <div>{`% ${getText( "SalesTariffTabDelta")}`}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                        {
                                            id: "marge2",
                                            accessor: (d) => d.marge2 != null ? d.marge2.toFixed(2) : "",
                                            Header: <div>{getText( "HistoMargin")}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                        {
                                            id: "marquePercentage2",
                                            accessor: (d) =>  d.marquePercentage2 != null ? d.marquePercentage2.toFixed(2) : "",
                                            Header: <div>{`% ${getText( "SalesTariffTabMarque")}`}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        },
                                        {
                                            id: "margePercentage2",
                                            accessor: (d) => d.margePercentage2 != null ? d.margePercentage2.toFixed(2) : "",
                                            Header: <div>{`% ${getText( "HistoMargin")}`}</div>,
                                            style: { height: '100%' },
                                            width: 85,
                                        }
                                ] as Array<SalesTariffColumn>
                            },
                        ] as Array<SalesTariffColumn>}
                        pageSize={Math.max(this.productsInPRails.length, 15)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        data={this.productsInPRails}>
                    </Table>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.salesTariff,
    stock: state.stock,
    products: state.seed.seed.products,
    vats: state.seed.seed.vats,
    countryId: state.seed.seed.countryVats[0] ? state.seed.seed.countryVats[0].countryId : 1,
    priceNames: state.seed.seed.priceNames,
    suppliers: state.seed.seed.suppliers, 
    countryVats: state.seed.seed.countryVats,
    productRailsInPlano: StockStore.productRailsInPlanoSelector(state)
}),{ ...SalesTariffStore.actionCreators })(SalesTariff as any)