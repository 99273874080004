import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import PricePeriod from './PricePeriod';
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import HelpHeader from './HelpHeader';
import OfferPromo from './OfferPromo';

type PromotionsProps = PromotionsOwnProps;

interface PromotionsOwnProps {
    selectedTab: string;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class Promotions extends React.Component<PromotionsProps, {}> {
    public render() {
        return (
            <div style={{height: "100%"}}>
                <ConnectedTabs name={"promotions"}>
                    <div className="card" style={{ ...Styles.cardStyle,  ...Styles.pageTitle}}>
                        <div style={{paddingRight: '2%'}}>
                            {getText( "PricePeriodTitle")}
                        </div>
                        <TabLink to="promos" style={{width: '50%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "PricePeriodByPromo")}</div>
                        </TabLink>
                        <TabLink to="periods" style={{width: '50%', marginRight: '1%', padding: '0.2rem 0', fontSize: 14}}>
                            <div style={tabHeadStyle}>{getText( "PricePeriodByPeriod")}</div>
                        </TabLink>
                        <HelpHeader />
                    </div>
                    <TabContent for="promos" style={{ height: "100%"}}>
                        {this.props.selectedTab && <OfferPromo/>}
                    </TabContent>
                    <TabContent for="periods" style={{ height: "100%"}}>
                        <PricePeriod />
                    </TabContent>
                </ConnectedTabs>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    selectedTab: state.tabs["promotions"] 
        ? state.tabs["promotions"].selectedTab 
        : "periods"
} as PromotionsOwnProps), null)(Promotions);