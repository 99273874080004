import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface PrintState {
    isLoading: boolean;
    requestTime?: number;
    state?: Api.PrintHandlerState;
    printEnabledState: {
        isLoading: boolean;
        requestTime?: number;
    };
    printTextState: {
        isLoading: boolean;
        requestTime?: number;
    }
}

interface RequestPrintState {
    type: "REQUEST_PRINT_STATE";
    payload: { requestTime: number }
}
interface ReceivePrintState {
    type: "RECEIVE_PRINT_STATE";
    payload: { requestTime: number; state?: Api.PrintHandlerState };
    error?: any;
}
export interface ReceivePrintStateUpdated {
    type: "RECEIVE_PRINT_STATE_UPDATED";
    payload: { state?: Api.PrintHandlerState };
}


interface RequestUpdatePrintEnabled {
    type: "REQUEST_UPDATE_PRINT_ENABLED";
    payload: { requestTime: number; };
}
export interface ReceiveUpdatePrintEnabled {
    type: "RECEIVE_UPDATE_PRINT_ENABLED";
    payload: { value: boolean; requestTime: number; };
    error?: any;
}

interface RequestPrintText {
    type: "REQUEST_PRINT_TEXT";
    payload: { requestTime: number; };
}
interface ReceivePrintText {
    type: "RECEIVE_PRINT_TEXT";
    payload: { requestTime: number; };
    error?: any;
}

type KnownAction = RequestPrintState
    | ReceivePrintState
    | RequestUpdatePrintEnabled
    | ReceiveUpdatePrintEnabled
    | RequestPrintText
    | ReceivePrintStateUpdated
    | ReceivePrintText;

export const actionCreators = {
    requestPrintState: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PrintApi();
        let fetch = api.getState({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(state => {
                dispatch({
                    type: "RECEIVE_PRINT_STATE",
                    payload: { requestTime: requestTime, state: state }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_PRINT_STATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_PRINT_STATE",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requesUpdatePrintEnabled: (requestTime: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PrintApi();
        let fetchTask = api.updatePrintEnabled({
            value: value
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_PRINT_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: value
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_PRINT_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: !value
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_PRINT_ENABLED",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestPrintText: (requestTime: number, value: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PrintApi();
        let fetchTask = api.printText({
            model: {
                includeImage: true,
                content: value
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_PRINT_TEXT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_PRINT_TEXT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_PRINT_TEXT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
}

const unloadedState: PrintState = {
    isLoading: false,
    printEnabledState: {
        isLoading: false
    },
    printTextState: {
        isLoading: false
    }
}


export const reducer: Reducer<PrintState> = (state: PrintState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_PRINT_STATE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_PRINT_STATE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                state: action.error
                    ? state.state
                    : action.payload.state
            };
        case "REQUEST_UPDATE_PRINT_ENABLED":
            return {
                ...state,
                printEnabledState: {
                    ...state.printEnabledState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_PRINT_ENABLED":
            if (action.payload.requestTime !== state.printEnabledState.requestTime)
                return state;

            return {
                ...state,
                printEnabledState: {
                    ...state.printEnabledState,
                    isLoading: false
                }
            };
        case "REQUEST_PRINT_TEXT":
            return {
                ...state,
                printTextState: {
                    ...state.printTextState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_PRINT_TEXT":
            if (action.payload.requestTime !== state.printTextState.requestTime)
                return state;

            return {
                ...state,
                printTextState: {
                    ...state.printTextState,
                    isLoading: false
                }
            };
        case "RECEIVE_PRINT_STATE_UPDATED":
            return {
                ...state,
                state: action.payload.state
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}