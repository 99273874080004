import * as Api from '../api/api';
import * as React from 'react';
import Moment from 'moment';
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import _ from 'lodash';

interface HistoryProductReceptionProps {
    storeType: string;
    isLoading: boolean;
    seed: Api.SeedMngModel;
    selectedIndex: number;
    dayStoreDatas: Array<Api.DayStoreDataModel>;
    updateSelectedIndex: (value: number) => void;
    supplierOrders: Array<Api.SupplierOrderModel>;
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
}

type Column = ReactTable.Column
    & {
        accessor: (d: Api.StoreProductReceptionModel) => any;
        style?: React.CSSProperties;
    };
const rowStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', paddingBottom: '1%'};

export default class HistoryProductReception extends React.Component<HistoryProductReceptionProps, {}> {

    get selectedDayStoreData(): Api.DayStoreDataModel {
        return this.props.dayStoreDatas[this.props.selectedIndex];
    }

    supplierOrderName(supplierOrderId: number): string {
        let supplierOrder = this.props.supplierOrders.find(x => x.supplierOrderId == supplierOrderId);
        if(supplierOrder)
            return supplierOrder.name;
        return '';
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{...rowStyle}}>
                <div className="heightHisto" style={{marginTop: 2, paddingLeft: '1%', overflow: 'auto', width: '20%'}}>
                    {this.props.dayStoreDatas.map((dayStoreData, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: xi === this.props.selectedIndex
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.updateSelectedIndex(xi)}>
                        {Moment(dayStoreData.dayDate).locale(getCurrentLang()).format("dddd DD / MM / YYYY")}
                    </div>)}
                </div>
                <div style={{paddingLeft: '1%', width: '100%'}}>
                    <Table className="heightHisto"
                        style={{margin: 0}}
                        key={"historyReceptions"}
                        loading={this.props.isLoading}
                        pageSize={this.selectedDayStoreData && Math.max(this.selectedDayStoreData.storeProductReceptions.length, 20)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        columns={[
                            {
                                Header: <div>{getText( "HistoReorderDate")}</div>,
                                id: 'receptionDate',
                                accessor: x => x.receptionDate,
                                Cell: row => <div>{Moment(row.value).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY HH:mm:ss")}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoWord")}</div>,
                                id: 'productId',
                                accessor: x => x,
                                Cell: row => {
                                    let data = row.value as Api.StoreProductReceptionModel;
                                    return this.props.storeType == "Ximiti" ?
                                        <div>{ this.props.seed.products[data.productId].name }</div>
                                        : <div>{ this.props.seed.externalProducts[this.props.productSuppliers[data.productSupplierId].externalProductId].name }</div>
                                },
                            },
                            {
                                Header: <div>{getText( "OrderNom")}</div>,
                                id: 'supplierOrderName',
                                accessor: x => x.supplierOrderId ? this.supplierOrderName(x.supplierOrderId) : 'Réception manuelle',
                            },
                            {
                                Header: <div>{getText( "HistoQty")}</div>,
                                id: 'quantity',
                                accessor: x => x.quantity,
                            },
                            {
                                Header: <div>{getText( "HistoSource")}</div>,
                                id: 'productSupplierId',
                                accessor: x => x,
                                Cell: row => {
                                    let data = row.value as Api.StoreProductReceptionModel;
                                    if(this.props.storeType == "Ximiti"){ 
                                        let product = this.props.seed.products[data.productId]
                                         return <div>{product.productSuppliers.some(x => x.productSupplierId === data.productSupplierId)
                                                ? this.props.seed.suppliers[product.productSuppliers
                                                    .find(x => x.productSupplierId === data.productSupplierId).supplierMainId].name
                                                : "N/A"}</div>
                                    }
                                    else {return <div>{this.props.seed.suppliers[this.props.productSuppliers[data.productSupplierId].supplierMainId].name}</div>}
                                },
                            },
                        ] as Array<Column>}
                        data={
                            this.selectedDayStoreData && this.selectedDayStoreData.storeProductReceptions 
                            ? this.selectedDayStoreData.storeProductReceptions
                            : []}
                        />
                </div>
            </div>
        );
    }
}