import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/texts';
import Moment from 'moment';

type NeoEventFilterFormProps = ReduxForm.InjectedFormProps<Api.NeoEventFilter, {}, {}>
    & NeoEventFilterFormOwnProps
    & NeoEventFilterFormExternalProps;

interface NeoEventFilterFormExternalProps {
    onSubmit: (values: Api.NeoEventFilter) => any;
}

interface NeoEventFilterFormOwnProps {
    fromDate: Date;
}

const formStyle: React.CSSProperties = { width: '100%' }
const fieldProps: React.CSSProperties = { display: "flex", alignItems: "center", marginLeft: 3 };

class NeoEventFilterForm extends React.Component<NeoEventFilterFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}
                style={{...formStyle, zIndex: 99}}
                contentContainerStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'space-between'
                }}>
                <div style={fieldProps}>
                    {/* <div style={{marginRight: 3}}> {getText( "HomeSince")} </div>
                    <div >
                        <ReduxForm.Field
                            name="fromDate"
                            className="form-control"
                            validate={[FormField.required]}
                            showTimeSelect={true}
                            component={FormField.DatePickerField} />
                    </div> */}
                </div>
                <div style={fieldProps}>
                    {/* <div style={{marginRight: 3}}> {getText( "HomeUntil")}</div>
                    <div >
                        <ReduxForm.Field
                            name="toDate"
                            className="form-control"
                            minDate={this.props.fromDate}
                            showTimeSelect={true}
                            validate={[]}
                            component={FormField.DatePickerField} />
                    </div> */}
                </div>
                <div style={fieldProps}>
                    <div style={{marginRight: 3}}>  {getText( "HomeCode")}</div>
                    <div >
                        <ReduxForm.Field
                            name="codes"
                            type={"text"}
                            placeholder={"001;013;143..."}
                            style= {{height: 32}}
                            //pattern={"^\d+(;\d+)*$"}
                            className="form-control"
                            component={FormField.renderField} />
                    </div>
                </div>                
                <div style={fieldProps}>
                    <div style={{marginRight: 3}}> {getText( "HomeSearch")}</div>
                    <div >
                        <ReduxForm.Field
                            name="searchText"
                            type={"text"}
                            style= {{height: 32}}
                            className="form-control"
                            component={FormField.renderField} />
                    </div>
                </div>
                {/* <div style={fieldProps}>
                    <div > {getText( "HomeCode")}&nbsp; </div>
                    <div >
                        <ReduxForm.Field
                            name="code"
                            type="number"
                            className="form-control"
                            allowUndefined={true}
                            validate={[]}
                            component={FormField.NumberField} />
                    </div>
                </div> */}
                {/* <div style={fieldProps}>
                    <div>Type :</div>
                    <div>
                        <ReduxForm.Field
                            name="source"
                            className="form-control"
                            validate={[]}
                            component={FormField.getSelectField([
                                { label: "-----", value: null },
                                { label: "PC", value: "Computer" },
                                { label: "Exploitant", value: "Management" },
                                { label: "TPA", value: "Card" },
                                { label: "TPN", value: "Cash" },
                                { label: "IHM", value: "Client" },
                                { label: "Imprimante", value: "Printer" },
                                { label: "Robot", value: "Robot" },
                            ] as Array<{ label: string, value: Api.NeoEventCodeModelSourceEnum }>)} />
                    </div>
                </div> */}
                <button className="btn btn-primary btn-sm"
                    style={{width: '100px', marginLeft: 3}}
                    disabled={this.props.submitting
                        || this.props.pristine}
                    type={"submit"}>
                        {getText( "HomeFilter")}
                </button>
            </FormField.BaseForm>
        );
    }
}

const form = ReduxForm.reduxForm({
    form: 'neoEventFilter',
    destroyOnUnmount: false,
    enableReinitialize: true
})(NeoEventFilterForm as any);
const formSelector = ReduxForm.formValueSelector('neoEventFilter');


export default connect((state: ApplicationState) => {
    return {
        initialValues: {
            ...state.home.neoEvents.filter,
            fromDate: Moment().add().startOf('day').toDate() //just visual
        } as Api.NeoEventFilter,
        fromDate: formSelector(state, "fromDate")
    } as NeoEventFilterFormOwnProps
}, null)(form as any) as any as React.ComponentClass<NeoEventFilterFormExternalProps>;
