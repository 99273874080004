import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface CashState {
    isLoading: boolean;
    requestTime?: number;
    state?: Api.CashHandlerStateModel;
    hoppers: {
        isLoading: boolean;
        requestTime?: number;
        hoppers: Array<Api.HmiMoneyHopperModel>;
    },
    hoppersRecharge: {
        [id: number]: HopperActionState
    },
    hoppersEmpty: {
        [id: number]: HopperActionState
    },
    cashEnabledState: {
        isLoading: boolean;
        requestTime?: number
    },
    testState: {
        solvency: number;
        collect: number;
        paymentRunning: boolean;
        solvencyCompleted: boolean;
        startPaymentState: {
            isLoading: boolean;
            requestTime?: number;
        };
        collectAmountState: {
            isLoading: boolean;
            requestTime?: number;
        };
        cancelPaymentState: {
            isLoading: boolean;
            requestTime?: number;
        };
        stopPaymentState: {
            isLoading: boolean;
            requestTime?: number;
        };
    }
}

export interface HopperActionState {
    value: number;
    isLoading: boolean;
    requestTime?: number;
    resetAmount?: number;
}

interface RequestCashState {
    type: "REQUEST_CASH_STATE";
    payload: { requestTime: number }
}
interface ReceiveCashState {
    type: "RECEIVE_CASH_STATE";
    payload: {
        requestTime: number;
        state?: Api.CashHandlerStateModel
    };
    error?: any;
}
export interface ReceiveCashStateUpdated {
    type: "RECEIVE_CASH_STATE_UPDATED";
    payload: { state?: Api.CashHandlerStateModel };
}

interface HomeRequestHoppers {
    type: "HOME_REQUEST_HOPPERS";
    payload: { requestTime: number; }
}
interface HomeReceiveHoppers {
    type: "HOME_RECEIVE_HOPPERS";
    payload: {
        requestTime: number;
        hoppers: Array<Api.HmiMoneyHopperModel>
    };
    error?: any
}

interface RequestDayMoneyTraffics {
    type: "REQUEST_DAY_MONEY_TRAFFICS";
    payload: { requestTime: number }
}
interface ReceiveDayMoneyTraffics {
    type: "RECEIVE_DAY_MONEY_TRAFFICS";
    payload: {
        requestTime: number;
        dayMoneyTraffics: Array<{}>
    }
    error?: any
}

interface RequestRechargeHopper {
    type: "REQUEST_RECHARGE_HOPPER";
    payload: { requestTime: number; hmiMoneyHopperId: number; }
}
export interface ReceiveRechargeHopper {
    type: "RECEIVE_RECHARGE_HOPPER";
    payload: {
        requestTime: number;
        hmiHopperTraffic?: Api.HmiHopperTrafficModel;
        hmiMoneyHopperId: number;
    }
    error?: any
}

interface RequestEmptyHoppers {
    type: "REQUEST_EMPTY_HOPPERS";
    payload: { requestTime: number; hmiMoneyHopperId: number; }
}
export interface ReceiveEmptyHoppers {
    type: "RECEIVE_EMPTY_HOPPERS";
    payload: {
        requestTime: number;
        hmiHopperTraffics?: Array<Api.HmiHopperTrafficModel>;
        hmiMoneyHopperId: number;
    }
    error?: any
}

interface RequestResetHoppers {
    type: "REQUEST_RESET_HOPPERS";
    payload: { requestTime: number; hmiMoneyHopperId: number; }
}
interface ReceiveResetHoppers {
    type: "RECEIVE_RESET_HOPPERS";
    payload: {
        requestTime: number;
        hmiHopperTraffics?: Array<Api.HmiHopperTrafficModel>;
        hmiMoneyHopperId: number;
    }
    error?: any
}

interface UpdateHopperRecharge {
    type: "UPDATE_HOPPER_RECHARGE";
    payload: { value: number; hmiMoneyHopperId: number; };
}
interface UpdateHopperEmpty {
    type: "UPDATE_HOPPER_EMPTY";
    payload: { value: number; hmiMoneyHopperId: number; };
}

interface RequestUpdateCashEnabled {
    type: "REQUEST_UPDATE_CASH_ENABLED";
    payload: { requestTime: number; };
}
export interface ReceiveUpdateCashEnabled {
    type: "RECEIVE_UPDATE_CASH_ENABLED";
    payload: { value: boolean; requestTime: number; };
    error?: any;
}

interface RequestUpdateCashBillEnabled {
    type: "REQUEST_UPDATE_CASH_BILL_ENABLED";
    payload: { requestTime: number; };
}
export interface ReceiveUpdateCashBillEnabled {
    type: "RECEIVE_UPDATE_CASH_BILL_ENABLED";
    payload: { value: boolean; requestTime: number; };
    error?: any;
}

interface RequestCashStartTestPayment {
    type: "REQUEST_CASH_START_TESTPAYMENT";
    payload: { requestTime: number; };
}
interface ReceiveCashStartTestPayment {
    type: "RECEIVE_CASH_START_TESTPAYMENT";
    payload: { requestTime: number; };
    error?: any;
}

interface RequestCashCancelPayment {
    type: "REQUEST_CASH_CANCEL_PAYMENT";
    payload: { requestTime: number; };
}
interface ReceiveCashCancelPayment {
    type: "RECEIVE_CASH_CANCEL_PAYMENT";
    payload: { requestTime: number; };
    error?: any;
}

interface RequestCashCollectAmount {
    type: "REQUEST_CASH_COLLECT_AMOUNT";
    payload: { requestTime: number; };
}
interface ReceiveCashCollectAmount {
    type: "RECEIVE_CASH_COLLECT_AMOUNT";
    payload: { requestTime: number; };
    error?: any;
}

export interface ReceiveCashSolvencyChanged {
    type: "RECEIVE_CASH_SOLVENCY_CHANGED";
    payload: { value: number; };
}
export interface ReceiveCashPaymentCancelled {
    type: "RECEIVE_CASH_PAYMENT_CANCELLED";
}

//export interface ReceiveCashAmountCollected {
//    type: "RECEIVE_CASH_AMOUNT_COLLECTED";
//    payload: { value: number; };
//}
interface CashUpdateSolvencyAmount {
    type: "CASH_UPDATE_SOLVENCY_AMOUNT";
    payload: { value: number; };
}
interface CashUpdateCollectAmount {
    type: "CASH_UPDATE_COLLECT_AMOUNT";
    payload: { value: number; };
}

interface RequestCashStopPaymentSession {
    type: "REQUEST_CASH_STOPPAYMENTSESSION";
    payload: { requestTime: number; };
}
interface ReceiveCashStopPaymentSession {
    type: "RECEIVE_CASH_STOPPAYMENTSESSION";
    payload: { requestTime: number; };
    error?: any;
}



export interface ReceiveSolvencyCashCompleted {
    type: "RECEIVE_CASH_SOLVENCY_COMPLETED";
    payload: { value: number }
}

type KnownAction = RequestCashState
    | ReceiveCashState
    | HomeRequestHoppers
    | HomeReceiveHoppers
    | RequestRechargeHopper
    | ReceiveRechargeHopper
    | RequestEmptyHoppers
    | ReceiveEmptyHoppers
    | UpdateHopperRecharge
    | UpdateHopperEmpty
    | RequestUpdateCashEnabled
    | ReceiveUpdateCashEnabled
    | RequestResetHoppers
    | ReceiveResetHoppers
    | RequestUpdateCashBillEnabled
    | ReceiveUpdateCashBillEnabled
    | RequestCashStopPaymentSession
    | ReceiveCashStopPaymentSession

    | RequestCashStartTestPayment
    | ReceiveCashStartTestPayment
    | RequestCashCancelPayment
    | ReceiveCashCancelPayment
    | RequestCashCollectAmount
    | ReceiveCashCollectAmount
    | ReceiveCashSolvencyChanged
    | ReceiveCashPaymentCancelled
    //| ReceiveCashAmountCollected
    | CashUpdateSolvencyAmount
    | CashUpdateCollectAmount
    | ReceiveCashStateUpdated
    | ReceiveSolvencyCashCompleted
    ;

export const actionCreators = {
    requestCashState: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetch = api.getState({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(state => {
                dispatch({
                    type: "RECEIVE_CASH_STATE",
                    payload: { requestTime: requestTime, state: state }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CASH_STATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CASH_STATE",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestHoppers: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.PaymentApi();
        let fetch = api.getHoppers(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hoppers => {
                dispatch({
                    type: "HOME_RECEIVE_HOPPERS",
                    payload: { requestTime: requestTime, hoppers: hoppers }
                });
            })
            .catch(err => {
                dispatch({
                    type: "HOME_RECEIVE_HOPPERS",
                    payload: { requestTime: requestTime, hoppers: [] },
                    error: err
                });
            });

        dispatch({ type: "HOME_REQUEST_HOPPERS", payload: { requestTime: requestTime } });
        addTask(fetch);
        return fetch;
    },
    updateHopperRecharge: (hmiMoneyHopperId: number, value: number) => <UpdateHopperRecharge>{
        type: "UPDATE_HOPPER_RECHARGE",
        payload: {
            hmiMoneyHopperId: hmiMoneyHopperId,
            value: value
        }
    },
    updateHopperEmpty: (hmiMoneyHopperId: number, value: number) => <UpdateHopperEmpty>{
        type: "UPDATE_HOPPER_EMPTY",
        payload: {
            hmiMoneyHopperId: hmiMoneyHopperId,
            value: value
        }
    },
    requestRechargeHopper: (requestTime: number, hmiMoneyHopperId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.rechargeHopper({
            model: {
                hmiMoneyHopperId: hmiMoneyHopperId,
                quantity: getState().cash.hoppersRecharge[hmiMoneyHopperId]
                    ? getState().cash.hoppersRecharge[hmiMoneyHopperId].value
                    : 0,
                completeEmptying:false
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hmiHopperTraffic => {
                dispatch({
                    type: "RECEIVE_RECHARGE_HOPPER",
                    payload: {
                        hmiMoneyHopperId: hmiMoneyHopperId,
                        requestTime: requestTime,
                        hmiHopperTraffic: hmiHopperTraffic
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_RECHARGE_HOPPER",
                    payload: {
                        hmiMoneyHopperId: hmiMoneyHopperId,
                        requestTime: requestTime
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_RECHARGE_HOPPER",
            payload: {
                hmiMoneyHopperId: hmiMoneyHopperId,
                requestTime: requestTime
            }
        });
        return fetchTask;
    },
    requestEmptyHoppers: (requestTime: number, hmiMoneyHopperId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.emptyHoppers({
            model: [{
                hmiMoneyHopperId: hmiMoneyHopperId,
                quantity: getState().cash.hoppersEmpty[hmiMoneyHopperId]
                    ? getState().cash.hoppersEmpty[hmiMoneyHopperId].value
                    : 0,
                completeEmptying: false
            }]
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hmiHopperTraffics => {
                dispatch({
                    type: "RECEIVE_EMPTY_HOPPERS",
                    payload: {
                        hmiMoneyHopperId: hmiMoneyHopperId,
                        requestTime: requestTime,
                        hmiHopperTraffics: hmiHopperTraffics
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_EMPTY_HOPPERS",
                    payload: {
                        hmiMoneyHopperId: hmiMoneyHopperId,
                        requestTime: requestTime
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_EMPTY_HOPPERS",
            payload: {
                hmiMoneyHopperId: hmiMoneyHopperId,
                requestTime: requestTime
            }
        });
        return fetchTask;
    },
    requestResetHopper: (requestTime: number, hmiMoneyHopperId: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.emptyHoppers({
            model: [{
                hmiMoneyHopperId: hmiMoneyHopperId,
                quantity: 100000,
                completeEmptying:true
            }]
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(hmiHopperTraffics => {
                dispatch({
                    type: "RECEIVE_RESET_HOPPERS",
                    payload: {
                        hmiMoneyHopperId: hmiMoneyHopperId,
                        requestTime: requestTime,
                        hmiHopperTraffics: hmiHopperTraffics
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_RESET_HOPPERS",
                    payload: {
                        hmiMoneyHopperId: hmiMoneyHopperId,
                        requestTime: requestTime
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_RESET_HOPPERS",
            payload: {
                hmiMoneyHopperId: hmiMoneyHopperId,
                requestTime: requestTime
            }
        });
        return fetchTask;
    },
    requesUpdateCashEnabled: (requestTime: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.updateCashEnabled({
            value: value
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_CASH_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: value
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_CASH_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: !value
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_CASH_ENABLED",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requesUpdateCashBillEnabled: (requestTime: number, value: boolean): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.updateCashBillEnabled({
            value: value
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_UPDATE_CASH_BILL_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: value
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_CASH_BILL_ENABLED",
                    payload: {
                        requestTime: requestTime,
                        value: !value
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_CASH_BILL_ENABLED",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestStartCashTestPayment: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.startTestPayment({
            model: {
                value: getState().cash.testState.solvency
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CASH_START_TESTPAYMENT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CASH_START_TESTPAYMENT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CASH_START_TESTPAYMENT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestCollectAmount: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.askCollectTestPayment({
            value: getState().cash.testState.collect
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CASH_COLLECT_AMOUNT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CASH_COLLECT_AMOUNT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CASH_COLLECT_AMOUNT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    requestCancelPayment: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.cancelTestPayment({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CASH_CANCEL_PAYMENT",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CASH_CANCEL_PAYMENT",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CASH_CANCEL_PAYMENT",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    },
    updateCashCollectAmount: (value: number) => <CashUpdateCollectAmount>{
        type: "CASH_UPDATE_COLLECT_AMOUNT",
        payload: { value: value }
    },
    updateCashSolvencyAmount: (value: number) => <CashUpdateSolvencyAmount>{
        type: "CASH_UPDATE_SOLVENCY_AMOUNT",
        payload: { value: value }
    },
    requestCashStopPaymentSession: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.CashApi();
        let fetchTask = api.stopPaymentSession({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_CASH_STOPPAYMENTSESSION",
                    payload: {
                        requestTime: requestTime,
                    }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CASH_STOPPAYMENTSESSION",
                    payload: {
                        requestTime: requestTime,
                    },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_CASH_STOPPAYMENTSESSION",
            payload: {
                requestTime: requestTime,
            }
        });
        return fetchTask;
    }
}

const unloadedState: CashState = {
    isLoading: false,
    hoppers: {
        isLoading: false,
        hoppers: []
    },
    hoppersRecharge: {},
    hoppersEmpty: {},
    cashEnabledState: {
        isLoading: false
    },

    testState: {
        collect: 0,
        solvency: 0,
        solvencyCompleted: false,
        paymentRunning: false,
        cancelPaymentState: {
            isLoading: false
        },
        collectAmountState: {
            isLoading: false
        },
        startPaymentState: {
            isLoading: false
        },
        stopPaymentState: {
            isLoading: false
        }
    }
}


export const reducer: Reducer<CashState> = (state: CashState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CASH_STATE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_CASH_STATE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                state: action.error
                    ? state.state
                    : action.payload.state
            };
        case "RECEIVE_CASH_STATE_UPDATED":
            return {
                ...state,
                state: action.payload.state
            };
        case "HOME_REQUEST_HOPPERS":
            return {
                ...state,
                hoppers: {
                    ...state.hoppers,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "HOME_RECEIVE_HOPPERS":
            if (action.payload.requestTime !== state.hoppers.requestTime)
                return state;

            return {
                ...state,
                hoppers: {
                    ...state.hoppers,
                    isLoading: false,
                    hoppers: action.error
                        ? state.hoppers.hoppers
                        : action.payload.hoppers
                }
            };
        case "UPDATE_HOPPER_RECHARGE":
            return {
                ...state,
                hoppersRecharge: {
                    ...state.hoppersRecharge,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersRecharge[action.payload.hmiMoneyHopperId],
                        value: action.payload.value
                    }
                }
            };
        case "UPDATE_HOPPER_EMPTY":
            return {
                ...state,
                hoppersEmpty: {
                    ...state.hoppersEmpty,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersEmpty[action.payload.hmiMoneyHopperId],
                        value: action.payload.value
                    }
                }
            };
        case "REQUEST_RECHARGE_HOPPER":
            return {
                ...state,
                hoppersRecharge: {
                    ...state.hoppersRecharge,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersRecharge[action.payload.hmiMoneyHopperId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_RECHARGE_HOPPER":
            return {
                ...state,
                hoppersRecharge: {
                    ...state.hoppersRecharge,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersRecharge[action.payload.hmiMoneyHopperId],
                        isLoading: state.hoppersRecharge[action.payload.hmiMoneyHopperId].requestTime === action.payload.requestTime
                            ? false
                            : state.hoppersRecharge[action.payload.hmiMoneyHopperId].isLoading,
                        value: 0
                    }
                },
                hoppers: action.error ? state.hoppers : {
                    ...state.hoppers,
                    hoppers: state.hoppers.hoppers.map(x => x.hmiMoneyHopperId === action.payload.hmiMoneyHopperId
                        ? {
                            ...x,
                            quantity: x.quantity + action.payload.hmiHopperTraffic.quantity
                        }
                        : x)
                }
            };
        case "REQUEST_EMPTY_HOPPERS":
            return {
                ...state,
                hoppersEmpty: {
                    ...state.hoppersEmpty,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersEmpty[action.payload.hmiMoneyHopperId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_EMPTY_HOPPERS":
            return {
                ...state,
                hoppersEmpty: {
                    ...state.hoppersEmpty,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersEmpty[action.payload.hmiMoneyHopperId],
                        isLoading: state.hoppersEmpty[action.payload.hmiMoneyHopperId].requestTime === action.payload.requestTime
                            ? false
                            : state.hoppersEmpty[action.payload.hmiMoneyHopperId].isLoading,
                        value: 0
                    }
                },
                hoppers: action.error ? state.hoppers : {
                    ...state.hoppers,
                    hoppers: state.hoppers.hoppers.map(x => {
                        let traffic = action.payload.hmiHopperTraffics
                            .find(y => y.hmiMoneyHopperId === x.hmiMoneyHopperId);
                        if (traffic) {
                            return {
                                ...x,
                                quantity: Math.max(x.quantity - traffic.quantity, 0)
                            };
                        } else {
                            return x;
                        }
                    })
                }
            };
        case "REQUEST_RESET_HOPPERS":
            return {
                ...state,
                hoppersEmpty: {
                    ...state.hoppersEmpty,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersEmpty[action.payload.hmiMoneyHopperId],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_RESET_HOPPERS":
            return {
                ...state,
                hoppersEmpty: {
                    ...state.hoppersEmpty,
                    [action.payload.hmiMoneyHopperId]: {
                        ...state.hoppersEmpty[action.payload.hmiMoneyHopperId],
                        isLoading: state.hoppersEmpty[action.payload.hmiMoneyHopperId].requestTime === action.payload.requestTime
                            ? false
                            : state.hoppersEmpty[action.payload.hmiMoneyHopperId].isLoading,
                        resetAmount: action.payload.hmiHopperTraffics
                            .find(y => y.hmiMoneyHopperId === action.payload.hmiMoneyHopperId)
                            ? action.payload.hmiHopperTraffics
                                .find(y => y.hmiMoneyHopperId === action.payload.hmiMoneyHopperId).quantity
                            : state.hoppersEmpty[action.payload.hmiMoneyHopperId].resetAmount
                    }
                },
                hoppers: action.error ? state.hoppers : {
                    ...state.hoppers,
                    hoppers: state.hoppers.hoppers.map(x => {
                        let traffic = action.payload.hmiHopperTraffics
                            .find(y => y.hmiMoneyHopperId === x.hmiMoneyHopperId);
                        if (traffic) {
                            return {
                                ...x,
                                quantity: Math.max(x.quantity - traffic.quantity, 0)
                            };
                        } else {
                            return x;
                        }
                    })
                }
            };
        case "REQUEST_UPDATE_CASH_ENABLED":
            return {
                ...state,
                cashEnabledState: {
                    ...state.cashEnabledState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_CASH_ENABLED":
            if (action.payload.requestTime !== state.cashEnabledState.requestTime)
                return state;

            return {
                ...state,
                cashEnabledState: {
                    ...state.cashEnabledState,
                    isLoading: false
                }
            };
        case "REQUEST_UPDATE_CASH_BILL_ENABLED":
            return {
                ...state,
                cashEnabledState: {
                    ...state.cashEnabledState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_UPDATE_CASH_BILL_ENABLED":
            return {
                ...state,
                cashEnabledState: {
                    ...state.cashEnabledState,
                    isLoading: false,
                }
            };
        case "REQUEST_CASH_START_TESTPAYMENT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvencyCompleted: false,
                    startPaymentState: {
                        ...state.testState.startPaymentState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CASH_START_TESTPAYMENT":
            if (state.testState.startPaymentState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    paymentRunning: action.error
                        ? state.testState.paymentRunning
                        : true,
                    startPaymentState: {
                        ...state.testState.startPaymentState,
                        isLoading: false,
                    }
                }
            };
        case "REQUEST_CASH_COLLECT_AMOUNT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    collectAmountState: {
                        ...state.testState.collectAmountState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CASH_COLLECT_AMOUNT":
            if (state.testState.collectAmountState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    collectAmountState: {
                        ...state.testState.collectAmountState,
                        isLoading: false,
                    },
                    paymentRunning: false,
                    collect: 0
                }
            };
        case "REQUEST_CASH_CANCEL_PAYMENT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    cancelPaymentState: {
                        ...state.testState.collectAmountState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CASH_CANCEL_PAYMENT":
            if (state.testState.cancelPaymentState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    paymentRunning: action.error
                        ? state.testState.paymentRunning
                        : false,
                    cancelPaymentState: {
                        ...state.testState.collectAmountState,
                        isLoading: false
                    }
                }
            };
        case "RECEIVE_CASH_PAYMENT_CANCELLED":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    paymentRunning: false
                }
            };
        case "CASH_UPDATE_SOLVENCY_AMOUNT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvency: action.payload.value
                }
            };
        case "CASH_UPDATE_COLLECT_AMOUNT":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    collect: action.payload.value
                }
            };
        case "RECEIVE_CASH_SOLVENCY_CHANGED":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvency: action.payload.value
                }
            };
        //case "RECEIVE_CASH_AMOUNT_COLLECTED":
        //    return {
        //        ...state,
        //        testState: {
        //            ...state.testState,
        //            solvency: action.payload.value,
        //            paymentRunning: false,
        //            collect: 0
        //        }
        //    };
        case "REQUEST_CASH_STOPPAYMENTSESSION":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    stopPaymentState: {
                        ...state.testState.stopPaymentState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_CASH_STOPPAYMENTSESSION":
            if (action.payload.requestTime !== state.testState.stopPaymentState.requestTime)
                return state;

            return {
                ...state,
                testState: {
                    ...state.testState,
                    stopPaymentState: {
                        ...state.testState.stopPaymentState,
                        isLoading: false,
                    }
                }
            };
        case "RECEIVE_CASH_SOLVENCY_COMPLETED":
            return {
                ...state,
                testState: {
                    ...state.testState,
                    solvencyCompleted: true
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}