import * as React from 'react';
import * as Api from '../api/api';
import * as CardStore from '../store/Card';
import * as _ from 'lodash';
import EventViewer from "./EventViewer";
import InputNumber from "./InputNumber";
import CheckBox from "./CheckBox";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canEditSettings, canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';

type CardTestProps = CardTestOwnProps
    & CardStore.CardState
    & typeof CardStore.actionCreators;

interface CardTestOwnProps {
    neoEvents: Array<Api.NeoEventModel>;
    neoEventCodes: { [id: number]: Api.NeoEventCodeModel };
    disabled: boolean;
}

const relevantCode = [601, 603, 604, 606, 607, 613];

interface CardTestState {
}

const inputStyle: React.CSSProperties = {height: 32}
const buttonStyle: React.CSSProperties = {height: 32, fontSize: 13, width: '100%', fontWeight: 'bold', margin: 0}
const spaceDiv: React.CSSProperties = {minHeight: 20}

class CardTest extends React.Component<CardTestProps, CardTestState> {
    constructor(props: CardTestProps) {
        super(props);
    }

    public render() {
        return (
            <div className="card" style={{...Styles.cardStyle, padding: '1%', marginTop: '1%'}}>
                <div style={{fontWeight: "bold", fontSize: 22, margin: 'auto'}}>
                    {getText( "MaintenanceCreditCard")}
                </div>
                {this.props.state && <div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group" style={{margin: 0}}>
                                <label>{getText( "MaintenanceAmount")}</label>
                                <InputNumber value={this.props.testState.solvency}
                                    style={inputStyle}
                                    onChange={(value) => this.props.updateCardSolvencyAmount(value)} />
                            </div>
                        </div>
                        <div className="col-md-3" style={{padding: 0}}>
                            <div style={spaceDiv}></div>
                            <button disabled={this.props.testState.paymentRunning || this.props.disabled}
                                onClick={(e) => this.props.requestStartCardTestPayment(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenanceSolvencyRequest")}
                            </button>
                        </div>
                        <div className="col-md-2">
                            <div style={spaceDiv}></div>
                            <button disabled={(!this.props.testState.paymentRunning && this.props.state.state === "Idle")
                                || this.props.disabled}
                                    onClick={(e) => this.props.requestCancelPayment(new Date().getTime())}
                                    className="btn btn-primary"
                                    style={buttonStyle}>
                                   {getText( "MaintenanceAbandonment")}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <CheckBox label={getText( "MaintenanceCreditCardTest")}
                                style={{width: 15, height: 15, fontSize: 7}}
                                value={this.props.testState.isCardTest}
                                onChange={(value) => this.props.updateIsTestCard(value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group" style={{margin: 0}}>
                                <label>{getText( "MaintenanceAmount2")}</label>
                                <InputNumber value={this.props.testState.collect}
                                    style={inputStyle}
                                    onChange={(value) => this.props.updateCardCollectAmount(value)} />
                            </div>
                        </div>
                        <div className="col-md-2" style={{padding: 0}}>
                            <div style={spaceDiv}></div>
                            <button disabled={!this.props.testState.paymentRunning || this.props.disabled}
                                onClick={(e) => this.props.requestCollectAmount(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenanceCollection")}
                            </button>
                        </div>
                        <div className="col-md-4">
                            <div style={spaceDiv}></div>
                            <button disabled={this.props.testState.stopPaymentState.isLoading || this.props.disabled}
                                onClick={(e) => this.props.requestCardStopPaymentSession(new Date().getTime())}
                                className="btn btn-primary"
                                style={buttonStyle}>
                                {getText( "MaintenancePaymentSession")}
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        );
        {/* <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", flexDirection: "row", padding: 10 }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>General</div>
                <div style={{
                    height: 30, width: 30, borderRadius: 30,
                    backgroundColor: this.props.state.status === "Ok"
                        ? "green" : "red"
                }}>
                </div>
            </div>
        </div> */}
    }
}

export default connect((state: ApplicationState) => ({
    ...state.card,
    neoEvents: state.home.neoEvents.neoEvents,
    neoEventCodes: state.seed.seed.neoEventCodes,
    disabled: !canEditSettings(state)
} as CardTestOwnProps), {
        ...CardStore.actionCreators,
    })(CardTest as any);