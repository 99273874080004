import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { push } from "connected-react-router";
import { createSelector } from 'reselect';
import * as Notification from "react-notification-system-redux";
import { getText } from '../utils/texts';

export interface SupplierOrderState {
    isOpen: boolean;
    isLoading: boolean;
    requestTime?: number;
    createSupplierOrder: {
        isLoading: boolean;
        requestTime?: number;
    },
    validateSupplierOrder: {
        isLoading: boolean;
        requestTime?: number;
    },
    supplierOrders: Array<Api.SupplierOrderModel>;
    supplierOrderSelectedId: number;
    showHisto: boolean;
}

interface OpenSupplierOrderDialog {
    type: "OPEN_SUPPLIERORDER_DIALOG";
}
interface CloseSupplierOrderDialog {
    type: "CLOSE_SUPPLIERORDER_DIALOG";
}

interface RequestSupplierOrders {
    type: "REQUEST_SUPPLIERORDERS";
    payload: { requestTime: number };
}
interface ReceiveSupplierOrders {
    type: "RECEIVE_SUPPLIERORDERS";
    payload: { requestTime: number; supplierOrders?: Array<Api.SupplierOrderModel>; };
    error?: any;
}

interface RequestValidateSupplierOrder {
    type: "REQUEST_VALIDATE_SUPPLIERORDER";
    payload: { requestTime: number };
}
export interface ReceiveValidateSupplierOrder {
    type: "RECEIVE_VALIDATE_SUPPLIERORDER";
    payload: {
        requestTime: number;
        productReception?: Api.ProductReceptionModel;
        productSupplier?: Api.ProductSupplierModel;
        supplierOrderProductSupplierId: number;
    };
    error?: any;
}

interface RequestCreateSupplierOrder {
    type: "REQUEST_CREATE_SUPPLIERORDER";
    payload: { requestTime: number };
}
interface ReceiveCreateSupplierOrder {
    type: "RECEIVE_CREATE_SUPPLIERORDER";
    payload: { requestTime: number; supplierOrder?: Api.SupplierOrderModel; };
    error?: any;
}

interface UpdateSupplierOrderSelectedId {
    type: "UPDATE_SUPPLIERORDERSELECTEDID";
    payload: { value: number }
}

interface SupplierOrderUpdateShowHisto {
    type: "SUPPLIERORDER_UPDATE_SHOWHISTO";
    payload: { value: boolean }
}

interface SupplierOrderUpdateOrderStatus {
    type: "SUPPLIERORDER_UPDATE_ORDERSTATUS";
    payload: { value: number }
    error?: any;
}

type KnownAction =
    | RequestCreateSupplierOrder
    | ReceiveCreateSupplierOrder
    | OpenSupplierOrderDialog
    | CloseSupplierOrderDialog
    | RequestSupplierOrders
    | ReceiveSupplierOrders
    | RequestValidateSupplierOrder
    | ReceiveValidateSupplierOrder
    | UpdateSupplierOrderSelectedId
    | SupplierOrderUpdateShowHisto
    | SupplierOrderUpdateOrderStatus
    ;

export const actionCreators = {
    requestCreateSupplierOrder: (requestTime: number, model: Api.SupplierOrderModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SupplierOrderApi();
        let fetchTask = api.createSupplierOrder({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(supplierOrder => {
                dispatch({
                    type: "RECEIVE_CREATE_SUPPLIERORDER",
                    payload: { requestTime: requestTime, supplierOrder: supplierOrder }
                });                                

                if (supplierOrder) {
                    dispatch(Notification.success({ title:  getText("NotificationTitleSuccess"), message: getText("NotificationCreatedSuccess"), position: "tc", level: "success" }) as any);
                }                
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_SUPPLIERORDER",
                    payload: { requestTime: requestTime },
                    error: err
                });

                if (err.text) {
                    err.text().then(x => {
                        dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
                    });
                }                
            });

        dispatch({
            type: "REQUEST_CREATE_SUPPLIERORDER",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestSupplierOrders: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SupplierOrderApi();
        let fetchTask = api.supplierOrders({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(supplierOrders => {
                dispatch({
                    type: "RECEIVE_SUPPLIERORDERS",
                    payload: { requestTime: requestTime, supplierOrders: supplierOrders }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "RECEIVE_SUPPLIERORDERS",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_SUPPLIERORDERS",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestValidateReception: (requestTime: number, model: Api.ValidateSupplierOrderModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SupplierOrderApi();
        let fetchTask = api.validateReception({ model: model }
            , { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(productReception => {
                //dispatch({
                //    type: "RECEIVE_VALIDATE_SUPPLIERORDER",
                //    payload: {
                //        requestTime: requestTime,
                //        productReception: productReception,
                //        productSupplier: getState().seed.seed.products[productReception.productId]
                //            .productSuppliers.find(x => x.productSupplierId === productReception.productReceptionId),
                //        supplierOrderProductSupplierId: supplierOrderProductSupplierId
                //    }
                //});
            })
            .catch(err => {
                console.error(err);
                //dispatch({
                //    type: "RECEIVE_VALIDATE_SUPPLIERORDER",
                //    payload: {
                //        requestTime: requestTime,
                //        supplierOrderProductSupplierId: supplierOrderProductSupplierId
                //    },
                //    error: err
                //});
            });

        dispatch({
            type: "REQUEST_VALIDATE_SUPPLIERORDER",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    openSupplierOrderDialog: () => <OpenSupplierOrderDialog>{ type: "OPEN_SUPPLIERORDER_DIALOG" },
    closeSupplierOrderDialog: () => <CloseSupplierOrderDialog>{ type: "CLOSE_SUPPLIERORDER_DIALOG" },    
    updateSupplierOrderSelectedId: (value: number) => <UpdateSupplierOrderSelectedId>{
        type: "UPDATE_SUPPLIERORDERSELECTEDID",
        payload: {
            value: value,
        }
    },    
    updateSupplierOrderShowHisto: (value: boolean) => <SupplierOrderUpdateShowHisto>{
        type: "SUPPLIERORDER_UPDATE_SHOWHISTO",
        payload: {
            value: value,
        }
    },    
    requestUpdateSupplierOrderStatus: (value: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.SupplierOrderApi();
        let fetchTask = api.updateSupplierOrderStatus(
            { id: value },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(supplierOrderId => {
                dispatch({
                    type: "SUPPLIERORDER_UPDATE_ORDERSTATUS",
                    payload: { value: supplierOrderId }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "SUPPLIERORDER_UPDATE_ORDERSTATUS",
                    payload: { value: 0 },
                    error: err
                });
            });
        return fetchTask;
    },
};

const unloadedState: SupplierOrderState = {
    isLoading: false,
    isOpen: false,
    createSupplierOrder: {
        isLoading: false
    },
    validateSupplierOrder: {
        isLoading: false
    },
    supplierOrders: [],
    supplierOrderSelectedId: undefined,
    showHisto: false
};

export const reducer: Reducer<SupplierOrderState> = (state: SupplierOrderState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CREATE_SUPPLIERORDER":
            return {
                ...state,
                createSupplierOrder: {
                    ...state.createSupplierOrder,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_SUPPLIERORDER":
            if (state.createSupplierOrder.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                createSupplierOrder: {
                    ...state.createSupplierOrder,
                    isLoading: false,
                }
            };
        case "OPEN_SUPPLIERORDER_DIALOG":
            return {
                ...state,
                isOpen: true
            };
        case "CLOSE_SUPPLIERORDER_DIALOG":
            return {
                ...state,
                isOpen: false
            };
        case "REQUEST_SUPPLIERORDERS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_SUPPLIERORDERS":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                supplierOrders: action.payload.supplierOrders
            };
        case "REQUEST_VALIDATE_SUPPLIERORDER":
            return {
                ...state,
                validateSupplierOrder: {
                    ...state.validateSupplierOrder,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_VALIDATE_SUPPLIERORDER":
            if (action.payload.requestTime !== state.validateSupplierOrder.requestTime)
                return state;

            return {
                ...state,
                supplierOrders: action.error
                    ? state.supplierOrders
                    : state.supplierOrders.map(x => ({
                        ...x,
                        supplierOrderProductSuppliers: x.supplierOrderProductSuppliers
                            .filter(y => y.supplierOrderProductSupplierId !== action.payload.supplierOrderProductSupplierId)
                    })),
                validateSupplierOrder: {
                    ...state.validateSupplierOrder,
                    isLoading: false,
                }
            };
        case "UPDATE_SUPPLIERORDERSELECTEDID":
            return {
                ...state,
                supplierOrderSelectedId: action.payload.value
            };            
        case "SUPPLIERORDER_UPDATE_SHOWHISTO":
            return {
                ...state,
                showHisto: action.payload.value
            };
        case "SUPPLIERORDER_UPDATE_ORDERSTATUS":
            return {
                ...state,                
                supplierOrderSelectedId: undefined,
                supplierOrders: action.error ? state.supplierOrders :
                    state.supplierOrders.filter(x => x.status != "Received")
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};