import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as PlanogramStore from "../store/Planogram";
import * as StockStore from "../store/Stock";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import PlanogramGrid, { ProductGrid } from "./PlanogramGrid";
import { calculateProductHeight, getImagePath, calculateProductWidth, calculateProductDepth, calculateViewScale, getStoreItemImage } from '../utils/utils';
import { CSSProperties } from 'react';
import Select from "./Select";
import Tooltip from "./Tooltip";
import ProductStockDialog from "./ProductStockDialog";
import Confirm from "react-confirm-bootstrap";
import Keyboard from "../utils/keyboard";
import { SubmitButton } from './SubmitButton';
import { hmiNotRunning } from '../store/HmiApplication';
import LgapImport from './LgapImport';
import { canUpdate } from '../security/roles';
import DialogModal from './DialogModal';
import PlanogramSettingsForm from './PlanogramSettingsForm';
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import CreateShelvingTrayPlacement from './CreateShelvingTrayPlacement';
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from "react-windowed-select";

type PlanogramProps = PlanogramStore.PlanogramState
    & PlanogramOwnProps
    & typeof StockStore.actionCreators
    & typeof PlanogramStore.actionCreators;

interface PlanogramOwnProps {
    activePlanogram: any,
    actualPlanogram: any,
    isHmiRunning: boolean;
    stock: StockStore.StockState;
    productRail: Api.ProductRailModel;
    storeType: string;
    products: { [id: number]: Api.ProductModel};
    externalProducts : { [id: number]: Api.ExternalProductModel};
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;   
    maxVolumeSAS: number;
}

interface PlanogramState {
    hasImportFile: boolean;
    productsPlanoFilter: boolean;
    heightPlanoFilter: boolean;
    filterProductsPlano: any;
}
interface PlanogramValues {
    familyId?: number;
    subFamilyId?: number;
}

const arrowContainerStyle: CSSProperties = {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 30,
    maxWidth: 40
    //padding: "0px 10px"
};

const labelStyle: CSSProperties = {
    fontWeight: "bold",
    display: "contents",
    /*textAlign: "right",*/
    paddingRight: 5
};

const fieldStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}

const sensList: Array<Api.ProductRailModelSensEnum> = [
    "Y1", "X1", "Z1", "X2", "Y2", "Z2"
];

const customFilter = createFilter({ ignoreAccents: true });

class Planogram extends React.Component<PlanogramProps, PlanogramState> {
    importFileInput: HTMLInputElement;
    planogramDiv: HTMLDivElement;

    constructor(props: PlanogramProps) {
        super(props);
        this.state = {
            hasImportFile: false,
            productsPlanoFilter: false,
            heightPlanoFilter: false,
            filterProductsPlano: []
        }
        this.handleSelectP = this.handleSelectP.bind(this);
    }

    componentWillReceiveProps(nextProps : PlanogramProps) {
        if (this.props.familyId != nextProps.familyId 
            || this.props.subFamilyId != nextProps.subFamilyId 
            || this.props.selectedPlanogramId != nextProps.selectedPlanogramId ) {
            this.handleChangeFilter({
                familyId: nextProps.familyId,
                subFamilyId: nextProps.subFamilyId
            } as PlanogramValues);
        }
    }

    get planogramHeight(): number {
        return 35;
    }

    get planogramWidth(): number {
        return _.max(_.values(
            this.props.stock.stockStatus.shelvingTrays)
            .map(x => x.railEnd - x.railStart + 1));
    }

    get selectedPlanogram(): Api.PlanogramModel {
        return this.props.stock.stockStatus
            ? this.props.stock.stockStatus.planograms
                .find(x => x.planogramId === this.props.selectedPlanogramId)
            : null;
    }

    get productPreviewSehlvingPlacement(): Api.ShelvingTrayPlacementModel {
        return this.props.stock.stockStatus.shelvingTrayPlacements
            .find(x => x.planogramId === this.props.selectedPlanogramId
                && x.level === this.props.createProductRailState.position.row - 1);
    }

    get productPreviewShelvingTray(): Api.ShelvingTrayModel {
        let shelvingPlacement = this.productPreviewSehlvingPlacement;
        if (!shelvingPlacement)
            return null;

        return this.props.stock.stockStatus.shelvingTrays[shelvingPlacement.shelvingTrayId];
    }

    get correctProduct() {
        return this.props.storeType == "Ximiti" ? this.props.products[this.props.createProductRailState.productId] : this.props.externalProducts[this.props.createProductRailState.productId]
    }

    get produtPreview(): ProductGrid {
        let shelvingPlacement = this.productPreviewSehlvingPlacement;
        if (!shelvingPlacement)
            return null;

        let shelving = this.props.stock.stockStatus.shelvings[shelvingPlacement.shelvingId];
        let shelvingTray = this.props.stock.stockStatus.shelvingTrays[shelvingPlacement.shelvingTrayId];
        return {
            startRow: this.props.createProductRailState.position.row,
            endRow: this.props.createProductRailState.position.row - 1
                + Math.ceil(calculateProductHeight(
                    this.correctProduct,
                    this.props.createProductRailState.sens) / shelving.railHeight),
            startCol: this.props.createProductRailState.position.col,
            endCol: this.props.createProductRailState.position.col
                + this.handleEndColValue(shelvingTray.railWidth) - 1, //-1 because it's inclusif (18-18 will have width of 1)
        };
    }

    handleEndColValue(shelvingTrayWidth: number){
        let bandsDistance = 5;
        let prodWidth = calculateProductWidth(this.correctProduct,
            this.props.createProductRailState.sens);
        if(prodWidth <= (shelvingTrayWidth - bandsDistance)) {
            return 1;
        }
        
        prodWidth = prodWidth - (shelvingTrayWidth - bandsDistance);
        return (Math.ceil(prodWidth / shelvingTrayWidth) + 1); 
    }

    componentDidMount() {
        this.handleChangeFilter();
        window.addEventListener("keydown", this.handleKeyPress);
    }

    handleKeyPress = key => {
        if (this.props.createProductRailState.productId
            && (key.key === "r" || key.key === "R")) {
            this.props.updateCreateSens(
                sensList[(sensList.indexOf(this.props.createProductRailState.sens) + 1) % sensList.length]);
        }
      };

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyPress);
    }

    handleImportPlanogram() {
        (this.props.requestPlanogramImport(new Date().getTime(), this.importFileInput.files.item(0)) as any as Promise<any>)
            .then(() => {
                this.importFileInput.value = null;
                this.props.requestStockStatus(new Date().getTime());
            });
    }

    filterPlanoPresents(value: boolean) {
        this.props.updateCreateProductId(undefined);
        this.setState({
            productsPlanoFilter: value
        }, () => { this.handleChangeFilter()});
    }

    filterPlanoHeights(value: boolean) {
        this.props.updateCreateProductId(undefined);
        this.setState({
            heightPlanoFilter: value
        }, () => { this.handleChangeFilter()});
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    floorsLength() {
         return this.props.stock.stockStatus.shelvingTrayPlacements
                    .filter(x => x.shelvingId === this.props.shelvingId
                        && x.planogramId === this.props.selectedPlanogramId)
                    .map(x => ({
                        row: x.level,
                        shelvingTrayPlacementId: x.shelvingTrayPlacementId,
                        shelvingName: this.props.stock.stockStatus.shelvingTrays[x.shelvingTrayId].name
                    })).length
    }

    get productPlanogramRADF() : Api.ProductRailModel[]  {
        let idProduct = this.props.storeType == "Ximiti" ? this.props.products[this.props.createProductRailState.productId].storeItemId
            : this.props.externalProducts[this.props.createProductRailState.productId].storeItemId;
        let arrayRADF = this.props.storeType == "Ximiti" ? this.props.stock.stockStatus.productRails.filter(x => x.planogramId === this.props.selectedPlanogramId && x.productId === idProduct)
            : this.props.stock.stockStatus.productRails.filter(x => x.planogramId === this.props.selectedPlanogramId && x.externalProductId === idProduct);
        return arrayRADF;
    }

    get startRowAnimation() {
        return 12 - (3 - 1
            + Math.ceil(calculateProductHeight(
                this.correctProduct,
                this.props.createProductRailState.sens) / this.previewRailHeight));
    }

    get previewRailHeight() {
        let productAnimationSehlvingPlacement = this.props.stock.stockStatus.shelvingTrayPlacements
            .find(x => x.planogramId === this.props.selectedPlanogramId);
        if (!productAnimationSehlvingPlacement)
            return 50;
        return this.props.stock.stockStatus.shelvings[productAnimationSehlvingPlacement.shelvingId].railHeight;
    }

    get previewRailWidth() {
        let productAnimationSehlvingPlacement = this.props.stock.stockStatus.shelvingTrayPlacements
            .find(x => x.planogramId === this.props.selectedPlanogramId);
        if (!productAnimationSehlvingPlacement)
            return 48;
        return this.props.stock.stockStatus.shelvingTrays[productAnimationSehlvingPlacement.shelvingTrayId].railWidth;
    }

    stockArr(productId: number) {
        if(this.props.storeType == "Ximiti"){
        return _.sum(this.props.stock.stockStatus.productReceptions
            .filter(x => x && this.props.stock.stockStatus.productSuppliers[x.productSupplierId] ?  this.props.stock.stockStatus.productSuppliers[x.productSupplierId].productId === productId : '')
            .map(x => x.quantityRemaining));
        }else{
          return _.sum(this.props.stock.stockStatus.productReceptions
                .filter(x => x && this.props.stock.stockStatus.productSuppliers[x.productSupplierId] ?  this.props.stock.stockStatus.productSuppliers[x.productSupplierId].externalProductId === productId : '')
                .map(x => x.quantityRemaining));
        }
    }

    getCurrentStockArr(productId: number, productRaildId: number) {
        return this.props.stock.storeStockState[productRaildId]
            || this.props.stock.storeStockState[productRaildId] === 0
            ? this.props.stock.storeStockState[productRaildId] :
            this.stockArr(productId)
    }

    handleChangeProduct(productId: number){
        this.props.updateCreateProductId(productId);
        let sensVal = this.props.storeType == "Ximiti" ? this.props.stock.stockStatus.productRails.find(x => x.planogramId === this.props.selectedPlanogramId && x.productId === productId)
        : this.props.stock.stockStatus.productRails.find(x => x.planogramId === this.props.selectedPlanogramId && x.externalProductId === productId);

        if(sensVal != undefined){
            this.props.updateCreateSens(
                sensList[sensList.indexOf(sensVal.sens)]);
        }
        else{
            this.props.updateCreateSens(sensList[0]);
        }
    }

    handleChangeFilter(modelValues: PlanogramValues = {
            familyId: this.props.familyId,
            subFamilyId: this.props.subFamilyId
        }){
        let filterProdPlano;
        if(this.props.storeType == "Ximiti")
        {
            if (this.state.productsPlanoFilter && this.state.heightPlanoFilter) {
                filterProdPlano = // Filter for products only present in planogramme and also height
                    _.sortBy(_.values(this.props.products)
                        .filter(x => x.enabled
                            && (!modelValues.familyId
                                || x.productSubFamilies
                                    .some(y => this.props.subFamilies[y.subFamilyId].familyId === modelValues.familyId))
                            && (!modelValues.subFamilyId
                                || x.productSubFamilies
                                    .some(y => y.subFamilyId === modelValues.subFamilyId))
                            && (this.props.stock.stockStatus.productRails.some(z => z.planogramId === this.selectedPlanogram.planogramId && z.productId === x.storeItemId))
                    ), x => x.height)
                        .map(x => ({ label: x.name, value: x.storeItemId }));
            }
            else {
                if (this.state.heightPlanoFilter) {
                    filterProdPlano =  // Filter for products by height
                        _.sortBy(_.values(this.props.products)
                            .filter(x => x.enabled
                                && (!modelValues.familyId
                                    || x.productSubFamilies
                                        .some(y => this.props.subFamilies[y.subFamilyId].familyId === modelValues.familyId))
                                && (!modelValues.subFamilyId
                                    || x.productSubFamilies
                                        .some(y => y.subFamilyId === modelValues.subFamilyId))), x => x.height)
                            .map(x => ({ label: x.name, value: x.storeItemId }));
                }
                else {
                    if (this.state.productsPlanoFilter) {
                        filterProdPlano = // Filter for products only present in planogramme
                            _.sortBy(_.values(this.props.products)
                                .filter(x => x.enabled
                                    && (!modelValues.familyId
                                        || x.productSubFamilies
                                            .some(y => this.props.subFamilies[y.subFamilyId].familyId === modelValues.familyId))
                                    && (!modelValues.subFamilyId
                                        || x.productSubFamilies
                                            .some(y => y.subFamilyId === modelValues.subFamilyId))
                                    && (this.props.stock.stockStatus.productRails.some(z => z.planogramId === this.selectedPlanogram.planogramId && z.productId === x.storeItemId))
                            ), x => x.name)
                                .map(x => ({ label: x.name, value: x.storeItemId }));
                    }
                    else {
                        filterProdPlano = // Droppicker without any filter, all products
                            _.sortBy(_.values(this.props.products)
                                .filter(x => x.enabled
                                    && (!modelValues.familyId
                                        || x.productSubFamilies
                                            .some(y => this.props.subFamilies[y.subFamilyId].familyId === modelValues.familyId))
                                    && (!modelValues.subFamilyId
                                        || x.productSubFamilies
                                            .some(y => y.subFamilyId === modelValues.subFamilyId))), x => x.name)
                                .map(x => ({ label: x.name, value: x.storeItemId }));
                    }
                }
            }
        }
        else // ExternalProducts
        { 
            if (this.state.productsPlanoFilter) {
                filterProdPlano = // Filter for products only present in planogramme
                    _.sortBy(_.values(this.props.externalProducts)
                        .filter(x => x.enabled
                            && (this.props.stock.stockStatus.productRails.some(z => z.planogramId === this.selectedPlanogram.planogramId && z.externalProductId === x.storeItemId))
                    ), x => x.name)
                        .map(x => ({ label: x.name, value: x.storeItemId }));
            }
            else{
                filterProdPlano = _.sortBy(_.values(this.props.externalProducts)
                    .filter(x => x.enabled), x => x.name)
                    .map(x => ({ label: x.name, value: x.storeItemId }));
                   
            }
        }
        this.setState({
            filterProductsPlano: filterProdPlano
        });
    }

    handleSelectP(valueSelected){
        this.handleChangeProduct(valueSelected.value);
    }

    public render() {
        if(!this.props.stock.stockStatus)
            return <div></div>;

        let previewGridSize = [1,2,3,4,5,6,7,8,9,10,11,12]

        return (<div className="planogram" >
            <div className="card" style={{
                ...Styles.cardStyle,
                ...Styles.pageTitle
            }}><span style={{paddingLeft: 30}}>{getText( "PlanogramTitle")}</span> <HelpHeader /></div>
            <div style={{display: "flex", marginLeft: "1%", width: "99%", height: "80vh" }}> {/* gridTemplateColumns: '780px auto' */}
                <div className="card" style={{
                    ...Styles.cardStyle,
                    gridRow: "2 / 5",
                    gridColumn: "1 / 2",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "max-content",
                    minHeight: "max-content", 
                }}>
                    {this.props.stock.stockStatus && <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        height: "85%"
                    }}>
                        <div style={{
                            flex: "0 0 auto",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0",
                            backgroundColor: this.isDarkMode() ? '#181F3A' : "#EBECF1",
                            color: this.isDarkMode() ? '#FFFFFF' : 'black'
                        }}>                                                         
                            <div style={{
                                width: 40, height: '100%', textAlign: "center", fontSize: 25,
                                background: this.isDarkMode() ? '#181F3A' : "white",
                                borderTopLeftRadius: 4
                            }}>
                            </div>
                            <div style={{display: "flex", maxWidth: 520, minWidth: 520, overflowX: "scroll"}}>
                                {_.values(this.props.stock.stockStatus.shelvings)
                                    .map((shelving, index) => <div key={shelving.shelvingId} style={{
                                        ...(shelving.shelvingId === this.props.shelvingId
                                            ? Styles.colors.planogram2
                                            : Styles.colors.planogram1),
                                        cursor: "pointer",
                                        padding: 8,
                                        flex: 1,
                                        textAlign: "center",
                                        borderTop: this.isDarkMode() ? "1px solid #181F3A" : "1px solid #E5E5E5",
                                        borderLeft: this.isDarkMode() ? "1px solid #cccccc73" : "1px solid rgba(60, 68, 114, 0.15)",
                                        borderRight: this.isDarkMode() ? "1px solid #cccccc73" : "1px solid rgba(60, 68, 114, 0.15)"
                                    }} onClick={(e) => {
                                        this.props.updateShelvingId(shelving.shelvingId);
                                        e.preventDefault();
                                    }}>
                                        {shelving.name}
                                    </div>)}
                            </div>
                            <div style={{
                                width: 40, height: '100%', textAlign: "center", fontSize: 25,
                                background: this.isDarkMode() ? '#181F3A' : "#fff",
                                borderTopRightRadius: 4
                            }}>
                                <Tooltip overlay={<div>{getText( "PlanogramModalAddPlate")}</div>}>
                                    <i className="glyphicon glyphicon-plus"
                                        onClick={(e) => this.props.planogramOpenTray()}
                                        style={{ cursor: "pointer" }}></i>
                                </Tooltip>
                            </div>                          
                        </div>
                        <div style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "stretch"
                        }}>
                            <div style={{
                                flex: "0 0 auto",                                
                                background: this.isDarkMode() ? '#181F3A' : "#fff",
                                ...arrowContainerStyle
                            }}>
                                <div style={{
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, width: 40, height: 40, textAlign: "center", fontSize: 25
                                }}>
                                </div>
                                <div style={{
                                    paddingTop: "350px",
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, textAlign: "center", fontSize: 25, width: 40 }}>
                                    {_.values(this.props.stock.stockStatus.shelvings)
                                        .findIndex(x => x.shelvingId === this.props.shelvingId) !== 0
                                        && <div style={{ cursor: "pointer" }} onClick={(e) => this.props.updateShelvingId(
                                            _.values(this.props.stock.stockStatus.shelvings)[
                                                Math.max(_.values(this.props.stock.stockStatus.shelvings)
                                                    .findIndex(x => x.shelvingId === this.props.shelvingId) - 1, 0)].shelvingId)}><i className="glyphicon glyphicon-chevron-left"></i></div>
                                        || <i style={{opacity: .2}} className="glyphicon glyphicon-chevron-left"></i>}
                                </div>
                            </div>
                            <div style={{ flex: "0 0 auto" }}>
                                <PlanogramGrid
                                    productRailId={this.props.productRailId}
                                    height={this.planogramHeight}
                                    width={this.planogramWidth}
                                    storeType= {this.props.storeType}
                                    products={this.props.storeType == "Ximiti" ? this.props.products : this.props.externalProducts}
                                    stock={this.props.stock}
                                    productGrids={_.sortBy(this.props.stock.stockStatus.productRails
                                        .filter(x => x.planogramId === this.props.selectedPlanogramId)
                                        .map(x => {
                                            let trayPlacement = this.props.stock.stockStatus
                                                .shelvingTrayPlacements
                                                .find(y => y.shelvingId === this.props.shelvingId
                                                    && y.shelvingTrayId === x.shelvingTrayId
                                                    && y.planogramId === this.props.selectedPlanogramId);

                                            if (!trayPlacement)
                                                return null;

                                            return {
                                                startRow: trayPlacement.level + 1,
                                                endRow: this.props.storeType == "Ximiti" 
                                                    ? trayPlacement.level + Math.ceil(
                                                    (calculateProductHeight(this.props.products[x.productId], x.sens)
                                                        / (this.props.stock.stockStatus.shelvings[trayPlacement.shelvingId].railHeight)))
                                                        : trayPlacement.level + Math.ceil(
                                                            (calculateProductHeight(this.props.externalProducts[x.externalProductId], x.sens)
                                                                / (this.props.stock.stockStatus.shelvings[trayPlacement.shelvingId].railHeight))),
                                                startCol: x.d,
                                                endCol: x.f,
                                                productRail: x
                                            };
                                        }).filter(x => x), x => `${x.startRow}|${x.startCol}`)}
                                    floors={this.props.stock.stockStatus.shelvingTrayPlacements
                                        .filter(x => x.shelvingId === this.props.shelvingId
                                            && x.planogramId === this.props.selectedPlanogramId)
                                        .map(x => ({
                                            row: x.level,
                                            shelvingTrayPlacementId: x.shelvingTrayPlacementId,
                                            shelvingName: this.props.stock.stockStatus.shelvingTrays[x.shelvingTrayId].name
                                        }))}
                                    productPreview={this.props.createProductRailState.isCreating
                                        && this.props.createProductRailState.productId
                                        && this.produtPreview}
                                    productIdCreateRail = {this.props.createProductRailState.isCreating
                                        && this.props.createProductRailState.productId}
                                    productSensCreateRail = {this.props.createProductRailState.sens}
                                    maxVolumeSAS = {this.props.maxVolumeSAS}
                                    settings={this.props.settings}
                                    onRequestDeleteFloor={(id) => this.props.requestDeleteShelvingTrayPlacement(new Date().getTime(), id)}
                                    onSelectCreatePosition={() => this.props.requestCreatePorductRail(new Date().getTime())}
                                    onMouseEnterRail={(row, col) => this.props.updateCreatePosition(row, col)}
                                    onSelectProductRail={(productRail) => this.props.updateProductRailId(productRail.productRailId)} 
                                    updateCreateProductId={(id) => this.handleChangeProduct(id)} />
                            </div>
                            <div style={{
                                flex: "0 0 auto",                                
                                background: this.isDarkMode() ? '#181F3A' : "#fff",
                                ...arrowContainerStyle
                            }}>
                                <div style={{
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, width: 40, height: 40,  textAlign: "center", fontSize: 25
                                }}>
                                    <Tooltip overlay={<div>{getText( "PlanogramSettings")}</div>}>
                                        <i className="glyphicon glyphicon-cog"
                                            onClick={(e) => this.props.planogramSettingsOpen()}
                                            style={{ cursor: "pointer" }}></i>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    paddingTop: "350px",
                                    pointerEvents: this.props.disabled ? "none" : "initial",
                                    opacity: this.props.disabled ? 0.7 : 1, textAlign: "center", fontSize: 25, width: 40 }}>
                                    {_.values(this.props.stock.stockStatus.shelvings)
                                        .findIndex(x => x.shelvingId === this.props.shelvingId) < (_.values(this.props.stock.stockStatus.shelvings).length - 1)
                                        && <div style={{ cursor: "pointer" }} onClick={(e) => this.props.updateShelvingId(
                                            _.values(this.props.stock.stockStatus.shelvings)[
                                                Math.min(_.values(this.props.stock.stockStatus.shelvings)
                                                    .findIndex(x => x.shelvingId === this.props.shelvingId) + 1,
                                                    _.values(this.props.stock.stockStatus.shelvings).length - 1)].shelvingId)}><i className="glyphicon glyphicon-chevron-right"></i></div> 
                                        || <i style={{opacity: .2}} className="glyphicon glyphicon-chevron-right"></i>}                                    
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div style= {{
                    gridRow: "2 / 3",
                    gridColumn: "2 / 3",
                    width: "100%",
                    paddingLeft: 20
                }}>
                    <div className="card" style={{
                        ...Styles.cardStyle,
                        gridRow: "2 / 3",
                        gridColumn: "2 / 3",
                        marginLeft: 10,
                        marginRight: '2%',
                        padding: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        fontSize: 12
                    }}>
                        <span style={{fontSize: 20, fontWeight: "bold" }}>{getText( "PlanogramAdding")}</span>
                        <div style= {{ padding: "0 10px", display: "inline-flex", justifyContent: "center" }}>
                            <div style= {{width: "50%"}}>
                                <input type="checkbox" id="PlanoPresentFiltre" name="PlanoPresentFiltre" onClick={() => this.filterPlanoPresents(!this.state.productsPlanoFilter)} /> &nbsp;
                                <label htmlFor="PlanoPresentFiltre">{getText( "PlanogramInPlanogram")}</label> &nbsp;
                            </div>
                            {this.props.storeType == "Ximiti" &&
                            <div style= {{width: "50%"}}>
                                <input type="checkbox" id="PlanoHeightFilter" name="PlanoHeightFilter" onClick={() => this.filterPlanoHeights(!this.state.heightPlanoFilter)} /> &nbsp;
                                <label htmlFor="PlanoHeightFilter">{getText( "PlanogramHeightFilter")}</label>
                            </div>}
                        </div>
                        <div style= {{ padding: "0 10px", display: "inline-flex", justifyContent: "center" }}>
                            <div style={{
                                flex: "0 0 auto", width: "50%",
                                flexDirection: "row", alignItems: "center",
                                paddingTop: 5
                            }}>
                                <div style={{ flex: "0 0 auto", minWidth: 125, ...labelStyle }}>
                                    {getText( "PlanogramFamily")}:
                                </div>
                                <div className="input-planogram" style={{ flex: 1 }}>
                                    <Select options={[{
                                        label: "------",
                                        value: undefined
                                    }].concat(_.values(this.props.families).map(x => ({ label: x.name, value: x.familyId })))} 
                                        style={{
                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                        }}
                                        value={this.props.familyId}
                                        disabled = {this.props.storeType == "Ximiti" ? false : true}
                                        onChange={(familyId) => this.props.updateFamilyId(familyId)} />
                                </div>
                            </div>
                            <div className="ml-5" style={{
                                flex: "0 0 auto",width: "50%",
                                flexDirection: "row", alignItems: "center",
                                paddingTop: 5
                            }}>
                                <div style={{ flex: "0 0 auto", minWidth: 125, ...labelStyle }}>
                                    {getText( "PlanogramSubfamily")}:
                                </div>
                                <div className="input-planogram" style={{ flex: 1 }}>
                                    <Select options={[{
                                        label: "------",
                                        value: undefined
                                    }].concat(_.values(this.props.subFamilies)
                                        .filter(x => x.familyId === this.props.familyId)
                                        .map(x => ({ label: x.name, value: x.subFamilyId })))} 
                                        style={{
                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                        }}
                                        disabled = {this.props.storeType == "Ximiti" ? false : true}
                                        value={this.props.subFamilyId}
                                        onChange={(subFamilyId) => this.props.updateSubFamilyId(subFamilyId)} />
                                </div>
                            </div>
                        </div>
                        <div style={{
                            flex: "0 0 auto",
                            flexDirection: "row", alignItems: "center",
                            padding: "2px 10px 0"
                        }}>
                            <div style={{ flex: "0 0 auto", minWidth: 125, ...labelStyle }}>
                                {getText( "PlanogramProduct")}:
                            </div>
                            <div className="input-planogram">
                                <WindowedSelect
                                    styles={{
                                        control: (base) => ({
                                        ...base,
                                        backgroundColor: "transparent",
                                        boxShadow:"0px 0px 20px rgb(66 73 120 / 10%)",
                                        border: "1px solid white",
                                        color: "black",
                                        cursor: "pointer"
                                        }),
                                        option: (styles, { data, isFocused, isSelected }) => {
                                            const bColor = this.isDarkMode() ? "#181F3A" : "white";
                                            return {
                                              ...styles,
                                              backgroundColor:  isSelected
                                                    ? "#ddd"
                                                    : isFocused
                                                    ? "#5897fb"
                                                    : bColor,
                                              color: this.isDarkMode() ? "white" : "#666",   
                                              cursor: "pointer"
                                            };
                                          },
                                    }}
                                    
                                    options={this.state.filterProductsPlano}
                                    value={this.state.filterProductsPlano.find(x => x.value === this.props.createProductRailState.productId) || null}
                                    onChange={this.handleSelectP} 
                                    filterOption={customFilter}
                                    placeholder = {""}
                                    components={{
                                        Option: (props) => <components.Option {...props}>
                                        <div onMouseUp={() => props.innerProps.onClick()}>
                                            {props.data.label}
                                        </div>
                                    </components.Option>
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{
                            flex: 1, display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: '3px 10px 0',
                            boxShadow: "0px 0px 20px rgba(66, 73, 120, 0.1)"
                        }}>
                            {this.props.createProductRailState.productId && <div style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "stretch", overflow: "hidden",
                                width: "100%", height: "100%"
                            }}>
                                {this.props.storeType == "Ximiti" &&
                                <div style={{
                                    flex: 1, position: "relative", display: "flex",
                                    flexDirection: "row", alignItems: "center", margin: "0 2%"
                                }}>
                                    <img style={{
                                        position: "absolute",
                                        width: "100%",
                                    }}
                                        src={getImagePath(getStoreItemImage(this.props.products[this.props.createProductRailState.productId]))} />
                                </div>}
                                <div style={{ flex: 2, fontSize: 14 }}>                                    
                                    <div style={{
                                        pointerEvents: this.props.disabled ? "none" : "initial",
                                        opacity: this.props.disabled ? 0.7 : 1, paddingTop: 3
                                    }}>
                                        {!this.props.createProductRailState.isCreating && <SubmitButton
                                            style = {{ marginLeft: 0, height: 32 }}
                                            disabled={this.props.createProductRailState.isLoading || this.props.disabled}
                                            disablingMessages={[]}
                                            onClick={() => {
                                                this.props.updateCreating(true);
                                            }}>{getText( "PlanogramAddButton")}</SubmitButton>}
                                        {this.props.createProductRailState.isCreating && <button className="btn btn-secondary"
                                            disabled={this.props.createProductRailState.isLoading}
                                            onClick={(e) => {
                                                this.props.updateCreating(false);
                                                e.preventDefault();
                                            }}
                                            style={{ height: 32 }}>{getText( "PlanogramCancel")}</button>}
                                    </div>
                                    <div style={{ display: "flex", flex: 1, marginTop: 5 }}>
                                        <b> {getText( "PlanogramProductPosition")} </b>
                                        <div style={{
                                            pointerEvents: this.props.disabled ? "none" : "initial",
                                            opacity: this.props.disabled ? 0.7 : 1, flex: 1, display: "flex", flexDirection: "row", alignItems: "center"
                                        }}>
                                            {/*<div style={{ flex: "0 0 auto" }}>{sensList.indexOf(this.props.createProductRailState.sens) + 1}</div>*/}
                                            <i style={{
                                                flex: "0 0 auto", paddingLeft: 5,
                                                paddingBottom: 3, display: "block", cursor: "pointer"
                                            }}
                                                onClick={(e) => {
                                                    if(this.props.disabled)
                                                        return;
                                                    
                                                    this.props.updateCreateSens(
                                                        sensList[(sensList.indexOf(this.props.createProductRailState.sens) + 1) % sensList.length]);
                                                    e.preventDefault();                                            
                                                }}
                                                className="glyphicon glyphicon-repeat"></i>
                                            <span style={{ paddingLeft: 3 }}>(Sens: {this.props.createProductRailState.sens})</span>
                                            <i style={{ flex: "0 0 auto", paddingLeft: 5 }}></i>
                                        </div>                                
                                    </div>
                                    <div style={{display: "grid"}}>
                                        <div style={{
                                            gridColumnStart: 1,
                                            gridColumnEnd: 2
                                        }}>
                                            <div style={{
                                                height: "105px",
                                                width: "105px",
                                                display: "grid",
                                                gridTemplateRows: "repeat(12, 1fr)",
                                                gridTemplateColumns: "repeat(12, 1fr)",
                                                alignSelf: "center",
                                            }}>
                                                {previewGridSize.map((i) => (
                                                    previewGridSize.map((j) => (
                                                        <div key={`${i}${j}`}
                                                            style={{
                                                                gridRow: i + " / " + Math.ceil(i + 1),
                                                                gridColumn: j + " / " + Math.ceil(j + 1),
                                                                backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                                                cursor: "pointer",
                                                                border: "1px solid rgba(60, 68, 114, 0.25)"
                                                            }}>
                                                        </div>
                                                    ))
                                                ))}
                                                <div
                                                    style={{
                                                        gridRow: this.startRowAnimation + " / " +  ((this.startRowAnimation + 1) - 1
                                                            + Math.ceil(calculateProductHeight(
                                                                this.correctProduct,
                                                                this.props.createProductRailState.sens) / this.previewRailHeight)),
                                                        gridColumn: 2 + " / " + (2
                                                            + this.handleEndColValue(this.previewRailWidth)),
                                                        backgroundColor: "red",
                                                        opacity: 0.65,
                                                        cursor: "pointer",
                                                        border: "2px solid rgba(60, 68, 114, 0.15)"
                                                    }}>
                                                </div>
                                                <div
                                                    style={{
                                                        gridRow: 11 + " / " + 10,
                                                        gridColumn: 1 + " / " + 13,
                                                        backgroundColor: "yellow",
                                                        opacity: 0.65,
                                                        cursor: "pointer",
                                                        border: "2px solid rgba(60, 68, 114, 0.15)"
                                                    }}>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            gridColumnStart: 2,
                                            gridColumnEnd: 3
                                        }}>
                                            <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramHeight")} :</div>
                                            <div style={{ flex: 1, marginLeft: 5 }}>{calculateProductHeight(this.correctProduct,this.props.createProductRailState.sens)}</div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramWidth")} :</div>
                                                <div style={{ flex: 1, marginLeft: 5 }}>{calculateProductWidth(this.correctProduct, this.props.createProductRailState.sens)}</div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramDepth")} :</div>
                                                <div style={{ flex: 1, marginLeft: 5 }}>{calculateProductDepth(this.correctProduct,this.props.createProductRailState.sens)}</div>
                                            </div>
                                            <div style={{ ...fieldStyle }}>
                                                <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramMaxQty")} :</div>
                                                <div style={{ flex: 1, marginLeft: 5 }}>{
                                                    this.productPreviewShelvingTray
                                                    && <span>{Math.floor(
                                                        this.productPreviewShelvingTray.depth / calculateProductDepth(this.correctProduct,
                                                            this.props.createProductRailState.sens))}</span>}
                                                </div>
                                            </div>
                                            {(this.props.productRail)
                                            && (this.props.productRail.productId === this.props.createProductRailState.productId 
                                                || this.props.productRail.externalProductId === this.props.createProductRailState.productId) 
                                            ? <div>
                                                <div style={{ ...fieldStyle }}>
                                                    <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramRADF")} :</div>
                                                    <div  style={{ flex: 1, marginLeft: 5 }}>
                                                        {
                                                            this.productPlanogramRADF.map((x) => {
                                                                return (`(${x.r}-${x.a}-${x.d}-${x.f})`)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ ...fieldStyle }}>
                                                <div style={{ flex: 1, width: "45%" }}>
                                                    <button className="btn btn-primary"
                                                        style= {{ width: "90%", height: 32, marginRight: "5px"}}
                                                        disabled={!this.props.productRailId
                                                            || (this.props.productRailStates[this.props.productRailId]
                                                                && this.props.productRailStates[this.props.productRailId].isLoading)
                                                            || this.props.disabled}
                                                        onClick={(e) => {
                                                            let stockArr = this.getCurrentStockArr(this.correctProduct.storeItemId, this.props.productRailId);
                                                            this.props.openCreateStock(this.props.productRailId, stockArr);
                                                            e.preventDefault();
                                                        }}>
                                                        {getText( "PlanogramStock")}
                                                    </button>
                                                </div>
                                                <div style={{ flex: 1, width: "45%" }}>
                                                    <Confirm
                                                        style= {{ width: "90%", height: 32}}
                                                        onConfirm={() => {
                                                            let response = this.props.requestDeleteProductRail(new Date().getTime(), this.props.productRailId, this.props.productRail.productStocks.length);
                                                                if (response === null) {
                                                                    {
                                                                        alert(getText("PlanogramAlertDeleteProdRail"));
                                                                    }
                                                                }
                                                            }}
                                                            body={getText("MaintenancePlanoProdBodyConfirm")}
                                                            confirmText={getText("MaintenanceConfirm")}
                                                            title={getText("MaintenanceBodyConfirmDelete")}>
                                                            <button className="btn btn-primary"
                                                                disabled={!this.props.productRailId
                                                                    || (this.props.productRailStates[this.props.productRailId]
                                                                        && this.props.productRailStates[this.props.productRailId].isLoading)
                                                                    // || this.props.selectedPlanogramId === this.props.planogramId
                                                                    || this.props.disabled}>
                                                                {getText( "PlanogramRemove")}
                                                            </button>
                                                    </Confirm>
                                                </div>
                                            </div>
                                            </div>
                                            :
                                                (this.productPlanogramRADF.length != 0)
                                                && <div>
                                                    <div style={{ ...fieldStyle }}>
                                                        <div style={{ flex: 1, ...labelStyle }}>{getText( "PlanogramRADF")} :</div>
                                                        <div  style={{ flex: 1, marginLeft: 5 }}>
                                                            {
                                                                this.productPlanogramRADF.map((x) => {
                                                                    return (`(${x.r}-${x.a}-${x.d}-${x.f})`)
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}                   
                        </div>                                
                    </div>                
                    {/* <div className="card" style={{
                        ...Styles.cardStyle,
                        gridRow: "3 / 4",
                        gridColumn: "2 / 3",
                        marginLeft: 10,
                        marginTop: 8,
                        marginRight: '2%',
                        padding: "5px 15px 0",
                        height: "fit-content"
                    }}>
                        {this.props.productRail
                            && <div style={{
                                height: "100%", display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch"
                            }}>
                                <div style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    flex: "0 0 auto"
                                }}>{this.props.products[this.props.productRail.productId].name}</div>
                                <div style={{
                                    flex: 1,
                                    position: "relative",
                                    display: "flex", flexDirection: "column",
                                    alignItems: "center"
                                }}>
                                    <img style={{
                                        position: "absolute",
                                        height: "100%",
                                        minHeight: 80
                                    }} src={getImagePath(this.props.products[this.props.productRail.productId].image)} />
                                </div>*
                                <div style={{
                                    flex: "0 0 auto",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "stretch",
                                    paddingTop: 5, fontSize: 14
                                }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 3 }}><span style= {{ ...labelStyle }}> {getText( "PlanogramDepth")} : </span>{this.props.products[this.props.productRail.productId].depth}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 3 }}><span style= {{ ...labelStyle }}> {getText( "PlanogramHeight")} : </span>{this.props.products[this.props.productRail.productId].height}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 3 }}><span style= {{ ...labelStyle }}> {getText( "PlanogramWidth")} : </span>{this.props.products[this.props.productRail.productId].width}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 3}}><span style= {{ ...labelStyle }}> {getText( "PlanogramMaxQty")} : </span>{Math.floor(
                                                this.props.stock.stockStatus.shelvingTrays[this.props.productRail.shelvingTrayId].depth
                                                / calculateProductDepth(this.props.products[this.props.productRail.productId], this.props.productRail.sens))}</div>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 1}}><span style= {{ ...labelStyle }}>{getText( "PlanogramRADF")} :</span> {this.props.productRail.r} {this.props.productRail.a} {this.props.productRail.d} {this.props.productRail.f}</div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 1}}><span style= {{ ...labelStyle }}>{getText( "PlanogramDirection")} :</span> {sensList.indexOf(this.props.productRail.sens) + 1} <i className="glyphicon glyphicon-repeat"></i></div>
                                        </div>
                                        <div style={{ ...fieldStyle }}>
                                            <div style={{ flex: 1, width: "45%" }}>
                                                <button className="btn btn-primary"
                                                    style= {{ width: "90%", height: 32, marginRight: "5px"}}
                                                    disabled={!this.props.productRailId
                                                        || (this.props.productRailStates[this.props.productRailId]
                                                            && this.props.productRailStates[this.props.productRailId].isLoading)
                                                        || this.props.disabled || this.isDisable()}
                                                    onClick={(e) => {
                                                        this.props.openCreateStock(this.props.productRailId);
                                                        e.preventDefault();
                                                    }}>
                                                    {getText( "PlanogramStock")}
                                                </button>
                                            </div>
                                            <div style={{ flex: 1, width: "45%" }}>
                                                <Confirm
                                                style= {{ width: "90%", height: 32}}
                                                onConfirm={() => {
                                                    let response = this.props.requestDeleteProductRail(new Date().getTime(), this.props.productRailId, this.props.productRail.productStocks.length);
                                                        if (response === null) {
                                                            {
                                                                alert("Vous n'avez pas le droit de supprimer ce produit (il y a de stock en robot)");
                                                            }
                                                        }
                                                    }}
                                                    body="Êtes-vous sûre de vouloir supprimer ce produit du planogramme ?"
                                                    confirmText="Confirmer"
                                                    title="Confirmation de supression">
                                                    <button className="btn btn-secondary"
                                                        disabled={!this.props.productRailId
                                                            || (this.props.productRailStates[this.props.productRailId]
                                                                && this.props.productRailStates[this.props.productRailId].isLoading)
                                                            || this.props.isHmiRunning
                                                        || this.props.disabled
                                                        || this.isDisable()}>
                                                        {getText( "PlanogramRemove")}
                                                    </button>
                                                </Confirm>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div> */}
                    <div className="card" style={{
                        ...Styles.cardStyle,
                        gridRow: "4 / 5",
                        gridColumn: "2 / 3",
                        marginLeft: 10,
                        marginTop: 8,
                        marginRight: '2%',
                        padding: "5px 10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch"
                    }}>
                        <div>
                            <div style={{
                                pointerEvents: this.props.disabled ? "none" : "initial",
                                opacity: this.props.disabled ? 0.7 : 1, display: "flex", flexDirection: "row", alignItems: "flex-start"
                            }}>
                                <div style={{
                                    fontWeight: "bold",
                                }}>
                                    {getText( "PlanogramImportLGAP")}:
                                </div>
                                <LgapImport />
                            </div>
                        </div>
                        <div>
                            <div>
                                <div style={{ flex: "0 0 auto", minWidth: 120, ...labelStyle, marginTop: 5 }}>{getText( "PlanogramPlanosList")}</div>
                                <div className="input-planogram" style={{ flex: 1, minWidth: 190}}>
                                    <Select options={(this.props.stock.stockStatus
                                        ? this.props.stock.stockStatus.planograms
                                        : [])
                                        .map(x => ({
                                            label: `${x.name}${this.props.planogramId === x.planogramId && getText( "PlanogramActive") || ""}`,
                                            value: x.planogramId
                                        }))
                                        /*.concat({
                                            label: `${this.props.stock.stockStatus.planograms.filter(obj => {
                                                return obj.planogramId === this.props.planogramId
                                              })[0].name}${'(Actuel)'}`,//It should be always a 0 index
                                            value: this.props.planogramId
                                        }).sort((a, b) => a.label.localeCompare(b.label))*/
                                        } 
                                        style={{
                                            backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                            color: this.isDarkMode() ? "white" : "#3B4370"
                                        }}
                                        value={this.props.selectedPlanogramId}
                                        onChange={(planogramId) => {
                                            this.props.updateSelectPlanogramId(planogramId)                        
                                        }}/>
                                </div>
                                <div style={{ marginTop: 5, width: "100%" }}>
                                    <SubmitButton
                                        className="btn btn-primary btn-sm"
                                        disablingMessages={this.props.isHmiRunning                                    
                                            ? [getText( "PlanogramHmiRunning")]
                                            : []}
                                        disabled={this.props.activePlanogramState.isLoading
                                            || !this.props.selectedPlanogramId
                                            || this.props.selectedPlanogramId === this.props.planogramId
                                            || this.props.disabled}
                                        style={{ flex: "0 0 auto", marginLeft: 0, width: "31%", height: 32 }}
                                        onClick={() => {
                                            //What is this for?
                                            // var uniquePlanograms = this.props.stock.stockStatus.planograms.filter(function(elem, index, self) {
                                            //     return index === self.indexOf(elem);
                                            // })
                                            // this.props.stock.stockStatus.planograms = uniquePlanograms;
                                            // this.props.stock.stockStatus.planograms.push({...this.selectedPlanogram})
                                            this.props.requestUpdateActivePlanogram(new Date().getTime());                                            
                                        }}>
                                        {getText( "PlanogramActivate")}
                                    </SubmitButton>
                                    <Confirm
                                        style= {{width: "33%", height: 32}}
                                        onConfirm={() => {
                                            this.props.requestDeletePlanogram(new Date().getTime(), this.props.selectedPlanogramId);
                                        }}
                                        body={getText( "MaintenancePlanoBodyConfirm")}
                                        confirmText={getText( "MaintenanceConfirm")}
                                        title={getText( "MaintenanceConfirmDeleteTitle")}>
                                        <button className="btn btn-primary btn-sm ml-5"
                                            style={{ marginLeft: 6 }}
                                            disabled={this.props.deletePlanogram.isLoading
                                                || this.props.selectedPlanogramId === this.props.planogramId
                                                || this.props.disabled}>
                                            {getText( "PlanogramRemove")}
                                        </button>
                                    </Confirm>
                                    <button className="btn btn-primary btn-sm ml-5"
                                        style={{ marginLeft: 9, width: "33%", height: 32, float: "right" }}
                                        onClick={(e) => this.props.requestPlanogramExport(new Date().getTime())}
                                        disabled={this.props.exportPlanogramState.isLoading
                                            || !this.props.selectedPlanogramId
                                            || this.props.disabled}>
                                        {getText( "PlanogramExport")}
                                    </button>
                                </div>
                                <div style={{ marginTop: 5, width: "100%" }}>                          
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input type={"file"}
                                            style= {{ paddingTop: 5, width: "66%", opacity: this.floorsLength() > 0 ? 0.6 : 1,
                                                    cursor: this.floorsLength() > 0 ? "not-allowed" : "inital"  }}
                                            disabled={this.props.disabled
                                                || this.floorsLength() > 0
                                            }
                                            onChange={(e) => this.setState({ hasImportFile: e.target.files.item(0) != undefined })}
                                            ref={(ref) => this.importFileInput = ref} />
                                        <button className="btn btn-primary btn-sm"
                                            style={{ marginLeft: 6, width: "33%", height: 32 }}
                                            onClick={(e) => this.handleImportPlanogram()}
                                            disabled={this.props.importPlanogramState.isLoading
                                                || !this.props.selectedPlanogramId
                                                || !this.state.hasImportFile
                                                || this.floorsLength() > 0
                                                || this.props.disabled}>
                                            {getText( "PlanogramImport")}
                                        </button>
                                    </div>
                                </div>
                            </div>                        
                            <div>
                                <div style={{ flex: "0 0 auto", minWidth: 120, ...labelStyle }}>{getText( "PlanogramName")} :</div>                                
                                <div style={{ display: "inline-flex", width: "100%" }}>
                                    <div style={{ flex: 1, marginRight: 6, width: "66%" }}>
                                        <input className="form-control input-sm"
                                            style= {{height: 32}}
                                            disabled={this.props.disabled}
                                            type={"text"}
                                            name={"planogramName"}
                                            value={this.props.createPlanogramState.name}
                                            onChange={(e) => this.props.updatePlanogramName(e.target.value)} />
                                    </div>
                                    <div style={{ flex: "0 0 auto", width: "33%" }}>
                                        <button className="btn btn-primary btn-sm"
                                            style={{ width: "100%", height: 32 }}
                                            disabled={this.props.createPlanogramState.isLoading || this.props.disabled}
                                            onClick={(e) => this.props.requestCreatePlanogram(new Date().getTime(), this.props.createPlanogramState.name)}>{getText( "PlanogramCreate")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProductStockDialog />
                <DialogModal isOpen={this.props.addTray.isOpen}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);"
                    }}
                    title={getText( "PlanogramAddButton")}
                    contentLabel={getText( "PlanogramModalAddPlate")}
                    onRequestClose={() => this.props.planogramCloseTray()}>
                    <div style={{ padding: 10 }}>
                        <div>
                            <CreateShelvingTrayPlacement/>
                        </div>
                    </div>
                </DialogModal>
                <DialogModal isOpen={this.props.planogramSettings.isOpen}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);"
                    }}
                    title={getText( "PlanogramSettings")}
                    contentLabel={getText( "PlanogramSettings")}
                    onRequestClose={() => this.props.planogramSettingsClose()}>
                    <div style={{ padding: 10 }}>
                        <div>
                            <PlanogramSettingsForm 
                                onSubmit={(values) => this.props.requestSaveSettings(new Date().getTime(), values)} />
                        </div>
                    </div>
                </DialogModal>
            </div>
        </div>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.planogram,
    stock: state.stock,
    storeType: state.seed.seed.storeAppSettings.typeStore,
    products: state.seed.seed.products,
    externalProducts: state.seed.seed.externalProducts,
    productRail: PlanogramStore.productRailSelector(state),
    families: state.seed.seed.families,
    subFamilies: state.seed.seed.subFamilies,
    isHmiRunning: !hmiNotRunning(state),
    disabled: !canUpdate(state),
    currentUser: state.account.currentUser,
    activePlanogram: state.stock.stockStatus && state.stock.stockStatus.planograms.find(x => x.active),
    actualPlanogram: state.stock.stockStatus &&  Object.assign({},state.stock.stockStatus.planograms.find(x => x.active)),
    maxVolumeSAS: state.robot.robotsState.robots.length > 0 && state.robot.robotsState.robots[0].robotConfiguration.maxVolume
} as PlanogramOwnProps), {
    ...StockStore.actionCreators,
    ...PlanogramStore.actionCreators
})(Planogram as any);