import * as Api from '../api/api';
import * as React from 'react';

interface RobotAnimationProps {
    roobotData: Api.RobotData;
    robot: Api.RobotModel;
}

const imgStyle: React.CSSProperties = {
    position: "absolute"
};

export default class RobotAnimation extends React.Component<RobotAnimationProps, {}> {
    get deliveryCoord(): Api.RobotCoordModel {
        if (!this.props.robot) {
            return {
                x: 1,
                z: 1
            }
        }
        return {
            x: this.props.robot.robotConfiguration.deliveryX,
            z: this.props.robot.robotConfiguration.deliveryZ,
        };
    }

    get idleCoord(): Api.RobotCoordModel {
        if (!this.props.robot) {
            return {
                x: 1,
                z: 1
            }
        }
        return {
            x: this.props.robot.robotConfiguration.idleX,
            z: this.props.robot.robotConfiguration.idleZ,
        };
    }

    get isExtractor1Extracted(): boolean {
        return (this.props.roobotData.extractor1
            && this.props.roobotData.extractor1.isExtracted)
    }
    get isExtractor1Running(): boolean {
        return (this.props.roobotData.extractor1
            && this.props.roobotData.extractor1.isRunning)
    }
    get hasExtractor1ItemDetected(): boolean {
        return (this.props.roobotData.extractor1
            && this.props.roobotData.extractor1.hasItemDetected)
    }

    get hasExtractor2(): boolean {
        return this.props.roobotData.extractor2 ? true : false;
    }
    
    get isExtractor2Extracted(): boolean {
        return (this.props.roobotData.extractor2
            && this.props.roobotData.extractor2.isExtracted)
    }
    get isExtractor2Running(): boolean {
        return (this.props.roobotData.extractor2
            && this.props.roobotData.extractor2.isRunning)
    }
    get hasExtractor2ItemDetected(): boolean {
        return (this.props.roobotData.extractor2
            && this.props.roobotData.extractor2.hasItemDetected)
    }


    idlePixelCoord = {
        x: 330,
        z: 20
    };

    deliveryPixelCoord = {
   
        x: 153,
        z: -10
    };

    getLeftFromX(x: number) {
        let ratioLeft = ((x - this.idleCoord.x)
            / (this.deliveryCoord.x - this.idleCoord.x));
        return (ratioLeft
            * (this.deliveryPixelCoord.x - this.idlePixelCoord.x))
            + this.idlePixelCoord.x;
    }

    getBotFromZ(z: number) {
        let ratioBot = ((z - this.idleCoord.z)
            / (this.deliveryCoord.z - this.idleCoord.z));

        return (ratioBot
            * (this.deliveryPixelCoord.z - this.idlePixelCoord.z))
            + this.idlePixelCoord.z;
    }

    public render() {
        return (
            <div className="robotAnmation" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}>
                <img src={this.props.roobotData.isDeliveryDoorClosed
                    ? "/images/robot/feuOrange.gif"
                    : "/images/robot/feuNoir.gif"}
                    height={15} width={15} style={{ ...imgStyle, left: 5, top: 178, zIndex: 9 }} />
                <img src={this.props.roobotData.isDeliveryDoorOpen
                    ? "/images/robot/feuOrange.gif"
                    : "/images/robot/feuNoir.gif"}
                    height={15} width={15} style={{ ...imgStyle, left: 5, top: 238, zIndex: 9 }} />
                <img src={this.props.roobotData.isDeliveryDoorClosed
                    ? "/images/robot/porteFerme.gif"
                    : "/images/robot/porteOuverte.gif"} height={60} width={64} style={{ ...imgStyle, left: 23, top: 185, zIndex: 10 }} />
                {!this.props.roobotData.isDeliveryDoorOpen
                    && !this.props.roobotData.isDeliveryDoorClosed
                    && <img src={"/images/robot/porteFerme.gif"} height={60} width={64} style={{ ...imgStyle, left: 23, top: 213, zIndex: 10 }} />}
                {this.props.roobotData.isDeliveryDoorOpen
                    && <img src={"/images/robot/porteFerme.gif"} height={60} width={64} style={{ ...imgStyle, left: 23, top: 242, zIndex: 10 }} />}
                

                <img src={this.props.roobotData.isSasDoorOpen
                    ? "/images/robot/feuOrange.gif"
                    : "/images/robot/feuNoir.gif"}
                    height={15} width={15} style={{ ...imgStyle, left: 57, top: 96, zIndex: 9 }} />
                <img src={this.props.roobotData.isSasDoorClosed
                    ? "/images/robot/feuOrange.gif"
                    : "/images/robot/feuNoir.gif"}
                    height={15} width={15} style={{ ...imgStyle, left: 57, top: 150, zIndex: 9 }} />
                <img src={this.props.roobotData.isSasDoorClosed
                    ? "/images/robot/porteFerme.gif"
                    : "/images/robot/porteOuverte.gif"} height={60} width={60} style={{ ...imgStyle, left: 76, top: 157, zIndex: 8 }} />
                {!this.props.roobotData.isSasDoorOpen
                    && !this.props.roobotData.isSasDoorClosed
                    && <img src={"/images/robot/porteFerme.gif"} height={60} width={60} style={{ ...imgStyle, left: 76, top: 128, zIndex: 8 }} />}
                {this.props.roobotData.isSasDoorOpen
                    && <img src={"/images/robot/porteFerme.gif"} height={60} width={60} style={{ ...imgStyle, left: 76, top: 100, zIndex: 8 }} />}

                <div style={{
                    ...imgStyle,
                    zIndex: 9,
                    width: 60,
                    top: 215,
                    left: 73,
                    borderTop: "1px "
                        + (this.props.roobotData.hasItemToSas ? "dashed" : "solid")
                        + " red",
                }}></div>
                <img src={this.props.roobotData.hasItemToSas ? "/images/robot/feuOrange.gif" : "/images/robot/feuNoir.gif"}
                    height={12} width={12}
                    style={{ ...imgStyle, left: 133, top: 210, zIndex: 9 }} />

                <div style={{
                    ...imgStyle,
                    zIndex: 7,
                    width: 60,
                    top: 183,
                    left: 120,
                    borderTop: "1px "
                        + (this.props.roobotData.hasItemToTrash ? "dashed" : "solid")
                        + " red",
                }}></div>
                <img src={this.props.roobotData.hasItemToTrash ? "/images/robot/feuOrange.gif" : "/images/robot/feuNoir.gif"}
                    height={12} width={12}
                    style={{ ...imgStyle, left: 180, top: 178, zIndex: 9 }} />

                <img src={"/images/robot/livraisonWithoutBackground.gif"} style={{ ...imgStyle, left: 20, top: 100, zIndex: 6 }} />
                <img src={"/images/robot/rayonnages.gif"} style={{ ...imgStyle, right: 25, top: 10, zIndex: 5 }} />

                {this.props.roobotData.deliveryBeltState
                    && <img src={this.props.roobotData.deliveryBeltState === "DeliveryDoor"
                        ? "/images/robot/sas.gif"
                        : "/images/robot/rebut.gif"} style={{ ...imgStyle, left: 200, top: 100, zIndex: 9 }} />}
                
                <div style={{
                    ...imgStyle,
                     ...(this.props.roobotData.coord
                         ? {
                            bottom: this.getBotFromZ(this.props.roobotData.coord.z),
                            left: this.getLeftFromX(this.props.roobotData.coord.x),
                         }
                         : {}),
                    zIndex: 9
                    //top: 20
                }}>
                    <div style={{
                        ...imgStyle,
                        width: 40,
                        left: 45, bottom: 10,
                        borderTop: "1px "
                            + (this.props.roobotData.hasItemInAccu ? "dashed" : "solid")
                            + " red",
                        transform: "rotate(150deg)"
                    }}></div>
                    <img style={{ ...imgStyle, left: 75, bottom: 20 }}
                        height={10} width={10}
                        src={this.props.roobotData.hasItemInAccu
                            ? "/images/robot/feuOrange.gif"
                            : "/images/robot/feuNoir.gif"} />

                    {/*Extractor 1*/}
                    <div style={{ ...imgStyle, 
                        ...(this.isExtractor1Extracted 
                            ? { right: -15, top: -8 } 
                            : { right: 0, top: -1 }),
                        width: 109, 
                        height: 11
                    }}>
                        <div style={{
                            ...imgStyle,
                            width: 78,
                            left: 19,
                            bottom: 6,
                            zIndex: 2,
                            borderTop: "1px "
                                + (this.hasExtractor1ItemDetected ? "dashed" : "solid")
                                + " red"
                        }}></div>
                        <img style={{
                            ...imgStyle,
                            zIndex: 2,
                            ...({ left: 10, bottom: 5 })
                        }}
                             height={10} width={10}
                             src={this.hasExtractor1ItemDetected
                                 ? "/images/robot/feuOrange.gif"
                                 : "/images/robot/feuNoir.gif"} />
                        <img src={this.isExtractor1Running
                            ? "/images/robot/extractorR.png"
                            : "/images/robot/extractorN.png"} width={109} height={11} style={{ ...imgStyle, right: 0, top: 0 }} />
                    </div>

                    {/*Extractor 2*/}
                    {this.hasExtractor2
                    && <div style={{ ...imgStyle,
                        ...(this.isExtractor2Extracted
                            ? { right: 35, bottom: -8 }
                            : { right: 20, bottom: -1 }),
                        width: 109,
                        height: 11
                    }}>
                        <div style={{
                            ...imgStyle,
                            width: 78,
                            left: 19,
                            bottom: 6,
                            zIndex: 2,
                            borderTop: "1px "
                                + (this.hasExtractor2ItemDetected ? "dashed" : "solid")
                                + " red"
                        }}></div>
                        <img style={{
                            ...imgStyle,
                            zIndex: 2,
                            ...({ left: 10, bottom: 5 })
                        }}
                             height={10} width={10}
                             src={this.hasExtractor2ItemDetected
                                 ? "/images/robot/feuOrange.gif"
                                 : "/images/robot/feuNoir.gif"} />
                        <img src={this.isExtractor2Running
                            ? "/images/robot/extractorR.png"
                            : "/images/robot/extractorN.png"} width={109} height={11} style={{ ...imgStyle, right: 0, top: 0 }} />
                    </div>}
                    
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img width={211} height={22} src={"/images/robot/accu.png"} />
                        {this.props.roobotData.accumulatorState
                            && <img src={this.props.roobotData.accumulatorState === "Accumulator"
                            ? "/images/robot/accu.gif"
                            : "/images/robot/liv.gif"} />}
                    </div>
                </div>

            </div>
        );
        }
}