import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface MenuCategoriesState {
    menuCategoriesStates : {
        isLoading: boolean;
        requestTime?: number;
    }
    menuCategoriesTranslationsStates : {
        isLoading: boolean;
        requestTime?: number;
    }
    selectedMenuCategoryId?: number;
    selectedMenuCategoryTranslationId?: number;
}

interface UpdateSelectedMenuCategoryId {
    type: "MENUCAT_UPDATE_SELECTEDMENUCATID";
    payload: { id: number; }
}
interface UpdateSelectedMenuCategoryTranslationId {
    type: "MENUCAT_UPDATE_SELECTEDMENUCATTRANSID";
    payload: { id: number; }
}

interface MenuCategoryRequestCreate {
    type: "MENUCAT_REQUEST_CREATE";
    payload: { requestTime: number; }
}
export interface MenuCategoryReceiveCreate {
    type: "MENUCAT_RECEIVE_CREATE";
    payload: { requestTime: number; model?: Api.MenuCategoryModel }
    error?: any
}

interface MenuCategoryRequestUpdate {
    type: "MENUCAT_REQUEST_UPDATE";
    payload: { requestTime: number; }
}
export interface MenuCategoryReceiveUpdate {
    type: "MENUCAT_RECEIVE_UPDATE";
    payload: { requestTime: number; model?: Api.MenuCategoryModel }
    error?: any
}

interface MenuCategoryRequestDelete {
    type: "MENUCAT_REQUEST_DELETE";
    payload: { requestTime: number; }
}
export interface MenuCategoryReceiveDelete {
    type: "MENUCAT_RECEIVE_DELETE";
    payload: { requestTime: number; idMenuCategory: number}
    error?: any
}


interface MenuCategoryTransRequestCreate {
    type: "MENUCAT_REQUEST_CREATE_TRANSLATION";
    payload: { requestTime: number; }
}
export interface MenuCategoryTransReceiveCreate {
    type: "MENUCAT_RECEIVE_CREATE_TRANSLATION";
    payload: { requestTime: number; model?: Api.MenuCategoryTranslationModel }
    error?: any
}

interface MenuCategoryTransRequestUpdate {
    type: "MENUCAT_REQUEST_UPDATE_TRANSLATION";
    payload: { requestTime: number; }
}
export interface MenuCategoryTransReceiveUpdate {
    type: "MENUCAT_RECEIVE_UPDATE_TRANSLATION";
    payload: { requestTime: number; model?: Api.MenuCategoryTranslationModel }
    error?: any
}

interface MenuCategoryTransRequestDelete {
    type: "MENUCAT_REQUEST_DELETE_TRANSLATION";
    payload: { requestTime: number; }
}
export interface MenuCategoryTransReceiveDelete {
    type: "MENUCAT_RECEIVE_DELETE_TRANSLATION";
    payload: { requestTime: number; idMC: number; idMCT: number}
    error?: any
}

type KnownAction = UpdateSelectedMenuCategoryId 
    | UpdateSelectedMenuCategoryTranslationId
    | MenuCategoryRequestCreate
    | MenuCategoryReceiveCreate
    | MenuCategoryRequestUpdate
    | MenuCategoryReceiveUpdate
    | MenuCategoryRequestDelete
    | MenuCategoryReceiveDelete
    | MenuCategoryTransRequestCreate
    | MenuCategoryTransReceiveCreate
    | MenuCategoryTransRequestUpdate
    | MenuCategoryTransReceiveUpdate
    | MenuCategoryTransRequestDelete
    | MenuCategoryTransReceiveDelete
;

export const actionCreators = {
    UpdateSelectedMenuCategory: (id: number) => <UpdateSelectedMenuCategoryId>{
        type: "MENUCAT_UPDATE_SELECTEDMENUCATID",
        payload: { id: id }
    },
    UpdateSelectedMenuCategoryTranslation: (id: number) => <UpdateSelectedMenuCategoryTranslationId>{
        type: "MENUCAT_UPDATE_SELECTEDMENUCATTRANSID",
        payload: { id: id }
    },
    requestCreateMenuCategory: (requestTime: number, model: Api.MenuCategoryModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuCategoriesApi();
        model.name = model.name.toUpperCase();
        let fetchTask = api.createMenuCategory({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(mc => {
                dispatch({
                    type: "MENUCAT_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, model: mc }
                });
            })
            .catch(err => {
                dispatch({
                    type: "MENUCAT_RECEIVE_CREATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "MENUCAT_REQUEST_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateMenuCategory: (requestTime: number, model: Api.MenuCategoryModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuCategoriesApi();
        model.name = model.name.toUpperCase();
        let fetchTask = api.updateMenuCategory({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(mc => {
                dispatch({
                    type: "MENUCAT_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime, model: mc }
                });
            })
            .catch(err => {
                dispatch({
                    type: "MENUCAT_RECEIVE_UPDATE",
                    payload: { requestTime: requestTime},
                    error: err
                });
            });

        dispatch({
            type: "MENUCAT_REQUEST_UPDATE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestDeleteMenuCategory: (requestTime: number, idMenuCategory: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuCategoriesApi();
        let fetchTask = api.deleteMenuCategory({
            id: idMenuCategory
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(mc => {
                dispatch({
                    type: "MENUCAT_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, idMenuCategory : idMenuCategory }
                });
            })
            .catch(err => {
                dispatch({
                    type: "MENUCAT_RECEIVE_DELETE",
                    payload: { requestTime: requestTime, idMenuCategory : idMenuCategory },
                    error: err
                });
            });

        dispatch({
            type: "MENUCAT_REQUEST_DELETE",
            payload: { requestTime: requestTime}
        });
        return fetchTask;
    },
    requestCreateMenuCategoryTranslation: (requestTime: number, model: Api.MenuCategoryTranslationModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuCategoriesApi();
        model.translation = model.translation.toUpperCase();
        let fetchTask = api.createMenuCategoryTranslation({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(mct => {
                dispatch({
                    type: "MENUCAT_RECEIVE_CREATE_TRANSLATION",
                    payload: { requestTime: requestTime, model: mct }
                });
            })
            .catch(err => {
                dispatch({
                    type: "MENUCAT_RECEIVE_CREATE_TRANSLATION",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "MENUCAT_REQUEST_CREATE_TRANSLATION",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestUpdateMenuCategoryTranslation: (requestTime: number, model: Api.MenuCategoryTranslationModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuCategoriesApi();
        model.translation = model.translation.toUpperCase();
        let fetchTask = api.updateMenuCategoryTranslation({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(mct => {
                dispatch({
                    type: "MENUCAT_RECEIVE_UPDATE_TRANSLATION",
                    payload: { requestTime: requestTime, model: mct }
                });
            })
            .catch(err => {
                dispatch({
                    type: "MENUCAT_RECEIVE_UPDATE_TRANSLATION",
                    payload: { requestTime: requestTime},
                    error: err
                });
            });

        dispatch({
            type: "MENUCAT_REQUEST_UPDATE_TRANSLATION",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestDeleteMenuCategoryTranslation: (requestTime: number, idMC: number, idMCT: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.MenuCategoriesApi();
        let fetchTask = api.deleteMenuCategoryTranslation({
            id: idMCT
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(mct => {
                dispatch({
                    type: "MENUCAT_RECEIVE_DELETE_TRANSLATION",
                    payload: { requestTime: requestTime, idMC: idMC, idMCT : idMCT }
                });
            })
            .catch(err => {
                dispatch({
                    type: "MENUCAT_RECEIVE_DELETE_TRANSLATION",
                    payload: { requestTime: requestTime, idMC: idMC, idMCT : idMCT },
                    error: err
                });
            });

        dispatch({
            type: "MENUCAT_REQUEST_DELETE_TRANSLATION",
            payload: { requestTime: requestTime}
        });
        return fetchTask;
    },
}

const unloadedState: MenuCategoriesState = {
    menuCategoriesStates : {
        isLoading: false
    },
    menuCategoriesTranslationsStates : {
        isLoading: false
    }
}

export const reducer: Reducer<MenuCategoriesState> = (state: MenuCategoriesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "MENUCAT_UPDATE_SELECTEDMENUCATID":
            return {
                ...state,
                selectedMenuCategoryId: action.payload.id
            };
        case "MENUCAT_UPDATE_SELECTEDMENUCATTRANSID":
            return {
                ...state,
                selectedMenuCategoryTranslationId: action.payload.id
            };
        case "MENUCAT_REQUEST_CREATE":
        case "MENUCAT_REQUEST_UPDATE":
        case "MENUCAT_REQUEST_DELETE":
            return {
                ...state,
                menuCategoriesStates: {
                    ...state.menuCategoriesStates,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "MENUCAT_RECEIVE_CREATE":
        case "MENUCAT_RECEIVE_UPDATE":
        case "MENUCAT_RECEIVE_DELETE":
            return {
                ...state,
                menuCategoriesStates: {
                    ...state.menuCategoriesStates,
                    isLoading: false
                },
            };
        case "MENUCAT_REQUEST_CREATE_TRANSLATION":
        case "MENUCAT_REQUEST_UPDATE_TRANSLATION":
        case "MENUCAT_REQUEST_DELETE_TRANSLATION":
            return {
                ...state,
                menuCategoriesTranslationsStates: {
                    ...state.menuCategoriesTranslationsStates,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "MENUCAT_RECEIVE_CREATE_TRANSLATION":
        case "MENUCAT_RECEIVE_UPDATE_TRANSLATION":
        case "MENUCAT_RECEIVE_DELETE_TRANSLATION":
            return {
                ...state,
                menuCategoriesTranslationsStates: {
                    ...state.menuCategoriesTranslationsStates,
                    isLoading: false
                },
                selectedMenuCategoryTranslationId: undefined
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        const exhaustiveCheck: never = action;
    }
     // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}