import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { getText } from '../utils/texts';

type RobotSimulationFormProps = ReduxForm.InjectedFormProps<Api.RobotSimulationModel, {}, {}>
    & RobotSimulationFormOwnProps
    & RobotSimulationFormExternalProps;

interface RobotSimulationFormExternalProps {
    onSubmit: (values: Api.RobotSimulationModel) => any;
    stopSimulation: () => void;
    disabled: boolean;
}

interface RobotSimulationFormOwnProps {
}

const labelStyle: React.CSSProperties = {paddingBottom: "3%",}
const inputStyle: React.CSSProperties = { minWidth: 200, height: 40}
const btnStyle: React.CSSProperties = { backgroundColor: "rgba(59, 67, 112, 0.25)", height: 30, fontSize: 14 };

class RobotSimulationForm extends React.Component<RobotSimulationFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} disableResetOnSubmit={true}>
                <div className="selectors borderSelectors" style={{display: 'flex', justifyContent: 'space-between' }}>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ ...labelStyle }}>{getText( "MaintenanceType2")}</div>
                        <ReduxForm.Field
                            name="type"
                            style={inputStyle}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.getSelectField([
                                { label: getText( "MaintenanceRobotSimulationLoop"), value: "Loop" },
                                { label: getText( "MaintenanceRobotSimulationOnce"), value: "Once" }
                            ] as Array<{ label: string, value: Api.RobotSimulationModelTypeEnum }>)}
                        />
                    </div>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ ...labelStyle }}>{getText( "MaintenanceType1")}</div>
                        <ReduxForm.Field
                            name="extractionType"
                            style={inputStyle}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.getSelectField([
                                { label: getText( "MaintenanceRobotSimulationNormal"), value: "Normal" },
                                { label: getText( "MaintenanceRobotSimulationTiming"), value: "Timing" },
                                { label: getText( "MaintenanceRobotSimulationEmpty"), value: "Empty" },
                            ] as Array<{ label: string, value: Api.RobotSimulationModelExtractionTypeEnum }>)}
                        />
                    </div>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ ...labelStyle }}>{getText( "MaintenanceTimeExt")}</div>
                        <ReduxForm.Field
                            name="extractionTimer"
                            className={"form-control"}
                            style={{ ...inputStyle, width: 80 }}
                            disabled={this.props.disabled}
                            validate={[FormField.requiredOrZero]}
                            component={FormField.NumberField}
                        />
                    </div>
                    <div style={{display: 'flex', alignItems:'flex-end'}}>
                        <button style={{ ...btnStyle, height: 40, minWidth: 200 }}
                            disabled={true}
                            onClick={() => { }}
                            className="btn btn-sm btn-secondary">
                            {getText( "MaintenanceUnitTestReset")}
                        </button>
                    </div>
                </div>
                <div className="selectors borderSelectors" style={{display: 'flex', justifyContent: 'space-between' }}>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ ...labelStyle }}>{getText( "MaintenanceType3")}</div>
                        <ReduxForm.Field
                            name="deliverProducts.destination"
                            style={inputStyle}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.getSelectField([
                                { label: getText( "MaintenanceDeliveryDoor"), value: "DeliveryDoor" },
                                { label: getText( "MaintenanceTrash"), value: "Trash" },
                                { label: getText( "MaintenanceNone"), value: "None" },
                            ] as Array<{ label: string, value: Api.DeliverProductsModelDestinationEnum }>)}
                        />
                    </div>
                </div>
                <div className="selectors borderSelectors" style={{display: 'flex', justifyContent: 'space-between' }}>
                    <div className="form-group" style={{margin: 0}}>
                        <div style={{ ...labelStyle }}>{getText( "MaintenanceSimulationRobot")}</div>
                        <ReduxForm.Field
                            name="simulationReturn"
                            style={{ ...inputStyle }}
                            validate={[FormField.required]}
                            disabled={this.props.disabled}
                            component={FormField.getSelectField([
                                { label: getText( "MaintenanceRobotSimulationIdle"), value: "Idle" },
                                { label: getText( "MaintenanceRobotSimulationNone"), value: "None" }
                            ] as Array<{ label: string, value: Api.RobotSimulationModelSimulationReturnEnum }>)}
                        />
                    </div>
                    <div style={{marginRight: '19%', display: 'flex', alignItems: 'center'}}>
                        <ReduxForm.FieldArray
                            validate={[FormField.requiredOne]}
                            name="deliverProducts.productOrders"
                            component={ProductOrders}
                            disabled={this.props.disabled}
                            props={{
                                disabled: this.props.disabled
                            }}/>
                    </div>
                </div>
                <div className="selectors borderSelectors" style={{display: 'flex', flexDirection: 'row-reverse' }}>
                    <div style={{display: 'flex', alignItems:'flex-end', minWidth: 440}}>
                        <button className="btn btn-primary"
                            type={"submit"}
                            style={{ height: 40, fontSize: 14, margin: '0 2%' }}
                            disabled={this.props.disabled || this.props.submitting || this.props.pristine}>
                            {getText( "MaintenanceStartSimulation")}
                        </button>
                        <button className="btn btn-secondary"
                            type={"button"}
                            disabled={this.props.disabled}
                            onClick={(e) => {
                                this.props.stopSimulation();
                                //making sure it doesnt submit on bad browsers
                                e.preventDefault();
                                return false;
                            }}
                            style={{ ...btnStyle, height: 40, minWidth: 80 }}>
                            {getText( "MaintenanceIHMStop")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

type ProductOrdersProps = ReduxForm.WrappedFieldArrayProps<Api.ProductOrderModel> & {
    disabled: boolean;
};

const cellStyle: React.CSSProperties = {
    borderLeft: "1px solid",
    borderBottom: "1px solid",
    padding: "5px 0px",
    textAlign: "center",
    minWidth: 100,
};

const headCellStyle: React.CSSProperties = {
    ...cellStyle,
    borderTop: "1px solid",
};

const bodyCellStyle: React.CSSProperties = {
    ...cellStyle,
    minHeight: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

class ProductOrders extends React.Component<ProductOrdersProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ ...headCellStyle }}>R</div>
                    <div style={{ ...headCellStyle }}>A</div>
                    <div style={{ ...headCellStyle }}>D</div>
                    <div style={{ ...headCellStyle }}>F</div>
                    <div style={{ ...headCellStyle }}>{getText( "MaintenanceProf")}</div>
                    <div style={{ ...headCellStyle, borderRight: "1px solid" }}>{getText( "MaintenanceQty")}</div>
                </div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{
                        ...bodyCellStyle,
                    }}>
                        <ReduxForm.Field
                            name={`${member}.coord.r`}
                            style={{ width: 70 }}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{
                        ...bodyCellStyle,
                    }}>
                        <ReduxForm.Field
                            name={`${member}.coord.a`}
                            style={{ width: 70 }}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{
                        ...bodyCellStyle,
                    }}>
                        <ReduxForm.Field
                            name={`${member}.coord.d`}
                            style={{ width: 70 }}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{
                        ...bodyCellStyle,
                    }}>
                        <ReduxForm.Field
                            name={`${member}.coord.f`}
                            style={{ width: 70 }}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{
                        ...bodyCellStyle,
                    }}>
                        <ReduxForm.Field
                            name={`${member}.productRailDepth`}
                            style={{ width: 70 }}
                            disabled={this.props.disabled}
                            validate={[FormField.required]}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{
                        ...bodyCellStyle, borderRight: '1px solid'
                    }}>
                        <ReduxForm.Field
                            name={`${member}.quantity`}
                            style={{ width: 70 }}
                            disabled={this.props.disabled}
                            validate={[FormField.requiredOrZero]}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{
                        minWidth: 50
                    }}>
                        <i onClick={() => this.props.fields.remove(index)}
                            style={{ cursor: "pointer" }}
                            className="glyphicon glyphicon-remove"></i>
                    </div>
                </div>)}
                <div style={{display: 'flex', flexDirection:'row-reverse', margin: '14px 0', minWidth: 400}}>
                    <button
                        type="button"
                        style={{height: 40, minWidth: 150}}
                        className="btn btn-primary"
                        disabled={this.props.disabled}
                        onClick={() => {this.props.fields.push({})}}> 
                        <i className="glyphicon glyphicon-arrow-down"></i> {getText( "MaintenanceAdd")} <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}

export default ReduxForm.reduxForm({
    form: 'deliverProducts',
    enableReinitialize: true,
    destroyOnUnmount: true
})(RobotSimulationForm as any) as any as React.ComponentClass<RobotSimulationFormExternalProps>;