import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as HistoryStore from "../store/History";
import Table from "./Table";
import { getText } from '../utils/texts';
import {generateCsvFromTable, CsvData } from "../utils/csv";
import * as Styles from '../styles/baseStyle';
import Moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

interface HistoryTurnoverTotalProps {
    isLoading: boolean;
    groupedStoreDatas: Array<HistoryStore.GroupedStoreData>;
    seed: Api.SeedMngModel;
}

export default class HistoryTurnoverTotal extends React.Component<HistoryTurnoverTotalProps, {}> {
    tableContainer: any = null;

    get usedVats(): Array<Api.VatModel> {
        return _.sortBy(_.values(this.props.seed.vats)
            .filter(x => this.props.groupedStoreDatas 
                && this.props.groupedStoreDatas.length !== 0
                && this.props.groupedStoreDatas.some(y => y.vatAmounts.some(z => z.vatId === x.vatId && z.amount !== 0))));
    }
    
    get csvData(): CsvData {
        if(!this.tableContainer)
            return {
                data: [],
                headers: []
            };
        
        return generateCsvFromTable(this.tableContainer);
    }

    get columns()
    {
        let arr: any[] = [];
        this.usedVats.map(x => {
            arr.push({
                Header: `${getText("HistoCAHT")} ${x.name}`,
                accessor: `caht${String(x.value).replace(".", "0")}`
            });
            arr.push({
                Header: `${getText("HistoTVA")} ${x.name}`,
                accessor: `tva${String(x.value).replace(".", "0")}`
            })
        })
        return arr;
    }

    getValueForData(indexVat: number): number{
        let auxValue = _.sum(_.map(this.props.groupedStoreDatas, y => _.sum(y.vatAmounts
            .filter(z => z.vatId === this.usedVats[indexVat].vatId)
            .map(z => z.amount / (1 + (this.props.seed.vats[z.vatId].value / 100))))));
        return auxValue;
    }

    get data()
    {
        let arr: any[] = [];
        let cad = "{"
        this.usedVats.forEach((item, index) => {
            cad = `${cad} "caht${String(item.value).replace(".", "0")}": 
                    "${this.getValueForData(index).toFixed(2)}",
                 "tva${String(item.value).replace(".", "0")}":
                    "${(this.getValueForData(index) / 100 * item.value).toFixed(2)}"
            ${(this.usedVats.length-1) == index ? "}" : ","}`;
        });
        arr.push(JSON.parse(cad));
        return arr
    }


    public render() {
        return (
            <div>
                { this.usedVats.length != 0 &&
                    <div className="card" style={{ ...Styles.cardStyle, margin: '9px 1% 0 1%', height: "20%"}}>
                    <div style={{ flex: "0 0 auto" , direction:"rtl", paddingRight: 12, paddingTop: 12}}>
                        <button type={"button"}
                            className="btn btn-primary"
                            style={{height: 40}}
                            onClick={(e) => {
                                const options = {
                                    fieldSeparator: ';',
                                    quoteStrings: '"',
                                    decimalSeparator: ',',
                                    showLabels: false,
                                    showTitle: false,
                                    filename: `${getText("HistoTitle")}-${getText("HistoTurnoverTotal")}-${Moment().format('YYYYMMDDHHmm')}`,
                                    useTextFile: false,
                                    useBom: true,
                                    useKeysAsHeaders: false,
                                    headers: this.csvData.headers,
                                };
                                const csvExporter = new ExportToCsv(options);
                                csvExporter.generateCsv(this.csvData.data);  
                            }}>
                                {getText( "HistoExport")}
                                <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                        </button>
                    </div>
                    <div ref={(r) => this.tableContainer = r}>
                        <Table   
                        columns={this.columns}
                        data={this.data}
                        loading={this.props.isLoading}  
                        defaultPageSize = {2} 
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        />
                    </div>
                </div>
                }
            </div>
        );
    }
}