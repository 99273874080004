import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as _ from 'lodash';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface InterventionsState {
    isLoading: boolean;
    requestTime?: number;
    interventions:  { [id: number]: Api.InterventionModel};
    createState: {
        isLoading: boolean;
        requestTime?: number;
    };
}

interface InterventionsRequestEntities {
    type: "INTERVENTIONS_REQUEST_ENTITIES";
    payload: { requestTime: number }
}
interface InterventionsReceiveEntities {
    type: "INTERVENTIONS_RECEIVE_ENTITIES";
    payload: {
        requestTime: number;
        interventions?: { [id: number]: Api.InterventionModel };
    },
    error?: any
}

interface InterventionsRequestCreate {
    type: "INTERVENTIONS_REQUEST_CREATE";
    payload: { requestTime: number; }
}
interface InterventionsReceiveCreate {
    type: "INTERVENTIONS_RECEIVE_CREATE";
    payload: { requestTime: number; value?: Api.InterventionModel }
    error?: any
}

interface InterventionsRequestClose {
    type: "INTERVENTIONS_REQUEST_CLOSE";
    payload: { requestTime: number; }
}
interface InterventionsReceiveClose {
    type: "INTERVENTIONS_RECEIVE_CLOSE";
    payload: { requestTime: number; id: number}
    error?: any
}

type KnownAction = InterventionsRequestEntities
    | InterventionsReceiveEntities
    | InterventionsRequestCreate
    | InterventionsReceiveCreate
    | InterventionsRequestClose
    | InterventionsReceiveClose;

export const requestInterventions = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.InterventionsApi();
    let fetchTask = api.getStoreInterventions({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(interventions => {
            dispatch({
                type: "INTERVENTIONS_RECEIVE_ENTITIES",
                payload: { requestTime: requestTime, interventions: interventions }
            });
        })
        .catch(err => {
            dispatch({
                type: "INTERVENTIONS_RECEIVE_ENTITIES",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "INTERVENTIONS_REQUEST_ENTITIES",
        payload: { requestTime: requestTime }
    });
    addTask(fetchTask);
    return fetchTask;
};

export const actionCreators = {
    requestInterventions: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestInterventions(requestTime, dispatch, getState);
    },
    requestCreateInterventions: (requestTime: number, model: Api.InterventionModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.InterventionsApi();
        model.creationDate = new Date();
        let fetchTask = api.createStoreInterventions({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(interventions => {
                dispatch({
                    type: "INTERVENTIONS_RECEIVE_CREATE",
                    payload: { requestTime: requestTime, value: interventions }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "INTERVENTIONS_RECEIVE_CREATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "INTERVENTIONS_REQUEST_CREATE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    },
    requestCloseInterventions: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.InterventionsApi();

        let fetchTask = api.closeIntervention({
            idIntervention: id
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "INTERVENTIONS_RECEIVE_CLOSE",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: "INTERVENTIONS_RECEIVE_CLOSE",
                    payload: { requestTime: requestTime, id: id },
                    error: err
                });
            });

        dispatch({
            type: "INTERVENTIONS_REQUEST_CLOSE",
            payload: { requestTime: requestTime }
        });
        return fetchTask;
    }
};

const unloadedState: InterventionsState = {
    isLoading: false,
    interventions: [],
    createState: {
        isLoading: false
    }
};

export const reducer: Reducer<InterventionsState> = (state: InterventionsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "INTERVENTIONS_REQUEST_ENTITIES":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "INTERVENTIONS_RECEIVE_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isloading: false,
                interventions: action.payload.interventions,
            };
        case "INTERVENTIONS_REQUEST_CREATE":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "INTERVENTIONS_RECEIVE_CREATE":
            return {
                ...state,
                interventions: action.error
                    ? state.interventions
                    : {
                        [action.payload.value.interventionId]: action.payload.value,
                        ...state.interventions
                        
                    },
                createState: {
                    ...state.createState,
                    isLoading: action.payload.requestTime !== state.createState.requestTime
                        ? state.createState.isLoading
                        : false
                }
            };
            case "INTERVENTIONS_REQUEST_CLOSE":
                return {
                    ...state,
                    createState: {
                        ...state.createState,
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                };
            case "INTERVENTIONS_RECEIVE_CLOSE":
                return {
                    ...state,
                    interventions: action.error
                        ? state.interventions
                        :  _.filter(state.interventions, x => x.interventionId !== action.payload.id)
                };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};