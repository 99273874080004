import * as React from 'react';
import * as FormField from './FormField';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { getText } from '../utils/texts';

interface LoginLgapOwnProps {
    lgapUri: string;
 }
type LoginLgapFormProps = LoginLgapOwnProps & InjectedFormProps<{}, {}, {}> ;

class LoginLgapForm extends React.Component<LoginLgapFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}
                className="form-vertical"
                contentContainerStyle={{fontSize: '16px'}}
                disableResetOnSubmit={true}>
                <div style={{fontSize: 30, paddingBottom: '10%'}}>
                    {getText( "GenLGAP")}
                </div>
                {/* <div className="form-group">
                    <label className="control-label">{getText( "GenUsername")} </label>
                    <Field name="username"
                        className="form-control"
                        type="text"
                        // validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
                <div className="form-group">
                    <label className="control-label">{getText( "GenPassword")} </label>
                    <Field name="password"
                        className="form-control"
                        type="password"
                        // validate={[FormField.required]}
                        component={FormField.renderField} />
                </div> */}
                <div className="form-group">
                    <button className="btn btn-primary"
                        style={{width: '100%'}}
                        // disabled={this.props.submitting
                        //     || this.props.pristine}
                        type={"button"}
                        onClick={() => window.open(this.props.lgapUri, '_blank')}>{getText( "GenLogin")}</button>
                </div>
            </FormField.BaseForm>
        );
    }
}

export default reduxForm({
    form: 'loginLGAP',
})(LoginLgapForm as any) as React.ComponentClass<any>;

