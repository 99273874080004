import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as StockStore from "../store/Stock";
import * as ReorderStore from "../store/Reorder";
import * as SupplierOrderStore from "../store/SupplierOrder";
import * as ReactTable from "react-table";
import Table from "./Table";
import DialogModal from "./DialogModal";
import { CreateSupplierOrderForm } from './SupplierOrderForm';
import { canUpdate } from '../security/roles';
import Select from "./Select";
import { ProductPriceCalculator, ProductPriceInfo } from '../utils/priceUtils';
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import CheckBox from "./CheckBox";
import InputNumber from "./InputNumber";
import { ExportToCsv } from 'export-to-csv';
import {generateCsvFromTable, CsvData } from "../utils/csv";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

type ReapproProps = ReorderStore.ReorderState
    & ReapproOwnProps
    & typeof StockStore.actionCreators
    & typeof ReorderStore.actionCreators
    & typeof SupplierOrderStore.actionCreators
    ;

interface ReapproOwnProps {
    reorderDatas: Array<ReorderStore.ReorderData>;
    productsDataCompleted: { [storeItemId: number]: ReorderStore.ProductData },
    seed: Api.SeedMngModel;
    isSupplierOrderOpen: boolean;
    supplierOrders: Array<Api.SupplierOrderModel>;
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;
    suppliers: { [id: number]: Api.SupplierModel };
    stock: StockStore.StockState;
    currencies: { [id: number]: Api.CurrencyModel };
    products: { [id: number]: Api.ProductModel };
    units: { [id: number]: Api.UnitModel };
    vats: { [id: number]: Api.VatModel };
}

type ReorderColumn = ReactTable.Column
    & {
        accessor: (d: ReorderStore.ReorderData) => any;
        columns?: Array<ReorderColumn>
    }
interface ReapproState {
    filterInputTab: string;
}
const headerSpaceStyle: React.CSSProperties = { whiteSpace: 'break-spaces' };
const headerPaddingStyle: React.CSSProperties = {padding: '30px 0px 0px 0px'}; //'9px 0'};
class Reappro extends React.Component<ReapproProps,  ReapproState> {

    constructor(props: ReapproProps) {
        super(props);
        this.state = {
            filterInputTab: ''
        };
        this.onCoefChange = this.onCoefChange.bind(this);
    }

    priceCalculator: ProductPriceCalculator = new ProductPriceCalculator(
        this.props.units,
        this.props.vats,
        this.props.suppliers,
        this.props.currencies);

    productRails() {
        let planogramId = _.sortBy(
            this.props.stock.stockStatus.planograms, x => !x.active)[0].planogramId;
        return this.props.stock.stockStatus.productRails
            .filter(x => x.planogramId === planogramId);
    }

    product(productId): Api.ProductModel {
        return this.props.products[productId];
    }

    componentDidMount() {
        this.props.requestSupplierOrders(new Date().getTime());
        this.props.requestReorderData(new Date().getTime());
    }

    getDaysToProject(storeItemId: number): number {
        let defValue = ReorderStore.getDaysToProject(storeItemId,this.props.productsData, this.props.seed.products, this.props.filter.watendNbDays)
        return this.props.productsData[storeItemId]
            ? this.props.productsData[storeItemId].daysToProject
            || defValue
            : defValue;
    }

    getTotalSold(d: ReorderStore.ReorderData, nbDays: number): number {
        let auxVal = d.dayProductData.length - nbDays;
        return _.sum(d.dayProductData
            .filter((x, xi) => xi >= auxVal)
            .map(x => x.sellCount));
    }

    get productSuppliers() {
        return this.reorderDatas
            .map(element => ReorderStore.getProductSupplier(element.storeItemId, this.props.seed.products))
            .map(x => {
                return {
                    name: this.props.suppliers[x.supplierMainId].name,
                    supplierMainId: x.supplierMainId 
                }
            });
    }
    
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get references(): Array<string> {
        return _.uniq(this.reorderDatas
            .map(x => ReorderStore.getProductSupplier(x.storeItemId, this.props.seed.products))
            .map(x => x.reference));
    }

    onCoefChange(e): void {
        var value = parseFloat(e.target.value)
        if (!isNaN(value) && value >= 0 && value <= 100) {
            this.props.updateCoef(value)
        }
        else {
            alert(getText("ValidationCoef"))
        }
    }

    handleWantedDays(storeItemId: number,value: number) {
        if(value) {
            this.props.requesUpdateDaysToProject({ productId: storeItemId, daysToProject: value}, new Date().getTime());  
        }
    }

    handleToOrder(storeItemId, value, days) {
        this.props.updateToOrder(storeItemId, parseInt(value) || 0, days);
    }

    getProbableTotalStock(d: ReorderStore.ReorderData, packCondition: number): number {
        return d.currentStock + (_.sum(this.props.supplierOrders.filter(x => x.status != "Received").map(x => x.supplierOrderProductSuppliers)
        .reduce((a, b) => a.concat(b), []).filter(x => x.productId === d.storeItemId).map(x => x.packQuantity)) * packCondition);
    }

    get reorderDatas() {
        return this.props.reorderDatas
            .filter(x => !this.props.filter.propositionOnly || this.productDataCompleted(x.storeItemId).toOrder > 0)
            .sort((a, b) => this.props.seed.products[a.storeItemId].name.localeCompare(this.props.seed.products[b.storeItemId].name));
    }

    productDataCompleted(storeItemId: number): ReorderStore.ProductData {
        return _.values(this.props.productsDataCompleted).find(x => x.storeItemId == storeItemId) ? 
            _.values(this.props.productsDataCompleted).find(x => x.storeItemId == storeItemId) : 
            { storeItemId: storeItemId, daysToProject: 0, toOrder: 0}
    }

    stockArr(productId: number) {
        if (productId) {
            return _.sum(this.props.stock.stockStatus.productReceptions
                .filter(y => y.productId === productId)
                .map(y => y.quantityRemaining))
        } else {
            return 0
        }
    }

    stockRobot(productId: number) {
        if (productId) { 
            return _.sum(this.productRails()
                .filter(y => y.productId == productId)
                .map(x => x.productStocks.length))
        } else {
            return 0;
        }
    }

    handleSubmit(model: Api.SupplierOrderModel) {        
        (this.props.requestCreateSupplierOrder(new Date().getTime(), model) as any as Promise<any>)
            .then(() => { 
                this.props.requestSupplierOrders(new Date().getTime());
        });
        this.props.closeSupplierOrderDialog();
    }

    csvData(): CsvData {
        if(!document.getElementById("reappro-container"))
            return {
                data: [],
                headers: []
            };

        return generateCsvFromTable(document.getElementById("reappro-container"));
    }

    get isMobileDevice(){
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        return regexp.test(details);
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF('l');
        autoTable(pdf, {body: data});
        pdf.save(`${getText("ReorderTabReorder")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    public render() {
        return (
            <div>
                <div className="card" style={{ ...Styles.cardStyle, ...Styles.pageTitle}}>
                    <span style={{paddingLeft: 30}}>
                        {getText( "ReorderTabReorder")} 
                    </span>
                    <HelpHeader />
                </div>
                <div className="selectors" style={{
                    flex: "0 0 auto",
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    margin: "1%"
                }}>
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <span style={{fontSize: 16}}> {getText( "ReorderSource")}: </span>
                        <div className="card" style={{ ...Styles.cardStyle }} >
                            <Select options={[{
                                label: "------",
                                value: undefined
                                }].concat(_.uniqBy(_.sortBy(_.values(this.productSuppliers)
                                    /*.filter(x =>
                                        this.reorderDatas.filter(y =>
                                            ReorderStore.getProductSupplier(y.storeItemId, this.props.seed.products).supplierMainId === x.supplierMainId))*/
                                    .map(z => ({ label: z.name, value: z.supplierMainId })), x => x.label), x => (x as any).label) as any)} 
                                    style={{
                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                    }}
                                    value={this.props.filter.supplierMainId}
                                    onChange={(newSupplierMainId) => {
                                        this.props.updateFilterSupplierMainId(newSupplierMainId);
                                    }}
                            />
                        </div>
                    </div>
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <span style={{fontSize: 16}}> {getText( "ReorderCode")}: </span>
                        <div className="card" style={{ ...Styles.cardStyle }} >
                            <Select options={[{
                                label: "------",
                                value: undefined
                                }].concat(_.values(this.references)
                                    .map(z => ({ label: z, value: z }))
                                    .sort((a, b) => a.label.localeCompare(b.label)))} 
                                    style={{
                                        backgroundColor: this.isDarkMode() ? "#181F3A" : "white",
                                        color: this.isDarkMode() ? "white" : "#3B4370"
                                    }}
                                    value={this.props.filter.reference}
                                    onChange={(newReference) => {
                                        this.props.updateFilterReference(newReference);
                                    }} 
                            />
                        </div>
                    </div>
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <div style={{fontSize: 16}}>{getText( "ReorderDaysPast")}: </div>
                        <div className="card" style={{ ...Styles.cardStyle }} >
                            <input type="text"
                                style={{ width: "100%", height: 40, border: 'none', borderRadius: 4, textAlign: 'center' }}
                                value={this.props.filter.nbDays}
                                onChange={(e) => {
                                    let value = parseInt(e.target.value) || 0;
                                    this.props.updateFilterNbDays(value <= 90 ? value : 90) //Max days due to VMM column
                                }} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <div style={{fontSize: 16}}>{getText( "ReorderDaysStock")}: </div>
                        <div className="card" style={{ ...Styles.cardStyle }} >
                            <input type="text"
                                style={{ width: "100%", height: 40, border: 'none', borderRadius: 4, textAlign: 'center' }}
                                value={this.props.filter.watendNbDays}
                                onChange={(e) => this.props.updateWantedNbDays(parseInt(e.target.value) || 0)} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <div style={{fontSize: 16}}>{getText( "ReorderSecurityCoeff")}: </div>
                        <div className="card" style={{ ...Styles.cardStyle }} >
                            <input type="number"
                                style={{ width: '100%', height: 40, border: 'none', borderRadius: 4, textAlign: 'center' }}
                                min="0"
                                max="100"
                                step="1"
                                value={this.props.filter.coef}
                                onChange={this.onCoefChange} 
                                />
                            </div>
                    </div>                    
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                                <CheckBox value={this.props.filter.propositionOnly}
                                    label={getText("ReorderOnlyProposals")}
                                    labelStyle={{ maxWidth: 'fit-content' }}
                                    onChange={(v) => this.props.updatePropositionOnly(v)} />
                        </div>
                    </div>
                    <div style={{ margin: "0px 10px", flex: "0 0 auto" }}>
                        <button className="btn btn-primary"
                            style={{height: 40, fontSize: 15}}
                            disabled={this.props.disabled}
                            onClick={(e) => {
                                this.reorderDatas.forEach(async element => {
                                    await this.handleWantedDays(element.storeItemId, this.props.filter.watendNbDays);
                                })
                            }}>
                            {getText( "ReorderApply")}
                        </button>
                    </div>
                    <div>
                        <button type={"button"}
                            className="btn btn-primary"
                            style={{ height: 40 }}
                            onClick={(e) => {
                                const options = {
                                    fieldSeparator: ';',
                                    quoteStrings: '"',
                                    decimalSeparator: ',',
                                    showLabels: false,
                                    showTitle: false,
                                    filename: `${getText("ReorderTabReorder")}-${Moment().format('YYYYMMDDHHmm')}`,
                                    useTextFile: false,
                                    useBom: true,
                                    useKeysAsHeaders: false,
                                    headers: this.csvData().headers,
                                };
                                const csvExporter = new ExportToCsv(options);
                                csvExporter.generateCsv(this.csvData().data);
                                this.pdfData(this.csvData().data);  
                            }}>
                            {getText( "HistoExport")}
                            <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                        </button>
                    </div>
                </div>
                <div id={"reappro-container"} className="card" style={{...Styles.cardStyle, margin: "1% 1% 0"}}>
                    <Table tableKey={"reappro"}
                        className="tableHeightMedium reappro-table"
                        style={{
                            pointerEvents: this.props.disabled ? "none" : "initial",
                            opacity: this.props.disabled ? 0.7 : 1,
                            textAlign: "center"
                        }}
                        data={this.reorderDatas}
                        pageSize={Math.max(this.reorderDatas.length, 15)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        loading={this.props.isLoading}
                        columns={[
                            {
                                id: "name",
                                style: { 
                                    height: '100%',
                                    width: '100%',
                                    textAlign: 'left'
                                },
                                Header: <div style={{width: '100%', ...headerPaddingStyle }}>{getText( "ReorderWord")}</div>,
                                accessor: d => this.props.seed.products[d.storeItemId].name,
                                minWidth: 200,
                                filterable: true,
                                Filter: ({filter, onChange}) => (
                                    <span>
                                        { !this.isMobileDevice ?
                                        <input
                                        onChange={event => { 
                                            onChange(event.target.value)
                                            }}
                                        value={filter ? filter.value : ''}
                                        placeholder= {getText( "StockSearch")}
                                        style={{width: '90%', fontWeight: 900}}
                                        />
                                        :<>
                                            <input
                                            onChange={event =>
                                                this.setState({
                                                    ...this.state,         
                                                    filterInputTab: event.target.value
                                                })}
                                            value={this.state.filterInputTab}
                                            placeholder= {getText( "StockSearch")}
                                            style={{width: '70%', fontWeight: 900}}
                                            />
                                            <button type={"button"}
                                                className="btn btn-primary"
                                                style={{width: '30%', padding: 5}}
                                                onClick={() => onChange(this.state.filterInputTab)}>
                                                    {getText( "HistoFilter")}
                                            </button>
                                        </>
                                        }
                                    </span>
                                ),
                                Footer: 
                                    <button className="btn btn-primary"
                                        style={{margin: 0, fontWeight: 'bold', fontSize: 12, padding: '2px 10px', width: '100%', height: 40}}
                                        disabled={this.props.disabled}
                                        onClick={(e) => this.props.openSupplierOrderDialog()}>
                                        {getText( "ReorderOrderCreate")}
                                    </button>
                            },
                            {
                                id: "source",
                                style: { height: '100%' },
                                accessor: (d) => ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).supplierMainId,
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderSource")}</div>,
                                width: 80,
                                Cell: row => {
                                    let supplierMainId = row.value;
                                    let supplierName = this.props.suppliers[supplierMainId].name;
                                    return (
                                        <div>{supplierName}</div>
                                    )
                                }
                            },
                            {
                                id: "reference",
                                style: { height: '100%'},
                                Header: <div style={{ ...headerPaddingStyle, ...headerSpaceStyle }}>{getText( "ReorderProductCode")}</div>,
                                width: 80,
                                accessor: d => ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).reference,
                                Footer: <div><strong>{getText( "ReorderModalTotal")}</strong></div>
                            },
                            {
                                id: "weeklyAvg",
                                style: { height: '100%' },
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderVMH")}</div>,
                                width: 50,
                                accessor: d => (this.getTotalSold(d,21)/3).toFixed(2), //last 3 weeks
                                Footer: <div ><strong>{_.sum(_.map(this.reorderDatas, d => (this.getTotalSold(d,21)/3))).toFixed(1) || 0}</strong></div>
                            },
                            {
                                id: "monthlyAvg",
                                style: { height: '100%' },
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderVMM")}</div>,
                                width: 50,
                                accessor: d => (this.getTotalSold(d,90)/3).toFixed(2), //last 3 months                              
                                Footer: <div ><strong>{_.sum(_.map(this.reorderDatas, d => (this.getTotalSold(d,90)/3))).toFixed(1) || 0}</strong></div>
                            },
                            {
                                id: "totalSold",//Ventes
                                style: { height: '100%' },
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderSales")}</div>,
                                width: 70,
                                accessor: d => this.getTotalSold(d,this.props.filter.nbDays),
                                Footer: <div ><strong>{_.sum(_.map(this.reorderDatas, d => this.getTotalSold(d,this.props.filter.nbDays))) || 0}</strong></div>
                            },
                            {
                                id: "totalLost",//Dém
                                style: { height: '100%' },
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderRemoved")}</div>,
                                width: 50,
                                accessor: d => _.sum(d.dayProductData
                                    .filter((x, xi) => xi >= (d.dayProductData.length - this.props.filter.nbDays))
                                    .map(x => x.lostCount)),
                                Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => _.sum(d.dayProductData
                                    .filter((x, xi) => xi >= (d.dayProductData.length - this.props.filter.nbDays))
                                    .map(x => x.lostCount)))) || 0}</strong></div>
                            },
                            {
                                id: "stockRob",//Stk Rob
                                style: { height: '100%' },
                                Header: <div style={{ ...headerPaddingStyle, ...headerSpaceStyle }}>{getText( "ReorderstockRob")}</div>,
                                width: 50,
                                accessor: d => this.stockRobot(d.storeItemId),                              
                                Footer: (
                                    <div>
                                        <strong>
                                            {Number(_.sum(_.map(this.reorderDatas, x => this.stockRobot(x.storeItemId)  )))}
                                        </strong>
                                    </div>
                                )
                            },
                            {
                                id: "stkArr",//stockArr
                                style: { height: '100%' },
                                accessor: (d) => this.stockArr(d.storeItemId),
                                Header: <div style={{ ...headerPaddingStyle, ...headerSpaceStyle}}>{getText( "ReorderstkArr")}</div>,
                                width: 50,
                                Footer: <div> <strong>{Number(_.sum(_.map(this.reorderDatas, x => this.stockArr(x.storeItemId))))}</strong> </div>
                            },
                            {
                                id: "alreadyOrdered",//Qté en cmd
                                style: { height: '100%' },
                                Header: <div style={headerSpaceStyle}>{getText( "ReorderAlreadyOrdered")}</div>,
                                width: 50,
                                accessor: d => _.sum(this.props.supplierOrders.filter(x => x.status != "Received").map(x => x.supplierOrderProductSuppliers)
                                    .reduce((a, b) => a.concat(b), [])
                                    .filter(x => x.productId === d.storeItemId).map(x => x.packQuantity)),
                                Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => _.sum(this.props.supplierOrders.filter(x => x.status != "Received").map(x => x.supplierOrderProductSuppliers)
                                    .reduce((a, b) => a.concat(b), [])
                                    .filter(x => x.productId === d.storeItemId).map(x => x.packQuantity)))) || 0}</strong></div>
                            },
                            {
                                id: "currentStock",//Stk tot prob (Qté)
                                style: { height: '100%' },
                                Header: <div style={headerSpaceStyle}>{getText( "ReorderQtyStkProb")}</div>,
                                width: 50,
                                accessor: d => this.getProbableTotalStock(d,ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition),
                                Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => this.getProbableTotalStock(d,ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition))) || 0}</strong></div>
                            },
                            {
                                id: "stockProjected",//Stk tot prob (J)
                                style: { height: '100%' },
                                Header: <div style={headerSpaceStyle}>{getText( "ReorderDaysStkProb")}</div>,
                                width: 50,
                                accessor: d => isFinite((this.getProbableTotalStock(d,ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition) / this.getTotalSold(d,this.props.filter.nbDays))) ? Math.ceil(((this.getProbableTotalStock(d,ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition) / this.getTotalSold(d,this.props.filter.nbDays)) * this.props.filter.nbDays)) : 0 ,
                                Footer: <div><strong>{_.sum(_.map(this.reorderDatas, 
                                    d =>
                                        isFinite((this.getProbableTotalStock(d,ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition) / this.getTotalSold(d,this.props.filter.nbDays))) ? Math.ceil(((this.getProbableTotalStock(d,ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition)/ this.getTotalSold(d,this.props.filter.nbDays)) * this.props.filter.nbDays)) : 0))
                                    || 0}</strong></div>
                            },
                            {
                                id: "wantedDays",//Stock voulu (J)
                                style: { height: '100%' },
                                Header: <div style={headerSpaceStyle}>{getText( "ReorderVolDays")}</div>,
                                width: 80,
                                accessor: d => d,
                                Cell: row => {
                                return <div>
                                    <input
                                        className="form-control"
                                        style={{ width: 70, textAlign: 'center', paddingRight: 1 }}
                                        type={"number"}
                                        min="0"
                                        value={this.productDataCompleted(row.value.storeItemId).daysToProject}//this.getDaysToProject(row.value).toString()}
                                        onChange={(e) => this.handleWantedDays(row.value.storeItemId ,parseInt(e.target.value))}
                                        onKeyUp={(e) => {
                                            if (e.keyCode === 13) {
                                                let value = parseInt(e.currentTarget.value);
                                                this.handleWantedDays(row.value.storeItemId,value);
                                            }
                                        }}
                                    />
                                </div>},
                                //Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => this.getDaysToProject(d.storeItemId)))}</strong></div>
                            },
                            {
                                id: "stockWanted",//Stk voulu (Qté)
                                style: { height: '100%' },
                                Header: <div style={headerSpaceStyle}>{getText( "ReorderVolQty")}</div>,
                                width: 50,
                                accessor: d => ReorderStore.getWantedStock(d, this.props.filter, this.props.filter.nbDays, this.props.productsData, this.props.seed.products),
                                Footer: <div ><strong>{_.sum(_.map(this.reorderDatas, d => ReorderStore.getWantedStock(d, this.props.filter, this.props.filter.nbDays, this.props.productsData, this.props.seed.products))) || 0}</strong></div>
                            },
                            {
                                id: "quantityToOrder",//Qté á cder
                                style: { height: '100%' },
                                Header: <div style={headerSpaceStyle}>{getText( "ReorderQtyToOrder")}</div>,
                                width: 50,
                                accessor: d => Math.max(0,
                                    ReorderStore.getWantedStock(d,
                                        this.props.filter,
                                        this.props.filter.nbDays,
                                        this.props.productsData,
                                        this.props.seed.products) - d.currentStock),
                                Footer: <div ><strong>{_.sum(_.map(this.reorderDatas, d => Math.max(0,
                                        ReorderStore.getWantedStock(d,
                                        this.props.filter,
                                        this.props.filter.nbDays,
                                        this.props.productsData,
                                        this.props.seed.products) - d.currentStock))) || 0}</strong></div>
                            },
                            {
                                id: "packCondition",//Cond.
                                style: { height: '100%' },
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderQty")}</div>,
                                width: 55,
                                accessor: d => ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition,
                                //Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).packCondition)) || 0}</strong></div>
                            },
                            {
                                id: "packToOrder",//Nb cdt
                                style: { height: '100%' },
                                Header: <div style={{ ...headerPaddingStyle, ...headerSpaceStyle }}>{getText( "ReorderNumberQty")}</div>,
                                width: 60,
                                accessor: (d) => d.storeItemId,
                                sortMethod: (a, b) => {
                                    let aVal = _.round(this.productDataCompleted(a).toOrder);
                                    let bVal = _.round(this.productDataCompleted(b).toOrder);
                                    if (aVal === bVal) {
                                        return aVal > bVal ? 1 : -1;
                                    }
                                    return aVal > bVal ? 1 : -1;
                                },
                                Cell: row => {
                                    return <div>
                                    <InputNumber value={_.round(this.productDataCompleted(row.value).toOrder)}
                                        style={{ width: 50, textAlign: 'center' }}
                                        onChange={(e) => this.handleToOrder(row.value, e,_.round(this.productDataCompleted(row.value).daysToProject))}
                                        onBlur={(e) => this.handleToOrder(row.value, e, _.round(this.productDataCompleted(row.value).daysToProject))}/>
                                </div> },
                                Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => _.round(this.productDataCompleted(d.storeItemId).toOrder)))}</strong></div>
                            },
                            {
                                id: "date",//DLC
                                style: { height: '100%' },
                                accessor: (d) => d.storeItemId,
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderDLC")}</div>,
                                width: 80,
                                Cell: row => {
                                    let productId = row.value
                                    let productRail = this.productRails().find(x => x.productId === productId)
                                    return (<div>
                                        {
                                            productRail && productRail.productStocks.length > 0 && Moment(productRail.productStocks[0].expirity).isValid() ?
                                                Moment(productRail.productStocks[0].expirity).format("DD/MM/YY")
                                                : ''
                                        }
                                    </div>);
                                }
                            },
                            {
                                id: "PU HT",//PU HT
                                style: { height: '100%' },
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderPUHT")}</div>,
                                width: 85,
                                accessor: d => d.storeItemId,
                                Cell: row => {
                                    let productId = row.value;
                                    let productSupplier = ReorderStore.getProductSupplier(productId, this.props.seed.products)
                                    let noVatUnitBuyPrice =  productSupplier.buyPriceNoVat / productSupplier.packCondition;
                                    return (<div>
                                        {noVatUnitBuyPrice.toFixed(2)} {this.props.currencies[productSupplier.buyPriceCurrencyId].symbol}
                                    </div>);
                                },
                                /*Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d => Number(this.priceCalculator.calculate(
                                    this.product(d.storeItemId) as any, //cause of model generation making stuff optional
                                    _.sortBy(this.product(d.storeItemId).prices,
                                        x => (0)
                                            + (x.priceNameId !== this.product(d.storeItemId).defaultPriceNameId ? 2 : 1))[0],
                                    ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products)).noVatUnitBuyPrice.toFixed(2)))).toFixed(2) || 0}</strong></div>*/
                            },
                            {
                                id: "PA HT",//PA HT
                                style: { height: '100%' },
                                width: 85,
                                Header: <div style={headerPaddingStyle}>{getText( "ReorderPAHT")}</div>,
                                accessor: d => d,
                                Cell: row => {
                                    let productSupplier = ReorderStore.getProductSupplier(row.value.storeItemId, this.props.seed.products);
                                    return (
                                        <div>
                                            {(productSupplier.buyPriceNoVat * _.round(this.productDataCompleted(row.value.storeItemId).toOrder)).toFixed(2)}
                                            {" "}
                                            {this.props.seed.currencies[productSupplier.buyPriceCurrencyId].symbol}
                                        </div>
                                    );
                                },
                                Footer: <div><strong>{_.sum(_.map(this.reorderDatas, d =>
                                     ReorderStore.getProductSupplier(d.storeItemId, this.props.seed.products).buyPriceNoVat
                                        * _.round(this.productDataCompleted(d.storeItemId).toOrder)
                                    )).toFixed(2) || 0}</strong></div>
                            }
                        ] as Array<ReorderColumn>} />
                </div>
                <DialogModal contentLabel={"supplierOrderProductSuppliers"}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204)", 
                        width:'50%', 
                        padding: '0 10px'
                    }}
                    title={getText( "ReorderOrderCreate")}
                    isOpen={this.props.isSupplierOrderOpen}
                    onRequestClose={() => this.props.closeSupplierOrderDialog()}>
                        <CreateSupplierOrderForm
                            onSubmit={(model: Api.SupplierOrderModel) => {
                                this.handleSubmit(model);
                            }}
                        />
                </DialogModal>
            </div>
        );
    };
}

export default connect((state: ApplicationState) => ({
    ...state.reorder,
    seed: state.seed.seed,
    reorderDatas: ReorderStore.reorderDataSelector(state),
    productsDataCompleted: ReorderStore.productsDataCompletedSelector(state),
    isSupplierOrderOpen: state.supplierOrder.isOpen,
    supplierOrders: state.supplierOrder.supplierOrders,
    disabled: !canUpdate(state),
    currentUser: state.account.currentUser,
    suppliers: state.seed.seed.suppliers,
    stock: state.stock,
    currencies: state.seed.seed.currencies,
    products: state.seed.seed.products,
    units: state.seed.seed.units,
    vats: state.seed.seed.vats,
} as ReapproOwnProps), {
    ...ReorderStore.actionCreators,
    ...StockStore.actionCreators,
    ...SupplierOrderStore.actionCreators
})(Reappro as any);