import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReactTable from 'react-table';
import * as PeriodStore from "../store/Period";
import * as PricePeriodStore from "../store/PricePeriod";
import * as StockStore from "../store/Stock";
import * as TabsStore from "../store/Tabs";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Table from "./Table";
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import { CreatePricePeriodForm, UpdatePricePeriodForm } from "./PricePeriodForm";
import * as ProductsDetailsStore from "../store/ProductsDetails";
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';
import Confirm from "react-confirm-bootstrap";
import { canUpdate } from '../security/roles';

type PricePeriodProps = PricePeriodStore.PricePeriodState
    & PricePeriodOwnProps
    & ProductsDetailsStore.ProductsDetailsState
    & typeof StockStore.actionCreators
    & typeof TabsStore.actionCreators
    & typeof PricePeriodStore.actionCreators
    & typeof PeriodStore.actionCreators
    & typeof ProductsDetailsStore.actionCreators;

interface PricePeriodOwnProps {
    periods: { [id: number]: Api.PeriodModel };
    priceNames: { [id: number]: Api.PriceNameModel };
    stockStatus: Api.StockStatusModel;
    currentUser: Api.CurrentUserManagerModel; 
    familyId: number;
    subFamilyId: number;
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
    disabled: boolean;
}

type PricePeriodColumn = ReactTable.Column
    & { accessor: (d: Api.PricePeriodModel) => any; }

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class PricePeriod extends React.Component<PricePeriodProps, {}> {

    componentDidMount() {
        (this.props.requestPeriods(new Date().getTime()) as any as Promise<any>)
            .then(() => this.props.requestPricePeriods(new Date().getTime()))
    }

    handleCreateSubmit(values) {
        this.props.requestCreatePricePeriod(new Date().getTime(), values);
        this.props.updateFamilyId(undefined);
        this.props.updateSubFamilyId(undefined);
        this.props.updateFamiliesIds("create",undefined);
        this.props.updateSubFamiliesIds("create", undefined);
    }

    handleUpdateSubmit(values) {
        this.props.requestUpdatePricePeriod(new Date().getTime(), values);
        this.props.updateFamilyId(undefined);
        this.props.updateSubFamilyId(undefined);
        this.props.updateFamiliesIds("update",undefined);
        this.props.updateSubFamiliesIds("update", undefined);
    }

    public render() {
        return (
            <div style={{height: "100%"}}>
                <div className="card" style={{...Styles.cardStyle, margin: "0 1%", height: "fit-content"}}>
                    <Table key={"pricePeriods"}
                        className="tableHeightSmall"
                        pageSize={Math.max(_.values(this.props.pricePeriods).length, 10)}
                        showPagination={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        style={{textAlign: "center" }}
                        data={_.values(this.props.pricePeriods)}
                        columns={[
                            {
                                id: "name",
                                Header: getText( "PricePeriodName"),
                                accessor: (d) => d.name,
                                Cell: row => { return <div style={{padding: 5}}> {row.value} </div>},
                                maxWidth: 300
                            },
                            {
                                id: "periodId",
                                Header: getText( "PricePeriodPeriod"),
                                accessor: (d) => this.props.periods[d.periodId].name,
                                Cell: row => { return <div style={{padding: 5}}> {row.value} </div>},
                                maxWidth: 300
                            },
                            {
                                id: "priceNameId",
                                Header: getText( "PricePeriodDefaultRate"),
                                accessor: (d) => this.props.priceNames[d.priceNameId].name,
                                Cell: row => { return <div style={{padding: 5}}> {row.value} </div>},
                                maxWidth: 300
                            },
                            {
                                id: "pricePeriodStoreItems",
                                Header: getText( "PricePeriodProduct"),
                                accessor: (d) => d.pricePeriodStoreItems.length,
                                Cell: row => { return <div style={{padding: 5}}> {row.value} </div>},
                                maxWidth: 300
                            },
                            {
                                id: "actions",
                                Header: getText( "PricePeriodActions"),
                                accessor: (d) => d.pricePeriodId,
                                Cell: row => {
                                    return <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                        <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        placeSelf: "center", 
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5
                                        }}>
                                            <i className="glyphicon glyphicon-pencil icon-dm"
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                                                    opacity: this.props.disabled ? 0.7 : 1,
                                                    margin: "11% 0", 
                                                    cursor: "pointer",
                                                    color: "#FFFFFF" }}
                                                onClick={(e) => {
                                                    this.props.selectPricePeriod(row.value);
                                                    this.props.selectTab("pricePeriods", "update");
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </div>
                                        <div className="icon-container-dm" style={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: "#007AFF",
                                        borderRadius: 5
                                        }}>                                            
                                            <Confirm
                                                style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                                    opacity: this.props.disabled ? 0.7 : 1, color: "#FFFFFF", 
                                                    marginTop: '15%',
                                                    marginRight: 1,
                                                    cursor: "pointer" }}
                                                onConfirm={() => {
                                                    this.props.requestDeletePricePeriod(new Date().getTime(), row.value);
                                                }}
                                                body={getText( "MaintenanceBodyConfirmDelete")}
                                                confirmText={getText( "MaintenanceConfirm")} title={getText( "MaintenanceConfirmDeleteTitle")}>
                                                    <i className="glyphicon glyphicon-trash icon-dm"/>
                                            </Confirm>
                                        </div>
                                    </div>
                                }
                            }
                        ] as Array<PricePeriodColumn>}
                        />
                </div>
                <div>
                    <ConnectedTabs name={"pricePeriods"}>
                    <div className="card" style={{...Styles.cardStyle, margin: "1% 1% 5px" }}>
                        <TabLink style={{width: '50%', padding: '0.4rem 0'}} to="create">
                            <div style={tabHeadStyle}>{getText( "PricePeriodCreate")}</div>
                        </TabLink>
                        <TabLink style={{width: '50%', padding: '0.4rem 0'}} to="update">
                            <div style={tabHeadStyle}>{getText( "PricePeriodEdit")}</div>
                        </TabLink>
                    </div>
                    <div className="card" style={{...Styles.cardStyle, margin: "0 1%", height: "40vh"}}>
                        <TabContent for="create">
                            <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                    opacity: this.props.disabled ? 0.7 : 1}}>
                                {this.props.stockStatus
                                    && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                        <CreatePricePeriodForm actionName={getText( "PricePeriodCreate")}
                                            isPricePeriodStoreItemDialogOpen={this.props.pricePeriodStoreItemsState["create"]
                                                && this.props.pricePeriodStoreItemsState["create"].isPricePeriodStoreItemDialogOpen}
                                            familiesGrouped={this.props.pricePeriodStoreItemsState["create"]
                                                && this.props.pricePeriodStoreItemsState["create"].familiesGrouped}
                                            subFamiliesGrouped={this.props.pricePeriodStoreItemsState["create"]
                                                && this.props.pricePeriodStoreItemsState["create"].subFamiliesGrouped}
                                            closePricePeriodStoreItemDialog={() => this.props.closePricePeriodStoreItemDialog("create")}
                                            openPricePeriodStoreItemDialog={() => this.props.openPricePeriodStoreItemDialog("create")}
                                            openPeriodDialog={() => this.props.openPeriodDialog()}
                                            onSubmit={(values) => this.handleCreateSubmit(values)}
                                            updateFamilyId={(value) => this.props.updateFamilyId(value)}
                                            updateSubFamilyId={(value) => this.props.updateSubFamilyId(value)}
                                            familyId={this.props.familyId}
                                            subFamilyId={this.props.subFamilyId}
                                            updateFamiliesIds={(key, value) => this.props.updateFamiliesIds(key,value)}
                                            updateSubFamiliesIds={(key, value) => this.props.updateSubFamiliesIds(key, value)}
                                            keyAction={"create"}
                                        />  
                                    </div>}
                            </div>
                        </TabContent>
                        <TabContent for="update">
                        <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                                opacity: this.props.disabled ? 0.7 : 1, paddingTop: 10 }}>
                            {this.props.selectedPricePeriodId
                                    && this.props.stockStatus
                                && <div className="card" style={{...Styles.cardStyle, padding: '1%'}}>
                                    <UpdatePricePeriodForm actionName={getText( "PricePeriodSave")}
                                        isPricePeriodStoreItemDialogOpen={this.props.pricePeriodStoreItemsState["update"]
                                            && this.props.pricePeriodStoreItemsState["update"].isPricePeriodStoreItemDialogOpen}
                                        familiesGrouped={this.props.pricePeriodStoreItemsState["update"]
                                            && this.props.pricePeriodStoreItemsState["update"].familiesGrouped}
                                        subFamiliesGrouped={this.props.pricePeriodStoreItemsState["update"]
                                            && this.props.pricePeriodStoreItemsState["update"].subFamiliesGrouped}
                                        closePricePeriodStoreItemDialog={() => this.props.closePricePeriodStoreItemDialog("update")}
                                        openPricePeriodStoreItemDialog={() => this.props.openPricePeriodStoreItemDialog("update")}
                                        openPeriodDialog={() => this.props.openPeriodDialog()}
                                        onSubmit={(values) => this.handleUpdateSubmit(values)}
                                        updateFamilyId={(value) => this.props.updateFamilyId(value)}
                                        updateSubFamilyId={(value) => this.props.updateSubFamilyId(value)}
                                        familyId={this.props.familyId}
                                        subFamilyId={this.props.subFamilyId}
                                        updateFamiliesIds={(key, value) => this.props.updateFamiliesIds(key, value)}
                                        updateSubFamiliesIds={(key, value) => this.props.updateSubFamiliesIds(key, value)}
                                        keyAction={"update"}
                                    />
                                </div> }
                        </div>
                    </TabContent>
                    </div>
                </ConnectedTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.pricePeriod,
    periods: state.period.periods,
    priceNames: state.seed.seed.priceNames,
    stockStatus: state.stock.stockStatus,
    currentUser: state.account.currentUser,
    familyId: state.productsDetails.familyId,
    subFamilyId: state.productsDetails.subFamilyId,
    families: state.seed.seed.families,
    subFamilies: state.seed.seed.subFamilies,    
    disabled: !canUpdate(state)
} as PricePeriodOwnProps), {
        ...TabsStore.actionCreators,
        ...StockStore.actionCreators,
        ...PricePeriodStore.actionCreators,
        ...PeriodStore.actionCreators,
        ...ProductsDetailsStore.actionCreators,
    })(PricePeriod as any);