import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as SettingsStore from '../store/Settings';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import StoreSettingsForm from './StoreSettingsForm';
import { canEditSettings, maint } from '../security/roles';
import Import from './Import';
import * as RobotStore from '../store/Robot';
import ImportCSVStock from './ImportCSVStock';
import StoreSettingsFormGeneral from './StoreSettingsFormGeneral';
import  * as HmiAppStore from '../store/HmiApplication';

type SettingsGeneralProps = SettingsGeneralOwnProps
    & SettingsStore.SettingsState
    & RobotStore.RobotState
    & typeof RobotStore.actionCreators
    & typeof SettingsStore.actionCreators
    & typeof HmiAppStore.actionCreators;

interface SettingsGeneralOwnProps {
    currentUser: Api.CurrentUserManagerModel;
    disabled: boolean;
    storeType: string;
}

const colStyle: React.CSSProperties = {padding: '1% 2%'}

class SettingsGeneral extends React.Component<SettingsGeneralProps, {}> {

    componentDidMount() {
        this.props.requestStoreSettings(new Date().getTime());
    }

    public render() {
        return (
            <div>
                <div style={{ pointerEvents: this.props.disabled ? "none" : "initial",
                    opacity: this.props.disabled ? 0.7 : 1 }}>
                    {this.props.robotsState.robots.map(x => <div key={x.robotId}>
                        <StoreSettingsFormGeneral robotId={x.robotId}
                                           onSubmit={(values) => {
                                               this.props.requestUpdateStoreSettings(new Date().getTime(), values);
                                               this.props.requestUpdateRobot(new Date().getTime(), values);
                                               this.props.requestUpdateStatusLanguage(new Date().getTime(), values.languages);
                                               this.props.updateHmisLang(new Date().getTime(), values.hmisApps);
                                           }} />
                    </div>)}
                </div>
                <div className="row" >
                    <div className="col-md-6" style={{fontSize: 14, paddingLeft: 0}}>
                        { this.props.storeType != "Ximiti" 
                        && <div className="card" style={{...Styles.cardStyle, ...colStyle, textAlign: "center", marginTop: 10, marginBottom: 10,
                        pointerEvents: this.props.disabled ? "none" : "initial", opacity: this.props.disabled ? 0.7 : 1 }}>
                            <ImportCSVStock
                            isDisabled = {this.props.disabled}
                            isLoading = {this.props.importStockState.isLoading}
                            uploadFile = {(file) => this.props.importData(new Date().getTime(), file )}/>
                        </div>}
                    </div>
                </div>
                {this.props.currentUser
                && this.props.currentUser.userManager.storeAccount.roles.some(x => x === maint)
                && <Import />}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.settings,
    ...state.robot,
    currentUser: state.account.currentUser,
    disabled: !canEditSettings(state),
    storeType: state.seed.seed.storeAppSettings.typeStore
}), { ...SettingsStore.actionCreators, ...RobotStore.actionCreators, ...HmiAppStore.actionCreators})(SettingsGeneral as any);