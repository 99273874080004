import * as Api from '../api/api';
import * as React from 'react';
import Moment from 'moment';
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import {IupCollection, iupCollectionFromPayment} from "../store/History";
import CardCollectionDialog from './CardCollectionDialog';

interface HistoryCardCollectionProps {
    isLoading: boolean;
    seed: Api.SeedMngModel;
    selectedIndex: number;
    dayStoreDatas: Array<Api.DayStoreDataModel>;
    updateSelectedIndex: (value: number) => void;
    openViewCardCollection: (collection: IupCollection, payment: Api.StoreOrderPaymentModel) => void;
}

type NeoOrderColumn = ReactTable.Column
    & {
        accessor: (d: { payment: Api.StoreOrderPaymentModel, collection: IupCollection }) => any;
        style?: React.CSSProperties;
};
const rowStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', paddingBottom: '1%'};

export default class HistoryCardCollection extends React.Component<HistoryCardCollectionProps, {}> {

    get selectedDayStoreData(): Api.DayStoreDataModel {
        return this.props.dayStoreDatas[this.props.selectedIndex];
    }

    get cardPayments(): Array<{ payment: Api.StoreOrderPaymentModel, collection: IupCollection }> {
        return this.selectedDayStoreData.storeOrders
            .filter(x => x.status === "Delivered"
                && x.storeOrderPayments.length)
            .map(x => x.storeOrderPayments)
            .reduce((a, b) => a.concat(b), [])
            .filter(x => x.status === "Success"
                && x.collection
                && x.paymentType === "Card"
                && x.collectiondDate)
            .map(x => ({
                payment: x,
                collection: iupCollectionFromPayment(x)
            }));
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{...rowStyle}}>
                <div className="heightHisto" style={{marginTop: 2, paddingLeft: '1%', overflow: 'auto', width: '20%'}}>
                    {this.props.dayStoreDatas.map((dayStoreData, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: xi === this.props.selectedIndex
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.updateSelectedIndex(xi)}>
                        {Moment(dayStoreData.dayDate).locale(getCurrentLang()).format("dddd DD / MM / YYYY")}
                    </div>)}
                </div>
                <div style={{paddingLeft: '1%', width: '80%'}}>
                    <Table className="heightHisto"
                        style={{margin: 0}}
                        key={"historyCardCollection"}
                        loading={this.props.isLoading}
                        pageSize={this.selectedDayStoreData && Math.max(this.cardPayments.length, 20)}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        columns={[
                            {
                                id: "actions",
                                maxWidth: 80,
                                accessor: (d) => d,
                                filterable: false,
                                Header: <div>{getText( "HistoCardCollectionAction")}</div>,
                                style: {
                                    height: "100%"
                                },
                                Cell: row => <div 
                                className="icon-container-dm"
                                style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center',
                                    width: 20,
                                    height: 20,
                                    placeSelf: "center", 
                                    backgroundColor: "#007AFF",
                                    borderRadius: 5, }}>
                                    <i className="glyphicon glyphicon-search icon-dm"
                                        style={{ margin: "11% 0",  cursor: "pointer", color: "#FFFFFF" }}
                                        onClick={(e) => {
                                            let collection = row.value.collection as IupCollection;
                                            let payment = row.value.payment as Api.StoreOrderPaymentModel;
                                            this.props.openViewCardCollection(collection, payment);
                                        }} />
                                </div>
                            },
                            {
                                Header: <div>{getText( "HistoTransactionNumber")}</div>,
                                id: 'transactionNumber',
                                accessor: x => x.collection.transactionNumber,
                                Cell: row => <div>{row.value}</div>,
                            },                                    
                            {
                                Header: <div>{getText( "HistoWord")} </div>,
                                id: 'collectionLabel',
                                accessor: x => x.payment.collectionLabel,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoDiscountNumber")}</div>,
                                id: 'remissionNumber',
                                accessor: x => x.collection.remissionNumber,
                                Cell: row => <div>{row.value}</div>,
                            },
                            // {
                            //     Header: <div>{getText( "HistoAmount")}</div>,
                            //     id: 'transactionAmount',
                            //     accessor: x => x.collection.transactionAmount,
                            //     Cell: row => <div>{row.value}</div>,
                            // },
                            {
                                Header: <div>{getText( "HistoSolvency")} </div>,
                                id: 'amount',
                                accessor: x => x.collection.estimatedAmount,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionDate")} </div>,
                                id: 'solvencyValidationDate',
                                accessor: x => x.collection.dateTime,
                                Cell: row => <div>{row.value ? Moment(row.value, "DDMMYY-hhmmss").format("DD/MM/YYYY HH:mm:ss") : ""}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionValidity")} </div>,
                                id: 'validity',
                                accessor: x => x.collection.cardValidity,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionAccountNum")} </div>,
                                id: 'accountNumber',
                                accessor: x => x.collection.acceptorNumber,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionPaymentType")} </div>,
                                id: 'paymentType',
                                accessor: x => x.collection.paymentType,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionCardType")} </div>,
                                id: 'cardType',
                                accessor: x => x.collection.label,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionContractNum")} </div>,
                                id: 'contractNumber',
                                accessor: x => x.collection.contractNumber,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionCryptogram")} </div>,
                                id: 'cryptogram',
                                accessor: x => x.collection.crypto,
                                Cell: row => <div>{row.value}</div>,
                            },  
                            {
                                Header: <div>{getText( "HistoCardCollectionEnterprise")} </div>,
                                id: 'enterprise',
                                accessor: x => x.collection.acceptorTitle,
                                Cell: row => <div>{row.value}</div>,
                            },   
                            {
                                Header: <div>{getText( "HistoCardCollectionCollection")} </div>,
                                id: 'collection',
                                accessor: x => x.collection.transactionAmount,
                                Cell: row => <div>{row.value}</div>,
                            },
                            {
                                Header: <div>{getText( "HistoCardCollectionStatus")} </div>,
                                id: 'status',
                                accessor: x => x.payment.status,
                                Cell: row => <div>{row.value}</div>,
                            },
                            // {
                            //     Header: <div> Date d'encaissement</div>,
                            //     id: 'collectiondDate',
                            //     accessor: x => x.payment.collectiondDate,
                            //     Cell: row => <div>{Moment(row.value).add(-new Date().getTimezoneOffset(), "minutes").format("DD/MM/YYYY HH:mm:ss")}</div>,
                            // },
                            // {
                            //     Header: <div>Module</div>,
                            //     id: 'module',
                            //     accessor: x => x.collectionModule,
                            //     Cell: row => <div>{row.value}</div>,
                            // },
                            // {
                            //     Header: <div>Commande </div>,
                            //     id: 'commande ',
                            //     accessor: x => x.storeOrderId,
                            //     Cell: row => <div>{row.value}</div>,
                            // },
                        ] as Array<NeoOrderColumn>}
                        data={
                        this.selectedDayStoreData && this.cardPayments
                        ? this.cardPayments
                        : []}
                    />
                </div>
                <CardCollectionDialog />
            </div>
        );
    }
}