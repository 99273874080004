import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import Moment from 'moment';
import * as Api from "../api/api";
import * as Notification from "react-notification-system-redux";
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { getText } from '../utils/texts';

export interface NeoEventState {
    isLoading: boolean;
    requestTime?: number;
    neoEvents: Array<Api.NeoEventModel>;
    alarmEventsState: {
        isLoading: boolean;
        requestTime?: number;
        neoEvents: Array<Api.NeoEventModel>;
        acquitStates: { [id: number]: { isLoading: boolean; requestTime?: number } }
    };
    isCreateEvent: {
        isLoading: boolean;
        requestTime?: number;
    }
}

//Actions
interface RequestNeoEvents {
    type: "REQUEST_NEO_EVENTS";
    payload: { requestTime: number; }
}
interface ReceiveNeoEvents {
    type: "RECEIVE_NEO_EVENTS";
    payload: { requestTime: number; neoEvents: Array<Api.NeoEventModel> };
    error?: any
}

interface RequestCreateNeoEvent {
    type: "REQUEST_CREATE_NEO_EVENT";
    payload: { requestTime: number; }
}
interface ReceiveCreateNeoEvent {
    type: "RECEIVE_CREATE_NEO_EVENT";
    payload: { requestTime: number;};
    error?: any
}

interface RequestAlarmNeoEvents {
    type: "REQUEST_ALARM_NEO_EVENTS";
    payload: { requestTime: number; }
}
interface ReceiveAlarmNeoEvents {
    type: "RECEIVE_ALARM_NEO_EVENTS";
    payload: { requestTime: number; neoEvents: Array<Api.NeoEventModel> };
    error?: any
}

interface RequestAcquitNeoEvent {
    type: "REQUEST_ACQUIT_NEOEVENT";
    payload: { requestTime: number; id: number }
}
interface ReceiveAcquitNeoEvent {
    type: "RECEIVE_ACQUIT_NEOEVENT";
    payload: { requestTime: number; id: number };
    error?: any
}

export interface NeoEventAdded {
    type: "NEO_EVENT_ADDED";
    payload: { value: Api.NeoEventModel }
}

type KnownAction = RequestNeoEvents
    | ReceiveNeoEvents
    | NeoEventAdded
    | RequestAlarmNeoEvents
    | ReceiveAlarmNeoEvents
    | RequestAcquitNeoEvent
    | ReceiveAcquitNeoEvent
    | RequestCreateNeoEvent
    | ReceiveCreateNeoEvent
    ;

export const requestAlarmEvents = (requestTime: number, dispatch: (action: KnownAction) => any, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.NeoEventApi();
    let fetch = api.getAlarmEvents({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(neoEvents => {
            dispatch({
                type: "RECEIVE_ALARM_NEO_EVENTS",
                payload: { requestTime: requestTime, neoEvents: neoEvents }
            });
        })
        .catch(error => {
            dispatch({
                type: "RECEIVE_ALARM_NEO_EVENTS",
                payload: { requestTime: requestTime, neoEvents: [] },
                error: error
            });
        });

    dispatch({ type: "REQUEST_ALARM_NEO_EVENTS", payload: { requestTime: requestTime } });
    addTask(fetch);
    return fetch;
}

// export const requestCreateEvent = (requestTime: number, eventCode: number, message: string, dispatch: (action: KnownAction) => any, getState: () => ApplicationState): Promise<any> => {
//     let api = new Api.NeoEventApi();
//         let fetch = api.create({
//             model: {
//                code: eventCode,
//                message: message
//             } as Api.CreateNeoEventModel
//         }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
//             .then(neoEvent => {
//                 dispatch({
//                     type: "RECEIVE_CREATE_NEO_EVENT",
//                     payload: { requestTime: requestTime }
//                 });
//             })
//             .catch(error => {
//                 dispatch({
//                     type: "RECEIVE_CREATE_NEO_EVENT",
//                     payload: { requestTime: requestTime },
//                     error: error
//                 });
//             });

//         dispatch({ type: "REQUEST_CREATE_NEO_EVENT", payload: { requestTime: requestTime } });
//         addTask(fetch);
//         return fetch;
// }

export const actionCreators = {
    requestNeoEvents: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventApi();
        let fetch = api.searchEvents({
            model: {
                fromDate: Moment().utc().startOf("day").toDate(),
                toDate: Moment().utc().endOf("day").toDate()
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(neoEvents => {
                dispatch({
                    type: "RECEIVE_NEO_EVENTS",
                    payload: { requestTime: requestTime, neoEvents: neoEvents }
                });
            })
            .catch(error => {
                dispatch({
                    type: "RECEIVE_NEO_EVENTS",
                    payload: { requestTime: requestTime, neoEvents: [] },
                    error: error
                });
            });

        dispatch({ type: "REQUEST_NEO_EVENTS", payload: { requestTime: requestTime } });
        addTask(fetch);
        return fetch;
    },
    requestAlarmEvents: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestAlarmEvents(requestTime, dispatch, getState);
    },
    requestAcquitNeoEvent: (requestTime: number, id: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.NeoEventApi();
        let fetch = api.acquitAlarmEvent({ neoEventId: id }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({
                    type: "RECEIVE_ACQUIT_NEOEVENT",
                    payload: { requestTime: requestTime, id: id }
                });
            })
            .catch(error => {
                dispatch({
                    type: "RECEIVE_ACQUIT_NEOEVENT",
                    payload: { requestTime: requestTime, id: id  },
                    error: error
                });

                if (error.text) {
                    error.text().then(x => {
                        dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
                    });
                }
            });

        dispatch({ type: "REQUEST_ACQUIT_NEOEVENT", payload: { requestTime: requestTime, id: id } });
        return fetch;
    },
};

const unloadedState: NeoEventState = {
    isLoading: false,
    neoEvents: [],
    alarmEventsState: {
        isLoading: true,
        neoEvents: [],
        acquitStates: {}
    },
    isCreateEvent: {
        isLoading: false
    }
};

export const reducer: Reducer<NeoEventState> = (state: NeoEventState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_NEO_EVENTS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_NEO_EVENTS":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                neoEvents: action.error
                    ? state.neoEvents
                    : action.payload.neoEvents
            };
        case "NEO_EVENT_ADDED":
            return {
                ...state,
                neoEvents: state.neoEvents.concat([ action.payload.value ]),
                alarmEventsState: {
                    ...state.alarmEventsState,
                    /*neoEvents: state.alarmEventsState.neoEvents
                        .filter(x => x.productRailId 
                            || x.neoEventCodeId !== action.payload.value.neoEventCodeId)
                        .concat([action.payload.value])*/
                }
            };
        case "REQUEST_ALARM_NEO_EVENTS":
            return {
                ...state,
                alarmEventsState: {
                    ...state.alarmEventsState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_ALARM_NEO_EVENTS":
            if (action.payload.requestTime !== state.alarmEventsState.requestTime)
                return state;

            return {
                ...state,
                alarmEventsState: {
                    ...state.alarmEventsState,
                    isLoading: false,
                    neoEvents: action.error
                        ? action.payload.neoEvents
                        : action.payload.neoEvents
                }
            };
        case "REQUEST_ACQUIT_NEOEVENT":
            return {
                ...state,
                alarmEventsState: {
                    ...state.alarmEventsState,
                    acquitStates: {
                        ...state.alarmEventsState.acquitStates,
                        [action.payload.id]: {
                            ...state.alarmEventsState.acquitStates[action.payload.id],
                            isLoading: true,
                            requestTime: action.payload.requestTime
                        }
                    }
                }
            };
        case "RECEIVE_ACQUIT_NEOEVENT":
            if (!state.alarmEventsState.acquitStates[action.payload.id]
                || state.alarmEventsState.acquitStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                alarmEventsState: {
                    ...state.alarmEventsState,
                    neoEvents: action.error
                        ? state.alarmEventsState.neoEvents
                        : state.alarmEventsState.neoEvents.filter(x => x.neoEventId !== action.payload.id),
                    acquitStates: {
                        ...state.alarmEventsState.acquitStates,
                        [action.payload.id]: {
                            ...state.alarmEventsState.acquitStates[action.payload.id],
                            isLoading: true,
                            requestTime: action.payload.requestTime
                        }
                    }
                }
            };
         case "REQUEST_CREATE_NEO_EVENT":
            return {
                ...state,
                isCreateEvent: {
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
                
            };
        case "RECEIVE_CREATE_NEO_EVENT":
            return {
                ...state,
                isCreateEvent: {
                    isLoading: false,
                    requestTime: action.payload.requestTime
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};