import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as HistoryStore from "../store/History";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import CashRegisterOperations from "./CashRegisterOperations";
import CashHoppersControl from "./CashHoppersControl";
import HelpHeader from './HelpHeader';
import { getText } from '../utils/texts';
import { canUpdate } from '../security/roles';

type CashRegisterProps = CashRegisterOwnProps
    & HistoryStore.HistoryState
    & typeof HistoryStore.actionCreators;

interface CashRegisterOwnProps {
    hoppers: Array<Api.HmiMoneyHopperModel>;
    seed: Api.SeedMngModel;
    currentUser: Api.CurrentUserManagerModel; 
    disabled: boolean;
}

const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class CashRegister extends React.Component<CashRegisterProps, {}> {
    componentDidMount() {
    }

    RefreshTodayOperations() {
        let needRefresh = this.props.todayData.needRefresh || this.props.todayMoneyStock.needRefresh;
        if (this.props.todayData.needRefresh) {
            this.props.requestTodayDayStoreData(new Date().getTime());
        }
        if (this.props.todayMoneyStock.needRefresh) {
            this.props.requestTodayDayMoneyStocks(new Date().getTime());
        }
        return needRefresh
    }
    public render() {
        return (
            <div style={{ height: "100%" }}>
                <div>
                    <div className="card" style={{ ...Styles.cardStyle,  ...Styles.pageTitle}}>
                        <div style={{paddingRight: '2%'}}>
                            {getText( "CashRegisterTitle")}
                        </div>
                        <HelpHeader />
                    </div>
                    <div style={{ height: "100%"}}>
                        <CashRegisterOperations
                            hoppers={this.props.hoppers}
                            seed={this.props.seed}
                            dayMoneyStocks={this.props.todayMoneyStock.dayMoneyStocks}
                            dayStoreData={this.props.todayData.dayStoreData}
                            resetDisabled={this.props.resetStoredMoneyState.isLoading || this.RefreshTodayOperations()}
                            isToday={true}
                            onResetStoredMoneyClick={(e) => {
                                this.props.requestResetStoredMoney(new Date().getTime())
                            }}
                            />
                    </div>
                </div>
                <div className="card" style={{ ...Styles.cardStyle, padding: '10px 10px 8px', margin: '1% 1% 0', fontSize: 13, height: "35vh",}}>
                    <div style={{fontWeight: "bold", fontSize: 16, paddingRight: '2%', margin: 'auto'}}>
                        {getText( "CashRegisterHopperMng")}
                    </div>
                    <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                        opacity: this.props.disabled ? 0.7 : 1 }}>
                            <CashHoppersControl />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.history,
    seed: state.seed.seed,
    hoppers: state.cash.hoppers.hoppers,
    currentUser: state.account.currentUser,
    disabled: !canUpdate(state)
} as CashRegisterOwnProps), {
        ...HistoryStore.actionCreators,
    })(CashRegister  as any);