import * as React from 'react';
import * as ReactSelect from 'rc-select';
import { filterWithoutAccents } from '../utils/utils';

type SelectProps = {
    style?: any;
    className?: string;
    options: Array<Option>;
    multiple?: boolean;
    disableSearch?: boolean;
    disabled?: boolean;
    value: any | Array<any>;
    onChange: (value: any | Array<any>) => void;
    optionComponent?: (opt: Option) => any;
}

export interface Option {
    label: string;
    value: any;
}

const defaultStyle = {
    zIndex: 9999
}

export default class Select extends React.Component<SelectProps, {}> {
    private dropDownStyle = { 
        width: "100%", 
        backgroundColor: this.isDarkMode() ? "#181F3A" : "white"
    };

    public constructor(props) {
        super(props);
    }

    updateStyle(props: SelectProps) {
        let style = {
            ...defaultStyle,
            ...this.props.style
        };
        this.dropDownStyle = style;
    }

    componentDidMount() {
        this.updateStyle(this.props);
    }

    componentWillReceiveProps(nextProps: SelectProps) {
        if (this.props.style !== nextProps.style)
            this.updateStyle(nextProps);
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    get selectedOptions(): Array<Option> {
        let values = this.props.value as Array<number>;
        if (Array.isArray(values))
            return this.props.options.filter(opt => values.some(va => va === opt.value));

        return this.props.options.filter(opt => opt.value === this.props.value);
    }

    findOptions(labels: Array<string>): Array<Option> {
        return this.props.options.filter(opt => labels.some(lb => lb === opt.label));
    }

    handleChange(label: string | Array<string>) {
        let labels = label as Array<string>;

        if (Array.isArray(labels))
            this.props.onChange(this.findOptions(labels).map(opt => opt.value));
        else {
            this.props.onChange(this.findOptions([label as string])[0].value);
        }
    }
    public render() {
        
        let color = this.isDarkMode() ? "white;" : "#181F3A;";
        const css = `
        .rc-select-selection--single .rc-select-selection__rendered {
            color:` +  color + `
        }
        `

        return <div>
            <style>{css}</style>    
            <ReactSelect.default
                showSearch={this.props.disableSearch ? false : true}
                className={this.props.className}
                disabled={this.props.disabled}
                dropdownStyle={this.dropDownStyle}
                dropdownMenuStyle={{maxHeight: "300px"}}
                multiple={this.props.multiple || false}
                filterOption={((value: string, option: { key: string }) => {
                    return !option.key || filterWithoutAccents(option.key).toLocaleLowerCase().includes((filterWithoutAccents(value) || "").toLocaleLowerCase());
                }) as any}
                value={!this.props.multiple
                    ? (this.selectedOptions.length !== 0 ? this.selectedOptions[0].label : null)
                    : this.selectedOptions.map(opt => opt.label)}
                onChange={(label: string | Array<string>) => this.handleChange(label)}>
                {this.props.options.map(opt =>
                    <ReactSelect.Option
                        key={`${opt.label}|${opt.value}`}
                        value={opt.label}>
                        {this.props.optionComponent
                            ? this.props.optionComponent(opt)
                            : <div style={{ 
                                display: "inline-block" ,
                                color: this.isDarkMode() ? "#FFFFFF" : "rgb(167,170,180)"
                            }}>
                                {opt.label}
                            </div>}
                    </ReactSelect.Option>
                    )}
            </ReactSelect.default>
        </div>
    }
}