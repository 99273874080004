import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notification from "react-notification-system-redux";
import { getText } from '../utils/texts';

export interface RobotState {
    isLoading: boolean;
    requestTime?: number;
    state?: Api.RobotHandlerState;
    deliveryBeltDirection: Api.RobotDataDeliveryBeltStateEnum;
    accumulatoryBeltDirection: "DeliveryDoor" | "Accumulator";
    selectedExtractorBelts: Array<number>;
    coordToSend: Api.RobotCoordModel;
    coordToSendText: RobotCoordTextModel;
    robotAttenptToUnlock: {
        isOpen: boolean;
    },
    railCoordState: {
        isOpen: boolean;
        robotCoord: Api.RobotCoordModel;
        railCoord: Api.RailCoordModel;
        isLoading: boolean;
        requestTime?: number;
    },
    robotCommandStates: {
        [key: string]: {
            isLoading: boolean;
            requestTime?: number
        } }
    robotsState: {
        isLoading: boolean;
        requestTime?: number;
        robots: Array<Api.RobotModel>;
        updateStates: {
            [id: number]: { isLoading: boolean; requestTime?: number; }
        };
    }
}

export interface RobotCoordTextModel {
    "x": string;
    "z": string;
}

interface RobotUpdateDeliveryBeltDestination {
    type: "ROBOT_UPDATE_DELIVERYBELT_DESTINATION";
    payload: { value: Api.RobotDataDeliveryBeltStateEnum };
}
interface RobotUpdateAccumulatorBeltDestination {
    type: "ROBOT_UPDATE_ACCUMULATORBELT_DESTINATION";
    payload: { value: "DeliveryDoor" | "Accumulator" };
}
interface RobotUpdateCoordToSend {
    type: "ROBOT_UPDATE_COORD_TO_SEND";
    payload: { value: Api.RobotCoordModel; };
}
interface RobotAddExtractorBelt {
    type: "ROBOT_ADD_EXTRACTOR_BELT";
    payload: { value: number };
}
interface RobotRemoveExtractorBelt {
    type: "ROBOT_REMOVE_EXTRACTOR_BELT";
    payload: { value: number };
}

export interface ReceiveRobotStateUpdated {
    type: "RECEIVE_ROBOT_STATE_UPDATED";
    payload: { state: Api.RobotHandlerState }
}

interface RequestRobotState {
    type: "REQUEST_ROBOT_STATE";
    payload: { requestTime: number }
}
interface ReceiveRobotState {
    type: "RECEIVE_ROBOT_STATE";
    payload: { requestTime: number; state?: Api.RobotHandlerState };
    error?: any;
}

interface RequestStartSimulation {
    type: "REQUEST_START_SIMULATION";
    payload: { requestTime: number; }
}
interface ReceiveStartSimulation {
    type: "RECEIVE_START_SIMULATION";
    payload: { requestTime: number; result?: Api.ProcessResult };
    error?: any;
}

interface RequestEndSimulation {
    type: "REQUEST_END_SIMULATION";
    payload: { requestTime: number; }
}
interface ReceiveEndSimulation {
    type: "RECEIVE_END_SIMULATION";
    payload: { requestTime: number; result?: Api.ProcessResult };
    error?: any;
}

interface RequestRobots {
    type: "REQUEST_ROBOTS";
    payload: { requestTime: number }
}
interface ReceiveRobots {
    type: "RECEIVE_ROBOTS";
    payload: { requestTime: number; robots?: Array<Api.RobotModel> };
    error?: any;
}

interface RequestUpdateRobot {
    type: "REQUEST_UPDATE_ROBOT";
    payload: { requestTime: number; robotId: number }
}
interface ReceiveUpdateRobot {
    type: "RECEIVE_UPDATE_ROBOT";
    payload: { requestTime: number; robotId: number; robot?: Api.RobotModel };
    error?: any;
}

interface RequestInitRobotTotal {
    type: "REQUEST_INIT_ROBOT_TOTAL";
    payload: { requestTime: number; }
}
interface ReceiveInitRobotTotal {
    type: "RECEIVE_INIT_ROBOT_TOTAL";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestInitRobotPartial {
    type: "REQUEST_INIT_ROBOT_PARTIAL";
    payload: { requestTime: number; }
}
interface ReceiveInitRobotPartial {
    type: "RECEIVE_INIT_ROBOT_PARTIAL";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotToCoord {
    type: "REQUEST_ROBOT_TO_COORD";
    payload: { requestTime: number; }
}
interface ReceiveRobotToCoord {
    type: "RECEIVE_ROBOT_TO_COORD";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotToRailCoord {
    type: "REQUEST_ROBOT_TO_RAIL_COORD";
    payload: { requestTime: number; }
}
interface ReceiveRobotToRailCoord {
    type: "RECEIVE_ROBOT_TO_RAIL_COORD";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotLockDoor {
    type: "REQUEST_ROBOT_LOCKDOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotLockDoor {
    type: "RECEIVE_ROBOT_LOCKDOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotUnLockDoor {
    type: "REQUEST_ROBOT_UNLOCKDOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotUnLockDoor {
    type: "RECEIVE_ROBOT_UNLOCKDOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}


interface RequestRobotOpenDoor {
    type: "REQUEST_ROBOT_OPEN_DOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotOpenDoor {
    type: "RECEIVE_ROBOT_OPEN_DOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotCloseDoor {
    type: "REQUEST_ROBOT_CLOSE_DOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotCloseDoor {
    type: "RECEIVE_ROBOT_CLOSE_DOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}


interface RequestRobotLockInsideDoor {
    type: "REQUEST_ROBOT_LOCKINSIDEDOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotLockInsideDoor {
    type: "RECEIVE_ROBOT_LOCKINSIDEDOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotUnLockInsideDoor {
    type: "REQUEST_ROBOT_UNLOCKINSIDEDOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotUnLockInsideDoor {
    type: "RECEIVE_ROBOT_UNLOCKINSIDEDOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}


interface RequestRobotOpenInsideDoor {
    type: "REQUEST_ROBOT_OPEN_INSIDEDOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotOpenInsideDoor {
    type: "RECEIVE_ROBOT_OPEN_INSIDEDOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotCloseInsideDoor {
    type: "REQUEST_ROBOT_CLOSE_INSIDEDOOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotCloseInsideDoor {
    type: "RECEIVE_ROBOT_CLOSE_INSIDEDOOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotRunDeliveryBelt {
    type: "REQUEST_ROBOT_RUN_DELIVERYBELT";
    payload: { requestTime: number; }
}
interface ReceiveRobotRunDeliveryBelt {
    type: "RECEIVE_ROBOT_RUN_DELIVERYBELT";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotStopDeliveryBelt {
    type: "REQUEST_ROBOT_STOP_DELIVERYBELT";
    payload: { requestTime: number; }
}
interface ReceiveRobotStopDeliveryBelt {
    type: "RECEIVE_ROBOT_STOP_DELIVERYBELT";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotRunAccumulatorBelt {
    type: "REQUEST_ROBOT_RUN_ACCUMULATORBELT";
    payload: { requestTime: number; }
}
interface ReceiveRobotRunAccumulatorBelt {
    type: "RECEIVE_ROBOT_RUN_ACCUMULATORBELT";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotStopAccumulatorBelt {
    type: "REQUEST_ROBOT_STOP_ACCUMULATORBELT";
    payload: { requestTime: number; }
}
interface ReceiveRobotStopAccumulatorBelt {
    type: "RECEIVE_ROBOT_STOP_ACCUMULATORBELT";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotExtendExtractor {
    type: "REQUEST_ROBOT_EXTEND_EXTRACTOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotExtendExtractor {
    type: "RECEIVE_ROBOT_EXTEND_EXTRACTOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotRetractExtractor {
    type: "REQUEST_ROBOT_RETRACT_EXTRACTOR";
    payload: { requestTime: number; }
}
interface ReceiveRobotRetractExtractor {
    type: "RECEIVE_ROBOT_RETRACT_EXTRACTOR";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotRunExtractorBelt {
    type: "REQUEST_ROBOT_RUN_EXTRACTORBELT";
    payload: { requestTime: number; }
}
interface ReceiveRobotRunExtractorBelt {
    type: "RECEIVE_ROBOT_RUN_EXTRACTORBELT";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotStopExtractorBelt {
    type: "REQUEST_ROBOT_STOP_EXTRACTORBELT";
    payload: { requestTime: number; }
}
interface ReceiveRobotStopExtractorBelt {
    type: "RECEIVE_ROBOT_STOP_EXTRACTORBELT";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRobotDispenseBags {
    type: "REQUEST_ROBOT_DISPENSEBAGS";
    payload: { requestTime: number; }
}
interface ReceiveRobotDispenseBags {
    type: "RECEIVE_ROBOT_DISPENSEBAGS";
    payload: { requestTime: number; processResult?: Api.ProcessResult; };
    error?: any;
}

interface RequestRailCoordToRobotCoord {
    type: "REQUEST_RAILCOORD_TO_ROBOTCOORD";
    payload: { requestTime: number; }
}
interface ReceiveRailCoordToRobotCoord {
    type: "RECEIVE_RAILCOORD_TO_ROBOTCOORD";
    payload: { requestTime: number; robotCoord?: Api.RobotCoordModel; };
    error?: any;
}

interface RobotOpenRailCoordDialog {
    type: "ROBOT_OPEN_RAILCOORDDIALOG"
}
interface RobotCloseRailCoordDialog {
    type: "ROBOT_CLOSE_RAILCOORDDIALOG"
}
interface RobotCopyRobotCoord {
    type: "ROBOT_COPY_ROBOTCOORD"
}
interface RobotSetRailCoord {
    type: "ROBOT_SET_RAILCOORD";
    payload: { value: Api.RailCoordModel; };
}
interface RobotUpdateCoordTextToSend {
    type: "ROBOT_UPDATE_COORDTEXT_TO_SEND";
    payload: { value: RobotCoordTextModel; };
}

interface RobotOpenUnlockDialog {
    type: "ROBOT_OPEN_UNLOCKDIALOG"
}
interface RobotCloseUnlockDialog {
    type: "ROBOT_CLOSE_UNLOCKDIALOG"
}

// interface RequestInitMW232 {
//     type: "REQUEST_INIT_MW232";
//     payload: { requestTime: number; }
// }
// interface ReceiveInitMW232 {
//     type: "RECEIVE_INIT_MW232";
//     payload: { requestTime: number; };
//     error?: any;
// }

type KnownAction =
    RobotOpenRailCoordDialog
    | RobotCloseRailCoordDialog
    | RobotCopyRobotCoord
    | RobotSetRailCoord
    | RobotUpdateDeliveryBeltDestination
    | RobotUpdateAccumulatorBeltDestination
    | RobotAddExtractorBelt
    | RobotRemoveExtractorBelt
    | RequestRobotState
    | ReceiveRobotState
    | RequestRobots
    | ReceiveRobots
    | RequestUpdateRobot
    | ReceiveUpdateRobot
    | RequestInitRobotTotal
    | ReceiveInitRobotTotal
    | RequestInitRobotPartial
    | ReceiveInitRobotPartial
    | RequestRobotToCoord
    | ReceiveRobotToCoord
    | RequestRobotToRailCoord
    | ReceiveRobotToRailCoord
    | RequestRobotLockDoor
    | ReceiveRobotLockDoor
    | RequestRobotUnLockDoor
    | ReceiveRobotUnLockDoor
    | RequestRobotOpenDoor
    | ReceiveRobotOpenDoor
    | RequestRobotCloseDoor
    | ReceiveRobotCloseDoor

    | RequestRobotLockInsideDoor
    | ReceiveRobotLockInsideDoor
    | RequestRobotUnLockInsideDoor
    | ReceiveRobotUnLockInsideDoor
    | RequestRobotOpenInsideDoor
    | ReceiveRobotOpenInsideDoor
    | RequestRobotCloseInsideDoor
    | ReceiveRobotCloseInsideDoor

    | RequestStartSimulation
    | ReceiveStartSimulation
    | RequestEndSimulation
    | ReceiveEndSimulation

    | RequestRobotRunDeliveryBelt
    | ReceiveRobotRunDeliveryBelt
    | RequestRobotStopDeliveryBelt
    | ReceiveRobotStopDeliveryBelt
    | RequestRobotRunAccumulatorBelt
    | ReceiveRobotRunAccumulatorBelt
    | RequestRobotStopAccumulatorBelt
    | ReceiveRobotStopAccumulatorBelt
    | RequestRobotExtendExtractor
    | ReceiveRobotExtendExtractor
    | RequestRobotRetractExtractor
    | ReceiveRobotRetractExtractor
    | RequestRobotRunExtractorBelt
    | ReceiveRobotRunExtractorBelt
    | RequestRobotStopExtractorBelt
    | ReceiveRobotStopExtractorBelt
    | RequestRobotDispenseBags
    | ReceiveRobotDispenseBags
    | RequestRailCoordToRobotCoord
    | ReceiveRailCoordToRobotCoord
    | RobotUpdateCoordToSend
    | ReceiveRobotStateUpdated
    | RobotUpdateCoordTextToSend
    | RobotOpenUnlockDialog
    | RobotCloseUnlockDialog
    // | RequestInitMW232
    // | ReceiveInitMW232
    ;

const onProcessReceived = (dispatch: (action: any) => void, processResult?: Api.ProcessResult, error?: any) => {
    if (processResult && !processResult.isSuccess) {
        dispatch(Notification.error({ position: "tc", message: processResult.message, title: getText("NotificationTitleRoborError") }) as any);
    }
    if (error) {
        //useful on JS/React error happening when rendering new componant, it is often catched by the promise
        if (error.text) {
            error.text().then(x => {
                dispatch(Notification.error({ title: getText("NotificationTitleError"), message: x, position: "tc", level: "error" }) as any);
            });
        } else {
            dispatch(Notification.error({ title: getText("NotificationTitleError"), message: getText("NotificationSomethingWrong"), position: "tc", level: "error" }) as any);
        }
        console.error(error);
    }
};

export const actionCreators = {
    requestRobotState: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.getState({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(state => {
                dispatch({
                    type: "RECEIVE_ROBOT_STATE",
                    payload: { requestTime: requestTime, state: state }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_STATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_ROBOT_STATE",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    },
    requestRobots: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.getRobots({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(robots => {
                dispatch({
                    type: "RECEIVE_ROBOTS",
                    payload: { requestTime: requestTime, robots: robots }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOTS",
                    payload: { requestTime: requestTime },
                    error: err
                });
                console.error(err);
            });

        dispatch({
            type: "REQUEST_ROBOTS",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    },
    requestUpdateRobot: (requestTime: number, model: Api.RobotModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.updateRobot({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(robot => {
                dispatch({
                    type: "RECEIVE_UPDATE_ROBOT",
                    payload: { requestTime: requestTime, robotId: model.robotId, robot: robot }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_UPDATE_ROBOT",
                    payload: { requestTime: requestTime, robotId: model.robotId },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_UPDATE_ROBOT",
            payload: { requestTime: requestTime, robotId: model.robotId }
        });
        addTask(fetch);
        return fetch;
    },
    requestInitRobotTotal: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.initializeRobotTotal({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_INIT_ROBOT_TOTAL",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_INIT_ROBOT_TOTAL",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_INIT_ROBOT_TOTAL",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestInitRobotPartial: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.initializeRobotPartial({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_INIT_ROBOT_PARTIAL",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_INIT_ROBOT_PARTIAL",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_INIT_ROBOT_PARTIAL",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    // requestInitMW232: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
    //     let api = new Api.RobotApi();
    //     let fetch = api.initializeMW232({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
    //         .then(processResult => {
    //             dispatch({
    //                 type: "RECEIVE_INIT_MW232",
    //                 payload: { requestTime: requestTime }
    //             });
    //             onProcessReceived(dispatch, processResult);
    //         })
    //         .catch(err => {
    //             dispatch({
    //                 type: "RECEIVE_INIT_MW232",
    //                 payload: { requestTime: requestTime },
    //                 error: err
    //             });
    //             onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
    //         });

    //     dispatch({
    //         type: "REQUEST_INIT_MW232",
    //         payload: { requestTime: requestTime }
    //     });
    //     return fetch;
    // },
    requestRobotToCoord: (requestTime: number, model: Api.RobotCoordModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.toCoord({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_TO_COORD",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_TO_COORD",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_TO_COORD",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotToRailCoord: (requestTime: number, model: Api.RailCoordModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.toRailCoord({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_TO_RAIL_COORD",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_TO_RAIL_COORD",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_TO_RAIL_COORD",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotLockDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.lockDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_LOCKDOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_LOCKDOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_LOCKDOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotUnLockDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.unlockDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_UNLOCKDOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_UNLOCKDOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_UNLOCKDOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotOpenDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.openDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_OPEN_DOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_OPEN_DOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_OPEN_DOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotCloseDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.closeDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_CLOSE_DOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_CLOSE_DOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_CLOSE_DOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotLockInsideDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.lockInsideDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_LOCKINSIDEDOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_LOCKINSIDEDOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_LOCKINSIDEDOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotUnLockInsideDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.unlockInsideDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_UNLOCKINSIDEDOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_UNLOCKINSIDEDOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_UNLOCKINSIDEDOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotOpenInsideDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.openInsideDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_OPEN_INSIDEDOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_OPEN_INSIDEDOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_OPEN_INSIDEDOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotCloseInsideDoor: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.closeInsideDoor({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_CLOSE_INSIDEDOOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_CLOSE_INSIDEDOOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_CLOSE_INSIDEDOOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotRunDeliveryBelt: (requestTime: number, destination: Api.RobotDataDeliveryBeltStateEnum): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.runDeliveryBelt({
            destination: destination
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_RUN_DELIVERYBELT",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_RUN_DELIVERYBELT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_RUN_DELIVERYBELT",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotStopDeliveryBelt: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.stopDeliveryBelt({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_STOP_DELIVERYBELT",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_STOP_DELIVERYBELT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_STOP_DELIVERYBELT",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotRunAccumulatorBelt: (requestTime: number, destination: string): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.runAccumulatorBelt({
            destination: destination
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_RUN_ACCUMULATORBELT",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_RUN_ACCUMULATORBELT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_RUN_ACCUMULATORBELT",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotStopAccumulatorBelt: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.stopAccumulatorBelt({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_STOP_ACCUMULATORBELT",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_STOP_ACCUMULATORBELT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_STOP_ACCUMULATORBELT",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotExtendExtractor: (requestTime: number, extractorNumber: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.extendExtractor({
            extractorNumber: extractorNumber
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_EXTEND_EXTRACTOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_EXTEND_EXTRACTOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_EXTEND_EXTRACTOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotRetractExtractor: (requestTime: number, extractorNumber: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.retractExtractor({
            extractorNumber: extractorNumber
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_RETRACT_EXTRACTOR",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_RETRACT_EXTRACTOR",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_RETRACT_EXTRACTOR",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotRunExtractorBelt: (requestTime: number, model: Api.ExtractorBeltModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.runExtractorBelt({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_RUN_EXTRACTORBELT",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_RUN_EXTRACTORBELT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_RUN_EXTRACTORBELT",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotStopExtractorBelt: (requestTime: number, model: Api.ExtractorBeltModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.stopExtractorBelt({
            model: model
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_STOP_EXTRACTORBELT",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_STOP_EXTRACTORBELT",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_STOP_EXTRACTORBELT",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRobotDispenseBags: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.dispenseBags({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_ROBOT_DISPENSEBAGS",
                    payload: { requestTime: requestTime }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_ROBOT_DISPENSEBAGS",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_ROBOT_DISPENSEBAGS",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestRailCoordToRobotCoord: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.railCoordToRobotCoord({ model: getState().robot.railCoordState.railCoord },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(robotCoord => {
                dispatch({
                    type: "RECEIVE_RAILCOORD_TO_ROBOTCOORD",
                    payload: { requestTime: requestTime, robotCoord: robotCoord }
                });
                console.log("x: " + (65536 + robotCoord.x))
                console.log("z: " + (65536 + robotCoord.z))
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_RAILCOORD_TO_ROBOTCOORD",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, null, err);
            });

        dispatch({
            type: "REQUEST_RAILCOORD_TO_ROBOTCOORD",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestStartSimulation: (requestTime: number, model: Api.RobotSimulationModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.startSimulation({ model: model },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_START_SIMULATION",
                    payload: { requestTime: requestTime, result: processResult }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_START_SIMULATION",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_START_SIMULATION",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestStopSimulation: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.RobotApi();
        let fetch = api.stopSimulation(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(processResult => {
                dispatch({
                    type: "RECEIVE_END_SIMULATION",
                    payload: { requestTime: requestTime, result: processResult }
                });
                onProcessReceived(dispatch, processResult);
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_END_SIMULATION",
                    payload: { requestTime: requestTime },
                    error: err
                });
                onProcessReceived(dispatch, { isSuccess: false, message: `Promise failed` });
            });

        dispatch({
            type: "REQUEST_END_SIMULATION",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    updateDeliveryBeltDestination: (value: Api.RobotDataDeliveryBeltStateEnum) => <RobotUpdateDeliveryBeltDestination>{
        type: "ROBOT_UPDATE_DELIVERYBELT_DESTINATION",
        payload: { value: value }
    },
    updateAccumulatorBeltDestination: (value: "DeliveryDoor" | "Accumulator") => <RobotUpdateAccumulatorBeltDestination>{
        type: "ROBOT_UPDATE_ACCUMULATORBELT_DESTINATION",
        payload: { value: value }
    },
    addExctratorBelt: (value: number) => <RobotAddExtractorBelt>{ type: "ROBOT_ADD_EXTRACTOR_BELT", payload: { value: value } },
    removeExctratorBelt: (value: number) => <RobotRemoveExtractorBelt>{ type: "ROBOT_REMOVE_EXTRACTOR_BELT", payload: { value: value } },
    updateCoordToSend: (value: Api.RobotCoordModel) => <RobotUpdateCoordToSend>{ type: "ROBOT_UPDATE_COORD_TO_SEND", payload: { value: value } },
    openRailCoordDialog: () => <RobotOpenRailCoordDialog>{ type: "ROBOT_OPEN_RAILCOORDDIALOG" },
    closeRailCoordDialog: () => <RobotCloseRailCoordDialog>{ type: "ROBOT_CLOSE_RAILCOORDDIALOG" },
    copyRobotCoord: () => <RobotCopyRobotCoord>{ type: "ROBOT_COPY_ROBOTCOORD" },
    setRailCoord: (value: Api.RailCoordModel) => <RobotSetRailCoord>{ type: "ROBOT_SET_RAILCOORD", payload: { value: value } },
    updateCoordTextToSend: (value: RobotCoordTextModel) => <RobotUpdateCoordTextToSend>{ type: "ROBOT_UPDATE_COORDTEXT_TO_SEND", payload: { value: value } },
    openUnlockDialog: () => <RobotOpenUnlockDialog>{ type: "ROBOT_OPEN_UNLOCKDIALOG" },
    closeUnlockDialog: () => <RobotCloseUnlockDialog>{ type: "ROBOT_CLOSE_UNLOCKDIALOG" }
}

const unloadedState: RobotState = {
    isLoading: false,
    robotsState: {
        isLoading: false,
        robots: [],
        updateStates: {}
    },
    coordToSend: {
        lane: 1,
        x: 0,
        z: 0
    },
    coordToSendText: {
        x: "0",
        z: "0"
    },
    robotAttenptToUnlock: {
        isOpen: false
    },
    railCoordState: {
        isLoading: false,
        isOpen: false,
        railCoord: {
            r: 0,
            a: 0,
            d: 0,
            f: 0,
        },
        robotCoord: {
            x: 0,
            z: 0,
            lane: 1
        },
    },
    selectedExtractorBelts: [],
    robotCommandStates: {},
    deliveryBeltDirection: "DeliveryDoor",
    accumulatoryBeltDirection: "DeliveryDoor"
}


export const reducer: Reducer<RobotState> = (state: RobotState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ROBOT_STATE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_ROBOT_STATE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                state: action.error
                    ? state.state
                    : action.payload.state
            };
        case "REQUEST_ROBOTS":
            return {
                ...state,
                robotsState: {
                    ...state.robotsState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_ROBOTS":
            if (action.payload.requestTime !== state.robotsState.requestTime)
                return state;

            return {
                ...state,
                robotsState: {
                    ...state.robotsState,
                    isLoading: false,
                    robots: action.error
                        ? []
                        : action.payload.robots
                }
            };
        case "REQUEST_UPDATE_ROBOT":
            return {
                ...state,
                robotsState: {
                    ...state.robotsState,
                    updateStates: {
                        ...state.robotsState.updateStates,
                        [action.payload.robotId]: {
                            ...state.robotsState.updateStates[action.payload.robotId],
                            isLoading: true,
                            requestTime: action.payload.requestTime
                        }
                    }
                }
            };
        case "RECEIVE_UPDATE_ROBOT":
            if (!state.robotsState.updateStates[action.payload.robotId]
                || state.robotsState.updateStates[action.payload.robotId].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                robotsState: {
                    ...state.robotsState,
                    robots: action.error
                        ? state.robotsState.robots
                        : state.robotsState.robots.map(x => x.robotId === action.payload.robotId
                            ? action.payload.robot
                            : x),
                    updateStates: {
                        ...state.robotsState.updateStates,
                        [action.payload.robotId]: {
                            ...state.robotsState.updateStates[action.payload.robotId],
                            isLoading: false
                        }
                    }
                }
            };
        case "ROBOT_UPDATE_DELIVERYBELT_DESTINATION":
            return {
                ...state,
                deliveryBeltDirection: action.payload.value
            };
        case "ROBOT_UPDATE_ACCUMULATORBELT_DESTINATION":
            return {
                ...state,
                accumulatoryBeltDirection: action.payload.value
            };
        case "REQUEST_INIT_ROBOT_TOTAL":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["initTotal"]: {
                        ...state.robotCommandStates["initTotal"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_INIT_ROBOT_TOTAL":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["initTotal"]: {
                        ...state.robotCommandStates["initTotal"],
                        isLoading: state.robotCommandStates["initTotal"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["initTotal"].isLoading,
                    }
                }
            };
        case "REQUEST_INIT_ROBOT_PARTIAL":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["initPartial"]: {
                        ...state.robotCommandStates["initPartial"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_INIT_ROBOT_PARTIAL":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["initPartial"]: {
                        ...state.robotCommandStates["initPartial"],
                        isLoading: state.robotCommandStates["initPartial"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["initPartial"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_TO_COORD":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["toCoord"]: {
                        ...state.robotCommandStates["toCoord"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_TO_COORD":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["toCoord"]: {
                        ...state.robotCommandStates["toCoord"],
                        isLoading: state.robotCommandStates["toCoord"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["toCoord"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_TO_RAIL_COORD":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["toRailCoord"]: {
                        ...state.robotCommandStates["toRailCoord"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_TO_RAIL_COORD":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["toRailCoord"]: {
                        ...state.robotCommandStates["toRailCoord"],
                        isLoading: state.robotCommandStates["toRailCoord"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["toRailCoord"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_LOCKDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["lockDoor"]: {
                        ...state.robotCommandStates["lockDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_LOCKDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["lockDoor"]: {
                        ...state.robotCommandStates["lockDoor"],
                        isLoading: state.robotCommandStates["lockDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["lockDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_UNLOCKDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["unlockDoor"]: {
                        ...state.robotCommandStates["unlockDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_UNLOCKDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["unlockDoor"]: {
                        ...state.robotCommandStates["unlockDoor"],
                        isLoading: state.robotCommandStates["unlockDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["unlockDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_OPEN_DOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["openDoor"]: {
                        ...state.robotCommandStates["openDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_OPEN_DOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["openDoor"]: {
                        ...state.robotCommandStates["openDoor"],
                        isLoading: state.robotCommandStates["openDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["openDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_CLOSE_DOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["closeDoor"]: {
                        ...state.robotCommandStates["closeDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_CLOSE_DOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["closeDoor"]: {
                        ...state.robotCommandStates["closeDoor"],
                        isLoading: state.robotCommandStates["closeDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["closeDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_LOCKINSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["lockInsideDoor"]: {
                        ...state.robotCommandStates["lockInsideDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_LOCKINSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["lockInsideDoor"]: {
                        ...state.robotCommandStates["lockInsideDoor"],
                        isLoading: state.robotCommandStates["lockInsideDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["lockInsideDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_UNLOCKINSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["unlockInsideDoor"]: {
                        ...state.robotCommandStates["unlockInsideDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_UNLOCKINSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["unlockInsideDoor"]: {
                        ...state.robotCommandStates["unlockInsideDoor"],
                        isLoading: state.robotCommandStates["unlockInsideDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["unlockInsideDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_OPEN_INSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["openInsideDoor"]: {
                        ...state.robotCommandStates["openInsideDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_OPEN_INSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["openInsideDoor"]: {
                        ...state.robotCommandStates["openInsideDoor"],
                        isLoading: state.robotCommandStates["openInsideDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["openInsideDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_CLOSE_INSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["closeInsideDoor"]: {
                        ...state.robotCommandStates["closeInsideDoor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_CLOSE_INSIDEDOOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["closeInsideDoor"]: {
                        ...state.robotCommandStates["closeInsideDoor"],
                        isLoading: state.robotCommandStates["closeInsideDoor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["closeInsideDoor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_RUN_DELIVERYBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["runDeliveryBelt"]: {
                        ...state.robotCommandStates["runDeliveryBelt"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_RUN_DELIVERYBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["runDeliveryBelt"]: {
                        ...state.robotCommandStates["runDeliveryBelt"],
                        isLoading: state.robotCommandStates["runDeliveryBelt"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["runDeliveryBelt"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_STOP_DELIVERYBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["stopDeliveryBelt"]: {
                        ...state.robotCommandStates["stopDeliveryBelt"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_STOP_DELIVERYBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["stopDeliveryBelt"]: {
                        ...state.robotCommandStates["stopDeliveryBelt"],
                        isLoading: state.robotCommandStates["stopDeliveryBelt"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["stopDeliveryBelt"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_RUN_ACCUMULATORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["runAccumulatorBelt"]: {
                        ...state.robotCommandStates["runAccumulatorBelt"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_RUN_ACCUMULATORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["runAccumulatorBelt"]: {
                        ...state.robotCommandStates["runAccumulatorBelt"],
                        isLoading: state.robotCommandStates["runAccumulatorBelt"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["runAccumulatorBelt"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_STOP_ACCUMULATORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["stopAccumulatorBelt"]: {
                        ...state.robotCommandStates["stopAccumulatorBelt"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_STOP_ACCUMULATORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["stopAccumulatorBelt"]: {
                        ...state.robotCommandStates["stopAccumulatorBelt"],
                        isLoading: state.robotCommandStates["stopAccumulatorBelt"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["stopAccumulatorBelt"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_EXTEND_EXTRACTOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["extendExtractor"]: {
                        ...state.robotCommandStates["extendExtractor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_EXTEND_EXTRACTOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["extendExtractor"]: {
                        ...state.robotCommandStates["extendExtractor"],
                        isLoading: state.robotCommandStates["extendExtractor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["extendExtractor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_RETRACT_EXTRACTOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["retractExtractor"]: {
                        ...state.robotCommandStates["retractExtractor"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_RETRACT_EXTRACTOR":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["retractExtractor"]: {
                        ...state.robotCommandStates["retractExtractor"],
                        isLoading: state.robotCommandStates["retractExtractor"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["retractExtractor"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_RUN_EXTRACTORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["runExtractorBelt"]: {
                        ...state.robotCommandStates["runExtractorBelt"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_RUN_EXTRACTORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["runExtractorBelt"]: {
                        ...state.robotCommandStates["runExtractorBelt"],
                        isLoading: state.robotCommandStates["runExtractorBelt"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["runExtractorBelt"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_STOP_EXTRACTORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["stopExtractorBelt"]: {
                        ...state.robotCommandStates["stopExtractorBelt"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_STOP_EXTRACTORBELT":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["stopExtractorBelt"]: {
                        ...state.robotCommandStates["stopExtractorBelt"],
                        isLoading: state.robotCommandStates["stopExtractorBelt"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["stopExtractorBelt"].isLoading,
                    }
                }
            };
        case "REQUEST_ROBOT_DISPENSEBAGS":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["dispenseBags"]: {
                        ...state.robotCommandStates["dispenseBags"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ROBOT_DISPENSEBAGS":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["dispenseBags"]: {
                        ...state.robotCommandStates["dispenseBags"],
                        isLoading: state.robotCommandStates["dispenseBags"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["dispenseBags"].isLoading,
                    }
                }
            };
        case "REQUEST_RAILCOORD_TO_ROBOTCOORD":
            return {
                ...state,
                railCoordState: {
                    ...state.railCoordState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_RAILCOORD_TO_ROBOTCOORD":
            if (state.railCoordState.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                railCoordState: {
                    ...state.railCoordState,
                    isLoading: false,
                    robotCoord: action.error
                        ? { x: 0, z: 0, lane: 0 }
                        : action.payload.robotCoord
                }
            };
        case "ROBOT_ADD_EXTRACTOR_BELT":
            return {
                ...state,
                selectedExtractorBelts: state.selectedExtractorBelts.concat([action.payload.value])
            };
        case "ROBOT_REMOVE_EXTRACTOR_BELT":
            return {
                ...state,
                selectedExtractorBelts: state.selectedExtractorBelts.filter(x => x !== action.payload.value)
            };
        case "ROBOT_UPDATE_COORD_TO_SEND": {
            return {
                ...state,
                coordToSend: action.payload.value
            };
        }
        case "RECEIVE_ROBOT_STATE_UPDATED":
            return {
                ...state,
                state: action.payload.state
            };
        case "ROBOT_OPEN_RAILCOORDDIALOG":
            return {
                ...state,
                railCoordState: {
                    ...state.railCoordState,
                    isOpen: true
                }
            };
        case "ROBOT_CLOSE_RAILCOORDDIALOG":
            return {
                ...state,
                railCoordState: {
                    ...state.railCoordState,
                    isOpen: false
                }
            };
        case "ROBOT_COPY_ROBOTCOORD":
            return {
                ...state,
                coordToSendText: {
                    ...state.coordToSendText,
                    x: state.railCoordState.robotCoord.x.toString(),
                    z: state.railCoordState.robotCoord.z.toString()
                },
                coordToSend: state.railCoordState.robotCoord,
                selectedExtractorBelts: state.railCoordState.robotCoord.belts || [],
                railCoordState: {
                    ...state.railCoordState,
                    isOpen: false
                }
            };
        case "ROBOT_SET_RAILCOORD":
            return {
                ...state,
                railCoordState: {
                    ...state.railCoordState,
                    railCoord: action.payload.value
                }
            };
        case "REQUEST_START_SIMULATION":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["simulRobot"]: {
                        ...state.robotCommandStates["simulRobot"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_START_SIMULATION":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["simulRobot"]: {
                        ...state.robotCommandStates["simulRobot"],
                        isLoading: state.robotCommandStates["simulRobot"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["simulRobot"].isLoading,
                    }
                }
            };
        case "REQUEST_END_SIMULATION":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["simulRobot"]: {
                        ...state.robotCommandStates["simulRobot"],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_END_SIMULATION":
            return {
                ...state,
                robotCommandStates: {
                    ...state.robotCommandStates,
                    ["simulRobot"]: {
                        ...state.robotCommandStates["simulRobot"],
                        isLoading: state.robotCommandStates["simulRobot"].requestTime === action.payload.requestTime
                            ? false
                            : state.robotCommandStates["simulRobot"].isLoading,
                    }
                }
            };
        case "ROBOT_UPDATE_COORDTEXT_TO_SEND": {
            return {
                ...state,
                coordToSendText: action.payload.value
            };
        }
        case "ROBOT_OPEN_UNLOCKDIALOG":
            return {
                ...state,
                robotAttenptToUnlock: {
                    ...state.robotAttenptToUnlock,
                    isOpen: true
                }
            };
        case "ROBOT_CLOSE_UNLOCKDIALOG":
            return {
                ...state,
                robotAttenptToUnlock: {
                    ...state.robotAttenptToUnlock,
                    isOpen: false
                }
            };
        // case "REQUEST_INIT_MW232":
        //     return {
        //         ...state,
        //         robotCommandStates: {
        //             ...state.robotCommandStates,
        //             ["initMW232"]: {
        //                 ...state.robotCommandStates["initMW232"],
        //                 isLoading: true,
        //                 requestTime: action.payload.requestTime
        //             }
        //         }
        //     };
        // case "RECEIVE_INIT_MW232":
        //     return {
        //         ...state,
        //         robotCommandStates: {
        //             ...state.robotCommandStates,
        //             ["initMW232"]: {
        //                 ...state.robotCommandStates["initMW232"],
        //                 isLoading: state.robotCommandStates["initMW232"].requestTime === action.payload.requestTime
        //                     ? false
        //                     : state.robotCommandStates["initMW232"].isLoading,
        //             }
        //         }
        //     };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}