import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';

export interface TempState {
    isLoading: boolean;
    requestTime?: number;
    state?: Api.TempHandlerState;
    temperatureState: {
        isLoading: boolean;
        requestTime?: number;
        value?: number;
    }
}

interface RequestTempState {
    type: "REQUEST_TEMP_STATE";
    payload: { requestTime: number }
}
interface ReceiveTempState {
    type: "RECEIVE_TEMP_STATE";
    payload: { requestTime: number; state?: Api.TempHandlerState };
    error?: any;
}

interface RequestTempTemperature {
    type: "REQUEST_TEMP_TEMPERATURE";
    payload: { requestTime: number }
}
interface ReceiveTempTemperature {
    type: "RECEIVE_TEMP_TEMPERATURE";
    payload: { requestTime: number; temperature?: number };
    error?: any;
}

type KnownAction = RequestTempState
    | ReceiveTempState
    | RequestTempTemperature
    | ReceiveTempTemperature
    ;

export const actionCreators = {
    requestTempState: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TempApi();
        let fetch = api.getState({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(state => {
                dispatch({
                    type: "RECEIVE_TEMP_STATE",
                    payload: { requestTime: requestTime, state: state }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_TEMP_STATE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_TEMP_STATE",
            payload: { requestTime: requestTime }
        });
        return fetch;
    },
    requestTempTemperature: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let api = new Api.TempApi();
        let fetch = api.getTemperature({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(temperature => {
                dispatch({
                    type: "RECEIVE_TEMP_TEMPERATURE",
                    payload: { requestTime: requestTime, temperature: temperature }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_TEMP_TEMPERATURE",
                    payload: { requestTime: requestTime },
                    error: err
                });
            });

        dispatch({
            type: "REQUEST_TEMP_TEMPERATURE",
            payload: { requestTime: requestTime }
        });
        addTask(fetch);
        return fetch;
    }
}

const unloadedState: TempState = {
    isLoading: false,
    temperatureState: {
        isLoading: false
    }
}


export const reducer: Reducer<TempState> = (state: TempState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_TEMP_STATE":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_TEMP_STATE":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                state: action.error
                    ? state.state
                    : action.payload.state
            };
        case "REQUEST_TEMP_TEMPERATURE":
            return {
                ...state,
                temperatureState: {
                    ...state.temperatureState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_TEMP_TEMPERATURE":
            if (action.payload.requestTime !== state.temperatureState.requestTime)
                return state;

            return {
                ...state,
                temperatureState: {
                    ...state.temperatureState,
                    isLoading: false,
                    value: action.error
                        ? state.temperatureState.value
                        : action.payload.temperature
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}