import * as Api from '../api/api';
import * as React from 'react';
import * as HmiMoneyStore from '../store/HmiMoney';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import HmiMoneyForm from "./HmiMoneyForm";
import { canEditSettings, canUpdate } from '../security/roles';

type SettingsHmiMoneyProps = SettingsHmiMoneyOwnProps
    & HmiMoneyStore.HmiMoneyState
    & typeof HmiMoneyStore.actionCreators;

interface SettingsHmiMoneyOwnProps {
    disabled: boolean;
    currentUser: Api.CurrentUserManagerModel;  
}

class SettingsHmiMoney extends React.Component<SettingsHmiMoneyProps, {}> {
    componentDidMount() {
        this.props.requestHmiMonies(new Date().getTime());
    }

    public render() {
        return (
            <div>
                <div style={{ pointerEvents: this.props.disabled ? "none" : "initial", 
                            opacity: this.props.disabled ? 0.7 : 1 }}>
                    {this.props.hmiMonies.map(x => <div key={x.hmiMoneyId}>
                        <HmiMoneyForm hmiMoneyId={x.hmiMoneyId}
                            disabled={this.props.disabled }
                            onSubmit={(value) => this.props.requestUpdateHmiMoney(new Date().getTime(), value)} />
                    </div>)}
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.hmiMoney,
    disabled: !canEditSettings(state),    
    currentUser: state.account.currentUser,
} as SettingsHmiMoneyOwnProps), HmiMoneyStore.actionCreators)(SettingsHmiMoney as any);