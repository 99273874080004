import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as FormField from "./FormField";
import * as ReduxForm from "redux-form";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { compose } from 'redux';
import { canUpdate } from '../security/roles';
import * as Styles from '../styles/baseStyle';
import { getText } from '../utils/texts';

type HmiMoneyFormProps = ReduxForm.InjectedFormProps<Api.HmiMoneyModel, {}, {}>
    & HmiMoneyFormOwnProps
    & HmiMoneyFormExternalProps;

interface HmiMoneyFormExternalProps {
    hmiMoneyId: number;
    disabled: boolean;
    onSubmit: (values: Api.HmiMoneyModel) => any;
}

interface HmiMoneyFormOwnProps {
    moneys: { [id: number]: Api.MoneyModel };
    hmiMoneyMoneys: Array<Api.HmiMoneyMoneyModel>;
}

const getPaymentCashTypeOptions = (): Array<{ label: string, value: Api.HmiMoneyModelCashTypeEnum }> => [
    { label: "Paylink", value: "Paylink" }
];
const getPaymentCardTypeOptions = (): Array<{ label: string, value: Api.HmiMoneyCreditCardTypeEnum }> => [
    { label: "Rs232", value: "Rs232" }
];
const getPrinterTypeOptions = (): Array<{ label: string, value: Api.HmiMoneyPrinterTypeEnum }> => [
    { label: "PMU", value: "Pmu" }
];

const colStyle: React.CSSProperties = {padding: '1% 2%'}
const inputStyle: React.CSSProperties = {minWidth: 200, maxWidth: 200, height: 34};

class HmiMoneyForm extends React.Component<HmiMoneyFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="row borderSelectors">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card" style={{...Styles.cardStyle, ...colStyle}}>
                                    <div style={{fontSize: 22}}>
                                        {getText( "SettingsTabMoney")}
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <div>{getText( "SettingsTimePaymentCash")}</div>
                                        <ReduxForm.Field
                                            name="paymentCashTimeout"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                                        <div>{getText( "SettingsModuleCash")}</div>
                                        <ReduxForm.Field
                                            name="cashType"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.getSelectField(getPaymentCashTypeOptions())} />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '3%'}}>
                                        <div>{getText( "SettingsMinCreditCard")}</div>
                                        <ReduxForm.Field
                                            name="cardMinAmount"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                                        <div>{getText( "SettingsRender")}</div>
                                        <ReduxForm.Field
                                            name="changeCeiling"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                                        <div>{getText( "SettingsTimeCreditCard")}</div>
                                        <ReduxForm.Field
                                            name="paymentCardTimeout"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.NumberField} />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2%'}}>
                                        <div>{getText( "SettingsModuleCreditCard")}</div>
                                        <ReduxForm.Field
                                            name="creditCardType"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.getSelectField(getPaymentCardTypeOptions())} />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '3% 0'}}>
                                        <div>{getText( "SettingsModulePrinter")}</div>
                                        <ReduxForm.Field
                                            name="printerType"
                                            style={inputStyle}
                                            validate={[FormField.required]}
                                            component={FormField.getSelectField(getPrinterTypeOptions())} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '2%'}}>
                            <div className="col-md-12">
                                <div className="settingsCurrency card" style={{...Styles.cardStyle, padding: '1% 3%', overflow: 'scroll'}}>
                                    <div style={{fontSize: 22}}>
                                        {getText( "SettingsTitleCurrency")}
                                    </div>
                                    <ReduxForm.FieldArray
                                        validate={[FormField.requiredOne]}
                                        name="hmiMoneyMoneys"
                                        component={HmiMoneyMoneys}
                                        props={{
                                            moneys: this.props.moneys,
                                            hmiMoneyMoneys: this.props.hmiMoneyMoneys
                                        } as HmiMoneyMoneysOwnProps} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingLeft: 0}}>
                        <div className="settingsHoppers card" style={{...Styles.cardStyle, padding: '1% 3%', overflow: 'scroll'}}>
                            <div style={{fontSize: 22}}>
                                {getText( "SettingsTitleHoppers")}
                            </div>
                            <ReduxForm.FieldArray
                                validate={[FormField.requiredOne]}
                                name="hmiMoneyHoppers"
                                component={HmiMoneyHoppers}
                                props={{
                                    moneys: this.props.moneys,
                                    hmiMoneyMoneys: this.props.hmiMoneyMoneys
                                } as HmiMoneyHoppersOwnProps} />
                        </div>
                        <div style={{paddingTop: '4%'}}>
                            <button className="btn btn-primary"
                                type={"submit"}
                                style={{width: '100%'}}
                                disabled={this.props.submitting || this.props.pristine || this.props.disabled}>
                                {getText( "SettingsSave")}
                            </button>
                        </div>
                    </div>
                </div>
            </FormField.BaseForm>
            );
    }
}

type HmiMoneyHoppersProps = HmiMoneyHoppersOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.HmiMoneyHopperModel>;
interface HmiMoneyHoppersOwnProps {
    moneys: { [id: number]: Api.MoneyModel };
    hmiMoneyMoneys: Array<Api.HmiMoneyMoneyModel>;
}

class HmiMoneyHoppers extends React.Component<HmiMoneyHoppersProps, {}> {
    public render() {
        return (
            <div style={{height: '50vh'}}>
                {this.props.fields.map((member, index) => 
                    <div key={index} style={{padding: '1% 0' }}>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsNameTable")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.name`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.required]}
                                    type={"text"}
                                    component={FormField.renderField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsNumber")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.number`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.requiredOrZero]}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsSerial")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.serialNumber`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.required]}
                                    type={"text"}
                                    component={FormField.renderField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsLowWarning")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.lowQuantity`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.requiredOrZero]}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsLowAlert")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.lowQuantityAlert`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.requiredOrZero]}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsHighWarning")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.highQuantity`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.requiredOrZero]}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsHighAlert")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.highQuantityAlert`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.requiredOrZero]}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '2%'}}>
                            <label className="control-label col-md-7" style={{textAlign: 'initial'}}>{getText( "SettingsChange")}</label>
                            <div className="col-md-5">
                                <ReduxForm.Field
                                    name={`${member}.moneyId`}
                                    style={{height: 34, width: '100%'}}
                                    validate={[FormField.required]}
                                    component={FormField.getSelectField(
                                        _.values(this.props.moneys)
                                            .filter(x => this.props.hmiMoneyMoneys.some(y => y.moneyId === x.moneyId))
                                            .map(x => ({
                                                label: x.name,
                                                value: x.moneyId
                                            })))} />
                            </div>
                        </div>
                    </div>)
                }
            </div>);
    }
}

type HmiMoneyMoneysProps = HmiMoneyMoneysOwnProps
    & ReduxForm.WrappedFieldArrayProps<Api.HmiMoneyMoneyModel>;
interface HmiMoneyMoneysOwnProps {
    moneys: { [id: number]: Api.MoneyModel };
    hmiMoneyMoneys: Array<Api.HmiMoneyMoneyModel>;
}
const moneyAvailabilityOptions = (): Array<{ label: string; value: Api.HmiMoneyMoneyModelStatusEnum }> => [
    { label: getText("SettingsAccepted"), value: "Available" },
    { label: getText("SettingsNotAccepted"), value: "Unavailable" }
];
class HmiMoneyMoneys extends React.Component<HmiMoneyMoneysProps, {}> {
    public render() {
        return (
            <div>
                {_.sortBy(this.props.fields
                    .map((member, index) => ({ member: member, index: index }))
                    , x => this.props.hmiMoneyMoneys[x.index]
                        ? (this.props.moneys[this.props.hmiMoneyMoneys[x.index].moneyId].type === "Coin"
                            ? this.props.moneys[this.props.hmiMoneyMoneys[x.index].moneyId].value
                            : (this.props.moneys[this.props.hmiMoneyMoneys[x.index].moneyId].value + 1000000))
                        : 10000000)
                    .map((data) => {
                        let money = this.props.hmiMoneyMoneys[data.index]
                            ? this.props.moneys[this.props.hmiMoneyMoneys[data.index].moneyId]
                            : undefined;
                        if (!money) {
                            return <div key={data.index} style={{ color: "ref" }}>{getText("SettingsErrorNotFound")}</div>
                        }

                        return (
                            <div key={money.moneyId} className="row" style={{padding: '1% 0' }}>
                                <div className="col-md-1" style={{lineHeight: '34px'}}>{money.type === "Bill" ? getText( "SettingsBanknote") : getText( "SettingsCoin")}</div>
                                <div className="col-md-5">
                                    <input disabled
                                        className="form-control"
                                        type="text"
                                        value={money.name} />
                                </div>
                                <div className="col-md-1" style={{lineHeight: '34px'}}>{getText( "SettingsState")}</div>
                                <div className="col-md-5">
                                    <ReduxForm.Field
                                        name={`${data.member}.status`}
                                        style={{width: '100%'}}
                                        validate={[FormField.required]}
                                        component={FormField.getSelectField(moneyAvailabilityOptions())} />
                                </div>
                            </div>
                        );
                    })}
            </div>);
    }
}


let selectors: { [id: number]: any } = {};
const getSelector = (hmiMoneyId: number): any => {
    if (!selectors[hmiMoneyId])
        selectors[hmiMoneyId] = ReduxForm.formValueSelector("hmiMoney-" + hmiMoneyId);

    return selectors[hmiMoneyId];
}

export default compose(connect((state: ApplicationState, props: HmiMoneyFormProps) => {
    let selector = getSelector(props.hmiMoneyId);

    return {
        form: "hmiMoney-" + props.hmiMoneyId,
        initialValues: state.hmiMoney.hmiMonies.find(x => x.hmiMoneyId === props.hmiMoneyId),
        moneys: state.seed.seed.moneys,
        disabled: !canUpdate(state),
        hmiMoneyMoneys: selector(state, "hmiMoneyMoneys") || []
    } as HmiMoneyFormOwnProps;
}), ReduxForm.reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true
    }))(HmiMoneyForm) as any as React.ClassicComponentClass<HmiMoneyFormExternalProps>