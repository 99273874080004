import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as Moment from 'moment';
import * as Styles from '../styles/baseStyle';
import { CSSProperties } from 'react';
import Tooltip from "./Tooltip";
import * as StockStore from "../store/Stock";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import DialogModal from './DialogModal';

import { getImagePath, calculateProductHeight, calculateProductWidth, calculateProductDepth } from '../utils/utils';

interface PlanogramGridProps {
    style?: any;
    width: number;
    height: number;
    floors: Array<{ row: number, shelvingName: string, shelvingTrayPlacementId: number }>;
    productRailId?: number;
    products: { [id: number]: Api.ProductModel | Api.ExternalProductModel };
    productGrids: Array<ProductGrid>;
    productPreview?: ProductGrid;
    productIdCreateRail?: number;
    productSensCreateRail?: Api.ProductRailModelSensEnum;
    maxVolumeSAS: number;
    onMouseEnterRail: (row: number, col: number) => void;
    onSelectCreatePosition: () => void;
    onSelectProductRail: (productRail: Api.ProductRailModel) => void;
    onRequestDeleteFloor: (id: number) => any;
    updateCreateProductId: (id: number) => any;
    stock: StockStore.StockState;
    settings: Api.PlanogramSettingsModel
    storeType: string;
}

export interface ProductGrid {
    productRail?: Api.ProductRailModel;
    startRow: number;
    startCol: number;
    endRow: number;
    endCol: number;
}

interface PlanogramGridState {
    openDialogMsg: boolean;
    msgDialog: string;
}

export default class PlanogramGrid extends React.Component<PlanogramGridProps, PlanogramGridState> {

    constructor(props: PlanogramGridProps) {
        super(props);
        this.state = {
            openDialogMsg: false,
            msgDialog: getText( "PlanogramGridDialogMsg")
        }
    }

    getRow(row: number) {
        return (this.props.height + 1 - row);
    }

    getGridStyle(row: number, col: number): CSSProperties {
        if (this.props.floors.some(x => this.getRow(x.row) === row))
            return {
                backgroundColor: "#FFF389",
                color: "#181F3A"
            };
        return {

        };
    }

    getGridStyleToolTip(row: number, col: number): CSSProperties {
        if (!this.props.floors.some(x => this.getRow(x.row) === row))
            return {
                display: "none"
            };
        return {

        };
    }
    getStatusStyle(product: Api.ProductRailModel) {
        if (product.status=="Blocked")
            return {
                color: "red",
                
            };
        return {

        };
    }
    getLabel(status: Api.ProductRailModelStatusEnum) {
        let label = '';
        switch (status) {
            case 'Active':
                label=getText( "PlanogramDisponible");
                break;
            case 'Blocked':
                label = getText( "PlanogramBlocked");
                break;
            default:
                label = getText( "PlanogramNoDisponible");
                break;
        }           
        return label;
    }
    GetIdFloor(row: number, col: number): JSX.Element | string {
        let floor = this.props.floors.find(x => this.getRow(x.row) === row);
        if (floor) {
            /*var shelvingID = this.props.stock.stockStatus.shelvingTrayPlacements.find(x => x.level === row).shelvingId;
            var index = this.props.stock.stockStatus.shelvings[shelvingID].name;*/
            return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 5, color: "#181F3A" }}>
                {floor.shelvingName}
                {this.props.settings.showDeleteShelving === true && <button className="btn btn-sm btn-secondary"
                    disabled={this.props.productGrids.some(x => x.startRow === floor.row + 1)}
                    onClick={(e) => this.props.onRequestDeleteFloor(floor.shelvingTrayPlacementId)}>
                   {getText( "PlanogramRemove")}
                </button>}
            </div>;
            /*return this.props.floors.find(x => this.getRow(x.row) === row).shelvingID.toString() + this.props.floors.find(x => this.getRow(x.row) === row).row.toString();*/
        }
        else {
            return "";
        }
            
    }
    GetIdFloorName(row: number, col: number): string {
        let floor = this.props.floors.find(x => this.getRow(x.row) === row);
        if (floor)
            return floor.shelvingName
        return "";
    }

    planogramGridClose(){
        this.setState({
            openDialogMsg: false
        });
    }

    get previewIsValid(): boolean {
        return this.props.productPreview
            && 0 < this.props.productPreview.startRow
            && 0 < this.props.productPreview.startCol
            && this.props.productPreview.endRow <= this.props.height
            && this.props.productPreview.endCol <= this.props.width
            && !this.props.floors.some(x => this.props.productPreview.startRow <= x.row
                && x.row <= this.props.productPreview.endRow)
            && !this.props.productGrids.some(x =>
                x.startRow === this.props.productPreview.startRow
                && ((x.startCol <= this.props.productPreview.startCol
                    && this.props.productPreview.startCol <= x.endCol)
            || (x.startCol <= this.props.productPreview.endCol
            && this.props.productPreview.endCol <= x.endCol)));
    }

    get extraValidations(): boolean {
        var depthCal = calculateProductDepth(this.props.storeType == "Ximiti" ? 
        this.props.products[this.props.productIdCreateRail] : this.props.products[this.props.productIdCreateRail]
        , this.props.productSensCreateRail);
        var heightCal = calculateProductHeight(this.props.storeType == "Ximiti" ? 
        this.props.products[this.props.productIdCreateRail] : this.props.products[this.props.productIdCreateRail]
        , this.props.productSensCreateRail);
        var widthCal = calculateProductWidth(this.props.storeType == "Ximiti" ? 
        this.props.products[this.props.productIdCreateRail] : this.props.products[this.props.productIdCreateRail]
        , this.props.productSensCreateRail);

        if(heightCal < 8 || heightCal > 350)
            return false;

        if(widthCal < 8 || widthCal > 280) //19 => 8 06/01/23
            return false;

        if(depthCal < 40 || depthCal > 350)
            return false;
        
        if(Math.sqrt(Math.pow(widthCal,2) + Math.pow(depthCal,2)) > 350)
        {
            this.setState({
                openDialogMsg: true,
                msgDialog: getText( "PlanogramGridDialogMsgCtr8")
            });
            return true;
        }

        if((heightCal / depthCal) >= 4 || (heightCal / widthCal) >= 4)
            this.setState({
                openDialogMsg: true,
                msgDialog: getText( "PlanogramGridDialogMsgCtr8")
            });
        
        if((widthCal * depthCal) > this.props.maxVolumeSAS)
            return false;

        return true;
    }

    handleSelectPreview(): void {
        if (this.previewIsValid) {
            if(this.extraValidations)
                this.props.onSelectCreatePosition();
            else 
                this.setState({
                    openDialogMsg: true,
                    msgDialog: getText( "PlanogramGridDialogMsg")
                });
        }
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div style={{
                height: "100%",
                minWidth: 520, //671
                display: "grid",
                fontSize: 12,
                gridTemplateRows: "repeat(" + (this.props.height + 1) + ", 1fr)",
                gridTemplateColumns: "repeat(" + (this.props.width + 1) + ", 1fr)",
                ...this.props.style
            }}>
                {Array.apply(null, { length: this.props.height + 1 })
                    .map(Number.call, Number)
                    .map(row => Array.apply(null, { length: this.props.width + 1 })
                        .map(Number.call, Number)
                        .map(col => <Tooltip key={`${row}-${col}`} placement={"topRight"}
                            style={{ ...this.getGridStyleToolTip(row, col) }}
                            overlay={<div>{this.GetIdFloor(row, col)}</div>}><div key={`${row}-${col}`}
                            style={{
                            position: "relative",
                            whiteSpace: "nowrap",
                            maxWidth: 40,
                            gridRow: (row + 1) + " / " + (row + 2),
                            gridColumn: (col + 1) + " / " + (col + 2),
                            border: this.isDarkMode() ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(59, 67, 112, 0.15)",
                            ...this.getGridStyle(row, col)
                        }}
                            onMouseEnter={(e) => {
                                let floor = _.sortBy(this.props.floors, x => -x.row)
                                    .find(x => x.row < this.getRow(row));                               
                                if (floor) {
                                    this.props.onMouseEnterRail(floor.row + 1, col);
                                }
                            }}
                            onClick={(e) => {
                                this.handleSelectPreview();
                            }}>
                            {col === 0
                                && row !== 0
                                && this.getRow(row)
                            }
                            {row === 0
                                && col !== 0
                                && <div style={{
                                    display: "flex", flexDirection: "row",
                                    alignItems: "center", height: "100%"
                                }}>                            
                                    <div style={{ textAlign: "center", width: "100%"}}>{col}</div>                                  
                                </div>}
                            {col === 10
                                && row !== 0
                                && this.props.floors.some(x => this.getRow(x.row) === row)
                                && <span style={{ position: "absolute", zIndex: 99, color: "#181F3A" }}>{getText( "PlanogramFloor") + " " + this.GetIdFloorName(row, col)}</span>}
                        </div></Tooltip>))}
                {_.sortBy(this.props.productGrids, x => (x.startCol * 1000) + x.startRow).map((productGrid, index) =>
                    <Tooltip key={productGrid.productRail.productRailId} placement={"topRight"} overlay={
                    <div>
                        <div> {this.props.storeType == "Ximiti" ? this.props.products[productGrid.productRail.productId].name : this.props.products[productGrid.productRail.externalProductId].name} <br /><span>{getText( "PlanogramStock")}: </span> {productGrid.productRail.productStocks.length}
                            <br /><span>{getText( "StockState")}: </span> <span style={{ ...this.getStatusStyle(productGrid.productRail) }}>{this.getLabel(productGrid.productRail.status)}</span> <br />
                            <span> {getText( "PlanogramMaxQty")}: </span> {Math.floor(
                                this.props.stock.stockStatus.shelvingTrays[productGrid.productRail.shelvingTrayId].depth
                                / calculateProductDepth(this.props.storeType == "Ximiti" ? 
                                this.props.products[productGrid.productRail.productId] : this.props.products[productGrid.productRail.externalProductId]
                                , productGrid.productRail.sens))}</div>                      
                    </div>}>
                        <div key={productGrid.productRail.productRailId}
                            style={{
                                gridRow: ((this.props.height + 1) - productGrid.endRow + 1) + " / " + ((this.props.height + 1) - productGrid.startRow + 2),
                                gridColumn: (productGrid.startCol + 1) + " / " + (productGrid.endCol + 2),
                                backgroundColor: Styles.colorsPalette[index % Styles.colorsPalette.length],
                                opacity: 0.75,
                                cursor: "pointer",
                                border: this.props.productRailId === productGrid.productRail.productRailId
                                    ? "4px solid #3B4370" : ""
                            }}
                            className={productGrid.productRail.status=="Blocked"?'blink-error':''}
                            onClick={(e) => {
                                if(this.props.storeType == "Ximiti"){
                                    this.props.updateCreateProductId(productGrid.productRail.productId)
                                }
                                else{
                                    this.props.updateCreateProductId(productGrid.productRail.externalProductId)
                                }
                                this.props.onSelectProductRail(productGrid.productRail);
                                e.preventDefault();
                            }}>
                        </div>
                    </Tooltip>)}
                {this.props.productPreview
                    && <div style={{
                        gridRow: ((this.props.height + 1) - this.props.productPreview.endRow + 1) + " / " + ((this.props.height + 1) - this.props.productPreview.startRow + 2),
                        gridColumn: (this.props.productPreview.startCol + 1) + " / " + (this.props.productPreview.endCol + 2),
                        backgroundColor: this.previewIsValid
                            ? "#000000"
                            : "red",
                        opacity: 0.9,
                        pointerEvents: "none"
                    }}>
                    </div>}
                <DialogModal isOpen={this.state.openDialogMsg}
                    contentStyle={{ 
                        background: this.isDarkMode() ? "#181F3A" : "#FFFFFF", 
                        color: this.isDarkMode() ? "#FFFFFF" : "#3B4370", 
                        border: this.isDarkMode() ? "1px solid #181F3A" : "1px solid rgb(204, 204, 204);",
                        width: 650
                    }}
                    title={getText( "PlanogramGridDialogTitle")}
                    contentLabel={getText( "PlanogramGridDialogTitle")}
                    onRequestClose={() => this.planogramGridClose()}>
                    <div>
                        <h4 style={{margin: "40 30", overflowWrap: 'break-word'}}>
                            {this.state.msgDialog}
                        </h4>
                        <button className="btn btn-secondary btn-sm"
                            type={"button"}
                            style={{
                                width: '100%',
                                height: 40,
                                fontSize: 15
                            }}
                            onClick={() => this.planogramGridClose()}> {getText( "MaintenanceCorrect")} </button>
                    </div>
                </DialogModal>
            </div>
        );
    };
}