import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as HistoryStore from "../store/History";
import { getText } from '../utils/texts';
import { CSVLink } from "react-csv";
import {CsvData, generateCsvFromCashRegister} from "../utils/csv";
import { ExportToCsv } from 'export-to-csv';
import Moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const colStyle: React.CSSProperties = {
    flex: 1,
    maxWidth: 300,
    textAlign: "center",
    padding: "2px 0",
    color: "#181F3A",
    minHeight: 18,
    minWidth: 45
};
const headStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "#FEFF81",
    minHeight: 18,
    minWidth: 45,
    overflow:"hidden",
    textOverflow: "ellipsis"
};
const gapStyle: React.CSSProperties = {
    flex: "0 0 20px",
    textAlign: "center",
    color: "#181F3A",
    minHeight: 18
};
const bigGapStyle: React.CSSProperties = {
    flex: "0 0 50px",
    minHeight: 18
};

const rStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "lightpink",
    minWidth: 45
};

const ccStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "#FCE0C8"
};
const nfcStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "#808281"
};
const smartStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "#2E313A",
    color: "white"
};
const totalStyle: React.CSSProperties = {
    ...colStyle,
    backgroundColor: "#F58300",
};
interface CashRegisterOperationsProps {
    hoppers: Array<Api.HmiMoneyHopperModel>;
    seed: Api.SeedMngModel;
    dayStoreData: Api.DayStoreDataModel;
    dayMoneyStocks?: Array<Api.DayMoneyStock>;
    resetDisabled?: boolean;
    isToday?: boolean;
    onResetStoredMoneyClick: (e: any) => void;
}
const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

export default class CashRegisterOperations extends React.Component<CashRegisterOperationsProps, {}> {

    get moneys(): Array<Api.MoneyModel> {
        return _.sortBy(_.values(this.props.seed.moneys), x => x.value);
    }

    get todayOrderMoneyTraffics(): Array<Api.StoreMoneyTrafficModel> {
        return HistoryStore.todayOrderMoneyTraffics(this.props.dayStoreData);
    }

    get todayMoneyTraffics(): Array<Api.StoreMoneyTrafficModel> {
        return HistoryStore.todayMoneyTraffics(this.props.dayStoreData);
    }

    get todayMoneyResets(): Array<Api.StoreHmiMoneyResetModel> {
        return this.props.dayStoreData
            ? this.props.dayStoreData.storeHmiMoneyResets
            : [];
    }

    get todayMoneyStocks(): Array<Api.StoreDayMoneyStockModel> {
        if (this.props.dayMoneyStocks) {
            return this.props.dayMoneyStocks;
        }

        //This comes from datasnapshoot: data saved every 24 hours from a worker task which might not happen on dev env
        if (this.props.dayStoreData
            && this.props.dayStoreData.storeDayDataSnapshot
            && this.props.dayStoreData.storeDayDataSnapshot.dayMoneyStocks
            && this.props.dayStoreData.storeDayDataSnapshot.dayMoneyStocks.length) {
            return this.props.dayStoreData.storeDayDataSnapshot.dayMoneyStocks;
        }

        return [];
    }

    get todayPayments(): Array<Api.StoreOrderPayment> {
        return HistoryStore.todayPayments(this.props.dayStoreData);
    }

    getCoinStyle = (index: number): React.CSSProperties => {
        if (this.props.hoppers) {
            let arr = [];
            let obj = this.props.hoppers;
            Object.keys(obj).map(function (key) {
                arr.push(obj[key])
                return arr;
            });
            if (arr.some(x => x.moneyId === index)) {
                return {
                    ...colStyle,
                    minWidth: 45,
                    backgroundColor: "#C5FFBF",
                }
            } else {
                return {
                    ...colStyle,
                    minWidth: 45,
                    backgroundColor: "#C2FEFE",
                }
            }
        } else {
            return {
                ...colStyle,
                minWidth: 45,
                backgroundColor: "#C2FEFE",
            }
        }
    };

    getBillStyle = (moneyId: number): React.CSSProperties => {
        if(this.isRecycled(moneyId))
            return {
                ...colStyle,
                minWidth: 45,
                backgroundColor: "#9F71F8",
            };

        return {
            ...colStyle,
            minWidth: 45,
            backgroundColor: "#D2C3FE",
        };
    };

    isRecycled = (moneyId: number): Boolean => {
        return this.props.hoppers
                .some(x => x.moneyId === moneyId)
            || this.todayMoneyStocks.some(x => x.moneyId === moneyId && x.hmiMoneyHopperId != undefined);
    };

    getMoneyColumnStyle(money: Api.MoneyModel, index: number): React.CSSProperties {
        if (money.type === "Coin") {
            return this.getCoinStyle(money.moneyId);
        } else {
            return this.getBillStyle(money.moneyId);
        }
    }

    getRecycledColumnStyle(money: Api.MoneyModel, index: number): React.CSSProperties {
        if (this.props.hoppers) {
            let arr = [];
            let obj = this.props.hoppers;
            Object.keys(obj).map(function (key) {
                arr.push(obj[key])
                return arr;
            });
            if (arr.some(x => x.moneyId === money.moneyId)) {
                return this.getMoneyColumnStyle(money, index);
            } else {
                return colStyle;
            }
        } else {
            return colStyle;
        }
    }

    getNonRecycledColumnStyle(money: Api.MoneyModel, index: number): React.CSSProperties {
        if (this.props.hoppers) {
            let arr = [];
            let obj = this.props.hoppers;
            Object.keys(obj).map(function (key) {
                arr.push(obj[key])
                return arr;
            });
            if (arr.some(x => x.moneyId === money.moneyId)) {
                return colStyle;
            } else {
                return this.getMoneyColumnStyle(money, index);
            }
        } else {
            return colStyle;
        }
    }

    getHopperQuantity(money: Api.MoneyModel): number {
        return _.sum(this.todayMoneyStocks
            .filter(x => x.moneyId == money.moneyId && x.hmiMoneyHopperId)
            .map(x => x.quantity))
    }
    getCoinQuantity(money: Api.MoneyModel): number {
        return _.sum(this.todayMoneyStocks
            .filter(x => x.moneyId == money.moneyId && !x.hmiMoneyHopperId)
            .map(x => x.quantity))
    }
    getBillRecycledQuantity(money: Api.MoneyModel): number {
        return _.sum(this.todayMoneyStocks
            .filter(x => x.moneyId == money.moneyId && x.hmiMoneyHopperId != undefined)
            .map(x => x.quantity))
    }
    getNotRecylceRAZValue(moneyId: number): number {
        return (!this.isRecycled(moneyId) ?
                _.sum(this.todayMoneyResets.map(x => x.hmiMoneyResetMoneys).reduce((a, b) => a.concat(b), []).filter(x => x.moneyId === moneyId).map(x => x.quantity)) : 0
        )
    }
    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    recycledMoneyQuantityInRegister(money: Api.MoneyModel): number {
        if(this.props.isToday){
            return (this.isRecycled(money.moneyId)
                ? this.props.hoppers.find(x => x.moneyId === money.moneyId).quantity
                : this.getCoinQuantity(money))
        }
        else {
            return (this.isRecycled(money.moneyId)
                ? this.getBillRecycledQuantity(money)
                : this.getCoinQuantity(money))
        }
    }
    
    get allCoinsAndRecycledBillsTotal(): number {
        let total = 0;
        this.moneys.forEach((money, index) => { //COINS, RECYCLED COINS AND RECYCLED BILLS
            if (money.type === "Coin" || (money.type === "Bill" && this.isRecycled(money.moneyId))) {
                total = total + (money.value * (this.isRecycled(money.moneyId)
                    ? this.recycledMoneyQuantityInRegister(money)
                    : this.getCoinQuantity(money)));
            }
        });
        return total;
    }

    get billsTotal(): number {
        let total = 0;
        this.moneys.map((money, index) => { //BILLS 
            if (money.type === "Bill") {
                total = total + money.value * (this.getCoinQuantity(money));
            }
        });
        return total;
    }

    get csvData(): CsvData {
        let headers = [];
        let data: Array<Array<string>> = [];

        headers = [getText("HistoCashRegisterMoney"), getText("HistoCashRegisterType"), getText("HistoCashRegisterTotal")];

        let dataToPush: Array<string>;
        this.moneys.forEach((money, index) => { //COINS, RECYCLED COINS AND RECYCLED BILLS
            if (!this.isRecycled(money.moneyId) || money.type === "Bill") {
                dataToPush = []
                dataToPush[0] = money.type;
                dataToPush[1] = money.value.toFixed(2) + ' €';
                dataToPush[2] = this.getCoinQuantity(money).toFixed(0);
                data.push(dataToPush);
            }
        });

        dataToPush = [] //TOTAL
        dataToPush[0] = "";
        dataToPush[1] = getText("HistoCashRegisterTotalMoney");
        dataToPush[2] = _.sum(data.map(x => 2 < x.length ? parseFloat(x[1].replace(" €", "")) * parseInt(x[2]) : 0)).toFixed(2) + ' €';
        data.push(dataToPush);

        return {
            headers: headers,
            data: data
        };
    }

    pdfData(data: Array<Array<string>>) {
        let pdf = new jsPDF();
        autoTable(pdf, {head: [[getText("HistoCashRegisterMoney"),getText("HistoCashRegisterType"),getText("HistoCashRegisterTotal")]], body: data});
        pdf.save(`${getText("NavMenuCashRegister")}-${getText("CashRegisterReset")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    csvDataCashRegister(): CsvData {
        if(!document.getElementById("cashReg-container"))
            return {
                data: [[getText("HistoCashRegisterNoValues")]],
                headers: []
            };
        
        return generateCsvFromCashRegister(document.getElementById("cashReg-container"), 2);
    }

    pdfDataCashRegister(data: Array<Array<string>>) {
        let pdf = new jsPDF();
        autoTable(pdf, {body: data});
        pdf.save(`${getText("HistoTitle")}-${getText("HistoTabCashRegister")}${getText("CashRegisterInCashRegister")}-${Moment().format('YYYYMMDDHHmm')}.pdf`);
    }

    get data(): Api.StoreOrderModel[] {
        return this.props.dayStoreData && this.props.dayStoreData.storeOrders 
            ? _.sortBy(this.props.dayStoreData.storeOrders
                .filter(x => x.status === "Delivered"
                    && x.storeOrderPayments.length
                    && (x.storeOrderPayments.some(y => (y.collectiondDate ? true : false)))), x => x.orderDate)
            : []
    }

    get vouchersDiscounts(){
        let totalDiscount = _.sum(_.map(this.data, x => {
            var collectedFromOrder = HistoryStore.totalToCollectFromOrder(x);
            return collectedFromOrder != 0 ? HistoryStore.vouchersDiscounts(x, collectedFromOrder) : 0;
        }));        
        
        return (totalDiscount).toFixed(2) + " \u20AC"  || "0"
    }

    public render() {
        return  <div style={{display:"flex"}}>
            <div className="card" style={{
            ...Styles.cardStyle,
            borderRadius: '4px 4px 0px 0px', 
            padding: '10px 10px 10px',
            margin: '1% 1% 0 1%',
            width: '65%',
            fontSize: 13}}>
                <h4 style={{paddingRight: '2%'}}>
                    <strong>{getText( "CashRegisterOperations")}</strong> 
                </h4>
                <div style={{ color: "#181F3A", display: "flex", flexDirection: "column", alignItems: "stretch" }} className="cashRegisterContainer">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                        {/* <div style={{ ...colStyle }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterType")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterDay")}</div>
                        <div style={{ ...gapStyle, color: "red", fontSize: 15 }}>=</div>
                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterIntro")}</div>
                        <div style={{ ...gapStyle, color: "red", fontSize: 15 }}>-</div>
                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterRendering")}</div>

                        <div style={{ ...bigGapStyle }}></div>

                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterReset")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterEmptying")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...headStyle }}>{getText("CashRegisterLoading")}</div>
                    </div>
                    {this.moneys.map((money, index) => { //JUST COINS AND RECYCLED BILLS
                        if (money.type === "Coin" || this.isRecycled(money.moneyId)) {
                            return (
                                <div key={money.moneyId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                                    {/* <div style={{ ...colStyle }}>
                                        {money === this.moneys.find(x => x.type === "Coin") && <span style={{ color: this.isDarkMode() ? "#FFFFFF" : "#3B4370" }}>{getText("CashRegisterCoins")}</span>}
                                        {money.type === "Bill" && <span style={{ color: this.isDarkMode() ? "#FFFFFF" : "#3B4370" }}>{getText("CashRegisterTickets")}</span>}
                                    </div> */}
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue" style={{ ...this.getMoneyColumnStyle(money, index) }}>
                                        {money.value.toFixed(2)} €
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue" style={{ ...this.getMoneyColumnStyle(money, index) }}>
                                        {_.sum(this.todayOrderMoneyTraffics
                                            .filter(x => x.moneyId === money.moneyId
                                                && (money.type === "Coin" || x.hmiMoneyHopperId)
                                                && _.includes(["PaymentIn", "PaymentOut"], x.type))
                                            .map(x => x.type === "PaymentIn"
                                                ? x.quantity
                                                : -x.quantity))}
                                    </div>
                                    <div style={{ ...gapStyle, color: "red" }}></div>
                                    <div className="cashRegisterValue" style={{ ...this.getMoneyColumnStyle(money, index) }}>
                                        {_.sum(this.todayOrderMoneyTraffics
                                            .filter(x => x.moneyId === money.moneyId && x.type === "PaymentIn"
                                                && (money.type === "Coin" || x.hmiMoneyHopperId))
                                            .map(x => x.quantity))}
                                    </div>
                                    <div style={{ ...gapStyle, color: "red" }}></div>
                                    <div className="cashRegisterValue" style={{ ...this.getMoneyColumnStyle(money, index) }}>
                                        {_.sum(this.todayOrderMoneyTraffics
                                            .filter(x => x.moneyId === money.moneyId && x.type === "PaymentOut"
                                                && (money.type === "Coin" || x.hmiMoneyHopperId))
                                            .map(x => x.quantity))}
                                    </div>

                                    <div style={{ ...bigGapStyle }}></div>

                                    <div className="cashRegisterValue" style={{ ...this.getNonRecycledColumnStyle(money, index) }}>
                                        {!this.isRecycled(money.moneyId) ? this.getNotRecylceRAZValue(money.moneyId) : ''}
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue" style={{ ...this.getRecycledColumnStyle(money, index) }}>
                                        {this.todayMoneyTraffics && this.isRecycled(money.moneyId) ? _.sum(this.todayMoneyTraffics
                                            .filter(x => x.type === "ManagerOut" && x.moneyId === money.moneyId)
                                            .map(x => x.quantity)) || 0 : ''}
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue" style={{ ...this.getRecycledColumnStyle(money, index) }}>
                                        {this.todayMoneyTraffics && this.isRecycled(money.moneyId) ? _.sum(this.todayMoneyTraffics
                                            .filter(x => x.type === "ManagerIn" && x.moneyId === money.moneyId)
                                            .map(x => x.quantity)) || 0 : ''}
                                    </div>
                                </div>
                            );
                        }
                    })}
                    {this.moneys.map((money, index) => { //JUST ALL BILLS
                        if (money.type === "Bill") {
                            return (
                                <div key={money.moneyId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                                    {/* <div style={{ ...colStyle }}>
                                        {money === this.moneys.find(x => x.type === "Coin") && <span></span>}
                                        {money === this.moneys.find(x => x.type === "Bill") && <span></span>}
                                    </div> */}
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue" style={{ ...colStyle, backgroundColor: "#D2C3FE" }}>
                                        {money.value.toFixed(2)} €
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue" style={{ ...colStyle, backgroundColor: "#D2C3FE" }}>
                                        {_.sum(this.todayOrderMoneyTraffics
                                            .filter(x => x.moneyId === money.moneyId && x.type === "PaymentIn" && !x.hmiMoneyHopperId)
                                            .map(x => x.type === "PaymentIn"
                                                ? x.quantity
                                                : -x.quantity)) || 0}{ /*Seems like a mistake but actually there shouldnt be any out here as it is not recycled*/}
                                    </div>
                                    <div style={{ ...gapStyle, color: "red" }}></div>
                                    <div className="cashRegisterValue" style={{ ...colStyle, backgroundColor: "#D2C3FE" }}>
                                        {_.sum(this.todayOrderMoneyTraffics
                                            .filter(x => x.moneyId === money.moneyId && x.type === "PaymentIn" && !x.hmiMoneyHopperId)
                                            .map(x => x.quantity)) || 0}
                                    </div>
                                    <div style={{ ...gapStyle, color: "red" }}></div>
                                    <div className="cashRegisterValue" style={{ ...colStyle, backgroundColor: "#D2C3FE" }}>
                                        {_.sum(this.todayOrderMoneyTraffics
                                            .filter(x => x.moneyId === money.moneyId && x.type === "PaymentOut" && !x.hmiMoneyHopperId)
                                            .map(x => x.quantity)) || 0}
                                    </div>

                                    <div style={{ ...bigGapStyle }}></div>

                                    <div className="cashRegisterValue" style={{ ...colStyle, backgroundColor: "#D2C3FE" }}>
                                        {_.sum(this.todayMoneyResets
                                            .map(x => x.hmiMoneyResetMoneys)
                                            .reduce((a, b) => a.concat(b), [])
                                            .filter(x => x.moneyId === money.moneyId)
                                            .map(x => x.quantity)) || 0}
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div style={{ ...colStyle }}></div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div style={{ ...colStyle }}></div>
                                </div>
                            );
                        }
                    })}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                        {/* <div style={{ ...colStyle }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...rStyle, overflow:"hidden", textOverflow: "ellipsis"}}>{getText("CashRegisterDiscounts")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...rStyle }}>
                            {
                               this.vouchersDiscounts
                            }
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div style={{ ...rStyle }}>
                            {
                               this.vouchersDiscounts
                            }
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div style={{ ...colStyle }}></div>

                        <div style={{ ...bigGapStyle }}></div>

                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                    </div>
                    <div style={{ height: 40, display: "flex", flexDirection: "row-reverse", alignItems: "flex-end" }}>
     
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                        {/* <div style={{ ...colStyle }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...ccStyle }}>{getText("CashRegisterCreditCard")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...ccStyle }}>
                            {_.sum(this.todayPayments
                                .filter(x => x.paymentType === "Card"
                                    && x.cardType === "Normal"
                                    && x.collection)
                                .map(x => x.collection)).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div className="cashRegisterValue" style={{ ...ccStyle }}>
                            {_.sum(this.todayPayments
                                .filter(x => x.paymentType === "Card"
                                    && x.cardType === "Normal"
                                    && x.collection)
                                .map(x => x.collection)).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div style={{ ...colStyle }}></div>

                        <div style={{ ...bigGapStyle }}></div>

                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                        {/* <div style={{ ...colStyle }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...nfcStyle }}>{getText("CashRegisterNFC")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...nfcStyle }}>
                            {_.sum(this.todayPayments
                                .filter(x => x.paymentType === "Card"
                                    && x.cardType === "NoContact"
                                    && x.collection)
                                .map(x => x.collection)).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div className="cashRegisterValue" style={{ ...nfcStyle }}>
                            {_.sum(this.todayPayments
                                .filter(x => x.paymentType === "Card"
                                    && x.cardType === "NoContact"
                                    && x.collection)
                                .map(x => x.collection)).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div style={{ ...colStyle }}></div>

                        <div style={{ ...bigGapStyle }}></div>

                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}>

                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                        {/* <div style={{ ...colStyle }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...smartStyle, overflow:"hidden", textOverflow: "ellipsis" }}>{getText("CashRegisterSmartphone")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...smartStyle }}>
                            {_.sum(this.todayPayments
                                .filter(x => x.paymentType === "SmartPhone"
                                    && x.collection)
                                .map(x => x.collection)).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div className="cashRegisterValue" style={{ ...smartStyle }}>
                            {_.sum(this.todayPayments
                                .filter(x => x.paymentType === "SmartPhone"
                                    && x.collection)
                                .map(x => x.collection)).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div style={{ ...colStyle }}></div>

                        <div style={{ ...bigGapStyle }}></div>

                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}>

                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow">
                        {/* <div style={{ ...colStyle }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div style={{ ...colStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue" style={{ ...totalStyle }}>
                            {/*TOTAL REGISTER*/}
                            {(_.sum(this.todayOrderMoneyTraffics
                                    .filter(x => _.includes(["PaymentIn", "PaymentOut"], x.type))
                                    .map(x => (x.type === "PaymentIn" ? x.quantity : -x.quantity)
                                        * this.props.seed.moneys[x.moneyId].value))
                                + _.sum(this.todayPayments
                                    .filter(x => x.paymentType !== "Cash" && x.collection)
                                    .map(x => x.collection))).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red", fontSize: 15 }}>=</div>
                        <div className="cashRegisterValue" style={{ ...totalStyle }}>
                            {(_.sum(this.todayOrderMoneyTraffics
                                    .filter(x => x.type === "PaymentIn")
                                    .map(x => x.quantity * this.props.seed.moneys[x.moneyId].value))
                                + _.sum(this.todayPayments
                                    .filter(x => x.paymentType !== "Cash" && x.collection)
                                    .map(x => x.collection))).toFixed(2)} €
                        </div>
                        <div style={{ ...gapStyle, color: "red", fontSize: 15 }}>-</div>
                        <div className="cashRegisterValue" style={{ ...totalStyle }}>
                            {(_.sum(this.todayOrderMoneyTraffics
                                .filter(x => x.type === "PaymentOut")
                                .map(x => x.quantity * this.props.seed.moneys[x.moneyId].value))).toFixed(2)} €
                        </div>

                        <div style={{ ...bigGapStyle }}></div>

                        <div className="cashRegisterValue" style={{ ...totalStyle }}>
                            {/*TOTAL RAZ, VIDAGE AND CHARGEMENT*/}
                            {_.sum(this.todayMoneyResets
                                .map(x => x.hmiMoneyResetMoneys)
                                .reduce((a, b) => a.concat(b), [])
                                .filter(x => x.moneyId === x.moneyId)
                                .map(x => x.quantity * this.props.seed.moneys[x.moneyId].value)).toFixed(2) || 0.00} €
                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div className="cashRegisterValue" style={{ ...totalStyle }}>
                            {this.todayMoneyTraffics && _.sum(this.todayMoneyTraffics
                                .filter(x => x.type === "ManagerOut")
                                .map(x => x.quantity * this.props.seed.moneys[x.moneyId].value)).toFixed(2) || 0.00} €

                        </div>
                        <div style={{ ...gapStyle, color: "red" }}></div>
                        <div className="cashRegisterValue" style={{ ...totalStyle }}>
                            {this.todayMoneyTraffics && _.sum(this.todayMoneyTraffics
                                .filter(x => x.type === "ManagerIn")
                                .map(x => x.quantity * this.props.seed.moneys[x.moneyId].value)).toFixed(2) || 0.00} €
                        </div>
                    </div>
                </div>
            </div>
            <div  id={"cashReg-container"} className="card" style={{
                    ...Styles.cardStyle,
                    borderRadius: '4px 4px 0px 0px', 
                    padding: '10px 10px 10px',
                    margin: '1% 1% 0 1%',
                    width: '35%',
                    fontSize: 13}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 10}}>
                    <h4 style={{paddingRight: '2%'}}>
                        <strong> {getText( "CashRegisterInCashRegister")}</strong>
                    </h4>
                    { this.props.isToday != true &&
                    <button type={"button"}
                        className="btn btn-primary"
                        style={{width: '40%', height: 40, overflow:"hidden"}}
                        onClick={(e) => {
                            const options = {
                                fieldSeparator: ';',
                                quoteStrings: '"',
                                decimalSeparator: ',',
                                showLabels: false,
                                showTitle: false,
                                filename: `${getText("HistoTitle")}-${getText( "HistoTabCashRegister")}${getText( "CashRegisterInCashRegister")}-${Moment().format('YYYYMMDDHHmm')}`,
                                useTextFile: false,
                                useBom: true,
                                useKeysAsHeaders: false,
                                headers: this.csvDataCashRegister().headers,
                            };
                            const csvExporter = new ExportToCsv(options);
                            csvExporter.generateCsv(this.csvDataCashRegister().data);  
                            this.pdfDataCashRegister(this.csvDataCashRegister().data);
                        }}>
                            {getText( "HistoExport")}
                            <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                    </button>}
                </div>
                <div style={{ color: "#181F3A" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow2">
                        {/* <div style={{ ...colStyle, maxWidth: 80 }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue2" style={{ ...headStyle, maxWidth: 120 }}>{getText("CashRegisterType")}</div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue2" style={{ ...headStyle, maxWidth: 120 }}>{getText("CashRegisterTotal2")}</div>
                        <div style={{ ...colStyle }}></div>
                    </div>
                    {/*_.sortBy(this.todayMoneyStocks
                        .filter(x => this.props.seed.moneys[x.moneyId].type === "Bill"
                            && x.hmiMoneyHopperId), x => this.props.seed.moneys[x.moneyId].value)
                        .map((todayMoneyStock, index) => {
                            let money = this.props.seed.moneys[todayMoneyStock.moneyId];
                            return <div key={todayMoneyStock.moneyId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow2">
                                <div style={{ ...colStyle, maxWidth: 80 }}>
                                    <span style={{ color: this.isDarkMode() ? "#FFFFFF" : "#3B4370" }}>{getText("CashRegisterTickets")}</span>
                                </div>
                                <div style={{ ...gapStyle }}></div>
                                <div style={{
                                    ...colStyle, maxWidth: 120,
                                    backgroundColor: "#9F71F8",
                                }}>
                                    {money.value.toFixed(2)} €
                                </div>
                                <div style={{ ...gapStyle }}></div>
                                <div style={{
                                    ...colStyle, maxWidth: 120,
                                    backgroundColor: "#9F71F8",
                                }}>
                                    {todayMoneyStock.quantity}
                                </div>
                                <div style={{ ...colStyle }}></div>
                            </div>;
                        })*/}
                    {this.moneys.map((money, index) => { //COINS, RECYCLED COINS AND RECYCLED BILLS
                        if (money.type === "Coin" || (money.type === "Bill" && this.isRecycled(money.moneyId))) {
                            return (
                                <div key={money.moneyId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow2">
                                    {/* <div style={{ ...colStyle, maxWidth: 80 }}>
                                        {money === this.moneys.find(x => x.type === "Coin") && <span style={{ color: this.isDarkMode() ? "#FFFFFF" : "#3B4370" }}>{getText("CashRegisterCoins")}</span>}
                                        {money.type === "Bill" && <span style={{ color: this.isDarkMode() ? "#FFFFFF" : "#3B4370" }}>{getText("CashRegisterTickets")}</span>}
                                    </div> */}
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue2" style={{ ...this.getMoneyColumnStyle(money, index), maxWidth: 120 }}>
                                        {money.value.toFixed(2)} €
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue2" style={{ ...this.getMoneyColumnStyle(money, index), maxWidth: 120 }}>
                                        {this.recycledMoneyQuantityInRegister(money)}
                                    </div>
                                    <div style={{ ...colStyle }}></div>
                                </div>
                            );
                        }
                    })}
                    {this.moneys.map((money, index) => { //BILLS 
                        if (money.type === "Bill") {
                            return (
                                <div key={money.moneyId} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className="cashRegisterRow2">
                                    {/* <div style={{ ...colStyle, maxWidth: 80 }}> */}
                                        {/*money === this.moneys.find(x => x.type === "Bill") && <span style={{ color: this.isDarkMode() ? "#FFFFFF" : "#3B4370" }}>{getText("CashRegisterTickets")}</span>*/}
                                    {/* </div> */}
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue2" style={{ ...colStyle, backgroundColor: "#D2C3FE", maxWidth: 120 }}>
                                        {money.value.toFixed(2)} €
                                    </div>
                                    <div style={{ ...gapStyle }}></div>
                                    <div className="cashRegisterValue2" style={{ ...colStyle, backgroundColor: "#D2C3FE", maxWidth: 120 }}>
                                        {this.getCoinQuantity(money)}
                                    </div>
                                    <div style={{ ...colStyle }}></div>
                                </div>
                            );
                        }
                    })}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 40, marginBottom:20 }} className="cashRegisterRow2">
                        {/* <div style={{ ...colStyle, maxWidth: 80 }}></div> */}
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue2" style={{ ...headStyle, maxWidth: 120 }}>
                            {getText("CashRegisterTotal")}
                        </div>
                        <div style={{ ...gapStyle }}></div>
                        <div className="cashRegisterValue2" style={{ ...totalStyle, maxWidth: 120 }}>
                            {(this.allCoinsAndRecycledBillsTotal + this.billsTotal).toFixed(2)} €
                        </div>
                        {/*<div style={{ ...totalStyle, maxWidth: 120 }}>
                            {(_.sum(this.todayMoneyStocks
                                .map(x => {
                                    let money = this.props.seed.moneys[x.moneyId];
                                    let quantity = 0;
                                    if (money.type === "Coin" || this.isRecycled(money.moneyId)) {
                                        quantity = this.isRecycled(money.moneyId) ? this.getHopperQuantity(money) : this.getCoinQuantity(money);
                                    }
                                    else if (money.type === "Bill") {
                                        quantity = this.todayMoneyStocks.some(x => x.moneyId === money.moneyId && !x.hmiMoneyHopperId)
                                            ? this.todayMoneyStocks.find(x => x.moneyId === money.moneyId && !x.hmiMoneyHopperId).quantity
                                            : 0

                                    }
                                    return quantity * this.props.seed.moneys[x.moneyId].value;
                                }))).toFixed(2)} €
                        </div>*/}
                        <div style={{ ...colStyle }}></div>
                    </div>
                    <div style={{ height: 40, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ ...gapStyle }}></div>
                        {!this.props.resetDisabled && <div style={{ ...colStyle, flex: '0 1 0%' }}>
                            <CSVLink data={this.csvData.data} headers={this.csvData.headers} separator={";"} filename={`${getText("NavMenuCashRegister")}-${getText("CashRegisterReset")}-${Moment().format('YYYYMMDDHHmm')}`}>
                                <button style={{ height: 40, fontSize: "15px" }} className="btn btn-primary"
                                        onClick={(e) =>{
                                            this.props.onResetStoredMoneyClick(e);
                                            this.pdfData(this.csvData.data);
                                        }}
                                        disabled={this.props.resetDisabled}>
                                    {getText("CashRegisterResetCR")}
                                </button>
                            </CSVLink>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    }
}