import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { SubmissionError } from 'redux-form';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from '../utils/utils';
import { push } from "connected-react-router";
import { isAuthenticated } from "../security/UserIsAuthenticated";
import { closeConnection, connectUserHub } from "../signalR/connectedUsers";
import { writeToken } from "../security/tokenManager";

//MNG
export interface AccountState {
    isLoading: boolean;
    isLoaded: boolean;
    requestTime?: number;
    token: string;
    currentUser: Api.CurrentUserManagerModel;
    LoginState: LoginState;
    languageId?: number;
    logout: {
        isLoading: boolean;
        requestTime?: number;
    };
    lastAction?: number;
}

export interface LoginState {
    isLoading: boolean;
    requestTime?: number;
}

export interface LoadToken { type: 'LOAD_TOKEN'; payload: { token: string; } }

interface RequestLogin {
    type: 'REQUEST_LOGIN';
    payload: { requestTime: number; }
}
interface ReceiveLogin {
    type: 'RECEIVE_LOGIN';
    payload: { requestTime: number; token?: Api.TokenModel }
    error?: any;
}

interface RequestCurrentUser {
    type: 'REQUEST_CURRENT_USER';
    payload: { requestTime: number; }
}
interface ReceiveCurrentUser {
    type: 'RECEIVE_CURRENT_USER';
    payload: { requestTime: number; currentUser?: Api.CurrentUserManagerModel; };
    error?: any;
}

interface RequestLogout {
    type: 'REQUEST_LOGOUT';
    payload: { requestTime: number; }
}
interface ReceiveLogout {
    type: 'RECEIVE_LOGOUT';
    payload: { requestTime: number; };
    error?: any;
}


interface AccountResetLastActionTimer {
    type: "ACCOUNT_RESET_LASTACTIONTIMER";
    payload: { time: number }
}

export type KnownAction = RequestCurrentUser
    | ReceiveCurrentUser
    | LoadToken
    | RequestLogin
    | ReceiveLogin
    | RequestLogout
    | ReceiveLogout
    | AccountResetLastActionTimer;

export const requestCurrentUser = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    if (requestTime === getState().account.requestTime)
        return Promise.resolve();

    let api = new Api.AccountApi();
    let fetchTask = api.getCurrentUser({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(currentUser => {
            dispatch({
                type: "RECEIVE_CURRENT_USER",
                payload: { requestTime: requestTime, currentUser: currentUser }
            });
        }).catch(error => {
            console.log(error.message);
            dispatch({
                type: "RECEIVE_CURRENT_USER",
                payload: { requestTime: requestTime },
                error: error
            });
        });

    addTask(fetchTask);
    dispatch({ type: "REQUEST_CURRENT_USER", payload: { requestTime: requestTime }});
    return fetchTask;
}

export const resetTimer = (time: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState) => {
    dispatch({ type: "ACCOUNT_RESET_LASTACTIONTIMER", payload: { time: time } });
};

export const requestLogout = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState) : Promise<any> => {
    let api = new Api.AccountApi();
    let fetchTask = api.logout(
        { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(() => {
            dispatch({
                type: "RECEIVE_LOGOUT",
                payload: { requestTime: requestTime }
            });
            localStorage.removeItem("token");
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_LOGOUT",
                payload: { requestTime: requestTime },
                error: err
            });
        });

    dispatch({
        type: "REQUEST_LOGOUT",
        payload: { requestTime: requestTime }
    });
    closeConnection();
    dispatch(push("/") as any);
    return fetchTask;
}

export const actionCreators = {
    requestCurentUser: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestCurrentUser(requestTime, dispatch, getState);
    },
    requestLogin: (requestTime: number, model: Api.LoginModel): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        let fetchTask = new Promise((resolve, reject) => {
            let api = new Api.AccountApi();
            api.createToken({
                model: model
            }, { credentials: "same-origin" })
                .then(token => {
                    window.localStorage.setItem("token", token.token);
                    dispatch({ type: "RECEIVE_LOGIN", payload: { requestTime, token: token } });
                    let date = new Date().getTime();
                    writeToken(token.token);
                    return requestCurrentUser(date, dispatch, getState)
                    .then(() => {
                        resolve();
                        if (isAuthenticated(getState()) && !getState().mngHub.isConnected) {
                            connectUserHub(dispatch as any, getState);
                        }
                    })
                    .catch(error => reject(error));
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_LOGIN",
                        payload: { requestTime: requestTime },
                        error: error
                    });
                    if (error.status === 401) {
                        error.text()
                            .then(text => reject({ message: JSON.parse(text) }));
                    } else {
                        reject(error);
                    }
                });
        }).catch(error => {
            throw new SubmissionError({ _error: error.message || error.statusText });
        });

        addTask(fetchTask);
        dispatch({ type: "REQUEST_LOGIN", payload: { requestTime: requestTime } });
        return fetchTask;
    },
    requestLogout: (requestTime: number): AppThunkAction<KnownAction, Promise<any>> => (dispatch, getState) => {
        return requestLogout(requestTime, dispatch, getState);
    }
};

const unloadedState: AccountState = {
    isLoading: false,
    isLoaded: false,
    requestTime: 0,
    token: null,
    currentUser: null,
    LoginState: {
        isLoading: false,
        requestTime: 0
    },
    logout: {
        isLoading: false
    },
    lastAction: undefined
};

export const reducer: Reducer<AccountState> = (state: AccountState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CURRENT_USER':
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case 'RECEIVE_CURRENT_USER':
            //Anti out of order
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                currentUser: action.payload.currentUser
            };
        case "LOAD_TOKEN":
            return {
                ...state,
                token: action.payload.token
            };
        case "REQUEST_LOGIN":
            return {
                ...state,
                LoginState: {
                    ...state.LoginState,
                    requestTime: action.payload.requestTime,
                    isLoading: true
                }
            };
        case "RECEIVE_LOGIN":
            if (action.payload.requestTime !== state.LoginState.requestTime)
                return state;

            return {
                ...state,
                token: action.error
                    ? state.token
                    : action.payload.token.token,
                LoginState: {
                    ...state.LoginState,
                    isLoading: false
                }
            };
        case "REQUEST_LOGOUT":
            return {
                ...state,
                logout: {
                    ...state.logout,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_LOGOUT":
            if (state.logout.requestTime !== action.payload.requestTime)
                return state;

            return unloadedState;
        case "ACCOUNT_RESET_LASTACTIONTIMER":
            return { ...state, lastAction: action.payload.time };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};