import * as React from 'react';
import * as ReduxForm from 'redux-form';
import Select, { Option } from './Select';
import {
    InjectedFormProps,
    FieldArrayMetaProps,
    WrappedFieldMetaProps
} from 'redux-form';
import InputNumber from './InputNumber';
import ImageUpload from "./ImageUpload";
import { Guid } from '../utils/utils';
import DatePicker from './DatePicker';
import CheckBox from './CheckBox';
import { getText } from '../utils/texts';


export const required = value => (value ? undefined : getText("ValidationRequired"));
export const requiredOrZero = value => (value || value === 0 ? undefined : getText("ValidationRequired"));
export const requiredNotZero = value => (value && value !== "0" ? undefined : getText("ValidationRequired"));
export const requiredOne = value => (!value || !value.length || value.length === 0
    ? getText("ValidationRequired") : undefined);

export const maxValue = max => value =>
    value && value > max ? `${getText("ValidationMaxValue")} ${max}` : undefined

export const minValue = min => value =>
    value && value < min ? `${getText("ValidationMinValue")}  ${min}` : undefined
export const maxLength = max => value =>
    value && value.length > max ? `${getText("ValidationMustBe")} ${max} ${getText("ValidationMaxLength")}` : undefined

export const minLength = min => value =>
    value && value.length < min ? `${getText("ValidationMustBe")} ${min} ${getText("ValidationMinLength")}` : undefined

export const minOne = value => parseInt(value)
    && parseInt(value) < 1 ? getText("ValidationNoValid") : undefined;

const numberRegex = /^[0-9]+\.?[0-9]*$/;
export const onlyNumbers = value =>
    value && numberRegex.test(value) ? undefined : getText("ValidationOnlyNumbers");

const passwordRegex = /^(?=.*[0-9])(?=.*[A-Za-z])/;
export const passwordValid = value =>
    value && passwordRegex.test(value) ? undefined : getText("ValidationPassword");

const phoneRegex = /^[0-9]*/;
export const phoneValid = value => !value || phoneRegex.test(value) ? undefined : getText("ValidationPhone");

export const renderField = ({
    disabled,
    input,
    className,
    style,
    containerStyle,
    type,
    meta,
    pattern,
    placeholder
}) => (
        <div style={containerStyle}>
            <div>
                <input {...input} style={{
                        ...style,
                        background: isDarkMode() ? "#181F3A" : "white",
                        color: isDarkMode() ? "white" : "#181F3A",
                        //border: "1px solid rgba(59, 67, 112, 0.15)",
                        borderRadius: "4px"
                    }} 
                    pattern={pattern} placeholder={placeholder}
                    className={className} type={type} disabled={disabled} />
            </div>
            <FieldErrors {...meta} />
        </div>
    )


export const textAreaField = (row: number, cols: number, placeholder?: string) => ({
    input,
    className,
    style,
    type,
    meta
}) => (
        <div>
            <div>
                <textarea style={{
                        ...style,
                        background: isDarkMode() ? "#181F3A" : "white",
                        color: isDarkMode() ? "white" : "#181F3A",
                        //border: "1px solid rgba(59, 67, 112, 0.15)",
                        borderRadius: "4px"
                    }}
                    placeholder={placeholder}
                    value={input.value}
                    onChange={(e) => { input.onChange(e.target.value) }}
                    rows={row} cols={cols} />
            </div>
            <FieldErrors {...meta} />
    </div>
);

export const fileField = (field: any) => (
    <input
        style= {{
            //border: "1px solid rgba(59, 67, 112, 0.15)",
            borderRadius: "4px"
        }}
        type="file"
        onChange={(e) => field.input.onChange(e.target.files[0])}
    />
);

export const getSelectField = (options: Array<Option>) => ({
    input,
    disabled,
    className,
    style,
    meta
}) => (
        <div style={{
            ...style,
            backgroundColor: isDarkMode() ? "#181F3A" : "white",
            color: isDarkMode() ? "white" : "#3B4370",   
        }}>
            <Select
                options={options}
                value={input.value}
                disabled={disabled}
                onChange={(value) => input.onChange(value)}
                style={{
                    backgroundColor: isDarkMode() ? "#181F3A" : "white",
                    color: isDarkMode() ? "white" : "#3B4370"
                }}
            />
            <FieldErrors {...meta} />
        </div>
    );


type CheckBoxFieldProps = CheckBoxFieldOwnProps
    & ReduxForm.WrappedFieldProps

interface CheckBoxFieldOwnProps {
    className: string;
    label?: string;
    disabled?: boolean;
    //Used to manage enum with checkbox
    checkedValue?: any;
    unCheckedValue?: any;
    style?: any;
}

export class CheckBoxField extends React.Component<CheckBoxFieldProps, {}> {
    public render() {
        return (
            <div>
                <div className={this.props.className}>
                    <CheckBox
                        label={this.props.label}
                        style={this.props.style}
                        value={this.props.checkedValue
                            ? (this.props.input.value === this.props.checkedValue)
                            : this.props.input.value}
                        disabled={this.props.disabled}
                        onChange={(value) => {
                            if (this.props.checkedValue) {
                                this.props.input.onChange(value
                                    ? this.props.checkedValue
                                    : this.props.unCheckedValue);
                            } else {
                                this.props.input.onChange(value as any);
                            }
                        }}
                    />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }
}

type NumberFieldProps = NumberFieldOwnProps
    & ReduxForm.WrappedFieldProps

interface NumberFieldOwnProps {
    className: string;
    unit?: string;
    disabled?: boolean;
    allowUndefined?: boolean;
    containerStyle?: React.CSSProperties;
    fieldContainerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

export class NumberField extends React.Component<NumberFieldProps, {}> {
    public render() {
        return (
            <div style={this.props.fieldContainerStyle}>
                <div>
                    <InputNumber
                        className={this.props.className}
                        unit={this.props.unit}
                        value={this.props.input.value}
                        style={{
                            ...this.props.style,
                            //border: "1px solid rgba(59, 67, 112, 0.15)",
                            borderRadius: "4px"
                        }}
                        containerStyle={this.props.containerStyle}
                        disabled={this.props.disabled}
                        allowUndefined={this.props.allowUndefined}
                        onChange={(value) => this.props.input.onChange(value as any)}
                    />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }

}

type ImageFieldProps = ImageFieldOwnProps
    & ReduxForm.WrappedFieldProps

interface ImageFieldOwnProps {
    fieldKey?: string;
    height?: number;
    className: string;
    disabled?: boolean;
}

export class ImageField extends React.Component<ImageFieldProps, {}> {
    private guid: string;

    constructor(props: ImageFieldProps) {
        super(props);
        this.guid = Guid.newGuid();
    }

    public render() {
        return <div>
            <div>
                <ImageUpload
                    fieldKey={this.props.fieldKey || this.guid}
                    disabled={this.props.disabled}
                    height={this.props.height}
                    value={this.props.input.value}
                    onChange={(imageId) => this.props.input.onChange(imageId as any)}
                />
            </div>
            <FieldErrors {...this.props.meta} />
        </div>
    }
}



type DatePickerFieldProps = DatePickerFieldOwnProps
    & ReduxForm.WrappedFieldProps

interface DatePickerFieldOwnProps {
    minDate?: Date;
    maxDate?: Date;
    className?: string;
    showTimeSelect?: boolean;
    setDateButton?: JSX.Element;
    dateToSet?: Date;
    dateFormat?: string
}

export class DatePickerField extends React.Component<DatePickerFieldProps, {}> {
    public render() {
        return (
            <div>
                <div>
                    <DatePicker
                        className={this.props.className || "form-control"}
                        showTimeSelect={this.props.showTimeSelect}
                        value={this.props.input.value}
                        setDateButton={this.props.setDateButton}
                        dateFormat={this.props.dateFormat || 'dd DD/MM/YYYY, HH:mm'}
                        dateToSet={this.props.dateToSet ? this.props.dateToSet : undefined}
                        onChange={(value) => this.props.input.onChange(value as any)}
                        minDate={this.props.minDate ? this.props.minDate : null}
                        maxDate={this.props.maxDate ? this.props.maxDate : null} />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }
}

export const rteField = ({
    input,
    disabled,
    meta,
    className
}) => {
    return <div>
        <div>
            <textarea
                style= {{
                    //border: "1px solid rgba(59, 67, 112, 0.15)",
                    borderRadius: "4px"
                }}
                className={className}
                value={input.value}
                disabled={disabled}
                onChange={(e) => { input.onChange(e.target.value) }}
                rows={5} cols={45} />
            
        </div>
        <FieldErrors {...meta} />
    </div>
}

interface BaseFormProps {
    className?: string;
    style?: React.CSSProperties;
    contentContainerStyle?: React.CSSProperties;
    disableResetOnSubmit?: boolean;
    validate?: Array<any>;
    warn?: Array<any>;
}

export class BaseForm extends React.Component<InjectedFormProps<{}, {}, {}> & BaseFormProps, {}> {

    submitWrapped(e) {
        let maybePromise = (this.props.handleSubmit(e) as any);
        if (maybePromise && maybePromise.then) {
            let promise = maybePromise as Promise<any>;
            promise
                .then(() => {
                    if (!this.props.disableResetOnSubmit) {
                        this.props.reset();
                    }
                });
        }
    }

    public render() {
        return (
            <form className={this.props.className || "form-horizontal"}
                style={this.props.style}
                onSubmit={(e) => {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                this.submitWrapped(e);
            }}>
                <div className="text-danger" style={{
                    backgroundColor: isDarkMode() ? "#181F3A" : "white",
                    textAlign: "center" 
                }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="text-warning" style={{
                    backgroundColor: isDarkMode() ? "#181F3A" : "white",
                    textAlign: "center"
                }}>
                    {this.props.warning && <span>{this.props.warning}</span>}
                </div>
                <div style={this.props.contentContainerStyle}>
                    {this.props.children}
                </div>
            </form>
        );
    }
}

export class FieldErrors extends React.Component<FieldArrayMetaProps | WrappedFieldMetaProps, {}> {
    public render() {
        return (
            <div>
                <div className="text-danger">
                    {(this.props.submitFailed || this.props.dirty)
                        && (this.props.error && <span>{this.props.error}</span>)}
                </div>
                <div className="text-warning">
                    {this.props.warning && <span>{this.props.warning}</span>}
                </div>
            </div>
            );
    }
}

function isDarkMode() {
    var isTrueSet = 'false';
    if (typeof window !== 'undefined') {
        isTrueSet = localStorage.getItem('isDarkMode');
    }
    if (isTrueSet == 'true') {
        return true;
    }
    return false;
}
