import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import * as AccountStore from "../store/Account";
import LoginForm from "./LoginForm";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';

type LoginProps = LoginOwnProps
    & AccountStore.LoginState
    & typeof AccountStore.actionCreators;

interface LoginOwnProps {
}

class Login extends React.Component<LoginProps, {}> {
    public render() {
        return (
            <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div 
                className="card"
                style={{
                    ...Styles.cardStyle, 
                    width: '40%',
                    padding: '8%',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                }}>
                    <LoginForm onSubmit={(model) =>
                        this.props.requestLogin(new Date().getTime(), model) as any} />
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
}),
    AccountStore.actionCreators)(Login as any) as any as React.ComponentClass<{}>;