import * as Api from '../api/api';
import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import * as HistoryStore from "../store/History";
import * as ReactTable from "react-table";
import Table from "./Table";
import { getCurrentLang } from '../utils/language';
import { getText } from '../utils/texts';
import { ProductStockData } from '../store/History';

interface HistoryStockOperationsProps {
    storeType: string;
    isLoading: boolean;
    seed: Api.SeedMngModel;
    selectedIndex: number;
    dayStoreDatas: Array<Api.DayStoreDataModel>;
    updateSelectedIndex: (value: number) => void;
    stockStatus: Api.StockStatusModel;
    productSellDatas: Array<HistoryStore.ProductSellData>;
    totalStockDatas: Array<HistoryStore.ProductStockData>;
    productSuppliers: { [id: number]: Api.ProductSupplierModel };
}

type StockOperationsColumn = ReactTable.Column
    & {
        accessor: (d: HistoryStore.ProductStockData) => any;
        style?: React.CSSProperties;
        columns?: Array<StockOperationsColumn>
    };

const colStyle: React.CSSProperties = {
    flex: 1,
    maxWidth: 300,
    textAlign: "center",
    padding: "3px 7px"
};
const rowStyle: React.CSSProperties = {display: 'flex', justifyContent: 'space-between', paddingBottom: '1%'};

export default class HistoryStockOperations extends React.Component<HistoryStockOperationsProps, {}> {

    get selectedDayStoreData(): Api.DayStoreDataModel {
        return this.props.dayStoreDatas[this.props.selectedIndex];
    }

    get selectedDayStoreDataStoreStockOperations(): Api.StoreStockOperationModel[] {
        return this.selectedDayStoreData ? this.selectedDayStoreData.storeStockOperations
            : [];
    }

    isDarkMode() {
        var isTrueSet = 'false';
        if (typeof window !== 'undefined') {
            isTrueSet = localStorage.getItem('isDarkMode');
        }
        if (isTrueSet == 'true') {
            return true;
        }
        return false;
    }

    getTotal(valueToSearch: number, externalP: boolean = false): number {
        if(externalP == true){
            if(this.selectedDayStoreDataStoreStockOperations
                .filter(x => x.productSupplierId === valueToSearch && x.type != "ManagerStockIn"
                    && x.type != "ManagerStockOut")
                .map(x => x.quantityStored).length != 0){
                console.log(this.selectedDayStoreDataStoreStockOperations
                    .filter(x => x.productSupplierId === valueToSearch && x.type != "ManagerStockIn"
                        && x.type != "ManagerStockOut")
                    .map(x => x.quantityStored))}
            return _.sum(this.selectedDayStoreDataStoreStockOperations
                .filter(x => x.productSupplierId === valueToSearch && x.type != "ManagerStockIn"
                    && x.type != "ManagerStockOut")
                .map(x => x.quantityStored));
        }
        return _.sum(this.selectedDayStoreDataStoreStockOperations
            .filter(x => x.productId === valueToSearch && x.type != "ManagerStockIn"
                && x.type != "ManagerStockOut")
            .map(x => x.quantityStored));
    }

    getStockOperationsByType(valueToSearch: number, type: Api.StoreStockOperationTypeEnum, externalP: boolean = false): number {
        if(externalP == true){
            return _.sum(this.selectedDayStoreDataStoreStockOperations
                .filter(x => x.productSupplierId === valueToSearch && x.type === type)
                .map(x => x.quantityStored));
        }
        return _.sum(this.selectedDayStoreDataStoreStockOperations
            .filter(x => x.productId === valueToSearch && x.type === type)
            .map(x => x.quantityStored));
    }

    getDayStock(productId: number, index: number): number {    
        let x = _.sum(_.map(this.props.totalStockDatas
            .filter(x => x.productId === productId), y => (y.stock + y.storedStock)))
        if(x)
            return x;
        return 0;
    }

    getReceptions(productId: number): number {
        return  this.props.dayStoreDatas[this.props.selectedIndex] ?
                        this.props.storeType == "Ximiti" ?
                         _.sum(this.props.dayStoreDatas[this.props.selectedIndex].storeProductReceptions
                            .filter(x => x.productId === productId)
                            .map(x => x.quantity)) 
                        :
                        _.sum(this.props.dayStoreDatas[this.props.selectedIndex].storeProductReceptions
                            .filter(x => x.productSupplierId === this.props.seed.externalProducts[productId].productSuppliers[0].productSupplierId)
                            .map(x => x.quantity)) 
                : 0;
    }

    get productRails() {
        if (!this.props.stockStatus)
            return [];


        let planogramId = _.sortBy(
            this.props.stockStatus.planograms, x => !x.active)[0].planogramId;

        return (this.props.storeType == "Ximiti" ? _.values(_.groupBy(
                    _.values(this.props.stockStatus.productRails).filter(x => x.planogramId === planogramId), x => x.productId))
                .map(x => x[0] && ({productId: x[0].productId, planogramId: x[0].planogramId }))
                .sort((x, y) => this.props.seed.products[x.productId].name.localeCompare(this.props.seed.products[y.productId].name))
                :
                _.values(_.groupBy(
                    _.values(this.props.stockStatus.productRails).filter(x => x.planogramId === planogramId), x => x.externalProductId))
                .map(x => x[0] && ({productId: x[0].externalProductId, planogramId: x[0].planogramId }))
                .sort((x, y) => this.props.seed.externalProducts[x.productId].name.localeCompare(this.props.seed.externalProducts[y.productId].name))
            );
    }   

    getValueSalesByProdId(productId: number) : number {
        var auxSales = 0;
        if(this.selectedDayStoreData && this.selectedDayStoreData.storeOrders)
            this.selectedDayStoreData.storeOrders.map(x => {
                    if( x.status === "Delivered" && x.storeOrderPayments.length)
                        auxSales += x.storeOrderProductDeliveries.filter(y => y.productId == productId).length
                }
            )
        return auxSales;
    }

    getNextDayStock(productId: number, index : number) : number {
        let finalStock = this.getDayStock(productId, index);
        let totalStock = this.props.storeType == "Ximiti" ? (this.getReceptions(productId) + this.getTotal(productId)) : 
                            (this.getReceptions(productId) + this.getTotal(this.props.seed.externalProducts[productId].productSuppliers[0].productSupplierId, true));
        let sales = this.getValueSalesByProdId(productId);

        return (finalStock+totalStock-sales);
    }

    public render() {
        return (
            <div style={{...rowStyle}}>
                <div className="heightHisto" style={{marginTop: 2, paddingLeft: '1%', overflow: 'auto', width: '20%'}}>
                    {this.props.dayStoreDatas.map((dayStoreData, xi) => <div key={xi}
                        className="histo-date"
                        style={{
                            color: xi === this.props.selectedIndex
                                ? "blue"
                                : "inherit",
                        }}
                        onClick={(e) => this.props.updateSelectedIndex(xi)}>
                        {Moment(dayStoreData.dayDate).locale(getCurrentLang()).format("dddd DD / MM / YYYY")}
                    </div>)}
                </div>

                <div style={{paddingLeft: '1%', width: '80%'}}>
                    <Table className="heightHisto"
                        style={{margin: 0}}
                        loading={this.props.isLoading}
                        pageSize={this.productRails
                            ? Math.max(this.productRails.length, 20) : 20}
                        getTheadGroupProps={(state, rowInfo, column)=>{
                            return {
                                style: {
                                    backgroundColor: this.isDarkMode() ? "#181F3A" : "#FFFFFF"

                                }
                            }
                        }}
                        showPageSizeOptions={false}
                        showPagination={false}
                        showPaginationBottom={false}
                        columns={[
                            {
                                Header: <span>&nbsp;</span>,
                                columns: [
                                    {
                                        Header: <div>{getText("HistoStockOpProductName")}</div>,
                                        id: 'name',
                                        accessor: x => this.props.storeType == "Ximiti" ? this.props.seed.products[x.productId].name 
                                                        : this.props.seed.externalProducts[x.productId].name,
                                        width: 200,
                                        Footer: (
                                            <div>
                                                <div>{getText( "HistoTotal")}</div>
                                               { this.props.storeType == "Ximiti" && <div>{getText( "HistoTotalPrice")}</div> }
                                            </div>
                                        )
                                    }
                                ]
                                
                            },
                            {
                                Header: <div>{getText( "HistoYesterday")}</div>,
                                id: 'day',
                                columns: [
                                    {
                                        Header: <div>{getText("HistoStockOpDay1")}</div>,
                                        id: 'today',
                                        accessor: x => this.getDayStock(x.productId, this.props.selectedIndex),
                                        Footer: (
                                            <div>  
                                                <div> 
                                                    <strong>
                                                        { _.sum(_.map(this.productRails, x => this.getDayStock(x.productId, this.props.selectedIndex))) || 0}
                                                    </strong>
                                                </div>
                                                { this.props.storeType == "Ximiti" &&
                                                <div>
                                                    ({_.sum(_.map(this.productRails, x => {
                                                        let dlcValue : number;
                                                        let auxSupplier : Api.ProductSupplierModel;
                                                        
                                                            dlcValue = Math.abs(this.getStockOperationsByType(x.productId, "ExpirityOut")) 
                                                            auxSupplier = _.sortBy(this.props.seed.products[x.productId]
                                                                .productSuppliers, x => (x.enabled ? 0 : 2)
                                                                    + (x.supplierMainId === this.props.seed.products[x.productId].defaultSupplierId ? 0 : 1))[0];
                                                            return (dlcValue * auxSupplier.buyPriceNoVat)
                                                    }))
                                                    .toFixed(2) || 0.00}{" \u20AC"})
                                                </div>}
                                            </div>
                                        )
                                    }
                                ] as Array<StockOperationsColumn>
                            },
                            {
                                Header: <div>{getText( "HistoDaysOperations")}</div>,
                                id: 'dayOperations',
                                columns: [                                    
                                    {
                                        Header: <div>{getText("HistoStockOpDayReception")}</div>,
                                        id: 'reception',
                                        accessor: x => this.getReceptions(x.productId),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.getReceptions(x.productId))) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDayDLC")}</div>,
                                        id: 'dlc',
                                        accessor: x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ExpirityOut"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ExpirityOut", true)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ExpirityOut")) 
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ExpirityOut", true)) )) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDayManualRemove")}</div>,
                                        id: 'removed',
                                        accessor: x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManualMarkdown"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManualMarkdown", true)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManualMarkdown"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManualMarkdown", true)) )) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDayLoad")}</div>,
                                        id: 'added',
                                        accessor: x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStockIn"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStockIn", true)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStockIn")) 
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStockIn", true)) )) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDayUnload")}</div>,
                                        id: 'substracted',
                                        accessor: x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStockOut"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStockOut", true)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStockOut")) 
                                                            :  Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStockOut", true)) )) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDayAdd")}</div>,
                                        id: 'stockAdded',
                                        accessor: x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStoredIn"))
                                                            :  Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStoredIn", true)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStoredIn"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStoredIn", true)) )) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDaySubs")}</div>,
                                        id: 'stockSubstracted',
                                        accessor: x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStoredOut"))
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStoredOut", true)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span><strong>{_.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? Math.abs(this.getStockOperationsByType(x.productId, "ManagerStoredOut")) 
                                                            : Math.abs(this.getStockOperationsByType(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, "ManagerStoredOut", true)) )) || 0}</strong></span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpSales")}</div>,
                                        id: 'sales',
                                        accessor: x => this.getValueSalesByProdId(x.productId),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span>
                                                        <strong>
                                                            { _.sum(_.map(this.productRails, x => this.getValueSalesByProdId(x.productId))) || 0}
                                                        </strong>
                                                    </span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpTotal")}</div>,
                                        id: 'total',
                                        accessor: x => this.props.storeType == "Ximiti" ? (this.getReceptions(x.productId) + this.getTotal(x.productId) - this.getValueSalesByProdId(x.productId)) : 
                                                            (this.getReceptions(x.productId) + this.getTotal(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, true) - this.getValueSalesByProdId(x.productId)),
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <span>
                                                        <strong>
                                                            { _.sum(_.map(this.productRails, x => this.props.storeType == "Ximiti" ? 
                                                                (this.getReceptions(x.productId) + this.getTotal(x.productId) - this.getValueSalesByProdId(x.productId)) :
                                                                (this.getReceptions(x.productId) + this.getTotal(this.props.seed.externalProducts[x.productId].productSuppliers[0].productSupplierId, true) - this.getValueSalesByProdId(x.productId)))) 
                                                                || 0}
                                                        </strong>
                                                    </span>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <div>{getText("HistoStockOpDsay2")}</div>,
                                        id: 'tomorrow',                                  
                                        accessor: x => x,
                                        Cell: (row) => {
                                            let nextStock = row.row.today + row.row.total;
                                            return <div>{Math.abs(nextStock)}</div>
                                        },
                                        Footer: (
                                            <div>
                                                <div style={{ ...colStyle }}>
                                                    <strong>
                                                        { _.sum(_.map(this.productRails, x => this.getNextDayStock(x.productId, this.props.selectedIndex))) || 0}
                                                    </strong>
                                                </div>
                                                <div><span></span></div>
                                            </div>
                                        )
                                    }
                                ] as Array<StockOperationsColumn>
                            }
                            // {
                            //     Header: <div>Demain</div>,
                            //     id: 'demain',
                            //     columns: [
                            //         {
                            //             Header: <div>Qte J+1</div>,
                            //             id: 'qtyDayAdd1',
                            //             accessor: x => x,
                            //             Cell: row => <div>{0}</div>,
                            //             width: 100,
                            //             Footer: (
                            //                 <div>
                            //                     <div style={{ ...colStyle }}>
                            //                         <span><strong>{0}</strong></span>
                            //                     </div>
                            //                     <div><span></span></div>
                            //                 </div>
                            //             )
                            //         }
                            //     ] as Array<StockOperationsColumn>
                            // }
                        ] as Array<StockOperationsColumn>}
                        data={this.productRails}
                    />
                </div>
            </div>
        );
    }
}