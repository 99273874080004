import * as React from "react";
import * as _ from 'lodash';
import * as Api from '../api/api';
import * as FormField from './FormField';
import { InjectedFormProps, Field, reduxForm, formValueSelector } from "redux-form";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { canUpdate } from "../security/roles";
import { getText } from '../utils/texts';

type ShelvingFormProps = InjectedFormProps<{}, {}, {}>
    & ShelvingFormOwnProps
    & ShelvingFormExternalProps;

interface ShelvingFormOwnProps {
    text: string;
    disabled: boolean;
    shelvings: { [key: string]: Api.ShelvingModel; };
    codeUpdated?: string;
}

interface ShelvingFormExternalProps {
    onSubmit: (values: Api.ShelvingModel) => Promise<any>
}

interface ShelvingState {
}

class ShelvingForm extends React.Component<ShelvingFormProps, ShelvingState> {
    constructor(props: ShelvingFormProps) {
        super(props);
        this.validationShelving = this.validationShelving.bind(this);
        this.state = {
        };
    }

    validationShelving(value) {
        return _.values(this.props.shelvings)
             .some(x =>(this.props.codeUpdated == undefined && x.code === value) || 
               (this.props.codeUpdated != value && x.code === value))
                ? getText("SettingsShelvingValidation")
                : undefined
    }

    public render() {
        return (<FormField.BaseForm {...this.props} className="form-vertical borderSelectors">
            <div className="row" style={{padding: '0 1%', textAlign: 'left', marginTop: '1rem'}}>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsCode")}</label>
                    <Field name="code"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required, this.validationShelving]}
                        component={FormField.renderField} />
                </div>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsNameTable")}</label>
                    <Field name="name"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.renderField} />
                </div>
            </div>
            <div className="row" style={{padding: '0 1%', textAlign: 'left'}}>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsLengthTable")}</label>
                    <Field name="width"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.NumberField} />
                </div>
                <div className="col-md-6 shelvingTrayFormCol">
                    <label className="control-label">{getText( "SettingsHeightTable")}</label>
                    <Field name="railHeight"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.NumberField} />
                </div>
            </div>
            <div className="row" style={{padding: '0 1%', textAlign: 'left', display: 'flex', alignItems: 'flex-end'}}>
                <div className="col-md-6 form-group">
                    <label className="control-label">{getText( "SettingsFace")}</label>
                    <Field name="lane"
                        className="form-control"
                        type="text"
                        style={{height: 40}}
                        validate={[FormField.required]}
                        component={FormField.getSelectField([{ label: "1", value: 1 }, { label: "2", value: 2 } ])} />
                </div>
                <div className="col-md-6 form-group">
                    <button type={"submit"}
                        className="btn btn-primary"
                        style={{width: '100%', height: 40}}
                        disabled={this.props.submitting || this.props.disabled}>
                        {this.props.text}
                    </button>
                </div>
                
            </div>
        </FormField.BaseForm>);
    }
}

let createForm = reduxForm({
    form: 'createShelving',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ShelvingForm as any) as any;
const createSelector = formValueSelector('createShelving');

export const CreateShelvingForm = connect((state: ApplicationState) => {
    let elements = state.stock.stockStatus.shelvings ? JSON.parse(JSON.stringify(state.stock.stockStatus.shelvings)) : null;
    let lastElement;
    if (elements) {
        Object.keys(elements).forEach(function (key) {
            lastElement = elements[key]
        });
    }
    if(lastElement) {
        let newName = (Number(lastElement.name) + 1).toString();

        lastElement.name = newName;
        let newCode = (Number(lastElement.code) + 1).toString();
        lastElement.code = newCode;
    }
    return {
        text: getText( "SettingsCreate"),
        initialValues: lastElement ? lastElement : null,
        disabled: !canUpdate(state),
        shelvings: state.stock.stockStatus.shelvings
    } as ShelvingFormOwnProps
}, null)(createForm as any) as any as React.ComponentClass<ShelvingFormExternalProps>


let form = reduxForm({
    form: 'updateShelving',
    enableReinitialize: true,
    destroyOnUnmount: true
})(ShelvingForm as any) as any;
const updateSelector = formValueSelector('updateShelving');

export const UpdateShelvingForm = connect((state: ApplicationState) => {
    return {
        text: getText( "SettingsSave"),
        initialValues: state.stock.stockStatus.shelvings[state.stock.selectedShelvingId],
        disabled: !canUpdate(state),
        shelvings: state.stock.stockStatus.shelvings,
        codeUpdated: state.stock.stockStatus.shelvings[state.stock.selectedShelvingId].code
    } as ShelvingFormOwnProps
}, null)(form as any) as any as React.ComponentClass<ShelvingFormExternalProps>

