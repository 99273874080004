import * as React from 'react';
import * as PrintStore from '../store/Print';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { canEditSettings, canUpdate } from '../security/roles';
import { getText } from '../utils/texts';

type PrintTestProps = PrintTestOwnProps
    & typeof PrintStore.actionCreators;

interface PrintTestOwnProps {
    print: PrintStore.PrintState;
    disabled: boolean;
}

interface PrintTestState {
    toPrint: string;
}

class PrintTest extends React.Component<PrintTestProps, PrintTestState> {
    constructor(props: PrintTestProps) {
        super(props);
        this.state = {
            toPrint: "Something to print"
                + "\r\n"
                + "1x COCA COLA 33CL    3.00$"
        };
    }

    public render() {
        return (
            <div>
                <textarea style={{display: 'block', margin: 'auto'}}
                    value={this.state.toPrint}
                    onChange={(e) => this.setState({ toPrint: e.target.value })}
                    rows={19} cols={53}>
                </textarea>
                <button className="btn btn-primary"
                    style={{height: 40, fontSize: 14, float: 'right', marginTop: '6%'}}
                    disabled={this.props.print.printTextState.isLoading
                        || this.props.disabled}
                    onClick={(e) => {
                        this.props.requestPrintText(new Date().getTime(), this.state.toPrint);
                    }}>
                    {getText( "MaintenanceToPrint")}
                </button>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    print: state.print,
    disabled: !canEditSettings(state)
} as PrintTestOwnProps), {
        ...PrintStore.actionCreators,
    })(PrintTest as any);