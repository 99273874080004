import * as Api from '../api/api';
import * as React from 'react';
import { getText } from '../utils/texts';

interface RobotIndicatorsProps {
    robotData: Api.RobotData;
    robotState: Api.RobotHandlerState;
}

export default class RobotIndicators extends React.Component<RobotIndicatorsProps, {}> {

    public render() {
        return (
            <div>
                <div style={{fontWeight: "bold", fontSize: 16, paddingRight: '2%'}}>{getText( "MaintenanceIndicators")}</div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{
                            height: 16, width: 16,
                            borderRadius: 16,
                            backgroundColor: this.props.robotData.isPanicButtonPressed ? "red" : "green",
                            marginRight: 5
                        }}></div>
                    <div>{getText( "MaintenanceEmergencyStop")}</div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '6%'}}>
                    <div style={{
                        height: 16, width: 16,
                        borderRadius: 16,
                        backgroundColor: this.props.robotState
                            && (this.props.robotState.status === "Ready"
                                || this.props.robotState.status === "Running") ? "green" : "red",
                        marginRight: 5
                    }}></div>
                    <div> {getText( "MaintenanceRobotInit")} </div>
                </div>
            </div>
            );
    }
}